import React, { useEffect, useState } from 'react';
import { RestorePassword, WithClearSafariPageCache } from '@it25syv/25syv-ui';
import { bindActionCreators } from 'redux';
import { hideModal as HideModal } from '../actions/UiActions';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { browserHistory } from 'react-router';

const RestoreUserPassword = ({ hideModal, auth }) => {
    const [userData, setUserData] = useState({});

    useEffect(
        () => {
            if (auth?.user) {
                setUserData(auth.user);
            }
        },
        [auth.user]
    );

    return userData.userName ? (
        <RestorePassword
            closeAction={() => {
                browserHistory.push('/sport/');
                hideModal('RESTORE_PASSWORD');
            }}
            site={'sport'}
            userProfile={userData}
            successHandler={() => {
                NotificationManager.success('Dit kodeord er blevet ændret');
                hideModal('RESTORE_PASSWORD');
                browserHistory.push('/sport/');
            }}
            onError={(e) => {
                NotificationManager.error(e.errorMessage);
            }}
        />
    ) : null;
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

function mapDispatchToProps(dispatch) {
    return {
        hideModal: bindActionCreators(HideModal, dispatch),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithClearSafariPageCache(RestoreUserPassword));
