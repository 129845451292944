import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { SpilIdBarcodePreloader } from './styled';
import FlexWrapper from 'ui/FlexWrapper';
import Bet25Logo from './assets/bet25-logo.svg';
import SpilIDLogo from './assets/spil-id-logo.svg';
import MainCardBG from './assets/spil-id-background.png';
import Icon from '../../ecoui/Icon';
import Text from 'ui/Text';
import { breakpoint } from 'constants/breakpoints';

const CARD_TYPE = {
    BARCODE: 'BARCODE',
    SPIL_ID: 'SPIL_ID',
};

const SpilCardWrapper = styled.div`
    width: 343px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 60px;
    @media screen and ${breakpoint.XS} {
        width: calc(100% - 35px);
    }
`;

const cardStyle = css`
    border-radius: 12px;
    box-shadow: 0 5px 20px #1a2aa229;
    height: 209px;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    padding: 20px;

    @media screen and ${breakpoint.XS} {
        height: 190px;
    }
`;

const styleForBackCard = css`
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    cursor: pointer;
`;

const styleForMainCard = css`
    position: relative;
    top: 80px;
    z-index: 2;
    cursor: auto;
`;

const BarcodeCard = styled.div`
    ${cardStyle};
    display: flex;
    justify-content: center;
    background: transparent linear-gradient(122deg, #ffffff 0%, #d5d5d5 100%) 0%
        0% no-repeat padding-box;
    ${styleForBackCard};
    ${({ activeCard }) =>
        activeCard === CARD_TYPE.BARCODE
            ? styleForMainCard
            : css`
                  ${styleForBackCard};

                  &:hover {
                      transform: translateY(-10px);
                  }
              `};
`;

const CardBackground = styled.div`
  ${cardStyle};
  background: url(${MainCardBG});
  background-size: cover;
  color: #FFFFFF;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ activeCard }) =>
      activeCard === CARD_TYPE.SPIL_ID
          ? styleForMainCard
          : css`
                ${styleForBackCard};

                &:hover {
                    transform: translateY(-10px);
                }
            `};
}

;
`;

const BarCodeImage = styled.img`
  background: url(${(props) => props.barcode});
  width: 224px;
  height: 40px;
  position: relative;
  top: 0;
  transition: height 300ms ease;
  ${({ active }) =>
      active &&
      css`
          position: absolute;
          top: 50%;
          left: 50%;
          box-sizing: border-box;
          //width: calc(100% - 40px);
          transform: translate(-50%, -50%);
          height: 100px;
      `}
}

;
`;

const UserNameWrapper = styled.div`
    font-size: 18px;
    display: flex;
    flex-direction: column;
    width: 50%;
`;

const StyledSpilIDLogo = styled(Icon)`
    position: relative;
`;

const SpilIdCard = ({ pending, hash, barcode, fullName, birthdate }) => {
    const ready = !pending;
    const [activeCard, setActiveCard] = useState(CARD_TYPE.SPIL_ID);

    return (
        <SpilCardWrapper>
            <BarcodeCard
                activeCard={activeCard}
                onClick={() => setActiveCard(CARD_TYPE.BARCODE)}
            >
                {ready ? (
                    <BarCodeImage
                        active={activeCard === CARD_TYPE.BARCODE}
                        barcode={barcode}
                    />
                ) : (
                    <SpilIdBarcodePreloader />
                )}
            </BarcodeCard>
            <CardBackground
                activeCard={activeCard}
                onClick={() => setActiveCard(CARD_TYPE.SPIL_ID)}
            >
                <FlexWrapper
                    align={'space-between'}
                    alignItems={'flex-start'}
                    direction={'row'}
                    margin={'10px 0 0'}
                    padding={'0'}
                >
                    <StyledSpilIDLogo
                        src={Bet25Logo}
                        color={'#fff'}
                        height={'40px'}
                        width={'85px'}
                    />
                    <StyledSpilIDLogo
                        src={SpilIDLogo}
                        color={'#fff'}
                        height={'40px'}
                        width={'100px'}
                    />
                </FlexWrapper>
                <FlexWrapper
                    /*align={''}*/
                    height={'auto'}
                    alignItems={'flex-end'}
                    direction={'column'}
                    padding={'0'}
                >
                    <FlexWrapper
                        align={'space-between'}
                        direction={'row'}
                        padding={'0'}
                        height={'auto'}
                    >
                        <Text
                            width={'50%'}
                            height={'auto'}
                            padding={'0 10px 0 0'}
                            margin={'0'}
                            size={'18'}
                            lh={'18'}
                            color={'#fff'}
                            bold
                        >
                            {fullName}
                        </Text>
                        <Text
                            width={'50%'}
                            height={'auto'}
                            padding={'0 0 0 10px'}
                            margin={'0'}
                            size={'18'}
                            lh={'18'}
                            color={'#fff'}
                            bold
                        >
                            Spil-ID
                        </Text>
                    </FlexWrapper>
                    <FlexWrapper
                        height={'auto'}
                        align={'space-between'}
                        direction={'row'}
                        padding={'0'}
                        margin={'10px 0 0 '}
                    >
                        <Text
                            width={'50%'}
                            size={'x2'}
                            color={'#fff'}
                            align={'left'}
                            padding={'0 10px 0 0'}
                            flex={'auto'}
                        >
                            {birthdate?.replace(/-/g, '.')}
                        </Text>
                        <Text
                            width={'50%'}
                            height={'auto'}
                            padding={'0 0 0 10px'}
                            margin={'0'}
                            size={'18'}
                            lh={'18'}
                            uppercase
                            color={'#fff'}
                        >
                            {hash}
                        </Text>
                    </FlexWrapper>
                </FlexWrapper>
            </CardBackground>
        </SpilCardWrapper>
    );
};

export default SpilIdCard;
