import {
    OVERVIEW_SEARCH_EVENTS_CLEAR,
    OVERVIEW_SEARCH_EVENTS_FETCH_ERROR,
    OVERVIEW_SEARCH_EVENTS_FETCH_PENDING,
    OVERVIEW_SEARCH_EVENTS_FETCH_SUCCESS,
} from 'constants/overviewEvents';

const initialState = {
    pending: false,
    fetched: false,
    data: [],
    error: false,
};

export default function overviewSearchEvents(state = initialState, action) {
    switch (action.type) {
        case OVERVIEW_SEARCH_EVENTS_FETCH_PENDING:
            return {
                ...state,
                pending: true,
                fetched: false,
                data: [],
                error: false,
            };

        case OVERVIEW_SEARCH_EVENTS_FETCH_SUCCESS:
            return {
                ...state,
                pending: false,
                fetched: true,
                data: action.payload,
                error: false,
            };

        case OVERVIEW_SEARCH_EVENTS_FETCH_ERROR:
            return {
                ...state,
                pending: false,
                fetched: false,
                data: [],
                error: action.payload,
            };

        case OVERVIEW_SEARCH_EVENTS_CLEAR:
            return {
                ...state,
                pending: false,
                fetched: false,
                data: [],
                error: false,
            };
        default:
            return state;
    }
}
