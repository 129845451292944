import virtualGameTypes from 'constants/virtualGameTypes';
import { get } from 'lodash';
import { performTerminalBetText } from 'utils/Helper';

export function oddsName(input) {
    let res = '';
    switch ((input + '').toLowerCase()) {
        case 'o':
            res = 'Over';
            break;
        case 'over':
            res = 'Over';
            break;
        case 'u':
            res = 'Under';
            break;
        case 'under':
            res = 'Under';
            break;
        case 'even':
            res = 'Lige';
            break;
        case 'odd':
            res = 'Ulige';
            break;
        case 'equals':
            res = 'Samme antal';
            break;
        case 'second half':
            res = '2. Halvleg';
            break;
        case 'first half':
            res = '1. Halvleg';
            break;
        case 'other':
            res = 'Andre';
            break;
        case 'no':
            res = 'Nej';
            break;
        case 'yes':
            res = 'Ja';
            break;
        case 'away and over':
            res = 'Udehold & Over';
            break;
        case 'draw and over':
            res = 'Uafgjort & Over';
            break;
        case 'home and over':
            res = 'Hjemmehold & Over';
            break;
        case 'away and under':
            res = 'Udehold & Under';
            break;
        case 'draw and under':
            res = 'Uafgjort & Under';
            break;
        case 'home and under':
            res = 'Hjemmehold & Under';
            break;
        default:
            res = input;
    }
    return res;
}

/**
 * @param {Object} market
 * @return {boolean}
 */
export function isVirtualMarketHandicap(market) {
    return market.gameName && market.gameName.startsWith('Handicap');
}

/**
 * @param market {MarketModel}
 * @return {boolean}
 */
export function isVirtualMarketOverUnder(market) {
    let isOUmarket = market.gameName && market.gameName.startsWith('O/U ');
    return isOUmarket;
}

/**
 * @param {Object} market
 * @return {boolean}
 */
export function isVirtualMarketHalvleg(market) {
    return (
        market.gameType && market.gameType === virtualGameTypes.halfTimeFullTime
    );
}

/**
 * @param {Object} market
 * @return {boolean}
 */
export function isVirtualMarketVinder(market) {
    return market.gameType && market.gameType === virtualGameTypes._1X2;
}

/**
 * @param {Object} market
 * @return {boolean}
 */
export function isVirtualMarketDoubleChance(market) {
    return market.gameType && market.gameType === virtualGameTypes.doubleChance;
}

/**
 * @param {Object} market
 * @return {boolean}
 */
export function isVirtualMarketBeggeHold(market) {
    return (
        market.gameType && market.gameType === virtualGameTypes.bothTeamsToScore
    );
}

/**
 * @param market {MarketModel}
 * @return {boolean}
 */
export function checkVirtualOverUnderByValue(market, value) {
    return market.gameType && market.gameType === value;
}

/**
 * @param virtualEvents {Object}
 * @return matchDay {Object}
 */
export const getMatchDay = (virtualEvents) => {
    let matchDay = 0;
    if (virtualEvents.fetched) {
        matchDay = get(virtualEvents, 'map[0].displayRoundId', 0);
    }

    return matchDay;
};
export const VIRTUAL_MARKET_GROUPS = {
    MOST_POPULAR_MARKETS: {
        label: 'Mest populære',
        markets: [
            virtualGameTypes._1X2,
            virtualGameTypes.doubleChance,
            virtualGameTypes.bothTeamsToScore,
            virtualGameTypes.redCard,
            virtualGameTypes.OU15,
            virtualGameTypes.OU25,
            virtualGameTypes.OU35,
            virtualGameTypes.OU45,
            virtualGameTypes.firstHalfMultiGoal,
        ],
    },
    HANDICAP: {
        label: 'Handicap',
        markets: [virtualGameTypes.winMargin],
    },
    HALF_FULL_TIME: {
        label: 'Halvleg / Fuldtid',
        markets: [virtualGameTypes.halfTimeFullTime],
    },
    DOUBLE_CHANCE_OU: {
        label: 'Double chance & Over/Under',
        markets: [
            virtualGameTypes.doubleChance,
            virtualGameTypes.doubleChanceOU25,
            virtualGameTypes.doubleChanceOU15,
            virtualGameTypes.doubleChanceGgNg,
            virtualGameTypes._1X2OU25,
            virtualGameTypes._1X2OU15,
            virtualGameTypes._1X2GgNg,
        ],
    },
    BOTH_TO_SCORE_MULTIGOAL: {
        label: 'Mål, Hjemmehold, Udehold',
        markets: [
            virtualGameTypes.bothTeamsToScore,
            virtualGameTypes.awayMultigoal,
            virtualGameTypes.homeMultigoal,
            virtualGameTypes.multiGoals,
        ],
    },
    CORRECT_SCORE: {
        label: 'Korrekt score',
        markets: [virtualGameTypes.correctScore, virtualGameTypes.exactGoals],
    },
    FIRST_GOAL: {
        label: 'Målscorer',
        markets: [virtualGameTypes.firstGoalScorer],
    },
    HOME_AWAY_OU: {
        label: 'Hjemmehold/Udehold & Over/Under',
        markets: [
            virtualGameTypes.awayOU25,
            virtualGameTypes.awayOU15,
            virtualGameTypes.awayOU05,
            virtualGameTypes.homeOU25,
            virtualGameTypes.homeOU15,
            virtualGameTypes.homeOU05,
        ],
    },
};

export const getBetSelection = (item) => {
    if (item.betText && item.betNr) {
        let betTextFormatted = performTerminalBetText(item.betText, item);
        return oddsName(betTextFormatted);
    } else {
        let isVirtual = !!(item.eventName && item.eventName.split(' v ')[1]);
        let selections = '';
        if (isVirtual) {
            selections =
                item.selectionText === '1'
                    ? item.eventName.split(' v ')[0]
                    : item.selectionText === '2'
                        ? item.eventName.split(' v ')[1]
                        : item.selectionText.toLowerCase() === '3'
                            ? 'Uafgjort'
                            : oddsName(item.selectionText);
        } else {
            selections = item.bet
                ? ['1', '3'].indexOf(item.bet) > -1
                    ? item.eventName.split(isVirtual ? ' v ' : ' - ')[
                          item.bet - 1
                      ]
                    : item.bet.toLowerCase() === 'x'
                        ? 'Uafgjort'
                        : item.bet
                : ['1', '3'].indexOf(item.selectionText) > -1
                    ? item.eventName.split(isVirtual ? ' v ' : ' - ')[
                          item.selectionText - 1
                      ]
                    : item.selectionText.toLowerCase() === 'x'
                        ? 'Uafgjort'
                        : oddsName(item.selectionText);
        }
        return selections;
    }
};
