import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
    Login as LoginDesktop,
    WithClearSafariPageCache,
} from '@it25syv/25syv-ui';
import { scrollToTop } from 'utils/UiHelper';
import {
    showModal as ShowModal,
    hideModal as HideModal,
} from '../actions/UiActions';
import { getCookie } from '../utils/Helper';
import { saveLS } from '../features/PersistentStorage/localStor';
import { login } from 'actions/AuthActions';
const stayOnPageLinks = ['/sport/big9/spil'];

const LoginModalDesk = ({
    serverConfig,
    showModal,
    hideModal,
    Login,
    modalData = {},
    cameFrom,
}) => {
    const stayOnPage = Boolean(modalData?.stayOnPage);
    const currentLocation = browserHistory.getCurrentLocation()?.pathname;
    const stayOnPrevPage =
        (stayOnPage && currentLocation !== '/sport/log-ind') ||
        (stayOnPageLinks.includes(currentLocation) &&
            currentLocation !== '/sport/log-ind');

    const onCloseModal = modalData?.onCloseModal;
    const redirectBackPath = modalData?.redirectBackPath;
    // this parameter means that modal should be auto closed if onCloseModal handler was called
    const onCloseModalAutoHide = modalData?.onCloseModalAutoHide;
    const isItInitialLogin = getCookie('isItInitialLogin');

    useEffect(() => {
        scrollToTop();
        redirectGeoDataBlocked();
    }, []);

    const closePopup = () => {
        if (onCloseModal) {
            onCloseModal();
            // close the modal automatically so onCloseModal implementation won't need calling hideModal itself
            onCloseModalAutoHide && hideModal('LOGIN');
        } else {
            if (
                window.location.pathname.split('/')[1] === 'log-ind' ||
                window.location.pathname.split('/')[2] === 'log-ind'
            ) {
                hideModal('LOGIN');
                browserHistory.push('/sport/');
            } else {
                hideModal('LOGIN');
            }
        }
        localStorage.removeItem('stay_on_prev_page');
    };

    const beforeLogin = () => {
        if (redirectBackPath) {
            return localStorage.setItem('stay_on_prev_page', redirectBackPath);
        }

        const pathname = window.location.pathname;
        const isOnLoginPath = pathname === '/sport/log-ind';
        const cyclicRedirect = pathname === cameFrom;

        const currentPage = isOnLoginPath
            ? cyclicRedirect
                ? '/'
                : cameFrom
            : pathname;

        localStorage.setItem('stay_on_prev_page', currentPage);
    };

    const isBlockedByGeoData = () => {
        return serverConfig.geoFetched && !serverConfig.geoData.allowed;
    };

    const redirectGeoDataBlocked = () => {
        if (isBlockedByGeoData()) {
            browserHistory.replace('/');
            showModal('GEO_BLOCK');
        }
    };

    const loginHandler = (userName, password) => {
        if (userName && password) {
            Login(userName, password, stayOnPrevPage);

            saveLS({ userName: userName });
        }
    };

    const forgotPassHandler = () => {
        hideModal('LOGIN');
        browserHistory.push('/sport/forgot-password');
    };

    return (
        <LoginDesktop
            closeAction={closePopup}
            beforeLogin={beforeLogin}
            site={'sport'}
            loginHandler={loginHandler}
            isMitIdLogin={!isItInitialLogin}
            forgotPassHandler={forgotPassHandler}
            showResetPassword
        />
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    ui: state.ui,
    serverConfig: state.serverConfig,
});

function mapDispatchToProps(dispatch) {
    return {
        showModal: bindActionCreators(ShowModal, dispatch),
        hideModal: bindActionCreators(HideModal, dispatch),
        Login: bindActionCreators(login, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithClearSafariPageCache(LoginModalDesk));
