import axios from 'axios';
import qs from 'qs';
let SpilIdService;

export function post(method, data) {
    return request('post', window.config.baseCmsPathSpilID + method, data);
}

export function get(method, data) {
    return request('get', window.config.baseCmsPathSpilID + method, data);
}

function request(requestType, url, data) {
    let options = {
        method: requestType,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url,
    };

    if (requestType === 'get') {
        options.params = data;
    }

    return axios(options).then((res) => res.data);
}

SpilIdService = {
    getSpilIdCardInfo: (params) =>
        post('/cafe/rest/game-card/get-card', params),

    createSpilIdCard: (params) =>
        post('/cafe/rest/game-card/create-card', params),

    orderSpilIdPhysicalCard: (params) =>
        post('/cafe/rest/game-card/physical-card-request', params),
};

export { SpilIdService };
