import React from 'react';
import {
    CloseModalBtn,
    CommonDesktopModalLayer,
    CommonDesktopModalWrapper,
} from 'ui/DesktopModalWrapper';
import FlexWrapper from 'ui/FlexWrapper';

export default class ModalContainer extends React.Component {
    constructor() {
        super();

        this.state = {
            desktopModal: false,
            closeModalHandler: false,
        };
    }

    useDesktopModal = () => {
        this.setState({
            desktopModal: true,
        });
    };

    useCustomCloseModalHandler = (handler) => {
        this.setState({
            closeModalHandler: handler,
        });
    };

    componentWillMount() {
        if (
            this.props.modalData &&
            this.props.modalData.useCustomCloseModalHandler
        ) {
            this.useCustomCloseModalHandler(
                this.props.modalData.useCustomCloseModalHandler
            );
        }
    }

    componentDidMount() {
        window.document.body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
        window.document.body.style.overflow = 'auto';
    }

    render() {
        const ModalComponent = this.props.ModalComponent;

        return (
            <CommonDesktopModalLayer bg={this.props.modalData?.bg}>
                {!this.state.desktopModal ? (
                    <ModalComponent
                        {...this.props.actions}
                        modalData={this.props.modalData}
                        cameFrom={this.props.cameFrom}
                        useDesktopModal={this.useDesktopModal}
                        enableCloseButton={() => null}
                        useCustomCloseModalHandler={
                            this.state.closeModalHandler
                        }
                    />
                ) : (
                    <FlexWrapper>
                        <CommonDesktopModalWrapper>
                            <CloseModalBtn
                                onClick={
                                    !this.state.closeModalHandler
                                        ? this.props.actions.hideModal.bind(
                                              this,
                                              this.props.id
                                          )
                                        : this.state.closeModalHandler
                                }
                            />
                            <ModalComponent
                                {...this.props.actions}
                                modalData={this.props.modalData}
                                useDesktopModal={this.useDesktopModal}
                                enableCloseButton={() => null}
                                useCustomCloseModalHandler={
                                    this.state.closeModalHandler
                                }
                                style={{
                                    maxWidth: '1400px',
                                    margin: '0px auto',
                                }}
                            />
                        </CommonDesktopModalWrapper>
                    </FlexWrapper>
                )}
            </CommonDesktopModalLayer>
        );
    }
}
