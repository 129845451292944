import styled, { css, keyframes } from 'styled-components';
import React from 'react';
import Text from 'ui/Text';

const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: scale(0);
        position:absolute;

    }
    100% {
        opacity: 1;
        transform: scale(1);
        position:relative;

    }
`;
const fadeOut = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0);
        position:absolute;

    }
`;
const Line = styled.div`
    width: 20px;
    height: 2px;
    background: white;
    border-radius: 2px;
    display: flex;
    transition: all 0.2s ease;
    opacity: 0.5;
`;
const MiddleLine = styled.div`
    position: relative;
    width: 100%;
    height: 2px;
    ${Line} {
        position: absolute;
    }
`;

const BurgerMenu = styled.div`
    width: 20px;
    height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    box-sizing: content-box;
    transition: all 0.2s ease;
`;

const StyledWrapper = styled.div`
    width: ${({ isOpen }) => (isOpen ? '250px' : '60px')};
    height: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 8px;
    box-sizing: border-box;
    .closed {
        animation: ${fadeOut} 0.1s linear;
        animation-fill-mode: forwards;
    }
    .open {
        animation: ${fadeIn} 0.1s linear;
        animation-fill-mode: forwards;
    }
`;
const BurgerMenuWrapper = styled.div`
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    transition: all 0.2s ease;
    border-radius: 5px;

    ${({ isOpen }) =>
        css`
            padding: ${isOpen ? '5px 15px' : '0.8rem'};
            justify-content: ${isOpen ? 'start' : 'center'};

            ${BurgerMenu} {
                ${isOpen && 'margin-right: 20px'};
            }
            ${Text} {
                color: white;
            }
            ${Line} {
                align-self: ${isOpen ? 'end' : 'start'};
            }
            ${MiddleLine} {
                ${Line}:first-child {
                    width: 8px;
                    opacity: 0;
                    ${isOpen ? 'left: 0' : 'right: 0'};
                }
                ${Line}:last-child {
                    width: 8px;
                    opacity: 0;
                    ${isOpen ? 'left: 0' : 'right: 0'};
                }
            }
            &:hover {
                background: #20486f;
                ${Line} {
                    opacity: 1;
                }
                ${BurgerMenu} {
                    & > ${Line} {
                        width: 12px;
                    }
                }
                ${MiddleLine} {
                    ${Line}:first-child {
                        transform: rotate(${isOpen ? '135deg' : '45deg'});
                        ${isOpen ? 'right: 15px' : 'left: 15px'};
                        ${isOpen && 'left: auto'};
                        top: -2px;
                        opacity: 1;
                    }
                    ${Line}:last-child {
                        transform: rotate(${isOpen ? '-135deg' : '-45deg'});
                        ${isOpen ? 'right: 15px' : 'left: 15px'};
                        ${isOpen && 'left: auto'};
                        top: 2px;
                        opacity: 1;
                    }
                }
            }
        `};
`;

const FancyBurger = ({ isOpen = false, onClickHandler = () => {} }) => {
    return (
        <StyledWrapper isOpen={isOpen} data-tip="Menu">
            <BurgerMenuWrapper onClick={onClickHandler} isOpen={isOpen}>
                <BurgerMenu>
                    <Line />
                    <MiddleLine>
                        <Line />
                        <Line />
                        <Line />
                    </MiddleLine>
                    <Line />
                </BurgerMenu>
                <Text className={isOpen ? 'open' : 'closed'}>Menu</Text>
            </BurgerMenuWrapper>
        </StyledWrapper>
    );
};

export default FancyBurger;
