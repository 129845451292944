import { formatDate } from 'utils/UiHelper';
import { NotificationManager } from 'react-notifications';
import React from 'react';

const statuses = {
    '-1': 'pending',
    1: 'lost',
    2: 'won',
    4: 'cancel',
};

export const systemNames = [
    'null',
    'Single',
    'Double',
    'Triple',
    'Firlinge',
    'Femlinge',
    'Sekslinge',
    'Syvlinge',
    'Ottelinge',
    'Nilinge',
    'Tilinge',
    'Ellevelinge',
    'Tolvlinge',
    'Trettelinge',
    'Fjortenlinge',
    'Femtenlinge',
];

export const getStatus = (bet) => {
    return statuses[bet.status] || null;
};

export const getStatusIcon = (bet) => {
    let icon = null;
    if (statuses[bet.status]) {
        icon =
            statuses[bet.status] === statuses['1'] ? (
                <i className="fa fa-times" aria-hidden="true" />
            ) : statuses[bet.status] === statuses['2'] ? (
                <i className="fa fa-check" aria-hidden="true" />
            ) : null;
    }
    return icon ? icon : null;
};

export const formatSaleTitle = (sale) => {
    return `ID: ${sale.id} - ${formatDate(
        sale.saleDate,
        'DD. MMMM [kl.] HH:mm'
    )}`;
};

export const showInfo = (text, e) => {
    NotificationManager.warning('', text, 7000);
    e.stopPropagation();
    e.preventDefault();
};

export const formatBetMarketLabel = (bet) => {
    if (
        bet.extraInfo &&
        ['Afleveringer', 'Tacklinger'].some((marketType) =>
            bet.marketName.startsWith(marketType)
        )
    ) {
        return `Minimum ${bet.extraInfo} afleveringer`;
    }
    return `${bet.marketTypeName === 'Asian' ? `${bet.marketTypeName} ` : ''} ${
        bet.marketName
    }`.trim();
};

export const getTotalOdds = (sale, isCustomBet) => {
    return sale.coupons[0].odds.toFixed(2);
};
