import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import BetslipService from 'utils/BetslipService';
import ViewSpinner from 'components/ViewSpinner';
import 'css/BetslipNotification.css';

const BetslipNotification = ({ betslip, routing }) => {
    const dontShowBetslipOnThisPages = () => {
        return (
            routing.locationBeforeTransitions.pathname === '/sport/kupon' ||
            routing.locationBeforeTransitions.pathname === '/sport/big9/spil' ||
            routing.locationBeforeTransitions.pathname
                .split('/')
                .includes('konto')
        );
    };

    const [showBetslip, setShowBetslip] = useState(
        !dontShowBetslipOnThisPages()
    );

    useEffect(
        () => {
            setShowBetslip(!dontShowBetslipOnThisPages());
        },
        [routing.locationBeforeTransitions.pathname]
    );

    const onClickHandler = () => {
        browserHistory.push('/sport/kupon');
    };

    const systemNames = BetslipService.getSystemNamesOdds();

    if (betslip.pending) {
        return (
            <section className="betslip-notification" onClick={onClickHandler}>
                <ViewSpinner />
            </section>
        );
    } else if (showBetslip) {
        return (
            <section
                className={`betslip-notification`}
                onClick={onClickHandler}
            >
                <div className="row">
                    <div className="col-4">
                        <div
                            className={`coupon-number ${
                                betslip.isCustomBet ? 'custom-bet' : ''
                            } `}
                        >
                            KUPON (
                            <span className="coupon-number-value">
                                {betslip.betList.length}
                            </span>
                            )
                        </div>
                    </div>
                    <div className="col-6">
                        <div
                            className={`coupon-label ${
                                betslip.isCustomBet ? 'custom-bet' : ''
                            } `}
                        >
                            {betslip.isCustomBet
                                ? 'ODDS '
                                : betslip.betList.length <= 3
                                    ? systemNames[
                                          betslip.betList.length - 1
                                      ].toUpperCase() + ': ODDS '
                                    : 'ODDS '}
                            <span>{betslip.totalOdds.toFixed(2)}</span>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else {
        return false;
    }
};

function mapStateToProps(state) {
    return { betslip: state.betslip, routing: state.routing };
}

export default connect(
    mapStateToProps,
    null
)(BetslipNotification);
