import SectionUserArea from 'containers/SectionUserArea';
import LoginFirstPopup from 'modals/LoginFirstPopup';
import SessionPopup from 'modals/SessionPopup';
import DepositLimitReached from 'modals/DepositLimitReached';
import LowBalanceModal from 'modals/LowBalanceModal';
import TransferRetailModal from 'modals/TransferRetailModal';
import SelfExclusionPopup from 'modals/SelfExclusionPopup';
import ConfirmPopup from 'components/ConfirmPopup';
import PinAdvicePopup from 'modals/PinAdvicePopup';
import GeoBlockModal from 'modals/GeoBlockModal';
import SignupPopup from 'modals/SignupPopup';
import EmailAlreadyExistPopup from 'modals/EmailAlreadyExistPopup';
import LoginModalDesk from 'modals/LoginModal';
import CPRConfirmModal from 'modals/CPRConfirmModal';
import ReceiptPopup from 'modals/ReceiptPopup';
import SportRadarWidgetPopup from 'modals/SportRadarWidgetPopup';
import SKConfirmationModal from 'features/SpilKlub/components/SKConfirmationModal';
import RemoveBonusConfirmModal from 'modals/RemoveBonusConfirmModal';
import SpilIdModal from 'features/SpilID/SpilIdModal';
import PhysicalCardPreview from 'features/SpilID/PhysicalCardPreview';
import PhysicalCardForm from 'features/SpilID/PhysicalCardForm';
import SpilIdDescriptionModal from 'features/SpilID/SpilIdDescriptionModal';
import AccountExcludedPermanently from '../modals/AccountExcludedPermanently';
import FastDepositLimitsModal from '../modals/FastDepositLimitsModal';
import PinModal from 'features/LoginMobile/PinModal';
import LoginForgotPass from '../modals/LoginForgotPass';
import RestoreUserPassword from '../modals/RestoreUserPassword';
import ChooseDateModal from '../modals/ChooseDateModal';
import TransactionDetailsModal from '../modals/TransactionDetailsModal';
import TransferMoneyNotificationModal from '../modals/TranferMoneyNotificationModal';

export const MapConfig = {
    USER_MENU: SectionUserArea,
    LOGIN_FIRST: LoginFirstPopup,
    SESSION_POPUP: SessionPopup,
    DEPOSIT_LIMIT_REACHED: DepositLimitReached,
    LOW_BALANCE: LowBalanceModal,
    TRANSFER_RETAIL: TransferRetailModal,
    SELF_EXCLUSION_POPUP: SelfExclusionPopup,
    CONFIRM_POPUP: ConfirmPopup,
    PIN_ADVICE_POPUP: PinAdvicePopup,
    GEO_BLOCK: GeoBlockModal,
    LOGIN: LoginModalDesk,
    FORGOT_PASSWORD: LoginForgotPass,
    RESTORE_PASSWORD: RestoreUserPassword,
    SIGNUP_MODAL: SignupPopup,
    EMAIL_EXIST: EmailAlreadyExistPopup,
    CPR_CONFIRMATION: CPRConfirmModal,
    RECEIPT_POPUP: ReceiptPopup,
    SPORT_RADAR_WIDGET_POPUP: SportRadarWidgetPopup,
    SK_CONFIRMATION_MODAL: SKConfirmationModal,
    REMOVE_BONUS_CONFIRM_MODAL: RemoveBonusConfirmModal,
    SPIL_ID_MODAL: SpilIdModal,
    SPIL_ID_PC_PREVIEW: PhysicalCardPreview,
    SPIL_ID_PC_FORM: PhysicalCardForm,
    SPIL_ID_DESCRIPTION_MODAL: SpilIdDescriptionModal,
    ACCOUNT_EXCLUDED_PERMANENTLY: AccountExcludedPermanently,
    FAST_DEPOSIT_LIMIT: FastDepositLimitsModal,
    PIN_LOGIN: PinModal,
    CHOSE_DATE_MODAL: ChooseDateModal,
    TRANSITION_DETAILS_MODAL: TransactionDetailsModal,
    TRANSFER_MONEY_NOTIFICATION_MODAL: TransferMoneyNotificationModal,
};
