import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ViewSpinner from 'components/ViewSpinner';
import { UI_SUCCESS_MESSAGE } from 'constants/ui';
import * as UiActions from 'actions/UiActions';
import { pinLogin as PinLogin } from 'actions/AuthActions';
import AuthService from 'utils/AuthService';
import { LOCAL_STORAGE_KEYS } from 'constants/appConfig';
import {
    PinCloseButton,
    PinIndicator,
    PinIndicatorsWrapper,
    PinKeyboard,
    PinKeyboardButton,
    StyledLoginMobileWrapper,
    TopWrapper,
} from 'features/LoginMobile/styled';
import Text from 'ui/Text';
import Button from 'ui/Button';
import { disableBodyScroll, enableBodyScroll } from 'utils/UiHelper';
import { browserHistory } from 'react-router';
import backspaceImg from 'images/backspace.svg';
import { HR } from '../../components/big9/styled';
import { changeStatusForForgotPassword as ChangeStatusForForgotPassword } from 'actions/AuthActions';

const PIN_LENGTH = 4;
const PIN_BUTTONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, '', 0];

const PinModal = (props) => {
    const [pinValues, setPinValues] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const isSpilId = props?.modalData?.isSpilId || false;
    const titleText = isSpilId
        ? 'Skift kode'
        : props?.modalData?.mode === 'restore-pin'
            ? 'Opret PIN kode'
            : 'Log ind med PIN kode';

    const stayOnPage = props?.modalData?.stayOnPage;
    // stayOnPage works only when log in modal don't pushes its path to history
    // redirectBackPath may also help for some specific paths to redirect after login
    const redirectBackPath = props?.modalData?.redirectBackPath;

    const onCloseModalHandler = props?.modalData?.onCloseModal;
    const onCommonLoginCloseModal = props?.modalData?.onCommonLoginCloseModal;
    // this parameter means that modal should be auto closed if onCloseModal handler was called
    const onCloseModalAutoHide = props?.modalData?.onCloseModalAutoHide;

    useEffect(() => {
        disableBodyScroll();
        return () => {
            enableBodyScroll();
        };
    }, []);

    useEffect(
        () => {
            const pin = pinValues.join('');
            if (pin.length === PIN_LENGTH) {
                if (isSpilId) {
                    AuthService.SetRetailPin(pin).then((res) => {
                        if (res.success) {
                            props.uiActions.hideModal('PIN_LOGIN');
                            props.uiActions.showModal('SPIL_ID_MODAL');
                            props.uiActions.notification(
                                UI_SUCCESS_MESSAGE,
                                'Din pinkode er nu ændret'
                            );
                        } else {
                            props.uiActions.notifyError(res.errorMessage);
                        }
                    });
                } else {
                    if (
                        props?.modalData?.mode === 'setup' ||
                        props?.modalData?.mode === 'restore-pin'
                    ) {
                        setIsPending(true);

                        AuthService.CreateHash(pin).then((res) => {
                            if (res.data) {
                                if (res.success) {
                                    window.localStorage.setItem(
                                        LOCAL_STORAGE_KEYS.PIN_TOKEN,
                                        res.data
                                    );
                                    window.localStorage.setItem(
                                        LOCAL_STORAGE_KEYS.PIN_USERNAME,
                                        props.auth.user.userName
                                    );

                                    props?.modalData?.onPinLoginEnter
                                        ? props?.modalData?.onPinLoginEnter()
                                        : props.uiActions.hideModal(
                                              'PIN_LOGIN'
                                          );

                                    props.uiActions.notification(
                                        UI_SUCCESS_MESSAGE,
                                        'Din PIN-kode er nu oprettet'
                                    );
                                }
                            } else {
                                props.uiActions.notifyError(res.errorMessage);
                            }
                        });
                    } else {
                        const token = localStorage.getItem(
                            LOCAL_STORAGE_KEYS.PIN_TOKEN
                        );
                        const userName = localStorage.getItem(
                            LOCAL_STORAGE_KEYS.PIN_USERNAME
                        );
                        props.pinLogin(pin, token, userName);

                        props?.modalData?.onPinLoginEnter
                            ? props?.modalData?.onPinLoginEnter()
                            : props.uiActions.hideModal();

                        if (!stayOnPage) {
                            browserHistory.push(redirectBackPath || '/sport/');
                        }
                    }
                }
            }
        },
        [pinValues.length]
    );

    const setPinDigit = (val) => {
        if (val === 0 || val > 0) {
            if (pinValues.length === PIN_LENGTH) {
                return;
            }
            setPinValues([...pinValues, val]);
        }
    };

    const cleanPinDigit = () => {
        setPinValues(pinValues.slice(0, pinValues.length - 1));
    };

    const renderPinIndicators = () => {
        return new Array(PIN_LENGTH)
            .fill(null)
            .map((_, index) => (
                <PinIndicator
                    key={index}
                    isCurrent={pinValues.length === index}
                    filled={pinValues.length >= index + 1}
                    className={isSpilId && 'spillId'}
                />
            ));
    };

    const onCloseModal = () => {
        if (isSpilId) {
            props.hideModal('PIN_LOGIN');
            props.uiActions.showModal('SPIL_ID_MODAL');
        } else {
            const loginModalData = {
                stayOnPage,
                redirectBackPath,
                onCloseModal: onCommonLoginCloseModal,
                onCloseModalAutoHide,
            };

            if (onCloseModalHandler) {
                onCloseModalHandler();
                if (onCloseModalAutoHide) {
                    props.hideModal('PIN_LOGIN');
                    props.showModal('LOGIN_MOBILE', loginModalData);
                }
            } else if (props.hideModal) {
                props.hideModal('PIN_LOGIN');
                if (props?.modalData?.mode !== 'setup') {
                    props.showModal('LOGIN_MOBILE', loginModalData);
                }
            }
        }
    };

    const redirectToForgotPassword = () => {
        props.hideModal('PIN_LOGIN');
        props.hideModal('LOGIN_MOBILE');
        setTimeout(() => {
            browserHistory.push('/sport/forgot-password');
        }, 10);
    };

    return (
        <StyledLoginMobileWrapper
            jContent={isSpilId ? 'space-between' : null}
            padding={isSpilId ? '24px 0 16px' : null}
            zIndex="11"
            bg={isSpilId ? '#F5F7FC' : null}
        >
            <TopWrapper>
                {(props.auth.loginPending || isPending) && <ViewSpinner />}
                {props?.modalData?.mode !== 'restore-pin' && (
                    <PinCloseButton
                        margin="0 0 0 auto"
                        className={isSpilId && 'spilId'}
                        onClick={onCloseModal}
                    >
                        {isSpilId ? (
                            <i
                                className="fa fa-chevron-left"
                                aria-hidden="true"
                            />
                        ) : (
                            <i className="fa fa-times" aria-hidden="true" />
                        )}
                    </PinCloseButton>
                )}

                <Text
                    bold
                    size={isSpilId ? '20' : '28'}
                    center
                    color={isSpilId ? '#333' : '#fff'}
                    lh="33"
                    margin={isSpilId ? '0' : '20px auto'}
                    padding={isSpilId ? '0 0 16px' : '0'}
                >
                    {titleText}
                </Text>
                {isSpilId && <HR />}
                {isSpilId && (
                    <Text
                        height={'auto'}
                        width={'100%'}
                        display={'block'}
                        flex={'auto'}
                        margin={'35px 0 0'}
                        padding={'0 32px'}
                        size={'16'}
                        lh={'24'}
                        align={
                            props.modalData.retailPin > 0 ||
                            `${props.modalData?.retailPin}` === `0000`
                                ? 'center'
                                : 'left'
                        }
                        boxSizing={'border-box'}
                    >
                        {props.modalData?.retailPin > 0 ||
                        `${props.modalData?.retailPin}` === `0000`
                            ? 'Vælg ny kode'
                            : 'Din midlertidige pinkode til dit spil-id er udløbet. Vælg venligst en ny pinkode.'}
                    </Text>
                )}
                <PinIndicatorsWrapper margin={isSpilId ? '54px 0 0 ' : '0'}>
                    {renderPinIndicators()}
                </PinIndicatorsWrapper>
                {props?.modalData?.mode !== 'restore-pin' &&
                props?.modalData?.mode !== 'setup' &&
                !isSpilId ? (
                    <Button
                        grey
                        width="100%"
                        color="#333333"
                        fontSize="18px"
                        fontWeight="bold"
                        margin="20px auto 0"
                        padding="20px 0"
                        onClick={redirectToForgotPassword}
                    >
                        Glemt kodeord?
                    </Button>
                ) : null}
            </TopWrapper>
            <PinKeyboard padding={'0 16px'}>
                {PIN_BUTTONS.map((el, index) => (
                    <PinKeyboardButton
                        key={index}
                        onClick={() => {
                            setPinDigit(el);
                        }}
                        bg={'#fff'}
                    >
                        {el}
                    </PinKeyboardButton>
                ))}
                <PinKeyboardButton bg={'#fff'} onClick={cleanPinDigit}>
                    <img
                        src={backspaceImg}
                        alt="backspaceImg"
                        width="35px"
                        height="29px"
                    />
                </PinKeyboardButton>
            </PinKeyboard>
        </StyledLoginMobileWrapper>
    );
};

function mapStateToProps(state) {
    return { auth: state.auth };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
        pinLogin: bindActionCreators(PinLogin, dispatch),
        changeStatusForForgotPassword: bindActionCreators(
            ChangeStatusForForgotPassword,
            dispatch
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PinModal);
