import React from 'react';
import UIModalNew from 'components/UIModalNew';

const withPopupWrapper = (WrappedComponent) => (props) => {
    return (
        <UIModalNew
            noPadding
            onCloseModal={() => {
                props.hideModal();
            }}
            maxWidth={'450px'}
        >
            <WrappedComponent {...props} />;
        </UIModalNew>
    );
};

export default withPopupWrapper;
