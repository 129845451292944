import { EVENT_STATUS, MARKET_STATUS, SPORTS } from 'constants/appConfig';
import * as patterns from '../constants/patterns';
import UserService from 'utils/UserService';
import { formatDate } from 'utils/UiHelper';
import { NotificationManager } from 'react-notifications';
import { AUTHORIZED_PAGES } from '../constants/AuthorizedPages';
import { ParsproService } from './ParsproService';
import { trackEvents } from '../helpers/VaixHelpers/VaixTracking';
import {
    VAIX_TRACKING_LOCATION_FOR_TYPE_OF_SPORT,
    VAIX_TRACKING_TYPE,
} from '../constants/vaix/VaixTrcakingConstants';

export function isManagerEntrance() {
    return window.location.hostname === 'terminal.cafe';
}

export const checkURL = (urlPart) => {
    return window.location.pathname.includes(urlPart);
};

export function formatLabel(str) {
    str = '' + str;

    if (str.indexOf('Under') === -1 && str.indexOf('Over') === -1) {
        if (str.indexOf(',') > -1) {
            str = str.split(',');

            return str[1] + ' ' + str[0];
        } else {
            return str;
        }
    } else {
        return str;
    }
}

export function formatPlayerName(name) {
    if (name) {
        if (name.indexOf(', ') === -1) {
            return name;
        } else {
            const tmp = name.split(', ');

            return tmp[1] + ' ' + tmp[0];
        }
    } else {
        return name;
    }
}

function AmericanFootballGroupNameFormat(marketName) {
    switch (marketName) {
        case 'Scorer i kampen':
            return 'Scorer et touchdown i kampen';
        case 'Scorer kampens sidste mål':
            return 'Scorer sidste touchdown';
        case 'Scorer første mål':
            return 'Scorer første touchdown';
        case 'Første målscorer':
            return 'Scorer første touchdown';
        case 'Sidste målscorer':
            return 'Scorer sidste touchdown';
        default:
            return marketName;
    }
}

export function formatGroupLabel(market, eventObj) {
    if (!market) return '';

    let extraInfo = market && market.extraInfo ? market.extraInfo : null;
    let marketName = market.marketName || market.gameName || '';
    let newInfo = null;

    if (market.sportId === SPORTS.AMERIKANSK_FODBOLD) {
        marketName = AmericanFootballGroupNameFormat(marketName);
    }

    if (marketName.toLowerCase().indexOf('handicap') === -1) {
        if (eventObj && eventObj.sportId === SPORTS.TENNIS) {
            return marketName
                .replace('Spiller 1', formatPlayerName(eventObj.homeTeam))
                .replace('Spiller 2', formatPlayerName(eventObj.awayTeam));
        } else if (eventObj && eventObj.sportId === SPORTS.HANDBALL) {
            return marketName
                .replace('AT', 'Udehold')
                .replace('HT', 'Hjemmehold');
        } else {
            return marketName;
        }
    }

    if (!extraInfo) {
        let re = marketName.match(/Handicap (.*)/);
        if (re == null) {
            return marketName;
        }
        extraInfo = re[1]
            .replace('Partier', '')
            .replace('Frame', '')
            .replace('Sæt', '');
    }

    if (market.marketTypeGroupName === 'Asian') {
        if (['1', 'X', '2'].indexOf(extraInfo) === -1) {
            if (extraInfo === '0') {
                newInfo = '-0 +0';
            } else {
                if (extraInfo.indexOf('-') === 0) {
                    if (extraInfo.indexOf('.') !== -1) {
                        let extraInfoHandicap = extraInfo.substring(1);
                        newInfo =
                            '-' + extraInfoHandicap + ' +' + extraInfoHandicap;
                    } else {
                        let extraInfoHandicap = extraInfo.replace('-', '');
                        newInfo =
                            '-' + extraInfoHandicap + ' +' + extraInfoHandicap;
                    }
                } else {
                    let extraInfoHandicap = extraInfo;
                    newInfo =
                        '+' + extraInfoHandicap + ' -' + extraInfoHandicap;
                }
            }

            return marketName.replace(
                'Handicap ' + extraInfo,
                'Handicap ' + newInfo
            );
        } else {
            return marketName;
        }
    } else {
        if (extraInfo.indexOf('-') === 0) {
            if (extraInfo.indexOf('.') !== -1) {
                let extraInfoHandicap = extraInfo.substring(1);
                newInfo =
                    '(-' + extraInfoHandicap + ' +' + extraInfoHandicap + ')';
            } else {
                newInfo = '(0' + extraInfo + ')';
            }
        } else if (extraInfo.indexOf(':') !== -1) {
            newInfo = extraInfo.replace(':', '-');
        } else if (extraInfo.indexOf('-') === -1) {
            if (extraInfo.indexOf('.') !== -1) {
                newInfo = '+' + extraInfo + ' -' + extraInfo;
            } else {
                newInfo = '(' + extraInfo + '-0)';
            }
        }

        if (
            market.marketName &&
            market.marketName.includes('Handicap hjørnespark')
        ) {
            return marketName.replace(
                'Handicap hjørnespark ' + extraInfo,
                'Handicap hjørnespark ' + newInfo
            );
        }

        if (
            market.marketName &&
            market.marketName.includes('Hjørnespark handicap')
        ) {
            return market.marketName.replace(
                'Hjørnespark handicap ' + extraInfo,
                'Hjørnespark handicap ' + newInfo
            );
        }

        return marketName.replace(
            'Handicap ' + extraInfo,
            'Handicap ' + newInfo
        );
    }
}

export function shortHandicap(market, bet, short = true, fromBetslip) {
    if (!market) return '';
    let extraInfo = market && market.extraInfo ? market.extraInfo : null;
    let marketName = market.marketName || 'n/a';
    let isSeat = false;
    let isParti = false;
    let isFrame = false;

    if (market.sportId === SPORTS.AMERIKANSK_FODBOLD) {
        marketName = marketName = AmericanFootballGroupNameFormat(marketName);
    }

    marketName = marketName.replace('Halvleg -', 'Halvleg ');
    marketName = marketName.replace('Quarter -', 'Quarter ');

    let newInfo = null;

    if (marketName.indexOf('Handicap') === -1) {
        return marketName;
    }

    if (!extraInfo) {
        let re = marketName.match(/Handicap (.*)/);
        if (re == null) {
            return marketName;
        }
        extraInfo = re[1]
            .replace('Partier', '')
            .replace('Frame', '')
            .replace('Sæt', '');
    }

    if (extraInfo.indexOf(':') > -1) {
        marketName = marketName
            .replace(' - 1.Halvleg', '')
            .replace(' - 2.Halvleg', '')
            .trim();

        return (
            '(' +
            marketName.replace(
                'Handicap ' + extraInfo,
                extraInfo.replace(':', '-')
            ) +
            ')'
        );
    }

    if (marketName.indexOf('Sæt') > -1 && market.marketTypeGroup !== 15) {
        isSeat = true;
        marketName = marketName.replace('Sæt', '');
    }

    if (marketName.indexOf('Partier') > -1) {
        isParti = true;
        marketName = marketName.replace('Partier', '');
    }

    if (marketName.indexOf('Frame') > -1) {
        isFrame = true;
        marketName = marketName.replace('Frame', '');
    }

    if (
        market.marketTypeName === '1X2 Handicap' &&
        extraInfo.indexOf('.') === -1
    ) {
        if (parseInt(extraInfo, 10) < 0) {
            newInfo = '(0' + extraInfo + ')';
        } else {
            newInfo = '(' + extraInfo + '-0)';
        }
    } else if (
        market.marketTypeGroupName &&
        !market.marketTypeGroupName.includes('Asian') &&
        !extraInfo.includes('.') &&
        !marketName.toLowerCase().includes('inkl. ot') &&
        !marketName.toLowerCase().includes('inkl ot')
    ) {
        if (parseInt(extraInfo, 10) < 0) {
            newInfo = '(0' + extraInfo + ')';
        } else {
            newInfo = '(' + extraInfo + '-0)';
        }

        return newInfo;
    } else {
        if (extraInfo.indexOf('-') === 0) {
            if (bet === '1') {
                newInfo = '(' + extraInfo + ')';
            } else {
                newInfo = '(+' + extraInfo.replace('-', '') + ')';
            }
        } else {
            if (bet === '2') {
                newInfo = '(-' + extraInfo + ')';
            } else {
                newInfo = '(+' + extraInfo.replace('-', '') + ')';
            }
        }

        if (isSeat) {
            newInfo = newInfo.replace(')', ' Sæt)');
        }

        if (isParti) {
            newInfo = newInfo.replace(')', ' Partier)');
        }

        if (isFrame) {
            newInfo = newInfo.replace(')', ' Frames)');
        }
    }

    if (
        newInfo.indexOf(' - 1.Halvleg') > -1 ||
        newInfo.indexOf(' - 2.Halvleg') > -1
    ) {
        newInfo = newInfo
            .replace(' - 1.Halvleg', '')
            .replace(' - 2.Halvleg', '');
    }

    if (newInfo.indexOf(' - 1.H') > -1 || newInfo.indexOf(' - 2.H') > -1) {
        newInfo = newInfo.replace(' - 1.H', '').replace(' - 2.H', '');
    }

    if (short) {
        let substr;
        if (marketName.toLowerCase().includes('handicap (inkl. ot)')) {
            substr = 'Handicap (Inkl. OT) ' + extraInfo;
        } else if (
            marketName
                .toLowerCase()
                .includes('handicap ' + extraInfo + '(inkl. ot)')
        ) {
            substr = 'Handicap ' + extraInfo + '(inkl. ot)';
        } else if (marketName.toLowerCase().includes('handicap (inkl ot)')) {
            substr = 'Handicap (Inkl. OT) ' + extraInfo;
        } else {
            substr = 'Handicap ' + extraInfo;
        }
        return marketName.replace(substr, newInfo);
    } else {
        return marketName.replace(
            'Handicap ' + extraInfo,
            'Handicap ' + newInfo
        );
    }
}

export function historyHandicap(market, bet, short = true) {
    if (!market) return '';
    let marketName = market.marketName;

    let eventName = marketName;
    if (marketName.indexOf(': ') > 2) {
        let tmp = marketName.split(': ');
        eventName = tmp[0];
        marketName = marketName.replace(eventName + ': ', '');
    }

    const index = bet - 1;

    if (market.sportName === 'American Football') {
        marketName =
            marketName === 'Scorer i kampen'
                ? 'Scorer et touchdown i kampen'
                : marketName;
        marketName =
            marketName === 'Scorer kampens sidste mål'
                ? 'Scorer sidste touchdown'
                : marketName;
        marketName =
            marketName === 'Scorer første mål'
                ? 'Scorer første touchdown'
                : marketName;
        marketName =
            marketName === 'Første målscorer'
                ? 'Scorer første touchdown'
                : marketName;
        marketName =
            marketName === 'Sidste målscorer'
                ? 'Scorer sidste touchdown'
                : marketName;
    }

    if (
        (market.marketTypeName === 'Asian' ||
            market.marketName.indexOf('Hjørnespark') > -1) &&
        market.marketName.indexOf('Handicap') > -1 &&
        market.marketTypeName.indexOf('1X2') === -1
    ) {
        if (market.betNr === 1) {
            return (
                (market.marketName.indexOf('Hjørnespark') > -1
                    ? 'Hjørnespark '
                    : '') +
                'Handicap (' +
                (market.extraInfo > 0
                    ? '+' + market.extraInfo
                    : market.extraInfo) +
                ') '
            );
        } else {
            return (
                (market.marketName.indexOf('Hjørnespark') > -1
                    ? 'Hjørnespark '
                    : '') +
                'Handicap (' +
                (market.extraInfo > 0
                    ? '-' + market.extraInfo
                    : '+' + (0 - parseFloat(market.extraInfo))) +
                ') '
            );
        }
    }

    if (['0', '1', '2'].indexOf(bet) > -1) {
        if (index === 0 || index === 1) {
            return marketName + ' - ' + eventName.split(' - ')[index];
        } else {
            return marketName + ' - ' + eventName;
        }
    } else {
        return marketName;
    }
}

export function normalizeStake(stake) {
    stake = stake.toString().replace(',', '.');
    if (isNaN(parseFloat(stake))) {
        stake = 0;
    } else {
        stake = parseFloat(stake);
    }
    return stake;
}

export function collectMarkets(data) {
    let groupedMarkets = {};

    if (data && data.marketList) {
        data.marketList.map((value) => {
            if (!groupedMarkets[value.marketTypeGroup]) {
                groupedMarkets[value.marketTypeGroup] = [];
            }

            if (
                value.marketType !== 2 ||
                (value.marketSubType !== 18 && value.marketType === 2)
            ) {
                groupedMarkets[value.marketTypeGroup].push(value);
            }

            return value;
        });
    }

    return groupedMarkets;
}

export function getErrorByCode(msg) {
    let t = msg || 'Error';

    switch (msg) {
        case 'EMAIL_WRONG':
            t = 'Forkert Email addres';
            break;
        case 'PASSWORDPOLICY_LOW':
            t = 'Din kode skal indeholde mindst 1 lille bogstav';
            break;
        case 'PASSWORDPOLICY_LEN':
            t = 'Din kode skal være mindst 8 karakterer';
            break;
        case 'PASSWORDPOLICY_NUM':
            t = 'Din kode skal indeholde mindst 2 tal';
            break;
        case 'PASSWORDPOLICY_UPP':
            t = 'Din kode skal indeholde mindst 1 stort bogstav';
            break;
        case 'DO_NOT_MATCH':
            t = 'Ny kode og gentagelse er ikke ens.';
            break;
        case 'PASSWORD_EMPTY':
            t = 'Indtast password';
            break;
        case 'PASSWOLDINCORRECT':
            t = 'Du angav det forkerte kode';
            break;
        default:
            t = msg;
    }

    return t;
}

export function performTerminalBetText(betText, item) {
    const isVirtual = !!(item.eventName && item.eventName.split(' v ')[1]);
    let performBetTextForVirtual = (item, position) =>
        item.eventName ? item.eventName.split(' v ')[position] : 'n/a';
    let performBetTextForReal = (item, position) =>
        item.eventName ? item.eventName.split(' - ')[position] : 'n/a';
    if (
        item.betText &&
        item.betText !== '1' &&
        item.betText !== 'X' &&
        item.betText !== '2'
    ) {
        return item.betText;
    } else if (item.betNr == 1 && item.betText === '1') {
        return isVirtual
            ? performBetTextForVirtual(item, 0)
            : performBetTextForReal(item, 0);
    } else if (item.betNr == 2 && item.betText === 'X') {
        return 'Uafgjort';
    } else if (item.betNr == 2 && item.betText === '2') {
        return isVirtual
            ? performBetTextForVirtual(item, 1)
            : performBetTextForReal(item, 1);
    } else if (
        item.betNr == 3 &&
        item.betText === '2' &&
        item.marketName.toLowerCase().indexOf('vinder') >= 0
    ) {
        return isVirtual
            ? performBetTextForVirtual(item, 1)
            : performBetTextForReal(item, 1);
    } else {
        return item.betText;
    }
}

export function formatSpillerPlayer(betText, homeTeam, awayTeam, item) {
    homeTeam = formatPlayerName(homeTeam);
    awayTeam = formatPlayerName(awayTeam);

    const marketName = item && item.marketName ? item.marketName : 'n/a';

    if (betText && betText.indexOf('Spiller 1 ') === 0) {
        betText = betText.replace('Spiller 1', homeTeam);
    }

    if (betText && betText.indexOf('Spiller 2 ') === 0) {
        betText = betText.replace('Spiller 2', awayTeam);
    }

    if (betText && betText.indexOf('Player 1 ') === 0) {
        betText = betText.replace('Player 1', homeTeam);
    }

    if (betText && betText.indexOf('Player 2 ') === 0) {
        betText = betText.replace('Player 2', awayTeam);
    }

    if (item) {
        let result = betText || '';

        if (
            item.marketTypeName &&
            item.marketTypeName.indexOf('Spiller - ') === 0 &&
            item.sportId === 1 &&
            (item.betText === '1' ||
                item.betText === '2' ||
                item.betText === '3')
        ) {
            return item.betText;
        }

        if (
            item.betText === 'Udehold' &&
            marketName.toLowerCase().indexOf('hold') > -1
        ) {
            result = 'Kun udehold';
        } else if (
            item.betText === 'Hjemmehold' &&
            marketName.toLowerCase().indexOf('hold') > -1
        ) {
            result = 'Kun hjemmehold';
        } else {
            result = result
                .replace('draw and over', 'Uafgjort og over')
                .replace('AT ', 'Udehold ')
                .replace('HT ', 'Hjemmehold ');

            if (
                marketName.toLowerCase().indexOf('antal') > -1 ||
                marketName.indexOf('Mål') > -1
            ) {
                result = item.betText;
            }
        }

        return result;
    }

    return betText;
}

export function detectError(errorMsg) {
    var err = null;

    switch (errorMsg) {
        case 'LOGINFIRST':
        case 'INVALIDSESSION':
            err = 'Du skal først logge ind';
            break;
        case 'STAKE_INVALID':
            err = 'Indsatsen er ugyldig';
            break;
        case 'ORA-20221':
        case 'NO_FREEBET':
            err = 'Freebet er ikke tilgængeligt';
            break;
        case 'ORA-20222':
        case 'ORA-20223':
            err = 'Dit freebet skal placeres';
            break;
        case 'DUPLICATE_MARKET':
        case 'ORA-20012':
            err = 'Komibinationen er ikke tilladt';
            break;
        case 'MARKET_LIMIT':
            err = 'Spillets grænse er overskredet';
            break;
        case 'MARKET_LIMIT_CUSTOM_BET':
            err =
                'Fejl Det maksimale antal udfald (10) for Byg væddemål er nået.';
            break;
        case 'ORA-20015':
            err = 'Maksimum mulig gevinst overskredet';
            break;
        case 'LIVEBET_ERROR_-10':
            err = 'En eller flere kampe er lukket for spil i øjeblikket';
            break;
        case 'PLACE_PAUSED_EVENT':
        case 'LIVEBET_ERROR_-1':
        case 'LIVEBET_ERROR_-2':
            err = 'Kampen er gået igang';
            break;
        case 'LIVEBET_ERROR_-3':
            err = 'Oddset har ændret sig';
            break;
        case 'ORA-20011':
        case 'LIVEBET_ERROR_-4':
        case 'LIVEBET_ERROR_-5':
        case 'LIVEBET_ERROR_-6':
        case 'LIVEBET_ERROR_-7':
        case 'LIVEBET_ERROR_-8':
            err = 'Lukket for spil i øjeblikket';
            break;
        case 'PLACE_CLOSED_MARKET':
        case 'LIVEBET_ERROR_-9':
            err = 'Kampen er lukket for spil';
            break;
        case 'LIVEBET_ERROR_-11':
            err = 'Linjen har ændret sig';
            break;
        case 'SELECTION_DISABLED':
        case 'LIVEBET_ERROR_-12':
            err = 'En eller flere kampe er lukket for spil i øjeblikket';
            break;
        case 'INVALIDAMOUNT':
            err = 'Beløbet er ugyldigt';
            break;
        case 'BALANCETOOLOW':
            err = 'Saldoen er for lav';
            break;
        case 'ORA-20031':
            err = 'For mange kampe er valgt på denne kupon';
            break;
        case 'ORA-20021':
            err = 'Beløbet er mindre end det minimum tilladte';
            break;
        case 'ORA-20022':
            err = 'Udbetaling er over den tilladte grænse';
            break;
        case 'ORA-20013':
            err = 'Odds ændres, prøv igen senere';
            break;
        case 'USER_EXCLUDED':
            err = 'Du er selvudelukket fra betting';
            break;
        case 'UNKNOWNERROR':
            err = 'Fejl. Prøv venligst igen senere.';
            break;
        case 'EMAILISREQ':
            err = 'Fejl. Ugyldig email.';
            break;
        default:
            err = errorMsg;
            break;
    }

    return err;
}

export function validateUrlParams(partOfUrl) {
    partOfUrl =
        partOfUrl.indexOf('å') > -1
            ? partOfUrl.replace(/[å]/g, 'aa')
            : partOfUrl;
    partOfUrl =
        partOfUrl.indexOf('æ') > -1
            ? partOfUrl.replace(/[æ]/g, 'ae')
            : partOfUrl;
    partOfUrl =
        partOfUrl.indexOf('ø') > -1
            ? partOfUrl.replace(/[ø]/g, 'o')
            : partOfUrl;

    return partOfUrl
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/\.+/g, '-');
}

export function getLinkForSidebar(data, item) {
    if (data.sportMap) {
        if (
            item.type === 3 &&
            data.tournamentInSport[item.id] &&
            data.groupInSport[data.tournamentInGroup[item.id]]
        ) {
            return (
                '/' +
                validateUrlParams(
                    data.sportMap[data.tournamentInSport[item.id]].name
                ) +
                '/' +
                validateUrlParams(
                    data.sportMap[
                        data.groupInSport[data.tournamentInGroup[item.id]]
                    ].itemList.find(
                        (el) => el.id === data.tournamentInGroup[item.id]
                    ).name
                ) +
                '/' +
                validateUrlParams(item.name)
            );
        } else if (
            item.type === 2 &&
            data.groupInSport[item.id] &&
            data.sportMap[data.groupInSport[item.id]]
        ) {
            return (
                '/' +
                validateUrlParams(
                    data.sportMap[data.groupInSport[item.id]].name
                ) +
                '/' +
                validateUrlParams(item.name)
            );
        } else {
            return '/' + validateUrlParams(item.name);
        }
    }
}

export function getWinTeamOrBet(item, withMarket) {
    let result = '';
    if (
        item.marketTypeGroupName &&
        item.marketTypeGroupName.toLowerCase().includes('spiller specials')
    ) {
        if (
            item.marketTypeName &&
            ['afleveringer', 'assists', 'tacklinger'].some((marketType) =>
                item.marketTypeName.toLowerCase().includes(marketType)
            )
        ) {
            return item.bet;
        }
    }

    if (
        item.sportId === 1 &&
        item.marketTypeName.indexOf('Spiller - ') === 0 &&
        (item.betNr === 1 || item.betNr === 2 || item.betNr === 3)
    ) {
        return item.betNr;
    }

    if (
        item.marketName.toLowerCase().indexOf('antal') > -1 ||
        item.marketName.indexOf('Mål') > -1
    ) {
        result = item.bet || '';
    } else {
        let bet = item.bet ? item.bet : item.betText || '';

        if (['1', '2', 'x'].indexOf(bet.toLowerCase()) > -1 && item.eventName) {
            let eventArr = item.eventName.split(' - ');

            if (bet === '1') {
                result = eventArr[0];
            } else if (bet === '2') {
                result = eventArr[1];
            } else {
                result = 'Uafgjort';
            }
        } else {
            result = bet;
        }
    }

    if (item.bet === 'Udehold' && item.marketTypeGroupName === 'Hold') {
        result = 'Kun udehold';
    } else if (
        item.bet === 'Hjemmehold' &&
        item.marketTypeGroupName === 'Hold'
    ) {
        result = 'Kun hjemmehold';
    } else {
        result = result
            .replace('draw and over', 'Uafgjort og over')
            .replace('AT ', 'Udehold ')
            .replace('HT ', 'Hjemmehold ');
    }

    if (withMarket) {
        result = item.marketName + ': ' + result;
    }

    return result;
}

export function getExtraInfo(item) {
    if (
        item.marketName.indexOf('O/U') === -1 &&
        item.extraInfo &&
        item.marketTypeName &&
        item.marketTypeName.indexOf('1X2') === -1 &&
        item.marketTypeGroupName &&
        item.marketTypeGroupName.toLowerCase().indexOf('spiller specials') ===
            -1 &&
        item.sportId !== 18
    ) {
        if (item.betNr === 1) {
            return (
                ': ' +
                (item.extraInfo > 0 ? '+' + item.extraInfo : item.extraInfo)
            );
        } else {
            return (
                ': ' +
                (item.extraInfo > 0
                    ? '-' + item.extraInfo
                    : '+' + item.extraInfo.replace('-', ''))
            );
        }
    } else {
        return null;
    }
}

/**
 * @param {BetModel} bet
 * @param {Object} events
 * @return {EventModel|null}
 */
export function getLiveEventByBet(bet, events) {
    if (!bet || !bet.eventId || !events) {
        return null;
    }

    if (bet.sportId) {
        return (events[bet.sportId] || {})[bet.eventId] || null;
    }

    return Object.values(events)
        .reduce((acc, items) => [...acc, ...Object.values(items)], [])
        .find((event) => event.eventId === bet.eventId);
}

/**
 * @param {BetModel} bet
 * @param {EventModel} event
 * @param {MarketModel} market
 * @return {boolean}
 */
export function isLiveBetActive(bet, event, market) {
    if (bet.odds <= 1 || !event || !market) {
        return false;
    }
    if (
        market.itemList &&
        !market.itemList.find((marketItem) => marketItem.betNr === bet.betNr)
    ) {
        return false;
    }
    return (
        event.status === EVENT_STATUS.IN_PROGRESS &&
        market.status === MARKET_STATUS.ACTIVE
    );
}

export function forceRedraw(element) {
    if (!element) {
        return;
    }

    const textNode = document.createTextNode(' ');
    const disp = element.style.display;

    element.appendChild(textNode);
    element.style.display = 'none';

    window.setTimeout(() => {
        element.style.display = disp;
        textNode.parentNode.removeChild(textNode);
    }, 20);
}

/**
 * @param market {MarketModel}
 * @return {boolean}
 */
export function isMarketOverUnder(market) {
    var isOUmarket =
        market.marketTypeGroupName === 'Over/Under' ||
        market.marketTypeGroup === 5 ||
        (market.gameName && market.gameName.startsWith('O/U '));
    if (typeof market.main !== 'undefined') {
        return market.main === 1 && isOUmarket;
    } else {
        return isOUmarket;
    }
}

/**
 * @param {MarketItemModel[]} [targetList=[]]
 * @param {MarketItemModel[]} [updatedList=[]]
 * @return {MarketItemModel[]}
 */
export function mergeMarketItemLists(targetList = [], updatedList = []) {
    return [
        ...new Set([
            ...targetList.map(({ betNr }) => betNr),
            ...updatedList.map(({ betNr }) => betNr),
        ]),
    ].map((betNr) => ({
        ...(targetList.find((item) => item.betNr === betNr) || {}),
        ...(updatedList.find((item) => item.betNr === betNr) || {}),
    }));
}

/**
 * @param {MarketModel[]} [targetList=[]]
 * @param {MarketModel[]} [updatedList=[]]
 * @return {MarketModel[]}
 */
export function mergeMarketLists(targetList = [], updatedList = []) {
    const prevMap = targetList.reduce((acc, market) => {
        acc[market.marketId] = market;
        return acc;
    }, {});
    const updateMap = updatedList.reduce((acc, market) => {
        acc[market.marketId] = market;
        return acc;
    }, {});

    return [
        ...new Set([
            ...targetList.map(({ marketId }) => marketId),
            ...updatedList.map(({ marketId }) => marketId),
        ]),
    ].map((marketId) => ({
        ...(prevMap[marketId] || {}),
        ...(updateMap[marketId] || {}),
        itemList: mergeMarketItemLists(
            (prevMap[marketId] || {}).itemList,
            (updateMap[marketId] || {}).itemList
        ),
    }));
}
export function mergeVaixMarketListsScope(oldList = [], updatedList = []) {
    let mergedList = [];
    /*updatedList.map((event, index) => {
        oldList[index];
    });*/

    return mergedList;
}

/**
 * Detect specific error message
 * @param {String} error - like "MTS\12340000|1242342|A"
 * @return {boolean}
 */
export function isMTSStakeError(error) {
    return !!(
        error.startsWith('MTS|') &&
        error.endsWith('|A') &&
        Number(error.split('|')[1])
    );
}

/**
 * @param {String} error - like "MTS\12340000|1242342|A"
 * @return {string}
 */
export function getStakeAmountMTSStakeError(error) {
    return (Number(error.split('|')[1]) / 10000).toFixed(2);
}

/**
 * @param {EventModel} event
 * @return {boolean}
 */
export function isEventInclude1X2market(event) {
    return event.marketList.some(
        (item) =>
            item.marketTypeGroupName === '1X2' && item.marketName === 'Vinder'
    );
}

/**
 * @param {EventModel[]} eventList
 * @param {Number[]} competitionIds
 * @param {Number[]} groupIds
 * @return {EventModel[]}
 */
export const sortEventsByCompetitionAndGroupPriority = (
    eventList,
    competitionIds,
    groupIds = []
) => [
    ...eventList
        .filter(
            ({ competitionId }) =>
                competitionId && competitionIds.includes(competitionId)
        )
        .sort(
            (a, b) =>
                competitionIds.indexOf(a.competitionId) -
                competitionIds.indexOf(b.competitionId)
        ),
    ...eventList
        .filter(({ groupId }) => groupId && groupIds.includes(groupId))
        .sort(
            (a, b) =>
                groupIds.indexOf(a.competitionId) -
                groupIds.indexOf(b.competitionId)
        )
        .filter(({ eventName }) => eventList.eventName),
    ...eventList
        .filter(
            ({ competitionId, groupId }) =>
                !competitionIds.includes(competitionId) &&
                !groupIds.includes(groupId)
        )
        .sort((a, b) => a.eventId - b.eventId),
];

/**
 * Format marketName
 * @param {MarketModel} market
 * @return {String}
 */
export const reduceMarketName = (market, event) => {
    let { marketName } = market;
    if (
        [SPORTS.ICE_HOCKEY, SPORTS.SPECIALS_ALT].includes(
            market.marketTypeGroup
        )
    ) {
        marketName = marketName.replace(
            patterns.market.handicapNameReplace,
            ''
        );
    }
    if (
        [
            SPORTS.TENNIS,
            SPORTS.CRICKET,
            SPORTS.FLOORBALL,
            SPORTS.HANDBALL,
        ].includes(market.marketTypeGroup)
    ) {
        const ou = ['O/U', 'Over/Under'].find(
            (text) => market.marketName && market.marketName.includes(text)
        );

        if (ou) {
            marketName = marketName.slice(
                0,
                market.marketName.indexOf(ou) + ou.length
            );
            if (
                market &&
                market.marketTypeGroupName &&
                !market.marketTypeGroupName.includes('Over/Under')
            ) {
                marketName += ' ' + market.marketTypeGroupName;
            }
        }
    }
    if (
        [SPORTS.BASKETBALL, SPORTS.HANDBALL].includes(market.marketTypeGroup) &&
        market.marketName.toLowerCase().includes('hjørnespark handicap')
    ) {
        marketName = marketName
            .split(' ')
            .filter((chunk) => Number.isNaN(Number(chunk)))
            .join(' ');
    }
    marketName = formatGroupLabel(market, event);
    return marketName;
};

/**
 * @param {MarketModel[]} marketList
 * @return {{label: String, marketList: MarketModel[]}[]}
 */
export const groupMarketsByUniqLabels = (marketList, event) => {
    return marketList.reduce((acc, item) => {
        const label = reduceMarketName(item, event);
        const subGroup = acc.find((item) => item.label === label);

        if (subGroup) {
            subGroup.marketList.push(item);
        } else {
            acc.push({
                label,
                marketList: [item],
            });
        }
        return acc;
    }, []);
};

const sizes = {
    xs: 12,
    sm: 14,
    md: 18,
    lg: 20,
    xl: 25,
    xxl: 30,
};
/**
 *
 * @param size {string}
 * @param scale {int} Use this number to increase default dimensions
 * @return {string}
 */
export const getSize = ({ size = 'sm', scale = 1 }) => {
    let dim = scale * sizes[size];
    return `
        min-width: ${dim}px;
        width: ${dim}px;
        height: ${dim}px;
    `;
};
/**
 *
 * @param res {object}
 * @param iframe {object}
 * @param Nem {object}
 */
export const setNemidParams = (res, iframe, Nem) => {
    let { data } = res;
    let json = { ...data };
    delete json.src;
    delete json.url;

    iframe.source = data.src;
    iframe.prefix = data.url;

    if (window.addEventListener) {
        window.addEventListener('message', Nem.Message);
    }

    if (window.attachEvent) {
        window.attachEvent('onmessage', Nem.Message);
    }
    window.document.getElementById(
        'nemid_parameters'
    ).innerText = JSON.stringify(json);
    window.document.getElementById('nemid-iframe').src = data.src;
};

export const checkUserExclusionStatus = (cb) => {
    UserService.getUserStatus().then((res) => {
        let { data } = res;
        if (data && data.tm && data.status !== 'ex_p') {
            const message = `Din selvudelukkelse er aktiv frem til  ${formatDate(
                res.data.tm,
                'DD.MM.YY [kl] HH:mm'
            )}`;
            if (cb) {
                cb(true);
            }
            NotificationManager.warning('', message, 7000);
        }
    });
};
export function debounce(fn, ms) {
    let timer;
    return (_) => {
        clearTimeout(timer);
        timer = setTimeout((_) => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}

export async function sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string

    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export function isEmptyObject(object) {
    return Object.keys(object).length === 0;
}

export function removeItemInArray(arr, value) {
    return arr.filter(function(element) {
        return element != value;
    });
}

export function removeTagsInString(str) {
    return str.replace(new RegExp('<[^>]*>', 'gmi'), '');
}

export function getEnvironment() {
    return window.location.hostname.indexOf('dev.bet25.dk') > -1 ||
        window.location.hostname === 'localhost' ||
        window.location.hostname.indexOf('dev.25bet.dk') > -1
        ? 'dev'
        : 'prod';
}

export function isString(value) {
    return typeof value === 'string' || value instanceof String;
}

export function isAuthorizedPage() {
    return AUTHORIZED_PAGES.includes(window.location.pathname.split('/')[2]);
}

export function hasMarketIsDrawDisabled(markets) {
    //return
}

export const setCookie = (cname, cookieValue, expirationDays = 365) => {
    let d = new Date();
    d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cookieValue + ';' + expires + ';path=/';
};

export const getCookie = (cname) => {
    let name = cname + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
export function eraseCookie(name) {
    document.cookie =
        name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function addEveryValueFromArrayToString(array, string = '') {
    return array.length > 0 ? string + array.join(',') : string;
}

export function getStringOfNumbersFromStringInArrayOfObjects(
    arrayOfObjects,
    keyWhereNumbersAre
) {
    //we will return string of numbers without spaces
    return arrayOfObjects
        .map((item) => item[keyWhereNumbersAre].match(/\d+/g).join())
        .join();
}

export function getArrayOfNumbersFromStringInArrayOfObjects(
    arrayOfObjects,
    keyWhereNumbersAre
) {
    return arrayOfObjects.flatMap((item) =>
        Number(item[keyWhereNumbersAre].match(/\d+/g))
    );
}

export const sortDataByArrayOfEventIds = (data, eventIds = []) => {
    return eventIds.flatMap(
        (id) => data.find((item) => item.externalId === id) || []
    );
};

export function sortEventsByVaixRecommendationAndOurPriority(
    liveEvents = [],
    vaixLiveEvents = [],
    trackData = false,
    userId = 0
) {
    let vaixEvents = [];
    let vaixEventsWithIndex = [];
    let notVaixEvents = [];
    vaixLiveEvents.map((vaixEventId) => {
        let index = liveEvents.findIndex(
            (event) => vaixEventId === event.externalId
        );
        if (index > -1) {
            vaixEvents.push({
                ...liveEvents[index],
                vaixIndex: vaixEvents.length + 1,
            });
        }
    });
    /*vaixEvents.map((event, index) => {
        vaixEventsWithIndex.push({
            event,
            vaixIndex: index + 1,
        })
    }*/

    liveEvents.map((event) => {
        if (!vaixLiveEvents.includes(event.externalId)) {
            notVaixEvents.push(event);
        }
    });

    if (trackData) {
        trackEvents(
            vaixEvents,
            VAIX_TRACKING_TYPE.impressions,
            VAIX_TRACKING_LOCATION_FOR_TYPE_OF_SPORT(vaixEvents[0].sport),
            '/live',
            userId
        );
    }

    notVaixEvents.sort((a, b) => a.priority - b.priority);
    return [...vaixEvents, ...notVaixEvents];
}

export const getEventsByVaixIds = async (data) => {
    let sortedData = sortEventsByVaixPriority(data);
    let eventsIds = getArrayOfNumbersFromStringInArrayOfObjects(
        sortedData,
        'event_id'
    );
    return await ParsproService.getEventsByIds(eventsIds.join(',')).then(
        (res) => {
            const { data } = res;
            if (data && data.length > 0) {
                return sortDataByArrayOfEventIds(data, eventsIds);
            } else {
                return [];
            }
        }
    );
};

export const sortEventsByVaixPriority = (data) => {
    if (data && data.length > 0) {
        return data.sort(
            (a, b) =>
                b.league_confidence === a.league_confidence
                    ? b.confidence - a.confidence
                    : b.league_confidence - a.league_confidence
        );
    } else {
        return [];
    }
};

export const getRandomIntBetween = (min, max) => {
    return Math.random() * (max - min) + min;
};

export const replaceNumbersInString = (str) => {
    return str.replace(/[^0-9]/g, '');
};
