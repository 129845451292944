import { makeRequest } from 'utils/REST';
import { PUNTER_PATH } from 'constants/appConfig';
import CashoutService from 'utils/CashoutService';
import Big9Service from 'utils/Big9Service';

class UserService {
    request(requestType, url, data) {
        return makeRequest(requestType, url, data)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
    }

    post(method, data) {
        return this.request('post', PUNTER_PATH + method, data);
    }

    get(method, data) {
        return this.request('get', PUNTER_PATH + method, data);
    }

    systemNames = [
        'null',
        'singler',
        'doubler',
        'tripler',
        '4-ling',
        '5-ling',
        '6-ling',
        '7-ling',
        '8-ling',
        '9-ling',
        '10-ling',
        '11-ling',
        '12-ling',
        '13-ling',
        '14-ling',
        '15-ling',
    ];

    handler(cb, eh) {
        return {
            callback: function(res) {
                if (res) {
                    if (res.error === true) {
                        if (res.errorMessage === 'Punter is null') {
                            console.log('onAuthSessionError');
                        } else {
                            console.log('Fejl. ' + res.errorMessage);
                        }

                        eh ? eh(res) : null;
                    } else {
                        cb(res);
                    }
                } else {
                    console.log('EMPTY!');
                }
            },
            errorHandler: function(er, ex) {
                console.log(er, ex);
            },
        };
    }

    DepositVoucher(serial, code) {
        return this.post('depositVoucher', { serial, code });
    }

    GetSalesPageRetail(page = 1, size = 10) {
        return this.post('getSalesPageRetail', {
            products: ['BE', 'VE', 'B9'],
            size,
            page,
        });
    }

    TransferRetailMoney(product, saleId) {
        return this.post('payoutRetailCoupon', {
            product,
            saleId,
        });
    }

    HistoryBettingOpenedEx(page = 1, number = 10) {
        return this.get(`getSalesPageEx/${page}/${number}/0`);
    }

    GetSalesPage(page = 1, count = 1, status = 0) {
        return this.get(`getSalesPageEx/${page}/${count}/${status}`);
    }
    GetMergedSalesPage() {
        return Promise.all([this.GetSalesPage(), Big9Service.history(1, 0)]);
    }
    HistoryBettingClosedEx(page = 1, number = 10) {
        return this.get(`getSalesPageEx/${page}/${number}/1`);
    }

    GetDepositBonus() {
        return this.get('getBonusList');
    }
    GetCasinoBonus() {
        return this.get('getBonusListSrv/Rod');
    }

    MoneyPayout() {
        return this.get('getWithdrawMaxAmountEx');
    }

    GetActiveBonusList() {
        return this.get('activeBonusList');
    }

    MoneyWithdraw(amount) {
        return this.post('withdrawalRequestNem', {
            amount,
            text: 'Bet25 Sport',
        });
    }

    LegalLimit() {
        return this.get('getDepositLimits');
    }

    SetLegalLimit(limits) {
        return this.post('setDepositLimits', limits);
    }

    InfoGet() {
        return this.get('getAccountInformation');
    }

    InfoSet(profile) {
        return this.post('setAccountInformation', profile);
    }

    getUserStatus() {
        return this.get('getUserStatus');
    }

    ChangePassword(params) {
        return this.post('changePassword', {
            current: params.current,
            edit: params.edit,
            confirm: params.confirm,
        });
    }

    OfferSubmit(code, note) {
        return this.post('addTeam', { code, note });
    }
    //old request which should be removed after updating terminals for new request
    Payment(dateFrom, dateTo, start, end, cb) {
        this.get(`getTransactionAllList/${dateFrom}/${dateTo}/${start}/${end}`)
            .then((res) => {
                if (res && res.data) {
                    cb(res.data);
                } else {
                    console.log(res.error_message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getTransactionList(
        group,
        dateFrom = '',
        dateTo = '',
        offset = 0,
        rowCount = 20
    ) {
        return this.get(
            `getTransactionGroupList/${group}/${dateFrom}/${dateTo}/${offset}/${rowCount}`
        );
    }

    ExcludeUserPermanently() {
        return this.post('excludeUserPermanently');
    }

    ExcludeUserByPeriod(exclusionPeriod) {
        return this.post('excludeUser', { exclusionPeriod });
    }

    NewsletterSubscription(status) {
        return this.post('newsletterSubscription', { on: status });
    }

    UnsubscribeFromNewsletter(status, email) {
        return this.post('unsubscribeFromNewsletter', { on: status, email });
    }

    CashoutData(cb) {
        CashoutService.couponList().then((res) => {
            let sales = {};
            if (!res.success && res.errorMessage) {
                cb({}, res.errorMessage);
                return {};
            }

            if (Array.isArray(res.data)) {
                res.data.map((v) => {
                    if (!sales[v.saleId]) {
                        sales[v.saleId] = {
                            id: v.saleId,
                            coupons: [],
                            saleDate: v.saleDate,
                            totalStake: 0,
                            totalCashout: 0,
                            totalPrize: 0,
                            system: [
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                                0,
                            ],
                            systemText: '',
                        };
                    }
                    v.prize = parseFloat(v.amount * v.odds);
                    v.sell = parseFloat(v.cashoutOdds * v.amount);
                    sales[v.saleId].coupons.push(v);
                    sales[v.saleId].totalStake += v.amount;
                    sales[v.saleId].totalCashout += v.sell;
                    sales[v.saleId].totalPrize += v.prize;
                    sales[v.saleId].system[v.betList.length]++;

                    return v;
                });
            }

            let tmp = sales;

            Object.keys(tmp).map((k) => {
                const v = tmp[k];
                if (v.coupons.length > 1) {
                    delete sales[v.id];
                } else {
                    sales[k].systemText = '';

                    Object.keys(v.system).map((l) => {
                        const s = v.system[l];
                        if (s >= 1) {
                            if (sales[k].systemText !== '') {
                                sales[k].systemText +=
                                    ', ' + s + ' ' + this.systemNames[l];
                            } else {
                                sales[k].systemText +=
                                    s + ' ' + this.systemNames[l];
                            }
                        }

                        return s;
                    });
                }

                return v;
            });

            cb(sales);
        });
    }

    BetRequest(eventId, text, live) {
        return this.post('betRequest', { eventId, text, live });
    }
}

export default new UserService();
