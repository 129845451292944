import React from 'react';
import Person from 'images/avatars/avatar_star.png';
import { hideModal as HideModal } from '../actions/UiActions';
import { ForgotPassword, WithClearSafariPageCache } from '@it25syv/25syv-ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { saveLS } from '../features/PersistentStorage/localStor';
import { browserHistory } from 'react-router';

const LoginForgotPass = ({ hideModal, modalData }) => {
    const loginHandler = (userName, password) => {
        if (userName && password) {
            login(userName, password, false);
            saveLS({ userName: userName });
        }
    };

    return (
        <ForgotPassword
            closeAction={() => {
                browserHistory.push('/sport/');
                hideModal('FORGOT_PASSWORD');
            }}
            loginHandler={loginHandler}
            site={'sport'}
            productIcon={Person}
            primaryColor={'#1C3E60'}
            isPinLogin={modalData?.isPinLogin}
        />
    );
};

function mapDispatchToProps(dispatch) {
    return {
        hideModal: bindActionCreators(HideModal, dispatch),
        login: bindActionCreators(login, dispatch),
    };
}

export default connect(
    null,
    mapDispatchToProps
)(WithClearSafariPageCache(LoginForgotPass));
