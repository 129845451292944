import React from 'react';
import Text from 'ui/Text';
import { OverlayWrapper } from 'features/Betslip/styled';
import '../styles/dropdown.css';

const dropdownModal = (Component) => (props) => {
    return (
        <OverlayWrapper
            showOverlay={props.isOpen}
            className={
                window.location.pathname.split('/')[2] === 'spil'
                    ? 'spilPage'
                    : null
            }
        >
            <div className={`sk-dropdown-wrapper ${props.isOpen && 'open'}`}>
                <hr />
                <div className={'sk-dropdown-content'}>
                    <div className={'sk-dropdown-header'}>
                        <Text
                            size={'x25'}
                            color={'#333'}
                            bold
                            margin={'0 0 0 8px'}
                            padding={'2px 0'}
                            width={'85%'}
                        >
                            {props.title}
                        </Text>
                        <div
                            className={'close-btn-icon'}
                            onClick={props.onClose}
                        >
                            <i className={'fa fa-times'} aria-hidden="true" />
                        </div>
                    </div>
                    <Component {...props} />
                </div>
            </div>
        </OverlayWrapper>
    );
};

export default dropdownModal;
