import React, { Component } from 'react';
import '../css/ViewButton.css';

class ViewButton extends Component {
    render() {
        return (
            <div
                className="view-button"
                style={this.props.style}
                onClick={this.props.onClickHandler}
            >
                {this.props.children}
            </div>
        );
    }
}

class ViewDisabledButton extends Component {
    render() {
        return (
            <div className="view-button disabled">{this.props.children}</div>
        );
    }
}

class ViewOddsDisabledButton extends Component {
    render() {
        return (
            <div className="view-odds-button disabled">
                {this.props.children}
            </div>
        );
    }
}

class ViewSmallOrangeButton extends ViewButton {
    render() {
        return (
            <div
                className="view-small-orange-button"
                onClick={this.props.onClickHandler}
            >
                {this.props.children}
            </div>
        );
    }
}

class ViewOrangeButton extends ViewButton {
    render() {
        return (
            <div
                className={
                    this.props.className
                        ? this.props.className + ' view-orange-button'
                        : 'view-orange-button'
                }
                onClick={this.props.onClickHandler}
            >
                {this.props.children}
            </div>
        );
    }
}

class ViewLinkButton extends ViewButton {
    render() {
        return (
            <div
                className="view-link-button"
                onClick={this.props.onClickHandler}
            >
                {this.props.children}
            </div>
        );
    }
}

export {
    ViewButton,
    ViewDisabledButton,
    ViewSmallOrangeButton,
    ViewOrangeButton,
    ViewLinkButton,
    ViewOddsDisabledButton,
};
