import { useQuery } from '@tanstack/react-query';
import UserService from 'utils/UserService';

const useHistoryItemsCount = () => {
    const fetchHistory = async () => {
        const res = await UserService.GetMergedSalesPage();
        if (res[0]?.data && res[1]?.data) {
            return res[0].data.count + res[1].data.count;
        } else {
            console.error('Failed active bets count');
            return 0;
        }
    };

    const { data, refetch } = useQuery(['history-count'], fetchHistory, {
        keepPreviousData: true,
        staleTime: 60 * 1000,
        enabled: true,
    });

    return { count: data, refetch };
};

export default useHistoryItemsCount;
