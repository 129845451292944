import {
    TERMINAL_BETSLIP_ADD_MARKET,
    TERMINAL_BETSLIP_ITEM_ERROR,
    TERMINAL_BETSLIP_PENDING,
    TERMINAL_BETSLIP_PROCEED,
    TERMINAL_BETSLIP_REMOVE_MARKET,
    TERMINAL_BETSLIP_RESET,
    TERMINAL_BETSLIP_SAVE_UNPAID,
    TERMINAL_BETSLIP_SET_LIVE_MARKETS,
    TERMINAL_BETSLIP_SET_MARKET,
    TERMINAL_BETSLIP_SET_SYSTEM_MARKET,
    TERMINAL_BETSLIP_SET_VALUE,
    TERMINAL_BETSLIP_ERROR,
    TERMINAL_BETSLIP_UPDATE_MARKET,
} from 'constants/terminalBetslip';
import BetslipService from '../utils/BetslipService';
import { normalizeStake } from 'utils/Helper';

const initialState = {
    success: false,
    error: false,
    pending: false,
    timer: null,
    added: false,
    addError: false,
    changed: false,
    disabled: false,
    markets: [],
    liveMarkets: {},
    systemName: null,
    totalOdds: 0,
    betList: [],
    hasMarkets: [],
    singleStake: 0,
    singlePrize: 0,
    system: {
        stake: [],
        prize: [],
        multi: [],
        names: [],
        totalPrize: 0,
        totalStake: 0,
        accum: [],
    },
    itemError: null,
    setMarkets: {},
    event: {},
};

let system = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [],
    12: [],
    13: [],
    14: [],
};

let accum = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
    13: 0,
    14: 0,
};

let Model = {
    changed: false,
    frozen: false,
    timeout: 0,
    betCount: 0,
    betList: [],
    hasMarkets: [],
    markets: {},
    system: {
        stake: [],
        prize: [],
        multi: [],
        names: [],
        totalPrize: 0,
        totalStake: 0,
    },
};

let initialModel = {
    changed: false,
    frozen: false,
    timeout: 0,
    betCount: 0,
    betList: [],
    hasMarkets: [],
    markets: {},
    system: {
        stake: [],
        prize: [],
        multi: [],
        names: [],
        totalPrize: 0,
        totalStake: 0,
    },
};

const Calculate = {
    Prize: function(stake, odds) {
        return stake * odds;
    },

    TotalPrize: function() {
        var totalPrize = 0;

        if (Model.system.prize.length > 0) {
            Model.system.prize.map((v, k) => {
                totalPrize += parseFloat(v);

                return v;
            });
        }

        Model.system.totalPrize = totalPrize;

        return totalPrize;
    },

    TotalStake: function() {
        var totalStake = 0;

        if (Model.system.stake.length > 0) {
            Model.system.stake.map((v, k) => {
                var multi = Model.system.multi[k];
                totalStake += v * multi;

                return v;
            });
        }

        Model.system.totalStake = totalStake;

        return totalStake;
    },

    TotalOdds: (betList) => {
        var totalOdds = 1;

        betList.map((bet) => {
            totalOdds *= bet.odds;

            return bet;
        });

        return totalOdds;
    },

    /* systemAccum */
    System: function(betList) {
        system = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
        };

        let odds = [];

        betList.map((v, k) => {
            odds.push(v.odds);
            return v;
        });

        function getCombinations(chars) {
            let result = [];
            let f = function(prefix, chars) {
                for (let i = 0; i < chars.length; i++) {
                    result.push(prefix + chars[i]);
                    f(prefix + chars[i] + ',', chars.slice(i + 1));
                }
            };
            f('', chars);
            return result;
        }
        let allCombinations = getCombinations(odds);
        allCombinations.forEach((el) => {
            let count = (el.match(/,/g) || []).length;
            system[count].push(el);
        });

        for (let i = 0; i < 15; i++) {
            var oddsArray = system[i];
            if (oddsArray.length > 0) {
                var accumulator = 0;

                for (let n = 0; n < oddsArray.length; n++) {
                    let value = oddsArray[n].toString();

                    if (value.indexOf(',') === -1) {
                        accumulator += parseFloat(value);
                    } else {
                        var oddsArray2 = value.split(',');
                        let odds = 1;

                        for (let k = 0; k < oddsArray2.length; k++) {
                            odds *= parseFloat(oddsArray2[k]);
                        }

                        accumulator += odds;
                    }
                }

                if (betList.length < 16) {
                    accum[i] = accumulator;

                    if (Model.system.stake[i]) {
                        let stake = Model.system.stake[i];
                        if (stake > 0) {
                            let prize = parseFloat(stake) * accumulator;
                            Model.system.prize[i] = prize;
                        }
                    }
                } else {
                    accum[i] = 0;
                    accum[0] = accumulator;

                    if (Model.system.stake[i]) {
                        let stake = Model.system.stake[i];
                        if (stake > 0) {
                            let prize = parseFloat(stake) * accumulator;
                            Model.system.prize[0] = prize;
                        }
                    }
                }
            }
        }
    },
};

var System = {
    factorial: function(n) {
        n = Number(n);
        if (n < 0) {
            return;
        }
        if (n < 2) {
            return 1;
        } else {
            return n * System.factorial(n - 1);
        }
    },

    Init: function(betlist) {
        const betCount = betlist.length;
        const systemNames = BetslipService.getSystemNames();

        if (betCount > 1) {
            var n = betCount;

            if (betCount > 14) {
                let totalStake = Model.system.stake[0];
                for (let i = 1; i < 15; i++) {
                    Model.system.multi[i] = 0;
                    Model.system.stake[i] = 0;
                    Model.system.prize[i] = 0;
                }

                Model.system.names = [];

                Model.system.multi[0] = 1;
                Model.system.names[0] = systemNames[betCount - 1];
                Model.system.stake[0] = totalStake;
                Model.system.stake[betCount - 1] = totalStake;
            } else {
                for (let i = 0; i < 15; i++) {
                    let r = i + 1;
                    var systemCount =
                        System.factorial(n) /
                        (System.factorial(r) * System.factorial(n - r));

                    if (i < betCount && i < 14) {
                        Model.system.multi[i] = systemCount;
                        Model.system.stake[i] = Model.system.stake[i] || 0;
                        Model.system.prize[i] = 0;
                        Model.system.names[i] = systemNames[i];
                    } else {
                        Model.system.multi[i] = 0;
                        Model.system.stake[i] = 0;
                        Model.system.prize[i] = 0;
                        delete Model.system.names[i];
                    }
                }
            }
            System.Update(betCount);
            Calculate.System(betlist);
        } else {
            for (let i = 1; i < 15; i++) {
                Model.system.multi[i] = 0;
                Model.system.stake[i] = 0;
                Model.system.prize[i] = 0;
            }
        }
    },

    Update: function(betCount) {
        if (betCount < 15) {
            const systemNames = BetslipService.getSystemNames();

            for (var i = 0; i < betCount; i++) {
                Model.system.names[i] = systemNames[i];
            }
        }
    },
};

export default function terminalBetslip(state = initialState, action) {
    let betslipData = null;
    let stake = 0;
    let odds = 0;
    let index = 0;

    const systemNames = BetslipService.getSystemNames();

    switch (action.type) {
        case TERMINAL_BETSLIP_ADD_MARKET:
            betslipData = {
                ...state,
                betList: [...state.betList, action.payload],
                hasMarkets: [...state.hasMarkets, action.payload.marketId],
            };

            let newSm = {};

            betslipData.betList.map((item) => {
                newSm[
                    item.gameId + '_' + item.betText + '_' + item.marketName
                ] = true;

                return item;
            });

            System.Init(betslipData.betList);

            let newSystemName = '';

            Model.system.stake = Model.system.stake.map((s, i) => {
                if (s > 0) {
                    if (newSystemName !== '') {
                        newSystemName +=
                            ', ' + Model.system.multi[i] + ' ' + systemNames[i];
                    } else {
                        newSystemName +=
                            Model.system.multi[i] + ' ' + systemNames[i];
                    }
                }

                Model.system.prize[i] = 0;

                return 0;
            });

            Calculate.TotalPrize();
            Calculate.TotalStake();

            return {
                ...betslipData,
                system: {
                    ...Model.system,
                    accum,
                },
                totalOdds: Calculate.TotalOdds(betslipData.betList),
                systemName: BetslipService.getSystemNames()[
                    betslipData.betList.length - 1
                ],
                setMarkets: newSm,
            };
        case TERMINAL_BETSLIP_REMOVE_MARKET:
            const marketId = action.payload;

            let sm = { ...state.setMarkets };

            state.betList.map((item) => {
                if (item.marketId === marketId) {
                    sm[
                        item.marketId +
                            '_' +
                            item.betText +
                            '_' +
                            item.marketName
                    ] = false;
                }

                return item;
            });

            betslipData = {
                ...state,
                betList: [
                    ...state.betList.filter(
                        (item) => item.marketId !== marketId
                    ),
                ],
                hasMarkets: [
                    ...state.hasMarkets.filter((item) => item !== marketId),
                ],
                singleStake: 0,
            };

            System.Init(betslipData.betList);

            let systemName = '';

            Model.system.stake = Model.system.stake.map((s, i) => {
                if (s > 0) {
                    if (systemName !== '') {
                        systemName +=
                            ', ' + Model.system.multi[i] + ' ' + systemNames[i];
                    } else {
                        systemName +=
                            Model.system.multi[i] + ' ' + systemNames[i];
                    }
                }

                Model.system.prize[i] = 0;

                return 0;
            });

            Calculate.TotalPrize();
            Calculate.TotalStake();

            return {
                ...betslipData,
                system: {
                    ...Model.system,
                    accum,
                },
                totalOdds: Calculate.TotalOdds(betslipData.betList),
                systemName: BetslipService.getSystemNames()[
                    betslipData.betList.length - 1
                ],
                setMarkets: sm,
                singlePrize: 0,
            };
        case TERMINAL_BETSLIP_SET_VALUE:
            return {
                ...state,
                ...action.payload,
            };
        case TERMINAL_BETSLIP_RESET:
            Model = initialModel;

            return initialState;
        case TERMINAL_BETSLIP_SAVE_UNPAID:
            return {
                ...state,
                pending: false,
                error: false,
            };
        case TERMINAL_BETSLIP_PENDING:
            return {
                ...state,
                pending: action.payload,
            };
        case TERMINAL_BETSLIP_SET_MARKET:
            stake = action.payload.value;
            odds = action.payload.odds;

            if ([',', '.'].indexOf(stake[stake.length - 1]) < 0) {
                stake = normalizeStake(stake);
            }

            return {
                ...state,
                singleStake: stake,
                singlePrize: odds * stake,
            };
        case TERMINAL_BETSLIP_SET_SYSTEM_MARKET:
            stake = action.payload.value;
            index = action.payload.index;

            if ([',', '.'].indexOf(stake[stake.length - 1]) < 0) {
                stake = normalizeStake(stake);
            }

            Model.system.prize[index] = stake * accum[index];
            Model.system.stake[index] = stake;
            Calculate.TotalPrize();
            Calculate.TotalStake();

            return {
                ...state,
                system: {
                    ...Model.system,
                },
                singleStake: 0,
                singlePrize: 0,
            };
        case TERMINAL_BETSLIP_PROCEED:
            if (action.payload.isRememberCoupon) {
                return {
                    ...state,
                    pending: false,
                    success: true,
                    error: false,
                };
            } else {
                Model = initialModel;

                return {
                    ...initialState,
                    success: true,
                };
            }

        case TERMINAL_BETSLIP_UPDATE_MARKET:
            let totalOdds = 1;
            let betslip = { ...state };
            let { data } = action.payload;

            betslip.betList.map((betitem, betIndex) => {
                if (data && betitem.gameId === data.marketId && data.itemList) {
                    data.itemList.map((item, index) => {
                        if (
                            item.betNr === betitem.betNr &&
                            betslip.betList[betIndex].odds !== item.odds
                        ) {
                            betslip.betList[betIndex].odds = item.odds;

                            if (!betslip.changed) {
                                betslip.changed = {};
                            }

                            betslip.changed[data.marketId] = item.betNr;
                        }

                        return item;
                    });
                }

                totalOdds *= betslip.betList[betIndex].odds;

                return betitem;
            });

            if (betslip.betList.length > 1) {
                System.Init(betslip.betList);
                Calculate.TotalPrize();
                Calculate.TotalStake();

                betslip.system = {
                    ...Model.system,
                };

                betslip.totalOdds = totalOdds;
            } else {
                betslip.singlePrize =
                    betslip.betList[0].odds * betslip.singleStake;
                betslip.totalOdds = betslip.betList[0].odds;
            }

            return {
                ...state,
                ...betslip,
            };
        case TERMINAL_BETSLIP_ITEM_ERROR:
            return {
                ...state,
                itemError: action.payload,
            };

        case TERMINAL_BETSLIP_ERROR:
            const error =
                action.payload === 'INVALIDSESSION'
                    ? 'Session udløbet'
                    : action.payload;
            return {
                ...state,
                success: false,
                pending: false,
                timer: null,
                added: false,
                changed: false,
                disabled: false,
                error: error,
            };

        case TERMINAL_BETSLIP_SET_LIVE_MARKETS:
            return {
                ...state,
                liveMarkets: action.payload,
            };
        default:
            return state;
    }
}
