import styled from 'styled-components';
import Wrapper from 'ui/Wrapper';
import loginBanner from 'images/banners/login_mobile_banner.jpg';
import Button from 'ui/Button';
const bgValue = `linear-gradient(to bottom,transparent, transparent 20%, #fff 54%), url(${loginBanner}) no-repeat top center #fff`;

export const StyledLoginMobileWrapper = styled(Wrapper)`
    display: flex;
    flex-direction: column;
    justify-content: ${({ jContent }) =>
        jContent ? jContent : 'space-between'};
    padding: ${({ padding }) => (padding ? padding : '35px 25px')};
    margin: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background: ${({ bg }) => (bg ? bg : bgValue)};
    background-size: contain;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ zIndex }) => (zIndex ? zIndex : '10')};
    @media screen and (min-width: 1024px) {
        max-height: 90%;
        min-height: 665px;
        width: 450px;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        background: ${({ bg }) => (bg ? bg : bgValue)}!important;
    }
    @media screen and (max-height: 700px) {
        padding: 10px 15px;
    }
    @media screen and (min-width: 750px) {
        background: linear-gradient(
                to bottom,
                transparent,
                transparent 20%,
                #fff 40%
            ),
            url(${loginBanner}) no-repeat left top #fff;
        background-size: contain;
    }
    @media screen and (orientation: landscape) {
        background: linear-gradient(
                to bottom,
                transparent,
                transparent 55%,
                #fff 75%
            ),
            url(${loginBanner}) no-repeat left top #fff;
        background-size: cover;
    }
`;
export const StyledSignUpButton = styled(Button)`
    margin: 0 0 0 auto;
`;

export const TopWrapper = styled.div`
    height: auto;
    width: 100%;
    display: block;
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;
    min-height: 263px;
    @media screen and (min-height: 850px) {
        margin-bottom: 0;
    }
`;

export const ToggleGroup = styled.div`
    margin: 100px auto 50px;
    width: 100%;
    background: #ffffff;
    display: flex;
    height: 50px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    text-align: center;
    align-items: center;
    padding: 5px;
    border-radius: 7px;

    .left,
    .right {
        user-select: none;
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        transition: 0.2s;
        width: 50%;
        z-index: 2;
    }
    div {
        background: #eceff6;
        border-radius: 5px;
        height: 40px;
        left: 5px;
        position: absolute;
        transition: all 0.5s;
        top: 5px;
        width: calc(50% - 5px);
        z-index: 1;
    }
    &.nem > div {
        transform: translateX(100%);
    }
    &.login > .right {
        color: #9198aa;
    }
    &.nem > .left {
        color: #9198aa;
    }

    @media screen and (max-width: 350px) {
        margin: 100px auto 50px;
    }
    @media screen and (max-height: 667px) {
        margin: 50px auto 20px;
    }
`;

export const CommonLoginInput = styled.input.attrs(({ type, name }) => ({
    type: type || 'text',
    name: name || '',
}))`
    width: 100%;
    height: 62px;
    box-sizing: border-box;
    padding: 0 25px;
    background: #eceff6;
    border-radius: 5px;
    color: #9198aa;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    outline: none;
    border: none;
    margin: 10px 0;
`;
export const LoginInputWrapper = styled(Wrapper)`
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    .fa {
        position: absolute;
        right: 20px;
        top: 32px;
        font-size: 18px;
        color: #9198aa;
    }
`;

export const PinCloseButton = styled.div`
    margin: ${({ margin }) => (margin ? margin : ' 0')};
    border-radius: 5px;
    width: 45px;
    height: 45px;
    box-sizing: border-box;
    color: white;
    font-size: 20px;
    line-height: 45px;
    padding: 0 15px;
    background: rgba(0, 0, 0, 0.37);
    &:hover {
        cursor: pointer;
    }
    &.spilId {
        height: 73px;
        width: 73px;
        padding-top: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 20px;
        border-radius: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        background: none;
        i {
            color: #c6ccdb;
        }
    }
`;

export const PinIndicatorsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin: ${({ margin }) => (margin ? margin : '0')};
`;

export const PinIndicator = styled.div`
    width: ${({ filled }) => (filled ? '30px' : '64px')};
    height: ${({ filled }) => (filled ? '30px' : '64px')};
    border-radius: 64px;
    box-sizing: border-box;
    border: ${({ isCurrent }) => (isCurrent ? '3px solid #1c3e60' : 'none')};
    transition: all 0.5s ease;
    background: ${({ filled }) => (filled ? '#1C3E60' : '#fff')};
    &.spillId {
        border: ${({ isCurrent }) =>
            isCurrent ? '3px solid #1c3e60' : 'none'};
    }
`;
export const PinKeyboard = styled.div`
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    padding: ${({ padding }) => (padding ? padding : '0')};
    box-sizing: border-box;
    margin-top: 20px;
    border-radius: 8px;
    @media screen and (min-height: 550px) {
        margin-top: 10px;
    }
`;
export const PinKeyboardButton = styled.div`
    background: ${({ bg }) => (bg ? bg : '#ececf4')};
    border: 1px solid #dee2eb;
    display: flex;
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    width: 33.3%;
    box-sizing: border-box;
    padding: 20px 0;
    &:hover {
        cursor: pointer;
    }
    &:first-child {
        border-radius: 8px 0 0 0;
    }
    &:last-child {
        border-radius: 0 0 8px 0;
    }
    &:nth-child(3) {
        border-radius: 0 8px 0 0;
    }
    &:nth-child(10) {
        border-radius: 0 0 0 8px;
    }
    .fa {
        font-weight: bold;
    }
`;
