import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ViewSpinner from 'components/ViewSpinner';
import { getExtraInfo, getWinTeamOrBet } from 'utils/Helper';
import UserService from 'utils/UserService';
import * as AuthActions from 'actions/AuthActions';
import * as UiActions from 'actions/UiActions';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import 'css/SectionCashout.css';
import CashoutService from 'utils/CashoutService';
import Text from 'ui/Text';
import Button from 'ui/Button';
import { formatDate } from 'utils/UiHelper';

class SectionCashout extends Component {
    timer = {};
    dataTimer = null;

    systemNames = [
        'null',
        'singler',
        'doubler',
        'tripler',
        '4-ling',
        '5-ling',
        '6-ling',
        '7-ling',
        '8-ling',
        '9-ling',
        '10-ling',
        '11-ling',
        '12-ling',
        '13-ling',
        '14-ling',
        '15-ling',
    ];

    constructor() {
        super();

        this.state = {
            sales: null,
            inProgress: {},
            isConfirme: {},
        };
    }

    getData() {
        UserService.CashoutData((sales, error) => {
            if (!error) {
                if (sales) {
                    this.setState({ sales: sales });
                }
            } else {
                NotificationManager.error('', error, 4000);
                this.props.authActions.logout();
                this.props.uiActions.showModal('SESSION_POPUP');
            }
        });
    }

    componentDidMount() {
        this.getData();

        this.dataTimer = window.setInterval(() => {
            this.getData();
        }, 5000);
    }

    componentWillUnmount() {
        window.clearInterval(this.dataTimer);
    }

    onClickHandler(sale) {
        if (!this.state.isConfirme[sale.id]) {
            let tmp = this.state.isConfirme;
            tmp[sale.id] = true;
            this.setState({
                isConfirme: tmp,
            });

            this.timer[sale.id] = setTimeout(() => {
                let tmp = this.state.isConfirme;
                tmp[sale.id] = false;
                this.setState({
                    isConfirme: tmp,
                });
            }, 5000);
        } else {
            window.clearTimeout(this.timer[sale.id]);

            let inProgress = this.state.inProgress;
            inProgress[sale.id] = true;
            this.setState({
                inProgress,
            });

            sale.coupons.forEach((v) => {
                sale[v.couponId] = {};
                this.makeCashout(sale.id, v.couponId, v.amountCashoutPotential);
            });
        }
    }

    makeCashout(saleId, cpn, amountCashoutPotential) {
        CashoutService.makeCashout(cpn, amountCashoutPotential).then((res) => {
            if (res.success) {
                const { data } = res;

                if (data === undefined || data === null) {
                    this.setState({
                        success: false,
                        error: 'Server error',
                    });
                } else {
                    if (data.cashout) {
                        window.setTimeout(() => {
                            this.setState({
                                success: true,
                                error: false,
                            });
                        }, 4000);

                        NotificationManager.success(
                            '',
                            'Væddemål lukket',
                            4000
                        );

                        this.props.authActions.updateBalance();

                        this.getData();
                    } else {
                        this.setState({
                            success: false,
                            error: 'Oddset har ændret sig',
                        });
                        NotificationManager.error(
                            '',
                            'Oddset har ændret sig',
                            4000
                        );

                        this.props.authActions.updateBalance();
                        this.getData();
                    }
                }
            } else {
                this.setState({ success: false, error: res.errorMessage });
                NotificationManager.error('', res.errorMessage, 4000);
            }
            this.setState({
                inProgress: {
                    [saleId]: false,
                },
            });
        });
    }

    getBetLabel(bet) {
        return [bet.sportName, bet.groupName, bet.competition]
            .filter((item) => item)
            .join(' / ');
    }

    render() {
        const sales = this.state.sales;

        return (
            <section className="cashout-section">
                {sales && Object.keys(sales).length === 0 ? (
                    <Text condensed size="x1" align="center" color="#333">
                        Ingen væddemål der kan laves cashout på
                    </Text>
                ) : null}
                {this.state.success ? (
                    <p className="p10 success message">Væddemål lukket</p>
                ) : null}
                {this.state.error ? (
                    <p className="p10 error message">{this.state.error}</p>
                ) : null}
                {sales ? (
                    Object.keys(sales)
                        .reverse()
                        .map((key) => {
                            const sale = sales[key];
                            let couponsFlags = {};

                            return (
                                <div key={key} className="gray-area">
                                    <div className="sale-label">
                                        {'ID: ' +
                                            sale.id +
                                            ' - ' +
                                            formatDate(
                                                sale.saleDate,
                                                'DD. MMMM'
                                            )}
                                    </div>

                                    <div className="pv10">
                                        {sale.coupons.map((item) =>
                                            item.betList
                                                .filter((item) => {
                                                    if (
                                                        couponsFlags[
                                                            item.marketName +
                                                                item.odds +
                                                                sale.id
                                                        ]
                                                    ) {
                                                        return false;
                                                    } else {
                                                        couponsFlags[
                                                            item.marketName +
                                                                item.odds +
                                                                sale.id
                                                        ] = true;

                                                        return true;
                                                    }
                                                })
                                                .map((bet, ind) => {
                                                    return (
                                                        <div
                                                            className="mb10"
                                                            key={ind}
                                                        >
                                                            <div className="bet-label">
                                                                {bet.marketTypeName ===
                                                                'Asian'
                                                                    ? bet.marketTypeName +
                                                                      ' '
                                                                    : ''}{' '}
                                                                {bet.marketName}
                                                            </div>
                                                            <div className="bet-winner">
                                                                {getWinTeamOrBet(
                                                                    bet
                                                                )}
                                                                {getExtraInfo(
                                                                    bet
                                                                )}
                                                            </div>
                                                            {bet.result ? (
                                                                <div className="bet-result">
                                                                    Resultat:{' '}
                                                                    {bet.result !==
                                                                    'Cancel'
                                                                        ? bet.result
                                                                        : 'Annulleret'}
                                                                </div>
                                                            ) : null}
                                                            <div className="bet-competition-label">
                                                                {this.getBetLabel(
                                                                    bet
                                                                )}
                                                            </div>
                                                            <div className="bet-odds-label">
                                                                <i>
                                                                    Odds:{' '}
                                                                    <span className="bet-odds-value">
                                                                        {bet.odds.toFixed(
                                                                            2
                                                                        )}
                                                                    </span>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                        )}
                                    </div>

                                    <div className="divider" />

                                    <div className="f13 system-label">
                                        {sale.systemText}
                                    </div>

                                    <div className="">
                                        <div className="f13 indsats-label">
                                            Indsats:{' '}
                                            <span className="indsats-value">
                                                {sale.totalStake.toFixed(2)} kr.
                                            </span>
                                        </div>
                                        <div className="f13 total-prize-label">
                                            Mulig gevinst:{' '}
                                            <span className="total-prize-value">
                                                {sale.totalPrize.toFixed(2)} kr.
                                            </span>
                                        </div>
                                    </div>

                                    <div className="p10">
                                        {!this.state.inProgress[key] ? (
                                            <Button
                                                margin="0 auto"
                                                padding="10px"
                                                fontSize="14px"
                                                width="180px"
                                                active
                                                onClick={this.onClickHandler.bind(
                                                    this,
                                                    sale
                                                )}
                                            >
                                                {!this.state.isConfirme[key]
                                                    ? 'Cashout: ' +
                                                      sale.totalCashout.toFixed(
                                                          2
                                                      ) +
                                                      ' kr.'
                                                    : 'Bekræft cashout for ' +
                                                      sale.totalCashout.toFixed(
                                                          2
                                                      ) +
                                                      ' kr.'}
                                            </Button>
                                        ) : (
                                            <ViewSpinner />
                                        )}
                                    </div>

                                    <NotificationContainer />
                                </div>
                            );
                        })
                ) : (
                    <ViewSpinner />
                )}
            </section>
        );
    }
}

function mapStateToProps(state) {
    return { auth: state.auth, betslip: state.betslip };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionCashout);
