import styled, { css } from 'styled-components';
import Text from 'ui/Text';
import Button from 'ui/Button';
import { detectMobile } from 'utils/UiHelper';
import { breakpoint } from 'constants/breakpoints';
import Icon from '../../ecoui/Icon';

export const OverlayWrapper = styled.div`
    &:before {
        content: ' ';
        display: ${({ showOverlay }) => (showOverlay ? 'block' : 'none')};
        position: fixed;
        width: 100vw;
        background: rgba(0, 0, 0, 0.7);
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 10;
    }
    &.spilPage {
        &:before {
            width: calc(100vw * 100);
            height: calc(100vh * 100);
            left: calc(-100vw * 3);
            top: calc(-100vh * 3);
        }
    }
    &.terminal {
        height: 100vh;
        width: 100%;
    }
`;
// Header styles
export const BetslipWrapper = styled.div`
    position: ${({ innerComponentMode }) =>
        innerComponentMode ? 'absolute' : 'fixed'};
    width: 477px;
    z-index: 10;
    max-height: 90vh;
    height: auto;
    transition: transform 200ms ease-in-out, bottom 200ms ease-in-out;

    ${({ fullOpen, openPrice, hideHeader }) =>
        fullOpen || openPrice
            ? css`
                  transform: translateY(0%);
                  bottom: ${detectMobile() ? '60px' : '0'};
              `
            : css`
                  transform: translateY(100%);
                  bottom: ${detectMobile()
                      ? hideHeader
                          ? '64px'
                          : '114px'
                      : '54px'};
              `};

    ${detectMobile() &&
        css`
            width: calc(100% - 30px);
        `};
    &.terminal {
        width: 600px;
        right: 100px;
    }

    @media screen and (min-width: 1025px) and (max-width: 1365px) {
        width: ${!detectMobile() && '477px'};
    }
    @media screen and (min-width: 1366px) and (max-width: 1600px) {
        width: ${!detectMobile() && '462px'};
    }
    @media screen and (max-width: 768px), (max-height: 390px) {
        left: auto;
        ${({ fullOpen }) =>
            fullOpen &&
            css`
                z-index: 11;
                bottom: 0;
            `};
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        left: 0;
    }

    ${({ innerComponentMode }) => (innerComponentMode ? 'bottom: 0;' : '')};
`;
export const BetslipContent = styled.div`
    height: auto;
    width: auto;
    box-sizing: border-box;
    display: block;
    &.terminal {
        box-shadow: 0 5px 25px #cdd6f2;
    }
`;
const warningWrapperStyle = css`
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto 12px;
    border-radius: 5px;
    /*box-shadow: 5px 10px 15px -5px rgb(0, 0, 0, 50%);*/
    box-shadow: none;
    box-sizing: border-box;
    @media screen and ${breakpoint.M} {
        box-shadow: none;
    }
`;
const bigWinWarningWrapperStyle = css`
    display: flex;
    align-items: center;
    width: 67%;
    margin: 0 auto 12px;
    margin-top: 16px;
    border-radius: 5px;
    box-sizing: border-box;
    @media screen and ${breakpoint.M} {
        box-shadow: none;
    }
`;
export const MaxBetPriceError = styled.div`
    ${warningWrapperStyle};
    background-color: #f60e0f;
    position: absolute;
    padding: 8px 0;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
`;
export const ErrorMessageWrapper = styled.div`
    ${warningWrapperStyle};
    background-color: #fbf5e5;
    min-height: 40px;
    padding: 8px 25px 8px 15px;
    @media screen and (min-width: 1024px) {
        &.skEmpty {
            margin-bottom: 20px !important;
        }
    }
`;

export const BigWinErrorMessageWrapper = styled.div`
    ${bigWinWarningWrapperStyle};
    background-color: #fbf5e5;
    min-height: 40px;
    padding: 8px 25px 8px 15px;
`;
export const WarningIcon = styled.div`
    border-radius: 100%;
    min-width: 22px;
    width: 22px;
    height: 22px;
    background-color: #fcbf2b;
    display: flex;
    align-items: center;
    margin-right: 8px;
`;
export const BetslipHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #edf1f8;
    height: 54px;
    box-sizing: border-box;
    cursor: pointer;
    border-top: 8px solid #1c3e60;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    ${({ readOnly }) =>
        readOnly
            ? css`
                  border-radius: 5px;
                  padding: 10px 30px;
                  border-top: none;
              `
            : css`
                  border-top: 8px solid #1c3e60;
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
              `};
    &.terminal {
        cursor: auto;
        padding: 0 80px;
        height: auto;
    }
    &.spilklub {
        border: none;
    }
`;
export const HeaderItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 0;

    ${({ active }) =>
        active &&
        css`
            border-radius: 5px;
            background-color: #d7dee8;
            padding: 4px 20px;
            cursor: pointer;
            &:hover {
                background-color: #c1c7d0;
            }
            transition: background-color 300ms ease-in-out;
        `};
`;
export const CenterElements = styled.div`
    display: flex;
    flex: 6;
    justify-content: space-between;
    ${({ readOnly }) => !readOnly && 'margin-right: 10px'};
`;
export const SideElements = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    height: 100%;
    width: 100%;
    align-items: center;
    &:last-child {
        cursor: pointer;
    }
`;
export const ArrowImageWrapper = styled.div`
    cursor: pointer;
    width: 34px;
    height: 34px;
    border-radius: 5px;
    background-color: #1c3e60;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const ArrowImage = styled(Icon)`
    width: 8px;
    height: 8px;
    transition: transform 200ms ease-in-out, width 200ms ease-in-out,
        height 200ms ease-in-out;
    transform: rotate(${({ open }) => (open ? '90deg' : '-90deg')});
`;

// Slip styles
export const SlipsWrapper = styled.div`
    padding: 0 8px 16px;
    display: flex;
    flex-direction: column;
    background: ${detectMobile() ? '#fff' : '#F5F7FC'};

    max-height: calc(90vh - 60px);
    overflow: auto;
    ${detectMobile() &&
        css`
            padding-bottom: 115px;
            box-sizing: border-box;
            @media screen and (max-width: 375px) {
                padding-bottom: 185px;
            }
        `};
`;
export const SlipItem = styled.div`
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding-right: 15px;
    &:nth-child(odd) {
        background-color: #f5f7fc;
    }
`;
export const EventNameWrapper = styled.div`
    display: flex;
    padding: ${({ terminal }) => (terminal ? '25px 15px' : '15px 14px')};
    position: relative;
    align-items: center;
    max-width: 64%;
    &.terminalHistory {
        padding: 12px 15px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    ${({ canceled }) =>
        canceled &&
        css`
            &:before {
                content: ' ';
                display: block;
                position: absolute;
                height: 2px;
                width: calc(100% - 20px);
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                background-color: #111111;
            }
        `};

    ${Text} {
        &:first-child {
            margin-right: 8px;
        }
        &:last-child {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    @media screen and (max-width: 768px) {
        max-width: 55%;
    }
    ${({ short }) =>
        short &&
        css`
            @media screen and (max-width: 768px) {
                max-width: 49%;
            }
            @media screen and (max-width: 360px) {
                max-width: 45%;
            }
        `};
`;
export const StyledButton = styled(Button)`
    min-width: 300px;
    &:disabled {
        background: #fff7cc;
        color: #9198aa;
    }
`;

export const StyledPrintButton = styled(Button)`
    min-width: 300px;
    background: rgb(28 62 96);
    color: #ffffff;
    &:disabled {
        background: rgb(68 107 146);
        color: #ffffff;
    }
`;
export const ResultsWrapper = styled.div`
    display: flex;
    position: relative;
    padding-right: 11px;
    height: 100%;
    min-height: 40px;
    align-items: center;
    justify-content: center;
`;

const betsStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    ${Text} {
        margin: 0 4px;
    }
`;
export const BetsLeft = styled.div`
    ${betsStyles};
    margin-right: 12px;
    width: 56px;
    box-sizing: content-box;
    div,
    p {
        box-sizing: content-box;
    }
`;
export const BetsRight = styled.div`
    ${betsStyles};
    margin-left: 4px;
    width: 46px;
    box-sizing: content-box;
    div,
    p {
        box-sizing: content-box;
    }
`;
export const Divider = styled.div`
    width: 1px;
    height: 100%;
    min-height: 40px;
    background-color: #e3e8f2;
`;
export const ResultsItem = styled.div`
    display: flex;
    align-items: center;
    border-radius: 100%;
    background-color: ${({ color }) => color || 'transparent'};
    ${({ setted }) =>
        setted &&
        css`
            p {
                color: #fff;
            }
        `};
`;

export const WildCardResult = styled.div`
    display: flex;
    align-items: center;
    margin-right: 10px;
    ${({ result }) =>
        result &&
        css`
            p {
                background-color: #18aa1b;
                border-radius: 25px;
            }
            margin-right: 2px;
        `};
`;
export const ActionsWrapper = styled.div`
    ${detectMobile() &&
        css`
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            padding: 20px 0;
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 0)
            );
        `};
`;

// Bet prices styles
export const BetPriceWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 5px 10px;
    background-color: #edf1f8;
`;
export const PriceItem = styled.div`
    flex: 1 0 25%;
    margin: 5px;
    height: 44px;
    border: 1px solid #c6ccdb;
    border-radius: 5px;
    line-height: 44px;
    ${({ active }) => active && 'background-color: #D7DEE8'};
    cursor: pointer;
`;

export const StyledText = styled(Text)`
    cursor: pointer;
`;
