import React, { Component } from 'react';
import '../css/ViewSpinner.css';

export default class ViewSpinner extends Component {
    render() {
        return (
            <div className="view-spinner" style={this.props.style}>
                <i
                    className={`fa fa-circle-o-notch fa-spin fa-2x fa-fw ${
                        this.props.white ? 'white' : ''
                    }`}
                />
            </div>
        );
    }
}
