import React from 'react';
import { ChooseDate } from '@it25syv/25syv-ui';
import { bindActionCreators } from 'redux';
import { hideModal as HideModal } from '../actions/UiActions';
import { connect } from 'react-redux';
import { DATEPICKER_THEME_CONFIG } from 'constants/ui';
import { detectMobile } from '../utils/UiHelper';
import 'react-infinite-calendar/styles.css';
import { SetTransactionHistoryDate } from '../actions/TransactionHistoryActions';

const themeDatePicker = DATEPICKER_THEME_CONFIG;

const ChooseDateModal = ({ hideModal, setTransactionHistoryDate }) => {
    return (
        <>
            <ChooseDate
                closeChooseDate={() => hideModal('CHOSE_DATE_MODAL')}
                datePickerTheme={themeDatePicker}
                sendChosenDate={(date) => {
                    setTransactionHistoryDate(date);
                    hideModal('CHOSE_DATE_MODAL');
                }}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: bindActionCreators(HideModal, dispatch),
        setTransactionHistoryDate: bindActionCreators(
            SetTransactionHistoryDate,
            dispatch
        ),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChooseDateModal);
