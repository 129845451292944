import React from 'react';
import {
    BetsLeft,
    BetsRight,
    EventNameWrapper,
    ResultsWrapper,
    SlipItem,
} from 'features/Betslip/styled';
import Text from 'ui/Text';
import { TableHeaderWrapper } from 'features/Big9History/styled';

const TableHeader = () => {
    const left = ['1', 'X', '2'];
    const right = ['O', 'U'];
    return (
        <TableHeaderWrapper>
            <SlipItem
                style={{
                    backgroundColor: 'transparent',
                }}
            >
                <EventNameWrapper
                    style={{
                        padding: '0 14px',
                        maxWidth: 'none',
                    }}
                >
                    <Text condensed size="x1" color={'#333'} padding={'0'}>
                        Kampe
                    </Text>
                </EventNameWrapper>
            </SlipItem>
            <ResultsWrapper
                style={{
                    minHeight: 'auto',
                    paddingRight: '15px',
                }}
            >
                <BetsLeft>
                    {left.map((el, index) => (
                        <Text
                            key={index}
                            condensed
                            size="x1"
                            color={'#333'}
                            padding="4px"
                            align="center"
                            width="8px"
                        >
                            {el}
                        </Text>
                    ))}
                </BetsLeft>
                <BetsRight>
                    {right.map((el, index) => (
                        <Text
                            key={index}
                            condensed
                            size="x1"
                            color={'#333'}
                            padding="4px"
                            align="center"
                            width="8px"
                        >
                            {el}
                        </Text>
                    ))}
                </BetsRight>
            </ResultsWrapper>
        </TableHeaderWrapper>
    );
};

export default React.memo(TableHeader);
