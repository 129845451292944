import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import mobilePay from 'images/mobilepay.svg';
import creditCard from 'images/creditcards.svg';
import { detectMobile } from 'utils/UiHelper';

const ButtonsRow = styled.div`
    display: flex;
    justify-content: ${detectMobile() ? 'space-between' : 'center'};
    padding: ${detectMobile() ? '0px 15px' : '0 0 25px'};
    align-items: center;
    margin: ${detectMobile() ? '' : '16px 80px 0'};
    border-bottom: ${detectMobile() ? 'none' : '1px solid #e1e1e1'};
    position: relative;
    background: #f8f8f8;
    flex-direction: row;
    z-index: 3;
`;
const ImageStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        height: ${detectMobile() ? '18px' : 'auto'};
    }
`;

const ImageStyleMobile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        height: ${detectMobile() ? '29px' : 'auto'};
    }
`;

const PaymentMethodWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${detectMobile() ? '5px' : '0 10px'};
    flex: 1;
`;
const BaseButton = styled.div`
    border: 3px solid #cbcbcb;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    width: ${detectMobile() ? '100%' : '210px'};
    height: ${detectMobile() ? '52px' : '43px'};
    margin: ${detectMobile() ? '0px' : '0 10px'};
`;

const Button = styled(BaseButton)`
    border-color: ${({ active }) => (active ? '#0daa13' : '#cbcbcb')};
    p {
        font-size: 10px;
        color: #333333;
        font-weight: 700;
        margin: 0 0 5px;
    }
`;

const PaymentLabel = styled.span`
    color: #9198aa;
    font-size: 14px;
`;

const Title = styled.p`
    color: #797979;
    padding: ${detectMobile() ? '0px 17px' : ''};
    font-size: 16px;
    text-align: ${detectMobile() ? 'left' : 'center'};
    line-height: 19px;
`;

const ApplePayBtn = styled(BaseButton)`
    border: 3px solid ${({ active }) => (active ? '#0daa13' : '#cbcbcb')};

    @supports (-webkit-appearance: -apple-pay-button) {
        .apple-pay-button {
            display: inline-block;
            width: 100%;
            height: 100%;
            -webkit-appearance: -apple-pay-button;
        }
        .apple-pay-button > * {
            display: none;
        }
        .apple-pay-button-black {
            -apple-pay-button-style: black;
        }
        .apple-pay-button-white {
            -apple-pay-button-style: white;
        }
        .apple-pay-button-white-with-line {
            -apple-pay-button-style: white-outline;
        }
    }
    @supports not (-webkit-appearance: -apple-pay-button) {
        .apple-pay-button {
            --apple-pay-scale: 1; /* (height / 32) */
            display: inline-flex;
            justify-content: center;
            font-size: 12px;
            border-radius: 5px;
            padding: 0px;
            box-sizing: border-box;
            min-width: ${detectMobile() ? '100%' : '210px'};
            min-height: 32px;
            max-height: 64px;
        }
        .apple-pay-button-black {
            background-color: black;
            color: white;
        }
        .apple-pay-button-white {
            background-color: white;
            color: black;
        }
        .apple-pay-button-white-with-line {
            background-color: white;
            color: black;
            border: 0.5px solid black;
        }
        .apple-pay-button.apple-pay-button-black > .logo {
            background-image: -webkit-named-image(apple-pay-logo-white);
            background-color: black;
        }
        .apple-pay-button.apple-pay-button-white > .logo {
            background-image: -webkit-named-image(apple-pay-logo-black);
            background-color: white;
        }
        .apple-pay-button.apple-pay-button-white-with-line > .logo {
            background-image: -webkit-named-image(apple-pay-logo-black);
            background-color: white;
        }
        .apple-pay-button > .text {
            font-family: -apple-system;
            font-size: calc(1em * var(--apple-pay-scale));
            font-weight: 300;
            align-self: center;
            margin-right: calc(2px * var(--apple-pay-scale));
        }
        .apple-pay-button > .logo {
            width: calc(35px * var(--scale));
            height: 100%;
            background-size: 100% 60%;
            background-repeat: no-repeat;
            background-position: 0 50%;
            margin-left: calc(2px * var(--apple-pay-scale));
            border: none;
        }
    }
`;

const PaymentType = ({ setPayType, payType }) => {
    useEffect(
        () => {
            const newPayType =
                window.ApplePaySession && ApplePaySession.canMakePayments()
                    ? 'applePay'
                    : 'card';
            setPayType(newPayType);
        },
        [setPayType]
    );

    const handleCardClick = () => {
        setPayType('card');
    };

    const handleMobilePayClick = () => {
        setPayType('mobilePay');
    };

    const handleApplePayClick = () => {
        setPayType('applePay');
    };

    return (
        <div>
            <Title>Vælg betalingsmetode:</Title>
            <ButtonsRow>
                <PaymentMethodWrapper>
                    <Button
                        active={payType === 'card'}
                        onClick={handleCardClick}
                    >
                        <ImageStyle>
                            <img src={creditCard} alt="creditCard" />
                        </ImageStyle>
                    </Button>
                    <PaymentLabel>Betalingskort</PaymentLabel>
                </PaymentMethodWrapper>

                <PaymentMethodWrapper>
                    <Button
                        active={payType === 'mobilePay'}
                        onClick={handleMobilePayClick}
                    >
                        <ImageStyleMobile>
                            <img src={mobilePay} alt="MobilePay" />
                        </ImageStyleMobile>
                    </Button>
                    <PaymentLabel>Mobilepay</PaymentLabel>
                </PaymentMethodWrapper>
                {window.ApplePaySession && ApplePaySession.canMakePayments() ? (
                    <PaymentMethodWrapper>
                        <ApplePayBtn
                            active={payType === 'applePay'}
                            onClick={handleApplePayClick}
                        >
                            <div className="apple-pay-button apple-pay-button-white">
                                <span className="logo" />
                            </div>
                        </ApplePayBtn>
                        <PaymentLabel>Applepay</PaymentLabel>
                    </PaymentMethodWrapper>
                ) : null}
            </ButtonsRow>
        </div>
    );
};

export default PaymentType;
