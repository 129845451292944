import omit from 'lodash/omit';

function root(state) {
    return state.big9;
}

export function coupon(state) {
    return root(state).couponData;
}

export function maxBetPrice(state) {
    return root(state).maxBetPrice;
}

export function extId(state) {
    return coupon(state).extId;
}

export function combinationPrice(state) {
    return coupon(state).combinationPrice;
}

export function combinationCount(state) {
    return coupon(state).combinationCount;
}

export function wildcard(state) {
    return coupon(state).wildcard;
}

export function selections(state) {
    //@TODO: Figure out why row with { false: {...} } is added to outcomes in case of canceled event
    // it breaks spilklub coupon updates
    return omit(coupon(state).outcomes, ['false']);
}
