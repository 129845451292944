import React, { Component } from 'react';
import ViewPageToolbar from 'components/ViewPageToolbar';
import { browserHistory } from 'react-router';
import RestService from 'utils/RestService';
import UserService from 'utils/RestService';
import { notDesktop } from 'utils/UiHelper';

export default class ParentAuthPage extends Component {
    restService = null;
    userService = null;

    constructor() {
        super();

        this.state = {
            pageTitle: '',
            errorMessage: null,
            successMessage: null,
        };

        this.restService = RestService;
        this.userService = UserService;
    }

    setErrorMessage(error) {
        this.setState({ errorMessage: error, successMessage: null });
    }

    setSuccessMessage(text) {
        this.setState({ errorMessage: null, successMessage: text });
    }

    setPageTitle(title) {
        this.setState({ pageTitle: title });
    }

    onBackBtnHandler() {
        browserHistory.goBack();
    }

    getHeaderView(backButtonHandler) {
        if (notDesktop()) {
            return (
                <ViewPageToolbar
                    title={this.state.pageTitle}
                    onBackBtn={this.onBackBtnHandler.bind(
                        this,
                        backButtonHandler
                    )}
                />
            );
        } else {
            return null;
        }
    }

    getErrorView(error, isObject) {
        return error ? (
            <div className="error message text-center p10">
                <i className="fa fa-exclamation-triangle" aria-hidden="true" />
                {isObject ? error.msg : this.getError(error)}
            </div>
        ) : null;
    }

    getSuccessView(text) {
        return text ? (
            <div className="success message text-center p10">
                <i className="fa fa-check" aria-hidden="true" />
                {text}
            </div>
        ) : null;
    }

    getError(msg) {
        let t = msg;

        switch (msg) {
            case 'EMAIL_WRONG':
                t = 'Forkert Email addres';
                break;
            case 'PASSWORDPOLICY_LOW':
                t = 'Din kode skal indeholde mindst 1 lille bogstav';
                break;
            case 'PASSWORDPOLICY_LEN':
                t = 'Din kode skal være mindst 8 karakterer';
                break;
            case 'PASSWORDPOLICY_NUM':
                t = 'Din kode skal indeholde mindst 2 tal';
                break;
            case 'PASSWORDPOLICY_UPP':
                t = 'Din kode skal indeholde mindst 1 stort bogstav';
                break;
            case 'DO_NOT_MATCH':
                t = 'Ny kode og gentagelse er ikke ens.';
                break;
            case 'PASSWORD_EMPTY':
                t = 'Indtast password';
                break;
            case 'PASSWOLDINCORRECT':
                t = 'Du angav det forkerte kode';
                break;
            default:
                //t = "Uforventet fejl";
                break;
        }

        return t;
    }

    render() {
        return null;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.auth.user && !nextProps.auth.user) {
            browserHistory.push('/sport/');
        }
    }
}
