import React, { Component, forwardRef } from 'react';
import Select from 'react-select';
import Switch from 'react-ios-switch';
import 'css/ViewInput.scss';
import 'react-select/dist/react-select.css';
import 'react-ios-switch/build/bundle.css';
import { desktopOnly, isTerminal } from 'utils/UiHelper';

const FORBIDDEN_VALUES = ['0', '.', ','];

const Input = forwardRef(function Input(props, ref) {
    const { disabled, onChangeValue, onChange, ...rest } = props;
    const manuals = {};
    //const oddsRef = useRef(null);

    if (disabled) {
        manuals.disabled = 'disabled';
    }
    return (
        <input
            {...rest}
            {...manuals}
            onChange={onChangeValue || onChange}
            ref={ref}
        />
    );
});

Input.defaultProps = {
    type: 'text',
    onChange: () => {},
};

const REQUIRED = 'required';
const ALPHABETIC = 'alphabetic';
const NUMERIC = 'numeric';
const EMAIL = 'email';
const MIN = 'min';
const MIN_NUMBER = 'min-number';
const MIN_ODDS = 'min-odds';
const MAX = 'max';
const MAX_NUMBER = 'max-number';
const NO_SPACES = 'nospaces';
const REGEX = 'regex';
const PASSWORD = 'password';
const USERNAME = 'username';

const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const alphabeticRegex = /^[a-zA-Z æøåöÆØÅÖ]+$/;
const numericRegex = /^\d+$/;
const passwordRegex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{6,})/;
const userNameRegex = /^((?=.*[a-zA-Z]))(?=.{6,})/;

class ViewInputField extends React.Component {
    icon = null;

    constructor() {
        super();

        this.state = {
            value: '',
            error: null,
            viewInput: null,
            showCurrency: true,
        };
        this.oddsRef = React.createRef();
        this.onCurrencyClickHandler = this.onCurrencyClickHandler.bind(this);
    }

    onCurrencyClickHandler() {
        this.oddsRef.current.focus();
    }

    onChangeValue(event) {
        let value = event.target.value;
        this.setState({ value });
        if (this.props.valueHandler) {
            this.props.valueHandler(this.props.name, value);
        }
    }

    onBlurHandler(event) {
        this.validateField();
    }

    reset() {
        if (this.props.valueHandler) {
            this.props.valueHandler(this.props.name, '');
        }

        this.setState({
            value: '',
        });
    }

    onFocusHandler(event) {
        if (this.props.onFocusHandler) {
            this.props.onFocusHandler(
                event,
                this.props.valueHandler ? this.props.valueHandler : null,
                this.reset.bind(this)
            );
            this.setState({ error: null });
        } else {
            this.setState({ error: null });
        }
    }

    validateField(val) {
        const value = val ? val : this.state.value;

        const validate = this.props.validate;
        const min = this.props.min ? parseInt(this.props.min, 10) : -1;
        const max = this.props.max ? parseInt(this.props.max, 10) : -1;

        const minOdds = this.props.minOdds
            ? parseInt(this.props.minOdds, 10)
            : -1;

        const minNumber = this.props.minNumber
            ? parseInt(this.props.minNumber, 10)
            : -1;
        const maxNumber = this.props.maxNumber
            ? parseInt(this.props.maxNumber, 10)
            : -1;

        let isValid = true;

        if (validate.indexOf(REQUIRED) > -1 && value === '') {
            this.setState({ error: REQUIRED });

            isValid = false;
        }

        if (validate.indexOf(PASSWORD) > -1 && !passwordRegex.test(value)) {
            this.setState({ error: PASSWORD });

            isValid = false;
        }
        if (validate.indexOf(USERNAME) > -1 && !userNameRegex.test(value)) {
            this.setState({ error: USERNAME });

            isValid = false;
        }

        if (validate.indexOf(ALPHABETIC) > -1 && !alphabeticRegex.test(value)) {
            this.setState({ error: ALPHABETIC });

            isValid = false;
        }
        if (validate.indexOf(NUMERIC) > -1 && !numericRegex.test(value)) {
            this.setState({ error: NUMERIC });

            isValid = false;
        }

        if (
            isValid &&
            validate.indexOf(EMAIL) > -1 &&
            !emailRegEx.test(value)
        ) {
            this.setState({ error: EMAIL });

            isValid = false;
        }

        if (
            isValid &&
            validate.indexOf(NO_SPACES) > -1 &&
            !emailRegEx.test(value)
        ) {
            this.setState({ error: NO_SPACES });

            isValid = false;
        }

        if (isValid && min > -1 && value.length < min) {
            this.setState({ error: MIN });

            isValid = false;
        }

        if (isValid && max > -1 && value.length > max) {
            this.setState({ error: MAX });

            isValid = false;
        }

        if (
            value !== 0 &&
            value !== '' &&
            isValid &&
            minNumber > -1 &&
            value < minNumber
        ) {
            this.setState({ error: MIN_NUMBER });

            isValid = false;
        }

        if (
            value !== 0 &&
            value !== '' &&
            isValid &&
            minOdds > -1 &&
            value < minOdds
        ) {
            this.setState({ error: MIN_ODDS });

            isValid = false;
        }

        if (
            value !== 0 &&
            value !== '' &&
            isValid &&
            maxNumber > -1 &&
            value > maxNumber
        ) {
            this.setState({ error: MAX_NUMBER });

            isValid = false;
        }

        if (
            isValid &&
            validate.indexOf(REGEX) > -1 &&
            !this.props.regex.test(value)
        ) {
            this.setState({ error: REGEX });

            isValid = false;
        }

        if (isValid) {
            this.setState({ error: null });
        }

        return isValid;
    }

    errorMessage() {
        let errorMessage = null;

        switch (this.state.error) {
            case REQUIRED:
                errorMessage = 'Skal udfyldes';
                break;
            case EMAIL:
                errorMessage = 'Ugyldig email';
                break;
            case MIN:
                errorMessage = 'Minimum ' + this.props.min + ' tegn';
                break;
            case MAX:
                errorMessage = 'Maximum ' + this.props.max + ' tegn';
                break;
            case MIN_NUMBER:
                errorMessage =
                    'Minimum indsats er ' + this.props.minNumber + ' kr.';
                break;
            case MAX_NUMBER:
                errorMessage =
                    'Maximum indsats er ' + this.props.maxNumber + ' kr.';
                break;
            case NO_SPACES:
                errorMessage = 'Ugyldig værdi';
                break;
            case REGEX:
                errorMessage = 'Ugyldig værdi';
                break;
            case ALPHABETIC:
                errorMessage = 'Feltet må kun indeholde bogstaver';
                break;
            case NUMERIC:
                errorMessage = 'Feltet bør kun indeholde cifre';
                break;
            case PASSWORD:
                errorMessage =
                    'Kodeord skal minimum indeholde 1 stort bogstav, 1 tal og 6 tegn';
                break;
            case USERNAME:
                errorMessage = 'Brugernavn skal minimum indeholde 6 tegn';
                break;
            default:
                errorMessage = null;
        }

        if (
            errorMessage &&
            this.props.placeholder &&
            this.props.pushInputError &&
            typeof this.props.pushInputError === 'function'
        ) {
            this.props.pushInputError(
                `${this.props.placeholder}: ${errorMessage}`
            );
        }

        return errorMessage ? (
            <div className={`validation-error `}>{errorMessage}</div>
        ) : null;
    }

    componentWillMount() {
        if (this.props.value) {
            this.setState({ value: this.props.value });
        }

        if (this.props.icon) {
            this.icon = (
                <i className={'fa ' + this.props.icon} aria-hidden="true" />
            );
        }

        if (this.props.checkHandler) {
            this.props.checkHandler(
                this.props.name,
                this.validateField.bind(this)
            );
        }
    }

    componentWillReceiveProps(newProps) {
        let newVal = newProps.value;

        if (newVal !== undefined && newVal !== this.state.value) {
            this.setState({ value: newVal });
        }
    }

    render() {
        const className = this.props.className
            ? this.props.className
            : 'view-text-input';

        return (
            <div>
                <div
                    className={`${className} ${this.state.error === MIN_ODDS &&
                        'min-odds-error'} ${this.state.value > 0 &&
                        'hasValue'} ${this.props.disabled && 'disabled'}`}
                >
                    <div className="input">
                        <Input
                            onKeyPress={this.props.onKeyPress}
                            id={this.props.id || this.props.name}
                            disabled={this.props.disabled}
                            type={this.props.type || 'text'}
                            name={this.props.name}
                            pattern={this.props.pattern}
                            value={this.state.value}
                            placeholder={this.props.placeholder}
                            onChangeValue={this.onChangeValue.bind(this)}
                            onFocus={this.onFocusHandler.bind(this)}
                            onBlur={this.onBlurHandler.bind(this)}
                            ref={this.oddsRef}
                        />
                        {this.props.showCurrency ? (
                            <span
                                onClick={this.onCurrencyClickHandler}
                                className={'currency'}
                            >
                                kr.
                            </span>
                        ) : null}
                        {this.errorMessage()}
                    </div>
                    {!this.props.withoutLabel && (
                        <label htmlFor={this.props.id || this.props.name}>
                            {this.icon || ''}{' '}
                            {this.props.label ||
                                this.props.placeholderLabel ||
                                this.props.placeholder ||
                                ''}
                        </label>
                    )}
                </div>
            </div>
        );
    }

    componentDidMount() {
        if (this.props.validateField) {
            this.props.validateField(this.validateField);
        }
    }
}

class ViewTextInput extends Component {
    render() {
        return (
            <ViewInputField
                label={this.props.label}
                placeholderLabel={this.props.placeholderLabel}
                withoutLabel={this.props.withoutLabel}
                valueHandler={this.props.valueHandler}
                checkHandler={this.props.checkHandler}
                icon={this.props.icon}
                type="text"
                name={this.props.name}
                value={this.props.value}
                placeholder={this.props.placeholder}
                validate={
                    this.props.validate
                        ? this.props.validate === 'empty'
                            ? ''
                            : this.props.validate
                        : REQUIRED
                }
                regex={this.props.regex}
                min={this.props.min}
                max={this.props.max}
                disabled={this.props.disabled}
                pushInputError={this.props.pushInputError}
            />
        );
    }
}

class ViewEmailInput extends Component {
    render() {
        return (
            <ViewInputField
                label={this.props.label}
                placeholderLabel={this.props.placeholderLabel}
                valueHandler={this.props.valueHandler}
                checkHandler={this.props.checkHandler}
                icon={this.props.icon}
                type="email"
                autocapitalize="none"
                name={this.props.name}
                value={this.props.value}
                placeholder={this.props.placeholder}
                pushInputError={this.props.pushInputError}
                validate={
                    this.props.validate
                        ? this.props.validate
                        : REQUIRED + ' ' + EMAIL
                }
            />
        );
    }
}

class ViewNumberInput extends Component {
    onChangeHandler(name, val) {
        let value = val;
        if (this.props.minOdds > 0 && FORBIDDEN_VALUES.includes(value)) {
            value = '0';
        }

        if (value.includes(',')) {
            value = value.replace(',', '.');
        }
        this.props.valueHandler(this.props.name, value);
    }

    render() {
        return (
            <ViewInputField
                label={this.props.label}
                placeholderLabel={this.props.placeholderLabel}
                valueHandler={this.onChangeHandler.bind(this)}
                checkHandler={this.props.checkHandler}
                withoutLabel={this.props.withoutLabel}
                icon={this.props.icon}
                type={this.props.type || 'number'}
                pattern={this.props.pattern}
                name={this.props.name}
                value={this.props.value}
                placeholder={this.props.placeholder}
                validate={this.props.validate ? this.props.validate : REQUIRED}
                pushInputError={this.props.pushInputError}
            />
        );
    }
}

class ViewPasswordInput extends Component {
    render() {
        return (
            <ViewInputField
                label={this.props.label}
                placeholderLabel={this.props.placeholderLabel}
                valueHandler={this.props.valueHandler}
                checkHandler={this.props.checkHandler}
                icon={this.props.icon}
                type="password"
                name={this.props.name}
                value={this.props.value}
                placeholder={this.props.placeholder}
                validate={this.props.validate ? this.props.validate : REQUIRED}
                min={this.props.min}
                max={this.props.max}
                pushInputError={this.props.pushInputError}
            />
        );
    }
}

class ViewOddsInput extends Component {
    onChangeHandler(name, val) {
        let value = val;

        /*if (!value.match(/^\d{0,8}(\.\d{0,2}){0,1}$/)) {
            value = '0';
        }*/
        //TODO
        if (this.props.minOdds > 0 && FORBIDDEN_VALUES.includes(value)) {
            value = '0';
        }

        if (value.includes(',')) {
            value = value.replace(',', '.');
        }

        this.props.valueHandler(this.props.name, value);
    }

    render() {
        if (!isTerminal()) {
            return (
                <ViewInputField
                    label={this.props.label}
                    onFocusHandler={this.props.onFocusHandler}
                    valueHandler={this.onChangeHandler.bind(this)}
                    checkHandler={this.props.checkHandler}
                    className="view-odds-input"
                    type={this.props.type || 'number'}
                    name={this.props.name}
                    value={this.props.val}
                    validate={''}
                    min={this.props.min}
                    minOdds={this.props.minOdds}
                    placeholder={this.props.placeholder}
                    withoutLabel={this.props.withoutLabel}
                    pushInputError={this.props.pushInputError}
                    disabled={this.props.disabled}
                    showCurrency={this.props.showCurrency || true}
                    ref={this.oddsRef}
                />
            );
        } else {
            return (
                <ViewInputField
                    label={this.props.label}
                    onFocusHandler={this.props.onFocusHandler}
                    valueHandler={this.onChangeHandler.bind(this)}
                    checkHandler={this.props.checkHandler}
                    className="view-odds-input"
                    type={desktopOnly() ? 'text' : 'number'}
                    name={this.props.name}
                    value={this.props.val}
                    validate={''}
                    withoutLabel={this.props.withoutLabel}
                    minOdds={this.props.minOdds}
                    minNumber={this.props.minNumber}
                    maxNumber={this.props.maxNumber}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    pushInputError={this.props.pushInputError}
                />
            );
        }
    }
}

class ViewSelect extends Component {
    constructor() {
        super();

        this.state = {
            value: '',
        };
    }

    onChange(option) {
        if (!option) return;
        this.setState({ value: option });

        if (this.props.onSelectHandler) {
            this.props.onSelectHandler(
                option && option.value ? option.value : ''
            );
        }
    }

    componentWillMount() {
        this.setState({ value: this.props.value });
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.value) {
            this.setState({ value: nextProps.value });
        }
    }

    render() {
        return (
            <div className="view-select">
                <label className="p10">{this.props.label}</label>
                <Select
                    name="form-field-name"
                    value={this.state.value}
                    className="select-dropdown"
                    options={this.props.options}
                    placeholder={this.props.placeholder}
                    onChange={this.onChange.bind(this)}
                    searchable={this.props.searchable}
                    clearable={!this.props.isNotClearable}
                />
            </div>
        );
    }
}

class ViewSwitch extends Component {
    onChangeHandler(status) {
        this.setState({ checked: status });

        if (this.props.changeHandler) {
            this.props.changeHandler(status);
        }
    }

    render() {
        const disabled = false;
        const handleColor = '#fff';
        const offColor = '#eee';
        const onColor = '#1c3e60';
        const pendingOffColor = '#ddd';

        return (
            <div className="view-switch p10">
                <label className={this.props.className}>
                    {this.props.children}
                </label>
                <div className="swith-wrapper">
                    <Switch
                        checked={this.props.checked}
                        disabled={disabled}
                        handleColor={handleColor}
                        offColor={offColor}
                        onChange={this.onChangeHandler.bind(this)}
                        onColor={onColor}
                        pendingOffColor={pendingOffColor}
                    />
                </div>
            </div>
        );
    }
}

class ViewCheckbox extends Component {
    onChangeHandler(status) {
        this.setState({ checked: status });

        if (this.props.changeHandler) {
            this.props.changeHandler(status);
        }
    }

    render() {
        const disabled = false;

        return (
            <div className="view-checkbox">
                <label className="container">
                    {this.props.children}
                    <input
                        type="checkbox"
                        checked={this.props.checked}
                        disabled={disabled}
                        onChange={this.onChangeHandler.bind(this)}
                    />
                    <span className="checkmark" />
                </label>
            </div>
        );
    }
}

class ViewRadio extends Component {
    onChange(e) {
        this.props.onChangeHandler(e.target.value);
    }

    render() {
        return (
            <div>
                <label>
                    <input
                        type="radio"
                        name={this.props.name}
                        value={this.props.value}
                        onChange={this.onChange.bind(this)}
                    />{' '}
                    {this.props.children}
                </label>
            </div>
        );
    }
}

class ViewTextArea extends Component {
    render() {
        return (
            <div className="view-textarea">
                <textarea
                    rows="4"
                    cols="50"
                    placeholder={this.props.placeholder}
                    onChange={this.props.valueHandler}
                />
            </div>
        );
    }
}

export {
    ViewTextInput,
    ViewEmailInput,
    ViewNumberInput,
    ViewPasswordInput,
    ViewOddsInput,
    ViewSelect,
    ViewSwitch,
    ViewRadio,
    ViewCheckbox,
    ViewTextArea,
};
