import { makeRequest } from 'utils/REST';
import {
    getVaixBasePath,
    VAIX_HEADER,
    VAIX_LIVE_FAVORITE_URL,
    VAIX_NAVIGATION_ORDER_URL,
    VAIX_LIVE_POPULAR_EVENTS_URL,
    VAIX_POPULAR_EVENTS_URL,
    VAIX_HIGHLIGHTS,
    VAIX_EVENTS_BY_SPORT_ID_URL,
} from '../constants/vaix/vaix_api';

const request = (requestType, url, data, tracking = false) => {
    return makeRequest(
        requestType,
        url,
        data,
        {},
        {
            ...VAIX_HEADER,
        },
        true
    )
        .then((response) => {
            return tracking ? response : response.json();
        })
        .then((data) => {
            return data;
        });
};

const post = (method, data) => {
    return request('post', getVaixBasePath() + method, data, true);
};

const get = (method, data) => {
    return request('get', getVaixBasePath() + method, data);
};

export const createTrackerEvent = (event) => {
    return post(`api/tracker/events`, event);
};

export default {
    getVaixPopularLiveEvents: (userId, offset, count) =>
        get(VAIX_LIVE_POPULAR_EVENTS_URL(userId, offset, count)),
    getVaixPopularEvents: (userId, offset, count) =>
        get(VAIX_POPULAR_EVENTS_URL(userId, offset, count)),
    getVaixPopularEventBySportId: (userId, offsetFrom, count, sportId) =>
        get(VAIX_EVENTS_BY_SPORT_ID_URL(userId, offsetFrom, count, sportId)),
    getVaixNavigationOrder: (userId, offset, count) =>
        get(VAIX_NAVIGATION_ORDER_URL(userId, offset, count)),
    getVaixLiveFavoriteEvents: (userId, offsetFrom, offsetTo, count) =>
        get(VAIX_LIVE_FAVORITE_URL(userId, offsetFrom, offsetTo, count)),
    getVaixHighlights: (userId, offsetTo, count) =>
        get(VAIX_HIGHLIGHTS(userId, offsetTo, count)),
    createTrackerEvent,
};
