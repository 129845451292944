import {
    TERMINAL_VIRTUAL_BETSLIP_ADD_MARKET,
    TERMINAL_VIRTUAL_BETSLIP_PENDING,
    TERMINAL_VIRTUAL_BETSLIP_PROCEED,
    TERMINAL_VIRTUAL_BETSLIP_REMOVE_MARKET,
    TERMINAL_VIRTUAL_BETSLIP_RESET,
    TERMINAL_VIRTUAL_BETSLIP_SET_MARKET,
    TERMINAL_VIRTUAL_BETSLIP_SET_SYSTEM_MARKET,
    TERMINAL_VIRTUAL_BETSLIP_SET_VALUE,
    TERMINAL_VIRTUAL_BETSLIP_UPDATE_MARKET,
} from 'constants/terminalVirtualBetslip';
import { SPIL_ID_RESET } from 'constants/spilId';
import { UI_ERROR_MESSAGE } from 'constants/ui';
import TerminalRestService from 'utils/TerminalRestService';
import {
    detectError,
    formatSpillerPlayer,
    performTerminalBetText,
} from 'utils/Helper';
import { convertFloatToInt } from 'utils/UiHelper';
import { oddsName } from 'utils/VirtualHelper';

export function addMarket(
    eventId,
    eventName,
    marketId,
    betNr,
    stake,
    odds,
    betText,
    marketName,
    matchDay,
    time
) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_VIRTUAL_BETSLIP_ADD_MARKET,
            payload: {
                eventId,
                marketId,
                betNr,
                stake,
                odds,
                gameId: marketId,
                marketName,
                betText,
                eventName,
                matchDay,
                time,
            },
        });
    };
}

export function removeMarket(marketId) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_VIRTUAL_BETSLIP_REMOVE_MARKET,
            payload: marketId,
        });
    };
}

export function clearAllMarkets() {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_VIRTUAL_BETSLIP_RESET,
            payload: true,
        });
    };
}

export function setMarket(value, odds) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_VIRTUAL_BETSLIP_SET_MARKET,
            payload: {
                value,
                odds,
            },
        });
    };
}

export function setSystemMarket(value, index) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_VIRTUAL_BETSLIP_SET_SYSTEM_MARKET,
            payload: {
                value,
                index,
            },
        });
    };
}

export function proceed(betModel, isPlayAndPay, spilId) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_VIRTUAL_BETSLIP_PENDING,
            payload: true,
        });

        let betList = [];
        let isSimpleAccum = false;
        let acumStake = 0;
        let totalStake = 0;

        const minValue = 1000;
        const maxValue = 100000;

        let isValid = true;
        let isLive = false;

        if (betModel.betList.length > 1) {
            isSimpleAccum = true;

            betModel.system.names.map((name, index) => {
                if (
                    index < betModel.system.names.length - 1 &&
                    betModel.system.stake[index]
                ) {
                    isSimpleAccum = false;
                }

                return name;
            });

            if (isSimpleAccum) {
                acumStake =
                    betModel.system.stake[betModel.system.names.length - 1];
            }
        }

        const savedBetModel = Object.assign({}, betModel);
        const singleStake = betModel.singleStake;
        const outcomes = {};

        betModel.betList.map((bet) => {
            const text = performTerminalBetText(bet.betText, bet);

            if (typeof bet.betText === 'string') {
                const tempName = bet.eventName
                    ? bet.eventName.split(' v ')
                    : '';
                const virtualSpillerPlayer = formatSpillerPlayer(
                    oddsName(text),
                    tempName[0],
                    tempName[1]
                );

                outcomes[bet.marketId] = virtualSpillerPlayer;
            }

            betList.push({
                id: bet.marketId,
                oddsType: bet.betNr,
                betText: text,
                odds: convertFloatToInt(bet.odds),
                matchDay: bet.matchDay,
            });

            return bet;
        });

        const betBody = {
            betList: betList,
            amount: convertFloatToInt(singleStake),
        };

        totalStake = convertFloatToInt(
            betList.length > 1 ? betModel.system.totalStake : singleStake
        );

        if (betList.length > 1) {
            betBody['amount'] = totalStake;
        }

        acumStake = acumStake * 1000;

        if (isSimpleAccum) {
            if (
                acumStake === 0 ||
                (acumStake !== 0 &&
                    (acumStake < minValue || acumStake > maxValue))
            ) {
                isValid = false;
            }
        } else {
            if (
                betBody.amount === 0 ||
                (betBody.amount !== 0 &&
                    (betBody.amount < minValue || betBody.amount > maxValue))
            ) {
                isValid = false;
            }
        }

        if (isValid) {
            const betslip = {
                user_id: spilId,
                outcomes,
                live: isLive,
                body: betBody,
                type: isSimpleAccum ? 0 : betModel.betList.length > 1 ? 2 : 1,
                session_id: window.localStorage.getItem('token'),
                totalOdds: betModel.totalOdds.toFixed(2),
                totalStake,
                singleStake: convertFloatToInt(singleStake),
                singlePrize: betModel.singlePrize,
            };

            /** condition for PlayAndPay workflow, when user plays without money */
            if (isPlayAndPay) {
                const data = {
                    hostname: window.localStorage.getItem('printerHost'),
                    payload: {
                        betslipSale: betslip,
                        betslip: savedBetModel,
                    },
                    type: 'bet25-virtual',
                };

                TerminalRestService.newUnpaidSale(data, (response) => {
                    if (response && response.success) {
                        dispatch({
                            type: TERMINAL_VIRTUAL_BETSLIP_RESET,
                            payload: response.data,
                        });
                    } else {
                        dispatch({
                            type: UI_ERROR_MESSAGE,
                            payload: response.error_message,
                        });
                    }

                    dispatch({
                        type: SPIL_ID_RESET,
                        payload: true,
                    });
                });
            } else {
                TerminalRestService.newVirtualSale(betslip).then((response) => {
                    if (response && response.success && response.data) {
                        dispatch({
                            type: TERMINAL_VIRTUAL_BETSLIP_PROCEED,
                            payload: response,
                        });
                    } else {
                        dispatch({
                            type: TERMINAL_VIRTUAL_BETSLIP_SET_VALUE,
                            payload: {
                                pending: false,
                            },
                        });

                        const error_message = response.error_message
                            ? response.error_message
                            : response.msg
                                ? response.msg
                                : 'Empty response';
                        dispatch({
                            type: UI_ERROR_MESSAGE,
                            payload: detectError(error_message),
                        });
                    }
                });
            }

            if (isLive) {
                let left = 6;

                let timer = setInterval(() => {
                    left--;

                    if (left === 0) {
                        clearInterval(timer);

                        dispatch({
                            type: TERMINAL_VIRTUAL_BETSLIP_SET_VALUE,
                            payload: {
                                timer: null,
                            },
                        });
                    } else {
                        dispatch({
                            type: TERMINAL_VIRTUAL_BETSLIP_SET_VALUE,
                            payload: {
                                timer: left,
                            },
                        });
                    }
                }, 1000);
            }
        } else {
            dispatch({
                type: TERMINAL_VIRTUAL_BETSLIP_SET_VALUE,
                payload: {
                    pending: false,
                },
            });

            dispatch({
                type: UI_ERROR_MESSAGE,
                payload: 'Indsats er ugyldig',
            });
        }
    };
}

export function acceptMarkets() {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_VIRTUAL_BETSLIP_SET_VALUE,
            payload: {
                changed: false,
            },
        });
    };
}

export function updateMarket(data, eventId, sportId) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_VIRTUAL_BETSLIP_UPDATE_MARKET,
            payload: {
                data,
                eventId,
                sportId,
            },
        });
    };
}
