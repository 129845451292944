import React, { Component } from 'react';
import classNames from 'classnames';
import { forceRedraw } from 'utils/Helper';
import 'css/ViewPageWrapper.scss';

class ViewPageWrapper extends Component {
    static defaultProps = {
        showBackBtn: false,
        onCloseHandler: () => {},
        onRightBtnHandler: () => {},
        additionalClass: '',
    };

    static types = {
        LOGOUT: 'logout',
        CLEAR_BETSLIP: 'clearBetslip',
    };

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
    }

    componentDidMount() {
        forceRedraw(this.containerRef.current);
    }

    onClickCloseBtn() {
        this.props.onCloseHandler();
    }

    onRightBtnClick() {
        this.props.onRightBtnHandler();
    }

    render() {
        return (
            <section
                ref={this.containerRef}
                className={`view-page-wrapper ${this.props.additionalClass}`}
            >
                {!this.props.withoutHeader && (
                    <header>
                        <div
                            className="close-btn"
                            onClick={this.onClickCloseBtn.bind(this)}
                        >
                            <i
                                className={classNames('fa', {
                                    'fa-angle-left fa-2x': this.props
                                        .showBackBtn,
                                    'fa-times': !this.props.showBackBtn,
                                })}
                                aria-hidden="true"
                            />
                        </div>

                        <div className="title" style={this.props.titleStyle}>
                            {this.props.title}
                        </div>

                        {this.renderRightBtn()}
                    </header>
                )}

                {this.props.children}
            </section>
        );
    }

    renderRightBtn() {
        switch (this.props.buttonType) {
            case ViewPageWrapper.types.LOGOUT:
                return (
                    <button
                        className="logout-btn"
                        onClick={this.onRightBtnClick.bind(this)}
                    >
                        LOG UD
                    </button>
                );
            case ViewPageWrapper.types.CLEAR_BETSLIP:
                return (
                    <button
                        className="remove-btn"
                        onClick={this.onRightBtnClick.bind(this)}
                    >
                        Ryd kupon
                    </button>
                );
            default:
                return null;
        }
    }
}

export default ViewPageWrapper;
