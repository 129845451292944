import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { APP_PATH } from '../constants/appConfig';
import * as UiActions from '../actions/UiActions';
import ViewPopup from '../components/ViewPopup';
import '../css/CasinoContainer.css';

class CasinoContainer extends Component {
    onClosePopupHandler = () => {
        this.props.uiActions.setRod25PopupStatus(false);
    };

    сlickHandler = () => {
        window.location.href = '/transfer.php?u=www.roed25.dk';
    };

    render() {
        if (this.props.rod25PopupStatus) {
            return (
                <ViewPopup
                    className="casino-popup"
                    showCloseButton={true}
                    onClosePopupHandler={this.onClosePopupHandler}
                >
                    <div className="content-container">
                        <img
                            src={APP_PATH + '/img/live-casino/rod25_logo.png'}
                            alt="Rod 25"
                        />
                        <div className="header">Spil på Bet25 Casino</div>
                        <div className="info">
                            Husk, at dit Bet25 logind også giver adgang til
                            Bet25 Casino, så du er klar til at spille med det
                            samme
                        </div>
                        <div className="button" onClick={this.сlickHandler}>
                            Gå til Bet25 Casino
                        </div>
                    </div>
                </ViewPopup>
            );
        }
        return null;
    }
}

function mapStateToProps(state) {
    return {
        rod25PopupStatus: state.ui.rod25PopupStatus,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CasinoContainer);
