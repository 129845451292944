import React, { useEffect } from 'react';
import Button from '../../ui/Button';
import Wrapper from '../../ui/Wrapper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationManager } from 'react-notifications';
import * as AuthActions from 'actions/AuthActions';
import { detectMobile } from 'utils/UiHelper';
import UserService from 'utils/UserService';
import { SetTransactionSaleId } from 'actions/RetailSalesHistoryActions';
import { showModal as ShowModal } from 'actions/UiActions';

const PayoutStatusComponent = ({
    showModal,
    payoutStatus,
    product,
    saleId,
    win,
    payoutType,
    retailSalesHistory,
    setTransactionSaleId,
    authActions,
    auth,
}) => {
    const [isCouponPayout, setIsCouponPayout] = React.useState(
        retailSalesHistory.transactionsIds.includes(saleId)
    );

    useEffect(
        () => {
            setIsCouponPayout(
                retailSalesHistory.transactionsIds.includes(saleId)
            );
        },
        [retailSalesHistory.transactionsIds.length]
    );

    const handleModalOpen = (product, saleId, win) => {
        const showTransferModal = localStorage.getItem('showTransferModal');
        const isSuccess = true;
        if (showTransferModal === 'true') {
            UserService.TransferRetailMoney(product, saleId)
                .then((res) => {
                    if (res.success) {
                        setTransactionSaleId(saleId);
                        if (auth && auth.user) {
                            authActions.updateBalance();
                            if (detectMobile()) {
                                showModal('TRANSFER_RETAIL', {
                                    product,
                                    saleId,
                                    win,
                                    isSuccess,
                                });
                            } else {
                                NotificationManager.success(
                                    '',
                                    `${win} kr. er blevet overført til din spillekonto.`,
                                    4000
                                );
                            }
                        }
                    } else {
                        if (detectMobile()) {
                            showModal('TRANSFER_RETAIL', {
                                product,
                                saleId,
                                win,
                            });
                        } else {
                            NotificationManager.error(
                                '',
                                res?.errorMessage || ''
                            );
                        }
                    }
                })
                .catch((err) => {
                    if (detectMobile()) {
                        showModal('TRANSFER_RETAIL', {
                            product,
                            saleId,
                            win,
                        });
                    } else {
                        NotificationManager.error('', res?.errorMessage || '');
                    }
                });
        } else {
            showModal('TRANSFER_RETAIL', { product, saleId, win });
        }
    };

    const checkStatus = () => {
        return (
            isCouponPayout ||
            (payoutStatus === 2 &&
                (payoutType === 'WEB' || payoutType === 'JOB'))
        );
    };

    return (
        <Wrapper
            width={'100%'}
            padding={'0 24px 16px 0 '}
            margin={'0 0 16px'}
            flex
            justify={'center'}
            flexAlign={'center'}
            boxSizing={'border-box'}
            borderBottom={'1px solid #d6e1ea'}
        >
            {!isCouponPayout &&
                payoutStatus === 1 && (
                    <Button
                        active
                        margin="0 auto"
                        fontSize="16px"
                        style={{ whiteSpace: 'nowrap' }}
                        onClick={() => handleModalOpen(product, saleId, win)}
                    >
                        Overfør gevinst til spillekonto
                    </Button>
                )}

            {checkStatus() && (
                <div className="green-text">Overført til spillekonto</div>
            )}

            {payoutStatus === 2 &&
                payoutType === 'SHOP' && (
                    <div className="green-text">Udbetalt hos forhandler</div>
                )}
        </Wrapper>
    );
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        retailSalesHistory: state.retailSalesHistory,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
        setTransactionSaleId: bindActionCreators(
            SetTransactionSaleId,
            dispatch
        ),
        showModal: bindActionCreators(ShowModal, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayoutStatusComponent);
