import {
    AUTH_INFO,
    AUTH_LOGIN_ERROR,
    AUTH_LOGIN_PENDING,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT,
    AUTH_RESET,
    AUTH_SESSION_ERROR,
    AUTH_SESSION_PENDING,
    AUTH_SESSION_SUCCESS,
    AUTH_SIGNUP_STEP1_ERROR,
    AUTH_SIGNUP_STEP1_PENDING,
    AUTH_SIGNUP_STEP1_SUCCESS,
    AUTH_SIGNUP_STEP2_ERROR,
    AUTH_SIGNUP_STEP2_PENDING,
    AUTH_SIGNUP_STEP2_SUCCESS,
    AUTH_SIGNUP_STEP3_ERROR,
    AUTH_SIGNUP_STEP3_PENDING,
    AUTH_SIGNUP_STEP3_SUCCESS,
    AUTH_USER_BALANCE,
    AUTH_USER_NEWSLATER,
    AUTH_SHOW_FORGOT_PASSWORD,
    AUTH_SHOW_LAST_LOGIN_POPUP,
} from 'constants/auth';

const initialState = {
    user: null,
    info: null,
    sessionPending: false,
    sessionSuccess: false,
    sessionError: false,
    loginPending: false,
    loginSuccess: false,
    showLastLoginPopup: false,
    loginError: false,
    failedLoginCounter: 0,
    step1Pending: false,
    step1Success: false,
    step1Error: false,
    step1: null,
    step2Pending: false,
    step2Success: false,
    step2Error: false,
    step2: null,
    vars: {
        foreign: false,
        changed: false,
        country: 'DK',
        currency: 'DKK',
        gender: 'M',
        birthdate: '01-01-1990',
        firstname: '',
        lastname: '',
        address: '',
        zip: '',
        city: '',
        cpr: '0000000000',
    },
    step3Pending: false,
    step3Success: false,
    step3Error: false,
    step3: null,
    showForgotPassword: false,
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case AUTH_LOGIN_PENDING:
            return {
                ...state,
                user: null,
                loginPending: action.payload,
                loginSuccess: false,
                loginError: false,
            };

        case AUTH_LOGIN_SUCCESS:
            if (
                window.localStorage.getItem('pin-username') !==
                action.payload.userName
            ) {
                window.localStorage.removeItem('pin-token');
                window.localStorage.removeItem('pin-username');
            }

            return {
                ...state,
                user: action.payload,
                loginPending: false,
                loginSuccess: true,
                loginError: false,
                failedLoginCounter: 0,
            };

        case AUTH_LOGIN_ERROR:
            return {
                ...state,
                user: null,
                loginPending: false,
                loginSuccess: false,
                loginError: action.payload.errorMsg,
                failedLoginCounter:
                    action.payload.message &&
                    action.payload.message.failedLogins
                        ? action.payload.message.failedLogins
                        : 0,
            };

        case AUTH_SESSION_PENDING:
            return {
                ...state,
                sessionPending: true,
                sessionSuccess: false,
                sessionError: false,
            };

        case AUTH_SESSION_SUCCESS:
            return {
                ...state,
                user: action.payload,
                sessionPending: false,
                sessionSuccess: true,
                sessionError: false,
            };
        case AUTH_INFO:
            return {
                ...state,
                info: action.payload,
            };

        case AUTH_SESSION_ERROR:
            return {
                ...state,
                user: null,
                info: null,
                sessionPending: false,
                sessionSuccess: false,
                sessionError: action.payload,
            };
        case AUTH_LOGOUT:
            return {
                ...state,
                user: null,
                info: null,
                sessionPending: false,
                sessionSuccess: false,
                sessionError: false,
                loginPending: false,
                loginSuccess: false,
                loginError: false,
            };

        case AUTH_SIGNUP_STEP1_PENDING:
            return {
                ...state,
                step1Pending: action.payload,
                step1Success: false,
                step1Error: false,
            };

        case AUTH_SIGNUP_STEP1_SUCCESS:
            state.vars.cpr = action.payload.cpr;
            return {
                ...state,
                step1Pending: false,
                step1Success: true,
                step1: action.payload,
                step1Error: false,
            };

        case AUTH_SIGNUP_STEP1_ERROR:
            return {
                ...state,
                step1Pending: false,
                step1Success: false,
                step1Error: action.payload,
            };

        case AUTH_SIGNUP_STEP2_PENDING:
            return {
                ...state,
                step2Pending: action.payload,
                step2Success: false,
                step2Error: false,
            };

        case AUTH_SIGNUP_STEP2_SUCCESS:
            const zipBlock = action.payload.zipBlock;
            var temp, temp2;
            var t = action.payload.data;
            var vars = {};

            if (t.ADRNVN) {
                temp = t.ADRNVN.split(';');
                temp2 = temp[0].split(',');
                vars.firstname = temp2[1] ? temp2[1] : ''; //first name
                vars.lastname = temp2[0] ? temp2[0] : '';
            }

            //address
            if (t.STADR === 'I udlandet' || t.STADR === 'Udrejst') {
                vars.foreign = true;
            } else {
                vars.address = t.STADR; //address
                if (t.POSTNR) {
                    temp = t.POSTNR.split(';');
                    vars.city = temp[1] ? temp[1] : ''; //city
                    vars.zip = temp[0] ? temp[0] : ''; //post code

                    if (vars.zip && zipBlock.indexOf(parseInt(vars.zip)) > -1) {
                        vars.foreign = true;
                    }
                }
            }

            if (t.FOEDDATO) {
                vars.birthdate =
                    t.FOEDDATO.substring(6) +
                    '-' +
                    t.FOEDDATO.substring(4, 6) +
                    '-' +
                    t.FOEDDATO.substring(0, 4);
            } else {
                let cpr = state.vars.cpr;
                let cent;
                if (cpr.toString().length === 9) {
                    cpr = '0' + cpr;
                }
                cpr = String(cpr);
                let cur = new Date()
                    .getFullYear()
                    .toString()
                    .substr(2);
                if (parseInt(cpr[4] + cpr[5]) > parseInt(cur) - 15) {
                    cent = '19';
                } else {
                    cent = '20';
                }
                vars.birthdate =
                    cpr[0] +
                    cpr[1] +
                    '-' +
                    cpr[2] +
                    cpr[3] +
                    '-' +
                    cent +
                    cpr[4] +
                    cpr[5];
            }

            if (t.KOEN) {
                vars.gender = t.KOEN === 'M' ? 'M' : 'F'; // M = man, K = F = kvinde/female
            }

            return {
                ...state,
                step2Pending: false,
                step2Success: true,
                step2: action.payload,
                step2Error: false,
                vars: {
                    ...state.vars,
                    ...vars,
                },
            };

        case AUTH_SIGNUP_STEP2_ERROR:
            return {
                ...state,
                step2Pending: false,
                step2Success: false,
                step2Error: action.payload,
            };

        case AUTH_SIGNUP_STEP3_PENDING:
            return {
                ...state,
                step3Pending: action.payload,
                step3Success: false,
                step3Error: false,
            };

        case AUTH_SIGNUP_STEP3_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loginPending: false,
                loginSuccess: true,
                loginError: false,
                step3Pending: false,
                step3Success: true,
                step3: action.payload,
                step3Error: false,
            };

        case AUTH_SIGNUP_STEP3_ERROR:
            return {
                ...state,
                step3Pending: false,
                step3Success: false,
                step3Error: action.payload,
            };

        case AUTH_RESET:
            return initialState;

        case AUTH_SHOW_FORGOT_PASSWORD:
            return {
                ...state,
                showForgotPassword: action.payload,
                loginPending: false,
            };

        case AUTH_USER_BALANCE:
            return {
                ...state,
                user: {
                    ...state.user,
                    balance: action.payload,
                },
            };

        case AUTH_USER_NEWSLATER:
            return {
                ...state,
                user: {
                    ...state.user,
                    newsletter: action.payload,
                    newsLetter: action.payload,
                },
            };

        case AUTH_SHOW_LAST_LOGIN_POPUP:
            return {
                ...state,
                showLastLoginPopup: action.payload,
            };
        default:
            window.Bet25 = {
                User: state.user,
            };

            return state;
    }
}
