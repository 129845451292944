import { keyframes } from 'styled-components';

export const fadeInRightToLeft = keyframes`
    0% {
        opacity: 0;
        right: -100%;
    }
    100% {
        opacity: 1;
        right: calc(100% + 100px)
    }
`;
