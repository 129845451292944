import React from 'react';
import UIModalNew from 'components/UIModalNew';
import styled from 'styled-components';
import Wrapper from 'ui/Wrapper';
import { APP_PATH } from 'constants/appConfig';
import Button from 'ui/Button';
import { connect } from 'react-redux';
import * as UiActions from 'actions/UiActions';
import '../css/PawModal.scss';
import { bindActionCreators } from 'redux';
import { hideModal, showModal } from 'actions/UiActions';

const Text = styled.p`
    font-size: 1rem;
    line-height: 24px;
    color: var(--Neutral-black_font_900, #1e1e1e);
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
`;
const Description = styled(Text)`
    margin: 5px 0;
    padding-left: 10px;
`;
const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
`;

const Link = styled.a`
    color: var(--Neutral-black_font_900, #1e1e1e);
    font-family: 'Roboto Condensed';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

const PwaModal = ({ hideModal, deferredPrompt, showModal, ...props }) => {
    const closePopup = () => {
        hideModal('INSTALL_PWA_MODAL');
        localStorage.setItem('hidePWAPrompt', 'true');
    };

    const handleInstall = (event) => {
        if (props.modalData.deferredPrompt) {
            props.modalData.deferredPrompt.prompt();
            props.modalData.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                    closePopup();
                    localStorage.setItem('hidePWAPrompt', 'true');
                } else {
                    console.log('User dismissed the install prompt');
                }
            });
        }
    };

    return (
        <UIModalNew title="Installer app?" preventFullscreen={true}>
            <Description>
                Tryk på tilføj og installer Bet25 Sport som app.
            </Description>
            <ImageWrapper>
                <div className="bet-container">
                    <img
                        className="bet-image"
                        src={APP_PATH + '/img/sport_icon.png'}
                        alt=""
                    />
                </div>
                <span className="bet-description">Bet25 Sport</span>
            </ImageWrapper>
            <Wrapper display="flex" justify="space-around">
                <Link onClick={closePopup}>Annuller</Link>
                <Link onClick={handleInstall}>Installer</Link>
            </Wrapper>
        </UIModalNew>
    );
};

function mapStateToProps(state) {
    return {
        deferredPrompt: state.ui.deferredPrompt,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
        showModal: bindActionCreators(showModal, dispatch),
        hideModal: bindActionCreators(hideModal, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PwaModal);
