import axios from 'axios';
import jsonp from 'jsonp';
import md5 from 'MD5';
import { isTerminal, notDesktop } from 'utils/UiHelper';
import { getEnvironment } from './Helper';
const bonusPath = window.config.baseCmsPath + '/rod25/bonus';
const BASE_CMS_PATH = window.config.baseCmsPath;
const BASE_API_PATH = window.config.baseAPIPath;

class RestService {
    apiPath = `${BASE_API_PATH}/util/bet25/api.php`;

    GetFavoriteEvents(cb) {
        const terminalParams = isTerminal() ? '?forTerminal=true' : '';
        axios
            .get(BASE_API_PATH + '/favorite-events/get-events' + terminalParams)
            .then((res) => {
                cb(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    GetConfig(cb) {
        jsonp(this.apiPath + '?r=GetConfig', (error, data) => {
            cb(data, error);
        });
    }

    GameItems(cb) {
        jsonp(
            `${BASE_API_PATH}/util/bet25/groups/api.php?r=GetItems`,
            (error, data) => {
                cb(data);
            }
        );
    }

    SportInfo(cb) {
        jsonp(this.apiPath + '?r=SportInfo', (error, data) => {
            cb(data);
        });
    }

    Campaigns(cb) {
        if (notDesktop()) {
            jsonp(this.apiPath + '?r=Campaigns', (error, data) => {
                cb(data, error);
            });
        } else {
            jsonp(this.apiPath + '?r=Campaigns', (error, data) => {
                cb(data, error);
            });
        }
    }

    Highlights(cb) {
        jsonp(this.apiPath + '?r=Highlights', (error, data) => {
            cb(data);
        });
    }

    CampaignSeo(seo, cb) {
        jsonp(this.apiPath + `?r=CampaignSeo&seo=${seo}`, (error, data) => {
            cb(data);
        });
    }

    Faq(cb) {
        jsonp(this.apiPath + '?r=Faq', (error, data) => {
            cb(data);
        });
    }

    GetText(id, cb) {
        jsonp(this.apiPath + '?r=Texts&id=' + id, (error, data) => {
            cb(data);
        });
    }

    CanSubscribe(userId, cb) {
        jsonp(
            `${BASE_API_PATH}/util/bet25/campaign/api.php?r=CanSubscribe&user=`,
            userId,
            (error, data) => {
                cb(data);
            }
        );
    }

    CampaignSubscribe(userId, campaignId, cb) {
        jsonp(
            `${BASE_API_PATH}/util/bet25/campaign/api.php?r=Subscribe&user=`,
            userId + '&camp=' + campaignId,
            (error, data) => {
                cb(data);
            }
        );
    }

    SendFeedback(type, text, step, cb) {
        axios
            .post(BASE_API_PATH + '-bet25/registration-feedback', {
                client_answer: type,
                step_number: step,
                app_name: 'bet25',
                client_text: text,
            })
            .then((res) => {
                cb(res);
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    GetUserNotifications(userId, cb) {
        axios
            .get(
                BASE_API_PATH +
                    '-bet25/get-user-notifications?client_id=' +
                    userId
            )
            .then((res) => {
                cb(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    CleanUserNotifications(type, userId, cb) {
        axios
            .post(BASE_API_PATH + '-bet25/set-notification-as-viewed', {
                client_id: userId,
                type: type,
            })
            .then((res) => {
                cb(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    _getHardwareId(salt = '') {
        return 'web-application-' + md5(window.navigator.userAgent + salt);
    }

    /**
     * According to have cross platform pin-code (Bet25 Sport and derby) we need to create common ID
     * @param {*} salt - application name (rod25-web/bet25-web)
     */
    _generateUID(salt = '') {
        const hash = md5(window.navigator.userAgent + salt);
        return `web-application-${hash}`;
    }

    CreateHashPL(mobileApp, userName, pin, token, hash, cb) {
        axios
            .post(BASE_API_PATH + '/create-hash', {
                user_name: userName,
                mobileApp: mobileApp,
                hardware_id: this._getHardwareId(userName),
                useragent_id: this._generateUID(mobileApp),
                pin,
                token,
                hash,
            })
            .then((res) => {
                cb(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    GetHashPL(mobileApp, userName, token, cb) {
        let params = {
            mobileApp: mobileApp,
            hardware_id: this._getHardwareId(userName),
            useragent_id: this._generateUID(mobileApp),
            user_name: userName,
            token: token,
        };
        let urlParams = Object.keys(params)
            .map(function(k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(a[k]);
            })
            .join('&');

        axios
            .get(BASE_API_PATH + '/get-hash?' + urlParams)
            .then((res) => {
                cb(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    LastLogin(mobileApp, account) {
        axios
            .post(BASE_API_PATH + '/last-login', {
                mobileApp: mobileApp,
                hardware_id: this._getHardwareId(account.userName),
                useragent_id: this._generateUID(mobileApp),
                user_id: account.userId,
            })
            .then(() => {});
    }

    AtachUser(mobileApp, account, balance, cb) {
        axios
            .post(BASE_CMS_PATH + '/util/index.php?r=rest/attach-user', {
                user_id: account.userId,
                hardware_id: this._getHardwareId(account.userName),
                useragent_id: this._generateUID(mobileApp),
                push_token: null,
                username: account.userName,
                email: account.userEmail,
                fullname: `${account.firstName} ${account.lastName}`,
                status: account.status,
                balance: balance,
                mobileApp: mobileApp,
            })
            .then((res) => {
                if (cb && typeof cb === 'function') {
                    cb(res);
                }
            })
            .catch((error) => {
                console.trace(error);
            });
    }

    RegisterDevice(subscriberId, mobileApp = 'bet25-web', cb) {
        axios
            .post(BASE_CMS_PATH + '/util/index.php?r=rest/register-device', {
                hardware_id: this._getHardwareId(mobileApp), // change to userName
                useragent_id: this._generateUID(mobileApp),
                mobileApp: mobileApp,
                push_token: subscriberId,
            })
            .then((res) => {
                if (typeof cb === 'function') {
                    let pin_set =
                        res.data && res.data.pin_set ? res.data.pin_set : null;
                    cb(pin_set);
                }
            })
            .catch((error) => {
                if (typeof cb === 'function') {
                    cb(false);
                }
            });
    }

    GetUserSegmentedSplash(userId, cb) {
        axios
            .get(
                `${BASE_API_PATH}/util/bet25/api.php?r=UserSplash&user=`,
                userId
            )
            .then((res) => {
                cb(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    GetBig9Title(cb) {
        let env = getEnvironment() === 'dev' ? 'dev.25bet.dk' : 'bet25.dk';
        axios
            .get(
                `${BASE_API_PATH}/util/bet25/api.php?r=Texts&id=big9_header_title`
            )
            .then((res) => {
                cb(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    GetBig9Description(cb) {
        let env = getEnvironment() === 'dev' ? 'dev.25bet.dk' : 'bet25.dk';
        axios
            .get(
                `${BASE_API_PATH}/util/bet25/api.php?r=Texts&id=big9_header_body`
            )
            .then((res) => {
                cb(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    GetUserBonuses(userId, cb) {
        axios({
            method: 'get',
            url: `${bonusPath}?client_id=${userId}`,
        }).then((res) => {
            cb(res.data);
        });
    }

    PostUserBonus(bonus, cb = (f) => f) {
        axios
            .post(bonusPath, bonus)
            .then((res) => {
                cb(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    GetRulesAndTermsTerminal(cb) {
        axios
            .get(
                `${BASE_API_PATH}/util/bet25/api.php?r=Texts&id=terminals_terms`
            )
            .then((res) => {
                cb(res.data.content);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    GetBig9Articles(tag) {
        return axios({
            method: 'get',
            url: this.apiPath + '?r=Big9Articles&tag=' + tag,
        });
    }
}

export default new RestService();
