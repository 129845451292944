import React from 'react';
import { isIPad13, isMobile } from 'react-device-detect';
import { APP_TYPE, TERMINAL_TYPE } from 'constants/appConfig';
import moment from 'moment';
import 'moment/locale/da';
import { browserHistory } from 'react-router';

export function convertFloatToInt(number) {
    return Math.round(parseFloat(number) * 100);
}

export function changeUrl(title, url) {
    let obj = {
        Title: title,
        Url: url,
    };

    window.history.pushState(obj, obj.Title, obj.Url);
}

export function formatDate(
    date,
    dateFormat = 'DD. MMMM [kl.] HH:mm',
    inputFormat = ''
) {
    return moment(date, inputFormat ? inputFormat : null).format(
        dateFormat ? dateFormat : 'DD/MM'
    );
}

export function disableBodyScroll() {
    window.document.body.style.overflowY = 'hidden';
    if (detectMobile()) {
        document.body.style.position = 'fixed';
        document.body.style.width = '100vw';
    }
}

export function isBodyScrollDisabled() {
    return window.document.body.style.overflowY === 'hidden';
}

export function enableBodyScroll() {
    window.document.body.style.overflowY = 'auto';
    if (detectMobile()) {
        document.body.style.position = 'relative';
        document.body.style.width = 'auto';
    }
}

export const formatDenmarkCurrency = (value = 0, fractionDigits = 2) =>
    new Intl.NumberFormat('da-DK', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(value || 0);

export const scrollToTop = () => {
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 600);
};
export const detectMobile = () => isMobile || isIPad13;

export const isMobileOnly = () => isMobile;

export const isTerminal = () => APP_TYPE === TERMINAL_TYPE;

export const isToken = () => {
    let token = window.localStorage.getItem('token');
    if (token && token !== '') {
        return true;
    } else {
        return false;
    }
};

export const desktopOnly = () =>
    !isMobile && !isIPad13 && APP_TYPE !== TERMINAL_TYPE;

export const notDesktop = () =>
    isMobile || isIPad13 || APP_TYPE === TERMINAL_TYPE;

export const useReactPath = () => {
    const [path, setPath] = React.useState(window.location.pathname);
    const listenToPopstate = () => {
        const winPath = window.location.pathname;
        setPath(winPath);
    };
    React.useEffect(() => {
        window.addEventListener('popstate', listenToPopstate);
        return () => {
            window.removeEventListener('popstate', listenToPopstate);
        };
    }, []);
    return path;
};

export const isBig9Page = (path) => {
    return path.split('/')[1] === 'big9';
};

export const getImageBasePath = () => {
    return window.location.hostname.indexOf('localhost') > -1 ? '' : '/sport';
};

export const getEnvForLocalhost = () => {
    return window.location.hostname === 'localhost' ||
        window.location.hostname === 'bs-local.com'
        ? ''
        : '/sport';
};

export const getBig9ResultPercents = (item) => {
    let homeOver = Number(item.dist1O);
    let homeUnder = Number(item.dist1U);
    let tieOver = Number(item.distXO);
    let tieUnder = Number(item.distXU);
    let awayOver = Number(item.dist2O);
    let awayUnder = Number(item.dist2U);

    return {
        home: Math.round(homeOver + homeUnder),
        tie: Math.round(tieOver + tieUnder),
        away: Math.round(awayOver + awayUnder),
        over: Math.round(homeOver + tieOver + awayOver),
        under: Math.round(homeUnder + tieUnder + awayUnder),
    };
};

export const numberWithCommasAndDots = (x) => {
    if (typeof x !== 'number') {
        return x;
    }
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (parts[1]) {
        parts[1] = (Math.floor(`1.${parts[1]}` * 100) / 100)
            .toString()
            .split('.')[1];
    }
    return parts.join(',');
};

export const browserHistoryGoBack = (action) => {
    if (
        window.locationHistory.length > 0 &&
        window.locationHistory[0] !== '/'
    ) {
        if (window.locationHistory[0] === '/kupon') {
            //page koupon
            if (window.locationHistory[1] === '/sport') {
                browserHistory.push('/sport/');
            } else {
                if (
                    window.location.pathname.includes('kamp') &&
                    window.locationHistory[1].includes('kamp')
                ) {
                    if (window.locationHistory[2]) {
                        browserHistory.push(
                            '/sport' + window.locationHistory[2]
                        );
                    } else {
                        browserHistory.push('/sport/');
                    }
                } else {
                    browserHistory.push('/sport' + window.locationHistory[1]);
                }
                /*if (action === 'clearAllMarkets') {
                } else {

                }*/
            }
        } else {
            /*if (window.locationHistory[0] === 'kupon') {
            }*/
            browserHistory.push('/sport' + window.locationHistory[0]);
        }
    } else {
        browserHistory.push('/sport/');
    }
};

export const isActiveBetButton = (item, market, setMarkets = []) => {
    let betType =
        market.marketTypeGroupName === 'Byg væddemål'
            ? 'CUSTOM_BET'
            : 'REGULAR_BET';
    let currentItem = `${betType}_${market.marketId}_${
        item.betNr
    }_${market.marketName.replace(/\s/g, '')}`;

    return setMarkets.includes(currentItem);
};

export const showRetailTransferUi = (sale) => {
    return (
        sale.payoutStatus === 1 ||
        (sale.payoutStatus === 2 &&
            (sale.payoutType === 'WEB' ||
                sale.payoutType === 'JOB' ||
                sale.payoutType === 'SHOP'))
    );
};

export const isCanceledCoupon = (sale) => {
    return sale.payoutType === 'CANCEL' && sale.payoutStatus === 2;
};
