import styled from 'styled-components';
import { fadeInTopToBottom } from '../../ui/animations/FadeInTopToBottom';
import { fadeInRightToLeft } from '../../ui/animations/FadeInRightToLeft';
import { fadeInBottomToTop } from '../../ui/animations/FadeInBottomToTop';
import { fadeInLeftToRight } from '../../ui/animations/FadeInLeftToRIght';

export const NotificationMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: fixed;
    background-color: #f5f8fc;
    //background-color: red;
    width: auto;
    border-radius: 4px;
    border: 1px solid #e4e9ef;
    z-index: 10;
    &.top {
        left: 50%;
        transform: translateX(-50%);
        //animation: 0.4s all ${fadeInTopToBottom} linear;
        animation: ${fadeInTopToBottom} 5s ease-in-out;
    }
    &.right {
        transform: translateY(-50%);
        animation:  ${fadeInRightToLeft} 0 1s ease-in;
    }
    &.bottom {
        left: 50%;
        transform: translateX(-50%);
        animation: ${fadeInBottomToTop} 5s ease-in-out;
    }
    &.bottom.removeBottom {
    }

    &.left {
        animation: ${fadeInLeftToRight} 0 1s ease-in;
    }
`;

export const NotificationMessageIconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    width: 35px;
    height: 40px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    &:hover {
        cursor: pointer;
    }
`;
