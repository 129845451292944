import React from 'react';
import ModalWrapper from 'ui/ModalWrapper';
import styled from 'styled-components';
import { enableBodyScroll, detectMobile } from 'utils/UiHelper';

const StyledModalWrapper = styled(ModalWrapper)`
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
`;

export default class ModalContainer extends React.Component {
    constructor() {
        super();

        this.state = {
            closeButtonEnabled: false,
            closeButtonComponent: null,
            closeButtonColor: null,
            closeButtonHandler: null,
            topBarHidden: true,
            background: 'rgba(0,0,0, 0.69)',
        };
    }

    hideModal = () => {
        this.props.actions.hideModal(this.props.modalId);
    };

    closeModal = () => {
        this.props.modalData && this.props.modalData.customModalCloseHandler
            ? this.props.modalData.customModalCloseHandler()
            : this.state.closeButtonHandler
                ? this.state.closeButtonHandler()
                : this.hideModal();
    };

    showTopBar = () => {
        this.setState({ topBarHidden: false });
    };

    setBackground = (background) => {
        this.setState({ background });
    };

    enableCloseButton = (closeButtonComponent, color, closeButtonHandler) => {
        this.setState({
            closeButtonEnabled: true,
            closeButtonComponent,
            closeButtonColor: color,
            closeButtonHandler,
        });
    };

    disableCloseButton = () => {
        this.setState({
            closeButtonEnabled: false,
        });
    };

    componentDidMount() {
        window.document.body.style.overflowY = 'hidden';
        if (detectMobile()) {
            document.body.style.position = 'initial';
            document.body.style.width = '100vw';
        }
    }

    componentWillUnmount() {
        enableBodyScroll();
    }

    render() {
        const ModalComponent = this.props.ModalComponent;

        return (
            <StyledModalWrapper
                popupMode={this.props.popupMode}
                topBarHidden={this.state.topBarHidden}
                background={this.state.background}
            >
                <ModalComponent
                    {...this.props.actions}
                    close={this.closeModal}
                    modalData={this.props.modalData}
                    enableCloseButton={this.enableCloseButton}
                    disableCloseButton={this.disableCloseButton}
                    showTopBar={this.showTopBar}
                    setBackground={this.setBackground}
                    topBarHidden={this.state.topBarHidden}
                    popupMode={this.props.popupMode}
                />
            </StyledModalWrapper>
        );
    }
}
