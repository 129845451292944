import {
    SPORT_INFO,
    SPORT_MENU_FETCH_SUCCESS,
    SPORT_TREE_FETCH_ERROR,
    SPORT_TREE_FETCH_PENDING,
    SPORT_TREE_FETCH_SUCCESS,
} from 'constants/sportTree';
import { ParsproService } from 'utils/ParsproService';
import RestService from 'utils/RestService';

import TerminalRestService from 'utils/TerminalRestService';
import { isTerminal } from 'utils/UiHelper';

window.dataTimer = null;

export function getSportTree() {
    return (dispatch) => {
        dispatch({
            type: SPORT_TREE_FETCH_PENDING,
            payload: true,
        });

        RestService.SportInfo((data) => {
            dispatch({
                type: SPORT_INFO,
                payload: data,
            });
        });

        if (!isTerminal()) {
            ParsproService.getCompList()
                .then((res) => {
                    const data = res.data;
                    dispatch({
                        type: SPORT_TREE_FETCH_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: SPORT_TREE_FETCH_ERROR,
                        payload: error.toString(),
                    });
                });
        } else {
            let getData = () => {
                TerminalRestService.getCompList((res) => {
                    if (res) {
                        dispatch({
                            type: SPORT_TREE_FETCH_SUCCESS,
                            payload: res,
                        });
                    }

                    dispatch({
                        type: SPORT_TREE_FETCH_PENDING,
                        payload: true,
                    });
                });
            };

            getData();

            if (window.dataTimer) {
                clearInterval(window.dataTimer);
            }

            window.dataTimer = setInterval(() => {
                getData();
            }, 29000);
        }
    };
}
