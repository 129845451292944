import {
    TERMINAL_APPLY_COUPON,
    TERMINAL_APPLY_COUPON_ERROR,
    TERMINAL_APPLY_VOUCHER,
    TERMINAL_APPLY_VOUCHER_ERROR,
    TERMINAL_CLEAR_APPLY_VOUCHER_ERROR,
    TERMINAL_SESSION_ERROR,
    TERMINAL_SESSION_LOGOUT,
    TERMINAL_SESSION_PENDING,
    TERMINAL_SESSION_RESET,
    TERMINAL_SESSION_SUCCESS,
} from '../constants/terminalSession';
import {
    SPIL_ID_RESET,
    SPIL_ID_PENDING,
    SPIL_ID_SUCCESS,
} from '../constants/spilId';
import { NotificationManager } from 'react-notifications';
import TerminalRestService from '../utils/TerminalRestService';

export function setSpilId(user_id) {
    return (dispatch) => {
        dispatch({
            type: SPIL_ID_SUCCESS,
            payload: {
                user_id,
                rofus_status: '',
            },
        });
    };
}

export function removeSpilId() {
    return (dispatch) => {
        dispatch({
            type: SPIL_ID_RESET,
            payload: true,
        });
    };
}

export function verifySpilId(hash) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_SESSION_PENDING,
            payload: true,
        });
        dispatch({
            type: SPIL_ID_PENDING,
            payload: true,
        });

        return TerminalRestService.verifySpilId(hash).then((res) => {
            if (res.success) {
                dispatch({
                    type: SPIL_ID_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: SPIL_ID_RESET,
                    payload: true,
                });
            }

            dispatch({
                type: TERMINAL_SESSION_RESET,
                payload: true,
            });
        });
    };
}

export function getSessionTransfferLink(path = '') {
    const sessionId = window.localStorage.getItem('token'), //this.props.terminalSession.data.id,
        printerHost = window.localStorage.getItem('printerHost'),
        terminalId = window.localStorage.getItem('terminalId'),
        spilId = window.localStorage.getItem('spilId');

    if (printerHost && terminalId) {
        const link = window.TELE_SALE
            ? 'http://derby.cafe:8080'
            : 'http://derby.bet25.dk:8080';
        const fullPath = `${link}${path}?sessionId=${sessionId}&printerHost=${printerHost}&terminalId=${terminalId}&spilId=${spilId}`; ///session-transferring.php
        window.location = fullPath;
    } else {
        NotificationManager.error(
            '',
            'printerHost or terminalId are not defined'
        );
    }
}

export function checkIn(hash) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_SESSION_PENDING,
            payload: true,
        });

        const terminalId = window.localStorage.getItem('terminalId');

        if (terminalId) {
            TerminalRestService.checkIn(hash, terminalId, (res) => {
                if (!res.success) {
                    dispatch({
                        type: TERMINAL_SESSION_ERROR,
                        payload: res.error_message.toString(),
                    });
                } else {
                    dispatch({
                        type: TERMINAL_SESSION_SUCCESS,
                        payload: res.data,
                    });
                }
            });
        } else {
            dispatch({
                type: TERMINAL_SESSION_ERROR,
                payload: 'Terminal ID is missing. Please contact to support.',
            });
        }
    };
}

export function checkInTele() {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_SESSION_PENDING,
            payload: true,
        });

        const terminalId = window.localStorage.getItem('terminalId');
        const hostname = window.localStorage.getItem('printerHost');

        if (terminalId) {
            TerminalRestService.checkInTele(hostname, terminalId, (res) => {
                if (!res.success) {
                    dispatch({
                        type: TERMINAL_SESSION_ERROR,
                        payload: res.error_message.toString(),
                    });
                } else {
                    dispatch({
                        type: TERMINAL_SESSION_SUCCESS,
                        payload: res.data,
                    });
                }
            });
        } else {
            dispatch({
                type: TERMINAL_SESSION_ERROR,
                payload: 'Terminal ID is missing. Please contact to support.',
            });
        }
    };
}

export function checkInByCoupon(hash) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_SESSION_PENDING,
            payload: true,
        });

        const terminalId = window.localStorage.getItem('terminalId');

        if (terminalId) {
            TerminalRestService.checkInByCoupon(hash, terminalId, (res) => {
                if (!res.success) {
                    dispatch({
                        type: TERMINAL_SESSION_ERROR,
                        payload: res.error_message.toString(),
                    });
                } else {
                    dispatch({
                        type: TERMINAL_SESSION_SUCCESS,
                        payload: res.data,
                    });
                }
            });
        } else {
            dispatch({
                type: TERMINAL_SESSION_ERROR,
                payload: 'Terminal ID is missing. Please contact to support.',
            });
        }
    };
}

export function checkInBySale(hash) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_SESSION_PENDING,
            payload: true,
        });

        const terminalId = window.localStorage.getItem('terminalId');

        if (terminalId) {
            TerminalRestService.checkInBySale(hash, terminalId, (res) => {
                if (!res.success) {
                    dispatch({
                        type: TERMINAL_SESSION_ERROR,
                        payload: res.error_message.toString(),
                    });
                } else {
                    dispatch({
                        type: TERMINAL_SESSION_SUCCESS,
                        payload: res.data,
                    });
                }
            });
        } else {
            dispatch({
                type: TERMINAL_SESSION_ERROR,
                payload: 'Terminal ID is missing. Please contact to support.',
            });
        }
    };
}

export function checkSession(token) {
    return (dispatch) => {
        if (token && token != '') {
            dispatch({
                type: TERMINAL_SESSION_PENDING,
                payload: true,
            });

            TerminalRestService.checkSession(token, (res) => {
                if (res.success) {
                    /** make logout if there left no money on session */
                    if (res.data.session.amount === 0) {
                        checkOut(res.data.session.id).call(this, dispatch);
                    } else {
                        dispatch({
                            type: TERMINAL_SESSION_SUCCESS,
                            payload: res.data,
                        });
                    }
                } else {
                    dispatch({
                        type: TERMINAL_SESSION_ERROR,
                        payload: res.error_message,
                    });

                    /** erase session if not found only, issue with network etc can't be a reason */
                    if (res.error_message === 'SESSION IKKE FUNDET') {
                        console.log('error_message', res.error_message);
                        window.localStorage.setItem('token', '');
                    }
                }
            });
        }
    };
}

export function checkOut(token) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_SESSION_PENDING,
            payload: true,
        });

        TerminalRestService.checkOut(token, (res) => {
            if (!res.success) {
                /** erase session if not found only, issue with network etc can't be a reason */
                if (
                    res.error_message &&
                    res.error_message.indexOf('SESSION IKKE FUNDET') > -1
                ) {
                    console.log('error_message', res.error_message);
                    window.localStorage.setItem('token', '');
                }

                dispatch({
                    type: TERMINAL_SESSION_ERROR,
                    payload: res.error_message,
                });
            } else {
                dispatch({
                    type: TERMINAL_SESSION_LOGOUT,
                    payload: true,
                });
            }
        });
    };
}

export function reset() {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_SESSION_RESET,
            payload: true,
        });
    };
}

export function applyVoucher(token, hash) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_SESSION_PENDING,
            payload: true,
            applyingVoucher: true,
        });

        TerminalRestService.applyVoucher(token, hash, (res) => {
            if (!res.success) {
                dispatch({
                    type: TERMINAL_APPLY_VOUCHER_ERROR,
                    payload: res.error_message,
                });
            } else {
                dispatch({
                    type: TERMINAL_APPLY_VOUCHER,
                    payload: res.data,
                });
            }
        });
    };
}

export function applyCoupon(token, hash) {
    return (dispatch) => {
        if (hash.length > 12) {
            hash = hash.substr(hash.length - 12);
        }
        const prefix = hash[0];
        TerminalRestService.applyReceipt(token, prefix, hash)
            .then((res) => {
                if (!res.success) {
                    dispatch({
                        type: TERMINAL_APPLY_COUPON_ERROR,
                        payload: res.error_message,
                    });
                } else {
                    dispatch({
                        type: TERMINAL_APPLY_COUPON,
                        payload: res.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: TERMINAL_APPLY_COUPON_ERROR,
                    payload: error,
                });
            });
    };
}

export function clearApplyError() {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_CLEAR_APPLY_VOUCHER_ERROR,
            payload: true,
        });
    };
}
