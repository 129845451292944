import React, { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import cprImage from 'images/cpr-verification.png';
import styled from 'styled-components';
import UIModalNew from 'components/UIModalNew';
import { bindActionCreators } from 'redux';
import { showModal } from 'actions/UiActions';
import { detectMobile } from 'utils/UiHelper';

const Text = styled.p`
    font-size: 1rem;
    color: #818181;
    font-family: 'Roboto Condensed', sans-serif;
    padding-left: 10px;
`;
const Description = styled(Text)`
    margin: 19px 0;
`;
const BottomText = styled(Text)`
    font-style: italic;
    font-weight: bold;
    margin: 30px 0;
`;
const StyledButton = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 17px;
    padding-bottom: 17px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    background: #ffd800;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background: #e5c200;
    }
`;
const BarcodeImage = styled.img`
    object-fit: contain;
`;

const VerificationModal = ({ user, ...props }) => {
    const [visible, setVisible] = useState(false);
    const { status, userId } = user;

    useEffect(
        () => {
            if (
                user &&
                status === 'temp' &&
                (!cookie.load('login-counter-' + userId) ||
                    cookie.load('login-counter-' + userId) === '4') &&
                props.verificationModalStatus
            ) {
                setVisible(true);

                if (
                    !cookie.load('login-counter-' + userId) ||
                    cookie.load('login-counter-' + userId) === '4'
                ) {
                    let d = new Date();

                    d.setTime(d.getTime() + 180 * 24 * 60 * 60 * 1000);

                    cookie.save('login-counter-' + userId, '1', {
                        path: '/',
                        expires: d,
                    });
                }
            }
        },
        [status, props.verificationModalStatus]
    );

    const onCloseModal = () => {
        setVisible(false);
        if (props.verificationModalStatus && detectMobile()) {
            props.showModal('PIN_ADVICE_POPUP');
        }
    };

    return visible ? (
        <UIModalNew
            title="Verificer din konto"
            closeBtn
            onCloseModal={onCloseModal}
        >
            <Description>
                Da din konto ikke automatisk er blevet verificeret, beder vi dig
                indsende et foto af dit sundhedskort til dokument@25syv.dk
            </Description>
            <BarcodeImage src={cprImage} alt="cpr-verification" />
            <BottomText>
                Ellers lukkes din konto 30 dage efter oprettelsen.
            </BottomText>
            <StyledButton
                onClick={() => (window.location = 'mailto:dokument@25syv.dk')}
            >
                Verficér konto
            </StyledButton>
        </UIModalNew>
    ) : null;
};

function mapStateToProps(state) {
    return {
        verificationModalStatus: state.ui.verificationModalStatus,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        showModal: bindActionCreators(showModal, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VerificationModal);
