import React from 'react';
import ParentAuthPage from './ParentAuthPage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthActions from 'actions/AuthActions';
import * as UiActions from 'actions/UiActions';
import UserDeposits from 'features/UserDeposits';
import { browserHistory } from 'react-router';
import { detectMobile } from 'utils/UiHelper';

class PaymentDepositPage extends ParentAuthPage {
    componentDidMount() {
        this.setPageTitle('Tilbage');

        this.restService.GetConfig((res) => {
            if (res.dibs === 'fail') {
                this.setErrorMessage(
                    'Vores betalingsudbyder har desværre tekniske problemer, det er desværre ikke muligt at indbetale i øjeblikket.'
                );
            }
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.auth.user && this.props.auth.sessionError) {
            browserHistory.push('/sport/');
            this.props.uiActions.showModal('LOGIN_FIRST');
        }
    }

    closeMobModal() {
        this.props.uiActions.hideModal('USER_MENU');
    }

    render() {
        if (detectMobile()) {
            return (
                <div className="payment-deposit-page">
                    {this.getHeaderView(() => {
                        this.props.uiActions.showModal('USER_MENU');
                    })}

                    {this.state.errorMessage ? (
                        <div className="message error p10">
                            {this.state.errorMessage}
                        </div>
                    ) : null}

                    <UserDeposits
                        user={this.props.auth.user}
                        openDepositLimitPopup={() => {
                            this.props.uiActions.showModal(
                                'DEPOSIT_LIMIT_REACHED'
                            );
                        }}
                        closeModal={() => {
                            this.closeMobModal();
                        }}
                        history={browserHistory}
                        checkSession={
                            this.props.authActions.checkUserLoggedStatus
                        }
                    />
                </div>
            );
        }
        return (
            <div className="payment-deposit-page">
                {this.getHeaderView()}

                {this.state.errorMessage ? (
                    <div className="message error p10">
                        {this.state.errorMessage}
                    </div>
                ) : null}

                <UserDeposits
                    user={this.props.auth.user}
                    openDepositLimitPopup={() => {
                        this.props.uiActions.showModal('DEPOSIT_LIMIT_REACHED');
                    }}
                    closeModal={this.props.closeModal}
                    history={browserHistory}
                    checkSession={this.props.authActions.checkUserLoggedStatus}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { auth: state.auth };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentDepositPage);
