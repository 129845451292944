import React from 'react';

export default class RememberCoupon extends React.Component {
    handleChange = () => {
        this.props.onChange(!this.props.accepted);
    };

    render() {
        return (
            <div className="view-checkbox accept-betslip-changes p20">
                <label className="container circle" htmlFor="rememberCoupon">
                    <input
                        id="rememberCoupon"
                        style={{ float: 'right' }}
                        type="checkbox"
                        checked={this.props.accepted}
                        onChange={this.handleChange}
                    />
                    Gem den spillede kupon
                    <span className="checkmark circle" />
                </label>
            </div>
        );
    }
}
