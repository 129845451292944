import {
    BETSLIP_ACCEPT_CHANGES,
    BETSLIP_ADDED_ERROR,
    BETSLIP_ADDED_OK,
    BETSLIP_ERROR,
    BETSLIP_CUSTOMBET_ADDED_OK,
    BETSLIP_CUSTOMBET_ADDED_ERROR,
    BETSLIP_ITEM_ERROR,
    BETSLIP_NORMALIZE_CHANGES,
    BETSLIP_CUSTOM_BET_NORMALIZE_CHANGES,
    BETSLIP_PENDING,
    BETSLIP_RESET,
    BETSLIP_RESET_SINGLE_STAKE,
    BETSLIP_CUSTOM_BET_RESET,
    BETSLIP_SET_LAST_RESULT,
    BETSLIP_SET_LIVE_MARKETS,
    BETSLIP_SET_SUGGESTION,
    BETSLIP_SET_VALUE,
    BETSLIP_SET_CUSTOM_BET_VALUE,
    BETSLIP_UPDATE_TOTAL_ODDS_VALUE,
    BETSLIP_UPDATE_TOTAL_ODDS_VALUE_PENDING,
    BETSLIP_SUCCESS,
    BETSLIP_SET_SLIP,
    BETSLIP_STATUS_CHANGED_FROM_LINK,
    BETSLIP_REGULAR_FETCHED_STATUS,
    BETSLIP_CUSTOMBET_FETCHED_STATUS,
} from 'constants/betslip';
import {
    UI_SUCCESS_BETSLIP_POPUP_STATUS,
    UI_WARNING_MESSAGE,
} from 'constants/ui';
import BetslipService from '../utils/BetslipService';
import UserService from '../utils/UserService';
import { detectError, normalizeStake } from 'utils/Helper';
import { NotificationManager } from 'react-notifications';
import CustomBetService from '../utils/CustomBetService';
import { detectMobile } from '../utils/UiHelper';

let system = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [],
    12: [],
    13: [],
    14: [],
};

let accum = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
    13: 0,
    14: 0,
};

let Model = {
    changed: false,
    frozen: false,
    isCustomBet: false,
    timeout: 0,
    betCount: 0,
    betList: [],
    hasMarkets: [],
    markets: {},
    customBetEventId: null,
    customBetStake: 0,
    customBetPrize: 0,
    system: {
        stake: [],
        prize: [],
        multi: [],
        names: [],
        totalPrize: 0,
        totalStake: 0,
        stakeFreeBet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
};

const Calculate = {
    TotalPrize: function() {
        let totalPrize = 0;
        if (Model.system.prize.length > 0) {
            Model.system.prize.forEach((v, k) => {
                totalPrize += parseFloat(v);
            });
        }
        Model.system.totalPrize = totalPrize;
        return totalPrize;
    },
    TotalStake: function() {
        let totalStake = 0;
        if (Model.system.stake.length > 0) {
            Model.system.stake.forEach((v, k) => {
                let multi = Model.system.multi[k];
                totalStake += v * multi;
            });
        }
        Model.system.totalStake = totalStake;
        return totalStake;
    },
    System: function(betList) {
        system = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
        };

        let odds = [];

        betList.map((v) => {
            odds.push(v.odds);
            return v;
        });

        function getCombinations(chars) {
            let result = [];
            let f = function(prefix, chars) {
                for (let i = 0; i < chars.length; i++) {
                    result.push(prefix + chars[i]);
                    f(prefix + chars[i] + ',', chars.slice(i + 1));
                }
            };
            f('', chars);
            return result;
        }
        let allCombinations = getCombinations(odds);
        allCombinations.forEach((el) => {
            let count = (el.match(/,/g) || []).length;
            system[count].push(el);
        });
        for (let i = 0; i < 15; i++) {
            let oddsArray = system[i];
            if (oddsArray.length > 0) {
                let accumulator = 0;

                for (let n = 0; n < oddsArray.length; n++) {
                    let value = oddsArray[n].toString();

                    if (value.indexOf(',') === -1) {
                        accumulator += parseFloat(value);
                    } else {
                        let oddsArray2 = value.split(',');
                        let odds = 1;

                        for (let k = 0; k < oddsArray2.length; k++) {
                            odds *= parseFloat(oddsArray2[k]);
                        }

                        accumulator += odds;
                    }
                }

                accum[i] = accumulator;

                if (Model.system.stake[i]) {
                    let stake = Model.system.stake[i];
                    if (stake > 0) {
                        let prize = parseFloat(stake) * accumulator;
                        Model.system.prize[i] = prize;
                    }
                }
            }
        }
    },
};

let System = {
    factorial: function(n) {
        n = Number(n);
        if (n < 0) {
            return;
        }
        if (n < 2) {
            return 1;
        } else {
            return n * System.factorial(n - 1);
        }
    },

    Init: function(betlist) {
        const betCount = betlist.length;
        const systemNames = BetslipService.getSystemNames();

        if (betCount > 1) {
            let n = betCount;
            for (let i = 0; i < 15; i++) {
                let r = i + 1;
                let systemCount =
                    System.factorial(n) /
                    (System.factorial(r) * System.factorial(n - r));
                if (i < betCount) {
                    Model.system.multi[i] = systemCount;
                    Model.system.stake[i] = Model.system.stake[i] || 0;
                    Model.system.prize[i] = 0;
                    Model.system.names[i] = systemNames[i];
                } else {
                    Model.system.multi[i] = 0;
                    Model.system.stake[i] = 0;
                    Model.system.prize[i] = 0;
                    delete Model.system.names[i];
                }
            }
            System.Update(betCount);
            Calculate.System(betlist);
        } else {
            for (let i = 1; i < 15; i++) {
                Model.system.multi[i] = 0;
                Model.system.stake[i] = 0;
                Model.system.prize[i] = 0;
            }
        }
    },

    Update: function(betCount) {
        const systemNames = BetslipService.getSystemNames();

        for (let i = 0; i < betCount; i++) {
            Model.system.names[i] = systemNames[i];
        }
    },
};

function getError(id) {
    let showError = true;
    let err = null;
    err = detectError(id);

    Model.errCode = id;
    Model.lastError = err;
    Model.showError = showError;

    return err;
}

function refresh(betslip, dispatch, noSubmit, cb) {
    BetslipService.updateOdds((res) => {
        if (res) {
            betslip = refreshOdds(res, betslip);

            if (betslip.changed || noSubmit) {
                dispatch({
                    type: BETSLIP_SET_VALUE,
                    payload: betslip,
                });
            } else {
                cb(betslip);
            }
        } else {
            cb(betslip);
        }
    });
}

export function getCustomBetBetslip() {
    return (dispatch) => {
        CustomBetService.getSlip()
            .then((res) => {
                const { data } = res;

                if (res.success && data.selectionList.length > 0) {
                    dispatch({
                        type: BETSLIP_CUSTOMBET_ADDED_OK,
                        payload: data,
                    });
                }
                dispatch({
                    type: BETSLIP_CUSTOMBET_FETCHED_STATUS,
                    payload: true,
                });
            })
            .catch((error) => console.log(error));
    };
}

function refreshOdds(data, betslip) {
    let res = betslip;

    betslip.betList.map((betitem, betIndex) => {
        data.betList.map((item, index) => {
            if (
                betitem.gameId === item.gameId &&
                item.betNr === betitem.betNr &&
                item.odds !== betitem.odds
            ) {
                res.betList[betIndex].odds = item.odds;

                if (!res.changed) {
                    res.changed = {};
                }

                res.changed[item.gameId] = item.betNr;
            }

            return item;
        });

        return betitem;
    });

    return res;
}

export function setup() {
    return (dispatch) => {
        BetslipService.updateOdds(
            (data) => {
                if (data && data.betList && data.betList.length > 0) {
                    data.betList.map((bet) => {
                        return bet;
                    });

                    dispatch({
                        type: BETSLIP_ADDED_OK,
                        payload: data,
                    });

                    if (data.betList.length > 1) {
                        System.Init(data.betList);
                        Calculate.TotalPrize();
                        Calculate.TotalStake();

                        dispatch({
                            type: BETSLIP_SET_VALUE,
                            payload: {
                                system: {
                                    ...Model.system,
                                    accum,
                                },
                                systemName: BetslipService.getSystemNames()[
                                    data.betList.length - 1
                                ],
                            },
                        });
                    }
                }
                dispatch({
                    type: BETSLIP_REGULAR_FETCHED_STATUS,
                    payload: true,
                });
            },
            (error) => {
                dispatch({
                    type: BETSLIP_ERROR,
                    payload: error.toString(),
                });
            }
        );
    };
}

export function reset() {
    return (dispatch) => {
        dispatch({
            type: BETSLIP_RESET,
            payload: true,
        });
    };
}

export function clearError() {
    return (dispatch) => {
        dispatch({
            type: BETSLIP_SET_VALUE,
            payload: {
                error: false,
                addError: false,
            },
        });
    };
}

export function addMarket(marketId, betNr, odds, isLive, sportId, hasMarkets) {
    return (dispatch) => {
        if (hasMarkets && hasMarkets.length >= 15) {
            dispatch({
                type: BETSLIP_ADDED_ERROR,
                payload: getError('MARKET_LIMIT'),
            });
        } else if (hasMarkets && hasMarkets.indexOf(marketId) > -1) {
            dispatch({
                type: BETSLIP_ADDED_ERROR,
                payload: getError('DUPLICATE_MARKET'),
            });
        } else {
            dispatch({
                type: BETSLIP_PENDING,
                payload: true,
            });

            BetslipService.addMarket(marketId, betNr, odds, isLive)
                .then((res) => {
                    if (!res.success && res.errorMessage) {
                        return dispatch({
                            type: BETSLIP_ADDED_ERROR,
                            payload: res.errorMessage,
                        });
                    }
                    const { data } = res;
                    const systemNames = BetslipService.getSystemNames();

                    dispatch({
                        type: BETSLIP_ADDED_OK,
                        payload: data,
                    });

                    if (data.betList.length > 1) {
                        dispatch({
                            type: BETSLIP_RESET_SINGLE_STAKE,
                        });
                        dispatch({
                            type: BETSLIP_SET_VALUE,
                            payload: {
                                system: {
                                    ...Model.system,
                                    accum,
                                },
                                systemName,
                            },
                        });
                        System.Init(data.betList);

                        let systemName = '';

                        Model.system.stake = Model.system.stake.map((s, i) => {
                            if (s > 0) {
                                if (systemName !== '') {
                                    systemName +=
                                        ', ' +
                                        Model.system.multi[i] +
                                        ' ' +
                                        systemNames[i];
                                } else {
                                    systemName +=
                                        Model.system.multi[i] +
                                        ' ' +
                                        systemNames[i];
                                }
                            }

                            Model.system.prize[i] = 0;

                            return 0;
                        });

                        Calculate.TotalPrize();
                        Calculate.TotalStake();

                        dispatch({
                            type: BETSLIP_SET_VALUE,
                            payload: {
                                system: {
                                    ...Model.system,
                                    accum,
                                },
                                systemName,
                            },
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: BETSLIP_ADDED_ERROR,
                        payload: error.errorMessage,
                    });
                });
        }
    };
}

export function addCustomBetMarket(
    marketId,
    betNr,
    odds,
    isLive,
    hasMarkets,
    stake
) {
    return (dispatch) => {
        if (hasMarkets && hasMarkets.length > 9) {
            dispatch({
                type: BETSLIP_CUSTOMBET_ADDED_ERROR,
                payload: getError('MARKET_LIMIT_CUSTOM_BET'),
            });
        } else {
            dispatch({
                type: BETSLIP_PENDING,
                payload: true,
            });
            //isLive ? setLiveMarkets() : null;
            CustomBetService.addCustomBetMarket(marketId, betNr, isLive)
                .then((res) => {
                    if (res.success) {
                        dispatch({
                            type: BETSLIP_CUSTOMBET_ADDED_OK,
                            payload: res.data,
                        });
                    } else {
                        dispatch({
                            type: BETSLIP_CUSTOMBET_ADDED_ERROR,
                            payload: res.errorMessage,
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: BETSLIP_CUSTOMBET_ADDED_ERROR,
                        payload: error.errorMessage,
                    });
                });
        }
    };
}

export function addCustomBetRecommendation(vaixEventId, vaixExternalEventId) {
    return (dispatch) => {
        CustomBetService.placeCustomBetRecommendation(
            vaixEventId,
            vaixExternalEventId.replace(/\s/g, '')
        ).then((res) => {
            const { data } = res;
            if (data) {
                dispatch({
                    type: BETSLIP_CUSTOMBET_ADDED_OK,
                    payload: data,
                });
            }
        });
    };
}

export function updateCustomBetTotalOdds() {
    return (dispatch) => {
        dispatch({
            type: BETSLIP_UPDATE_TOTAL_ODDS_VALUE_PENDING,
            payload: true,
        });
        CustomBetService.getSlip().then((res) => {
            if (res.success) {
                dispatch({
                    type: BETSLIP_UPDATE_TOTAL_ODDS_VALUE,
                    payload: res.data.totalOdds,
                });
                dispatch({
                    type: BETSLIP_UPDATE_TOTAL_ODDS_VALUE_PENDING,
                    payload: false,
                });
            }
        });
    };
}

export function setMarket(stake, odds) {
    return (dispatch) => {
        if ([',', '.'].indexOf(stake[stake.length - 1]) < 0) {
            stake = normalizeStake(stake);
        }

        dispatch({
            type: BETSLIP_SET_VALUE,
            payload: {
                singleStake: stake,
                singlePrize: odds * stake,
            },
        });
    };
}

export function setCustomBetMarket(stake, odds) {
    return (dispatch) => {
        if ([',', '.'].indexOf(stake[stake.length - 1]) < 0) {
            stake = normalizeStake(stake);
        }

        dispatch({
            type: BETSLIP_SET_CUSTOM_BET_VALUE,
            payload: {
                customBetStake: stake,
                customBetPrize: odds * stake,
            },
        });
    };
}

export function setSuggestion(suggestion) {
    return (dispatch) => {
        dispatch({
            type: BETSLIP_SET_SUGGESTION,
            payload: suggestion,
        });
    };
}

export function setSystemMarket(stake, index) {
    return (dispatch) => {
        const systemNames = BetslipService.getSystemNames();

        if ([',', '.'].indexOf(stake[stake.length - 1]) < 0) {
            stake = normalizeStake(stake);
        }

        Model.system.prize[index] = stake * accum[index];
        Model.system.stake[index] = stake;
        Calculate.TotalPrize();
        Calculate.TotalStake();

        let systemName = '';

        Model.system.stake.map((s, i) => {
            if (s > 0) {
                if (systemName !== '') {
                    systemName +=
                        ', ' + Model.system.multi[i] + ' ' + systemNames[i];
                } else {
                    systemName += Model.system.multi[i] + ' ' + systemNames[i];
                }
            }

            return s;
        });

        dispatch({
            type: BETSLIP_SET_VALUE,
            payload: {
                system: {
                    ...Model.system,
                },
                systemName,
            },
        });
    };
}

export const updateMarketsWithOddsChangeCheck = (events) => (
    dispatch,
    getState
) => {
    const { betslip } = getState();
    events.forEach((event) =>
        event.marketList
            .filter((market) => betslip.hasMarkets.includes(market.marketId))
            .forEach((market) => {
                const bet = betslip.betList.find(
                    ({ gameId }) => gameId === market.marketId
                );

                const marketItem = market.itemList.find(
                    (item) => item.betNr === bet.betNr
                );
                if (marketItem.odds !== bet.odds) {
                    updateMarket(betslip, market)(dispatch);
                }
            })
    );
};

export function updateMarket(betslip, data, eventPage) {
    return (dispatch, getState) => {
        let betslipLoc = eventPage ? getState().betslip : betslip;
        if (
            betslipLoc.customBetEventId !== null &&
            !betslipLoc.totalOddsUpdatePending
        ) {
            updateCustomBetTotalOdds();
        }
        if (betslipLoc.hasMarkets.length > 0) {
            let totalOdds = 1;
            betslipLoc.betList.map((betitem, betIndex) => {
                if (data && betitem.gameId === data.marketId && data.itemList) {
                    data.itemList.map((item, index) => {
                        if (item.betNr === betitem.betNr) {
                            betslipLoc.betList[betIndex].odds = item.odds;

                            if (!betslipLoc.changed) {
                                betslipLoc.changed = {};
                            }

                            betslipLoc.changed[data.marketId] = item.betNr;
                        }

                        return item;
                    });
                }

                totalOdds *= betslipLoc.betList[betIndex].odds;

                return betitem;
            });

            if (
                betslipLoc.betList.length > 1 &&
                betslipLoc.customBetEventId === null
            ) {
                System.Init(betslipLoc.betList);
                Calculate.TotalPrize();
                Calculate.TotalStake();

                betslipLoc.system = {
                    ...Model.system,
                };

                betslipLoc.totalOdds = totalOdds;
            } else if (betslipLoc?.customBetEventId === null) {
                betslipLoc.singlePrize =
                    betslipLoc.betList[0]?.odds * betslipLoc.singleStake || 0;
                betslipLoc.totalOdds = betslipLoc.betList[0].odds;
            }

            dispatch({
                type: BETSLIP_SET_VALUE,
                payload: betslipLoc,
            });
        }
    };
}

export function acceptMarkets() {
    return (dispatch) => {
        BetslipService.updateOdds((res) => {
            dispatch({
                type: BETSLIP_ACCEPT_CHANGES,
                payload: true,
            });
        });
    };
}

export function clearSingleMarket(eventId, marketId) {
    return (dispatch) => {
        dispatch({
            type: BETSLIP_PENDING,
            payload: true,
        });

        BetslipService.removeMarket(marketId)
            .then((res) => {
                dispatch({
                    type: BETSLIP_NORMALIZE_CHANGES,
                    payload: { marketId: marketId, betList: res.betList },
                });

                dispatch({
                    type: BETSLIP_RESET,
                    payload: true,
                });
            })
            .catch((error) => {
                dispatch({
                    type: BETSLIP_SET_VALUE,
                    payload: error.toString(),
                });
            });
    };
}

export function clearSingleCustomBetMarket(marketId, betNr) {
    return (dispatch) => {
        dispatch({
            type: BETSLIP_PENDING,
            payload: true,
        });
        CustomBetService.removeMarket(marketId, betNr)
            .then((res) => {
                if (res.success) {
                    let data = {
                        ...res.data,
                        removedMarkedId: marketId,
                    };
                    dispatch({
                        type: BETSLIP_CUSTOM_BET_NORMALIZE_CHANGES,
                        payload: { data, marketId },
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: BETSLIP_SET_VALUE,
                    payload: error.toString(),
                });
            });
        dispatch({
            type: BETSLIP_PENDING,
            payload: false,
        });
    };
}

export function clearSystemMarket(eventId, marketId) {
    return (dispatch) => {
        const systemNames = BetslipService.getSystemNames();

        dispatch({
            type: BETSLIP_PENDING,
            payload: true,
        });

        BetslipService.removeMarket(marketId)
            .then((data) => {
                dispatch({
                    type: BETSLIP_ADDED_OK,
                    payload: data,
                });

                if (data.betList.length > 1) {
                    System.Init(data.betList);

                    let systemName = '';

                    Model.system.stake = Model.system.stake.map((s, i) => {
                        if (s > 0) {
                            if (systemName !== '') {
                                systemName +=
                                    ', ' +
                                    Model.system.multi[i] +
                                    ' ' +
                                    systemNames[i];
                            } else {
                                systemName +=
                                    Model.system.multi[i] +
                                    ' ' +
                                    systemNames[i];
                            }
                        }

                        Model.system.prize[i] = 0;

                        return 0;
                    });

                    Calculate.TotalPrize();
                    Calculate.TotalStake();

                    dispatch({
                        type: BETSLIP_NORMALIZE_CHANGES,
                        payload: { marketId, betList: data.betList },
                    });

                    dispatch({
                        type: BETSLIP_SET_VALUE,
                        payload: {
                            system: {
                                ...Model.system,
                                accum,
                            },
                            systemName,
                        },
                    });
                } else {
                    Model.system = {
                        stake: [],
                        prize: [],
                        multi: [],
                        names: [],
                        totalPrize: 0,
                        totalStake: 0,
                    };

                    dispatch({
                        type: BETSLIP_NORMALIZE_CHANGES,
                        payload: { marketId, betList: data.betList },
                    });

                    dispatch({
                        type: BETSLIP_SET_VALUE,
                        payload: {
                            systemName: '',
                        },
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: BETSLIP_SET_VALUE,
                    payload: error.toString(),
                });
            });
    };
}

export function clearAllMarkets(betslipResetedFromLink = false) {
    return (dispatch) => {
        BetslipService.clearBetslip(
            (data) => {
                dispatch({
                    type: BETSLIP_RESET,
                    payload: betslipResetedFromLink,
                });
                Model.system = {
                    stake: [],
                    prize: [],
                    multi: [],
                    names: [],
                    totalPrize: 0,
                    totalStake: 0,
                };
            },
            (error) => {
                dispatch({
                    type: BETSLIP_ERROR,
                    payload: error,
                });
            }
        );
    };
}

export function clearAllCustomBetMarkets(betslipResetedFromLink = false) {
    return (dispatch) => {
        dispatch({
            type: BETSLIP_PENDING,
            payload: true,
        });
        CustomBetService.clearMarkets()
            .then((res) => {
                if (res?.success) {
                    dispatch({
                        type: BETSLIP_CUSTOM_BET_RESET,
                        payload: betslipResetedFromLink,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: BETSLIP_ERROR,
                    payload: error,
                });
            });
    };
}

export function changeStatusBetslipResetFromLink(status = false) {
    return (dispatch) => {
        dispatch({
            type: BETSLIP_STATUS_CHANGED_FROM_LINK,
            payload: status,
        });
    };
}

export function proceed(
    betslipObject,
    isAcceptedBetslipChanges = false,
    isRememberCoupon = false,
    isFreeBet = 0,
    freeBetAmount = 0
) {
    return (dispatch) => {
        dispatch({
            type: BETSLIP_PENDING,
            payload: true,
        });

        refresh(betslipObject, dispatch, false, (betslip) => {
            const betCount = betslip.betList.length;

            async function finish() {
                const popupData = betslip;

                BetslipService.clearBetslip(
                    (data) => {
                        dispatch({
                            type: BETSLIP_RESET,
                            payload: true,
                        });

                        dispatch({
                            type: BETSLIP_SUCCESS,
                            payload: true,
                        });

                        dispatch({
                            type: UI_SUCCESS_BETSLIP_POPUP_STATUS,
                            payload: popupData,
                        });

                        Model.system = {
                            stake: [],
                            prize: [],
                            multi: [],
                            names: [],
                            totalPrize: 0,
                            totalStake: 0,
                        };

                        if (isRememberCoupon) {
                            betslipObject.betList.forEach((item) => {
                                addMarket(
                                    item.gameId,
                                    item.betNr,
                                    item.odds,
                                    item.live ? '1' : '0',
                                    item.sportId
                                )(dispatch);
                            });
                        }
                    },
                    (error) => {
                        dispatch({
                            type: BETSLIP_ERROR,
                            payload: getError(error),
                        });
                    }
                );
            }

            function saveLastRequest(dispatch, slip) {
                UserService.HistoryBettingOpenedEx().then(({ data }) => {
                    let saleId = data?.salesList?.[0]?.saleId;

                    let coupons = data?.salesList.filter((item) => {
                        return item.saleId === saleId;
                    });

                    if (slip) {
                        dispatch({
                            type: BETSLIP_SET_SLIP,
                            payload: { ...slip, freeBetAmount: freeBetAmount },
                        });
                    }

                    dispatch({
                        type: BETSLIP_SET_LAST_RESULT,
                        payload: coupons,
                    });
                });
            }

            if (betCount > 0) {
                dispatch({
                    type: BETSLIP_SET_VALUE,
                    payload: {
                        pending: true,
                        disabled: true,
                        error: null,
                    },
                });

                let betType = 0;
                let amountList = [];

                if (betCount === 1) {
                    betType = 1;
                    if (isFreeBet) {
                        amountList = [freeBetAmount];
                    } else {
                        amountList = [betslip.singleStake];
                    }
                } else {
                    betType = 2;
                    if (isFreeBet) {
                        let i = 0;
                        while (i < betCount) {
                            if (i === betCount - 1) {
                                amountList.push(freeBetAmount);
                            } else {
                                amountList.push(0);
                            }
                            i++;
                        }
                    } else {
                        amountList = betslip.system.stake.slice(0, betCount);
                    }
                }

                let validAmounts = false;
                amountList.map((value) => {
                    if (value > 0) {
                        validAmounts = true;
                    }

                    return value;
                });

                if (validAmounts) {
                    BetslipService.proceedBet(
                        betType,
                        amountList,
                        (betData) => {
                            if (!betData) {
                                let left = betData.errorNumber;

                                let timer = setInterval(function() {
                                    left--;

                                    if (left === 0) {
                                        clearInterval(timer);

                                        dispatch({
                                            type: BETSLIP_SET_VALUE,
                                            payload: {
                                                timer: null,
                                            },
                                        });

                                        function checkReceipt() {
                                            BetslipService.checkReceipt(
                                                (data) => {
                                                    if (data) {
                                                        finish().then(() => {
                                                            saveLastRequest(
                                                                dispatch
                                                            );
                                                        });
                                                    } else {
                                                        if (
                                                            data.errorMessage ===
                                                                'LIVEBET_ERROR_-3' ||
                                                            data.errorMessage ===
                                                                'Odds er ændret'
                                                        ) {
                                                            dispatch({
                                                                type: UI_WARNING_MESSAGE,
                                                                payload:
                                                                    'Oddset har ændret sig',
                                                            });

                                                            refresh(
                                                                betslipObject,
                                                                dispatch,
                                                                true,
                                                                (betslip) => {
                                                                    console.log(
                                                                        betslip
                                                                    );
                                                                }
                                                            );
                                                        } else if (
                                                            data.errorMessage ===
                                                                'NO_RECEIPT_FOUND' ||
                                                            data.errorMessage ===
                                                                'Der skete en fejl - prøv igen'
                                                        ) {
                                                            setTimeout(() => {
                                                                checkReceipt();
                                                            }, 3000);
                                                        } else {
                                                            dispatch({
                                                                type: BETSLIP_ERROR,
                                                                payload: getError(
                                                                    data.errorMessage,
                                                                    false,
                                                                    dispatch
                                                                ),
                                                            });

                                                            if (data.betList) {
                                                                dispatch({
                                                                    type: BETSLIP_ITEM_ERROR,
                                                                    payload:
                                                                        data.betList,
                                                                });
                                                            }
                                                        }
                                                    }
                                                },
                                                (error) => {
                                                    if (
                                                        error.errorMessage ===
                                                            'LIVEBET_ERROR_-3' ||
                                                        error.errorMessage ===
                                                            'Odds er ændret'
                                                    ) {
                                                        dispatch({
                                                            type: UI_WARNING_MESSAGE,
                                                            payload:
                                                                'Oddset har ændret sig',
                                                        });

                                                        refresh(
                                                            betslipObject,
                                                            dispatch,
                                                            true,
                                                            (betslip) => {
                                                                console.log(
                                                                    betslip
                                                                );
                                                            }
                                                        );
                                                    } else {
                                                        dispatch({
                                                            type: BETSLIP_ERROR,
                                                            payload: getError(
                                                                error.errorMessage,
                                                                false,
                                                                dispatch
                                                            ),
                                                        });

                                                        if (
                                                            error.data &&
                                                            error.data.betList
                                                        ) {
                                                            dispatch({
                                                                type: BETSLIP_ITEM_ERROR,
                                                                payload:
                                                                    error.data
                                                                        .betList,
                                                            });
                                                        }
                                                    }
                                                }
                                            );
                                        }

                                        checkReceipt();
                                    } else {
                                        dispatch({
                                            type: BETSLIP_SET_VALUE,
                                            payload: {
                                                timer: left - 1,
                                            },
                                        });
                                    }
                                }, 1000);
                            } else {
                                if (betData && betData.freeBetInfo) {
                                    const freeBetInfo = betData.freeBetInfo;
                                    if (freeBetInfo === 1) {
                                        NotificationManager.info(
                                            'Du har nu kvalificeret dig til et Live Bet!'
                                        );
                                    }
                                }
                                finish().then(() => {
                                    saveLastRequest(dispatch, betData);
                                });
                            }
                        },
                        (error) => {
                            dispatch({
                                type: BETSLIP_ERROR,
                                payload: getError(error),
                            });
                        },
                        isAcceptedBetslipChanges,
                        isFreeBet
                    );
                } else {
                    dispatch({
                        type: BETSLIP_ERROR,
                        payload: 'Indsats er ugyldig',
                    });
                }
            }
        });
    };
}

export function proceedCustomBet(
    odds,
    stake,
    betslipData,
    isFreeBetSelected = 0,
    freeBetAmount = 0,
    isFreeBet = false
) {
    return (dispatch) => {
        dispatch({
            type: BETSLIP_PENDING,
            payload: true,
        });

        let totalAmount = 0;
        if (isFreeBetSelected && isFreeBet && freeBetAmount > 0) {
            totalAmount = betslipData.totalOdds * freeBetAmount - freeBetAmount;
        } else if (isFreeBetSelected && freeBetAmount > 0) {
            totalAmount = betslipData.totalOdds * freeBetAmount;
        } else {
            totalAmount = betslipData.customBetPrize;
        }
        let slip = {
            accumulateAmount: 0,
            amount: 0,
            bankerCnt: 0,
            betCount: 0,
            betList: betslipData.betList,
            couponCount: 1,
            gameCount: betslipData.betList.length,
            possiblePayout: totalAmount,
            saleId: null,
            stake: isFreeBetSelected
                ? freeBetAmount
                : betslipData.customBetStake,
            freeBetAmount: freeBetAmount,
            system: false,
            totalOdds: betslipData.totalOdds,
            totalOddsFraction: null,
            type: 2,
        };

        UserService.HistoryBettingOpenedEx().then(({ data }) => {
            let saleId = data?.salesList?.[0]?.saleId;

            let coupons = data?.salesList.filter((item) => {
                return item.saleId === saleId;
            });
            slip.saleId = saleId;

            dispatch({
                type: BETSLIP_SET_LAST_RESULT,
                payload: coupons,
            });
        });

        if (stake > 0 || (isFreeBetSelected && freeBetAmount > 0)) {
            let amount = isFreeBetSelected ? freeBetAmount : stake;
            CustomBetService.placeBet(odds, amount, isFreeBetSelected)
                .then((res) => {
                    if (res?.success) {
                        dispatch({
                            type: BETSLIP_CUSTOM_BET_RESET,
                            payload: true,
                        });

                        dispatch({
                            type: BETSLIP_SUCCESS,
                            payload: true,
                        });

                        dispatch({
                            type: UI_SUCCESS_BETSLIP_POPUP_STATUS,
                            payload: betslipData,
                        });

                        if (slip) {
                            dispatch({
                                type: BETSLIP_SET_SLIP,
                                payload: slip,
                            });
                        }
                        if (res?.data?.freeBetInfo === 1) {
                            NotificationManager.info(
                                'Du har nu kvalificeret dig til et Live Bet!'
                            );
                        }
                    } else {
                        let er = '';
                        if (
                            res.errorMessage === 'MTS-442' ||
                            res.errorMessage === 'SLIP_NOT_IN_SYNC'
                        ) {
                            er =
                                'En eller flere kampe/markeder er lukket for spil i øjeblikket.';
                        } else {
                            er = res.errorMessage;
                        }
                        dispatch({
                            type: BETSLIP_ERROR,
                            payload: er,
                        });
                    }
                })
                .catch((error) => {
                    let er = '';
                    if (error === 'MTS-442' || error === 'SLIP_NOT_IN_SYNC') {
                        er =
                            'En eller flere kampe/markeder er lukket for spil i øjeblikket.';
                    } else {
                        er = error;
                    }
                    dispatch({
                        type: BETSLIP_ERROR,
                        payload: er,
                    });
                });
        } else {
            dispatch({
                type: BETSLIP_ERROR,
                payload: 'Indsats er ugyldig',
            });
        }
    };
}

export function setLastResult(coupons) {
    return (dispatch) => {
        dispatch({
            type: BETSLIP_SET_LAST_RESULT,
            payload: coupons,
        });
    };
}

export function setLiveMarkets(markets) {
    return (dispatch) => {
        dispatch({
            type: BETSLIP_SET_LIVE_MARKETS,
            payload: markets,
        });
    };
}
