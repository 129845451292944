import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import * as UiActions from 'actions/UiActions';
import { validateUrlParams, getLinkForSidebar } from 'utils/Helper';
import ReactTooltip from 'react-tooltip';
import { SideBarWrapper, SideBarContent, MenuItem, StyledLink } from './styled';
import { getMenuItemsList } from './constants';
import Icon from '../../ecoui/Icon';
import { bindActionCreators } from 'redux';
import FancyBurger from 'ui/FancyBurger';
import { isTerminal } from '../../utils/UiHelper';
import { getCardFromStorage } from 'features/SpilID/useSpilIdCard';

const DesktopLeftSidebar = ({
    onBurgerClickHandler = () => {},
    sportTree = null,
    isOpen = false,
    uiActions = {},
    auth = null,
}) => {
    const [randomID, setRandomID] = useState(String(Math.random()));

    const openUserHistory = () => {
        if (auth.user) {
            browserHistory.push('/sport/konto/historik/aktive');
            uiActions.showModal('USER_MENU');
        } else {
            !isTerminal()
                ? uiActions.showModal('LOGIN', { stayOnPage: true })
                : browserHistory.push('/sport/log-ind');
        }
    };
    const openHelpDialog = () => {
        window.Intercom('show');
    };

    const showSpilID = () => {
        const spilIDCardFromStorage = getCardFromStorage() || {};
        auth?.user || Object.keys(spilIDCardFromStorage).length > 0
            ? uiActions.showModal('SPIL_ID_MODAL', auth)
            : uiActions.showModal('LOGIN');
    };

    const menuItemsList = getMenuItemsList({
        openUserHistory,
        openHelpDialog,
        showSpilID,
    });
    const data = sportTree;

    const isActiveLink = (link) => {
        if (window.location.pathname.split('/')[2] === 'big9') {
            return `/${window.location.pathname.split('/')[3]}` === link;
        } else {
            return `/${window.location.pathname.split('/')[2]}` === link;
        }
    };

    return (
        <Fragment>
            <SideBarWrapper isOpen={isOpen}>
                <FancyBurger
                    onClickHandler={onBurgerClickHandler}
                    isOpen={isOpen}
                />

                <SideBarContent>
                    {menuItemsList.map(
                        (item, index) =>
                            item.onClick ? (
                                <Fragment key={item.title}>
                                    <StyledLink
                                        data-tip={item.title}
                                        data-for={randomID}
                                        data-multiline={true}
                                        data-effect={'solid'}
                                        onClick={item.onClick}
                                        delimiter={item.delimiter}
                                        height="46px"
                                    >
                                        {item.icon ? (
                                            <Icon
                                                size="md"
                                                margin={
                                                    isOpen ? '0 20px 0 0 ' : '0'
                                                }
                                                color={
                                                    isActiveLink(item.link)
                                                        ? 'white'
                                                        : 'rgba(255,255,255,.5)'
                                                }
                                                src={item.icon}
                                                alt={item.link}
                                            />
                                        ) : null}
                                        <span
                                            className={
                                                isOpen ? 'open' : 'closed'
                                            }
                                        >
                                            {item.title}
                                        </span>
                                        {!isActiveLink(item.link) && isOpen ? (
                                            <i className="fa fa-chevron-right" />
                                        ) : null}
                                    </StyledLink>
                                </Fragment>
                            ) : (
                                <Fragment key={index}>
                                    <MenuItem
                                        to={item.link}
                                        data-for={randomID}
                                        data-tip={item.title}
                                        activelink={isActiveLink(
                                            item.link
                                        ).toString()}
                                        delimiter={item.delimiter ? 1 : 0}
                                        height="46px"
                                    >
                                        {item.icon ? (
                                            <Icon
                                                size="md"
                                                margin={
                                                    isOpen ? '0 20px 0 0 ' : '0'
                                                }
                                                color={
                                                    isActiveLink(item.link)
                                                        ? 'white'
                                                        : 'rgba(255,255,255,.5)'
                                                }
                                                src={item.icon}
                                                alt={item.link}
                                            />
                                        ) : null}
                                        <span
                                            className={
                                                isOpen ? 'open' : 'closed'
                                            }
                                        >
                                            {item.title}
                                        </span>
                                        {/*{item.isNewElement ? (
                                            <span
                                                className={
                                                    isOpen
                                                        ? 'isNewItem'
                                                        : 'isNewItem sm'
                                                }
                                            >
                                                nyhed
                                            </span>
                                        ) : null}*/}
                                        {!isActiveLink(item.link) && isOpen ? (
                                            <i className="fa fa-chevron-right" />
                                        ) : null}
                                    </MenuItem>
                                </Fragment>
                            )
                    )}
                    {isOpen && data.fetched && data.hotData ? (
                        <Fragment>
                            {data.hotData.map((item, index) => (
                                <MenuItem
                                    className={isOpen ? 'open' : 'closed'}
                                    key={index}
                                    to={getLinkForSidebar(data, item)}
                                    activelink={isActiveLink(
                                        getLinkForSidebar(data, item)
                                    ).toString()}
                                    title={item.name}
                                    hoveranimation={1}
                                    delimiter={
                                        index === data.hotData.length - 1
                                            ? 1
                                            : 0
                                    }
                                >
                                    <span>{item.name}</span>
                                </MenuItem>
                            ))}
                        </Fragment>
                    ) : null}
                    {isOpen && data.fetched && data.sportData ? (
                        <Fragment>
                            {data.sportData
                                .filter((sport) => sport.count > 0)
                                .slice(0, 5)
                                .map((item, index) => (
                                    <MenuItem
                                        hoveranimation={1}
                                        className={isOpen ? 'open' : 'closed'}
                                        key={index}
                                        marginbottom="15px"
                                        to={'/' + validateUrlParams(item.name)}
                                        activelink={isActiveLink(
                                            '/' + validateUrlParams(item.name)
                                        ).toString()}
                                        title={item.name}
                                    >
                                        <span>{item.name}</span>
                                    </MenuItem>
                                ))}
                        </Fragment>
                    ) : null}
                    {!isOpen && (
                        <ReactTooltip
                            border={true}
                            id={randomID}
                            showTabSwitcher={false}
                            borderColor="#70707033"
                            className="tooltip sidebar"
                            type="light"
                            place="right"
                            effect="solid"
                        />
                    )}
                </SideBarContent>
            </SideBarWrapper>
        </Fragment>
    );
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DesktopLeftSidebar);
