export const HISTORY_STATUSES = {
    0: 'Aktiv',
    1: 'Vundet - Ikke udbetalt',
    2: 'Vundet',
    3: 'Tabt',
    4: 'Udbetalt',
    6: 'Ikke bekræftet',
    7: 'Udløbet',
    8: 'Annulleret',
    9: 'Annulleret - Ikke refunderet',
};
