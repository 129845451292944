import {
    UI_CLEAR_MESSAGE,
    UI_COUPON_STATUS,
    UI_DANMARK_ITEMS,
    UI_ERROR_MESSAGE,
    UI_GAME_WIDGET_STATUS,
    UI_HIGHLIGHT_STATUS,
    UI_KEYBOARD_POPUP_STATUS,
    UI_LOGIN_MODAL_STATUS,
    UI_PRINT_POPUP_STATUS,
    UI_ROD25_POPUP_STATUS,
    UI_SET_LIVESCORE_STATUS,
    UI_SIGNUP_MODAL_STATUS,
    UI_SPORT_ACTIVE_TAB,
    UI_SUCCESS_BETSLIP_POPUP_STATUS,
    UI_VOUCHER_POPUP_STATUS,
    UI_WARNING_MESSAGE,
    UI_VERIFICATION_MODAL_STATUS,
    UI_SET_LEFT_SIDEBAR_STATUS,
    UI_ACTIVE_BETS,
    UI_AFTER_LOGIN_SET,
    UI_AFTER_LOGIN_RESET,
    UI_SPIL_ID_MODAL_STATUS,
    SET_DEFERRED_PROMPT,
    UI_SHOW_MODAL,
    UI_HIDE_MODAL,
    UI_PIN_LOGIN_STATUS,
} from 'constants/ui';

export function setSportTab(index = '0') {
    return (dispatch) => {
        dispatch({
            type: UI_SPORT_ACTIVE_TAB,
            payload: index,
        });
    };
}

export function setGameWidgetStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_GAME_WIDGET_STATUS,
            payload: status,
        });
    };
}

export function setLoginModalStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_LOGIN_MODAL_STATUS,
            payload: status,
        });
    };
}

export function setSpilIdModalStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_SPIL_ID_MODAL_STATUS,
            payload: status,
        });
    };
}

export function setSignupModalStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_SIGNUP_MODAL_STATUS,
            payload: status,
        });
    };
}

export function setHighlightStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_HIGHLIGHT_STATUS,
            payload: status,
        });
    };
}

export function notification(type, msg) {
    return (dispatch) => {
        dispatch({
            type: type,
            payload: msg,
        });
    };
}

export function setDeferredPrompt(deferredPrompt) {
    return {
        type: SET_DEFERRED_PROMPT,
        deferredPrompt,
    };
}

export function notifyError(msg) {
    return (dispatch) => {
        dispatch({
            type: UI_ERROR_MESSAGE,
            payload: msg,
        });
    };
}

export function clearNotification() {
    return (dispatch) => {
        dispatch({
            type: UI_CLEAR_MESSAGE,
            payload: true,
        });
    };
}

export function setDannmarkItems(items) {
    return (dispatch) => {
        dispatch({
            type: UI_DANMARK_ITEMS,
            payload: items,
        });
    };
}

export function setCouponPopupStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_COUPON_STATUS,
            payload: status,
        });
    };
}

export function setPrintPopupStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_PRINT_POPUP_STATUS,
            payload: status,
        });
    };
}

export function setApplyVoucherPopupStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_VOUCHER_POPUP_STATUS,
            payload: status,
        });
    };
}

export function setKeyboardPopupStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_KEYBOARD_POPUP_STATUS,
            payload: status,
        });
    };
}

export function setRod25PopupStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_ROD25_POPUP_STATUS,
            payload: status,
        });
    };
}

export function setSuccessBetslipPopupSatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_SUCCESS_BETSLIP_POPUP_STATUS,
            payload: status,
        });
    };
}

export function betslipLimit() {
    return (dispatch) => {
        dispatch({
            type: UI_WARNING_MESSAGE,
            payload: 'Det er ikke muligt at tilføje flere spil',
        });
    };
}

export function setLiveScoreStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_SET_LIVESCORE_STATUS,
            payload: status,
        });
    };
}

export function setVerificationModalStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_VERIFICATION_MODAL_STATUS,
            payload: status,
        });
    };
}
export function setLeftSidebarStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_SET_LEFT_SIDEBAR_STATUS,
            payload: status,
        });
    };
}
export function setActiveBets(count) {
    return (dispatch) => {
        dispatch({
            type: UI_ACTIVE_BETS,
            payload: count,
        });
    };
}
export function afterLoginSet(status) {
    return (dispatch) => {
        dispatch({
            type: UI_AFTER_LOGIN_SET,
            payload: status,
        });
    };
}
export function afterLoginReset() {
    return (dispatch) => {
        dispatch({
            type: UI_AFTER_LOGIN_RESET,
            payload: null,
        });
    };
}
export function showModal(
    modalId = '',
    data = null,
    duration = 0,
    showingAction = UI_SHOW_MODAL,
    hidingAction = UI_HIDE_MODAL
) {
    return (dispatch) => {
        dispatch({
            type: showingAction,
            payload: {
                id: modalId,
                data,
                visible: true,
                cameFrom: window.location.pathname,
            },
        });

        if (duration > 0) {
            setTimeout(() => {
                dispatch({
                    type: hidingAction,
                    payload: {
                        id: modalId,
                        meta: {},
                    },
                });
            }, duration);
        }
    };
}

export function hideModal(modalId = '', meta = {}) {
    return (dispatch) => {
        dispatch({
            type: UI_HIDE_MODAL,
            payload: {
                id: modalId,
                meta,
            },
        });
    };
}

export function changePinLoginStatus(status) {
    return (dispatch) => {
        dispatch({
            type: UI_PIN_LOGIN_STATUS,
            payload: {
                status,
            },
        });
    };
}
