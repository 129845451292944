export const EVENT_OBJECT_FETCH_PENDING = 'EVENT_OBJECT_FETCH_PENDING';
export const EVENT_OBJECT_FETCH_SUCCESS = 'EVENT_OBJECT_FETCH_SUCCESS';
export const EVENT_OBJECT_FETCH_ERROR = 'EVENT_OBJECT_FETCH_ERROR';
export const EVENT_OBJECT_UPDATE_MARKET = 'EVENT_OBJECT_UPDATE_MARKET';
export const EVENT_OBJECT_REMOVE_MARKET = 'EVENT_OBJECT_REMOVE_MARKET';
export const EVENT_OBJECT_CHECK_MARKETS = 'EVENT_OBJECT_CHECK_MARKETS';
export const EVENT_OBJECT_UPDATE_STATUS = 'EVENT_OBJECT_UPDATE_STATUS';
export const EVENT_OBJECT_IS_UPDATED = 'EVENT_OBJECT_IS_UPDATED';
export const EVENT_OBJECT_PATCH_DATA = 'EVENT_OBJECT_PATCH_DATA';
export const EVENT_IS_FINISHED = 'EVENT_IS_FINISHED';
export const EVENT_OBJECT_UPDATE_CUSTOMBET_MARKETS =
    'EVENT_OBJECT_UPDATE_CUSTOMBET_MARKETS';
export const EVENT_OBJECT_PENDING_CUSTOMBET_MARKETS =
    'EVENT_OBJECT_PENDING_CUSTOMBET_MARKETS';
export const EVENT_OBJECT_RESET_MARKETS = 'EVENT_OBJECT_RESET_MARKETS';

/**
 * @typedef {Object} EventModel
 * @property {String} awayTeam
 * @property {Number} awayTeamId
 * @property {String} competition
 * @property {Number} competitionId
 * @property {Number} defaultMarketId
 * @property {Number} eventId
 * @property {String} eventName
 * @property {Number} externalId
 * @property {Number} groupId
 * @property {String} groupName
 * @property {String} homeTeam
 * @property {Number} homeTeamId
 * @property {String} kickoff
 * @property {String} kickoffDate
 * @property {String} kickoffTime
 * @property {Boolean} live
 * @property {EventLiveScore|null} liveScore
 * @property {Number} marketCount
 * @property {Array<MarketModel>} marketList
 * @property {Number} priority
 * @property {String} sport
 * @property {Number} sportId
 * @property {Number} status
 * @property {String} streamingId
 * @property {Boolean} tradeLive
 * @property {Null} tvChannels
 */

/**
 * @typedef {Object} EventLiveScore
 * @property {String} awayScore
 * @property {Number} eventId
 * @property {Object} extraInfo
 * @property {String} homeScore
 * @property {String} message
 * @property {Array} scorePartList
 * @property {String} status
 * @property {Null} statusDescription
 * @property {Number} time
 */

/**
 * @typedef {Object} MarketModel
 * @property {String} channels
 * @property {Number} eventId
 * @property {String} extraInfo
 * @property {String} freeText
 * @property {Array<MarketItemModel>} itemList
 * @property {Number} itemOrder
 * @property {Number} mainMarket
 * @property {Number} marketId
 * @property {String} marketName
 * @property {Number} marketSubType
 * @property {Number} marketType
 * @property {Number} marketTypeGroup
 * @property {String} marketTypeGroupName
 * @property {Number} marketTypeGroupPriority
 * @property {String} marketTypeName
 * @property {Number} marketTypePriority
 * @property {String} part
 * @property {Number} sportId
 * @property {Number} status
 * @property {Number} popular
 */

/**
 * @typedef {Object} MarketItemModel
 * @property {String} bet
 * @property {Number} popular
 * @property {Number} betCount
 * @property {Number} betNr
 * @property {String} competitor
 * @property {Number} competitorId
 * @property {String} homeDrawAway
 * @property {Number} odds
 * @property {String} oddsFraction
 * @property {Number} oddsInt
 * @property {String} outcome
 */
