import React from 'react';
import { TransferMoneyModal } from '@it25syv/25syv-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideModal as HideModal } from '../actions/UiActions';
import Wrapper from '../ui/Wrapper';
import { detectMobile, enableBodyScroll } from '../utils/UiHelper';
import { browserHistory } from 'react-router';
import { goToWithBasePath } from '../constants/appConfig';

const TransferMoneyNotificationModal = ({ hideModal, modalData }) => {
    const handleCloseModal = () => {
        if (detectMobile()) {
            browserHistory.push(goToWithBasePath('/konto'));
        } else {
            browserHistory.push(goToWithBasePath());
            hideModal('USER_MENU');
            enableBodyScroll();
        }
        hideModal('TRANSFER_MONEY_NOTIFICATION_MODAL');
    };

    return (
        <Wrapper
            height={'100%'}
            width={'100%'}
            minHeight={'100vh'}
            flex
            flexDirection={'column'}
            flexAlign={'center'}
            justify={detectMobile() ? 'flex-end' : 'center'}
            padding={detectMobile() ? '0 0 100px' : '0'}
            overflow={'hidden'}
            boxSizing={'border-box'}
        >
            <TransferMoneyModal
                amount={modalData.amount}
                closeModal={handleCloseModal}
            />
        </Wrapper>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        hideModal: bindActionCreators(HideModal, dispatch),
    };
}
export default connect(
    null,
    mapDispatchToProps
)(TransferMoneyNotificationModal);
