import {
    CAMPAIGNS_FETCH_ERROR,
    CAMPAIGNS_FETCH_PENDING,
    CAMPAIGNS_FETCH_SUCCESS,
} from '../constants/campaigns';

const initialState = {
    pending: false,
    fetched: false,
    data: [],
    error: false,
};

export default function campaigns(state = initialState, action) {
    switch (action.type) {
        case CAMPAIGNS_FETCH_PENDING:
            return {
                ...state,
                pending: true,
                fetched: false,
                data: [],
                error: false,
            };

        case CAMPAIGNS_FETCH_SUCCESS:
            return {
                ...state,
                pending: false,
                fetched: true,
                data: action.payload,
                error: false,
            };

        case CAMPAIGNS_FETCH_ERROR:
            return {
                ...state,
                pending: false,
                fetched: false,
                data: [],
                error: action.payload,
            };

        default:
            return state;
    }
}
