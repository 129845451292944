import React from 'react';
import { TransactionDetails } from '@it25syv/25syv-ui';
import { bindActionCreators } from 'redux';
import { hideModal as HideModal } from '../actions/UiActions';
import { connect } from 'react-redux';
import Wrapper from '../ui/Wrapper';

const TransactionDetailsModal = ({ hideModal, modalData }) => {
    return (
        <Wrapper
            height={'100%'}
            width={'100%'}
            flex
            flexDirection={'column'}
            flexAlign={'center'}
            justify={'center'}
            padding={'0'}
            margin={'0'}
        >
            <TransactionDetails
                data={modalData.data}
                onClose={() => {
                    hideModal('TRANSITION_DETAILS_MODAL');
                }}
            />
        </Wrapper>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: bindActionCreators(HideModal, dispatch),
    };
};
export default connect(
    null,
    mapDispatchToProps
)(TransactionDetailsModal);
