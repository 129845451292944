import winImg from 'images/win.svg';
import loseImg from 'images/lose.svg';
import React from 'react';

export const systemNames = [
    'null',
    'Single',
    'Double',
    'Trippel',
    'Firling',
    'Femling',
    'Seksling',
    'Syvling',
    'Otteling',
    'Niling',
    'Tiling',
    'Elleveling',
    'Tolvling',
    'Trettenling',
    'Fjortenling',
    'Femtenling',
];

export const renderIcon = (status) => {
    switch (status) {
        case 1:
            return (
                <img
                    src={loseImg}
                    alt="lose icon"
                    width="14px"
                    height="14px"
                    className="transfer-icon"
                />
            );
        case 2:
            return (
                <img
                    src={winImg}
                    alt="win icon"
                    width="14px"
                    height="14px"
                    className="transfer-icon"
                />
            );
        case 3:
            return (
                <img
                    src={loseImg}
                    alt="lose icon"
                    width="14px"
                    height="14px"
                    className="transfer-icon"
                />
            );
        default:
            return (
                <i
                    className="fa fa-gray transfer-icon"
                    aria-hidden="true"
                    style={{ color: '#B8C3D6' }}
                />
            );
    }
};
export const renderStatus = (coupons) => {
    const priorityOrder = [0, 3, 4, 6, 7, 1, 2, 8, 9];

    const statusCounts = {};

    for (let coupon of coupons) {
        statusCounts[coupon.status] = (statusCounts[coupon.status] || 0) + 1;
    }

    const statusLabels = {
        0: 'Aktiv',
        1: 'Vundet',
        2: 'Vundet',
        3: 'Tabt',
        4: 'Cashed out',
        6: 'Ikke bekræftet',
        7: 'Udløbet',
        8: 'Annulleret',
        9: 'Annulleret',
    };
    for (let status of priorityOrder) {
        if (statusCounts[status]) {
            return statusLabels[status];
        }
    }
    return '';
};

export const getStatusBorder = (coupons) => {
    if (coupons.some((coupon) => coupon.status === 0)) {
        return '#B8C3D6';
    }
    if (coupons.some((coupon) => coupon.status === 1 || coupon.status === 2)) {
        return '#18AA1B';
    }
    if (coupons.some((coupon) => coupon.status === 8 || coupon.status === 9)) {
        return '#E24E4E';
    }
    if (coupons.some((coupon) => coupon.status === 3)) {
        return '#E24E4E';
    }

    return '#B8C3D6';
};
