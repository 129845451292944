import {
    SPIL_ID_RESET,
    SPIL_ID_PENDING,
    SPIL_ID_SUCCESS,
} from '../constants/spilId';

const initialState = {
    pending: false,
    fetched: false,
    data: {
        userId: '',
        rofusStatus: '',
    },
};

export default function spilId(state = initialState, action) {
    switch (action.type) {
        case SPIL_ID_PENDING:
            return {
                ...state,
            };
        case SPIL_ID_SUCCESS:
            return {
                ...state,
                data: {
                    userId: action.payload.user_id,
                    rofusStatus: action.payload.rofus_status,
                },
            };
        case SPIL_ID_RESET:
            return {
                ...state,
            };
        default:
            return state;
    }
}
