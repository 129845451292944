const BASE_VAIX_API_PATH = {
    dev: 'https://staging-api.vaix.ai/',
    prod: 'https://api.vaix.ai/',
};

export const getVaixBasePath = () => {
    if (window.config?.ENV) {
        if (window.config.ENV === 'test') {
            return BASE_VAIX_API_PATH.prod;
        } else {
            return BASE_VAIX_API_PATH[window.config.ENV];
        }
    }
};

const VAIX_HEADER_AUTH = {
    dev: {
        Authorization:
            'Bearer d1ef22f4a3e1e0768b7fb389bf702d976aa1089847b17b2ece4fbc75e0d6612e',
        'x-vaix-client-id': 'bet25',
    },
    prod: {
        Authorization:
            'Bearer dea6acf83db99d7514d1cb089c7be326c5bd7256b617a9dbea7964f7f84df1ef',
        'x-vaix-client-id': 'bet25',
    },
};

const getVaixAuthKey = () => {
    if (window.config?.ENV) {
        if (window.config.ENV === 'test') {
            return VAIX_HEADER_AUTH.prod;
        } else {
            return VAIX_HEADER_AUTH[window.config.ENV];
        }
    }
};

export const VAIX_HEADER = getVaixAuthKey();
export const PROD_VAIX_HEADER_AUTH = VAIX_HEADER_AUTH.prod;

export const VAIX_LIVE_POPULAR_EVENTS_URL = (
    userId = 0,
    offsetFrom = -3,
    offsetTo = 0,
    count = 10
) =>
    `api/sports/events/recommended?user=${userId}&count=${count}&from_offset=${offsetFrom}h&to_offset=${offsetTo}&filters=status:eq:live&order_by=-league_confidence%2C%2Bbegin&location=sr-widget:livebetrecommendation.eventlist:recommended`;

export const VAIX_EVENTS_BY_SPORT_ID_URL = (
    userId = 0,
    offsetFrom = -5,
    count = 100,
    sportId = 1
) =>
    `api/sports/events/recommended?user=${userId}&count=${count}&from_offset=${offsetFrom}h&order_by=-league_confidence,-event_confidence,%2Bbegin&location=sr-widget:livebetrecommendation.eventlist:recommended&filters=status%3Aeq%3Alive%3Bsport_id%3Aeq%3Asr%3Asport%3A${sportId}`;

//&fields=event_id,league_id,league_confidence,confidence,participants,league
export const VAIX_POPULAR_EVENTS_URL = (userId = 0, offset = 48, count = 10) =>
    `api/sports/events/recommended?user=${userId}&to_offset=${offset}h&filters=status:eq:not_started&count=${count}&location=sr-widget:livepagesport:recommended&order_by=-league_confidence%2C%2Bbegin`;

export const VAIX_NAVIGATION_ORDER_URL = (
    userId = 0,
    offsetFrom = -3,
    offsetTo = 0,
    count = 10
) =>
    `api/sports/sports/recommended?user=${userId}&filters=status:eq:live&from_offset=${offsetFrom}h&to_offset=${offsetTo}&count=${count}&location=sr-widget:livepagesport:recommended`;

export const VAIX_LIVE_FAVORITE_URL = (
    userId = 0,
    offsetFrom = -3,
    offsetTo = 0,
    count = 30
) =>
    `api/sports/events/recommended?user=${userId}&from_offset=${offsetFrom}h&to_offset=${offsetTo}&filters=status:eq:live&count=${count}&order_by=-league_confidence%2C%2Bbegin&location=sr-widget:livepagebetrecommendation.eventlist:recommended`;

export const VAIX_HIGHLIGHTS = (userId = 0, offsetTo = `2d`, count = 6) =>
    `api/sports/events/recommended?user=${userId}&&to_offset=${offsetTo}&count=${count}&order_by=-event_confidence&location=sr-widget/sr-widget-1:betrecommendation:recommended`;

export const VAIX_TRACKER = () => `api/tracker/events`;

/*
curl 'https://staging-api.vaix.ai/api/sports/bet_builders/recommended?user=0&to_offset=24h&length=4&count=8&filters=status:eq:not_started;sport_id:eq:sr:sport:1&location=sr-widget:samegamecombinations:recommended' \
  -H 'accept: application/json' \
  -H 'accept-language: ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,uk;q=0.6' \
  -H 'authorization: Bearer d1ef22f4a3e1e0768b7fb389bf702d976aa1089847b17b2ece4fbc75e0d6612e' \
  -H 'cache: no-cache' \2
  -H 'cache-control: no-cache' \
  -H 'content-type: application/json' \
  -H 'origin: http://localhost:3000' \
  -H 'pragma: no-cache' \
  -H 'referer: http://localhost:3000/' \
  -H 'sec-ch-ua: "Google Chrome";v="123", "Not:A-Brand";v="8", "Chromium";v="123"' \
  -H 'sec-ch-ua-mobile: ?0' \
  -H 'sec-ch-ua-platform: "macOS"' \
  -H 'sec-fetch-dest: empty' \
  -H 'sec-fetch-mode: cors' \
  -H 'sec-fetch-site: cross-site' \
  -H 'user-agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36' \
  -H 'x-vaix-client-id: bet25'
*/
