import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import UIModalNew from 'components/UIModalNew';
import * as UiActions from 'actions/UiActions';
import { bindActionCreators } from 'redux';
import { hideModal } from 'actions/UiActions';
import Text from 'ui/Text';
import CloseIcon from 'images/close-icon.svg';
import Icon from '../ecoui/Icon';
import Button from 'ui/Button';
import { browserHistory } from 'react-router';

const Top = styled.div`
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
`;

const LowBalanceModal = (props) => {
    const onCloseModal = () => {
        props.hideModal('LOW_BALANCE');
    };

    const onDepositRedirect = () => {
        onCloseModal();
        browserHistory.push('/sport/konto/indbetaling');
    };

    return (
        <UIModalNew>
            <Top>
                <Icon
                    src={CloseIcon}
                    onClick={() => onCloseModal('LOW_BALANCE')}
                    color={'#c6ccdb'}
                    width={'20px'}
                    height={'20px'}
                    style={{ cursor: 'pointer' }}
                />
            </Top>
            <Text bold margin={'0'} align={'center'} size="24" color="#000000">
                Din balance er lav.
            </Text>
            <Text
                color="#333333"
                margin={'19px 0'}
                align={'center'}
                size="1rem"
            >
                Prøv igen med et lavere beløb, eller lav en indbetaling.
            </Text>
            <Button
                margin="10px 0"
                width="100%"
                active
                onClick={onDepositRedirect}
            >
                INDBETAL
            </Button>
        </UIModalNew>
    );
};

function mapStateToProps(state) {
    return {
        limits: state.auth.user.limits,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
        hideModal: bindActionCreators(hideModal, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LowBalanceModal);
