import React, { Component } from 'react';
import classNames from 'classnames';
import '../css/ViewPopup.css';
import { disableBodyScroll, enableBodyScroll } from 'utils/UiHelper';

class ViewPopup extends Component {
    static defaultProps = {
        showCloseButton: false,
        smallMode: false,
        onClosePopupHandler: () => {},
    };

    constructor() {
        super();
        this.focusInput = React.createRef();
    }

    componentDidMount() {
        disableBodyScroll();
    }

    componentDidUpdate() {
        this.focusInput.current && this.focusInput.current.focus();
    }

    componentWillUnmount() {
        enableBodyScroll();
    }

    onCloseBtn = () => {
        this.props.onClosePopupHandler();
    };

    render() {
        return (
            <section
                className={classNames('view-popup', {
                    small: this.props.smallMode,
                    [this.props.className]: this.props.className,
                })}
            >
                <div
                    className="view-popup-background"
                    onClick={this.onCloseBtn}
                />
                <div
                    style={this.props.wrapperStyles}
                    className="view-popup-wrapper"
                >
                    {this.props.showCloseButton && (
                        <div
                            className="view-close-popup"
                            onClick={this.onCloseBtn}
                        >
                            <i className="fa fa-times" aria-hidden="true" />
                        </div>
                    )}
                    <button style={{ opacity: 0 }} ref={this.focusInput} />
                    <div
                        style={this.props.contentStyles}
                        className="view-popup-content"
                        id="view-popup-scroll"
                    >
                        {this.props.children}
                    </div>
                </div>
            </section>
        );
    }
}

export default ViewPopup;
