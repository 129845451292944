export const VAIX_TRACKING_TYPE = {
    impressions: 'impressions:events',
    clickEvent: 'clicks:event',
    clickOutcome: 'clicks:outcome',
    clickCombinations: 'clicks:combination',
};

export const VAIX_TRACKING_LOCATIONS = {
    highlights: 'sr-widget/sr-widget-1:betrecommendation:recommended',
    betBuilderHomePage: 'sr-widget:betrecommendation.betbuilder:recommended',
    betBuilderEventPage: 'sr-widget:samegamecombinationseventpage:recommended',
    liveEvents: 'sr:widget:livepagesport:recommended',
};

export const VAIX_TRACKING_LOCATION_FOR_TYPE_OF_SPORT = (typeOfSport) => {
    let sport = typeOfSport === 'Fodbold' ? 'Football' : typeOfSport;
    return `sr-widget:livepagesport_${sport.toLowerCase()}:recommended`;
};
