export const UI_SPORT_ACTIVE_TAB = 'UI_SPORT_ACTIVE_TAB';
export const UI_GAME_WIDGET_STATUS = 'UI_GAME_WIDGET_STATUS';
export const UI_LOGIN_MODAL_STATUS = 'UI_LOGIN_MODAL_STATUS';
export const UI_SIGNUP_MODAL_STATUS = 'UI_SIGNUP_MODAL_STATUS';
export const UI_HIGHLIGHT_STATUS = 'UI_HIGHLIGHT_STATUS';
export const SET_DEFERRED_PROMPT = 'SET_DEFERRED_PROMPT';
export const UI_WARNING_MESSAGE = 'UI_WARNING_MESSAGE';
export const UI_ERROR_MESSAGE = 'UI_ERROR_MESSAGE';
export const UI_SUCCESS_MESSAGE = 'UI_SUCCESS_MESSAGE';
export const UI_INFO_MESSAGE = 'UI_INFO_MESSAGE';
export const UI_CLEAR_MESSAGE = 'UI_CLEAR_MESSAGE';
export const UI_DANMARK_ITEMS = 'UI_DANMARK_ITEMS';
export const UI_COUPON_STATUS = 'UI_COUPON_STATUS';
export const UI_VOUCHER_POPUP_STATUS = 'UI_VOUCHER_POPUP_STATUS';
export const UI_PRINT_POPUP_STATUS = 'UI_PRINT_POPUP_STATUS';
export const UI_KEYBOARD_POPUP_STATUS = 'UI_KEYBOARD_POPUP_STATUS';
export const UI_SUCCESS_BETSLIP_POPUP_STATUS =
    'UI_SUCCESS_BETSLIP_POPUP_STATUS';
export const UI_VERIFICATION_MODAL_STATUS = 'UI_VERIFICATION_MODAL_STATUS';

export const UI_SPORT_OVERVIEW_NAVIGATION_TYPE =
    'UI_SPORT_OVERVIEW_NAVIGATION_TYPE';
export const UI_SPORT_OVERVIEW_ACTIVE_SPORT = 'UI_SPORT_OVERVIEW_ACTIVE_SPORT';
export const UI_SPORT_OVERVIEW_BACK_BTN = 'UI_SPORT_OVERVIEW_BACK_BTN';
export const UI_SPORT_OVERVIEW_ACTIVE_GROUP = 'UI_SPORT_OVERVIEW_ACTIVE_GROUP';
export const UI_SPORT_OVERVIEW_SHOW_ACTUAL_EVENTS =
    'UI_SPORT_OVERVIEW_SHOW_ACTUAL_EVENTS';
export const UI_SPORT_OVERVIEW_PARAMS = 'UI_SPORT_OVERVIEW_PARAMS';
export const UI_SPORT_OVERVIEW_REST_EUROPE = 'UI_SPORT_OVERVIEW_REST_EUROPE';
export const UI_SPORT_OVERVIEW_REST_WORLD = 'UI_SPORT_OVERVIEW_REST_WORLD';
export const UI_SPORT_OVERVIEW_ANOTHER_GROUPS =
    'UI_SPORT_OVERVIEW_ANOTHER_GROUPS';
export const UI_SPORT_OVERVIEW_SEARCH_TEXT = 'UI_SPORT_OVERVIEW_SEARCH_TEXT';
export const UI_SPORT_OVERVIEW_TOURANMENT_ID =
    'UI_SPORT_OVERVIEW_TOURANMENT_ID';
export const UI_SPORT_OVERVIEW_TOURANMENT_STATUS =
    'UI_SPORT_OVERVIEW_TOURANMENT_STATUS';
export const UI_SPORT_OVERVIEW_RESET = 'UI_SPORT_OVERVIEW_RESET';
export const UI_ROD25_POPUP_STATUS = 'UI_ROD25_POPUP_STATUS';

export const UI_SPORT_LIVE_ACTIVE_SPORT = 'UI_SPORT_LIVE_ACTIVE_SPORT';
export const UI_SPORT_LIVE_EVENTS_BY_SPORT = 'UI_SPORT_LIVE_EVENTS_BY_SPORT';
export const UI_SPORT_LIVE_SEARCH_TEXT = 'UI_SPORT_LIVE_SEARCH_TEXT';

export const UI_SPORT_PRE_ACTIVE_SPORT = 'UI_SPORT_PRE_ACTIVE_SPORT';
export const UI_SPORT_PRE_EVENTS_BY_SPORT_AND_TIME =
    'UI_SPORT_PRE_EVENTS_BY_SPORT_AND_TIME';
export const UI_SPORT_PRE_SEARCH_TEXT = 'UI_SPORT_PRE_SEARCH_TEXT';

export const UI_SET_NOTIFICATIONS = 'UI_SET_NOTIFICATIONS';
export const UI_ACTIVE_BETS = 'UI_ACTIVE_BETS';
export const UI_SET_LIVESCORE_STATUS = 'UI_SET_LIVESCORE_STATUS';
export const UI_SET_LEFT_SIDEBAR_STATUS = 'UI_SET_LEFT_SIDEBAR_STATUS';
export const UI_SET_USER_EXCLUSION_POPUP_STATUS =
    'UI_SET_USER_EXCLUSION_POPUP_STATUS';
export const UI_AFTER_LOGIN_SET = 'UI_AFTER_LOGIN_SET';
export const UI_AFTER_LOGIN_RESET = 'UI_AFTER_LOGIN_RESET';
export const UI_SPIL_ID_MODAL_STATUS = 'UI_SPIL_ID_MODAL_STATUS';

export const UI_SHOW_MODAL = 'SHOW_MODAL';
export const UI_HIDE_MODAL = 'HIDE_MODAL';
export const UI_PIN_LOGIN_STATUS = 'UI_PIN_LOGIN_STATUS';

export const DATEPICKER_THEME_CONFIG = {
    BselectionColor: '#ebcd32',
    textColor: {
        default: '#333',
        active: '#333',
    },
    weekdayColor: '#dddddd',
    headerColor: '#1c3e60',
    floatingNav: {
        background: '#1c3e60',
        color: '#FFF',
        chevron: '#FFA726',
        opacity: '0.8',
    },
};
