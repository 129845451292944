import styled from 'styled-components';

const Divider = styled.hr`
    margin: ${(props) => (props.margin ? props.margin : '0px')};
    padding: 0;
    height: 1px;
    background: ${(props) => (props.color ? props.color : '#8A8C8E')};
    border: 0;
`;

export default Divider;
