import {
    EVENT_OBJECT_CHECK_MARKETS,
    EVENT_OBJECT_FETCH_ERROR,
    EVENT_OBJECT_FETCH_PENDING,
    EVENT_OBJECT_FETCH_SUCCESS,
    EVENT_OBJECT_PATCH_DATA,
    EVENT_OBJECT_REMOVE_MARKET,
    EVENT_OBJECT_UPDATE_MARKET,
    EVENT_OBJECT_IS_UPDATED,
    EVENT_IS_FINISHED,
    EVENT_OBJECT_UPDATE_CUSTOMBET_MARKETS,
    EVENT_OBJECT_PENDING_CUSTOMBET_MARKETS,
    EVENT_OBJECT_UPDATE_STATUS,
    EVENT_OBJECT_RESET_MARKETS,
} from 'constants/eventObject';
import { collectMarkets } from 'utils/Helper';

const initialState = {
    pending: false,
    isPendingCustomBetUpdate: false,
    isFirstRendering: true,
    updatedMarkets: false,
    fetched: false,
    data: null,
    eventIsFinished: false,
    groupedMarkets: null,
    previousGroupedMarkets: null,
    error: false,
    marketsShouldBeUpdated: false,
    changedMarkets: {},
};

export default function eventObject(state = initialState, action) {
    let changedMarkets = {};

    switch (action.type) {
        case EVENT_OBJECT_FETCH_PENDING:
            return {
                ...state,
                pending: true,
                fetched: false,
                data: null,
                error: false,
            };

        case EVENT_OBJECT_PENDING_CUSTOMBET_MARKETS:
            return {
                ...state,
                isPendingCustomBetUpdate: action.payload,
            };

        case EVENT_OBJECT_FETCH_SUCCESS:
            changedMarkets = {};

            let t = Date.now();

            action.payload.marketList.map((market) => {
                market.itemList.map((item) => {
                    changedMarkets[market.marketId + '-' + item.betNr] = {
                        time: t,
                        type: '',
                        odds: item.odds,
                    };

                    return item;
                });

                return market;
            });

            return {
                ...state,
                pending: false,
                fetched: true,
                isFirstRendering: false,
                data: action.payload,
                groupedMarkets: collectMarkets(action.payload),
                error: false,
                changedMarkets,
            };

        case EVENT_OBJECT_FETCH_ERROR:
            return {
                ...state,
                pending: false,
                fetched: false,
                data: null,
                error: action.payload,
            };

        case EVENT_OBJECT_UPDATE_STATUS:
            return {
                ...state,
                data: {
                    ...state.data,
                    status: action.payload.status,
                },
            };

        case EVENT_OBJECT_IS_UPDATED:
            return {
                ...state,
                updatedMarkets: action.payload,
            };

        case EVENT_IS_FINISHED:
            return {
                ...state,
                eventIsFinished: action.payload,
            };

        case EVENT_OBJECT_PATCH_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                    marketList: state.data.marketList,
                },
            };

        case EVENT_OBJECT_REMOVE_MARKET:
            const tmp2 = { ...state.data };
            if (state?.data?.marketList && state?.data?.marketList.length > 0) {
                state.data?.marketList.forEach((market, index) => {
                    if (action.payload === market.marketId) {
                        delete tmp2.marketList[index];
                        tmp2.marketList = tmp2.marketList.filter(
                            (item) => item
                        );
                    }
                });
            }

            if (state?.data?.customBetMarkets?.length > 0) {
                state.data.customBetMarkets.map((item, index) => {
                    if (action.payload === item.marketId) {
                        tmp2.customBetMarkets.splice(index, 1);
                    }
                });
            }

            return {
                ...state,
                pending: false,
                fetched: true,
                data: tmp2,
                groupedMarkets: collectMarkets(tmp2),
                error: false,
            };

        case EVENT_OBJECT_UPDATE_CUSTOMBET_MARKETS:
            let tmpData = {};
            tmpData = { ...state.data };

            return {
                ...state,
                fetched: true,
                data: {
                    ...tmpData,
                    customBetMarkets: [...action.payload],
                },
                error: false,
            };

        case EVENT_OBJECT_UPDATE_MARKET:
            const tmp = { ...state.data };
            let updated = false;
            const actionPayload = { ...action.payload };
            let shouldUpdateMarkets = false;

            actionPayload.part = actionPayload.part || null;
            let indexOfVinder = -1;

            if (tmp.marketList && tmp?.marketList.length > 0) {
                tmp.marketList.findIndex((market) => {
                    if (
                        actionPayload.marketTypeGroup === 1 &&
                        market.marketTypeGroup === 1 &&
                        actionPayload.marketId !== market.marketId &&
                        tmp.live === false
                    ) {
                        shouldUpdateMarkets = true;
                        indexOfVinder = market.marketTypeGroup === 1;
                    }
                });

                if (indexOfVinder !== -1) {
                    tmp.marketList.splice(indexOfVinder, 1);
                }
            }

            if (state.data && state.data.marketList) {
                if (state.data?.customBetMarkets) {
                    state.data.customBetMarkets.map((item, index) => {
                        if (actionPayload.marketId === item.marketId) {
                            let itemList = [];
                            actionPayload.itemList.map((item, n) => {
                                itemList.push({
                                    ...item,
                                    ...tmp.customBetMarkets[index].itemList[n]
                                        .conflict,
                                    ...tmp.customBetMarkets[index].itemList[n],
                                });
                            });
                            tmp.customBetMarkets[index] = {
                                ...tmp.customBetMarkets[index],
                                status: actionPayload.status,
                                itemList,
                            };
                        }
                    });
                }
                state.data.marketList.forEach((market, index) => {
                    if (actionPayload.marketId === market.marketId) {
                        tmp.marketList[index] = {
                            ...tmp.marketList[index],
                            ...actionPayload,
                            itemList: actionPayload.itemList.map((item, n) => ({
                                ...tmp.marketList[index].itemList[n],
                                ...item,
                            })),
                        };

                        updated = true;
                    }
                });
            }

            if (
                !updated &&
                tmp &&
                tmp.marketList &&
                actionPayload.marketTypeGroup
            ) {
                tmp.marketList.push(actionPayload);
                //tmp.live = true;
            }

            const groupedMarkets = collectMarkets(tmp);
            const now = Date.now();
            changedMarkets = {};
            Object.keys(state.changedMarkets).forEach((key) => {
                if (now - state.changedMarkets[key].time <= 6000) {
                    changedMarkets[key] = state.changedMarkets[key];
                } else {
                    changedMarkets[key] = {
                        time: now,
                        type: '',
                        odds: state.changedMarkets[key].odds,
                    };
                }
            });

            actionPayload.itemList.forEach((item) => {
                if (
                    state.changedMarkets[
                        actionPayload.marketId + '-' + item.betNr
                    ]
                ) {
                    changedMarkets[
                        actionPayload.marketId + '-' + item.betNr
                    ] = {
                        time: now,
                        type:
                            state.changedMarkets[
                                actionPayload.marketId + '-' + item.betNr
                            ].odds < item.odds
                                ? 'plus'
                                : state.changedMarkets[
                                      actionPayload.marketId + '-' + item.betNr
                                  ].odds > item.odds
                                    ? 'minus'
                                    : '',
                        odds: item.odds,
                    };
                }
            });

            return {
                ...state,
                pending: false,
                fetched: true,
                data: tmp,
                groupedMarkets,
                error: false,
                marketsShouldBeUpdated: shouldUpdateMarkets,
                changedMarkets,
            };

        case EVENT_OBJECT_CHECK_MARKETS:
            const time = Date.now();

            changedMarkets = {};

            Object.keys(state.changedMarkets).forEach((key) => {
                if (time - state.changedMarkets[key].time <= 6000) {
                    changedMarkets[key] = state.changedMarkets[key];
                } else {
                    changedMarkets[key] = {
                        time: time,
                        type: '',
                        odds: state.changedMarkets[key].odds,
                    };
                }
            });

            return {
                ...state,
                changedMarkets,
            };
        case EVENT_OBJECT_RESET_MARKETS:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}
