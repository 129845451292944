import {
    SERVER_CONFIG_FETCH_ERROR,
    SERVER_CONFIG_FETCH_PENDING,
    SERVER_CONFIG_FETCH_SUCCESS,
    SERVER_GEO_FETCH_ERROR,
    SERVER_GEO_FETCH_PENDING,
    SERVER_GEO_FETCH_SUCCESS,
    SERVER_SEO_FETCH_ERROR,
    SERVER_SEO_FETCH_PENDING,
    SERVER_SEO_FETCH_SUCCESS,
} from '../constants/serverConfig';

const initialState = {
    pending: false,
    fetched: false,
    data: [],
    error: false,
    seoPending: false,
    seoFetched: false,
    seoData: [],
    seoError: false,
    geoPending: false,
    geoFetched: false,
    geoData: [],
    geoError: false,
};

export default function getServerConfig(state = initialState, action) {
    switch (action.type) {
        case SERVER_CONFIG_FETCH_PENDING:
            return {
                ...state,
                pending: true,
                fetched: false,
                data: [],
                error: false,
            };

        case SERVER_CONFIG_FETCH_SUCCESS:
            return {
                ...state,
                pending: false,
                fetched: true,
                data: action.payload,
                error: false,
            };

        case SERVER_CONFIG_FETCH_ERROR:
            return {
                ...state,
                pending: false,
                fetched: false,
                data: [],
                error: action.payload,
            };

        case SERVER_SEO_FETCH_PENDING:
            return {
                ...state,
                seoPending: true,
                seoFetched: false,
                seoData: [],
                seoError: false,
            };

        case SERVER_SEO_FETCH_SUCCESS:
            return {
                ...state,
                seoPending: false,
                seoFetched: true,
                seoData: action.payload,
                seoError: false,
            };

        case SERVER_SEO_FETCH_ERROR:
            return {
                ...state,
                seoPending: false,
                seoFetched: false,
                seoData: [],
                seoError: action.payload,
            };

        case SERVER_GEO_FETCH_PENDING:
            return {
                ...state,
                geoPending: true,
                geoFetched: false,
                geoData: [],
                geoError: false,
            };

        case SERVER_GEO_FETCH_SUCCESS:
            return {
                ...state,
                geoPending: false,
                geoFetched: true,
                geoData: action.payload,
                geoError: false,
            };

        case SERVER_GEO_FETCH_ERROR:
            return {
                ...state,
                geoPending: false,
                geoFetched: false,
                geoData: [],
                geoError: action.payload,
            };

        default:
            return state;
    }
}
