import sportTree from './sportTree';
import liveEvents from './liveEvents';
import preEvents from './preEvents';
import popularEvents from './popularEvents';
import overviewEvents from './overviewEvents';
import overviewSearchEvents from './overviewSearchEvents';
import eventObject from './eventObject';
import ui from './ui';
import uiSportOverview from './uiSportOverview';
import uiSportLive from './uiSportLive';
import uiSportPre from './uiSportPre';
import auth from './auth';
import betslip from './betslip';
import campaigns from './campaigns';
import serverConfig from './serverConfig';
import terminalSession from './terminalSession';
import virtualEvents from './virtualEvents';
import terminalBetslip from './terminalBetslip';
import terminalVirtualBetslip from './terminalVirtualBetslip';
import shopConfig from './shopConfig';
import spilId from './spilId';
import DatePicker from '../features/DatePicker/reducer';
import big9 from '../components/big9/reducer';
import bonus from './bonus';
import vaixEvents from './vaixEvents';
import trackingEvents from './trackingEvents';
import { isTerminal } from 'utils/UiHelper';
import retailSalesHistory from './retailSalesHistory';
import transactionHistory from './transactionHistory';

let tree = {};

if (!isTerminal()) {
    tree = {
        DatePicker,
        sportTree,
        liveEvents,
        preEvents,
        overviewEvents,
        overviewSearchEvents,
        popularEvents,
        eventObject,
        ui,
        uiSportOverview,
        uiSportLive,
        uiSportPre,
        auth,
        betslip,
        campaigns,
        serverConfig,
        big9,
        bonus,
        vaixEvents,
        trackingEvents,
        retailSalesHistory,
        transactionHistory,
    };
} else {
    tree = {
        terminalSession,
        sportTree,
        liveEvents,
        preEvents,
        virtualEvents,
        overviewEvents,
        overviewSearchEvents,
        popularEvents,
        eventObject,
        ui,
        uiSportOverview,
        uiSportLive,
        uiSportPre,
        betslip: terminalBetslip,
        terminalVirtualBetslip,
        serverConfig,
        shopConfig,
        spilId,
        big9,
        bonus,
        trackingEvents,
        //vaixEvents, when vaix will be implemented in terminal you should uncomment it
        transactionHistory,
    };
}

export default tree;
