import styled from 'styled-components';

const ModalWrapper = styled.div`
    position: fixed;
    top: ${(props) => {
        if (!props.topBarHidden) return 0;
        return props.popupMode ? `90px` : 0;
    }};
    bottom: ${(props) => (props.popupMode ? `50px` : 0)};
    left: 0;
    right: 0;
    width: 100%;
    height: ${(props) => (props.popupMode ? 'auto' : '100%')};
    overflow: hidden;
    z-index: 110;
    background: ${(props) =>
        props.color
            ? props.color
            : !props.popupMode
                ? props.background
                : 'none'};
`;

export default ModalWrapper;
