import React from 'react';
import ModalsHandler from 'modals/MobileModals/ModalsHandler';
import { MapConfig } from 'constants/desktopModals';
import ModalContainer from './ModalContainer';

class DesktopModals extends React.Component {
    render() {
        return (
            <ModalsHandler modalsMap={MapConfig} parent={this.props.parent}>
                {(modalProps) => <ModalContainer {...modalProps} />}
            </ModalsHandler>
        );
    }
}

export default DesktopModals;
