import { makeRequest } from 'utils/REST';
import { REST_BETSLIP } from 'constants/appConfig';

class BetslipService {
    request(requestType, url, data) {
        return makeRequest(requestType, url, data)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
    }
    post(method, data) {
        return this.request('post', REST_BETSLIP + method, data);
    }

    get(method, data) {
        return this.request('get', REST_BETSLIP + method, data);
    }

    getSystemNames() {
        return [
            'Singler',
            'Doubler',
            'Tripler',
            'Firlinger',
            'Femlinger',
            'Sekslinger',
            'Syvlinger',
            'Ottelinger',
            'Nilinger',
            'Tilinger',
            'Ellevelinger',
            'Tolvlinger',
            'Trettelinger',
            'Fjortenlinger',
            'Femtenlinger',
        ];
    }

    getSystemNamesOdds() {
        return [
            'Single',
            'Double',
            'Tripple',
            'Firlinger',
            'Femlinger',
            'Sekslinger',
            'Syvlinger',
            'Ottelinger',
            'Nilinger',
            'Tilinger',
            'Ellevelinger',
            'Tolvlinger',
            'Trettelinger',
            'Fjortenlinger',
            'Femtenlinger',
        ];
    }

    addMarket(marketId, betNr, odds, isLive) {
        return this.post('add', {
            gameId: marketId,
            betId: betNr,
            odds: odds,
            live: isLive,
        });
    }

    removeMarket(marketId) {
        return this.post(`del/${marketId}`).then((res) => res.data);
    }

    proceedBet(
        betType,
        amountList,
        cb,
        errorHandler,
        isAcceptedBetslipChanges,
        isFreeBet
    ) {
        this.post('prepare', { betType, amountList }).then((res) => {
            const { data } = res;
            if (!res.success) {
                errorHandler(data.errorMessage);
            } else {
                const betList = data.betList.map((bet) => ({
                    gameId: bet.gameId,
                    betNr: bet.betNr,
                    odds: bet.odds,
                    live: bet.live ? 1 : 0,
                }));

                this._confirmBet(
                    betList,
                    cb,
                    errorHandler,
                    isAcceptedBetslipChanges,
                    isFreeBet
                );
            }
        });
    }

    clearBetslip(cb, errorHandler) {
        this.post('clear').then((res) => {
            const { data } = res;
            if (data && data.betCount > 0) {
                this.clearBetslip(cb, errorHandler);
            } else {
                cb(data);
            }
        });
    }

    checkReceipt(cb, errorHandler) {
        let check = 0;
        this.get('slip').then((res) => {
            if (!res.data) {
                if (res.errorMessage === 'NO_RECEIPT_FOUND' && check++ < 3) {
                    setTimeout(() => {
                        this.checkReceipt(cb, errorHandler);
                    }, 2000);
                } else {
                    errorHandler(res.errorMessage);
                }
            } else {
                cb(res?.data?.slip?.betList);
            }
        });
    }

    updateOdds(cb) {
        this.post('update').then((res) => {
            cb(res.data);
        });
    }

    _confirmBet(
        betList,
        cb,
        errorHandler,
        isAcceptedBetslipChanges,
        isFreeBet = 0
    ) {
        this.post('confirm', {
            freeBet: isFreeBet,
            betList,
        })
            .then((res) => {
                if (!res.success) {
                    errorHandler(res.errorMessage);
                } else {
                    cb(res.data);
                }
            })
            .catch((err) => {
                errorHandler(err);
            });
    }
}

export default new BetslipService();
