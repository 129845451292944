import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { detectMobile } from 'utils/UiHelper';
import { breakpoint } from '../constants/breakpoints';

const getButtonBackground = (props) => {
    let background = '#1C3E60';
    if (props.active) {
        background = '#FED300';
    } else if (props.transparent) {
        background = 'none';
    } else if (props.disabled && props.yellow) {
        background = '#FFF7CD';
    } else if (props.green) {
        background = '#18AA1B';
    } else if (props.disabled || props.dis) {
        background = '#EDF1F8';
    } else if (props.white) {
        background = 'white';
    } else if (props.grey) {
        background = '#ECECF4';
    } else if (props.lightBlue) {
        background = '#EDF1F8';
    } else if (props.lightShadeBlue) {
        background = '#ECEFF6';
    } else if (props.lavended) {
        background = '#C6CCDB';
    } else if (props.neutral) {
        background = '#EDF1F8';
    } else if (props.lynch) {
        background = '#617890';
    } else if (props.red) {
        background = '#F60E0F';
    }
    return background;
};
const getHoverBackground = (props) => {
    let background = '#305274';
    if (props.active) {
        background = detectMobile() ? '#FED300' : '#ffe714';
    } else if (props.transparent) {
        background = 'none';
    } else if (props.disabled || props.dis) {
        background = props.yellow ? '#FFF7CD' : '#EDF1F8';
    } else if (props.green) {
        background = '#18AA1B';
    } else if (props.white) {
        background = 'white';
    } else if (props.grey) {
        background = '#f6f6fe';
    } else if (props.lightBlue) {
        background = '#ECECF4';
    } else if (props.lavended) {
        background = '#C6CCDB';
    } else if (props.neutral) {
        background = '#EDF1F8';
    } else if (props.red) {
        background = '#F60E0F';
    }
    return background;
};
const getButtonColor = (props) => {
    let color = '#FFFFFF';
    if (props.active) {
        color = '#333333';
    } else if (props.transparent) {
        color = '#FFFFFF';
    } else if (props.disabled || props.dis) {
        color = '#D6D6D6';
    } else if (props.white) {
        color = '#333333';
    } else if (props.oddsYellow) {
        color = '#ebcd32';
    } else if (props.grey) {
        color = '#333333';
    } else if (props.lightBlue || props.lightShadeBlue) {
        color = '#333333';
    } else if (props.lavended) {
        color = '#333333';
    } else if (props.neutral) {
        color = '#333333';
    } else if (props.red) {
        color = '#FFFFFF';
    }
    return color;
};
const getMobileProps = (props) => {
    if (detectMobile() && props && Array.isArray(props)) {
        return [...props];
    }
};

const OddsButton = styled.button`
    display: ${({ display }) => (display ? display : 'flex')};
    align-items: ${({ align }) => (align ? align : 'center')};
    ${({ width }) => (width ? 'width: ' + width : null)};
    ${({ height }) => (height ? 'width: ' + height : null)};
    ${({ maxHeight }) => (maxHeight ? 'max-height: ' + maxHeight : null)};
    ${({ textAlign }) => (textAlign ? 'text-align: ' + textAlign : null)};
    ${({ flex }) => (flex ? 'flex: ' + flex : null)};
    ${({ flexDirection }) =>
        flexDirection ? 'flex-direction: ' + flexDirection : null};
    justify-content: ${({ justify }) => (justify ? justify : 'center')};
    background: ${(props) =>
        props.background ? props.background : getButtonBackground(props)};
    padding: ${({ padding }) => (padding ? padding : '15px 30px')};
    margin: ${({ margin }) => (margin !== undefined ? margin : '10px')};
    border-radius: ${({ radius }) => (radius ? radius : '5px')};
    color: ${(props) => (props.color ? props.color : getButtonColor(props))};
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'bold')};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
    border: ${({ border }) => (border ? border : 'none')};
    box-sizing: ${({ boxSizing }) => (boxSizing ? boxSizing : 'border-box')};
    text-transform: ${({ textTransform }) =>
        textTransform ? textTransform : 'none'};
    cursor: ${({ disabled }) => (!disabled ? 'pointer' : null)};
    opacity: ${({ opacity, disabled }) =>
        opacity ? opacity : disabled ? '0.5' : '1'};
    transition: all 0.2s ease;
    ${({ position }) => (position ? 'position: ' + position : null)};
    overflow: ${({ overflow }) => (overflow ? overflow : 'hidden')};
    ${(props) => getMobileProps(props.mobile)};
    &:hover {
        background: ${(props) => getHoverBackground(props)};
    }
    .bet-label {
        color: ${({ active }) => (active ? '#333' : '#fff')};
    }
    &.wildcard {
        transition: all 0.1s ease-in-out;
    }
    &.wildcardActive {
        height: ${({ height }) => (height ? height : '42px')};
        position: absolute;
        right: 0;
        top: -1px;
        z-index: 1;
        transition: all 0.1s ease;
        width: calc(100% + 1px);
        @media screen and ${breakpoint.L} {
            height: 42px;
            top: -1px;
        }
    }
    &.resultButton {
        height: 44px;
        padding: 0 5px;
        text-transform: capitalize;
    }
    &.webSwitcher {
        height: 44px;
    }
    &.terminalSwitcher {
        padding: 20px 40px;
        .disc {
            height: 20px;
            width: 20px;
            display: inline-block;
            margin-right: 10px;
            box-sizing: border-box;
            border: 1px solid #c6ccda;
            background: #fff;
            border-radius: 100%;
            position: relative;
            &:before {
                content: '';
                height: 6px;
                width: 6px;
                display: block;
                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 50%;
                background: #fff;
                border-radius: 100%;
                transform: translate(-50%, -50%);
                z-index: 1;
            }
        }

        ${({ active }) =>
            active &&
            css`
                background: #edf1f8;
                .disc {
                    background: #18aa1b;
                    &:before {
                        display: block;
                    }
                }
            `};
    }
`;

OddsButton.propTypes = {
    display: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    align: PropTypes.string,
    position: PropTypes.string,
    textAlign: PropTypes.string,
    overflow: PropTypes.string,
    justify: PropTypes.string,
    background: PropTypes.string,
    padding: PropTypes.string,
    margin: PropTypes.string,
    radius: PropTypes.string,
    color: PropTypes.string,
    flex: PropTypes.string,
    fontWeight: PropTypes.string,
    flexDirection: PropTypes.string,
    maxHeight: PropTypes.string,
    fontSize: PropTypes.string,
    border: PropTypes.string,
    boxSizing: PropTypes.string,
    opacity: PropTypes.string,
    neutral: PropTypes.bool,
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    dis: PropTypes.bool,
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    white: PropTypes.bool,
    transparent: PropTypes.bool,
    oddsYellow: PropTypes.bool,
};

export default OddsButton;
