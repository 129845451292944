import React from 'react';
import ModalView from '../components/ViewModal';
import '../css/GeoBlockModal.css';
import UIModal from 'components/UIModal';
import { detectMobile } from 'utils/UiHelper';

const GeoBlockModal = (props) =>
    detectMobile() ? (
        <UIModal
            onCloseModal={props.modalData?.onClose || props.hideModal}
            title="Du befinder dig udenfor Danmark"
        >
            <div className="geo-info text-center grey p10">
                Du befinder dig udenfor Danmark, og kan derfor ikke spille.
                <br /> Kontakt os via chatten eller på;{' '}
                <a className="lnk" href="mailto:support@bet25.dk">
                    support@bet25.dk
                </a>
                , hvis du har spørgsmål.
            </div>
        </UIModal>
    ) : (
        <div className="geoblock-container">
            <ModalView
                title="Du befinder dig udenfor Danmark"
                onCloseModal={props.modalData?.onClose || props.hideModal}
            >
                <br />
                <br />
                <h4 className="text-center m40 grey">
                    Du befinder dig udenfor Danmark, og kan derfor ikke spille.
                    <br /> Kontakt os via chatten eller på;{' '}
                    <a className="lnk" href="mailto:support@bet25.dk">
                        support@bet25.dk
                    </a>
                    , hvis du har spørgsmål.
                </h4>
            </ModalView>
        </div>
    );

GeoBlockModal.defaultProps = {
    onClose: () => {},
};

export default GeoBlockModal;
