import initConfig from './config';
import { detectMobile } from 'utils/UiHelper';
import seoSportsList from 'constants/seoSportsList';
import { getEnvForLocalhost } from '../utils/UiHelper';

initConfig();
const BASE_API_PATH = window.config.baseAPIPath;
const BASE_CUSTOM_BET_RECOMMENDATION_PATH =
    window.config.baseCustomBetRecommendationPath;

export const DESKTOP_TYPE = 'DESKTOP';
export const TERMINAL_TYPE = 'TERMINAL';

export const APP_TYPE = process.env.MODE ? process.env.MODE : DESKTOP_TYPE;

export const MOBILE_APPS_URL_SCHEMAS = {
    DERBY_25: 'iOSDerby25://',
    ROD_25: 'iOSRod25://',
    BET_25: 'iOSBet25App://',
};

export const goToWithBasePath = (path) => (path ? `/sport${path}` : '/sport/');

export const FETCH_DATA_INTERVAL_MS =
    Number(process.env.FETCH_DATA_INTERVAL_MS) || 60 * 1000;
export const SEARCH_LETTER_COUNT = 3;

export const APP_PATH =
    window.location.hostname !== 'localhost' ||
    window.location.hostname !== 'bs-local.com'
        ? `${window.location.protocol}//${
              window.location.host
          }${getEnvForLocalhost()}`
        : '';

export const CAMPAIGN_IMAGE_PATH = window.config.baseCmsPath;

export const PUNTER_PATH = `${BASE_API_PATH}/punter/`;
export const REST_SPORTS = `${BASE_API_PATH}/sport/content/`;
export const REST_BETSLIP = `${BASE_API_PATH}/sport/slip/`;
export const REST_CASHOUT = `${BASE_API_PATH}/sport/cashout/`;
export const REST_BIG9 = `${BASE_API_PATH}/sport/big9/`;
export const REST_TERMINAL_BIG9 =
    window.location.hostname === 'localhost'
        ? '/big9/'
        : window.PP_REST_URL + `/big9/`;

export const CUSTOM_BET_PATH = `${BASE_API_PATH}/sport/customBet/`;
export const OUR_BE_REST_VAIX_PATH = `${BASE_API_PATH}/sport/vaix/`;

export const LOCAL_STORAGE_KEYS = {
    PIN_TOKEN: 'pin-token',
    PIN_USERNAME: 'pin-username',
    LAST_LOGGED_IN_USER_NAME: 'LAST_LOGGED_IN_USER_NAME',
    FIRST_USER_LOGGED_IN: 'FIRST_USER_LOGGED_IN/{USER_ID}',
};

export const REST_EUROPE_ID = 'resten.af.europa';
export const REST_WORLD_ID = 'resten.af.verden';

export const ANOTHER_SPORTS_ID = 'andet.sport';
export const ANOTHER_GROUPS_ID = 'andet.groups';

export const STREAM_MODE_ATG = 'STREAM_MODE_ATG';
export const STREAM_MODE_SA = 'STREAM_MODE_SA';
export const STREAM_MODE_EN = 'STREAM_MODE_EN';
export const STREAM_MODE_FT = 'STREAM_MODE_FT';
export const STREAM_MODE_AU = 'STREAM_MODE_AU';

export const HORSE_RACING_STREAMS = {
    [STREAM_MODE_AU]:
        'https://httpcache0-00688-cacheliveedge0.dna.ip-only.net/00688-cacheliveedge0/out/u/atg_sdi_3_premium.m3u8',
    [STREAM_MODE_EN]:
        'https://httpcache0-00688-cacheliveedge0.dna.ip-only.net/00688-cacheliveedge3/out/u/atg_sdi_12_derby25_gb.m3u8',
    [STREAM_MODE_SA]:
        'https://httpcache0-00688-cacheliveedge0.dna.ip-only.net/00688-cacheliveedge3/out/u/atg_sdi_11_derby25_za.m3u8',
};

export const DANMARK_ID = 'DANMARK_ID';
export const LIVE_ID = 'live';
export const ANBEFALET = 'Anbefalet';
export const ANBEFALET_SPORT_ID = 197893854755;
export const SOON_ID = 'soon';
export const LIVE_CASINO = 'live_casino';
export const BIG_NINE = 'big9';
export const SPORTS = {
    FOOTBALL: 1,
    BASKETBALL: 2,
    BASEBALL: 3,
    ICE_HOCKEY: 4,
    TENNIS: 5,
    HANDBALL: 6,
    FLOORBALL: 7,
    GOLF: 9,
    BOXING: 10,
    MOTOR_SPORT: 11,
    RUGBY: 12,
    AUSTRALSK_FODBOLD: 13,
    VINTERSPORT: 14,
    AMERIKANSK_FODBOLD: 16,
    CYKLING: 17,
    SPECIALS_ALT: 18,
    SNOOKER: 19,
    BORDTENNIS: 20,
    CRICKET: 21,
    DART: 22,
    VOLLEYBALL: 23,
    POOL: 25,
    TABLE_TENNIS: 20,
    FUTSAL: 29,
    BADMINTON: 31,
    BEACH_VOLLEY: 34,
    SQUASH: 37,
    SEJLADS: 81,
    ESPORT_COUNTERSTRIKE: 109,
    LEAGUE_OF_LEGENDS: 110,
    ESPORT_DOTA: 111,
    ESPORT_STARCRAFT: 112,
    MMA: 117,
    ATLETIK: 1698,
    SPECIALS: 1696,
    HESTESPORT: 2231,
    SWIMMING: 52,
    SOFTBALL: 54,
    RONING: 64,
    TRIATLON: 84,
    ARCHERY: 75,
    RIDING: 76,
    FENCING: 77,
    WEIGHTLIFTING: 85,
    SPEEDWAY: 131,
    BASKETBALL3x3: 155,
    SKATEBOARDING: 183,
    MOTORCYCLE_RACING: 190,
    PADEL: 3119,
};

export const EVENT_STATUS = {
    FINISHED_ALT: 0,
    IN_PROGRESS: 1,
    PAUSE: 2,
    FINISHED: 3,
};

export const MARKET_STATUS = {
    ACTIVE: 11,
    ACTIVE_ALT: 12,
};

export const MARKET_SUB_TYPE = {
    MAIN_1X2: 1,
};

export const GAMES_WITH_1X2 = [
    SPORTS.FOOTBALL,
    SPORTS.HANDBALL,
    SPORTS.ICE_HOCKEY,
    SPORTS.GOLF,
    SPORTS.RUGBY,
];

export const SPORT_COMPETITIONS_ID = {
    EURO: 20381,
    SUPERLIGAEN: 1394,
    DBU_POKALEN: 1810,
    CHAMPION_LEAGUE: 2572,
    PREMIER_LEAGUE: 1400,
    EUROPA_LEAGUE: 2573,
    LA_LIGA: 2010,
    A_LIGA: 1596,
    RESERVELIGAEN: 32673,
    BUNDESLIGA_1: 1727,
    SERIA_A: 1438,
    COPA_DEL_REY: 1856,
    DIVISION_1: 2388,
    DIVISION_2: 21038,
    FA_CUP: 1557,
    LEAGUE_1: 1398,
    CHAMPIOSHIP: 1601,
    CARABAO_CUP: 2090,
};

export const LIVE_COMPETITORS_ORDER = [
    SPORT_COMPETITIONS_ID.SUPERLIGAEN,
    SPORT_COMPETITIONS_ID.DBU_POKALEN,
    SPORT_COMPETITIONS_ID.CHAMPION_LEAGUE,
    SPORT_COMPETITIONS_ID.PREMIER_LEAGUE,
    SPORT_COMPETITIONS_ID.LA_LIGA,
    SPORT_COMPETITIONS_ID.DIVISION_1,
    SPORT_COMPETITIONS_ID.DIVISION_2,
    SPORT_COMPETITIONS_ID.RESERVELIGAEN,
    SPORT_COMPETITIONS_ID.A_LIGA,
    SPORT_COMPETITIONS_ID.EUROPA_LEAGUE,
    SPORT_COMPETITIONS_ID.COPA_DEL_REY,
    SPORT_COMPETITIONS_ID.FA_CUP,
    SPORT_COMPETITIONS_ID.SERIA_A,
    SPORT_COMPETITIONS_ID.CARABAO_CUP,
    SPORT_COMPETITIONS_ID.BUNDESLIGA_1,
    SPORT_COMPETITIONS_ID.LEAGUE_1,
];

export const SPORT_GROUPS_ID = {
    INTERNATIONAL: 1590,
    INTERNATIONAL_CLUB: 1469,
};

export const CUSTOM_BET_ALLOWED_LIVE_SPORT_ID = [
    SPORTS.FOOTBALL,
    SPORTS.TENNIS,
];

export const CUSTOM_BET_ALLOWED_PRE_SPORT_ID = [
    SPORTS.FOOTBALL,
    SPORTS.TENNIS,
    SPORTS.BASKETBALL,
];

export const MARKET_TYPE_GROUPS_ID = {
    _1X2: 1,
    HANDICAP: 4,
    CUSTOM_BET: 1234111, //TODO this group type was added manualy because smbdy doesn't want to add
    OVER_UNDER: 5,
    ASIAN_HANDICAP: 18,
    ASIAN_OVER_UNDER: 21,
    HALVLEG: 2,
    HJORNESPARK: 6,
    HOLD: 9,
    KOMBINATIONER: 49,
    BET_REQUEST: 9999999,
    RESULTAT: 3,
    SPECIALS: 10,
    STATISTIK: 47,
    SPILLERE: 8,
    SPILLER_SPECIALS: {
        ASSIST: 26,
        KORT: 48,
        MAL: 25,
        SKUD: 27,
        SKUD_PA_MAL: 28,
    },
};

export const CAMPAIGNS_COMPARE_WITH = !detectMobile()
    ? ['all', 'web', 'webmob']
    : ['mobile', 'all', 'appmob', 'webmob'];

export const SEO_SPORTS = seoSportsList;

export const COUNTRIES_IMG = [
    'afghanistan',
    'albanien',
    'algeriet',
    'andet',
    'andet.sport',
    'spilklub',
    'big9',
    'andorra',
    'angola',
    'argentina',
    'armenien',
    'aserbajdsjan',
    'australien',
    'bahamas',
    'bahrain',
    'bangladesh',
    'barbados',
    'baskerlandet',
    'belgien',
    'belize',
    'benin',
    'bermuda',
    'bolivia',
    'brunei',
    'bulgarien',
    'burkina.faso',
    'burundi',
    'cambodja',
    'cameroon',
    'canada',
    'caymanøerne',
    'centralafrikanske.republik',
    'challenger',
    'chile',
    'colombia',
    'costa.rica',
    'cuba',
    'cypern',
    'danmark',
    'demokratiske.republik.congo',
    'dominikanske.republik',
    'ecuador',
    'egypten',
    'elfenbenskysten',
    'england',
    'eritrea',
    'estland',
    'em.2020',
    'etiopien',
    'farvorit',
    'fiji',
    'filippinerne',
    'finland',
    'forenede.arabiske.emirater',
    'frankrig',
    'færøerne',
    'gabon',
    'gambia',
    'georgien',
    'ghana',
    'gibraltar',
    'grækenland',
    'grønland',
    'guatemala',
    'guinea',
    'haiti',
    'hawaii',
    'holland',
    'honduras',
    'hong.kong',
    'hviderusland',
    'indien',
    'indonesien',
    'international.klubber',
    'international.lande',
    'international',
    'international.ungdom',
    'irak',
    'iran',
    'irland',
    'island',
    'israel',
    'italien',
    'jamaica',
    'japan',
    'jordan',
    'kap.verde',
    'kasakhstan',
    'kenya',
    'kina',
    'kosovo',
    'kroatien',
    'kuwait',
    'kyrgyzstan',
    'laos',
    'lesotho',
    'letland',
    'libanon',
    'liberia',
    'libyen',
    'liechtenstein',
    'litauen',
    'luxembourg',
    'makedonien',
    'malawi',
    'malaysia',
    'maldiverne',
    'mali',
    'malta',
    'marokko',
    'mauritius',
    'mexico',
    'moldova',
    'monaco',
    'mongoliet',
    'montenegro',
    'mozambique',
    'myanmar',
    'namibia',
    'nepal',
    'new.zealand',
    'nicaragua',
    'niger',
    'nigeria',
    'nordkorea',
    'norge',
    'oman',
    'pakistan',
    'palæstina',
    'panama',
    'papua.ny.guinea',
    'paraguay',
    'peru',
    'polen',
    'portugal',
    'puerto.rico',
    'qatar',
    'republikken.congo',
    'resten.af.europa',
    'resten.af.verden',
    'rumænien',
    'rusland',
    'rwanda',
    'salvador',
    'samoa',
    'san.marino',
    'saudi.arabien',
    'schweiz',
    'senegal',
    'serbien',
    'sierra.leone',
    'singapore',
    'skotland',
    'slovakiet',
    'slovenien',
    'somalia',
    'spanien',
    'specials',
    'sudan',
    'sverige',
    'swaziland',
    'sydafrika',
    'sydkorea',
    'syrien',
    'taiwan',
    'tanzania',
    'tchad',
    'thailand',
    'tibet',
    'tjekkiet',
    'togo',
    'trinidad.og.tobago',
    'tunesien',
    'turkmenistan',
    'tyrkiet',
    'tyskland',
    'uganda',
    'ukraine',
    'ungarn',
    'uruguay',
    'usa',
    'uzbekistan',
    'venezuela',
    'vietnam',
    'wales',
    'yemen',
    'zambia',
    'zimbabwe',
    'ækvatorialguinea',
    'østrig',
    'esoccer',
];

export const SYSTEM_NAMES = [
    'null',
    'singler',
    'doubler',
    'tripler',
    'firlinger',
    'femlinger',
    '6-ling',
    '7-ling',
    '8-ling',
    '9-ling',
    '10-ling',
    '11-ling',
    '12-ling',
    '13-ling',
    '14-ling',
    '15-ling',
];

export const SYSTEM_NAMES_WEB = [
    'Single',
    'Double',
    'Trippel',
    'Firling',
    'Femling',
    'Seksling',
    'Syvling',
    'Otteling',
    'Niling',
    'Tiling',
    'Elleveling',
    'Tolvling',
    'Trettenling',
    'Fjortenling',
    'Femtenling',
];
