export const AUTH_LOGIN_PENDING = 'AUTH_LOGIN_PENDING';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_SESSION_PENDING = 'AUTH_SESSION_PENDING';
export const AUTH_SESSION_SUCCESS = 'AUTH_SESSION_SUCCESS';
export const AUTH_SESSION_ERROR = 'AUTH_SESSION_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SHOW_FORGOT_PASSWORD = 'AUTH_SHOW_FORGOT_PASSWORD';

export const AUTH_SIGNUP_STEP1_PENDING = 'AUTH_SIGNUP_STEP1_PENDING';
export const AUTH_SIGNUP_STEP1_SUCCESS = 'AUTH_SIGNUP_STEP1_SUCCESS';
export const AUTH_SIGNUP_STEP1_ERROR = 'AUTH_SIGNUP_STEP1_ERROR';

export const AUTH_SIGNUP_STEP2_PENDING = 'AUTH_SIGNUP_STEP2_PENDING';
export const AUTH_SIGNUP_STEP2_SUCCESS = 'AUTH_SIGNUP_STEP2_SUCCESS';
export const AUTH_SIGNUP_STEP2_ERROR = 'AUTH_SIGNUP_STEP2_ERROR';

export const AUTH_SIGNUP_STEP3_PENDING = 'AUTH_SIGNUP_STEP3_PENDING';
export const AUTH_SIGNUP_STEP3_SUCCESS = 'AUTH_SIGNUP_STEP3_SUCCESS';
export const AUTH_SIGNUP_STEP3_ERROR = 'AUTH_SIGNUP_STEP3_ERROR';

export const AUTH_RESET = 'AUTH_RESET';

export const AUTH_USER_BALANCE = 'AUTH_USER_BALANCE';

export const NEM_ID = 'NEM_ID';
export const AUTH_INFO = 'AUTH_INFO';
export const AUTH_USER_NEWSLATER = 'AUTH_USER_NEWSLATER';
export const AUTH_SHOW_LAST_LOGIN_POPUP = 'AUTH_SHOW_LAST_LOGIN_POPUP';
