import React, { useEffect, useState } from 'react';
import customersIcon from 'images/icons/big9/customers.svg';
import Text from 'ui/Text';
import '../styles/create-group-button.css';
import { createState, useState as useHookState } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import { connect } from 'react-redux';
import {
    combinationCount,
    combinationPrice,
    maxBetPrice,
} from 'components/big9/selectors';
import Icon from '../../../ecoui/Icon';
import { bindActionCreators } from 'redux';
import { showModal } from 'actions/UiActions';
import useTweaks from 'features/SpilKlub/state/useTweaks';
import useBetslipState from 'features/SpilKlub/state/useBetslipState';
import useOffersData from 'features/SpilKlub/state/useOffersData';

const STORAGE_KEY = 'SK.confirm_checkbox_checked';
const initialState = createState(false);

const CreateGroupButton = ({
    combinationPrice,
    combinationCount,
    maxBetPrice,
    changeDesktopIndex = () => {},
    showModal,
    parentRef,
}) => {
    const [maxBetPriceExceeded, setMaxBetPriceExceeded] = useState(false);
    const checked = useHookState(initialState);
    const { offers, status } = useOffersData({});
    const { enablePoolWithCoupon } = useTweaks();
    const { isShown: betslipShown } = useBetslipState();
    const [isResult, setIsResult] = useState(false);

    checked.attach(Persistence(STORAGE_KEY));

    useEffect(
        () => {
            setMaxBetPriceExceeded(
                combinationPrice * combinationCount > maxBetPrice
            );
        },
        [combinationPrice, combinationCount]
    );

    useEffect(
        () => {
            if (offers) {
                const filteredData = offers.filter(
                    (item) => item.status === 'SELL_OPEN'
                )[0];
                setIsResult(!filteredData?.items?.length);
            }
        },
        [offers]
    );

    return (
        !isResult && (
            <div
                className={`sk-create-group-button ${
                    betslipShown ? 'with-betslip' : ''
                } ${maxBetPriceExceeded ? 'with-error' : ''} ${
                    status === 'success' ? 'show-button' : ''
                }`}
                onClick={() => {
                    if (!maxBetPriceExceeded && status === 'success') {
                        if (!checked.get()) {
                            let left = 0;
                            if (parentRef) {
                                left = parentRef.getBoundingClientRect().left;
                            }
                            showModal('SK_CONFIRMATION_MODAL', {
                                left,
                                onSuccess: (e) => {
                                    checked.set(e);
                                    enablePoolWithCoupon();
                                    changeDesktopIndex();
                                },
                                onClose: () => {
                                    changeDesktopIndex();
                                },
                            });
                        } else {
                            enablePoolWithCoupon();
                        }
                        changeDesktopIndex();
                    }
                }}
            >
                <Icon
                    size="md"
                    color={!maxBetPriceExceeded ? '#333' : '#9198aa'}
                    src={customersIcon}
                    alt="customersIcon"
                />
                <Text color={'#333'} size={'x07'} bold padding={'0'}>
                    SpilKlub25
                </Text>
            </div>
        )
    );
};

const stateToProps = (state) => ({
    combinationPrice: combinationPrice(state),
    combinationCount: combinationCount(state),
    maxBetPrice: maxBetPrice(state),
});
const dispatchToProps = (dispatch) => {
    return {
        showModal: bindActionCreators(showModal, dispatch),
    };
};

export default connect(
    stateToProps,
    dispatchToProps
)(CreateGroupButton);
