import React, { Component } from 'react';
import styled from 'styled-components';
import { setDerbyPermission } from 'actions/AuthActions';
import { ViewEmailInput } from 'components/ViewInput';
import { detectMobile } from '../../utils/UiHelper';
import { connect } from 'react-redux';
import IOSwitch from 'react-ios-switch';

const Wrapper = styled.div`
    background: #edf1f8;
    border: 1px solid #dadde4;
    border-radius: 5px;
    padding: 15px;
`;

const StyledEmailInput = styled.div`
    .view-text-input {
        min-height: 0px;
        .input {
            input {
                width: 100%;
                height: 44px;
                background: #ffffff;
                border: 1px solid #dadde4;
                border-radius: 5px;
                margin-bottom: 10px;
                text-align: left;
                color: #8a8c8e;
                padding: 0 10px;
                box-sizing: border-box;
            }
            .validation-error {
                text-align: left;
                margin-bottom: 10px;
            }
        }
        label {
            display: none;
        }
    }
`;

const NewsletterSwitchWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const SwitchWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const PermissionText = styled.p`
    font: normal normal normal 10px/15px 'Roboto Condensed', sans-serif;
    color: #333333;
    margin: 0px;
`;

const SwitchContainer = styled.div`
    margin-right: 12px;
    display: flex;
`;

const PolicyLink = styled.a`
    color: #007bff;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

class NewsletterPermissionSwitch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchChecked: true,
            displaySwitch: !props.hasUserPlacedBet,
            prevSwitchChecked: null,
            email: props.userEmail || '',
            isEmailValid: true,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.hasUserPlacedBet && !prevProps.hasUserPlacedBet) {
            this.props.setDerbyPermission(
                this.state.switchChecked,
                this.state.email
            );
        }
    }

    handleEmailValidityChange = (isValid) => {
        this.setState({ isEmailValid: isValid });
        if (this.props.onEmailValidityChange) {
            this.props.onEmailValidityChange(isValid);
        }
    };

    handleEmailChange = (name, value) => {
        const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmailValid = emailRegEx.test(value);
        this.setState({ email: value, isEmailValid: isEmailValid });

        if (this.props.onEmailValidityChange) {
            this.props.onEmailValidityChange(isEmailValid);
        }
    };

    handleChange = (checked) => {
        this.setState({ switchChecked: checked });
    };

    render() {
        const { switchChecked, displaySwitch, email } = this.state;
        const isMobile = detectMobile();

        if (!displaySwitch) {
            return null;
        }

        return (
            <Wrapper>
                <StyledEmailInput>
                    <ViewEmailInput
                        placeholder="Indtast e-mail"
                        placeholderLabel=" "
                        value={email}
                        name="email"
                        valueHandler={this.handleEmailChange}
                        onValidityChange={this.handleEmailValidityChange}
                        className="view-email-input"
                    />
                </StyledEmailInput>
                <NewsletterSwitchWrapper>
                    <SwitchWrapper>
                        <SwitchContainer>
                            <IOSwitch
                                onColor="#4CD964"
                                offColor="#ccc"
                                checked={this.state.switchChecked}
                                onChange={this.handleChange}
                            />
                        </SwitchContainer>

                        <div>
                            {isMobile ? (
                                <PermissionText>
                                    Hvis ikke du ønsker at modtage tilbud eller
                                    nyheder om lignende produkter (væddemål og
                                    online casino fra Bet25 A/S, herunder
                                    CasinoGo) via din
                                </PermissionText>
                            ) : (
                                <PermissionText>
                                    Hvis ikke du ønsker at modtage tilbud eller
                                    nyheder om lignende produkter
                                </PermissionText>
                            )}
                        </div>
                    </SwitchWrapper>
                    {isMobile ? (
                        <PermissionText>
                            oplyste e-mailadresse, skal du swipe til venstre. Du
                            kan til enhver framelde dig at modtage tilbud og
                            nyheder fra Bet25 A/S igen. Læs mere om regler for
                            beskyttelse af personlige oplysninger her{' '}
                            <PolicyLink
                                target="_blank"
                                href="https://intercom.help/25syv/da/articles/3870767-persondatapolitik-for-bet25-a-s"
                            >
                                Vores persondatapolitik.
                            </PolicyLink>
                        </PermissionText>
                    ) : (
                        <PermissionText>
                            (væddemål og online casino fra Bet25 A/S, herunder
                            CasinoGo) via din oplyste e-mailadresse, skal du
                            swipe til venstre. Du kan til enhver framelde dig at
                            modtage tilbud og nyheder fra Bet25 A/S igen. Læs
                            mere om regler for beskyttelse af personlige
                            oplysninger her{' '}
                            <PolicyLink
                                target="_blank"
                                href="https://intercom.help/25syv/da/articles/3870767-persondatapolitik-for-bet25-a-s"
                            >
                                Vores persondatapolitik.
                            </PolicyLink>
                        </PermissionText>
                    )}
                </NewsletterSwitchWrapper>
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToProps = {
    setDerbyPermission,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterPermissionSwitch);
