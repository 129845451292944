import React, { useEffect, useState } from 'react';
import dropdownModal from 'features/SpilKlub/HOCs/dropdownModal';
import Text from 'ui/Text';
import Button from 'ui/Button';
import tickIcon from 'images/tick-rounded.svg';
import '../styles/confirmation_modal.css';
import { bindActionCreators } from 'redux';
import { hideModal } from 'actions/UiActions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { detectMobile } from 'utils/UiHelper';

const ConfirmationWrapper = styled.div`
    ${({ left }) => !detectMobile() && left && `left: ${left}px`};
`;

const SKConfirmationModal = ({ modalData = {}, hideModal }) => {
    const [checked, setChecked] = useState(false);
    return (
        <>
            <ConfirmationWrapper
                className={`sk-confirmation-wrapper`}
                left={modalData.left}
            >
                <hr />
                <div className={'sk-confirmation-content'}>
                    <div className={'sk-confirmation-header'}>
                        <Text
                            size={'x25'}
                            color={'#333'}
                            bold
                            margin={'0 0 0 8px'}
                            padding={'2px 0'}
                            width={'85%'}
                        >
                            Vil du tilføje denne kupon/udkast til en SpilKlub?
                        </Text>
                        <div
                            className={'close-btn-icon'}
                            onClick={() => {
                                hideModal('SK_CONFIRMATION_MODAL');
                                modalData.onClose();
                            }}
                        >
                            <i className={'fa fa-times'} aria-hidden="true" />
                        </div>
                    </div>
                    <hr size={1} color={'#DADDE4'} />
                    <div className={'confirmation-description'}>
                        <Text
                            color={'#333'}
                            size={'x15'}
                            padding={'0'}
                            lh={'22'}
                        >
                            Med SpilKlub25 kan du få andre til at købe andele
                            til din kupon. Når du spiller med andre, øger du
                            chancen for at få gevinst, da I får flere penge i
                            puljen og dermed kan lave en større kuponer med
                            flere udfald.
                        </Text>

                        <div className={'sk-select-wrapper'}>
                            <label>
                                <Text color={'#333'} size={'x15'} padding={'0'}>
                                    Vis ikke denne besked igen.
                                </Text>
                                <input
                                    type="checkbox"
                                    checked={checked}
                                    onChange={(e) => {
                                        setChecked(e.target.checked);
                                    }}
                                />
                                <span className="checkmark">
                                    {checked && (
                                        <img src={tickIcon} alt="tickIcon" />
                                    )}
                                </span>
                            </label>
                        </div>
                    </div>
                    <hr size={1} color={'#DADDE4'} />
                    <Button
                        active
                        width={'300px'}
                        margin={'25px auto 10px'}
                        onClick={() => {
                            hideModal('SK_CONFIRMATION_MODAL');
                            modalData.onSuccess(checked);
                        }}
                    >
                        <Text
                            bold
                            size={'x15'}
                            color={'#333'}
                            padding={'0'}
                            align={'center'}
                        >
                            Ja, tilføj til SpilKlub25
                        </Text>
                    </Button>
                </div>
            </ConfirmationWrapper>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: bindActionCreators(hideModal, dispatch),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(SKConfirmationModal);
