import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FastDepositLimitForm } from '@it25syv/25syv-ui';
import styled, { css } from 'styled-components';
import ViewModal from 'components/ViewModal';
import 'css/DepositLimitModal.scss';
import UserService from 'utils/UserService';
import { bindActionCreators } from 'redux';
import * as UiActions from 'actions/UiActions';
import { UI_ERROR_MESSAGE, UI_SUCCESS_MESSAGE } from 'constants/ui';
import * as AuthActions from 'actions/AuthActions';
import { detectMobile } from 'utils/UiHelper';
import Button from 'ui/Button';

const texts = {
    title: 'Daglig indbetalingsgrænse',
    description:
        'Du bedes indtaste en daglig indbetalingsgrænse for dit spil. Dette er et krav fra Spillemyndigheden, og det er ikke muligt at spille hos os, uden du har sat en indbetalingsgrænse. Bemærk, at din indbetalingsgrænse er gældende på både Bet25 Casino, Bet25 Heste og Bet25 Sport.',
    inputToolTip:
        'Indbetalingsgrænse er gældende pa både Bet25 Casino, Bet25 Heste og Bet25 Sport.',
    inputLabel: 'Indtast grænse',
    maxAmountLabel: 'Eller',
    maxAmountBtn: 'Vælg max grænse',
    confirmBtnLabel: '(50.000 kr.)',
    confirmBtn: 'Gem',
    termsCondsLink: 'ansvarligt spil',
    termsCondsTitle: 'Ansvarligt spil',
    termsCondsText: 'Terms & Conditions',
    readMore: 'Læs mere om ',
};

const MaxAmountButton = styled(Button).attrs({ active: true })`
    border-radius: 5px;
    margin: 10px 0;
`;

const ConfirmationButton = styled(Button)`
    ${({ disabled }) => (disabled ? 'opacity: 0.5' : '')};
    border-radius: 5px;
    margin: 30px 0;
`;

const Label = styled.p`
    margin: 0;
    padding-bottom: 5px;
    text-align: center;
    font-weight: 800;
    color: rgba(88, 88, 88, 0.5);
`;

const inputCSS = css`
    background: #eff2f7;
    border-radius: 5px;
    border: 2px solid #3333331a;
`;

const inputLabelCSS = css`
    font-weight: 800;
    color: #b1b7c8;
`;

const FastDepositLimitsModal = ({ userStatus, ...props }) => {
    let limits = {};
    const [isPending, setIsPending] = useState(false);

    useEffect(() => {
        setIsPending(true);
        UserService.LegalLimit()
            .then((res) => {
                if (res.data) {
                    limits = res.data;
                }
            })
            .finally(() => {
                setIsPending(false);
            });
    }, []);

    const onClickHandler = (val) => {
        if (val) {
            setIsPending(true);
            limits.dailyAmount = val;
            const messageText = (
                <p>
                    <b>Din indbetalingsgrænse er gemt - {val} kr. dagligt.</b>
                    <br />
                    <br />
                    <span>
                        Du kan altid ændre grænsen under dine kontoindstillinger
                        i øverste højre hjørne.
                    </span>
                </p>
            );

            UserService.SetLegalLimit(limits).then((res) => {
                if (res.success) {
                    props.uiActions.notification(
                        UI_SUCCESS_MESSAGE,
                        messageText
                    );
                    props.hideModal('FAST_DEPOSIT_LIMIT');
                    if (userStatus === 'temp') {
                        props.uiActions.setVerificationModalStatus(true);
                    }
                } else {
                    props.uiActions.notification(
                        UI_ERROR_MESSAGE,
                        'Der skete en fejl'
                    );
                }
                setIsPending(false);
                if (detectMobile() && userStatus !== 'temp') {
                    props.uiActions.showModal('PIN_ADVICE_POPUP');
                }
            });
        }
    };

    return (
        <div className="deposit-limit-modal">
            <div className="deposit-limit-modal__wrapper">
                <FastDepositLimitForm
                    inputCSS={inputCSS}
                    MaxAmountButton={MaxAmountButton}
                    ConfirmationButton={ConfirmationButton}
                    texts={texts}
                    Label={Label}
                    inputLabelCSS={inputLabelCSS}
                    onConfirm={(val) => {
                        onClickHandler(val);
                    }}
                    pending={isPending}
                />
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
        authActions: bindActionCreators(AuthActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FastDepositLimitsModal);
