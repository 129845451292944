import {
    TERMINAL_APPLY_COUPON,
    TERMINAL_APPLY_COUPON_ERROR,
    TERMINAL_APPLY_VOUCHER,
    TERMINAL_APPLY_VOUCHER_ERROR,
    TERMINAL_CLEAR_APPLY_VOUCHER_ERROR,
    TERMINAL_SESSION_ERROR,
    TERMINAL_SESSION_LOGOUT,
    TERMINAL_SESSION_PENDING,
    TERMINAL_SESSION_RESET,
    TERMINAL_SESSION_SUCCESS,
} from '../constants/terminalSession';

const initialState = {
    pending: false,
    fetched: false,
    data: {},
    voucher: null,
    error: false,
    applyError: false,
    //spilId
};

export default function terminalSession(state = initialState, action) {
    switch (action.type) {
        case TERMINAL_SESSION_PENDING:
            if (action.applyingVoucher) {
                return {
                    ...state,
                    pending: true,
                    voucher: null,
                    error: false,
                };
            } else {
                return {
                    ...state,
                    pending: true,
                    fetched: false,
                    data: {},
                    voucher: null,
                    error: false,
                };
            }

        case TERMINAL_SESSION_SUCCESS:
            window.localStorage.setItem('token', action.payload.session.id);

            return {
                ...state,
                pending: false,
                fetched: true,
                data: action.payload.session,
                voucher: action.payload.voucher,
                error: false,
            };

        case TERMINAL_SESSION_ERROR:
            return {
                ...state,
                pending: false,
                fetched: false,
                data: {},
                voucher: null,
                error: action.payload,
            };

        case TERMINAL_SESSION_LOGOUT:
            window.localStorage.setItem('token', '');

            return {
                ...state,
                pending: false,
                fetched: false,
                data: {},
                voucher: null,
                error: false,
            };

        case TERMINAL_SESSION_RESET:
            return {
                ...state,
                pending: false,
                fetched: false,
                data: {},
                voucher: null,
                error: false,
                applyError: false,
            };

        case TERMINAL_APPLY_VOUCHER:
            return {
                ...state,
                pending: false,
                fetched: true,
                data: action.payload.session,
                voucher: action.payload.voucher,
                error: false,
            };
        case TERMINAL_APPLY_VOUCHER_ERROR:
            return {
                ...state,
                pending: false,
                applyError: action.payload,
            };
        case TERMINAL_APPLY_COUPON:
            return {
                ...state,
                pending: false,
                fetched: true,
                data: action.payload.session,
                voucher: action.payload.voucher,
                error: false,
            };
        case TERMINAL_APPLY_COUPON_ERROR:
            return {
                ...state,
                applyError: action.payload,
            };
        case TERMINAL_CLEAR_APPLY_VOUCHER_ERROR:
            return {
                ...state,
                applyError: false,
            };

        default:
            return state;
    }
}
