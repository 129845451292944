import React, { useEffect, useState } from 'react';
import { UserHistory } from '@it25syv/25syv-ui';
import Wrapper from '../../ui/Wrapper';
import {
    detectMobile,
    disableBodyScroll,
    enableBodyScroll,
} from '../../utils/UiHelper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    GetTransactions,
    SetInitialConfig,
    ResetTransactions,
    SetTransactionHistoryDate,
} from '../../actions/TransactionHistoryActions';
import { showModal as ShowModal } from '../../actions/UiActions';
import ViewPageToolbar from '../../components/ViewPageToolbar';
import { browserHistory } from 'react-router';
import Text from '../../ui/Text';

const TransactionHistory = ({
    showModal,
    getTransactions,
    transactionHistory,
    setInitialConfig,
    resetTransactions,
    setTransactionHistoryDate,
}) => {
    const [showMore, setShowMore] = useState(false);
    const [activeTabData, setActiveTabData] = useState({});
    const [isInit, setIsInit] = useState(false);

    useEffect(() => {
        disableBodyScroll();
        return () => {
            resetTransactions();
            enableBodyScroll();
        };
    }, []);

    useEffect(
        () => {
            if (
                transactionHistory.isInitialDataSet &&
                !transactionHistory.pending
            ) {
                fetchTransactions();
                setIsInit(true);
            }
        },
        [transactionHistory.isInitialDataSet]
    );

    useEffect(
        () => {
            if (
                transactionHistory.isInitialDataSet &&
                !transactionHistory.pending &&
                isInit
            ) {
                if (
                    transactionHistory.listOfTransactions[
                        activeTabData.groupKey
                    ].transactions.length === 0
                ) {
                    fetchTransactions();
                }
            }
        },
        [activeTabData]
    );

    useEffect(
        () => {
            if (
                !transactionHistory.pending &&
                transactionHistory.isInitialDataSet
            ) {
                let currentTransaction =
                    transactionHistory.listOfTransactions[
                        activeTabData.groupKey
                    ];

                let allPages =
                    currentTransaction.count / transactionHistory.rowCount;

                setShowMore(
                    allPages > currentTransaction.offset && allPages > 1
                );
            }
        },
        [transactionHistory.pending]
    );

    useEffect(
        () => {
            if (
                !transactionHistory.pending &&
                transactionHistory.isInitialDataSet &&
                transactionHistory.dateIsUpdated
            ) {
                fetchTransactions(true);
            }
        },
        [transactionHistory.date]
    );

    const fetchTransactions = (isFilter = false) => {
        let group = activeTabData?.groupKey;
        let dateFromS = transactionHistory.date.dateFrom;
        let dateToS = transactionHistory.date.dateTo;
        let offsetS = !isFilter
            ? transactionHistory.listOfTransactions[group].offset
            : 0;
        let rowCount = transactionHistory.rowCount;

        getTransactions(group, dateFromS, dateToS, offsetS, rowCount, isFilter);
    };

    const loadMoreHandler = () => {
        fetchTransactions();
    };

    return (
        <Wrapper
            height={'100%'}
            minHeight={detectMobile() ? '100vh' : '100%'}
            maxHeight={'100vh'}
            flex
            flexDirection={'column'}
            justify={'stretch'}
            flexHeight={'1 1 0'}
            width={'100%'}
            padding={'0'}
            margin={'0'}
            boxSizing={'border-box'}
            overflow={'hidden'}
            position={detectMobile() ? 'fixed' : 'relative'}
            fontSize={'14px'}
            zIndex={detectMobile() ? 11 : 1}
            left={'0'}
            top={'0'}
        >
            {detectMobile() ? (
                <ViewPageToolbar
                    title={'Kontohistorik'}
                    onBackBtn={() => {
                        browserHistory.goBack();
                    }}
                />
            ) : (
                <Text
                    height={'56px'}
                    width={'100%'}
                    size={'16'}
                    lh={'24'}
                    flex={'0'}
                    bold
                    color={'#1e1e1e'}
                    align={'center'}
                    margin={'0'}
                    padding={'16px'}
                    boxSizing={'border-box'}
                    border={'border-bottom: 1px solid #DFE5ED'}
                >
                    Kontohistorik
                </Text>
            )}

            <Wrapper
                height={'100%'}
                maxHeight={'100%'}
                flex
                flexDirection={'column'}
                width={'100%'}
                padding={detectMobile() ? '0 0 70px' : '0'}
                margin={'0'}
                flexHeight={'1 1 0'}
                boxSizing={'border-box'}
                overflow={'hidden'}
                position={'relative'}
                fontSize={'14px'}
                zIndex={detectMobile() ? 11 : 1}
            >
                <UserHistory
                    transactions={
                        transactionHistory.listOfTransactions[
                            activeTabData.groupKey
                        ]?.transactions || []
                    }
                    loading={transactionHistory.pending}
                    showMore={showMore}
                    loadMoreHandler={() => loadMoreHandler()}
                    loadMoreColor={'#1C3F61'}
                    setTab={setActiveTabData}
                    setInitialConfig={(data) => setInitialConfig(data)}
                    openChooseDate={() => showModal('CHOSE_DATE_MODAL')}
                    showDetails={(data) => {
                        showModal('TRANSITION_DETAILS_MODAL', { data });
                    }}
                    currentDate={transactionHistory.date} //default format {dateTo: DDMMYYYY, dateFrom: DDMMYYYY}
                    resetDateHandler={(date) => {
                        setTransactionHistoryDate(date);
                    }}
                    buttonsAreaPosition={detectMobile() ? 'fixed' : 'absolute'}
                />
            </Wrapper>
        </Wrapper>
    );
};

const mapStateToProps = (state) => {
    return { transactionHistory: state.transactionHistory };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: bindActionCreators(ShowModal, dispatch),
        getTransactions: bindActionCreators(GetTransactions, dispatch),
        setInitialConfig: bindActionCreators(SetInitialConfig, dispatch),
        resetTransactions: bindActionCreators(ResetTransactions, dispatch),
        setTransactionHistoryDate: bindActionCreators(
            SetTransactionHistoryDate,
            dispatch
        ),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionHistory);
