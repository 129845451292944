import React, { useEffect, useState } from 'react';
import UIModal from 'components/UIModal';
import UserService from 'utils/UserService';
import Text from 'ui/Text';
import { formatDate } from 'utils/UiHelper';
import styled from 'styled-components';

const StyledWrapper = styled.div`
    display: block;
    padding: 90px 15px 15px;
    a {
        text-decoration: none;
        color: #1c3e60;
    }
    ul {
        padding-left: 20px;
        li {
            list-style: disc;
        }
    }
    @media screen and (min-width: 1024px) {
        padding-top: 50px;
    }
`;

const SelfExclusionPopup = (props) => {
    const [exclusionDate, setExclusionDate] = useState(false);
    useEffect(() => {
        UserService.getUserStatus().then((res) => {
            let { data } = res;
            if (data && data.tm) {
                setExclusionDate(data.tm);
            }
        });
    }, []);

    return (
        <UIModal
            {...props}
            onCloseModal={() => {
                props.hideModal('SELF_EXCLUSION_POPUP');
            }}
            title="Selvudelukkelse"
        >
            <StyledWrapper>
                {exclusionDate ? (
                    <Text size="x3" bold>
                        Din konto er nu lukket til:{' '}
                        {formatDate(exclusionDate, 'DD. MMMM YYYY [kl] HH:mm')}
                    </Text>
                ) : null}

                <Text>
                    Du har valgt at selvudelukke dig fra din konto.{' '}
                    {props.modalData?.type === 'permanent' ? (
                        <b>
                            Din eventuelle balance vil blive udbetalt automatisk
                            til din Nemkonto.{' '}
                        </b>
                    ) : null}
                    Skulle du have spørgsmål, kan du kontakte vores support. Har
                    du spilleproblemer, eller er i tvivl om du har
                    spilleproblemer så kan du henvende dig til{' '}
                    <b>Center for ludomani</b> på telefon:{' '}
                    <a href="tel:70111810">70 11 18 10</a> eller e-mail på{' '}
                    <a href="mailto:info@ludomani.dk">info@ludomani.dk</a>. Du
                    kan læse mere på om ludomani på{' '}
                    <a href="https://ludomani.dk/" target={'_blank'}>
                        www.ludomani.dk
                    </a>{' '}
                    hvor du også kan teste dig selv for ludomani. Du kan også
                    besøge Center for Ludomani på følge adresser:
                </Text>

                <ul>
                    <li>
                        <Text>
                            Center for ludomani i København Fiolstræde 17B st
                            tv. 1171 København
                        </Text>
                    </li>
                    <li>
                        <Text>
                            Center for ludomani i Odense Østergade 42 5000
                            Odense
                        </Text>
                    </li>
                    <li>
                        <Text>
                            Center for ludomani i Århus Gammel Munkegade 6D 8000
                            Århus C
                        </Text>
                    </li>
                </ul>

                <Text>
                    Du kan ligeledes henvende dig til Frederiksberg Centeret på
                    telefon: <a href="tel:33217300">33 21 73 00</a> eller e-mail
                    på{' '}
                    <a href="mailto:info@frederiksberg-centeret.dk">
                        info@frederiksberg-centeret.dk
                    </a>{' '}
                    eller læse mere på{' '}
                    <a
                        href="http://www.frederiksberg-centeret.dk/ludomani/"
                        target={'_blank'}
                    >
                        www.frederiksberg-centeret.dk/ludomani/
                    </a>
                </Text>

                <ul>
                    <li>
                        <Text>
                            Frederiksberg Centeret - København Afdeling
                            Esplanaden 8C, 2. sal 1263 København K.
                        </Text>
                    </li>
                </ul>
            </StyledWrapper>
        </UIModal>
    );
};
export default SelfExclusionPopup;
