import React from 'react';
import { connect } from 'react-redux';
import 'css/AccountExcludedPermanently.scss';
import Wrapper from '../ui/Wrapper';
import Text from '../ui/Text';
import { COLORS } from '../themes/colors';
import Button from '../ui/Button';
import { hideModal } from '../actions/UiActions';
import { HR } from '../components/big9/styled';
import { detectMobile } from '../utils/UiHelper';
import { bindActionCreators } from 'redux';

const AccountExcludedPermanently = (props) => {
    return (
        <Wrapper
            width={'100%'}
            height={'100%'}
            padding={'7px'}
            margin={'0 auto'}
            flex
            flexDirection={'column'}
            flexAlign={'center'}
            justify={detectMobile() ? 'flex-end' : 'center'}
            boxSizing={'border-box'}
        >
            <div className="account-content">
                <Text
                    width={'100%'}
                    size={'24'}
                    lh={'24'}
                    color={COLORS.dark}
                    margin={'0'}
                    padding={'0'}
                    align={'left'}
                    bold
                >
                    Lukket konto
                </Text>
                <Text
                    width={'100%'}
                    size={'16'}
                    lh={'24'}
                    color={COLORS.dark}
                    margin={'15px 0 0'}
                    padding={'0'}
                    align={'left'}
                >
                    Din konto er nu lukket permanent, dit eventuelle <br />{' '}
                    indestående er sendt til din Nemkonto.
                </Text>
                <HR margin={'20px auto'} bg={'#DBDDE3'} />
                {/*<Button
                    width={'100%'}
                    margin={'0'}
                    yellow
                    active
                    onClick={closeModal}
                >
                    Okay
                </Button>*/}
                <button onClick={closeModal}>dadasa</button>
            </div>
        </Wrapper>
    );
};

/*
const mapDispatchToProps = (dispatch) => {
    return {
        //HideModal: (modalId) => dispatch(hideModal(modalId)),
        hideModal: (modalId) => dispatch(hideModal(modalId)),
    };
};
*/

/*function mapDispatchToProps(dispatch) {
    return {
        HideModal: bindActionCreators(hideModal, dispatch),
    };
}*/

export default AccountExcludedPermanently;
/*export default connect(
    null,
    null
)(AccountExcludedPermanently);*/
