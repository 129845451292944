import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import DesktopLayout from './DesktopLayout';
import * as UiActions from 'actions/UiActions';
import * as AuthActions from 'actions/AuthActions';
import * as LiveEventsActions from 'actions/LiveEventsActions';
import * as BetslipActions from 'actions/BetslipActions';
import * as EventObjectActions from 'actions/EventObjectActions';
import * as ServerConfigActions from 'actions/ServerConfigActions';
import * as PreEventsActions from 'actions/PreEventsActions';
import * as PopularEventsActions from 'actions/PopularEventsActions';
import {
    FETCH_DATA_INTERVAL_MS,
    LOCAL_STORAGE_KEYS,
} from 'constants/appConfig';
import {
    UI_ERROR_MESSAGE,
    UI_SUCCESS_MESSAGE,
    UI_WARNING_MESSAGE,
} from 'constants/ui';
import MetaTags from 'react-meta-tags';
import Firebase from 'features/Firebase';
import { isMTSStakeError } from 'utils/Helper';
import 'css/App.css';
import NotificationMessage from '../components/NotificationMessage/NotificationMessage';
import { detectMobile, formatDate } from '../utils/UiHelper';

class DesktopApp extends Component {
    notifications = {};

    constructor() {
        super();
        this.routeListWithPreEvents = ['/', '/pre'];
        //this.changeCallback = React.createRef();
        window.locationHistory = [];
    }

    isBetListContainPreEvents() {
        const { preEvents, betslip } = this.props;
        return preEvents?.data?.some((event) =>
            betslip.betList.find((bet) => bet.eventId === event.eventId)
        );
    }

    intervalFetchData = () => {
        window.clearTimeout(this.fetchDataTimerlId);
        this.props.authActions.checkUserLoggedStatus();
        if (
            this.isBetListContainPreEvents() ||
            this.routeListWithPreEvents.includes(this.props.location.pathname)
        ) {
            this.fetchPreEvents();
        }
        if (this.props.location.pathname === '/') {
            this.fetchPopularEvents();
        }
        this.fetchDataTimerlId = window.setTimeout(
            this.intervalFetchData,
            FETCH_DATA_INTERVAL_MS
        );
    };

    fetchPreEvents = () => {
        this.props.preEventsActions.getTodayEvents(this.props.sportData);
    };

    fetchPopularEvents = () => {
        this.props.popularEventsActions.getPopularEvents(
            true,
            this.props.auth?.user?.userId
        );
    };

    componentWillUnmount() {
        window.clearTimeout(this.fetchDataTimerlId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            window.locationHistory.unshift(this.props.location.pathname);
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'page_view',
                    type: 'sport',
                    eventUrl: nextProps.location.pathname, //variable for URL
                    userId: this.props.auth?.user?.userId || 0,
                });
            }

            if (window.locationHistory.length > 3) {
                window.locationHistory = window.locationHistory.slice(0, 3);
            }
        }

        if (nextProps.betslip.success) {
            this.props.betslipActions.reset();

            NotificationManager.success(
                '',
                'Dit spil er sat - held og lykke!',
                4000
            );

            this.props.authActions.updateBalance();
        } else if (nextProps.betslip.error) {
            setTimeout(() => {
                this.notifications['betslip'] = {
                    error: false,
                };

                this.props.betslipActions.clearError();
            }, 4000);

            if (
                !this.notifications.betslip ||
                !this.notifications.betslip.error
            ) {
                if (
                    !isMTSStakeError(nextProps.betslip.error) &&
                    nextProps.betslip.error !== 'Saldoen er for lav'
                ) {
                    NotificationManager.error(
                        '',
                        nextProps.betslip.error,
                        4000
                    );
                }
                this.notifications['betslip'] = {
                    error: true,
                };
            }
        } else if (nextProps.betslip.addError) {
            this.props.betslipActions.clearError();

            NotificationManager.error('', nextProps.betslip.addError, 4000);
        } else if (nextProps.ui.messageText) {
            switch (nextProps.ui.messageType) {
                case UI_SUCCESS_MESSAGE:
                    NotificationManager.success(
                        '',
                        nextProps.ui.messageText,
                        4000
                    );
                    break;
                case UI_WARNING_MESSAGE:
                    NotificationManager.warning(
                        '',
                        nextProps.ui.messageText,
                        4000
                    );
                    break;
                case UI_ERROR_MESSAGE:
                    NotificationManager.error(
                        '',
                        nextProps.ui.messageText,
                        4000
                    );
                    break;
                default:
                    NotificationManager.info(
                        '',
                        nextProps.ui.messageText,
                        4000
                    );
            }

            this.props.uiActions.clearNotification();
        }

        if (
            this.props.location.pathname !== nextProps.location.pathname &&
            nextProps.location.pathname.indexOf('/kampagner/') === -1
        ) {
            if (window.adf) {
                window.adf.track(217163, null, {});
            }
        }
    }

    userLoggedInHandler() {
        window.localStorage.setItem(
            LOCAL_STORAGE_KEYS.LAST_LOGGED_IN_USER_NAME,
            this.props.auth.user.userName
        );

        if (
            this.props.auth.user.status === 'ex_24h' ||
            this.props.auth.user.status === 'ex_1m'
        ) {
            this.props.uiActions.showModal('SELF_EXCLUSION_POPUP');
        }
    }

    render() {
        const seoData = this.props.serverConfig.seoData[
            window.location.pathname
        ];

        let lastLoginNotification = this.props.auth.showLastLoginPopup && (
            <NotificationMessage
                date={`${formatDate(
                    this.props.auth?.user?.extraInfo?.lastLogin || new Date(),
                    'DD/MM-YYYY [kl.] HH:MM:SS'
                )}`}
                fs={'13'}
                lh={'20'}
                padding={'10px 64px 10px 32px'}
                showCloseIcon={!detectMobile()}
                closeNotification={() =>
                    this.props.authActions.showLastLoginPopupStatus(false)
                }
            />
        );

        return (
            <Firebase>
                {(eventsListener, marketsListener) => {
                    return (
                        <div className={'app'}>
                            <DesktopLayout>{this.props.children}</DesktopLayout>
                            {seoData ? (
                                <MetaTags>
                                    <title>Bet25.dk - {seoData.title}</title>
                                    <meta
                                        name="description"
                                        content={seoData.description}
                                    />
                                    <meta
                                        name="keywords"
                                        content={seoData.keywords}
                                    />
                                </MetaTags>
                            ) : (
                                <MetaTags>
                                    <title>
                                        Bet25.dk - Odds og Livebetting – Spil
                                        dansk!
                                    </title>
                                    <meta
                                        name="description"
                                        content="Bet25 tilbyder odds og livebetting på et utal af sportsbegivenheder på fodbold, tennis, håndbold, basketball, osv. Danmarks bedste på dansk fodbold."
                                    />
                                </MetaTags>
                            )}

                            {lastLoginNotification}

                            <NotificationContainer />
                        </div>
                    );
                }}
            </Firebase>
        );
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.auth.user &&
            this.props.betslip.error === 'Session udløbet'
        ) {
            this.props.authActions.logout();
        }
        if (this.props.sportTree.fetched && !prevProps.sportTree.fetched) {
            this.intervalFetchData();
        }
        if (prevProps.preEvents.data !== this.props.preEvents.data) {
            if (this.isBetListContainPreEvents()) {
                this.props.betslipActions.updateMarketsWithOddsChangeCheck(
                    this.props.preEvents.data
                );
            }
        }
        if (!prevProps.betslip.error && this.props.betslip.error) {
            this.intervalFetchData();
        }
        if (
            prevProps.location.pathname !== this.props.location.pathname &&
            this.routeListWithPreEvents.includes(this.props.location.pathname)
        ) {
            this.intervalFetchData();
        }

        if (!prevProps.auth.loginSuccess && this.props.auth.loginSuccess) {
            this.userLoggedInHandler();
        }

        if (
            !prevProps.auth.showLastLoginPopup &&
            this.props.auth.showLastLoginPopup
        ) {
            if (this.props.auth.showLastLoginPopup) {
                setTimeout(() => {
                    this.props.authActions.showLastLoginPopupStatus(false);
                }, 5000);
            }
        }

        if (
            prevProps.betslip.regularBetBetslipFetched !==
            this.props.betslip.regularBetBetslipFetched
        ) {
            if (this.props.betslip.hasMarkets.length === 0) {
                this.props.betslipActions.getCustomBetBetslip();
            }
        }
    }
    componentDidMount() {
        console.log('app');
        this.props.liveEventsActions.getLiveEvents(this.props.sportData);
        this.intervalFetchData();

        this.props.betslipActions.setup();

        this.props.serverConfigActions.getServerConfig();
    }
}

function mapStateToProps(state) {
    return {
        loginModalStatus: state.ui.loginModalStatus,
        signupModalStatus: state.ui.signupModalStatus,
        auth: state.auth,
        liveEvents: state.liveEvents,
        preEvents: state.preEvents,
        sportTree: state.sportTree,
        sportData: state.sportTree.sportData,
        betslip: state.betslip,
        eventObject: state.eventObject,
        ui: state.ui,
        serverConfig: state.serverConfig,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
        authActions: bindActionCreators(AuthActions, dispatch),
        liveEventsActions: bindActionCreators(LiveEventsActions, dispatch),
        betslipActions: bindActionCreators(BetslipActions, dispatch),
        preEventsActions: bindActionCreators(PreEventsActions, dispatch),
        popularEventsActions: bindActionCreators(
            PopularEventsActions,
            dispatch
        ),
        eventObjectActions: bindActionCreators(EventObjectActions, dispatch),
        serverConfigActions: bindActionCreators(ServerConfigActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DesktopApp);
