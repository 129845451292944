import { CUSTOM_BET_PATH, OUR_BE_REST_VAIX_PATH } from 'constants/appConfig';
import { makeRequest } from 'utils/REST';

const request = (requestType, data, url = CUSTOM_BET_PATH) => {
    return makeRequest(requestType, data, url)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
};

const post = (method, data, path = CUSTOM_BET_PATH) => {
    return request('post', path + method, data);
};

const get = (method, data, path = CUSTOM_BET_PATH) => {
    return request('get', path + method, data);
};

export default {
    getCustomBetOffer: (marketId) => get(`available/${marketId}`),
    addCustomBetMarket: (gameId, betId, isLive) =>
        post(`add`, {
            gameId,
            betId,
            live: isLive,
        }),
    getSlip: () => get(`slip`),
    clearMarkets: () => post(`clear`),
    removeMarket: (gameId, betNr) => post(`del/${gameId}/${betNr}`),
    placeBet: (odds, stake, freeBet) =>
        post(
            `buy`,
            {
                odds,
                stake,
                freeBet,
            },
            CUSTOM_BET_PATH
        ),

    getEventsCustomBetRecommendation: (id, location, isEventPage) =>
        get(
            `custombuilderNoOdds${id ? `?eventId=${id}` : ''}${
                isEventPage ? '&' : '?'
            }location=${location}`,
            {},
            OUR_BE_REST_VAIX_PATH
        ),

    getOddsCustomBetRecommendation: (data) =>
        post(`odds`, data, OUR_BE_REST_VAIX_PATH),

    placeCustomBetRecommendation: (uofEventId, uofExternalIds) =>
        post(
            `addExternal`,
            {
                uofEventId,
                uofExternalIds,
            },
            CUSTOM_BET_PATH
        ),
};
