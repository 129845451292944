import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonsArea from 'features/UserDeposits/Components/ButtonsArea';
import PaymentType from 'features/UserDeposits/Components/PaymentType';
import {
    DIBS_ACCEPT_RETURN_URL,
    DIBS_CLIENT_NAME,
    DIBS_EXCHANGE_DK,
    DIBS_PMNT,
    fundErrors,
} from './dibs';
import { NotificationManager } from 'react-notifications';
import UserService from 'utils/UserService';
import AuthService from 'utils/AuthService';
import { detectMobile, scrollToTop } from 'utils/UiHelper';
import { checkUserExclusionStatus } from 'utils/Helper';
import ApplePayService from 'utils/ApplePayService';
import { browserHistory } from 'react-router';
import { browserHistoryGoBack } from '../../utils/UiHelper';

const Wrapper = styled.div`
    width: 100%;
    padding: ${detectMobile() ? '0' : '30px 0'};
    height: ${detectMobile() ? 'auto' : '100%'};
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;
`;

const StyledIframe = styled.iframe`
    min-height: 550px;
    border: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f8f8f8;
    display: ${(props) => (props.show ? 'block' : 'none')};
    margin-top: ${detectMobile() ? '10px' : '-40px'};
    z-index: 3;
    position: relative;
`;

const USERS_WITH_CUSTOM_MINDEPOSIT = [190738, 75117, 198023];

const UserDeposits = (props) => {
    const [bonusList, setBonusList] = useState([]);
    const [casinoBonuses, setCasinoBonuses] = useState([]);
    const [submitDeposit, setSubmitDeposit] = useState(false);
    const [selectedBonuses, setSelectedBonuses] = useState([]);
    const [amount, setAmount] = useState('');
    const [payType, setPayType] = useState('card');
    const [minDeposit, setMinDeposit] = useState(50);
    const [isLoading, setIsLoading] = useState(false);
    const [isExcluded, setIsExcluded] = useState(false);
    const [userDepositLimits, setUserDepositLimits] = useState(null);

    const iframeRef = React.createRef();
    const odds1Bonuses = [1094];
    const { history, closeModal } = props;

    useEffect(() => {
        UserService.LegalLimit().then((res) => {
            if (res.data) {
                setUserDepositLimits(res.data);
            }
        });

        getMinDepositConf();

        getBonusList();

        getCasinoBonuses();

        window.addEventListener('message', depositHandler);

        checkUserExclusionStatus((res) => {
            setIsExcluded(res);
        });

        return () => {
            window.removeEventListener('message', depositHandler);
        };
    }, []);

    const getMinDepositConf = () => {
        USERS_WITH_CUSTOM_MINDEPOSIT.includes(props?.user?.userId)
            ? setMinDeposit(1)
            : setMinDeposit(50);
    };

    const getBonusList = () => {
        UserService.GetDepositBonus()
            .then((res) => {
                {
                    let bonus_obj = res.data;
                    if (bonus_obj && bonus_obj.length > 0) {
                        bonus_obj.forEach((bonus) => {
                            if (
                                bonus.bonusType === 'D' &&
                                bonus.bonusAction === 'D' &&
                                odds1Bonuses.indexOf(bonus.bonusId) < 0
                            ) {
                                setBonusList([...bonusList, bonus]);
                            }
                        });
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getCasinoBonuses = () => {
        UserService.GetCasinoBonus()
            .then((res) => {
                {
                    let bonus_obj = res.data;
                    if (bonus_obj && bonus_obj.length > 0) {
                        bonus_obj.forEach((bonus) => {
                            if (
                                bonus.bonusType === 'C' &&
                                bonus.bonusAction === 'D' &&
                                odds1Bonuses.indexOf(bonus.bonusId) >= 0
                            ) {
                                setCasinoBonuses([...casinoBonuses, bonus]);
                            }
                        });
                    }
                }
            })
            .catch((err) => {
                console.error('getBonusListSrv err: ' + err);
            });
    };

    const sendGTM = (amount) => {
        AuthService.isUserLoggedIn().then((res) => {
            const { data } = res;
            UserService.Payment(
                '01011970',
                new Date().toLocaleDateString('en-GB').replace(/\//g, ''),
                0,
                10,
                (res) => {
                    if (res) {
                        window.dataLayer.push({
                            event: 'bet25.deposit',
                            depositCount: res.count,
                            accountBalance: data.balance,
                            depositAmount: amount,
                            userName: data.userName,
                            userId: data?.userId,
                        });
                    }
                }
            );
        });
    };

    const maxDepositValue = () => {
        const limits = userDepositLimits;

        return (
            parseInt(amount) > parseInt(limits?.maxDeposit) ||
            (limits?.dailyAmountLimit &&
                parseInt(amount) > parseInt(limits?.dailyAmount)) ||
            (limits?.weeklyAmountLimit &&
                parseInt(amount) > parseInt(limits?.weeklyAmount)) ||
            (limits?.monthlyAmountLimit &&
                parseInt(amount) > parseInt(limits?.monthlyAmount))
        );
    };

    const getIframeUrl = () => {
        let bonus = '';
        if (selectedBonuses && selectedBonuses.length) {
            selectedBonuses.forEach((b) => {
                bonus += `&bonusId=${b.bonusId}`;
            });
        }

        return `${DIBS_ACCEPT_RETURN_URL}/dibs?op=deposit&client=${DIBS_CLIENT_NAME}&pmnt=${DIBS_PMNT}&exchcode=${DIBS_EXCHANGE_DK}&payType=${payType}&amount=${amount *
            100}&input_amount=${amount}${bonus}`;
    };

    const depositHandler = ({ data }) => {
        if (typeof data === 'string' && data.includes('custom-event')) {
            const errors = fundErrors;
            let dibsIframeMessage = JSON.parse(data);
            const url = new URL(dibsIframeMessage.url);
            const reason = url.searchParams.get('reason');
            const amount = url.searchParams.get('amount');
            switch (dibsIframeMessage.status) {
                case 'success':
                    try {
                        sendGTM(amount);
                    } catch (err) {
                        console.error(err);
                    }
                    browserHistoryGoBack();
                    setTimeout(() => {
                        closeModal();
                    }, 0);
                    NotificationManager.success(
                        `Din indbetaling på ${amount} kr. er gennemført`
                    );
                    props.checkSession();
                    break;
                case 'error':
                    setSubmitDeposit(false);
                    setIsLoading(false);
                    props.checkSession();
                    NotificationManager.error(errors[reason]);
                    break;
            }
        }
    };

    const submit = () => {
        if (isExcluded) {
            setAmount('');
            return checkUserExclusionStatus();
        }
        if (amount && maxDepositValue()) {
            props.openDepositLimitPopup();
        } else {
            if (payType === 'applePay' && amount) {
                let validationURL = '';
                const paymentRequest = {
                    currencyCode: 'DKK',
                    countryCode: 'DK',
                    validationURL,
                    total: {
                        label: 'Bet25 Sport deposit',
                        type: 'final',
                        amount,
                    },
                    merchantIdentifier: 'merchant.824477',
                    merchantCapabilities: [
                        'supports3DS',
                        'supportsCredit',
                        'supportsDebit',
                    ],
                    supportedNetworks: ['amex', 'masterCard', 'visa'],
                };
                let session = new window.ApplePaySession(3, paymentRequest);
                session.onvalidatemerchant = async (event) => {
                    validationURL = event.validationURL;
                    // Call your own server to request a new merchant session.
                    ApplePayService.ApplePayMerchantVerification().then(
                        (res) => {
                            if (res?.data?.merchantSessionIdentifier) {
                                session.completeMerchantValidation(res?.data);
                            }
                        }
                    );
                };
                session.onpaymentauthorized = (event) => {
                    let data = event?.payment?.token;
                    const paymentData = encodeURIComponent(
                        JSON.stringify(data)
                    );

                    // Define ApplePayPaymentAuthorizationResult
                    const result = {
                        status: ApplePaySession.STATUS_SUCCESS,
                    };
                    ApplePayService.CompleteApplePayPayment(
                        amount,
                        paymentData,
                        selectedBonuses
                    )
                        .then((res) => {
                            if (res.url) {
                                history.push('/');
                                setTimeout(() => {
                                    closeModal();
                                }, 0);
                                NotificationManager.success(
                                    `Din indbetaling på ${amount} kr. er gennemført`
                                );
                                try {
                                    sendGTM(amount);
                                } catch (err) {
                                    console.error(err);
                                }
                                props.checkSession();
                            } else {
                                NotificationManager.error(
                                    fundErrors[res.error] ||
                                        'Fejl - prøv igen og kontakt kundesupport hvis problemet fortsætter'
                                );
                            }
                        })
                        .catch((e) => {
                            NotificationManager.error(
                                fundErrors[e.error] ||
                                    'Fejl - prøv igen og kontakt kundesupport hvis problemet fortsætter'
                            );
                        });
                    session.completePayment(result);
                };
                session.begin();
            } else {
                setSubmitDeposit(true);
                setIsLoading(true);
                scrollToTop();
            }
        }
    };

    return (
        <Wrapper>
            {!submitDeposit ? (
                <Fragment>
                    <PaymentType
                        payType={payType}
                        amount={amount}
                        setPayType={setPayType}
                    />
                    <ButtonsArea
                        onSubmitClick={submit}
                        minDeposit={minDeposit}
                        bonusList={bonusList}
                        casinoBonuses={casinoBonuses}
                        selectedBonuses={selectedBonuses}
                        setSelectedBonuses={setSelectedBonuses}
                        amount={amount}
                        setAmount={setAmount}
                    />
                </Fragment>
            ) : (
                <Fragment>
                    <StyledIframe
                        width={'100%'}
                        height={'100%'}
                        show={submitDeposit}
                        onLoad={() => setIsLoading(false)}
                        src={getIframeUrl()}
                        ref={iframeRef}
                    >
                        <div>
                            LOCATION
                            {window.location.href}
                        </div>
                    </StyledIframe>
                </Fragment>
            )}
        </Wrapper>
    );
};

export default UserDeposits;
