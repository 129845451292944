export const BETSLIP_PENDING = 'BETSLIP_PENDING';
export const BETSLIP_ADDED_OK = 'BETSLIP_ADDED_OK';
export const BETSLIP_REGULAR_FETCHED_STATUS = 'BETSLIP_REGULAR_FETCHED_STATUS';
export const BETSLIP_CUSTOMBET_FETCHED_STATUS =
    'BETSLIP_CUSTOMBET_FETCHED_STATUS';
export const BETSLIP_ADDED_ERROR = 'BETSLIP_ADDED_ERROR';
export const BETSLIP_CUSTOMBET_ADDED_OK = 'BETSLIP_CUSTOMBET_ADDED_OK';
export const BETSLIP_CUSTOMBET_ADDED_ERROR = 'BETSLIP_CUSTOMBET_ADDED_ERROR';
export const BETSLIP_SET_VALUE = 'BETSLIP_SET_VALUE';
export const BETSLIP_SET_CUSTOM_BET_VALUE = 'BETSLIP_SET_CUSTOM_BET_VALUE';
export const BETSLIP_ERROR = 'BETSLIP_ERROR';
export const BETSLIP_RESET = 'BETSLIP_RESET';
export const BETSLIP_RESET_SINGLE_STAKE = 'BETSLIP_RESET_SINGLE_STAKE';
export const BETSLIP_CUSTOM_BET_RESET = 'BETSLIP_CUSTOM_BET_RESET';
export const BETSLIP_ITEM_ERROR = 'BETSLIP_ITEM_ERROR';
export const BETSLIP_SUCCESS = 'BETSLIP_SUCCESS';
export const BETSLIP_DISSABLED = 'BETSLIP_DISSABLED';
export const BETSLIP_CHANGED = 'BETSLIP_CHANGED';
export const BETSLIP_ACCEPT_CHANGES = 'BETSLIP_ACCEPT_CHANGES';
export const BETSLIP_SET_SUGGESTION = 'BETSLIP_SET_SUGGESTION';
export const BETSLIP_SET_LAST_RESULT = 'BETSLIP_SET_LAST_RESULT';
export const BETSLIP_SET_LIVE_MARKETS = 'BETSLIP_SET_ACTIVE_MARKET';
export const BETSLIP_CUSTOM_BET_NORMALIZE_CHANGES =
    'BETSLIP_CUSTOM_BET_NORMALIZE_CHANGES';
export const BETSLIP_UPDATE_TOTAL_ODDS_VALUE =
    'BETSLIP_UPDATE_TOTAL_ODDS_VALUE';
export const BETSLIP_UPDATE_TOTAL_ODDS_VALUE_PENDING =
    'BETSLIP_UPDATE_TOTAL_ODDS_VALUE_PENDING';
export const BETSLIP_NORMALIZE_CHANGES = 'BETSLIP_NORMALIZE_CHANGES';
export const BETSLIP_SET_SLIP = 'BETSLIP_SET_SLIP';
export const BETSLIP_ADD_CUSTOMBET_RECOMMENDATION =
    'BETSLIP_ADD_CUSTOMBET_RECOMMENDATION';
export const BETSLIP_ADDED_FROM_URL_STATUS = 'BETSLIP_ADDED_FROM_URL_STATUS';
export const BETSLIP_STATUS_CHANGED_FROM_LINK =
    'BETSLIP_STATUS_CHANGED_FROM_LINK';

/** @typedef {Object} BetModel
 * @property {Number} amount
 * @property {Boolean} banker
 * @property {String|Null} bet
 * @property {Number} betNr
 * @property {String} betText
 * @property {Number} calcOdds
 * @property {String|Null} cleanBetText
 * @property {Boolean} closedCombination
 * @property {String} competition
 * @property {String} error
 * @property {Number} eventId
 * @property {String} eventName
 * @property {String|Null} extraInfo
 * @property {Number} gameId
 * @property {String} groupName
 * @property {String|Null} info
 * @property {String} kickOff
 * @property {String} kickOffDate
 * @property {String} kickOffTime
 * @property {Boolean} live
 * @property {String} marketName
 * @property {String} marketTypeGroupName
 * @property {String} marketTypeName
 * @property {String|Null} mtsRef
 * @property {Number} odds
 * @property {String} oddsFraction
 * @property {String} outcome
 * @property {String|Null} result
 * @property {Number} sportId
 * @property {String} sportName
 * @property {Number} status
 */
