import {
    TRACKING_EVENT_PENDING,
    TRACKING_EVENT_SUCCESS,
    TRACKING_EVENT_ERROR,
    TRACKING_EVENT_CLICK,
    TRACKING_EVENT_IMPRESSION,
} from 'constants/vaix/trackingEvents';
const initialState = {
    clicks: [],
    impressions: [],
    pending: false,
    error: null,
};
export default function trackingEvents(state = initialState, action) {
    switch (action.type) {
        case TRACKING_EVENT_PENDING:
            return {
                ...state,
                pending: true,
                error: null,
            };
        case TRACKING_EVENT_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
            };
        case TRACKING_EVENT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        case TRACKING_EVENT_CLICK:
            return {
                ...state,
                clicks: [...state.clicks, action.payload],
            };
        case TRACKING_EVENT_IMPRESSION:
            return {
                ...state,
                impressions: [...state.impressions, action.payload],
            };
        default:
            return state;
    }
}
