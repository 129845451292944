import seoSportsList from 'constants/seoSportsList';

const SERVERS = {
    'bet25.dk': 'prod',
    'bet25test.dk': 'test',
    'bet25dev.dk': 'dev',
    'bet25dev2.dk': 'dev2',
    'bet25dev3.dk': 'dev3',
    localhost: 'dev',
    'bs-local.com': 'dev',
};

const API_PATHS = {
    prod: 'https://api.bet25.dk',
    test: 'https://api.bet25test.dk',
    dev: 'https://api.bet25dev.dk',
    dev2: 'https://api.bet25dev2.dk',
    dev3: 'https://api.bet25dev3.dk',
};

const BASE_CMS_PATH = {
    prod: 'https://cms.bet25.dk',
    test: 'https://cms.bet25.dk',
    dev: 'https://cms.bet25dev.dk',
    dev2: 'https://cms.bet25dev2.dk',
    dev3: 'https://cms.bet25dev3.dk',
};

const BASE_CMS_PATH_SPIL_ID = {
    prod: 'https://cms.retail.25syv.dk',
    test: 'https://cms.retail.25syv.dk',
    dev: 'https://cms-3.bet25dev.dk',
    dev2: 'https://cms-3.bet25dev.dk',
    dev3: 'https://cms-3.bet25dev.dk',
};

const initConfig = () => {
    let host = window.location.hostname;
    let env = SERVERS[host];

    window.config = {
        ENV: SERVERS[host],
        baseAPIPath: API_PATHS[env],
        baseCmsPath: BASE_CMS_PATH[env],
        baseCmsPathSpilID: BASE_CMS_PATH_SPIL_ID[env],
        staging: window.location.hostname.indexOf('bet25test.dk') > -1,
        terminal: {
            showVirtuals: true,
        },
        firebaseDevel:
            window.location.hostname.indexOf('bet25dev.dk') > -1 ||
            window.location.hostname.indexOf('localhost') > -1 ||
            window.location.hostname.indexOf('bs-local.com') > -1,
        seoSports: seoSportsList,
        tutorialModal: false,
        WC2018Mode: false,
    };
};

export default initConfig;
