import {
    LIVE_EVENT_ADD,
    LIVE_EVENT_ANIMATION_STATUS,
    LIVE_EVENT_MERGE_MARKET_LIST,
    LIVE_EVENT_REMOVE,
    LIVE_EVENT_ADD_FAVORITE,
    LIVE_EVENTS_VAIX_FAVORITE_ADD,
    LIVE_EVENTS_VAIX_POPULAR_ADD,
    LIVE_EVENTS_VAIX_POPULAR_PENDING,
    LIVE_EVENTS_VAIX_POPULAR_FETCHED,
    LIVE_EVENTS_VAIX_POPULAR_ERROR,
    LIVE_EVENT_SET,
    LIVE_EVENT_UPDATE,
    LIVE_EVENT_UPDATE_MAIN_MARKET,
    LIVE_EVENT_UPDATE_DATA_WITH_VAIX_SORTING,
    LIVE_EVENT_UPDATE_DATA,
    LIVE_EVENTS_FETCH_ERROR,
    LIVE_EVENTS_FETCH_PENDING,
    LIVE_EVENTS_FETCH_SUCCESS,
    LIVE_EVENTS_VAIX_FAVORITE_FETCHED,
} from 'constants/liveEvents';

import { ParsproService } from 'utils/ParsproService';

import TerminalRestService from 'utils/TerminalRestService';
import { isTerminal } from 'utils/UiHelper';
import VAIXService from '../utils/VAIXService';
import {
    getArrayOfNumbersFromStringInArrayOfObjects,
    sortEventsByVaixPriority,
} from '../utils/Helper';

export function getLiveEvents() {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENTS_FETCH_PENDING,
            payload: true,
        });

        if (!isTerminal()) {
            ParsproService.getLiveEvents()
                .then((res) => {
                    const data = res.data;
                    let obj = {};
                    let eventSport = {};

                    data.filter((item) => {
                        return item.live === true;
                    }).map((item) => {
                        if (!obj[item.sportId]) {
                            obj[item.sportId] = {};
                        }

                        obj[item.sportId][item.eventId] = item;
                        eventSport[item.eventId] = item.sportId;

                        return item;
                    });

                    dispatch({
                        type: LIVE_EVENTS_FETCH_SUCCESS,
                        payload: {
                            data: obj,
                            eventSport,
                            array: data,
                        },
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: LIVE_EVENTS_FETCH_ERROR,
                        payload: error.errorMessage,
                    });
                });
        } else {
            let getLive = () => {
                TerminalRestService.getLiveEvents((data) => {
                    let obj = {};
                    let eventSport = {};

                    data.filter((item) => item.liveScore).map((item) => {
                        if (item.liveScore) {
                            if (!obj[item.sportId]) {
                                obj[item.sportId] = {};
                            }

                            obj[item.sportId][item.eventId] = item;
                            eventSport[item.eventId] = item.sportId;
                        }

                        return item;
                    });

                    dispatch({
                        type: LIVE_EVENTS_FETCH_SUCCESS,
                        payload: {
                            data: obj,
                            array: data,
                            eventSport,
                        },
                    });
                });
            };

            if (window.liveTimer) {
                clearInterval(window.liveTimer);
            }

            window.liveTimer = setInterval(() => {
                getLive();
            }, 60000);

            getLive();
        }
    };
}

export function updateLiveDataWithVaixSorting(status) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENT_UPDATE_DATA_WITH_VAIX_SORTING,
            payload: status,
        });
    };
}

export function updateLiveData(status) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENT_UPDATE_DATA,
            payload: status,
        });
    };
}

export function addEvent(data) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENT_ADD,
            payload: data,
        });
    };
}

export function setEvent(data) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENTS_VAIX_POPULAR_FETCHED,
            payload: false,
        });
        if (
            data.liveScore &&
            data.liveScore.status &&
            !data.liveScore.status.includes('ended') &&
            !data.liveScore.status.includes('interrupted') &&
            !data.liveScore.status.includes('not_started')
        ) {
            dispatch({
                type: LIVE_EVENT_SET,
                payload: data,
            });
        }
    };
}

export function updateEvent(data) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENT_UPDATE,
            payload: data,
        });
    };
}

export function addVaixLiveFavoriteEvents(eventsIds) {
    return (dispatch) => {
        ParsproService.getEventsByIds(eventsIds.join(','), 2).then((res) => {
            const { data } = res;
            if (data) {
                dispatch({
                    type: LIVE_EVENTS_VAIX_FAVORITE_ADD,
                    payload: {
                        data,
                        eventsIds,
                    },
                });
            }
        });
    };
}

export function updateMainMarket(data) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENT_UPDATE_MAIN_MARKET,
            payload: data,
        });
    };
}

export function removeEvent(id) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENT_REMOVE,
            payload: id,
        });
    };
}

export function addFavorite(id) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENT_ADD_FAVORITE,
            payload: id,
        });
    };
}

export function animationStatus(status) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENT_ANIMATION_STATUS,
            payload: status,
        });
    };
}

export function mergeMarkets(eventId, marketList) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENT_MERGE_MARKET_LIST,
            payload: {
                eventId,
                marketList,
            },
        });
    };
}

export function setLiveVaixPopularFetchedStatus(data) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENTS_VAIX_POPULAR_FETCHED,
            payload: data,
        });
    };
}

export function setLiveVaixFavoriteFetchedStatus(data) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENTS_VAIX_FAVORITE_FETCHED,
            payload: data,
        });
    };
}
//getVaixPopularLiveEvents
export function getLivePopularVaixEvents(userId = 0) {
    return (dispatch) => {
        dispatch({
            type: LIVE_EVENTS_VAIX_POPULAR_PENDING,
            payload: true,
        });
        dispatch({
            type: LIVE_EVENTS_VAIX_POPULAR_FETCHED,
            payload: false,
        });

        VAIXService.getVaixPopularLiveEvents(userId)
            .then((res) => {
                const { data } = res;
                if (data) {
                    data.filter((item) => item.status === 'live');
                    let sortedData = sortEventsByVaixPriority(data);
                    let eventsIds = getArrayOfNumbersFromStringInArrayOfObjects(
                        sortedData,
                        'event_id'
                    );
                    ParsproService.getEventsByIds(eventsIds.join(','), 2)
                        .then((res) => {
                            const { data } = res;
                            if (data) {
                                dispatch({
                                    type: LIVE_EVENTS_VAIX_POPULAR_ADD,
                                    payload: {
                                        data,
                                        eventsIds,
                                    },
                                });
                            } else {
                                dispatch({
                                    type: LIVE_EVENTS_VAIX_POPULAR_FETCHED,
                                    payload: true,
                                });
                            }
                        })
                        .catch((error) => {
                            dispatch({
                                type: LIVE_EVENTS_VAIX_POPULAR_ERROR,
                                payload: error.errorMessage,
                            });
                        });
                } else {
                    dispatch({
                        type: LIVE_EVENTS_VAIX_POPULAR_PENDING,
                        payload: false,
                    });
                    dispatch({
                        type: LIVE_EVENTS_VAIX_POPULAR_FETCHED,
                        payload: true,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: LIVE_EVENTS_VAIX_POPULAR_PENDING,
                    payload: false,
                });
                dispatch({
                    type: LIVE_EVENTS_VAIX_POPULAR_FETCHED,
                    payload: true,
                });
                dispatch({
                    type: LIVE_EVENTS_VAIX_POPULAR_ERROR,
                    payload: error.errorMessage,
                });
            });
    };
}
