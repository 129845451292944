import React from 'react';
import Button from 'ui/Button';
import { isTerminal, isToken } from 'utils/UiHelper';
import { applyCoupon } from 'actions/TerminalSessionActions';
import {
    BigWinErrorMessageWrapper,
    WarningIcon,
} from 'features/Betslip/styled';
import Text from 'ui/Text';
import { POOL_DETAILS_SLUG } from 'features/SpilKlub/screens/useScreens';

export const resultConstants = {
    home: '1',
    tie: 'X',
    away: '2',
    over: 'O',
    under: 'U',
    cancelled: 'cancelled',
};

export const prepareHistoryItems = (
    sale = {},
    outcomesArray = [],
    outcomesIndex = 0
) => {
    let selections = sale?.selections ? sale.selections : sale.saleList;

    return selections.map((selection, key) => {
        const results = selection?.result
            ? selection.result
                  .split(',')
                  .map((res) => resultConstants[res])
                  .filter((res) => res)
            : '';

        outcomesArray[outcomesIndex] = {
            ...outcomesArray[outcomesIndex],
            [selection?.nr || key + 1]: {
                marks: selection?.marks.map((mark) => mark.split('/')).flat(1),
                results,
            },
        };
        return {
            nr: selection?.nr || key + 1,
            name:
                selection?.nameShort ||
                selection?.eventName ||
                selection?.abbreviation ||
                '',
        };
    });
};

export const onClickHandler = (
    sale,
    setScreen = () => {},
    selectedCoupons,
    setSelectedCoupons = () => {},
    index
) => {
    {
        return sale?.couponId
            ? setSelectedCoupons({
                  ...selectedCoupons,
                  [index]: !selectedCoupons[index],
              })
            : sale?.betGroupInfo?.poolHash
                ? setScreen(POOL_DETAILS_SLUG, sale.betGroupInfo.poolHash)
                : true;
    }
};

export const prepareStatus = (status) => {
    switch (status) {
        case 2:
        case '2':
        case 'won':
            return {
                border: '#18AA1B',
                icon: <i className="fa fa-check 10" aria-hidden="true" />,
                circleStatus: 'won',
            };
        case 3:
        case '3':
        case 'lost':
            return {
                border: '#E24E4E',
                icon: <i className="fa fa-times" aria-hidden="true" />,
                circleStatus: 'lost',
            };
        default:
            return {
                border: '#B8C3D6',
                icon: null,
                circleStatus: '',
            };
    }
};

export const getTitleByStatusCoupon = (coupon) => {
    let status = '';

    if (coupon.paidout === 'yes ') {
        status = 'paidout';
    } else {
        status = coupon.status;
    }

    switch (status) {
        case 'won':
            return 'Tillykke med gevinsten!';
        case 'lost':
            return 'Kuponen er desværre tabt';
        case 'pending':
            return 'Endnu ikke afgjort';
        case 'active':
            return 'Endnu ikke afgjort';
        case 'cancelled':
            return 'Kuponen er blevet annulleret';
        case 'paidout':
            return 'Tillykke med gevinsten!';
        default:
            return 'Mine væddemål';
    }
};

export const renderCommandButtons = (
    coupon,
    checkInByCoupon = () => {},
    onClosePopupHandler = () => {}
) => {
    let token = window.localStorage.getItem('token');

    if (coupon.status === 'won') {
        return (
            <div className="mv15 row row-buttons">
                <Button
                    fontSize="18px"
                    width="250px"
                    active
                    margin="10px auto"
                    onClick={() => {
                        if (isToken()) {
                            applyCoupon(token);
                        } else {
                            checkInByCoupon();
                        }
                    }}
                >
                    OVERFØR TIL TERMINAL
                </Button>
            </div>
        );
    } else {
        return (
            <div className="mv15 row-buttons">
                <Button
                    fontSize="18px"
                    width="250px"
                    active
                    margin="10px auto"
                    onClick={() => {
                        onClosePopupHandler('coupon');
                    }}
                >
                    Luk
                </Button>
            </div>
        );
    }
};

export const showLimit = (coupon) => {
    if (coupon?.win_amount >= 1500000 && coupon?.status === 'won') {
        return (
            <BigWinErrorMessageWrapper>
                <WarningIcon>
                    <Text
                        condensed
                        size="x15"
                        color="#FBF5E5"
                        padding="0"
                        align="center"
                    >
                        !
                    </Text>
                </WarningIcon>
                <Text condensed size="x1" color="#333333" padding="0">
                    Gevinster over 15.000,00 kr. kan ikke udbetales til
                    terminalen. Henvend dig ved kassen for udbetaling.
                </Text>
            </BigWinErrorMessageWrapper>
            // <Text size="8" color="#333" center>
            //     Gevinster over 14.999kr skal udbetales ved personalet og
            //     kræver fremvisning af ID oplysninger
            // </Text>
        );
    }
};

export const prepareAmountBet = (sale, isCouponPlaced = true) =>
    sale.betGroupInfo
        ? isCouponPlaced
            ? sale.betGroupInfo.userBetAmount
            : sale.betGroupInfo.userAmount
        : isTerminal()
            ? sale.sale_amount / 100
            : sale.amount;

export const prepareAmountWin = (sale) =>
    sale.betGroupInfo
        ? sale.amount
        : isTerminal()
            ? sale.win_amount / 100
            : sale.amountWin;
