import React, { Component } from 'react';
import { ViewLinkButton } from 'components/ViewButton';
import '../css/ConfirmPopup.css';

export default class ConfirmPopup extends Component {
    render() {
        return (
            <div className="confirm-popup">
                <div className="background-wrapper" />
                <div className="confirm-popup-wrapper">
                    <div className="question-wrapper">
                        {this.props.modalData.question}
                    </div>
                    <div className="row buttons-wrapper">
                        <div className="col-5">
                            <ViewLinkButton
                                onClickHandler={this.props.modalData.onClickYes}
                            >
                                {this.props.modalData.yesLabel
                                    ? this.props.modalData.yesLabel
                                    : 'Yes'}
                            </ViewLinkButton>
                        </div>
                        <div className="col-5">
                            <ViewLinkButton
                                onClickHandler={this.props.modalData.onClickNo}
                            >
                                {this.props.modalData.noLabel
                                    ? this.props.modalData.noLabel
                                    : 'No'}
                            </ViewLinkButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
