import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../themes/colors';
import { fadeInMixin } from '../../ui/animations/FadeIn2';
import PlacesAutocomplete from '../../ui/PlacesAutocomplete';

export const InputWrapper = styled.div`
    padding-top: 20px;
`;

export const InputLabel = styled.div`
    color: ${COLORS.gray};
    font-size: 14px;
    line-height: 19px;
    padding: 5px;
    font-weight: 400;
`;

const InputContainer = styled.div`
    height: auto;
    width: 100%;
    position: relative;
`;

export const InputNative = styled.input`
    background: ${COLORS.lightGray};
    color: ${COLORS.darkGray};
    border-radius: 5px;
    border: 1px solid #c6ccdb;
    box-sizing: border-box;
    font-size: 16px;
    padding: 0 45px 0 20px;
    font-weight: 400;
`;

export const EditButton = styled.div`
    position: absolute;
    font-size: 14px;
    color: ${COLORS.blue};
    height: auto;
    line-height: 100%;
    top: 20px;
    right: 0;
    font-weight: 400;
    padding: 0 20px;
`;

export const ValidationMessage = styled(InputLabel)`
    padding: 5px 20px 0;
    font-size: 14px;
    font-weight: bold;
    color: ${COLORS.red};
    ${fadeInMixin};
`;

const SuggestionItem = styled.div`
    cursor: pointer;
    padding: 10px 20px;
    &:hover,
    &.active {
        background: #eceff6;
    }
    &:not(:last-child) {
        border-bottom: 1px solid #eceff6;
    }
`;
const SuggestionsWrapper = styled.div`
    border: 1px solid #eceff6;
    margin-top: 5px;
    border-radius: 5px;
    color: #333333;
    font-size: 16px;
`;

const Input = ({
    label,
    value,
    onChange,
    style,
    placeholder,
    onEdit = () => {},
    inputRef,
    error,
}) => (
    <InputWrapper>
        <InputLabel>{label}</InputLabel>
        <InputContainer>
            <InputNative
                value={value ? value : ''}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder}
                style={style}
                ref={inputRef}
                autoComplete="none"
            />
            <EditButton onClick={onEdit}>Ret</EditButton>
            {error ? <ValidationMessage>{error}</ValidationMessage> : null}
        </InputContainer>
    </InputWrapper>
);

export const AddressSelect = ({
    value,
    onChange,
    label,
    style,
    placeholder,
    onEdit,
    inputRef,
    error,
    setZipCode,
    setCity,
}) => {
    const handleAddressSelect = (address, placeId, suggestion) => {
        const { city, zipCode } = suggestion;

        let pureAddress = address
            .replace(zipCode, '')
            .replace(city, '')
            .trim();

        if (pureAddress.slice(-1) === ',') {
            pureAddress = pureAddress.slice(0, -1);
        }

        onChange(address);
        setZipCode(zipCode);
        setCity(city);
    };

    return (
        <PlacesAutocomplete
            value={value}
            onChange={onChange}
            onSelect={handleAddressSelect}
            searchOptions={{}}
            debounce={1000}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                triggerInput,
            }) => (
                <>
                    <Input
                        {...getInputProps({
                            label,
                            style,
                            placeholder,
                            onEdit,
                            inputRef,
                            error,
                        })}
                    />
                    {suggestions.length ? (
                        <SuggestionsWrapper>
                            {suggestions.map((suggestion, index) => {
                                return (
                                    <SuggestionItem
                                        key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                            key: index,
                                        })}
                                    >
                                        {suggestion.text}
                                    </SuggestionItem>
                                );
                            })}
                        </SuggestionsWrapper>
                    ) : null}
                </>
            )}
        </PlacesAutocomplete>
    );
};

export default Input;
