import React, { useEffect } from 'react';
import {
    NotificationMessageIconWrapper,
    NotificationMessageWrapper,
} from './styled';
import Text from '../../ui/Text';
import Icon from '../../ecoui/Icon';
import closeIcon from 'images/close-icon.svg';

const NotificationMessage = ({
    message = null,
    fs = '14',
    lh = '14',
    padding,
    margin,
    textColor = '#2C3036',
    date = null,
    showCloseIcon = false,
    position = 'bottom',
    brakeMessage = false,
    closeNotification = () => {},
}) => {
    useEffect(() => {
        console.log('opened');
    }, []);

    return (
        <NotificationMessageWrapper className={position}>
            <Text
                margin={margin || '0'}
                padding={padding || '0'}
                size={fs || '14px'}
                lh={lh || '14px'}
                color={textColor || '#333'}
                overflow={brakeMessage ? 'none' : 'hidden'}
                whiteSpace={brakeMessage ? 'normal' : 'nowrap'}
            >
                Velkommen tilbage. Du var sidst logget ind <b>{`${date}`}</b>
            </Text>
            {showCloseIcon && (
                <NotificationMessageIconWrapper onClick={closeNotification}>
                    <Icon
                        name="close"
                        width={'13px'}
                        height={'13px'}
                        color={'black'}
                        src={closeIcon}
                    />
                </NotificationMessageIconWrapper>
            )}
        </NotificationMessageWrapper>
    );
};

export default NotificationMessage;
