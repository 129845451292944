import React from 'react';
import styled from 'styled-components';
import Button from 'ui/Button';

const Wrapper = styled.div`
    width: 400px;
    max-width: 80%;
    height: 300px;
    max-height: 80%;
    border-radius: 5px;
    background: #fff;
    margin: 0 auto;
    position: fixed;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
`;

const PinAdvicePopup = (props) => {
    return (
        <Wrapper>
            <div className="quick-login-popup">
                <div className="title">
                    Log hurtigere ind med en 4-cifret PIN kode
                </div>
                <div className="control-wrapper">
                    <Button
                        active
                        margin="5px auto"
                        width="200px"
                        onClick={() => {
                            props.showModal('PIN_LOGIN', {
                                mode: 'setup',
                            });
                            props.hideModal('PIN_ADVICE_POPUP');
                        }}
                        theme="orange"
                    >
                        OPRET PIN
                    </Button>
                    <Button
                        width="200px"
                        margin="10px auto"
                        onClick={() => {
                            props.hideModal('PIN_ADVICE_POPUP');
                        }}
                    >
                        IKKE NU
                    </Button>
                </div>
            </div>
        </Wrapper>
    );
};

export default PinAdvicePopup;
