import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    FetchRetailSalesHistory,
    FetchMoreRetailSalesHistory,
    ResetRetailSalesHistory,
} from 'actions/RetailSalesHistoryActions';
import Spinner from '../../ui/Spinner';
import Big9History from '../Big9History';
import VirtualTransfer from '../VirtualTransfer';
import SportTransfer from '../SportTransfer';
import Button from '../../ui/Button';
import Lottie from 'lottie-react';
import NoItemsLottie from './../../lottie/no_items.json';
import Wrapper from '../../ui/Wrapper';
import Text from '../../ui/Text';
import { detectMobile } from '../../utils/UiHelper';

const RetailSalesHistory = ({
    retailSalesHistory,
    fetchRetailSalesHistory,
    fetchMoreRetailSalesHistory,
    resetRetailSalesHistory,
}) => {
    const [page, setPage] = React.useState(2);

    useEffect(() => {
        !retailSalesHistory.data?.salesList &&
            fetchRetailSalesHistory() &&
            setPage(page + 1);
        return () => {
            resetRetailSalesHistory();
        };
    }, []);

    const fetchMore = () => {
        fetchMoreRetailSalesHistory(page);
        setPage(page + 1);
    };

    const getContent = (sales) => {
        return sales.map((item, index) => {
            if (item.product === 'B9') {
                return (
                    <Big9History
                        key={`${index}___item__${item.product}`}
                        retailSails={item}
                    />
                );
            } else if (item.product === 'VE') {
                return (
                    <VirtualTransfer
                        data={[item]}
                        key={`${index}___item__${item.product}`}
                    />
                );
            } else if (item.product === 'BE') {
                return (
                    <SportTransfer
                        data={[item]}
                        key={`${index}___item__${item.product}`}
                    />
                );
            } else {
                return null;
            }
        });
    };

    return (
        <Wrapper
            padding={detectMobile() ? '0' : '0 0 30px'}
            margin={'0'}
            width={'100%'}
            height={'auto'}
            boxSizing={'border-box'}
        >
            {!detectMobile() && (
                <Text
                    size={'x2'}
                    padding={'0'}
                    margin={'30px auto 0'}
                    display={'block'}
                    color="#333"
                    align={'center'}
                    bold
                >
                    Mine væddemål
                </Text>
            )}
            {retailSalesHistory.data?.salesList &&
                getContent(retailSalesHistory.data.salesList)}
            {retailSalesHistory.data?.salesList?.length === 0 &&
                !retailSalesHistory.pending && (
                    <Wrapper
                        width={'100%'}
                        height={'auto'}
                        padding={'0'}
                        margin={'0'}
                        flex
                        flexDirection={'column'}
                        justify={'center'}
                        flexAlign={'center'}
                        boxSizing={'border-box'}
                    >
                        <Lottie
                            animationData={NoItemsLottie}
                            loop={true}
                            style={{
                                width: '100px',
                                height: '100px',
                                display: 'block',
                                margin: '42px auto 0',
                            }}
                        />
                        <Text
                            color={'#818181'}
                            align={'center'}
                            size={'16'}
                            lh={'21'}
                        >
                            Ingen spil fundet
                        </Text>
                    </Wrapper>
                )}

            {retailSalesHistory.data?.salesList &&
                retailSalesHistory.data?.salesList.length <
                    retailSalesHistory.data?.count && (
                    <Button
                        onClick={fetchMore}
                        margin={detectMobile() ? '20px auto 0' : '30px auto 0'}
                        fontSize="14px"
                        fontWeight="bold"
                        padding="14px 30px"
                        width="260px"
                        color={'#333333'}
                        active
                    >
                        Vis mere
                    </Button>
                )}

            {retailSalesHistory.pending ? <Spinner /> : null}
        </Wrapper>
    );
};

function mapStateToProps(state) {
    return {
        retailSalesHistory: state.retailSalesHistory,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchRetailSalesHistory: bindActionCreators(
            FetchRetailSalesHistory,
            dispatch
        ),
        fetchMoreRetailSalesHistory: bindActionCreators(
            FetchMoreRetailSalesHistory,
            dispatch
        ),
        resetRetailSalesHistory: bindActionCreators(
            ResetRetailSalesHistory,
            dispatch
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RetailSalesHistory);
