import { REST_SPORTS } from 'constants/appConfig';
import { axiosRequest } from 'utils/REST';
import { addEveryValueFromArrayToString } from './Helper';

let ParsproService = {};

const request = (requestType, url, data) => {
    return axiosRequest(requestType, url, data).then(
        (response) => response.data
    );
};

const get = (method, data) => request('get', REST_SPORTS + method, data);

const prepareIdsString = (sports) => {
    let ids = [];
    sports.forEach((sport) => {
        ids.push(sport.id);
    });
    return ids.join(':');
};

ParsproService = {
    getCompList: () => get('compList'),
    getLiveEvents: () => get('events/liveMainEx'),
    getPreEvents: () => get(' events'),
    getTodayEvents: (sports) => {
        if (sports && sports.length) {
            const ids = prepareIdsString(sports);
            return get(`events/sportIds/${ids}?hours=12`);
        }
        return get(`events/hours/12`);
    },
    getLivePopularEvents: () => get('events/list/live/popularLive'),
    getPopularEvents: () => get('events/list/popular'),
    getEventById: (id) => get(`event/${id}?all=1`),
    getEventsByIds: (ids, eventType = 0) => {
        return get(`events/${ids}?eventType=${eventType}`);
    },
    getByGroup: (timeRange, id) => get(`events/groupId/${id}`),
    getByTournament: (timeRange, id) => get(`events/compId/${id}`),
    getEventsByQuery: (query) => get(`events/query/${query}`),
};
export { ParsproService };
