import React from 'react';
import {
    SuccessPopupContainer,
    SuccessPopupFaded,
    SuccessPopupDescription,
} from 'features/SpilID/styled';
import Button from '../../ui/Button';

import CompleteImage from './assets/checkmark.circle.svg';
import Text from '../../ui/Text';
import { COLORS } from '../../themes/colors';

const SuccessPopup = ({ shown, backToSpilIdRoot }) => {
    return (
        <SuccessPopupFaded shown={shown}>
            <SuccessPopupContainer
                shown={shown}
                className="flex flex-col space-between"
            >
                <Text
                    margin={'0'}
                    padding={'0'}
                    align={'center'}
                    size={'20'}
                    lh={'26'}
                    bold
                    color={COLORS.dark}
                >
                    Spil ID kort bestilt
                </Text>

                <SuccessPopupDescription className="flex justify-evenly fade-in">
                    <img src={CompleteImage} alt="circle" />
                    <Text
                        margin={'0'}
                        padding={'0 0 0 20px'}
                        align={'left'}
                        size={'16'}
                        lh={'21'}
                        weight={'400'}
                        color={COLORS.dark}
                    >
                        Dit Spil ID kort er på vej og leveres indenfor 7 dage.
                    </Text>
                </SuccessPopupDescription>

                <Button
                    onClick={backToSpilIdRoot}
                    size="x2"
                    width={'100%'}
                    className="full-width text-bold"
                    active
                    bold
                    textTransform={'uppercase'}
                >
                    OK
                </Button>
            </SuccessPopupContainer>
        </SuccessPopupFaded>
    );
};

export default SuccessPopup;
