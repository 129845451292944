import {
    SET_MARKS,
    SET_COUPON_DATA,
    SET_COUPON_ITEMS,
    CLEAR_MARKS,
    COMPLETE_COUPON,
    VALIDATE_COUPON,
    FORMATTING_MARKS,
    SET_WILDCARD,
    SET_VALID,
    CHANGE_COMBINATION_PRICE,
    SET_COMBINATION_COUNT,
} from './actions';
import { BIG9_EVENT_STATUS } from 'constants/big9Config';

const initialState = {
    initialCouponItems: [],
    couponData: {
        outcomes: {},
        startTime: 0,
        gameType: 'big9',
        extId: '',
        id: 0,
        wildcard: null,
        combinationPrice: 0.5,
        fromLocalStorage: false,
        combinationCount: 0,
    },
    complete: false,
    validatedMarks: {},
    formattedMarks: {},
    validState: {
        status: false,
        message: '',
    },
    maxBetPrice: 60000,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MARKS:
            return {
                ...state,
                couponData: {
                    ...state.couponData,
                    outcomes: {
                        ...state.couponData.outcomes,
                        ...action.payload,
                    },
                },
            };
        case SET_COUPON_DATA:
            return {
                ...state,
                couponData: {
                    ...state.couponData,
                    ...action.payload,
                    outcomes: {
                        ...state.couponData.outcomes,
                        ...action.payload.outcomes,
                    },
                },
            };
        case SET_COUPON_ITEMS:
            return {
                ...state,
                initialCouponItems: action.payload,
            };
        case SET_WILDCARD:
            return {
                ...state,
                couponData: {
                    ...state.couponData,
                    wildcard: action.payload,
                },
            };
        case CLEAR_MARKS:
            return {
                ...state,
                couponData: {
                    ...state.couponData,
                    outcomes: Object.fromEntries(
                        Object.entries(state.couponData.outcomes).filter(
                            (item) =>
                                item[1].status === BIG9_EVENT_STATUS.canceled
                        )
                    ),
                    wildcard: null,
                    combinationPrice: 0.5,
                },
                formattedMarks: {},
            };
        case VALIDATE_COUPON:
            return {
                ...state,
                validatedMarks: {
                    ...state.validatedMarks,
                    ...action.payload,
                },
            };
        case FORMATTING_MARKS:
            return {
                ...state,
                formattedMarks: {
                    ...state.formattedMarks,
                    ...action.payload,
                },
            };
        case SET_VALID:
            return {
                ...state,
                validState: action.payload,
            };
        case COMPLETE_COUPON:
            return {
                ...initialState,
                complete: true,
            };
        case CHANGE_COMBINATION_PRICE:
            return {
                ...state,
                couponData: {
                    ...state.couponData,
                    combinationPrice: action.payload,
                },
            };
        case SET_COMBINATION_COUNT:
            return {
                ...state,
                couponData: {
                    ...state.couponData,
                    combinationCount: action.payload,
                },
            };
        default:
            return state;
    }
};

export default reducer;
