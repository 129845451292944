export const TERMINAL_BETSLIP_ADD_MARKET = 'TERMINAL_BETSLIP_ADD_MARKET';
export const TERMINAL_BETSLIP_REMOVE_MARKET = 'TERMINAL_BETSLIP_REMOVE_MARKET';
export const TERMINAL_BETSLIP_SET_VALUE = 'TERMINAL_BETSLIP_SET_VALUE';
export const TERMINAL_BETSLIP_RESET = 'TERMINAL_BETSLIP_RESET';
export const TERMINAL_BETSLIP_SAVE_UNPAID = 'TERMINAL_BETSLIP_SAVE_UNPAID';
export const TERMINAL_BETSLIP_SET_MARKET = 'TERMINAL_BETSLIP_SET_MARKET';
export const TERMINAL_BETSLIP_UPDATE_MARKET = 'TERMINAL_BETSLIP_UPDATE_MARKET';
export const TERMINAL_BETSLIP_SET_SYSTEM_MARKET =
    'TERMINAL_BETSLIP_SET_SYSTEM_MARKET';
export const TERMINAL_BETSLIP_PROCEED = 'TERMINAL_BETSLIP_PROCEED';
export const TERMINAL_BETSLIP_PENDING = 'TERMINAL_BETSLIP_PENDING';
export const TERMINAL_BETSLIP_SET_LIVE_MARKETS =
    'TERMINAL_BETSLIP_SET_LIVE_MARKETS';
export const TERMINAL_BETSLIP_ITEM_ERROR = 'TERMINAL_BETSLIP_ITEM_ERROR';
export const TERMINAL_BETSLIP_ERROR = 'TERMINAL_BETSLIP_ERROR';
