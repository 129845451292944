import { makeRequest } from 'utils/REST';
import { REST_BIG9 } from 'constants/appConfig';

class Big9Service {
    request(requestType, url, data) {
        return makeRequest(requestType, url, data)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
    }

    post(method, data) {
        return this.request('post', REST_BIG9 + method, data);
    }

    get(method, data) {
        return this.request('get', REST_BIG9 + method, data);
    }

    getOffers() {
        return this.get('content/offers');
    }

    getResults() {
        return this.get('content/results');
    }

    getSlip() {
        return this.get('pam/slip');
    }

    getTitle() {
        return this.get('content/offers');
    }

    updateSelection(number, marks, eventName) {
        return this.post('pam/updateSelection', {
            nr: number,
            marks: marks,
            eventName: eventName,
        });
    }

    // placedPrice chosen price for every bet * 100
    makeBet(exId = '', placedPrice = 1, bets = [], combinationCount) {
        return this.post('pam/buy', {
            offeringId: exId,
            combinationPrice: placedPrice,
            selections: bets,
            combinationCount,
        });
    }

    history(page = 1, limit = 10) {
        return this.get(`pam/coupons/${page}/${limit}`);
    }
}

export default new Big9Service();
