export default {
    _1X2: 67,
    doubleChance: 68,
    correctScore: 69,
    halfTimeFullTime: 70,
    firstGoalScorer: 71,
    winMargin: 72,
    bothTeamsToScore: 73,
    redCard: 74,
    multiGoals: 75,
    homeMultigoal: 76,
    awayMultigoal: 77,
    OU15: 78,
    OU25: 79,
    OU35: 80,
    OU45: 81,
    _1X2GgNg: 82,
    _1X2OU15: 83,
    _1X2OU25: 84,
    doubleChanceGgNg: 85,
    doubleChanceOU15: 86,
    doubleChanceOU25: 87,
    homeOU05: 300,
    homeOU15: 301,
    homeOU25: 302,
    awayOU05: 303,
    awayOU15: 304,
    awayOU25: 305,
    exactGoals: 306,
    firstHalfMultiGoal: 307,
};
