import { REST_CASHOUT } from 'constants/appConfig';
import { makeRequest } from 'utils/REST';

const request = (requestType, url, data) => {
    return makeRequest(requestType, url, data)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
};
const post = (method, data) => {
    return request('post', REST_CASHOUT + method, data);
};

const get = (method, data) => {
    return request('get', REST_CASHOUT + method, data);
};

export default {
    couponList: () => get('couponList'),
    makeCashout: (couponId, amountCashoutPotential) =>
        post(`cashout/${couponId}/${amountCashoutPotential * 100}`),
};
