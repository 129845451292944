export const OVERVIEW_EVENTS_FETCH_BY_GROUP_PENDING =
    'OVERVIEW_EVENTS_FETCH_BY_GROUP_PENDING';
export const OVERVIEW_EVENTS_FETCH_BY_GROUP_SUCCESS =
    'OVERVIEW_EVENTS_FETCH_BY_GROUP_SUCCESS';
export const OVERVIEW_EVENTS_FETCH_BY_GROUP_ERROR =
    'OVERVIEW_EVENTS_FETCH_BY_GROUP_ERROR';
export const OVERVIEW_SEARCH_EVENTS_FETCH_PENDING =
    'OVERVIEW_SEARCH_EVENTS_FETCH_PENDING';
export const OVERVIEW_SEARCH_EVENTS_FETCH_SUCCESS =
    'OVERVIEW_SEARCH_EVENTS_FETCH_SUCCESS';
export const OVERVIEW_SEARCH_EVENTS_FETCH_ERROR =
    'OVERVIEW_SEARCH_EVENTS_FETCH_ERROR';
export const OVERVIEW_SEARCH_EVENTS_CLEAR = 'OVERVIEW_SEARCH_EVENTS_CLEAR';
