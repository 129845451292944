import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Button from 'ui/Button';
import { detectMobile } from 'utils/UiHelper';

const commonDepositList = [200, 400, 600, 800, 1000, 2000];

const Wrapper = styled.div`
    margin: ${detectMobile() ? '0 7px' : '0 10px'};
    padding: ${detectMobile() ? '0 5px' : '0 30px'};
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #f8f8f8;
    z-index: 3;
`;
const StyledButton = styled.div`
    border: 1px solid #86868640;
    border-radius: 5px;
    min-width: ${detectMobile() ? '95px' : '135px'};
    margin: ${detectMobile() ? '5px' : '10px'};
    background: ${({ active }) => (active ? '#1c3e60' : '#fff')};
    min-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ active }) => (active ? '#fff' : '#868686')};
    font-weight: bold;
    cursor: pointer;
`;
const Title = styled.div`
    display: block;
    width: 100%;
    color: #696969;
    font-weight: bold;
    font-size: 11px;
    text-align: center;
    margin-bottom: ${detectMobile() ? '7px' : '23px'};
`;
const DepositWrapper = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const ButtonsWrapper = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`;
const InputWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: ${detectMobile() ? '7px' : '10px'};
    margin-bottom: ${detectMobile() ? '7px' : '10px'};
    width: 100%;
`;
const Input = styled.input`
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    padding: 15px;
    margin: 5px;

    width: ${detectMobile() ? 'calc(100% - 20px)' : '40%'};
    text-align: center;
    box-shadow: none;
    border: 1px solid #86868640;
    color: #696969;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
  &::placeholder {
            color: #868686;
            font-weight: bold!important; 
`;
const BonusesWrapper = styled.div`
    margin: 0 auto;
    width: 300px;
    padding: 0;
    color: #696969;
    font-weight: bold;

    p {
        width: 300px;
        padding-left: 23px;
        line-height: 18px;
        font-size: 10px;
        font-weight: 100;
    }
`;
const BonusTitle = styled.div`
    margin-left: 20px;
    margin-bottom: 7px;
    width: 300px;
`;
const CheckboxWrapper = styled.div`
    width: 20px;
    height: 20px;
    position: relative;
    top: 21px;
    left: -6px;
    background: #fff;
    border: 1px solid #86868640;
    label {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 4px;
        left: 4px;
        cursor: pointer;
        &:after {
            content: '';
            width: 14px;
            height: 14px;
            position: absolute;
            top: -1px;
            left: -1px;
            background: #47db67;
            opacity: 0;
        }
        &:hover::after {
            opacity: 0.35;
        }
    }
`;
const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    visibility: hidden;
    &:checked + label:after {
        opacity: 1;
    }
`;

const isTWA = Boolean(JSON.parse(window.localStorage.getItem('isTWA')));

const ButtonsArea = ({
    amount,
    setAmount,
    onSubmitClick = () => {},
    bonusList = [],
    casinoBonuses = [],
    selectedBonuses = [],
    setSelectedBonuses = () => {},
    minDeposit = 50,
}) => {
    const [displayAmount, setDisplayAmount] = useState('');
    const inputRef = useRef(null);

    const clickHandler = (e) => {
        const depositValue = e.target.innerText.replace(/[^0-9]/g, '');
        setAmount(depositValue);
        setDisplayAmount(`${depositValue} Kr.`);
    };

    const onFocusHandler = (e) => {
        const numericValue = e.target.value.replace(' Kr.', '');
        setDisplayAmount(numericValue);
    };

    const onDepositChangeHandler = (e) => {
        const inputElement = inputRef.current;
        const cursorPosition = inputElement.selectionStart;

        const numericValue = e.target.value.replace(/[^0-9]/g, '');
        setAmount(numericValue);
        setDisplayAmount(numericValue);

        const valueLength = numericValue.length;
        if (cursorPosition <= valueLength) {
            setTimeout(() => {
                inputElement.setSelectionRange(cursorPosition, cursorPosition);
            }, 0);
        }
    };

    const onBlurHandler = (e) => {
        if (e.target.value && !e.target.value.includes(' Kr.')) {
            setDisplayAmount(e.target.value + ' Kr.');
        }
    };

    const setSelectedBonus = (bonus, e) => {
        if (e.target.checked) {
            setSelectedBonuses([...selectedBonuses, bonus]);
        } else {
            setSelectedBonuses([
                selectedBonuses.filter((b) => b.bonusId !== bonus.bonusId),
            ]);
        }
    };

    return (
        <Wrapper>
            <InputWrapper>
                <Input
                    ref={inputRef}
                    value={displayAmount}
                    onFocus={onFocusHandler}
                    onChange={onDepositChangeHandler}
                    onBlur={onBlurHandler}
                    placeholder="Indtast beløb"
                />
            </InputWrapper>
            <Title>Minimum beløb er {minDeposit} kr.</Title>
            {!isTWA && (
                <DepositWrapper>
                    {commonDepositList.map((commonDeposit) => (
                        <StyledButton
                            active={displayAmount === `${commonDeposit} Kr.`}
                            onClick={clickHandler}
                            key={commonDeposit}
                        >
                            {commonDeposit} Kr.
                        </StyledButton>
                    ))}
                </DepositWrapper>
            )}
            <ButtonsWrapper>
                {bonusList && bonusList.length
                    ? bonusList.map((bonus) => (
                          <BonusesWrapper key={bonus.bonusId}>
                              <CheckboxWrapper>
                                  <Checkbox
                                      id={bonus.bonusId}
                                      onChange={(e) => {
                                          setSelectedBonus(bonus, e);
                                      }}
                                  />
                                  <label htmlFor={bonus.bonusId} />
                              </CheckboxWrapper>
                              <BonusTitle>
                                  Ja tak, jeg vil gerne have min bonus:
                              </BonusTitle>
                              <p>
                                  {bonus.pct}% bonus på din indbetaling på Bet25
                                  Sport op til {bonus.maxAmount} kr. i bonus.{' '}
                                  <br />
                                  Minimum indbetaling {bonus.minAmount} kr.
                                  <br /> Indbetaling og bonus skal tilsammen
                                  omsættes {bonus.rollOver} gange til min. odds
                                  1.95, før der kan udbetales.
                              </p>
                          </BonusesWrapper>
                      ))
                    : null}
                {casinoBonuses && casinoBonuses.length
                    ? casinoBonuses.map((bonus) => (
                          <BonusesWrapper key={bonus.bonusId}>
                              <CheckboxWrapper>
                                  <Checkbox
                                      id={bonus.bonusId}
                                      onChange={(e) => {
                                          setSelectedBonus(bonus, e);
                                      }}
                                  />
                                  <label htmlFor={bonus.bonusId} />
                              </CheckboxWrapper>
                              <BonusTitle>
                                  Ja tak, jeg vil gerne have min bonus:
                              </BonusTitle>
                              <p>
                                  {bonus.pct}% bonus på din første indbetaling
                                  på Bet25 Casino op til {bonus.maxAmount} kr. i
                                  bonus. <br />
                                  Minimum indbetaling {bonus.minAmount} kr.
                                  <br /> Indbetaling og bonus skal tilsammen
                                  omsættes {bonus.rollOver} gange til min. odds
                                  1.95, før der kan udbetales.
                              </p>
                          </BonusesWrapper>
                      ))
                    : null}
            </ButtonsWrapper>
            <ButtonsWrapper>
                {
                    <Button
                        padding="15px 80px"
                        width="200px"
                        active={Number(amount) >= minDeposit}
                        disabled={Number(amount) < minDeposit}
                        onClick={onSubmitClick}
                    >
                        NÆSTE
                    </Button>
                }
            </ButtonsWrapper>
        </Wrapper>
    );
};

export default ButtonsArea;
