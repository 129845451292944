export const TERMINAL_VIRTUAL_BETSLIP_ADD_MARKET =
    'TERMINAL_VIRTUAL_BETSLIP_ADD_MARKET';
export const TERMINAL_VIRTUAL_BETSLIP_REMOVE_MARKET =
    'TERMINAL_VIRTUAL_BETSLIP_REMOVE_MARKET';
export const TERMINAL_VIRTUAL_BETSLIP_SET_VALUE =
    'TERMINAL_VIRTUAL_BETSLIP_SET_VALUE';
export const TERMINAL_VIRTUAL_BETSLIP_RESET = 'TERMINAL_VIRTUAL_BETSLIP_RESET';
export const TERMINAL_VIRTUAL_BETSLIP_SET_MARKET =
    'TERMINAL_VIRTUAL_BETSLIP_SET_MARKET';
export const TERMINAL_VIRTUAL_BETSLIP_UPDATE_MARKET =
    'TERMINAL_VIRTUAL_BETSLIP_UPDATE_MARKET';
export const TERMINAL_VIRTUAL_BETSLIP_SET_SYSTEM_MARKET =
    'TERMINAL_VIRTUAL_BETSLIP_SET_SYSTEM_MARKET';
export const TERMINAL_VIRTUAL_BETSLIP_PROCEED =
    'TERMINAL_VIRTUAL_BETSLIP_PROCEED';
export const TERMINAL_VIRTUAL_BETSLIP_PENDING =
    'TERMINAL_VIRTUAL_BETSLIP_PENDING';
