export const DIBS_OPERATION_DEPOSIT = 'deposit';
export const DIBS_CLIENT_NAME = 'bet25';
export const DIBS_PMNT = 'Sport';

const API_PATH = window.config.baseAPIPath;
const ORIGIN_HOST = window.location.origin;

export const DIBS_EXCHANGE_DK = 'DKK';
export const DIBS_PAYMENT_URL = API_PATH + '/punter/payment';
export const DIBS_ACCEPT_RETURN_URL = API_PATH + '/punter/payment';
export const DIBS_CANCEL_RETURN_URL = API_PATH + '/punter/payment';
export const DIBS_SUCCESS_URL = ORIGIN_HOST + '/sport/deposit/success';
export const DIBS_ERROR_URL = ORIGIN_HOST + '/sport/deposit/error';

export const fundErrors = {
    LOGINFIRST: 'Du skal logge ind først',
    DEPOSIT_CANCEL: 'Indbetaling annulleret',
    DEPOSIT_INTERNALERROR:
        'Fejl - prøv igen og kontakt kundesupport hvis problemet fortsætter',
    PAYMENT_ERROR:
        'Fejl - prøv igen og kontakt kundesupport hvis problemet fortsætter',
    DEPOSIT_DECLINED_DKK_TEMP_MAX: 'Du har overskredet din maximum indbetaling',
    DEPOSIT_PPT_DECLINED: 'Indbetaling afvist',
    DEPOSIT_PPT_INTERNAL:
        'Systemfejl - prøv igen og kontakt kundesupport hvis problemet fortsætter',
    DEPOSIT_PPT_DAYMAX:
        'Du har overskredet den maksimale daglige indskudsgrænse',
    DEPOSIT_PPT_WEEKMAX:
        'Du har overskredet din ugentlige maximum udbetalingsgrænse',
    DEPOSIT_PPT_MONTHMAX:
        'Du har overskredet den maksimale månedlige indbetalingsgrænse',
};
