import axios from 'axios';
import $ from 'jquery';
import { get, has } from 'lodash';

class TerminalRestService {
    cache = {};
    // pprest = window.PP_REST_URL;
    receiptEndpoints = {
        D: '/cafe/rest/derby/',
        W: '/cafe/rest/virtual-coupon/',
        C: '/cafe/rest/coupon/',
        T: '/cafe/rest/trident/',
        S: '/cafe/rest/sale/',
        B: '/cafe/rest/big-nine/',
    };

    verifySpilId(hash) {
        return $.ajax({
            url: window.API_PATH + '/cafe/rest/game-card/get-card-terminal',
            data: {
                hash,
                hostname: window.localStorage.getItem('printerHost'),
            },
            type: 'post',
        });
    }

    newUnpaidSale(data, cb) {
        $.ajax({
            url: window.API_PATH + '/cafe/rest/unpaid-sale/new-unpaid-sale',
            cache: false,
            data,
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    getUnpaidSaleInfo(hash, cb) {
        $.ajax({
            url: window.API_PATH + '/cafe/rest/unpaid-sale/get-unpaid-sale',
            cache: false,
            data: { hash },
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    checkIn(hash, terminalId, cb) {
        $.ajax({
            url: window.API_PATH + '/cafe/rest/terminal/check-in',
            cache: false,
            data: {
                hash: hash.toString().trim(),
                terminal_id: terminalId,
            },
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    checkInTele(hostname, terminalId, cb) {
        $.ajax({
            url: window.API_PATH + '/cafe/rest/terminal/tele-check-in',
            cache: false,
            data: {
                hostname: hostname,
                terminal_id: terminalId,
            },
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    checkInByCoupon(hash, terminalId, cb) {
        $.ajax({
            url: window.API_PATH + '/cafe/rest/terminal/check-in-by-coupon',
            cache: false,
            data: {
                hash: hash.toString().trim(),
                terminal_id: terminalId,
            },
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    checkInBySale(hash, terminalId, cb) {
        $.ajax({
            url: window.API_PATH + '/cafe/rest/terminal/check-in-by-sale',
            cache: false,
            data: {
                hash: hash.toString().trim(),
                terminal_id: terminalId,
            },
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    checkOut(token, cb) {
        $.ajax({
            url: window.API_PATH + '/cafe/rest/terminal/check-out',
            data: {
                session_id: token,
                hostname: window.localStorage.getItem('printerHost'),
            },
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    checkSession(token, cb) {
        $.ajax({
            url: window.API_PATH + '/cafe/rest/terminal/get-session',
            data: {
                session_id: token,
            },
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    initTerminal(cb) {
        $.ajax({
            url: window.API_PATH + '/cafe/rest/terminal/init-terminal',
            data: {
                hostname: window.localStorage.getItem('printerHost'),
            },
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    applyVoucher(token, hash, cb) {
        $.ajax({
            url: window.API_PATH + '/cafe/rest/voucher/apply-voucher',
            data: {
                session_id: token,
                hash: hash.toString().trim(),
            },
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    newSale(betModel, isAcceptedBetslipChanges) {
        return $.post(window.API_PATH + '/cafe/rest/sale/new-sale', {
            data: betModel,
            hostname: window.localStorage.getItem('printerHost'),
            anyOdds: isAcceptedBetslipChanges,
        });
    }

    newVirtualSale(betModel) {
        return $.ajax({
            url: window.API_PATH + '/cafe/rest/trident/new-sale', //'/cafe/rest/virtual-sale/new-sale',
            data: {
                data: betModel,
                hostname: window.localStorage.getItem('printerHost'),
            },
            type: 'post',
        });
    }

    /** new method to cover all types of coupon (W, D, C, T, B) */
    fetchCoupon(prefix, hash) {
        const url =
            window.API_PATH + this.receiptEndpoints[prefix] + 'get-coupon';
        return $.ajax({
            url,
            data: {
                hash: hash.trim(),
                hostname: window.localStorage.getItem('printerHost').trim(),
            },
            type: 'post',
        });
    }

    applyReceipt(session_id, prefix, hash) {
        const model = prefix === 'S' ? 'sale' : 'coupon';
        const url =
            window.API_PATH + this.receiptEndpoints[prefix] + 'apply-' + model;

        return $.ajax({
            url,
            data: {
                session_id,
                hash: hash.trim(),
            },
            type: 'post',
        });
    }

    getCoupon(hash, cb) {
        return $.ajax({
            url: window.API_PATH + '/cafe/rest/coupon/get-coupon',
            data: {
                hash: hash.trim(),
                hostname: window.localStorage.getItem('printerHost').trim(),
            },
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    getDerbyCoupon(hash, cb) {
        $.ajax({
            url: window.API_PATH + '/cafe/rest/derby/get-coupon',
            data: {
                hash: hash.trim(),
                hostname: window.localStorage.getItem('printerHost').trim(),
            },
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    getVirtualCoupon(hash, cb) {
        $.ajax({
            url: window.API_PATH + '/cafe/rest/virtual-coupon/get-coupon',
            data: {
                hash: hash.trim(),
                hostname: window.localStorage.getItem('printerHost').trim(),
            },
            type: 'post',
            success: (res) => {
                cb(res);
            },
        });
    }

    fetchSale(hash) {
        return $.post(window.API_PATH + '/cafe/rest/sale/fetch-sale', {
            hash: hash.trim(),
            hostname: window.localStorage.getItem('printerHost').trim(),
        });
    }

    getCompList(cb) {
        axios
            .get(window.PP_REST_URL + '/rest/sports/compList')
            .then((res) => {
                if (res && res.data) {
                    cb(res.data.data);
                } else {
                    console.log(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getMenu(cb) {
        axios
            .get(window.PP_REST_URL + '/rest/sports/menu?lang=da&channel=R')
            .then((res) => {
                if (res && res.data) {
                    cb(res.data.data);
                } else {
                    console.log(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getPreEvents(cb) {
        axios
            .get(window.PP_REST_URL + '/rest/sports/events')
            .then((res) => {
                if (res.data) {
                    cb(res.data.data);
                } else {
                    console.log(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getTodayEvents(cb) {
        axios
            .get(window.PP_REST_URL + '/rest/sports/events/hours/12')
            .then((res) => {
                if (res.data) {
                    cb(res.data.data);
                } else {
                    console.log(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getBetslipEvents(cb) {
        axios
            .get(window.PP_REST_URL + '/rest/sports/events/hours/120')
            .then((res) => {
                if (res.data) {
                    cb(res.data.data);
                } else {
                    console.log(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getPopularEvents(cb) {
        axios
            .get(window.PP_REST_URL + '/rest/sports/events/list/popularR')
            .then((res) => {
                if (res && res.data) {
                    cb(res.data.data);
                } else {
                    console.log(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getByGroup(id, cb) {
        axios
            .get(
                window.PP_REST_URL +
                    '/rest/sports/events/groupId/' +
                    id +
                    '/R?lang=da&all=0'
            )
            .then((res) => {
                if (res && res.data) {
                    cb(res.data.data);
                } else {
                    console.log(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getLiveEvents(cb) {
        axios
            .get(window.PP_REST_URL + '/rest/sports/events/live?lang=da&all=0')
            .then((res) => {
                if (res && res.data) {
                    cb(res.data.data);
                } else {
                    console.log(res.error_message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getEvent(id, cb) {
        axios
            .get(
                window.PP_REST_URL +
                    '/rest/sports/event/' +
                    id +
                    '/R?lang=da&all=1'
            )
            .then((res) => {
                if (res && res.data) {
                    cb(res.data.data);
                } else {
                    console.log(res.error_message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getVirtualSeasons(cb) {
        //axios.get('http://drest.bet25.dk/?r=/rest/betradar/content/vfl/events').then((res) => {
        axios
            .get(window.PP_REST_URL + '/vfl2/betradar/content/vfl/season')
            .then((res) => {
                if (res && res.data) {
                    cb(res.data.data);
                } else {
                    console.log(res.error_message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getAllVirtualEvents(cb) {
        axios
            .get(window.TRIDENT_REST_URL)
            .then((res) => {
                if (res && res.data) {
                    let data = res.data.data;
                    cb(data);
                } else {
                    console.log(res.error_message);
                }
            })
            .catch((error) => {
                console.log('get trident events error->', error);
            });
    }

    getEventsByQuery(query, cb) {
        axios
            .get(window.PP_REST_URL + '/rest/sports/events/query/' + query)
            .then((res) => {
                if (res && res.data) {
                    cb(res.data.data);
                } else {
                    console.log(res.error_message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getLivePopularEvents(cb) {
        axios
            .get(
                window.PP_REST_URL + '/rest/sports/events/list/live/popularLive'
            )
            .then((res) => {
                if (res && res.data) {
                    cb(res.data.data);
                } else {
                    console.log(res.error_message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getShopConfig(cb) {
        axios
            .post(window.API_PATH + '/cafe/rest/shop/get-config')
            .then((res) => {
                const successfulRes = get(res, 'data.response.success', null);
                const config = get(res, 'data.response.data.config', null);
                // const configIsNotEmpty = has(res, 'data.response.data.config');

                if (successfulRes && config) {
                    /** rewrite enpoints on defined in CMS */
                    const env = get(config, 'front_endpoints.environment', '');
                    const endpoints = get(
                        config,
                        'front_endpoints.bet' + `.${env}url`,
                        {}
                    );

                    if (endpoints.trident_rest_url) {
                        window.TRIDENT_REST_URL = endpoints.trident_rest_url;
                    }

                    if (endpoints.pp_rest_url) {
                        window.PP_REST_URL = endpoints.pp_rest_url;
                    }
                    /** */

                    cb(config);
                } else {
                    const errorMessage = get(
                        res,
                        'data.response.error_message',
                        null
                    );
                    const message = errorMessage
                        ? errorMessage
                        : 'Netværksforbindelsen fejler.'; //'Network error. ManagerPC can\'t get config for the shop.';

                    cb(
                        null,
                        message +
                            ' Genoprettelse af forbindelse starter om 30 sekunder'
                    ); //' Next attempt to reconnect in 30 sec');
                }
            })
            .catch((error) => {
                const message = has(error, 'message')
                    ? error.message
                    : "Can't reach ManagerPC server";
                cb(null, message);
            });
    }

    getVersion() {
        return axios.get(window.API_PATH + '/cafe/info/version');
    }
}

export default new TerminalRestService();
