import React, { useEffect } from 'react';
import UIModalNew from 'components/UIModalNew';
import styled from 'styled-components';
import Wrapper from 'ui/Wrapper';
import Button from 'ui/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideModal, setLoginModalStatus } from 'actions/UiActions';
import { browserHistory } from 'react-router';
import { detectMobile } from 'utils/UiHelper';

const Text = styled.p`
    font-size: 1rem;
    color: #818181;
    font-family: 'Roboto Condensed', sans-serif;
    padding-left: 10px;
`;
const Description = styled(Text)`
    margin: 19px 0;
`;

const SessionPopup = ({ hideModal, loginModalStatus }) => {
    useEffect(() => {
        hideModal('LOGIN_FIRST');
        hideModal('USER_MENU');
    }, []);

    const closePopup = () => {
        hideModal('SESSION_POPUP');
        browserHistory.push('/sport/');
    };
    const openLoginPage = () => {
        hideModal('SESSION_POPUP');
        detectMobile()
            ? loginModalStatus(true)
            : browserHistory.push('/sport/log-ind');
    };

    return (
        <UIModalNew title="Session udløbet" preventFullscreen={true}>
            <Description>
                Din session er udløbet, det kan skyldes at du er logget ind via
                en anden enhed, eller har været inaktiv for længe.
            </Description>
            <Wrapper display="flex" justify="space-between">
                <Button
                    grey
                    margin="0"
                    padding="15px"
                    width="45%"
                    onClick={closePopup}
                >
                    OK
                </Button>
                <Button
                    margin="0"
                    padding="15px"
                    active
                    width="45%"
                    onClick={openLoginPage}
                >
                    Log ind
                </Button>
            </Wrapper>
        </UIModalNew>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        hideModal: bindActionCreators(hideModal, dispatch),
        loginModalStatus: bindActionCreators(setLoginModalStatus, dispatch),
    };
}

export default connect(
    null,
    mapDispatchToProps
)(SessionPopup);
