import React, { useEffect, useState } from 'react';
import Big9Service from 'utils/Big9Service';
import { CardWrapper } from 'features/MyBets/styled';
import Icon from '../../ecoui/Icon';
import big9Logo from 'images/icons/big9/Big9Logo_sm.svg';
import arrowIcon from 'images/arrow.svg';
import Text from 'ui/Text';
import { desktopOnly, detectMobile, formatDate } from 'utils/UiHelper';
import TableContent from 'features/Betslip/TableContent';
import {
    ArrowIcon,
    StyledBetCardItemWrapper,
    StyledItemsRow,
    LoadWrapper,
    CouponTitle,
    StartTimeWrapper,
} from 'features/Big9History/styled';
import Wrapper from 'ui/Wrapper';
import ViewSpinner from 'components/ViewSpinner';
import Button from 'ui/Button';
import TableHeader from 'features/Big9History/TableHeader';
import BettingHistoryResults from 'features/Big9History/BettingHistoryResults';
import {
    isCanceledCoupon,
    isTerminal,
    showRetailTransferUi,
} from '../../utils/UiHelper';
import useScreens from 'features/SpilKlub/screens/useScreens';
import {
    getTitleByStatusCoupon,
    prepareStatus,
    renderCommandButtons,
    showLimit,
    prepareHistoryItems,
    onClickHandler,
    prepareAmountBet,
    prepareAmountWin,
} from 'features/Big9History/historyHelper';
import { bindActionCreators } from 'redux';
import { hideModal as HideModal } from 'actions/UiActions';
import { connect } from 'react-redux';
import PayoutStatusComponent from '../../components/TransferRetail/PayoutStatusComponent';

const Big9History = ({
    big9TerminalCoupon,
    onClosePopupHandler = () => {},
    payPlay = false,
    checkInByCoupon = () => {},
    retailSalesHistory,
    hideModal,
    retailSails = {},
}) => {
    const prepareRetailSalesData = (retailSalesData) => {
        return retailSalesData.map((sale) => {
            return {
                ...sale,
                statusConstants: prepareStatus(sale.status),
            };
        });
    };
    const [salesList, setSalesList] = useState(
        Object.keys(retailSails).length > 0
            ? prepareRetailSalesData(retailSails.data.couponList)
            : []
    );
    const [selectedCoupons, setSelectedCoupons] = useState(null);
    const [items, setItems] = useState([]);
    const [outcomes, setOutcomes] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isAllData, setIsAllData] = useState(
        Object.keys(retailSails).length > 0 || false
    );
    const [pageIndex, setPageIndex] = useState(1);
    const pageLimit = 10;
    const { setScreen } = useScreens();
    const isRetailSails = Object.keys(retailSails).length > 0;

    const loadMore = () => {
        setIsFetching(true);
        getHistory(pageIndex, pageLimit);
    };

    const getHistory = (page, limit) => {
        if (!isTerminal()) {
            Big9Service.history(page, limit)
                .then((res) => {
                    if (res.data?.salesList?.length) {
                        setSalesList([
                            ...salesList,
                            ...res.data.salesList
                                .sort((a, b) => b.bought - a.bought)
                                .map((sale) => {
                                    return {
                                        ...sale,
                                        statusConstants: prepareStatus(
                                            sale.status
                                        ),
                                    };
                                }),
                        ]);
                        if (res.data.salesList.length < pageLimit) {
                            setIsAllData(true);
                        } else {
                            setPageIndex((prev) => prev + 1);
                        }
                    } else {
                        setIsAllData(true);
                    }
                    setIsFetching(false);
                })
                .catch((e) => {
                    console.error('History error', e);
                    setIsAllData(true);
                    setIsFetching(false);
                });
        }
    };

    useEffect(() => {
        !isRetailSails && getHistory(pageIndex, pageLimit);
    }, []);

    useEffect(
        () => {
            if (isTerminal() && Object.keys(big9TerminalCoupon).length) {
                let big9TerminalCouponArray = [];
                big9TerminalCouponArray.push(big9TerminalCoupon);

                setSalesList([
                    ...big9TerminalCouponArray.map((sale) => {
                        return {
                            ...sale,
                            statusConstants: prepareStatus(sale.status),
                        };
                    }),
                ]);
                setIsAllData(true);
                setIsFetching(false);
            }
        },
        [big9TerminalCoupon]
    );

    useEffect(
        () => {
            if (salesList.length > 0) {
                let outcomesArray = [];
                let selectedCouponsTemp = {};
                const itemsArray = salesList.map((sale, index) => {
                    outcomesArray[index] = {};
                    selectedCouponsTemp[index] = index === 0 && !isRetailSails;
                    return prepareHistoryItems(sale, outcomesArray, index);
                });
                setItems(itemsArray);
                setOutcomes(outcomesArray);
                setSelectedCoupons({
                    ...selectedCouponsTemp,
                    ...selectedCoupons,
                });
            }
        },
        [salesList]
    );

    return (
        <Wrapper
            display={'flex'}
            flexDirection={'column'}
            padding={desktopOnly() ? '0 50px' : '0'}
            margin={'0'}
        >
            {!detectMobile() &&
                !isTerminal() &&
                !payPlay &&
                !isRetailSails && (
                    <Text
                        size={'x2'}
                        color="#333"
                        padding={'0'}
                        margin={'30px auto 0'}
                        align={'center'}
                        bold
                    >
                        Mine væddemål
                    </Text>
                )}

            {isTerminal() && !payPlay ? (
                <CouponTitle>
                    <Text
                        size={'28'}
                        align={'left'}
                        bold
                        color={'#333'}
                        margin={'20px 0 30px'}
                        padding={'0'}
                    >
                        {getTitleByStatusCoupon(big9TerminalCoupon)}
                    </Text>
                </CouponTitle>
            ) : null}

            {salesList.length && selectedCoupons ? (
                salesList.map((sale, index) => {
                    const { border, icon, circleStatus } = sale.statusConstants;
                    const isCouponPlaced = !!sale.couponId || !!sale.coupon_id;
                    return (
                        <CardWrapper
                            done
                            leftBorderColor={border}
                            key={index}
                            className={isTerminal() ? 'terminal' : null}
                            order={sale.couponId === 0 ? 1 : 2}
                            margin={'20px auto 0'}
                        >
                            <StyledBetCardItemWrapper
                                style={{ cursor: 'pointer' }}
                                padding={'10px 10px 10px 0!important'}
                                onClick={() => {
                                    sale?.betGroupInfo?.poolHash &&
                                        !isCouponPlaced &&
                                        hideModal('USER_MENU');
                                    onClickHandler(
                                        sale,
                                        setScreen,
                                        selectedCoupons,
                                        setSelectedCoupons,
                                        index
                                    );
                                }}
                                spilklub
                            >
                                {/*<StyledStatusCircle status={circleStatus}>
                                    {icon}
                                </StyledStatusCircle>*/}
                                <StyledItemsRow>
                                    <Icon
                                        width={'40px'}
                                        height={'12px'}
                                        color={'black'}
                                        src={big9Logo}
                                        alt={'big-9-logo'}
                                        margin={'1px 0 0'}
                                    />
                                    {sale.betGroupInfo && (
                                        <Text
                                            color="#333333"
                                            size="x15"
                                            condensed
                                            bold
                                            padding="0"
                                            margin="0 0 0 4px"
                                        >
                                            - SpilKlub25
                                        </Text>
                                    )}
                                    <ArrowIcon
                                        width={'4px'}
                                        height={'10px'}
                                        color={'#B8C3D6'}
                                        src={arrowIcon} // todo ask for icon svg
                                        alt={'arrow-icon'}
                                        slipOpened={
                                            isCouponPlaced &&
                                            selectedCoupons[index]
                                        }
                                        className={
                                            sale.betGroupInfo &&
                                            sale.selections.length === 0 &&
                                            'spilklub'
                                        }
                                    />
                                </StyledItemsRow>

                                {sale.betGroupInfo && (
                                    <StyledItemsRow>
                                        <StartTimeWrapper>
                                            {sale.betGroupInfo && (
                                                <Text
                                                    color="#333333"
                                                    size="x07"
                                                    condensed
                                                    padding="4px 0 0"
                                                >
                                                    Klub:{' '}
                                                    {sale.betGroupInfo
                                                        .groupName || ''}
                                                </Text>
                                            )}
                                            <Text
                                                color="#333333"
                                                size="x07"
                                                condensed
                                                padding={
                                                    sale.betGroupInfo
                                                        ? '4px 0 0'
                                                        : '7px 0 0'
                                                }
                                                capitalize
                                            >
                                                {formatDate(
                                                    sale.startTime,
                                                    'dddd DD[.] MMMM'
                                                )}
                                            </Text>
                                        </StartTimeWrapper>
                                    </StyledItemsRow>
                                )}
                            </StyledBetCardItemWrapper>

                            <StyledBetCardItemWrapper
                                hide={isCouponPlaced}
                                slipOpened={selectedCoupons[index]}
                            >
                                <StyledItemsRow
                                    slipOpened={selectedCoupons[index]}
                                >
                                    {isCouponPlaced ? (
                                        <>
                                            {selectedCoupons[index] && (
                                                <TableHeader />
                                            )}
                                            {selectedCoupons[index] &&
                                                items[index].map(
                                                    (item, key) => (
                                                        <TableContent
                                                            key={key}
                                                            item={item}
                                                            outcomes={
                                                                outcomes[index]
                                                            }
                                                            isHistoryOnTerminal={isTerminal()}
                                                        />
                                                    )
                                                )}
                                        </>
                                    ) : (
                                        <Text
                                            color="#333333"
                                            size="x07"
                                            condensed
                                            padding="15px 10px"
                                        >
                                            Klubbens kaptajn har endnu ikke
                                            placeret spillet. Når det bliver
                                            placeret, vil du finde den endelige
                                            kupon her.
                                        </Text>
                                    )}
                                </StyledItemsRow>
                            </StyledBetCardItemWrapper>

                            <BettingHistoryResults
                                spilklub={sale.betGroupInfo}
                                amount={prepareAmountBet(sale, isCouponPlaced)}
                                amountWin={prepareAmountWin(sale)}
                                isTerminal={isTerminal()}
                                amountForTerminal={sale.comb_price / 100}
                                isCouponPlaced={isCouponPlaced}
                                status={sale.status}
                                isTransferRetailMoney={isRetailSails}
                                isCanceledCoupon={isCanceledCoupon(retailSails)}
                                isPayoutInShop={
                                    retailSails.payoutStatus === 2 &&
                                    retailSails.payoutType === 'SHOP'
                                }
                            />
                            {sale.betGroupInfo &&
                                sale.status !== 0 && (
                                    <BettingHistoryResults
                                        spilklub
                                        winResults
                                        win={
                                            sale.status === 2 ||
                                            sale.status === 1
                                        }
                                        amount={sale.betGroupInfo.userWinAmount}
                                        amountWin={sale.amountWin}
                                        status={sale.status}
                                        isTransferRetailMoney={isRetailSails}
                                        isCanceledCoupon={isCanceledCoupon(
                                            retailSails
                                        )}
                                        isPayoutInShop={
                                            retailSails.payoutStatus === 2 &&
                                            retailSails.payoutType === 'SHOP'
                                        }
                                    />
                                )}
                            {showRetailTransferUi(retailSails) &&
                                !isCanceledCoupon(retailSails) && (
                                    <Wrapper
                                        width={'100%'}
                                        padding={'0'}
                                        margin={'0'}
                                        flex
                                        justify={'center'}
                                        flexAlign={'center'}
                                        boxSizing={'border-box'}
                                    >
                                        <PayoutStatusComponent
                                            product={retailSails.product}
                                            saleId={retailSails.data.saleId}
                                            win={
                                                retailSails.data.couponList[0]
                                                    .amountWin
                                            }
                                            payoutStatus={
                                                retailSails.payoutStatus
                                            }
                                            payoutType={retailSails.payoutType}
                                        />
                                    </Wrapper>
                                )}
                            <Text
                                margin={'0 0 0'}
                                padding="0"
                                color="#8C93A0"
                                size="x07"
                                align="center"
                                condensed
                                capitalize
                            >
                                {isCouponPlaced
                                    ? `ID: ${
                                          isTerminal()
                                              ? sale.coupon_id
                                              : sale.couponId
                                      } -`
                                    : null}
                                {isTerminal()
                                    ? formatDate(
                                          sale.created_at,
                                          'DD. MMMM HH:mm'
                                      )
                                    : formatDate(sale.bought, 'DD. MMMM HH:mm')}
                            </Text>
                        </CardWrapper>
                    );
                })
            ) : !isFetching ? (
                <Text center padding="10px" size="x15">
                    Du har ingen aktive væddemål i øjeblikket
                </Text>
            ) : null}

            {/*<BigWinErrorMessageWrapper>*/}
            {/*    <WarningIcon>*/}
            {/*        <Text*/}
            {/*            condensed*/}
            {/*            size="x15"*/}
            {/*            color="#FBF5E5"*/}
            {/*            padding="0"*/}
            {/*            align="center"*/}
            {/*        >*/}
            {/*            !*/}
            {/*        </Text>*/}
            {/*    </WarningIcon>*/}
            {/*    <Text condensed size="x1" color="#333333" padding="0">*/}
            {/*        Gevinster over 15.000,00 kr. kan ikke udbetales til*/}
            {/*        terminalen. Henvend dig ved kassen for udbetaling.*/}
            {/*    </Text>*/}
            {/*</BigWinErrorMessageWrapper>*/}

            {isTerminal() &&
            Object.keys(big9TerminalCoupon).length > 0 &&
            big9TerminalCoupon.win_amount >= 1500000
                ? showLimit(big9TerminalCoupon)
                : null}

            {isTerminal() &&
            Object.keys(big9TerminalCoupon).length > 0 &&
            !payPlay &&
            big9TerminalCoupon.win_amount < 1500000
                ? renderCommandButtons(
                      big9TerminalCoupon,
                      checkInByCoupon,
                      onClosePopupHandler
                  )
                : null}

            {!isTerminal() && !isRetailSails ? (
                <LoadWrapper order={3}>
                    {isFetching ? (
                        <ViewSpinner />
                    ) : !isAllData ? (
                        <Button
                            onClick={loadMore}
                            margin="16px auto"
                            fontSize="14px"
                            fontWeight="bold"
                            padding="14px 30px"
                            width="260px"
                            color={'#333333'}
                            active
                        >
                            Vis mere
                        </Button>
                    ) : null}
                </LoadWrapper>
            ) : null}
        </Wrapper>
    );
};

function mapStateToProps(state) {
    return {
        retailSalesHistory: state.retailSalesHistory,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        hideModal: bindActionCreators(HideModal, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Big9History);
