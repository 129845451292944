import { makeRequest } from 'utils/REST';
import {
    DIBS_ACCEPT_RETURN_URL,
    DIBS_CANCEL_RETURN_URL,
    DIBS_CLIENT_NAME,
    DIBS_ERROR_URL,
    DIBS_EXCHANGE_DK,
    DIBS_PAYMENT_URL,
} from 'features/UserDeposits/dibs';

class ApplePayService {
    request(requestType, url, data) {
        return makeRequest(requestType, url, data)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
    }

    post(method, data) {
        return this.request('post', method, data);
    }

    get(method, data) {
        return this.request('get', method, data);
    }

    ApplePayMerchantVerification() {
        return this.get(
            `${DIBS_PAYMENT_URL}/dibs?op=applepayinit&merchant=824477&name=Bet25`
        );
    }
    CompleteApplePayPayment(inputAmount, payData, selectedBonuses) {
        let bonus = '';
        if (selectedBonuses && selectedBonuses.length) {
            selectedBonuses.forEach((b) => {
                bonus += `&bonusId=${b.bonusId}`;
            });
        }

        return this.get(
            `${DIBS_PAYMENT_URL}/dibs?op=start&client=${DIBS_CLIENT_NAME}&exchcode=${DIBS_EXCHANGE_DK}&errorUrl=${DIBS_ERROR_URL}&amount=${inputAmount *
                100}&input_amount=${inputAmount}&agree=on&acceptReturnUrl=${DIBS_ACCEPT_RETURN_URL}&cancelReturnUrl=${DIBS_CANCEL_RETURN_URL}&payType=applePay&payData=${payData}${bonus}`
        );
    }
}

export default new ApplePayService();
