import {
    TERMINAL_VIRTUAL_BETSLIP_ADD_MARKET,
    TERMINAL_VIRTUAL_BETSLIP_PENDING,
    TERMINAL_VIRTUAL_BETSLIP_PROCEED,
    TERMINAL_VIRTUAL_BETSLIP_REMOVE_MARKET,
    TERMINAL_VIRTUAL_BETSLIP_RESET,
    TERMINAL_VIRTUAL_BETSLIP_SET_MARKET,
    TERMINAL_VIRTUAL_BETSLIP_SET_SYSTEM_MARKET,
    TERMINAL_VIRTUAL_BETSLIP_SET_VALUE,
    TERMINAL_VIRTUAL_BETSLIP_UPDATE_MARKET,
} from 'constants/terminalVirtualBetslip';
import BetslipService from '../utils/BetslipService';
import { normalizeStake } from 'utils/Helper';

const initialState = {
    success: false,
    error: false,
    pending: false,
    timer: null,
    added: false,
    addError: false,
    changed: false,
    disabled: false,
    markets: [],
    systemName: null,
    totalOdds: 0,
    betList: [],
    hasMarkets: [],
    singleStake: 0,
    singlePrize: 0,
    system: {
        stake: [],
        prize: [],
        multi: [],
        names: [],
        totalPrize: 0,
        totalStake: 0,
        accum: [],
    },
    itemError: null,
};

let system = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [],
    12: [],
    13: [],
    14: [],
};

let accum = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
    13: 0,
    14: 0,
};

let Model = {
    changed: false,
    frozen: false,
    timeout: 0,
    betCount: 0,
    betList: [],
    hasMarkets: [],
    markets: {},
    system: {
        stake: [],
        prize: [],
        multi: [],
        names: [],
        totalPrize: 0,
        totalStake: 0,
    },
};

const Calculate = {
    Prize: function(stake, odds) {
        return stake * odds;
    },

    TotalPrize: function() {
        var totalPrize = 0;

        if (Model.system.prize.length > 0) {
            Model.system.prize.map((v, k) => {
                totalPrize += parseFloat(v);

                return v;
            });
        }

        Model.system.totalPrize = totalPrize;

        return totalPrize;
    },

    TotalStake: function() {
        var totalStake = 0;

        if (Model.system.stake.length > 0) {
            Model.system.stake.map((v, k) => {
                totalStake += v;

                return v;
            });
        }

        Model.system.totalStake = totalStake;

        return totalStake;
    },

    TotalOdds: (betList) => {
        var totalOdds = 1;

        betList.map((bet) => {
            totalOdds *= bet.odds;

            return bet;
        });

        return totalOdds;
    },

    /* systemAccum */
    System: function(betList) {
        system = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
        };

        var odds = [];

        betList.map((v, k) => {
            odds.push(v.odds);
            return v;
        });

        var n = odds.length;
        var i, i1, i2, i3, i4, i5, i6, i7, i8, i9, i10, i11, i12, i13, i14, i15;
        for (i1 = 0; i1 < n; i1++) {
            system[0].push(odds[i1]);
            for (i2 = i1 + 1; i2 < n; i2++) {
                system[1].push(odds[i1] + ',' + odds[i2]);
                for (i3 = i2 + 1; i3 < n; i3++) {
                    system[2].push(odds[i1] + ',' + odds[i2] + ',' + odds[i3]);
                    for (i4 = i3 + 1; i4 < n; i4++) {
                        system[3].push(
                            odds[i1] +
                                ',' +
                                odds[i2] +
                                ',' +
                                odds[i3] +
                                ',' +
                                odds[i4]
                        );
                        for (i5 = i4 + 1; i5 < n; i5++) {
                            system[4].push(
                                odds[i1] +
                                    ',' +
                                    odds[i2] +
                                    ',' +
                                    odds[i3] +
                                    ',' +
                                    odds[i4] +
                                    ',' +
                                    odds[i5]
                            );
                            for (i6 = i5 + 1; i6 < n; i6++) {
                                system[5].push(
                                    odds[i1] +
                                        ',' +
                                        odds[i2] +
                                        ',' +
                                        odds[i3] +
                                        ',' +
                                        odds[i4] +
                                        ',' +
                                        odds[i5] +
                                        ',' +
                                        odds[i6]
                                );
                                for (i7 = i6 + 1; i7 < n; i7++) {
                                    system[6].push(
                                        odds[i1] +
                                            ',' +
                                            odds[i2] +
                                            ',' +
                                            odds[i3] +
                                            ',' +
                                            odds[i4] +
                                            ',' +
                                            odds[i5] +
                                            ',' +
                                            odds[i6] +
                                            ',' +
                                            odds[i7]
                                    );
                                    for (i8 = i7 + 1; i8 < n; i8++) {
                                        system[7].push(
                                            odds[i1] +
                                                ',' +
                                                odds[i2] +
                                                ',' +
                                                odds[i3] +
                                                ',' +
                                                odds[i4] +
                                                ',' +
                                                odds[i5] +
                                                ',' +
                                                odds[i6] +
                                                ',' +
                                                odds[i7] +
                                                ',' +
                                                odds[i8]
                                        );
                                        for (i9 = i8 + 1; i9 < n; i9++) {
                                            system[8].push(
                                                odds[i1] +
                                                    ',' +
                                                    odds[i2] +
                                                    ',' +
                                                    odds[i3] +
                                                    ',' +
                                                    odds[i4] +
                                                    ',' +
                                                    odds[i5] +
                                                    ',' +
                                                    odds[i6] +
                                                    ',' +
                                                    odds[i7] +
                                                    ',' +
                                                    odds[i8] +
                                                    ',' +
                                                    odds[i9]
                                            );
                                            for (i10 = i9 + 1; i10 < n; i10++) {
                                                system[9].push(
                                                    odds[i1] +
                                                        ',' +
                                                        odds[i2] +
                                                        ',' +
                                                        odds[i3] +
                                                        ',' +
                                                        odds[i4] +
                                                        ',' +
                                                        odds[i5] +
                                                        ',' +
                                                        odds[i6] +
                                                        ',' +
                                                        odds[i7] +
                                                        ',' +
                                                        odds[i8] +
                                                        ',' +
                                                        odds[i9] +
                                                        ',' +
                                                        odds[i10]
                                                );
                                                for (
                                                    i11 = i10 + 1;
                                                    i11 < n;
                                                    i11++
                                                ) {
                                                    system[10].push(
                                                        odds[i1] +
                                                            ',' +
                                                            odds[i2] +
                                                            ',' +
                                                            odds[i3] +
                                                            ',' +
                                                            odds[i4] +
                                                            ',' +
                                                            odds[i5] +
                                                            ',' +
                                                            odds[i6] +
                                                            ',' +
                                                            odds[i7] +
                                                            ',' +
                                                            odds[i8] +
                                                            ',' +
                                                            odds[i9] +
                                                            ',' +
                                                            odds[i10] +
                                                            ',' +
                                                            odds[i11]
                                                    );
                                                    for (
                                                        i12 = i11 + 1;
                                                        i12 < n;
                                                        i12++
                                                    ) {
                                                        system[11].push(
                                                            odds[i1] +
                                                                ',' +
                                                                odds[i2] +
                                                                ',' +
                                                                odds[i3] +
                                                                ',' +
                                                                odds[i4] +
                                                                ',' +
                                                                odds[i5] +
                                                                ',' +
                                                                odds[i6] +
                                                                ',' +
                                                                odds[i7] +
                                                                ',' +
                                                                odds[i8] +
                                                                ',' +
                                                                odds[i9] +
                                                                ',' +
                                                                odds[i10] +
                                                                ',' +
                                                                odds[i11] +
                                                                ',' +
                                                                odds[i12]
                                                        );
                                                        for (
                                                            i13 = i12 + 1;
                                                            i13 < n;
                                                            i13++
                                                        ) {
                                                            system[12].push(
                                                                odds[i1] +
                                                                    ',' +
                                                                    odds[i2] +
                                                                    ',' +
                                                                    odds[i3] +
                                                                    ',' +
                                                                    odds[i4] +
                                                                    ',' +
                                                                    odds[i5] +
                                                                    ',' +
                                                                    odds[i6] +
                                                                    ',' +
                                                                    odds[i7] +
                                                                    ',' +
                                                                    odds[i8] +
                                                                    ',' +
                                                                    odds[i9] +
                                                                    ',' +
                                                                    odds[i10] +
                                                                    ',' +
                                                                    odds[i11] +
                                                                    ',' +
                                                                    odds[i12] +
                                                                    ',' +
                                                                    odds[i13]
                                                            );
                                                            for (
                                                                i14 = i13 + 1;
                                                                i14 < n;
                                                                i14++
                                                            ) {
                                                                system[13].push(
                                                                    odds[i1] +
                                                                        ',' +
                                                                        odds[
                                                                            i2
                                                                        ] +
                                                                        ',' +
                                                                        odds[
                                                                            i3
                                                                        ] +
                                                                        ',' +
                                                                        odds[
                                                                            i4
                                                                        ] +
                                                                        ',' +
                                                                        odds[
                                                                            i5
                                                                        ] +
                                                                        ',' +
                                                                        odds[
                                                                            i6
                                                                        ] +
                                                                        ',' +
                                                                        odds[
                                                                            i7
                                                                        ] +
                                                                        ',' +
                                                                        odds[
                                                                            i8
                                                                        ] +
                                                                        ',' +
                                                                        odds[
                                                                            i9
                                                                        ] +
                                                                        ',' +
                                                                        odds[
                                                                            i10
                                                                        ] +
                                                                        ',' +
                                                                        odds[
                                                                            i11
                                                                        ] +
                                                                        ',' +
                                                                        odds[
                                                                            i12
                                                                        ] +
                                                                        ',' +
                                                                        odds[
                                                                            i13
                                                                        ] +
                                                                        ',' +
                                                                        odds[
                                                                            i14
                                                                        ]
                                                                );
                                                                for (
                                                                    i15 =
                                                                        i14 + 1;
                                                                    i15 < n;
                                                                    i15++
                                                                ) {
                                                                    system[14].push(
                                                                        odds[
                                                                            i1
                                                                        ] +
                                                                            ',' +
                                                                            odds[
                                                                                i2
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i3
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i4
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i5
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i6
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i7
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i8
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i9
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i10
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i11
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i12
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i13
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i14
                                                                            ] +
                                                                            ',' +
                                                                            odds[
                                                                                i15
                                                                            ]
                                                                    );
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        for (i = 0; i < 15; i++) {
            var oddsArray = system[i];
            if (oddsArray.length > 0) {
                var accumulator = 0;

                for (let n = 0; n < oddsArray.length; n++) {
                    let value = oddsArray[n].toString();

                    if (value.indexOf(',') === -1) {
                        accumulator += parseFloat(value);
                    } else {
                        var oddsArray2 = value.split(',');
                        let odds = 1;

                        for (let k = 0; k < oddsArray2.length; k++) {
                            odds *= parseFloat(oddsArray2[k]);
                        }

                        accumulator += odds;
                    }
                }

                accum[i] = accumulator;

                if (Model.system.stake[i]) {
                    var stake = Model.system.stake[i];
                    if (stake > 0) {
                        var prize = parseFloat(stake) * accumulator;
                        Model.system.prize[i] = prize;
                    }
                }
            }
        }
    },
};

var System = {
    factorial: function(n) {
        n = Number(n);
        if (n < 0) {
            return;
        }
        if (n < 2) {
            return 1;
        } else {
            return n * System.factorial(n - 1);
        }
    },

    Init: function(betlist) {
        const betCount = betlist.length;
        const systemNames = BetslipService.getSystemNames();

        if (betCount > 1) {
            var n = betCount;
            Model.system.names = [systemNames[betCount]];
            for (let i = 0; i < 15; i++) {
                let r = i + 1;
                var systemCount =
                    System.factorial(n) /
                    (System.factorial(r) * System.factorial(n - r));

                if (i < betCount && i < 5) {
                    Model.system.multi[i] = systemCount;
                    Model.system.stake[i] = Model.system.stake[i] || 0;
                    Model.system.prize[i] = 0;
                } else {
                    Model.system.multi[i] = 0;
                    Model.system.stake[i] = 0;
                    Model.system.prize[i] = 0;
                }
            }
            System.Update(betCount);
            Calculate.System(betlist);
        } else {
            for (let i = 1; i < 15; i++) {
                Model.system.multi[i] = 0;
                Model.system.stake[i] = 0;
                Model.system.prize[i] = 0;
            }
        }
    },

    Update: function(betCount) {
        const systemNames = BetslipService.getSystemNames();

        for (var i = 0; i < betCount; i++) {
            Model.system.names[i] = systemNames[i];
        }
    },
};

export default function terminalBetslip(state = initialState, action) {
    let betslipData = null;
    let stake = 0;
    let odds = 0;
    let index = 0;

    switch (action.type) {
        case TERMINAL_VIRTUAL_BETSLIP_ADD_MARKET:
            betslipData = {
                ...state,
                betList: [...state.betList, action.payload],
                hasMarkets: [...state.hasMarkets, action.payload.marketId],
            };

            betslipData.betList.map((item, index) => {
                Model.system.prize[index] = 0;
                Model.system.stake[index] = 0;

                return item;
            });

            System.Init(betslipData.betList);
            Calculate.TotalPrize();
            Calculate.TotalStake();

            return {
                ...betslipData,
                system: {
                    ...Model.system,
                    accum,
                },
                totalOdds: Calculate.TotalOdds(betslipData.betList),
                systemName: BetslipService.getSystemNames()[
                    betslipData.betList.length - 1
                ],
            };
        case TERMINAL_VIRTUAL_BETSLIP_REMOVE_MARKET:
            const marketId = action.payload;

            betslipData = {
                ...state,
                betList: [
                    ...state.betList.filter((item, index) => {
                        Model.system.prize[index] = 0;
                        Model.system.stake[index] = 0;

                        return item.marketId !== marketId;
                    }),
                ],
                hasMarkets: [
                    ...state.hasMarkets.filter((item) => item !== marketId),
                ],
                singleStake: 0,
            };

            System.Init(betslipData.betList);
            Calculate.TotalPrize();
            Calculate.TotalStake();

            return {
                ...betslipData,
                system: {
                    ...Model.system,
                    accum,
                },
                totalOdds: Calculate.TotalOdds(betslipData.betList),
                systemName: BetslipService.getSystemNames()[
                    betslipData.betList.length - 1
                ],
                singlePrize: 0,
            };
        case TERMINAL_VIRTUAL_BETSLIP_SET_VALUE:
            return {
                ...state,
                ...action.payload,
            };
        case TERMINAL_VIRTUAL_BETSLIP_RESET:
            return initialState;
        case TERMINAL_VIRTUAL_BETSLIP_PENDING:
            return {
                ...state,
                pending: true,
            };
        case TERMINAL_VIRTUAL_BETSLIP_SET_MARKET:
            stake = action.payload.value;
            odds = action.payload.odds;

            if ([',', '.'].indexOf(stake[stake.length - 1]) < 0) {
                stake = normalizeStake(stake);
            }

            return {
                ...state,
                singleStake: stake,
                singlePrize: odds * stake,
            };
        case TERMINAL_VIRTUAL_BETSLIP_SET_SYSTEM_MARKET:
            stake = action.payload.value;
            index = 0; //action.payload.index;

            if ([',', '.'].indexOf(stake[stake.length - 1]) < 0) {
                stake = normalizeStake(stake);
            }

            Model.system.prize[index] = stake * state.totalOdds.toFixed(2);
            Model.system.stake[index] = stake;
            Calculate.TotalPrize();
            Calculate.TotalStake();

            return {
                ...state,
                system: {
                    ...Model.system,
                },
                singleStake: 0,
                singlePrize: 0,
            };
        case TERMINAL_VIRTUAL_BETSLIP_PROCEED:
            return {
                ...initialState,
                success: true,
            };
        case TERMINAL_VIRTUAL_BETSLIP_UPDATE_MARKET:
            let totalOdds = 1;
            let betslip = state;
            let { data } = action.payload;

            betslip.betList.map((betitem, betIndex) => {
                if (data && betitem.gameId === data.marketId && data.itemList) {
                    data.itemList.map((item) => {
                        if (
                            item.betNr === betitem.betNr &&
                            betslip.betList[betIndex].odds !== item.odds
                        ) {
                            betslip.betList[betIndex].odds = item.odds;

                            if (!betslip.changed) {
                                betslip.changed = {};
                            }

                            betslip.changed[data.marketId] = item.betNr;
                        }

                        return item;
                    });
                }

                totalOdds *= betslip.betList[betIndex].odds;

                return betitem;
            });

            if (betslip.betList.length > 1) {
                System.Init(betslip.betList);
                Calculate.TotalPrize();
                Calculate.TotalStake();

                betslip.system = {
                    ...Model.system,
                };

                betslip.totalOdds = totalOdds;
            } else {
                betslip.singlePrize =
                    betslip.betList[0].odds * betslip.singleStake;
                betslip.totalOdds = betslip.betList[0].odds;
            }

            return {
                ...state,
                ...betslip,
            };
        default:
            return state;
    }
}
