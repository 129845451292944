import {
    VAIX_LIVE_FAVORITE_EVENTS_IDS_SET,
    VAIX_LIVE_FAVORITE_EVENTS_SHOULD_BE_UPDATED,
    VAIX_LIVE_FAVORITE_EVENTS_IDS_FETCHED,
    VAIX_LIVE_EVENTS_IDS_RESET,
    VAIX_EVENTS_FETCH_POPULAR_PENDING,
    VAIX_LIVE_EVENTS_NAVIGATION_ORDER_SET,
    VAIX_LIVE_EVENTS_NAVIGATION_ORDER_RESET,
    VAIX_EVENTS_FETCH_PENDING,
    VAIX_LIVE_EVENTS_NAVIGATION_ORDER_FETCHED,
} from 'constants/vaix/vaixEvents';

const initialState = {
    pending: false,
    navigationOrderIds: [],
    navigationOrderIdsFetched: false,
    vaixLivePopularEvents: [],
    vaixLiveFavoriteEventsIds: [],
    vaixLiveFavoriteEventsIdsFetched: false,
    vaixFavoriteEventsShouldBeUpdated: false,
    error: false,
};

export default function vaixEvents(state = initialState, action) {
    switch (action.type) {
        case VAIX_LIVE_FAVORITE_EVENTS_IDS_SET:
            return {
                ...state,
                vaixLiveFavoriteEventsIds: action.payload,
                vaixFavoriteEventsShouldBeUpdated: true,
                vaixLiveFavoriteEventsIdsFetched: true,
                pending: false,
            };
        case VAIX_LIVE_EVENTS_IDS_RESET:
            return {
                ...state,
                vaixLiveFavoriteEventsIds: [],
                pending: false,
            };
        case VAIX_LIVE_EVENTS_NAVIGATION_ORDER_SET:
            return {
                ...state,
                navigationOrderIds: action.payload,
                navigationOrderIdsFetched: true,
                pending: false,
            };
        case VAIX_LIVE_EVENTS_NAVIGATION_ORDER_RESET:
            return {
                ...state,
                navigationOrderIds: [],
                pending: false,
            };
        case VAIX_LIVE_EVENTS_NAVIGATION_ORDER_FETCHED:
            return {
                ...state,
                navigationOrderIdsFetched: action.payload,
            };
        /*case VAIX_LIVE_POPULAR_EVENTS_SET:
            return {
                ...state,
                vaixLivePopularEvents: [],
                pending: false,
            };*/
        case VAIX_EVENTS_FETCH_PENDING:
            return {
                ...state,
                pending: action.payload,
            };
        case VAIX_EVENTS_FETCH_POPULAR_PENDING:
            return {
                ...state,
                pending: action.payload,
            };
        case VAIX_LIVE_FAVORITE_EVENTS_IDS_FETCHED:
            return {
                ...state,
                vaixLiveFavoriteEventsIdsFetched: action.payload,
            };
        case VAIX_LIVE_FAVORITE_EVENTS_SHOULD_BE_UPDATED:
            return {
                ...state,
                vaixFavoriteEventsShouldBeUpdated: action.payload,
            };
        default:
            return state;
    }
}
