import React from 'react';

const Checkbox = ({ value, onChange, label }) => {
    return (
        <div
            className="checkbox-wrapper flex align-center top-indent-lg"
            onClick={() => onChange(!value)}
        >
            <span
                className={`checkbox ${value ? 'checkbox--active' : ''}`}
                aria-hidden="true"
            />
            <label className="checkbox-label">{label}</label>
        </div>
    );
};

export default Checkbox;
