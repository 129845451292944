import styled from 'styled-components';

export const BetslipTitle = styled.div`
    font-size: 28px;
    text-align: center;
    padding: 15px;
    color: #fff;
    background: #1c3e60;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
`;

export const AmountSuggestContainer = styled.div`
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const SectionsDivider = styled.div`
    padding: 15px;
    font-size: 16px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    color: white;
    background: #1c3e60;
`;

export const CouponRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

export const RemoveCouponButton = styled.div`
    height: 28px;
    width: 28px;
    border-radius: 3px;
    background: #252525;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 300;
    &:hover {
        filter: brightness(150%);
    }
`;
