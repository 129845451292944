import {
    FETCH_RETAIL_BETS_HISTORY_SUCCESS,
    FETCH_RETAIL_BETS_HISTORY_ERROR,
    FETCH_RETAIL_BETS_HISTORY_PENDING,
    SET_TRANSACTION_SALE_ID,
    RESET_RETAIL_BETS_HISTORY,
} from '../constants/retailSailsHistory';

const initialState = {
    data: {},
    transactionsIds: [],
    pending: false,
    error: false,
};

export default function retailSalesHistory(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_RETAIL_BETS_HISTORY_PENDING':
            return {
                ...state,
                pending: action.payload,
            };
        case 'FETCH_RETAIL_BETS_HISTORY_SUCCESS':
            return {
                ...state,
                pending: false,
                data: {
                    ...action.payload,
                },
            };
        case 'FETCH_RETAIL_BETS_HISTORY_ERROR':
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        case 'FETCH_MORE_RETAIL_BETS_HISTORY_SUCCESS':
            return {
                ...state,
                pending: false,
                data: {
                    ...state.data,
                    salesList: [
                        ...state.data.salesList,
                        ...action.payload.salesList,
                    ],
                },
            };
        case 'SET_TRANSACTION_SALE_ID':
            return {
                ...state,
                pending: false,
                transactionsIds: [...state.transactionsIds, action.payload],
            };
        case 'RESET_RETAIL_BETS_HISTORY':
            return {
                ...initialState,
            };
        default:
            return state;
    }
}
