export const TRANSACTION_HISTORY_PENDING = 'TRANSACTION_HISTORY_PENDING';
export const TRANSACTION_HISTORY_FETCH_SUCCESS =
    'TRANSACTION_HISTORY_FETCH_SUCCESS';
export const TRANSACTION_HISTORY_ERROR = 'TRANSACTION_HISTORY_ERROR';
export const TRANSACTION_HISTORY_DATE = 'TRANSACTION_HISTORY_DATE';
export const TRANSACTION_HISTORY_SET_INITIAL_CONFIG =
    'TRANSACTION_HISTORY_SET_INITIAL_CONFIG';
export const TRANSACTION_HISTORY_DATA_IS_UPDATED =
    'TRANSACTION_HISTORY_DATA_IS_UPDATED';
export const TRANSACTION_HISTORY_RESET_DATA = 'TRANSACTION_HISTORY_RESET_DATA';
export const TRANSACTION_HISTORY_FETCH_SUCCESS_WITH_FILTER =
    'TRANSACTION_HISTORY_FETCH_SUCCESS_WITH_FILTER';
