import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from './../../ui/topbar';
import SpilIdCard from './SpilIdCard';
import useSpilIdCard, { getCardFromStorage } from './useSpilIdCard';
import {
    hideModal as HideModal,
    showModal as ShowModal,
} from '../../actions/UiActions';
import { NavigationBar } from 'features/SpilID/styled';
import { connect } from 'react-redux';
import { detectMobile, enableBodyScroll } from 'utils/UiHelper';
import Icon from '../../ecoui/Icon';
import ArrowIcon from 'images/arrow.svg';
import withPopupWrapper from 'features/SpilID/withPopupWrapper';
import UserService from '../../utils/UserService';

const SpilIdModalContent = styled.div`
    height: auto;
    min-height: ${detectMobile() ? '100vh' : '700px'};
    min-height: ${detectMobile() && 'var(--app-height)'};
    width: 100%;
    box-sizing: border-box;
    background: #f5f7fc;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 22px;
    > div {
        height: 100%;
    }
`;

const SpilIdButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 20px auto 0;
`;

const SpilIdBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${COLORS.white};
    border-radius: 10px;
    color: #333333;
    font-size: 16px;
    line-height: 16px;
    font-weight: ${({ bold }) => (bold ? '900' : '400')};
    padding: 15px 20px;
    box-sizing: border-box;
    margin: ${({ margin }) => margin || '0'};
    height: 60px;
    cursor: pointer;
`;

const SpilIdBtnLink = styled.a`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${COLORS.white};
    border-radius: 10px;
    color: #333333;
    font-size: 16px;
    line-height: 21px;
    text-decoration: none;
    font-weight: ${({ bold }) => (bold ? '900' : '400')};
    padding: 15px 20px;
    box-sizing: border-box;
    margin: ${({ margin }) => margin || '0'};
    cursor: pointer;
    .right {
        height: auto;
        width: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .dots {
            height: auto;
            width: 50px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-right: 20px;
            .dot {
                height: 6px;
                width: 6px;
                box-sizing: border-box;
                display: block;
                border-radius: 100%;
                background: #9198aa;
            }
        }
    }
`;

const SpilIdModal = ({ showModal, hideModal, auth, modalData }) => {
    const {
        pending,
        userInfoPending,
        failed,
        spilIdCard,
        fullName,
        address,
        zipCode,
        birthdate,
        city,
        retailPin,
    } = useSpilIdCard(auth);

    useEffect(
        () => {
            if (!userInfoPending && retailPin < 0) {
                showPinCodeModal(retailPin, userInfoPending);
            }
        },
        [retailPin]
    );

    const { barcode, name, id: cardId, year } = spilIdCard;

    const hidePopup = () => {
        hideModal('SPIL_ID_MODAL');
        enableBodyScroll();
    };

    const showPCPreviewModal = () => {
        if (auth?.user) {
            showModal('SPIL_ID_PC_PREVIEW', {
                fullName,
                address,
                zipCode,
                cardId,
                birthdate,
                city,
                bg: 'transparent',
            });
        } else {
            showModal(detectMobile() ? 'LOGIN_MOBILE' : 'LOGIN');
        }
    };

    const showPinCodeModal = (retailPin, userInfoPending) => {
        if (auth?.user) {
            showModal('PIN_LOGIN', {
                isSpilId: true,
                onCloseModal: onClosePinLoginModal,
                stayOnPage: true,
                retailPin,
                userInfoPending,
            });
            hideModal('SPIL_ID_MODAL');
        } else {
            showModal(detectMobile() ? 'LOGIN_MOBILE' : 'LOGIN');
        }
    };

    const onClosePinLoginModal = () => {
        hideModal('PIN_LOGIN');
    };

    return (
        <SpilIdModalContent>
            <div style={{ width: '100%' }}>
                <NavigationBar
                    title={'Mit Spil-ID'}
                    clickHandler={hidePopup}
                    show={() => showModal('SPIL_ID_DESCRIPTION_MODAL')}
                    isClose
                />
                {/* Spil id card */}
                {failed ? null : (
                    <SpilIdCard
                        pending={pending}
                        barcode={barcode}
                        year={year}
                        hash={spilIdCard.hash}
                        fullName={name}
                        birthdate={birthdate}
                    />
                )}
            </div>
            {/* Spil id actions */}
            <SpilIdButtonsContainer>
                <SpilIdBtnLink
                    onClick={() => {
                        showPinCodeModal(retailPin, userInfoPending);
                    }}
                    margin={'0 0 12px'}
                    bold
                >
                    Skift kode{' '}
                    <div className={'right'}>
                        <div className={'dots'}>
                            <span className="dot" />
                            <span className="dot" />
                            <span className="dot" />
                            <span className="dot" />
                        </div>
                        <Icon
                            src={ArrowIcon}
                            height={'12px'}
                            width={'12px'}
                            color={'#333333'}
                        />
                    </div>
                </SpilIdBtnLink>
                <SpilIdBtn
                    onClick={showPCPreviewModal}
                    bold
                    margin={'0 0 12px'}
                >
                    Bestil fysisk Spil ID{' '}
                    <Icon
                        src={ArrowIcon}
                        height={'12px'}
                        width={'12px'}
                        color={'#333333'}
                    />
                </SpilIdBtn>
                <SpilIdBtnLink
                    href="https://bet25.dk/spil-id"
                    target={'_blank'}
                    margin={'0'}
                    bold
                >
                    Læs mere om Spil-ID{' '}
                    <Icon
                        src={ArrowIcon}
                        height={'12px'}
                        width={'12px'}
                        color={'#333333'}
                    />
                </SpilIdBtnLink>
            </SpilIdButtonsContainer>
        </SpilIdModalContent>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (modalId, data) => dispatch(ShowModal(modalId, data)),
        hideModal: (modalId) => dispatch(HideModal(modalId)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(detectMobile() ? SpilIdModal : withPopupWrapper(SpilIdModal));
