import {
    SPORT_INFO,
    SPORT_MENU_FETCH_SUCCESS,
    SPORT_TREE_FETCH_ERROR,
    SPORT_TREE_FETCH_PENDING,
    SPORT_TREE_FETCH_SUCCESS,
} from '../constants/sportTree';

const initialState = {
    pending: false,
    fetched: false,
    sportData: [],
    hotData: [],
    tournamentInSport: null,
    tournamentInGroup: null,
    groupInSport: null,
    sportMap: null,
    sportInfo: null,
    error: false,
};

function groupData(data) {
    let groupInSport = {};
    let tournamentInSport = {};
    let tournamentInGroup = {};

    data.map((sport) => {
        sport.itemList.map((group) => {
            groupInSport[group.id] = sport.id;

            group.itemList.map((tournamet) => {
                tournamentInSport[tournamet.id] = sport.id;
                tournamentInGroup[tournamet.id] = group.id;

                return group;
            });

            return group;
        });

        return sport;
    });

    return {
        groupInSport,
        tournamentInSport,
        tournamentInGroup,
    };
}

export default function sportTree(state = initialState, action) {
    switch (action.type) {
        case SPORT_TREE_FETCH_PENDING:
            return { ...state, pending: true };

        case SPORT_TREE_FETCH_SUCCESS:
            let sportsMap = {};

            action.payload.eventList.map((item) => {
                sportsMap[item.id] = item;

                return item;
            });

            let obj = groupData(action.payload.eventList);

            return {
                ...state,
                pending: false,
                fetched: true,
                sportData: action.payload.eventList,
                hotData: action.payload.hotList,
                sportMap: sportsMap,
                tournamentInSport: obj.tournamentInSport,
                tournamentInGroup: obj.tournamentInGroup,
                groupInSport: obj.groupInSport,
                error: false,
            };
        case SPORT_MENU_FETCH_SUCCESS:
            sportsMap = {};

            action.payload.map((item) => {
                sportsMap[item.id] = item;

                return item;
            });

            obj = groupData(action.payload);

            return {
                ...state,
                pending: false,
                fetched: true,
                sportData: action.payload,
                sportMap: sportsMap,
                tournamentInSport: obj.tournamentInSport,
                tournamentInGroup: obj.tournamentInGroup,
                groupInSport: obj.groupInSport,
                error: false,
            };

        case SPORT_TREE_FETCH_ERROR:
            return {
                ...state,
                pending: false,
                fetched: true,
                sportData: [],
                hotData: [],
                sportMap: null,
                error: action.payload,
            };

        case SPORT_INFO:
            return { ...state, sportInfo: action.payload };

        default:
            return state;
    }
}
