import React, { useEffect, useState } from 'react';
import { Withdraw } from '@it25syv/25syv-ui';
import { PaymentPayoutPageWrapper } from './styled';
import {
    detectMobile,
    disableBodyScroll,
    enableBodyScroll,
} from '../../utils/UiHelper';
import Text from '../../ui/Text';
import ViewPageToolbar from '../../components/ViewPageToolbar';
import { browserHistory } from 'react-router';
import UserService from '../../utils/UserService';
import UISpinner from '../../ecoui/UISpinner';
import { showModal as ShowModal } from '../../actions/UiActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import {
    changeRemoveBonusStatus as ChangeRemoveBonusStatus,
    getBonus as GetBonus,
    resetBonusState as ResetBonusState,
} from '../../actions/BonusActions';
import { checkSession as CheckSession } from '../../actions/AuthActions';

const PaymentPayoutPageNew = ({
    showModal,
    auth,
    bonus,
    getBonus,
    checkSession,
    changeRemoveBonusStatus,
    resetBonusState,
}) => {
    const [payoutPending, setPayoutPending] = useState(false);

    useEffect(() => {
        getBonus();
        disableBodyScroll();
        return () => {
            resetBonusState();
            enableBodyScroll();
        };
    }, []);

    useEffect(
        () => {
            if (bonus.bonusWasRemovedSuccessfully) {
                changeRemoveBonusStatus(false);
                getBonus();
            }
        },
        [bonus.bonusWasRemovedSuccessfully]
    );

    const transferMoneyHandler = (amount) => {
        setPayoutPending(true);
        UserService.MoneyWithdraw(amount)
            .then((res) => {
                //checkSession(); using this method because there is not cached data
                if (res.success) {
                    checkSession();
                    showModal('TRANSFER_MONEY_NOTIFICATION_MODAL', { amount });
                } else {
                    NotificationManager.error(res.errorMessage);
                }
                setPayoutPending(false);
            })
            .catch((err) => {
                NotificationManager.error(err);
                setPayoutPending(false);
            });
    };

    return (
        <PaymentPayoutPageWrapper className={detectMobile() ? 'mob' : ''}>
            {detectMobile() ? (
                <ViewPageToolbar
                    title={'Udbetaling'}
                    onBackBtn={() => {
                        browserHistory.goBack();
                    }}
                />
            ) : (
                <Text
                    height={'56px'}
                    width={'100%'}
                    size={'16'}
                    lh={'24'}
                    flex={'0'}
                    bold
                    color={'#1e1e1e'}
                    align={'center'}
                    margin={'0'}
                    padding={'16px'}
                    boxSizing={'border-box'}
                    border={'border-bottom: 1px solid #DFE5ED'}
                >
                    Udbetaling
                </Text>
            )}
            {bonus.fetched ? (
                <Withdraw
                    product={'sport'}
                    userData={{
                        status: auth?.user?.status,
                        balance: auth?.user?.balance,
                    }}
                    bonusesData={bonus.bonuses}
                    onRemove={(bonusAmount) => {
                        showModal('REMOVE_BONUS_CONFIRM_MODAL', {
                            bonusAmount,
                        });
                    }}
                    transferMoneyHandler={(amount) => {
                        transferMoneyHandler(amount);
                    }}
                    redirectUrl={() => (window.location.pathname = '/casino')}
                    payoutPending={payoutPending}
                />
            ) : (
                <UISpinner />
            )}
        </PaymentPayoutPageWrapper>
    );
};

function mapStateToProps(state) {
    return { auth: state.auth, bonus: state.bonus };
}

function mapDispatchToProps(dispatch) {
    return {
        showModal: bindActionCreators(ShowModal, dispatch),
        getBonus: bindActionCreators(GetBonus, dispatch),
        resetBonusState: bindActionCreators(ResetBonusState, dispatch),
        checkSession: bindActionCreators(CheckSession, dispatch),
        changeRemoveBonusStatus: bindActionCreators(
            ChangeRemoveBonusStatus,
            dispatch
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentPayoutPageNew);
