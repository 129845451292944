import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { browserHistory, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { ViewTab, ViewTabs } from 'components/ViewTabs';
import { ViewOddsInput, ViewSwitch } from 'components/ViewInput';
import ViewOverlayLoader from 'components/ViewOverlayLoader';
import ViewSpinner from 'components/ViewSpinner';
import * as BetslipActions from 'actions/BetslipActions';
import * as TerminalBetslipActions from 'actions/TerminalBetslipActions';
import * as TerminalVirtualBetslipActions from 'actions/TerminalVirtualBetslipActions';
import * as UiActions from 'actions/UiActions';
import SectionCashout from 'containers/SectionCashout';
import 'css/BetslipPage.css';
import 'css/SectionRigntSidebar.scss';
import AcceptBetslipChanges from 'components/AcceptBetslipChanges';
import RememberCoupon from 'components/RememberCoupon';
import cookie from 'react-cookies';
import {
    getLiveEventByBet,
    getStakeAmountMTSStakeError,
    isLiveBetActive,
    isManagerEntrance,
    isMTSStakeError,
} from 'utils/Helper';
import NewsletterPermissionSwitch from 'features/RightSidebar/NewsletterPermissionSwitch';
import { NotificationManager } from 'react-notifications';
import PlayAndPay from 'features/PlayAndPay';
import { get } from 'lodash';
import Button from 'ui/Button';
import {
    AmountSuggestContainer,
    BetslipTitle,
    CouponRow,
} from 'features/RightSidebar/styled';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import BetslipItem from 'features/RightSidebar/BetslipItem';
import { isTerminal } from 'utils/UiHelper';
import { SYSTEM_NAMES_WEB } from '../../constants/appConfig';
import InfoIconRed from 'images/icons/info_circle_red.svg';
import {
    getButtonAmount,
    getButtonFreeBetLabel,
    getPossibleWinAmount,
    isPlaceBetDisabled,
    showSingleCustomBetErrorMessage,
} from '../Betslip/helperFreebet';

class RightSidebar extends Component {
    isVirtual = false;
    validationHandlers = {};

    constructor() {
        super();
        this.state = {
            currentTab: '0',
            activeDropdown: true,
            oddsValues: {},
            freeBetEnabled: false,
            hasPlacedBet: false,
            isEmailValidForNewsletter: true,
            isAcceptedBetslipChanges:
                cookie.load('isAcceptedBetslipChanges') === 'true',
            isRememberCoupon: cookie.load('isRememberCoupon') === 'true',
        };

        if (
            window.location.pathname === '/virtualbet' ||
            window.location.pathname.indexOf('/virtual-kamp') > -1
        ) {
            this.isVirtual = true;
        }
        this.suggestionAmounts = ['50', '100', '200', '300', '500', '1000'];
    }

    get betslip() {
        return this.isVirtual
            ? this.props.terminalVirtualBetslip
            : this.props.betslip;
    }

    get betslipActions() {
        return !isTerminal()
            ? this.props.betslipActions
            : this.isVirtual
                ? this.props.terminalVirtualBetslipActions
                : this.props.terminalBetslipActions;
    }

    onRightBtnHandler() {
        this.props.betslipActions.clearAllMarkets();
    }

    onClickFreeBetUse() {
        this.setState({ freeBetEnabled: !this.state.freeBetEnabled });
    }

    onCloseHandler() {
        browserHistory.push('/sport/');
    }

    showStakeSuggestionNotification() {
        NotificationManager.error(
            '',
            `Indsatsen er for høj. Det maksimale beløb for dette spil er ${getStakeAmountMTSStakeError(
                this.props.betslip.error
            )} kr.`,
            10 * 1000
        );
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (isTerminal()) {
            if (nextProps.location.pathname !== this.props.location.pathname) {
                this.isVirtual =
                    nextProps.location.pathname === '/virtualbet' ||
                    nextProps.location.pathname.indexOf('/virtual-kamp') > -1;
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.betslip.error && this.props.betslip.error) {
            if (isMTSStakeError(this.props.betslip.error)) {
                this.showStakeSuggestionNotification();
            }
        }
        if (
            prevProps.betslip.hasMarkets.length !==
            this.props.betslip.hasMarkets.length
        ) {
            if (this.props.betslip.hasMarkets.length === 0) {
                this.setState({
                    ...this.state,
                    activeDropdown: true,
                });
            }
        }
    }

    setEmailValidity = (isValid) => {
        this.setState({ isEmailValidForNewsletter: isValid });
    };

    onClickPlacerBtnHandler = () => {
        if (isTerminal()) {
            const { terminalSession, uiActions } = this.props;
            const isActiveSession = terminalSession && terminalSession.fetched;

            if (isActiveSession) {
                this.proceedTerminalBet();
                this.setState({ hasPlacedBet: true });
            } else {
                uiActions.setLoginModalStatus(true);
            }
        } else if (!isTerminal() && this.props.betslip.hasMarkets) {
            if (this.props.auth && this.props.auth.user) {
                if (this.props.betslip.customBetEventId !== null) {
                    this.props.betslipActions.proceedCustomBet(
                        this.props.betslip.totalOdds,
                        this.props.betslip.customBetStake,
                        this.props.betslip,
                        this.state.freeBetEnabled ? 1 : 0,
                        this.props.auth.user.freeBet,
                        this.props.auth?.user?.freeBetSettleType === 1
                    );
                    this.setState({
                        hasPlacedBet: true,
                        freeBetEnabled: false,
                    });
                } else {
                    this.props.betslipActions.proceed(
                        this.props.betslip,
                        this.state.isAcceptedBetslipChanges,
                        this.state.isRememberCoupon,
                        this.state.freeBetEnabled ? 1 : 0,
                        this.props.auth.user.freeBet
                    );
                    if (this.props.auth.user.freeBet > 0) {
                        this.props.auth.user.freeBetAmount = 0;
                        this.props.auth.user.freeBet = 0;
                    }
                    this.setState({
                        hasPlacedBet: true,
                        freeBetEnabled: false,
                    });
                }
            } else {
                browserHistory.push('/sport/log-ind');
            }
        } else {
            if (this.props.auth && this.props.auth.user) {
                this.props.betslipActions.proceed(
                    this.props.betslip,
                    this.state.isAcceptedBetslipChanges,
                    this.state.isRememberCoupon,
                    this.state.freeBetEnabled ? 1 : 0,
                    this.props.auth.user.freeBet
                );
                if (this.props.auth.user.freeBet > 0) {
                    this.props.auth.user.freeBetAmount = 0;
                    this.props.auth.user.freeBet = 0;
                }
                this.setState({ hasPlacedBet: true, freeBetEnabled: false });
            } else {
                browserHistory.push('/sport/log-ind');
            }
        }
    };

    onClickPlaceBetHandler = () => {
        const customBetStake = this.props.betslip.customBetStake;
        const betAmount = this.props.betslip.singleStake;
        const totalStakeAmount = this.props.betslip.system.totalStake;
        const userBalance = this.props.auth.user.balance;

        if ((betAmount || totalStakeAmount || customBetStake) > userBalance) {
            this.props.uiActions.showModal('LOW_BALANCE');
        }
    };

    proceedTerminalBet(isPlayAndPay) {
        const {
            shopConfig,
            spilId,
            uiActions,
            terminalVirtualBetslipActions,
            terminalBetslipActions,
        } = this.props;

        // if (!spilId || !spilId.user_id) {
        //     uiActions.setSpilIdModalStatus(true);
        //     return;
        // }

        if (this.isVirtual) {
            terminalVirtualBetslipActions.proceed(
                this.props.terminalVirtualBetslip,
                isPlayAndPay,
                spilId
            );
            this.setState({ oddsValues: {} });
        } else {
            terminalBetslipActions.proceed(
                this.props.betslip,
                this.state.isAcceptedBetslipChanges,
                this.state.isRememberCoupon,
                isPlayAndPay,
                get(shopConfig, 'data.minOdds', 1.2),
                spilId
            );
        }
    }

    validateInputs() {
        Object.keys(this.validationHandlers).map((key) => {
            this.validationHandlers[key]();

            return key;
        });
    }

    valueHandler(name, val) {
        this.validationHandlers[name](val);

        let oddsValues = this.state.oddsValues;

        oddsValues[name] = val;

        this.setState({ oddsValues });

        if (!isTerminal()) {
            if (this.props.betslip.customBetEventId !== null) {
                this.props.betslipActions.setCustomBetMarket(
                    val,
                    this.props.betslip.totalOdds
                );
            } else {
                this.props.betslipActions.setMarket(
                    val,
                    this.props.betslip.totalOdds
                );
            }
        } else {
            if (!this.isVirtual) {
                if (this.props.betslip.customBetEventId !== null) {
                    this.props.betslipActions.setCustomBetMarket(
                        val,
                        this.props.betslip.totalOdds
                    );
                } else {
                    this.props.betslipActions.setMarket(
                        val,
                        this.props.betslip.totalOdds
                    );
                }
            } else {
                this.props.terminalVirtualBetslipActions.setMarket(
                    val,
                    this.props.terminalVirtualBetslip.totalOdds
                );
            }
        }
    }

    valueSystemHandler(index, name, val) {
        this.validationHandlers[name]
            ? this.validationHandlers[name](val)
            : null;

        let oddsValues = this.state.oddsValues;
        oddsValues[name] = val;

        this.setState({ oddsValues });

        this.betslipActions.setSystemMarket(val, index);
    }

    onRemoveSystemHandler(index, item) {
        this.setState({ oddsValues: {} });
        if (!isTerminal()) {
            if (this.props.betslip.customBetEventId !== null) {
                this.props.betslipActions.clearSingleCustomBetMarket(
                    item.marketId,
                    item.betNr
                );
            } else {
                if (this.props.betslip.betList.length > 1) {
                    this.props.betslipActions.clearSystemMarket(
                        item.eventId,
                        item.gameId
                    );
                } else {
                    this.props.betslipActions.clearSingleMarket(
                        item.eventId,
                        item.gameId
                    );
                }
            }
        } else {
            if (!this.isVirtual) {
                this.props.terminalBetslipActions.removeMarket(item.gameId);
            } else {
                this.props.terminalVirtualBetslipActions.removeMarket(
                    item.gameId
                );
            }
        }

        this.setState({ oddsValues: {} });
    }

    onClickAceptBtnHandler() {
        this.betslipActions.acceptMarkets();
    }

    onClickClearAll() {
        this.props.betslip.customBetEventId !== null ||
        this.props.betslip.placedCustomBetMarkets.length > 0
            ? this.betslipActions.clearAllCustomBetMarkets()
            : this.betslipActions.clearAllMarkets();

        this.setState({ oddsValues: {} });
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.auth &&
            nextProps.betslip.betList.length === 0 &&
            nextProps.auth.user &&
            this.state.currentTab !== '1'
        ) {
            this.setState({ currentTab: '1' });
        } else if (nextProps.betslip.betList.length > 0) {
            this.setState({ currentTab: '0' });
        }

        if (!this.isVirtual) {
            if (nextProps.betslip.betList !== this.props.betslip.betList) {
                this.setState({ oddsValues: {} });
            }
        } else {
            if (
                nextProps.terminalVirtualBetslip.betList !==
                this.props.terminalVirtualBetslip.betList
            ) {
                this.setState({ oddsValues: {} });
            }
        }
    }

    componentWillMount() {
        if (
            this.props.betslip.betList.length === 0 &&
            this.props.auth &&
            this.props.auth.user
        ) {
            this.setState({ currentTab: '1' });
        }
    }

    onStakeInputFocusHandler(event, valueHandler, resetHandler) {
        this.props.uiActions.setKeyboardPopupStatus({
            element: event.target,
            valueHandler,
            resetHandler,
        });
    }

    inputCheckHandler(name, handler) {
        this.validationHandlers[name] = handler;
    }

    onAcceptBetslipChanged = (status) => {
        this.setState({
            isAcceptedBetslipChanges: status,
        });

        let d = new Date();

        d.setTime(d.getTime() + 180 * 24 * 60 * 60 * 1000);

        cookie.save('isAcceptedBetslipChanges', status, {
            path: '/',
            expires: d,
        });
    };

    onRememberCouponChanged = (status) => {
        this.setState({
            isRememberCoupon: status,
        });

        let d = new Date();

        d.setTime(d.getTime() + 180 * 24 * 60 * 60 * 1000);

        cookie.save('isRememberCoupon', status, {
            path: '/',
            expires: d,
        });
    };

    isLiveBetActive(bet) {
        const event = getLiveEventByBet(bet, this.props.liveEvents.data);
        const market = this.props.betslip.liveMarkets[bet.gameId];
        return isLiveBetActive(bet, event, market);
    }

    handleSuggestsAmountBtnClick = (amount) => () => {
        if (this.betslip.betList.length === 0) {
            return;
        }
        const oddsValues = {};
        const isSystemBet =
            this.betslip.betList.length > 1 &&
            this.betslip.customBetEventId === null;

        if (isSystemBet) {
            var systemNames = this.betslip.system.names.filter(function(el) {
                return el != null;
            });
            const topSystemName = systemNames[systemNames.length - 1];
            this.betslipActions.setSystemMarket(amount, systemNames.length - 1);
            oddsValues[`odds-${topSystemName}`] = amount;
        } else {
            if (this.betslip.customBetEventId !== null) {
                this.betslipActions.setCustomBetMarket(
                    amount,
                    this.betslip.totalOdds
                );
            } else {
                this.betslipActions.setMarket(amount, this.betslip.totalOdds);
            }
            oddsValues['odds-0'] = amount;
        }

        this.setState({ oddsValues });
    };

    isShowSuggestionInputs() {
        return this.betslip.betList.length > 0;
    }

    getPlayAndPayButton() {
        const { shopConfig, terminalSession } = this.props;
        const button = (
            <PlayAndPay
                onClick={() => {
                    this.proceedTerminalBet(true);
                }}
            />
        );

        const isManager = window.TELE_SALE && isManagerEntrance();
        //!terminalSession.fetched or amount is lower than bet
        const shouldShowButton =
            isTerminal() &&
            !isManager &&
            !terminalSession.fetched &&
            shopConfig.data.playAndPayAllowed;

        return shouldShowButton ? button : null;
    }
    shouldFreeBetShow(betslip) {
        const user = this.props.auth.user;
        const isFreeBetConditions =
            user && user?.freeBet && user?.freeBet !== 0 && user?.freeBetLive;

        const isLoggedIn = () => this.props.auth && this.props.auth.user;
        const isBiggerOdds = () =>
            this.props?.betslip?.totalOdds >
            this.props?.auth?.user?.freeBetMinOdds;

        const shouldShowButton = () => {
            let shouldShow;
            if (user.freeBetAmount > 0 && user.freeBetType === 0) {
                shouldShow = true;
            } else if (
                user?.freeBetLive &&
                (user?.freeBetType === 0 || user?.freeBetType === 3)
            ) {
                shouldShow =
                    isFreeBetConditions &&
                    betslip.betList.some(
                        (event) =>
                            (event.competitionId === user.freeBetEventId ||
                                event.eventId === user.freeBetEventId) &&
                            event.live
                    );
            } else if (
                user?.freeBetLive &&
                (user?.freeBetType === 1 || user?.freeBetType === 3)
            ) {
                shouldShow =
                    isFreeBetConditions &&
                    betslip.betList.some(
                        (event) =>
                            event.eventId === user.freeBetEventId && event.live
                    );
            } else if (
                user?.freeBetLive &&
                (user?.freeBetType === 2 || user?.freeBetType === 3)
            ) {
                shouldShow =
                    isFreeBetConditions &&
                    betslip.betList.some(
                        (event) =>
                            event.competitionId === user.freeBetEventId &&
                            event.live
                    );
            } else if (!user?.freeBetLive) {
                shouldShow = user?.freeBet && user?.freeBet !== 0;
            }
            return shouldShow;
        };
        return isLoggedIn() && isBiggerOdds() && shouldShowButton();
    }

    FreeBetRow(betslip) {
        let text =
            this.props.auth?.user?.freeBetSettleType === 1
                ? 'Freebet'
                : this.props.auth?.user?.freeBetLive === 1
                    ? 'Live Bet'
                    : 'Ekstra Bet';

        if (this.shouldFreeBetShow(betslip)) {
            return (
                <div className={'WrapperFreeBetButton'}>
                    <Button
                        className={`view-button ${
                            this.state.freeBetEnabled ? 'green-btn' : ''
                        }`}
                        onClick={() => {
                            this.onClickFreeBetUse();
                        }}
                        bold
                    >
                        {`Benyt mit ${text} på ${
                            this.props.auth.user.freeBet
                        } kr.`}{' '}
                    </Button>
                </div>
            );
        } else {
            return null;
        }
    }

    amountButtons() {
        return (
            this.isShowSuggestionInputs() && (
                <AmountSuggestContainer>
                    {this.suggestionAmounts.map((amountValue) => (
                        <Button
                            key={amountValue}
                            onClick={this.handleSuggestsAmountBtnClick(
                                amountValue
                            )}
                            padding="15px 0"
                            width="30%"
                            fontSize="12px"
                            margin="5px 0"
                        >
                            {amountValue} kr.
                        </Button>
                    ))}
                </AmountSuggestContainer>
            )
        );
    }

    showErorMessage(betslip) {
        if (this.state.freeBetEnabled && this.props?.auth?.user?.freeBet > 0) {
            return false;
        } else {
            if (betslip?.system?.stake?.length) {
                return betslip.system.stake.some((bet) => bet > 0 && bet < 5);
            } else {
                return (
                    (betslip?.singleStake > 0 && betslip?.singleStake < 10) ||
                    (betslip?.customBetStake > 0 &&
                        betslip?.customBetStake < 10)
                );
            }
        }
    }

    getCashoutView(user) {
        if (user) {
            return <SectionCashout />;
        } else {
            return (
                <p className="p10 text-center just-info">
                    Du skal først logge ind
                </p>
            );
        }
    }

    render() {
        let errorMessageSingle = 'Min. indsatsen er 10 kr.';
        let errorMessageSystem = 'Min. indsatsen er 5 kr.';
        const { betslip, auth } = this.props;
        const { hasPlacedBet } = this.state;
        const isDerbyPermissionNull = auth.user?.derbyPermission === null;

        const userEmail = auth.user?.email;
        const shouldDisplayNewsletter = auth.user?.newsletter === 0;

        if (this.isVirtual) {
            /**
             * check state
             * this.props.terminalBetslipActions.removeMarket
             */
            betslip = this.props.terminalVirtualBetslip;
            betslip.betList = betslip.betList.filter((bet) => {
                const firstEventTime = moment(bet.time);
                const currentTime = moment();
                const timeToMatch = firstEventTime.diff(
                    currentTime,
                    'milliseconds'
                );

                return timeToMatch > 0;
            });
        }

        let breakLine =
            betslip.hasMarkets.length > 1
                ? betslip.system.totalStake.toString().length > 4
                : betslip.singleStake.toString().length > 4 ||
                  betslip.customBetStake?.toString().length > 4;

        const betSlipButtons = (
            <div className="relative-container">
                {betslip.pending && <ViewSpinner className="view-spinner" />}
                {!betslip.changed ? (
                    <Fragment>
                        <Button
                            margin="25px auto 0"
                            width="calc(100% - 30px)"
                            fontSize={'13px'}
                            active={
                                !isPlaceBetDisabled(
                                    betslip,
                                    this.props?.auth?.user,
                                    this.state.freeBetEnabled
                                )
                            }
                            disabled={
                                !this.state.isEmailValidForNewsletter ||
                                isPlaceBetDisabled(
                                    betslip,
                                    this.props?.auth?.user,
                                    this.state.freeBetEnabled
                                ) ||
                                betslip.pending
                            }
                            onClick={() => {
                                this.onClickPlacerBtnHandler();
                                this.onClickPlaceBetHandler();
                            }}
                            yellow
                        >
                            {`Placer væddemål`} {breakLine && <br />}
                            {`${getButtonAmount(
                                betslip,
                                this.props.auth,
                                this.state.freeBetEnabled
                            )} ${getButtonFreeBetLabel(
                                this.props.auth,
                                this.state.freeBetEnabled
                            )}`}
                        </Button>
                        {isTerminal() ? this.getPlayAndPayButton() : null}
                    </Fragment>
                ) : (
                    <Button
                        active
                        margin="25px auto 0"
                        width="calc(100% - 30px)"
                        disabled={betslip.pending}
                        onClick={this.onClickAceptBtnHandler.bind(this)}
                    >
                        Accepter oddsændring
                    </Button>
                )}
            </div>
        );

        const user = this.props.auth ? this.props.auth.user : null;
        const betListView =
            betslip.betList.length > 1 &&
            betslip.customBetEventId === null &&
            betslip.placedCustomBetMarkets.length === 0 ? (
                <section>
                    <Wrapper padding={'0'}>
                        <div className={'betListViewWrapper'}>
                            <Wrapper flex justify="space-between" padding="0">
                                <Text
                                    condensed
                                    size="14"
                                    lh={'19'}
                                    color="#333333"
                                    padding={'0'}
                                    margin={'0 0 10px'}
                                >
                                    {`${
                                        SYSTEM_NAMES_WEB[
                                            betslip.betList.length - 1
                                        ]
                                    }:`}
                                    <span className={'arrow-right'} />
                                </Text>

                                <Text
                                    align="right"
                                    condensed
                                    size="14"
                                    lh={'19'}
                                    color="#333333"
                                    padding={'0'}
                                    margin={'0 0 10px'}
                                >
                                    {betslip.totalOdds.toFixed(2)}
                                </Text>
                            </Wrapper>
                            <Wrapper
                                flex
                                padding={'0 0 10px'}
                                justify={'space-between'}
                            >
                                <div
                                    className={`dropdown ${
                                        this.state.activeDropdown
                                            ? 'active'
                                            : ''
                                    } `}
                                    onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            activeDropdown: !this.state
                                                .activeDropdown,
                                        });
                                    }}
                                >
                                    {`Vis ${
                                        this.state.activeDropdown
                                            ? 'flere'
                                            : 'færre'
                                    } spil`}
                                </div>
                            </Wrapper>
                            <Wrapper
                                padding={'0'}
                                margin={'-35px 0 0'}
                                height={`${
                                    this.state.activeDropdown ? '26px' : 'auto'
                                }`}
                                transition={'all 0.4s ease-in-out'}
                                overflow={'hidden'}
                            >
                                {(!isTerminal()
                                    ? betslip.system.names
                                    : this.isVirtual
                                        ? [
                                              betslip.system.names[
                                                  betslip.system.names.length -
                                                      1
                                              ],
                                          ]
                                        : betslip.system.names.slice(0, 15)
                                )
                                    .filter((name) => name)
                                    .map((name, index) => (
                                        <Wrapper
                                            key={index}
                                            padding="0"
                                            flexAlign="center"
                                        >
                                            <CouponRow>
                                                <Text
                                                    flex="1 1 30%"
                                                    width="25%"
                                                    condensed
                                                    padding="0"
                                                    size="x1"
                                                    color="#333333"
                                                >
                                                    {index ===
                                                    betslip.betList.length - 1
                                                        ? ''
                                                        : `${name}:`}
                                                </Text>
                                                <Wrapper
                                                    padding="0"
                                                    flexBasis="36%"
                                                >
                                                    <ViewOddsInput
                                                        label={
                                                            !this.isVirtual
                                                                ? betslip
                                                                      .betList
                                                                      .length -
                                                                      1 ===
                                                                  index
                                                                    ? ``
                                                                    : `${
                                                                          betslip
                                                                              .system
                                                                              .multi[
                                                                              index
                                                                          ]
                                                                      } x`
                                                                : `${
                                                                      betslip.system
                                                                  } kr`
                                                        }
                                                        withoutLabel={
                                                            betslip.betList
                                                                .length -
                                                                1 ===
                                                            index
                                                        }
                                                        disabled={
                                                            this.state
                                                                .freeBetEnabled
                                                        }
                                                        name={'odds-' + name}
                                                        valueHandler={this.valueSystemHandler.bind(
                                                            this,
                                                            index
                                                        )}
                                                        placeholder="0.00"
                                                        val={
                                                            this.state
                                                                .oddsValues[
                                                                'odds-' + name
                                                            ]
                                                                ? this.state
                                                                      .oddsValues[
                                                                      'odds-' +
                                                                          name
                                                                  ]
                                                                : ''
                                                        }
                                                        validate={'minOdds'}
                                                        minOdds={5}
                                                        checkHandler={this.inputCheckHandler.bind(
                                                            this
                                                        )}
                                                        onFocusHandler={this.onStakeInputFocusHandler.bind(
                                                            this
                                                        )}
                                                    />
                                                </Wrapper>
                                            </CouponRow>
                                        </Wrapper>
                                    ))
                                    .reverse()}
                            </Wrapper>
                            {this.amountButtons()}
                            {!isTerminal() && this.FreeBetRow(betslip)}
                            <Wrapper
                                flex
                                justify="space-between"
                                padding="10px 0 0"
                            >
                                <Text
                                    condensed
                                    size="x1"
                                    color="#333333"
                                    padding={'0'}
                                    margin={'0'}
                                >
                                    Mulig gevinst:
                                </Text>

                                <Text
                                    align="right"
                                    condensed
                                    size="x1"
                                    color="#333333"
                                    padding={'0'}
                                >
                                    {getPossibleWinAmount(
                                        true,
                                        this.state.freeBetEnabled,
                                        user?.freeBet,
                                        user?.freeBetLive,
                                        betslip
                                    )}{' '}
                                    kr.
                                </Text>
                            </Wrapper>
                        </div>

                        {betSlipButtons}
                        {this.showErorMessage(betslip) && (
                            <Wrapper
                                width={'100%'}
                                flex
                                flexAlign={'center'}
                                flexDirection={'row'}
                                justify={'center'}
                                padding={'12px 0 0'}
                                align={'center'}
                            >
                                <img src={InfoIconRed} alt={'infoIcon'} />
                                <Text
                                    width={'auto'}
                                    size={'12'}
                                    lh={'16'}
                                    color={'#DB1C1B'}
                                    margin={'0 0 0 5px'}
                                    padding={'0'}
                                    align={'center'}
                                    display={'inline-block'}
                                    flex={'0 auto'}
                                >
                                    {errorMessageSystem}
                                </Text>
                            </Wrapper>
                        )}
                    </Wrapper>
                </section>
            ) : betslip.customBetEventId !== null ||
            betslip.placedCustomBetMarkets.length > 0 ||
            betslip.betList.length === 1 ? (
                <Wrapper padding={'0'}>
                    <div className={'betListViewWrapper'}>
                        <Wrapper
                            width="100%"
                            flex
                            justify={'space-between'}
                            flexAlign="center"
                            padding="0"
                        >
                            <Text
                                condensed
                                size="14"
                                lh={'14'}
                                color="#333"
                                padding="0"
                            >
                                {(betslip.customBetEventId !== null ||
                                    betslip.placedCustomBetMarkets.length >
                                        0) &&
                                betslip.betList.length > 1
                                    ? 'Kombination:'
                                    : 'Single:'}
                            </Text>
                            <Text
                                condensed
                                size="14"
                                lh={'14'}
                                color="#333"
                                padding="0"
                                align={'right'}
                            >
                                {betslip.totalOdds.toFixed(2)}
                            </Text>
                        </Wrapper>
                        <Wrapper
                            width={'100%'}
                            margin={'10px 0 0'}
                            padding="0"
                            justify="flex-end"
                        >
                            <ViewOddsInput
                                withoutLabel
                                label=""
                                name="odds-0"
                                placeholder={`0.00`}
                                disabled={this.state.freeBetEnabled}
                                valueHandler={this.valueHandler.bind(this)}
                                val={
                                    this.state.oddsValues['odds-0']
                                        ? this.state.oddsValues['odds-0']
                                        : ''
                                }
                                validate="minOdds"
                                minOdds={10}
                                checkHandler={this.inputCheckHandler.bind(this)}
                                onFocusHandler={this.onStakeInputFocusHandler.bind(
                                    this
                                )}
                            />
                        </Wrapper>

                        {!isTerminal() && this.FreeBetRow(betslip)}

                        {this.amountButtons()}

                        <Wrapper
                            padding="10px 0 0"
                            flex
                            justify={'space-between'}
                        >
                            <Text
                                condensed
                                size="14"
                                width={'auto'}
                                color="#333333"
                                padding="0"
                                margin={'0'}
                            >
                                Mulig gevinst:
                            </Text>
                            <Text
                                condensed
                                size="14"
                                width={'auto'}
                                color="#333333"
                                padding="0"
                                align={'right'}
                            >
                                {getPossibleWinAmount(
                                    false,
                                    this.state.freeBetEnabled,
                                    user?.freeBet,
                                    user?.freeBetLive,
                                    betslip
                                )}{' '}
                                kr.
                            </Text>
                        </Wrapper>
                    </div>
                    {betSlipButtons}

                    {showSingleCustomBetErrorMessage(
                        betslip,
                        this.state.freeBetEnabled
                    ) && (
                        <Wrapper
                            width={'100%'}
                            flex
                            flexAlign={'center'}
                            flexDirection={'row'}
                            justify={'center'}
                            padding={'12px 0 0'}
                            align={'center'}
                        >
                            <img src={InfoIconRed} alt={'infoIcon'} />
                            <Text
                                width={'auto'}
                                size={'12'}
                                lh={'16'}
                                color={'#DB1C1B'}
                                margin={'0 0 0 5px'}
                                padding={'0'}
                                align={'center'}
                                display={'inline-block'}
                                flex={'0 auto'}
                            >
                                {errorMessageSingle}
                            </Text>
                        </Wrapper>
                    )}
                </Wrapper>
            ) : (
                <Text
                    condensed
                    size="14"
                    color="#333333"
                    padding="15px 0"
                    align="center"
                    bold
                >
                    Klik på et odds for at tilføje det <br />
                    til din kupon
                </Text>
            );

        const items = betslip.betList.map((item, index) => {
            return (
                <BetslipItem
                    isLiveBetDisabled={
                        item.live &&
                        item.live !== '0' &&
                        !this.isLiveBetActive(item)
                    }
                    isVirtual={this.isVirtual}
                    key={item.marketName + index}
                    item={item}
                    changed={betslip.changed}
                    onRemoveHandler={this.onRemoveSystemHandler.bind(
                        this,
                        index
                    )}
                    itemError={this.props.betslip.itemError}
                    isCustomBet={
                        this.props.betslip.customBetEventId !== null ||
                        betslip.placedCustomBetMarkets.length > 0
                    }
                    customBetEventName={this.props.betslip.customBetEventName}
                />
            );
        });

        {
            this.getCashoutView(user);
        }

        if (!isTerminal()) {
            return (
                <div className="section-right-sidebar">
                    <ViewTabs
                        currentTab={this.state.currentTab}
                        className="right-sidebar-tabs"
                    >
                        <ViewTab label="Kupon" className="coupon-tab">
                            {items}
                            {betListView}
                            {betslip.betList.length > 0 ? (
                                <Button
                                    width="100%"
                                    margin="20px 0px 10px 0px"
                                    transparent
                                    color="#ED4949"
                                    padding="0"
                                    onClick={this.onClickClearAll.bind(this)}
                                >
                                    Ryd kupon
                                </Button>
                            ) : null}
                            {betslip.betList.length > 0 ? (
                                <AcceptBetslipChanges
                                    onChange={this.onAcceptBetslipChanged}
                                    accepted={
                                        this.state.isAcceptedBetslipChanges
                                    }
                                />
                            ) : null}
                        </ViewTab>

                        <ViewTab label="Cashout" className="cashout-tab">
                            {this.getCashoutView(user)}
                        </ViewTab>
                    </ViewTabs>

                    {betslip.timer ? <ViewOverlayLoader /> : null}

                    {betslip.betList.length > 0 &&
                        betslip.customBetEventId === null && (
                            <>
                                <RememberCoupon
                                    onChange={this.onRememberCouponChanged}
                                    accepted={this.state.isRememberCoupon}
                                />

                                {shouldDisplayNewsletter &&
                                isDerbyPermissionNull ? (
                                    <NewsletterPermissionSwitch
                                        userEmail={userEmail}
                                        onEmailValidityChange={
                                            this.setEmailValidity
                                        }
                                        isNewsletterSubscribed={
                                            auth.user.newsletter === 1
                                        }
                                        hasUserPlacedBet={hasPlacedBet}
                                    />
                                ) : null}
                            </>
                        )}
                </div>
            );
        } else {
            const terminalLiveOrVirtualBet =
                isTerminal() &&
                (this.isVirtual || window.location.pathname === '/livebet');
            const betslipIsActive = betslip.betList.length > 0;

            return (
                <div className="section-right-sidebar">
                    <BetslipTitle>Kupon</BetslipTitle>
                    {items}
                    {betListView}
                    {!betslip.pending && betslipIsActive ? (
                        <Button
                            transparent
                            color="#ED4949"
                            margin="0"
                            width="100%"
                            padding="0 0 10px 0"
                            onClick={this.onClickClearAll.bind(this)}
                        >
                            Ryd alle
                        </Button>
                    ) : null}
                    {!this.isVirtual &&
                    betslipIsActive &&
                    betslip.customBetEventId === null ? (
                        <div>
                            <RememberCoupon
                                onChange={this.onRememberCouponChanged}
                                accepted={this.state.isRememberCoupon}
                            />
                        </div>
                    ) : null}
                    {terminalLiveOrVirtualBet &&
                        betslipIsActive && (
                            <div id="terminal-warning-text">
                                Dette spil kan IKKE annulleres
                            </div>
                        )}
                    {betslip.timer ? <ViewOverlayLoader /> : null}
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        betslip: state.betslip,
        liveEvents: state.liveEvents,
        terminalVirtualBetslip: state.terminalVirtualBetslip,
        terminalSession: state.terminalSession,
        shopConfig: state.shopConfig,
        spilId: state.spilId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        betslipActions: bindActionCreators(BetslipActions, dispatch),
        terminalBetslipActions: bindActionCreators(
            TerminalBetslipActions,
            dispatch
        ),
        terminalVirtualBetslipActions: bindActionCreators(
            TerminalVirtualBetslipActions,
            dispatch
        ),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(RightSidebar)
);
