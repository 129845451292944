import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import ViewPageWrapper from 'components/ViewPageWrapper';
import 'css/ViewUserMenu.scss';
import { ViewSwitch } from 'components/ViewInput';
import {
    disableBodyScroll,
    enableBodyScroll,
    formatDate,
    formatDenmarkCurrency,
} from 'utils/UiHelper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthActions from 'actions/AuthActions';
import * as UiActions from 'actions/UiActions';
import { LOCAL_STORAGE_KEYS } from 'constants/appConfig';
import AuthService from 'utils/AuthService';

const menuPages = [
    'sport/konto/indbetaling',
    'sport/konto/udbetaling',
    'sport/konto/bonus',
    'sport/konto/voucher',
    'sport/konto/spil-id',
    'sport/konto/kontohistorik',
    'sport/konto/indbetalingsgraense',
    'sport/konto/selvudelukkelse',
    'sport/konto/brugerinfo',
    'sport/konto/nyhedsbrev',
];

class ViewUserMenu extends Component {
    static defaultProps = {
        onCloseHandler: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            activePage: '',
            spilIdBarCode: null,
            pinLoginStatus: this.isPinTokenExist(),
            pinModalStatus: false,
        };
    }

    onClickCloseBtn() {
        browserHistory.push('/sport/');
        this.props.uiActions.hideModal('USER_MENU');
    }

    onLogoutClick() {
        this.props.uiActions.hideModal('USER_MENU');
        this.props.uiActions.setSignupModalStatus(false);
        this.props.authActions.logout();
        browserHistory.push('/sport/');
    }

    _goToPage(link) {
        let page = window.location.pathname.substring(1);
        link && this.setState({ activePage: page });
        this.props.uiActions.hideModal('USER_MENU');
        browserHistory.push('/sport' + link);
    }
    updatePinLoginState() {
        this.setState({ pinLoginStatus: this.isPinTokenExist() });
    }

    componentDidMount() {
        const page = window.location.pathname.substring(1);
        if (page && !this.state.activePage) {
            this.setState({ activePage: page });
        }
        // hide before spilId release

        /*const userId = this.props.auth?.user?.userId;
        const retailHash = this.props.auth?.user?.retailHash;
        SpilIdService.getCard(userId, retailHash).then((res) => {
            if (res.success && res?.data?.barcode) {
                this.setState({ spilIdBarCode: res?.data?.barcode });
            }
            /!** if user failed checking on rofus status there will be no barcode in the response 'barcode: null', ask about text to show for that case *!/
        });*/
        disableBodyScroll();
    }

    componentWillUnmount() {
        enableBodyScroll();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let page = window.location.pathname.substring(1);
        if (
            page &&
            page !== this.state.activePage &&
            menuPages.includes(page)
        ) {
            this._goToPage(page);
        }
        if (!prevProps.auth.user && this.props.auth.user) {
            this.updatePinLoginState();
        }
    }

    isPinTokenExist() {
        const token = localStorage.getItem(LOCAL_STORAGE_KEYS.PIN_TOKEN);
        const pinUser = localStorage.getItem(LOCAL_STORAGE_KEYS.PIN_USERNAME);
        const curUser = this.props.auth?.user?.userName;
        return (
            typeof token === 'string' &&
            token !== 'null' &&
            token.length > 0 &&
            curUser === pinUser
        );
    }
    onClosePinLoginModal = () => {
        this.props.uiActions.hideModal('PIN_LOGIN');
        this.updatePinLoginState();
    };

    pinLoginSwitcherHandler = (status) => {
        this.setState({ pinLoginStatus: status, pinModalStatus: status });
        if (status) {
            this.props.uiActions.showModal('PIN_LOGIN', {
                mode: 'setup',
                onCloseModal: this.onClosePinLoginModal,
            });
        } else {
            localStorage.setItem(LOCAL_STORAGE_KEYS.PIN_TOKEN, null);
            AuthService.ClearHash().then(() => {
                this.updatePinLoginState();
            });
        }
    };

    shouldShowSpilId() {
        return false; // Temporary hide before release SpilID
        // return (
        //     localStorage.getItem('spil_id_switcher') === '1' &&
        //     this.state.spilIdBarCode
        // );
    }
    onClickInfoPopup = () => {
        this.props.showModal('SPIL_ID_INFO');
    };

    getLastLoginDate = () => {
        let user = this.props?.auth?.user;
        let date = user?.extraInfo?.lastLogin
            ? user.extraInfo?.lastLogin
            : new Date();

        return `Sidste log ind: ${formatDate(date, 'DD. MMM [kl.] HH:mm')}`;
    };

    render() {
        return (
            <ViewPageWrapper
                title={this.getLastLoginDate()}
                titleStyle={{ fontSize: '12px', lineHeight: '12px' }}
                buttonType={ViewPageWrapper.types.LOGOUT}
                onCloseHandler={this.onClickCloseBtn.bind(this)}
                onRightBtnHandler={this.onLogoutClick.bind(this)}
                additionalClass="userMenu"
            >
                <section className="view-user-menu">
                    <div className="user-header">
                        {this.shouldShowSpilId() && (
                            <div className="spil-id-container">
                                <p className="spil-id-top-tooltip">
                                    Dit Spil-ID{' '}
                                    <i
                                        onClick={this.onClickInfoPopup}
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                    />
                                </p>
                                <img
                                    src={this.state.spilIdBarCode}
                                    alt="barcode"
                                />
                            </div>
                        )}

                        <div className="username">
                            {this.props.auth?.user?.userName}
                        </div>
                        <div className="balance">
                            Saldo:{' '}
                            <b>
                                {formatDenmarkCurrency(
                                    this.props.auth?.user?.balance
                                )}{' '}
                                kr
                            </b>
                        </div>
                    </div>

                    <nav>
                        <ul className="user-menu-list">
                            <li
                                onClick={this._goToPage.bind(
                                    this,
                                    '/konto/indbetaling'
                                )}
                            >
                                Indbetaling
                            </li>
                            <li
                                onClick={this._goToPage.bind(
                                    this,
                                    '/konto/udbetaling'
                                )}
                            >
                                Udbetaling
                            </li>
                            <li onClick={this._goToPage.bind(this, '/bonus')}>
                                Bonusomsætning
                            </li>
                            <li
                                onClick={this._goToPage.bind(
                                    this,
                                    '/konto/kontohistorik'
                                )}
                            >
                                Kontohistorik
                            </li>
                            <li
                                onClick={this._goToPage.bind(
                                    this,
                                    '/konto/voucher'
                                )}
                            >
                                Indløs Voucher
                            </li>
                            {/* <li
                                onClick={this._goToPage.bind(
                                    this,
                                    this.state.spilIdBarCode
                                        ? '/konto/spil-id'
                                        : '/konto/spil-id/create-card'
                                )}
                            >
                                Dit Spil-ID
                            </li>*/}
                        </ul>

                        <h3 className="menu-list-title">Login</h3>

                        <ul className="user-menu-list">
                            <li>
                                <ViewSwitch
                                    changeHandler={this.pinLoginSwitcherHandler}
                                    checked={
                                        this.state.pinLoginStatus &&
                                        this.state.pinLoginStatus !== 'null'
                                    }
                                >
                                    PIN Log Ind
                                </ViewSwitch>
                            </li>
                        </ul>

                        <h3 className="menu-list-title">Ansvarligt Spil</h3>

                        <ul className="user-menu-list">
                            <li
                                onClick={this._goToPage.bind(
                                    this,
                                    '/konto/indbetalingsgraense'
                                )}
                            >
                                Indbetalingsgrænse
                            </li>
                            <li
                                onClick={this._goToPage.bind(
                                    this,
                                    '/konto/selvudelukkelse'
                                )}
                            >
                                Selvudelukkelse
                            </li>
                        </ul>

                        <h3 className="menu-list-title">Andet</h3>

                        <ul className="user-menu-list">
                            <li
                                onClick={this._goToPage.bind(
                                    this,
                                    '/konto/brugerinfo'
                                )}
                            >
                                Dine oplysninger
                            </li>
                            <li
                                onClick={this._goToPage.bind(
                                    this,
                                    '/konto/nyhedsbrev'
                                )}
                            >
                                Nyhedsbrev
                            </li>
                        </ul>
                    </nav>
                </section>
            </ViewPageWrapper>
        );
    }
}
function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewUserMenu);
