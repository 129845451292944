import {
    CAMPAIGNS_FETCH_ERROR,
    CAMPAIGNS_FETCH_PENDING,
    CAMPAIGNS_FETCH_SUCCESS,
} from 'constants/campaigns';

import RestService from '../utils/RestService';

export function getCampaigns() {
    return (dispatch) => {
        dispatch({
            type: CAMPAIGNS_FETCH_PENDING,
            payload: true,
        });

        RestService.Campaigns((data, error) => {
            if (error) {
                dispatch({
                    type: CAMPAIGNS_FETCH_ERROR,
                    payload: error.toString(),
                });
            }

            dispatch({
                type: CAMPAIGNS_FETCH_SUCCESS,
                payload: data,
            });
        });
    };
}
