import styled, { css } from 'styled-components';
import { getSize } from 'utils/Helper';

const Icon = styled.svg`
    ${({ margin }) => (margin ? `margin: ${margin}` : '')};
    ${({ padding }) => (padding ? `margin: ${padding}` : '')};
    vertical-align: middle;
    ${(props) => getSize(props)};
    mask-image: url(${(props) => (props.src ? props.src : '')});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center center;
    ${(props) =>
        !props.size &&
        css`
            width: ${props.width};
            height: ${props.height};
        `};
    background: ${(props) => (props.color ? props.color : 'black')};
    height: ${(props) => props.height && props.height};
    width: ${(props) => props.width && props.width};
`;

export default Icon;
