import React, { useEffect } from 'react';
import { RemoveBonusConfirm } from '@it25syv/25syv-ui';
import { hideModal } from './../actions/UiActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { removeBonus as RemoveBonus } from '../actions/BonusActions';
import { NotificationManager } from 'react-notifications';
import { disableBodyScroll, isBodyScrollDisabled } from '../utils/UiHelper';

const RemoveBonusConfirmModal = ({
    bonus,
    HideModal,
    removeBonus,
    modalData,
}) => {
    useEffect(() => {
        !isBodyScrollDisabled() && disableBodyScroll();
        return () => {
            disableBodyScroll();
        };
    }, []);

    const handleRemoveBonus = () => {
        removeBonus();
        HideModal('REMOVE_BONUS_CONFIRM_MODAL');
    };

    return (
        <div className={'ui-modal-wrapper'}>
            {Object.keys(bonus).length > 0 && (
                <RemoveBonusConfirm
                    bonusAmount={modalData.bonusAmount}
                    onConfirm={handleRemoveBonus}
                    onCancel={() => {
                        HideModal('REMOVE_BONUS_CONFIRM_MODAL');
                    }}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        bonus: state.bonus,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        HideModal: bindActionCreators(hideModal, dispatch),
        removeBonus: bindActionCreators(RemoveBonus, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RemoveBonusConfirmModal);
