import React from 'react';
import ParentAuthPage from './ParentAuthPage';
import ViewSpinner from 'components/ViewSpinner';
import { ViewNumberInput, ViewSelect } from 'components/ViewInput';
import UserService from 'utils/UserService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthActions from 'actions/AuthActions';
import * as UiActions from 'actions/UiActions';
import { UI_ERROR_MESSAGE, UI_SUCCESS_MESSAGE } from 'constants/ui';
import classNames from 'classnames';
import 'css/IndbetalingsgraensePage.css';
import Button from 'ui/Button';
import { detectMobile, formatDate } from 'utils/UiHelper';
import { browserHistory } from 'react-router';

class IndbetalingsgraensePage extends ParentAuthPage {
    constructor() {
        super();

        this.selectOptions = [
            {
                value: 'dailyLimit',
                label: 'Dagligt',
            },
            {
                value: 'weeklyLimit',
                label: 'Ugentligt',
            },
            {
                value: 'monthlyLimit',
                label: 'Månedligt',
            },
        ];
        this.defaultOption = this.selectOptions[0];

        this.state = {
            ...this.state,
            daily: {},
            weekly: {},
            monthly: {},
            dailyLimit: 0,
            weeklyLimit: 0,
            monthlyLimit: 0,
            progress: false,
            limitKey: this.defaultOption.value,
        };
    }

    setLimitsState(data) {
        this.setState({
            maxDeposit: data.maxDeposit,
            daily: {
                amount: data.dailyAmountLimit ? data.dailyAmount : 0,
                date: data.dailyDate ? data.dailyDate : '-',
                change: data.dailyAmountChange,
                limit: data.dailyAmountLimit,
            },
            weekly: {
                amount: data.weeklyAmountLimit ? data.weeklyAmount : 0,
                date: data.weeklyDate ? data.weeklyDate : '-',
                change: data.weeklyAmountChange,
                limit: data.weeklyAmountLimit,
            },
            monthly: {
                amount: data.monthlyAmountLimit ? data.monthlyAmount : 0,
                date: data.monthlyDate ? data.monthlyDate : '-',
                change: data.monthlyAmountChange,
                limit: data.monthlyAmountLimit,
            },
        });
    }

    componentDidMount() {
        this.setPageTitle('Indbetalingsgraense');

        UserService.LegalLimit().then((res) => {
            if (res.data) {
                this.setLimitsState(res.data);
            }
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.auth.user && this.props.auth.sessionError) {
            browserHistory.push('/sport/');
            this.props.uiActions.showModal('LOGIN_FIRST');
        }
    }

    valueHandler(key, val) {
        let tmp = {};
        tmp[key] = val;
        this.setState(tmp);
    }

    clickHandler(key) {
        if (this.state[key] > 0) {
            this.setState({ progress: true });

            let newLimits = {
                dailyAmount: this.state.daily.amount,
                dailyAmountLimit: this.state.daily.limit,
                weeklyAmount: this.state.weekly.amount,
                weeklyAmountLimit: this.state.weekly.limit,
                monthlyAmount: this.state.monthly.amount,
                monthlyAmountLimit: this.state.monthly.limit,
                maxDeposit: this.state.maxDeposit,
            };

            switch (key) {
                case 'dailyLimit':
                    newLimits.dailyAmount = parseInt(this.state[key], 10);
                    break;
                case 'weeklyLimit':
                    newLimits.weeklyAmount = parseInt(this.state[key], 10);
                    break;
                case 'monthlyLimit':
                    newLimits.monthlyAmount = parseInt(this.state[key], 10);
                    break;
                default:
                    newLimits.dailyAmount = parseInt(this.state[key], 10);
            }

            UserService.SetLegalLimit(newLimits).then((res) => {
                if (res.data) {
                    this.setLimitsState(res.data);
                    this.props.authActions.checkUserLoggedStatus();
                    this.setState({ progress: false });
                    this.props.uiActions.notification(
                        UI_SUCCESS_MESSAGE,
                        'Indbetalingsgrænse er nu ændret'
                    );
                } else {
                    this.setState({ progress: false });

                    this.props.uiActions.notification(
                        UI_ERROR_MESSAGE,
                        'Der skete en fejl'
                    );
                }
            });
        }
    }

    selectHandler(value) {
        this.setState({ limitKey: value });
    }

    render() {
        const infoKey = this.state.limitKey.substr(
            0,
            this.state.limitKey.length - 5
        );
        const spinner = <ViewSpinner />;
        return (
            <React.Fragment>
                {this.getHeaderView(() => {
                    this.props.uiActions.showModal('USER_MENU');
                })}
                <div
                    className={classNames('indbetalingsgraense-page', {
                        mobile: detectMobile(),
                    })}
                >
                    <div className="header">Ret din indbetalingsgrænse</div>

                    <div className="info">
                        Vælg dagligt, ugentligt eller månedligt
                        indbetalingsgrænse for din konto.
                        <br />
                        <br />
                        <b>OBS</b> rettelser eller fjernelse af din
                        indbetalingsgrænse tæder i kraft efter <b>24 timer</b>.
                    </div>

                    <div className="fields-container">
                        <ViewSelect
                            searchable={false}
                            options={this.selectOptions}
                            value={this.state.limitKey}
                            onSelectHandler={this.selectHandler.bind(this)}
                        />
                        <div className="input-wrapper">
                            <ViewNumberInput
                                valueHandler={this.valueHandler.bind(this)}
                                name={this.state.limitKey}
                                placeholder="Indtast beløbsgrænse"
                                placeholderLabel=" "
                            />
                        </div>
                    </div>
                    <div>
                        <div className="info-container">
                            <div>
                                <b>Nuværende grænse:</b>
                            </div>
                            <div>
                                <b>{this.state[infoKey].amount} DKK</b>
                                <br />
                            </div>
                        </div>
                        <div className="info-container">
                            <div>Udløber:</div>
                            <div>
                                {!this.state[infoKey].date ||
                                this.state[infoKey].date === '-'
                                    ? '-'
                                    : formatDate(
                                          this.state[infoKey].date,
                                          'DD. MMMM [kl.] HH:mm',
                                          'DD.MM.YYYY hh:mm'
                                      )}
                            </div>
                        </div>
                        <div className="info-container">
                            <div>Næste grænse</div>
                            <div>{this.state[infoKey]['change']} DKK</div>
                        </div>
                    </div>

                    {this.state.progress ? (
                        spinner
                    ) : (
                        <Button
                            margin="10px auto"
                            active
                            onClick={this.clickHandler.bind(
                                this,
                                this.state.limitKey
                            )}
                        >
                            Ret grænse
                        </Button>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return { auth: state.auth };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndbetalingsgraensePage);
