import { makeRequest } from 'utils/REST';
import { REST_TERMINAL_BIG9 } from 'constants/appConfig';
import TerminalRestService from './TerminalRestService';
//import {REST_POST_TERMINAL_BIG9}

class Big9ServiceTerminal {
    request(requestType, url, data) {
        return makeRequest(requestType, url, data)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
    }

    post(method, data) {
        return this.request(
            'post',
            window.API_PATH + '/cafe/rest/big-nine/' + method,
            data
        );
    }

    get(method, data) {
        return this.request('get', REST_TERMINAL_BIG9 + method, data);
    }

    getOffers() {
        return this.get('content/offers');
    }

    getResults() {
        return this.get('content/results');
    }

    getSlip() {
        return this.get('pam/slip');
    }

    getTitle() {
        return this.get('content/offers');
    }

    updateSelection(number, marks, eventName) {
        return this.post('pam/updateSelection', {
            nr: number,
            marks: marks,
            eventName: eventName,
        });
    }

    // placedPrice chosen price for every bet * 100
    makeBetTerminal(
        exId = '',
        placedPrice = 1,
        bets = [],
        combinationCount,
        token = ''
    ) {
        ///cafe/rest/big-nine/new-sale - покупка{как на скрине sessionId = localStorage.token}
        return this.post('new-sale', {
            offeringId: exId,
            combinationPrice: placedPrice,
            selections: bets,
            combinationCount,
            session_id: token,
            hostname: window.localStorage.getItem('printerHost')
                ? window.localStorage.getItem('printerHost')
                : 'localhost',
        });
    }

    makePayAndPlayTerminal(
        exId = '',
        placedPrice = 1,
        bets = [],
        combinationCount,
        cb = () => {}
    ) {
        return TerminalRestService.newUnpaidSale(
            {
                payload: {
                    offeringId: exId,
                    combinationPrice: placedPrice,
                    selections: bets,
                    combinationCount,
                },
                hostname: window.localStorage.getItem('printerHost')
                    ? window.localStorage.getItem('printerHost')
                    : 'localhost',
                type: 'big9',
            },
            cb
        );
    }

    history(page = 1, limit = 10) {
        return this.get(`pam/coupons/${page}/${limit}`);
    }
}

export default new Big9ServiceTerminal();
