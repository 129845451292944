import React from 'react';
import infoIcon from '../images/info.png';
import { NotificationManager } from 'react-notifications';
import { isTerminal } from 'utils/UiHelper';

export default class AcceptBetslipChanges extends React.Component {
    showInfo(e) {
        NotificationManager.info(
            '',
            'Aktiver knappen, hvis du ønsker at acceptere oddsændringer automatisk der stiger eller falder med 5% af dit samlede væddemål. Eksempel: Du har spillet et odds til 2. Under nedtællingen har oddset ændret sig til 2.03. Dermed får du odds 2.03 uden at acceptere oddsændringen.',
            5000
        );
        e.stopPropagation();
        e.preventDefault();
    }

    render() {
        if (isTerminal()) {
            return (
                <div className="accept-betslip-changes p10">
                    <label htmlFor="acceptOdds" className="f12">
                        Accepter odds ændringer automatisk{' '}
                        <img
                            src={infoIcon}
                            onClick={this.showInfo.bind(this)}
                            height="10px"
                            align="top"
                            alt="ingo"
                        />{' '}
                        <input
                            id="acceptOdds"
                            type="checkbox"
                            style={{ float: 'right' }}
                            onChange={() =>
                                this.props.onChange(!this.props.accepted)
                            }
                            defaultChecked={this.props.accepted}
                        />
                    </label>
                </div>
            );
        } else {
            return null;
        }
    }
}
