import React from 'react';
import ParentAuthPage from './ParentAuthPage';
import UserService from 'utils/UserService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthActions from 'actions/AuthActions';
import * as UiActions from 'actions/UiActions';
import classNames from 'classnames';
import 'css/NewsPage.scss';
import { detectMobile } from 'utils/UiHelper';
import { ViewCheckbox } from 'components/ViewInput';
import { browserHistory, Link } from 'react-router';

class NewsPage extends ParentAuthPage {
    constructor() {
        super();

        this.state = {
            ...this.state,
            status: false,
            consentForPurchase: false,
            canChangeConsentForPurchase: false,
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.handleConsentChange = this.handleConsentChange.bind(this);
    }

    componentDidMount() {
        this.setPageTitle('Nyhedsbrev');
        if (this.props.auth && this.props.auth.user) {
            this.setState({
                status: this.props.auth.user.newsletter === 1,
                consentForPurchase:
                    this.props.auth.user.derbyPermission === true,
            });
            this.props.authActions.checkUserLoggedStatus();
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props?.auth?.user &&
            (prevProps.auth?.user?.newsletter !==
                this.props.auth?.user?.newsletter ||
                prevProps.auth?.user?.derbyPermission !==
                    this.props.auth?.user?.derbyPermission)
        ) {
            this.setState({
                status: this.props.auth.user.newsletter === 1,
                consentForPurchase:
                    this.props.auth.user.derbyPermission === true,
            });
        }
        if (!this.props.auth.user && this.props.auth.sessionError) {
            browserHistory.push('/sport/');
            this.props.uiActions.showModal('LOGIN_FIRST');
        }
    }

    changeHandler(e) {
        const status = e.target.checked;
        let { consentForPurchase, canChangeConsentForPurchase } = this.state;

        if (!status) {
            consentForPurchase = false;
            canChangeConsentForPurchase = true;
        } else {
            canChangeConsentForPurchase = false;
        }

        this.setState(
            { status, consentForPurchase, canChangeConsentForPurchase },
            () => {
                UserService.NewsletterSubscription(status ? 1 : 0).then(() => {
                    this.props.authActions.updateNewslater(status ? 1 : 0);
                    this.props.authActions.setDerbyPermission(
                        consentForPurchase,
                        this.props.auth?.user?.email || ''
                    );
                });
            }
        );
    }

    handleConsentChange(e) {
        const newConsentStatus = e.target.checked;
        if (this.state.canChangeConsentForPurchase || this.state.status) {
            this.setState({ consentForPurchase: newConsentStatus }, () => {
                const email = this.props.auth?.user?.email || '';
                this.props.authActions.setDerbyPermission(
                    newConsentStatus,
                    email
                );
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.state.status !== nextState.status ||
            this.state.consentForPurchase !== nextState.consentForPurchase
        );
    }

    render() {
        const { derbyPermission } = this.props.auth?.user || {};
        return (
            <React.Fragment>
                {this.getHeaderView(() => {
                    this.props.uiActions.showModal('USER_MENU');
                })}
                <div
                    className={classNames('news-page', {
                        mobile: detectMobile(),
                    })}
                >
                    {detectMobile() ? (
                        <div className="checkbox-wrapper mobile">
                            <div className="checkbox-title">
                                <p> Vil du modtage tilbud og kampagner?</p>
                                <ViewCheckbox
                                    checked={this.state.status}
                                    changeHandler={(e) => this.changeHandler(e)}
                                >
                                    <p>Ja tak</p>
                                </ViewCheckbox>
                            </div>
                        </div>
                    ) : (
                        <div className="checkbox-wrapper">
                            <div className="checkbox-title">
                                <p> Vil du modtage tilbud og kampagner?</p>
                                <ViewCheckbox
                                    checked={this.state.status}
                                    changeHandler={(e) => this.changeHandler(e)}
                                >
                                    <p>Ja tak</p>
                                </ViewCheckbox>
                            </div>
                        </div>
                    )}

                    <div className="info">
                        Ved tilmelding gives tilladelse til at modtage
                        henvendelser fra Bet25 A/S (vedr. Bet25 og CasinoGo) med
                        tilbud, nyheder, kampagner og konkurrencer via e-mail og
                        SMS.
                    </div>

                    <div className="info" style={{ marginTop: '15px' }}>
                        Ved at tilmelde dig giver du også dit samtykke til, at
                        vi anvender oplysninger om din interaktion med denne
                        hjemmeside og vores henvendelser, så vi bedre kan måle
                        effekten af dem og målrette dem til dig. Oplysningerne
                        indsamles via cookies i de henvendelser du modtager samt
                        cookies på hjemmesiden, hvis du har samtykket til dem
                        særskilt.
                    </div>

                    <div className="info" style={{ marginTop: '15px' }}>
                        Du kan altid trække dit samtykke tilbage ved brug af
                        f.eks. afmeldingslink i vores e-mails.
                    </div>

                    <div className="info" style={{ marginTop: '15px' }}>
                        Behandlingen af personoplysninger er nærmere beskrevet i{' '}
                        <a
                            target="_blank"
                            href="https://intercom.help/25syv/da/articles/3870767-persondatapolitik-for-25syv-a-s"
                        >
                            vores persondatapolitik.
                        </a>
                    </div>
                    <div
                        className="checkbox-wrapper"
                        style={{ marginTop: '15px' }}
                    >
                        <div className="checkbox-title">
                            <p> Samtykke i forbindelse med Køb?</p>
                            <ViewCheckbox
                                style={{ marginTop: '15px' }}
                                checked={this.state.consentForPurchase}
                                changeHandler={this.handleConsentChange}
                                disabled={!this.state.status}
                            >
                                <p>Ja tak</p>
                            </ViewCheckbox>
                        </div>
                        <div className="info" style={{ marginTop: '15px' }}>
                            Hvis ikke du ønsker at modtage tilbud eller nyheder
                            om lignende produkter (væddemål og online casino fra
                            25Syv A/S, herunder Bet25, Rød25, Derby25 og
                            CasinoGo) via din oplyste e-mailadresse, skal du
                            klikke “ja tak” fra. Du kan til enhver framelde dig
                            at modtage tilbud og nyheder fra 25Syv A/S igen. Læs
                            mere om regler for beskyttelse af personlige
                            oplysninger her{' '}
                            <a
                                target="_blank"
                                href="https://intercom.help/25syv/da/articles/3870767-persondatapolitik-for-25syv-a-s"
                            >
                                Vores persondatapolitik.
                            </a>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return { auth: state.auth };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsPage);
