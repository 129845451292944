import {
    VIRTUAL_EVENTS_FETCH_ERROR,
    VIRTUAL_EVENTS_FETCH_PENDING,
    VIRTUAL_EVENTS_FETCH_SUCCESS,
} from '../constants/virtualEvents';

const initialState = {
    pending: false,
    fetched: false,
    data: [],
    map: {},
    error: false,
};

export default function virtualEvents(state = initialState, action) {
    switch (action.type) {
        case VIRTUAL_EVENTS_FETCH_PENDING:
            return {
                ...state,
                pending: true,
                fetched: false,
                data: [],
                error: false,
            };

        case VIRTUAL_EVENTS_FETCH_SUCCESS:
            const filteredEvents = action.payload.filter(
                (ev) =>
                    ev.status !== 0 &&
                    (ev.marketList.length > 0 || ev.market1X2)
            );

            return {
                ...state,
                pending: false,
                fetched: true,
                data: action.payload,
                map: filteredEvents,
                error: false,
            };

        case VIRTUAL_EVENTS_FETCH_ERROR:
            return {
                ...state,
                pending: false,
                fetched: false,
                data: [],
                map: [],
                error: action.payload,
            };

        default:
            return state;
    }
}
