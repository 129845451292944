import {
    UI_CLEAR_MESSAGE,
    UI_COUPON_STATUS,
    UI_DANMARK_ITEMS,
    UI_ERROR_MESSAGE,
    UI_VERIFICATION_MODAL_STATUS,
    UI_GAME_WIDGET_STATUS,
    UI_HIGHLIGHT_STATUS,
    SET_DEFERRED_PROMPT,
    UI_INFO_MESSAGE,
    UI_KEYBOARD_POPUP_STATUS,
    UI_LOGIN_MODAL_STATUS,
    UI_PRINT_POPUP_STATUS,
    UI_ROD25_POPUP_STATUS,
    UI_SET_LIVESCORE_STATUS,
    UI_SET_NOTIFICATIONS,
    UI_SIGNUP_MODAL_STATUS,
    UI_SPORT_ACTIVE_TAB,
    UI_SUCCESS_BETSLIP_POPUP_STATUS,
    UI_SUCCESS_MESSAGE,
    UI_VOUCHER_POPUP_STATUS,
    UI_WARNING_MESSAGE,
    UI_ACTIVE_BETS,
    UI_SET_LEFT_SIDEBAR_STATUS,
    UI_AFTER_LOGIN_SET,
    UI_AFTER_LOGIN_RESET,
    UI_SPIL_ID_MODAL_STATUS,
    UI_SHOW_MODAL,
    UI_HIDE_MODAL,
} from 'constants/ui';

const initialState = {
    sportActiveTab: '0',
    leftSidebarOpen: false,
    gameWidgetStatus: true,
    loginModalStatus: false,
    spilIdModalStatus: false,
    signupModalStatus: false,
    highlightStatus: true,
    messageType: false,
    messageText: false,
    deferredPrompt: null,
    danmarkItems: null,
    couponStatus: false,
    applyVoucherPopupStatus: false,
    printPopupStatus: false,
    rod25PopupStatus: false,
    keyboardPopupStatus: false,
    successBetslipPopupStatus: false,
    notifications: null,
    activeBets: 0,
    showLiveSection: false,
    verificationModalStatus: false,
    afterLoginRedirect: null,
    modals: {},
};

const initialModalState = {
    id: 0,
    data: {},
    visible: false,
    cameFrom: '',
};

export default function ui(state = initialState, action) {
    switch (action.type) {
        case UI_SPORT_ACTIVE_TAB:
            return { ...state, sportActiveTab: action.payload };

        case UI_GAME_WIDGET_STATUS:
            return { ...state, gameWidgetStatus: action.payload };

        case UI_LOGIN_MODAL_STATUS:
            return { ...state, loginModalStatus: action.payload };
        case UI_SPIL_ID_MODAL_STATUS:
            return { ...state, spilIdModalStatus: action.payload };
        case UI_SIGNUP_MODAL_STATUS:
            return { ...state, signupModalStatus: action.payload };
        case UI_HIGHLIGHT_STATUS:
            return { ...state, highlightStatus: action.payload };
        case SET_DEFERRED_PROMPT:
            return { ...state, deferredPrompt: action.payload };
        case UI_ACTIVE_BETS:
            return { ...state, activeBets: action.payload };
        case UI_WARNING_MESSAGE:
            return {
                ...state,
                messageType: UI_WARNING_MESSAGE,
                messageText: action.payload,
            };
        case UI_ERROR_MESSAGE:
            return {
                ...state,
                messageType: UI_ERROR_MESSAGE,
                messageText: action.payload,
            };
        case UI_SUCCESS_MESSAGE:
            return {
                ...state,
                messageType: UI_SUCCESS_MESSAGE,
                messageText: action.payload,
            };
        case UI_INFO_MESSAGE:
            return {
                ...state,
                messageType: UI_INFO_MESSAGE,
                messageText: action.payload,
            };
        case UI_CLEAR_MESSAGE:
            return { ...state, messageType: false, messageText: false };
        case UI_DANMARK_ITEMS:
            return { ...state, danmarkItems: action.payload };
        case UI_COUPON_STATUS:
            return { ...state, couponStatus: action.payload };
        case UI_VOUCHER_POPUP_STATUS:
            return { ...state, applyVoucherPopupStatus: action.payload };
        case UI_PRINT_POPUP_STATUS:
            return { ...state, printPopupStatus: action.payload };
        case UI_ROD25_POPUP_STATUS:
            return { ...state, rod25PopupStatus: action.payload };
        case UI_KEYBOARD_POPUP_STATUS:
            return { ...state, keyboardPopupStatus: action.payload };
        case UI_SUCCESS_BETSLIP_POPUP_STATUS:
            return { ...state, successBetslipPopupStatus: action.payload };
        case UI_SET_NOTIFICATIONS:
            return { ...state, notifications: action.payload };
        case UI_SET_LIVESCORE_STATUS:
            return { ...state, showLiveSection: action.payload };
        case UI_VERIFICATION_MODAL_STATUS:
            return { ...state, verificationModalStatus: action.payload };
        case UI_SET_LEFT_SIDEBAR_STATUS:
            return { ...state, leftSidebarOpen: action.payload };
        case UI_AFTER_LOGIN_SET:
            return {
                ...state,
                afterLoginRedirect: action.payload,
            };
        case UI_AFTER_LOGIN_RESET:
            return {
                ...state,
                afterLoginRedirect: null,
            };
        case UI_SHOW_MODAL:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    [action.payload.id]: {
                        ...initialModalState,
                        ...action.payload,
                    },
                },
            };
        case UI_HIDE_MODAL:
            if (action.payload.id) {
                let newModals = { ...state.modals };
                delete newModals[action.payload.id];
                return {
                    ...state,
                    modals: {
                        ...newModals,
                    },
                };
            } else {
                return { ...state, modals: {} };
            }
        default:
            return state;
    }
}
