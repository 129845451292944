import {
    FETCH_RETAIL_BETS_HISTORY_SUCCESS,
    FETCH_RETAIL_BETS_HISTORY_ERROR,
    FETCH_RETAIL_BETS_HISTORY_PENDING,
    FETCH_MORE_RETAIL_BETS_HISTORY_SUCCESS,
    SET_TRANSACTION_SALE_ID,
    RESET_RETAIL_BETS_HISTORY,
} from '../constants/retailSailsHistory';
import UserService from '../utils/UserService';

export function FetchRetailSalesHistory(page = 1) {
    return (dispatch) => {
        dispatch({
            type: FETCH_RETAIL_BETS_HISTORY_PENDING,
            payload: true,
        });

        UserService.GetSalesPageRetail(page).then((res) => {
            const { data, success } = res;
            if (success && data) {
                dispatch({
                    type: FETCH_RETAIL_BETS_HISTORY_SUCCESS,
                    payload: data,
                });
            } else {
                dispatch({
                    type: FETCH_RETAIL_BETS_HISTORY_ERROR,
                    payload: true,
                });
            }
        });
    };
}

export function FetchMoreRetailSalesHistory(page) {
    return (dispatch) => {
        dispatch({
            type: FETCH_RETAIL_BETS_HISTORY_PENDING,
            payload: true,
        });

        UserService.GetSalesPageRetail(page).then((res) => {
            const { data, success } = res;
            if (success && data) {
                dispatch({
                    type: FETCH_MORE_RETAIL_BETS_HISTORY_SUCCESS,
                    payload: data,
                });
            } else {
                dispatch({
                    type: FETCH_RETAIL_BETS_HISTORY_ERROR,
                    payload: true,
                });
            }
        });
    };
}

export function SetTransactionSaleId(saleId) {
    return (dispatch) => {
        dispatch({
            type: FETCH_RETAIL_BETS_HISTORY_PENDING,
            payload: true,
        });

        dispatch({
            type: SET_TRANSACTION_SALE_ID,
            payload: saleId,
        });
    };
}

export function ResetRetailSalesHistory() {
    return (dispatch) => {
        dispatch({
            type: FETCH_RETAIL_BETS_HISTORY_PENDING,
            payload: true,
        });
        dispatch({
            type: RESET_RETAIL_BETS_HISTORY,
        });
    };
}
