import styled from 'styled-components';
import PropTypes from 'prop-types';

const getFontSize = (size = 14) => {
    let s = size;

    switch (size) {
        case 'x04' || '8':
            s = 8;
            break;
        case 'x05' || '10':
            s = 10;
            break;
        case 'x07' || '12':
            s = 12;
            break;
        case 'x1' || '14':
            s = 14;
            break;
        case 'x15' || '16':
            s = 16;
            break;
        case 'x2' || '18':
            s = 18;
            break;
        case 'x25' || '20':
            s = 20;
            break;
        case 'x3' || '22':
            s = 22;
            break;
        case '26':
            s = 26;
            break;
        case '28':
            s = 28;
            break;
        default:
            s = size;
    }

    return s;
};
const Text = styled.p`
    flex: ${(props) => (props.flex ? props.flex : '1 1 auto')};
    width: ${(props) => (props.width ? props.width : 'auto')};
    font-size: ${(props) => getFontSize(props.size)}px;
    ${(props) =>
        props.heightstrict
            ? 'line-height: ' + getFontSize(props.size) + 'px;'
            : ''};
    margin: ${({ margin }) => (margin ? margin : '0px')};
    padding: ${(props) => (props.padding ? props.padding : '10px 0px')};
    color: ${(props) => props.color || '#696969'};
    text-align: ${(props) => (props.align ? props.align : 'left')};
    box-sizing: ${(props) => (props.boxSizing ? props.boxSizing : 'inherit')};
    font-weight: ${(props) => {
        const weight = props.bold || props.weight;
        if (weight) {
            return parseInt(weight, 10) > 0 ? weight : '900';
        }
        return 'normal';
    }};
    ${(props) => (props.block ? 'display: block;' : '')};
    ${(props) =>
        props.short
            ? 'text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: auto;'
            : ''};
    ${(props) => (props.shadow ? `text-shadow: ${props.shadow};` : '')};
    ${(props) => (props.uppercase ? `text-transform: uppercase;` : '')};
    ${(props) => (props.capitalize ? `text-transform: capitalize;` : '')};
    ${(props) =>
        props.whiteSpace ? `white-space: ${props.whiteSpace}` : 'normal'};
    ${(props) => (props.center ? `text-align: center` : '')};
    ${(props) => (props.opacity ? `opacity: ${props.opacity};` : '')};
    ${(props) => (props.height ? `height: ${props.height};` : '')};
    ${(props) =>
        props.borderRadius ? `border-radius: ${props.borderRadius};` : ''};
    ${(props) => (props.overflow ? `overflow: ${props.overflow};` : '')};
    ${(props) => (props.bg ? `background: ${props.bg};` : '')};
    ${(props) => (props.fontStyle ? `font-style: ${props.fontStyle};` : '')};
    ${(props) => (props.display ? `display: ${props.display};` : '')};
    ${(props) => (props.float ? `float: ${props.float};` : '')};
    ${(props) => (props.border ? `${props.border};` : 'none')};
    ${(props) => {
        if (props.condensed) {
            return 'font-family: Roboto Condensed';
        }
    }};
    ${(props) => {
        if (props.lh) {
            return `line-height: ${props.lh}px`;
        }
    }};
    text-decoration: ${(props) =>
        props.decoration ? props.decoration : 'none'};
`;

Text.propTypes = {
    width: PropTypes.string,
    display: PropTypes.string,
    float: PropTypes.string,
    align: PropTypes.string,
    decoration: PropTypes.string,
    padding: PropTypes.string,
    margin: PropTypes.string,
    color: PropTypes.string,
    flex: PropTypes.string,
    weight: PropTypes.string,
    shadow: PropTypes.string,
    height: PropTypes.string,
    overflow: PropTypes.string,
    fontStyle: PropTypes.string,
    lh: PropTypes.string,
    bg: PropTypes.string,
    opacity: PropTypes.string,
    size: PropTypes.string,
    heightstrict: PropTypes.bool,
    boxSizing: PropTypes.string,
    whiteSpace: PropTypes.string,
    strike: PropTypes.bool,
    bold: PropTypes.bool,
    short: PropTypes.bool,
    block: PropTypes.bool,
    uppercase: PropTypes.bool,
    borderRadius: PropTypes.string,
    border: PropTypes.string,
    capitalize: PropTypes.bool,
    center: PropTypes.bool,
    condensed: PropTypes.bool,
};

export default Text;
