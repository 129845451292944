import React, { Component } from 'react';
import img404 from '../images/404.png';

export default class NoMatchPage extends Component {
    render() {
        const styles = {
            margin: '10px',
        };

        return (
            <div style={styles} className="no-match-page text-center">
                <br />
                <br />
                <br />
                <img src={img404} alt="404" className="img-404" />
                <p className="blue">
                    Det ser ud til at denne side mangler. Tip gerne vores
                    support med denne fejl, så vi kan få den rettet hurtigst
                    muligt.
                </p>
            </div>
        );
    }
}
