import React, { Fragment } from 'react';
import ParentAuthPage from './ParentAuthPage';
import UserService from 'utils/UserService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthActions from 'actions/AuthActions';
import * as UiActions from 'actions/UiActions';
import classNames from 'classnames';
import 'css/BonusPage.css';
import Button from 'ui/Button';
import { detectMobile } from 'utils/UiHelper';
import { browserHistory } from 'react-router';
import { disableBodyScroll } from '../utils/UiHelper';

class BonusPage extends ParentAuthPage {
    constructor() {
        super();

        this.state = {
            ...this.state,
            payout: null,
        };
    }

    onEmailClickHandler = () => {
        window.location = 'mailto:support@bet25.dk';
    };

    onContantClickHandler = () => {
        if (detectMobile()) {
            if (
                window.Intercom &&
                this.props.auth.user &&
                this.props.auth.info
            ) {
                window.Intercom('update', {
                    app_id: 'qkqulpbi',
                    name:
                        this.props.auth.info.firstName +
                        ' ' +
                        this.props.auth.info.lastName,
                    phone: this.props.auth.info.phone,
                    user_id: this.props.auth.user.userId,
                    email: this.props.auth.user.email,
                    username: this.props.auth.user.userName,
                    postal_code: this.props.auth.info.zip,
                });
            }

            window.Intercom('show', {
                app_id: 'qkqulpbi',
            });
        } else {
            window.Intercom('show', {
                app_id: 'qkqulpbi',
            });
        }
        window.Intercom('trackEvent', 'test_js');
    };

    componentDidMount() {
        disableBodyScroll();
        this.setPageTitle('Bonusomsætning');
        UserService.MoneyPayout().then((res) => {
            if (!res.data || res.errorMessage) {
                let msg = res.errorMessage;

                if (
                    this.props.auth.user &&
                    this.props.auth.user.status === 'temp'
                ) {
                    msg =
                        'Du kan ikke udbetale fordi din konto stadig kun er midlertidig. Da vi ikke har kunnet verificere din konto igennem NEMID, skal vi bede dig indsende et digitalt foto af dit gyldige sygesikringsbevis. Dette skal sendes til support@bet25.dk';
                }

                this.setState({
                    error: {
                        code: res.statusCode,
                        msg: msg,
                        show: true,
                    },
                });
            } else {
                if (
                    res.data.amount === 0 &&
                    this.props.auth.user &&
                    this.props.auth.user.status === 'temp'
                ) {
                    this.setState({
                        error: {
                            code: res.statusCode,
                            msg:
                                'Du kan ikke udbetale fordi din konto stadig kun er midlertidig. Da vi ikke har kunnet verificere din konto igennem NEMID, skal vi bede dig indsende et digitalt foto af dit gyldige sygesikringsbevis. Dette skal sendes til support@bet25.dk',
                            show: true,
                        },
                    });
                } else {
                    const payout = res.data;

                    this.setState({
                        payout: {
                            ...payout,
                            voucher: payout.pendingBalance - payout.bonus,
                        },
                    });
                }
            }
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.auth.user && this.props.auth.sessionError) {
            browserHistory.push('/sport/');
            this.props.uiActions.showModal('LOGIN_FIRST');
        }
    }

    render() {
        const payout = this.state.payout;
        let view = null;

        const errorMsg = this.state.error ? this.state.error.msg : false;
        const successMsg = this.state.success ? (
            <p className="success message p10">
                Udbetalingen sker automatisk til din nemkonto, og det tager 2-3
                bankdage før at pengene er på din konto
            </p>
        ) : null;

        if (payout && payout.bonus > 0) {
            view = (
                <Fragment>
                    <div className="bonus">
                        Din bonus er <b>{payout.bonus.toFixed(2)} DKK</b>
                    </div>
                    <div className="usage-info">
                        Før du kan foretage en udbetaling skal du omsætte for
                        mindst &nbsp;
                        <b>
                            {payout.rollover}
                            &nbsp;DKK
                        </b>
                        &nbsp; til minimum odds &nbsp;
                        {payout.minOdds === 0
                            ? '1.95'
                            : payout.minOdds.toFixed(2)}
                    </div>
                    <div className="info">
                        Skal vi slette din bonus? Så kontant support.
                        <br />
                        <br />
                        Chat og Email - Alle dage 10-21
                    </div>
                    <div className="buttons-container">
                        <Button onClick={this.onContantClickHandler}>
                            Kontakt
                        </Button>
                        <Button onClick={this.onEmailClickHandler}>
                            Send email
                        </Button>
                    </div>
                </Fragment>
            );
        } else {
            view = (
                <div className="bonus">
                    {' '}
                    Du har ingen bonus i &#248;jeblikket.
                </div>
            );
        }

        return (
            <React.Fragment>
                {this.getHeaderView(() => {
                    this.props.uiActions.showModal('USER_MENU');
                })}
                <section
                    className={classNames('bonus-page', {
                        mobile: detectMobile(),
                    })}
                >
                    <div className="header">Bonusomsætning</div>
                    {errorMsg}
                    {successMsg}
                    {view}
                </section>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return { auth: state.auth };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BonusPage);
