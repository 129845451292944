import { useEffect } from 'react';

const useAuthEffect = (onAuth, auth) => {
    const userId = auth?.user ? auth?.user.userId : 0;

    useEffect(
        () => {
            if (auth?.user) {
                // logged in
                onAuth();
            }
        },
        [userId]
    );
};

export default useAuthEffect;
