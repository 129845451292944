export const Big9Routes = [
    { to: '/big9/spil', label: 'Spil', exact: true },
    { to: '/big9/resultater', label: 'Resultat', exact: false },
    { to: '/big9/spilklub25', label: 'SpilKlub25', exact: false },
    //{ to: '/big9/tips', label: 'Nyheder', exact: false },
    { to: '/big9/ombig9', label: 'Om Big9', exact: false },
];

export const Big9RoutesTerminals = [
    { to: '/big9/spil', label: 'Big 9', exact: true },
    { to: '/big9/resultater', label: 'Resultater', exact: false },
    { to: '/big9/ombig9', label: 'Sådan spiller du', exact: false },
];

export const BIG9_EVENT_STATUS = {
    decided: 'DECIDED',
    upcoming: 'UPCOMING',
    ongoing: 'ONGOING',
    canceled: 'CANCELED',
};
