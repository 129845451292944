import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UiActions from '../actions/UiActions';
import moment from 'moment';
import {
    disableBodyScroll,
    enableBodyScroll,
    formatDenmarkCurrency,
} from 'utils/UiHelper';
import styled from 'styled-components';
import Text from 'ui/Text';
import successImg from 'images/success_icon.svg';
import Button from 'ui/Button';

const PopupContainer = styled.div`
    height: 100%;
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 12;
`;
const PopupWrapper = styled.div`
    max-height: 90%;
    width: 97%;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 10px;
`;
const TopMessageWrapper = styled.div`
    width: 100%;
    background: #18aa1b;
    border-radius: 5px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: 5px;
`;
const MainBlockWrapper = styled.div`
    padding: 25px 30px 0 30px;
    background: #edf1f8;
    border-radius: 5px;
`;
const ItemsRow = styled.div`
    position: relative;
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    ${({ border }) =>
        border ? 'border-bottom: 1px solid rgba(138, 140, 142, 0.4);' : null};
    &.newLayout {
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        p {
            width: 50%;
            max-width: 50%;
            position: relative;
        }
    }
`;
const Subtext = styled.span`
    font-weight: normal;
    position: absolute;
    top: -5px;
    color: #8a8c8e;
    font-size: 12px;
`;

const getPayout = (slip) => {
    // if (slip.possiblePayout > 0) {
    //     if (slip.freeBetAmount > 0) {
    //         return slip.possiblePayout - slip.freeBetAmount;
    //     } else {
    return slip.possiblePayout;
    //     }
    // }
};

class BetslipRecieptModal extends Component {
    onClosePopupHandler() {
        enableBodyScroll();
        this.props.uiActions.setSuccessBetslipPopupSatus(false);
    }

    render() {
        if (this.props.successBetslipPopupStatus && this.props.slip) {
            const betslip = this.props.successBetslipPopupStatus;
            const slip = this.props.slip;
            disableBodyScroll();
            return (
                <PopupContainer>
                    <PopupWrapper>
                        <TopMessageWrapper>
                            <Text
                                flex="0 0 auto"
                                margin=" 0 5px"
                                color="white"
                                size="x2"
                            >
                                Dit spil er placeret
                            </Text>
                            <img src={successImg} alt="success" />
                        </TopMessageWrapper>
                        <MainBlockWrapper>
                            <ItemsRow>
                                <Text
                                    align="left"
                                    size="x15"
                                    color="#333333"
                                    bold
                                >
                                    {betslip.isCustomBet
                                        ? 'Byg væddemål'
                                        : betslip.systemName
                                            ? betslip.systemName
                                            : 'Single'}
                                </Text>
                            </ItemsRow>
                            <ItemsRow border className={`newLayout`}>
                                <Text color="#333333" bold size="x15">
                                    <Subtext>Indsats</Subtext>
                                    {formatDenmarkCurrency(slip.stake)} kr.
                                </Text>
                                <Text
                                    color="#333333"
                                    bold
                                    size="x15"
                                    align="right"
                                >
                                    <Subtext
                                        style={{
                                            right: '0',
                                            width: '65px',
                                        }}
                                    >
                                        Samlet odds
                                    </Subtext>
                                    {slip.totalOdds.toFixed(2)}
                                </Text>
                                <Text
                                    color="#333333"
                                    bold
                                    size="x15"
                                    margin={'10px 0 0 '}
                                    align={'left'}
                                >
                                    <Subtext
                                        style={{ width: '100%', left: '0' }}
                                    >
                                        Mulig gevinst
                                    </Subtext>
                                    {formatDenmarkCurrency(getPayout(slip))} kr.
                                </Text>
                            </ItemsRow>
                            <Button
                                active
                                width="100%"
                                margin="10px auto"
                                onClick={() => {
                                    this.onClosePopupHandler();
                                }}
                            >
                                Luk
                            </Button>
                            <ItemsRow>
                                <Text
                                    center
                                    color="#8A8C8E"
                                    size="12"
                                    capitalize
                                >
                                    ID: {this.props.slip.saleId} -{' '}
                                    {moment().format('DD. MMMM [KL.] HH:mm ')}
                                </Text>
                            </ItemsRow>
                        </MainBlockWrapper>
                    </PopupWrapper>
                </PopupContainer>
            );
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    return {
        betslip: state.betslip,
        slip: state.betslip.slip,
        successBetslipPopupStatus: state.ui.successBetslipPopupStatus,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BetslipRecieptModal);
