import React, { Component } from 'react';
import ViewPageToolbar from './ViewPageToolbar';
import 'css/ViewModal.css';
import $ from 'jquery';
import { desktopOnly, notDesktop } from 'utils/UiHelper';

class ViewModal extends Component {
    onCloseBtn() {
        if (this.props.onCloseModal) {
            window.localStorage.setItem(
                'isLeftSideBarOpen',
                (!this.props.isLeftSideBarOpen).toString()
            );
            this.props.onCloseModal();
        }
    }

    render() {
        if (desktopOnly() || this.props.mode === 'popup') {
            return (
                <div className="view-modal-wrapper">
                    <div className="view-modal">
                        <div
                            className="close-btn"
                            onClick={this.onCloseBtn.bind(this)}
                        >
                            <div className="close-btn-icon">
                                <i className="fa fa-times" aria-hidden="true" />
                            </div>
                        </div>
                        {this.props.children}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="view-modal">
                    <ViewPageToolbar
                        title={this.props.title}
                        onCloseBtn={this.onCloseBtn.bind(this)}
                    />{' '}
                    {this.props.children}
                </div>
            );
        }
    }

    componentDidMount() {
        if (notDesktop()) {
            $('.layout').addClass('without-scroll');
        }
    }

    componentWillUnmount() {
        if (notDesktop()) {
            $('.layout').removeClass('without-scroll');
        }
    }
}

export default ViewModal;
