import styled from 'styled-components';
import { detectMobile } from '../../utils/UiHelper';

export const PaymentPayoutPageWrapper = styled.div`
    height: 100%;
    min-height: ${detectMobile() ? '100vh' : '100%'};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    background: #fff;
    box-sizing: border-box;
    flex: 1 1 0;
    z-index: 1;
    overflow: hidden;
    &.mob {
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 11;
    }
`;
