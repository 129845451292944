import React from 'react';
import { CPRConfirmation } from '@it25syv/25syv-ui';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import * as UiActions from 'actions/UiActions';
import { connect } from 'react-redux';

const CPRConfirmModal = (props) => {
    const history = browserHistory;

    return (
        <CPRConfirmation
            onConfirm={() => {
                history.push(`/mitid-verify/success/login`);
                props.uiActions.hideModal('CPR_CONFIRMATION');
            }}
            onError={(e) => {
                history.push(`/mitid-verify/error?error=${e.toString()}`);
            }}
            idp={props.modalData.idp}
            site={'sport'}
        />
    );
};

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    null,
    mapDispatchToProps
)(CPRConfirmModal);
