import { formatDenmarkCurrency } from '../../utils/UiHelper';

export const calculateOddsDisplay = (coupons) => {
    if (coupons.length === 1) {
        return coupons[0].odds.toFixed(2);
    } else {
        let minOdds = Infinity;
        let maxOdds = -Infinity;

        coupons.forEach((coupon) => {
            minOdds = Math.min(minOdds, coupon.odds);
            maxOdds = Math.max(maxOdds, coupon.odds);
        });

        return `${minOdds.toFixed(2)} - ${maxOdds.toFixed(2)}`;
    }
};

export const calculateTotalWin = (couponList) => {
    const totalWin = couponList.reduce((acc, coupon) => {
        if (coupon.status === 0) {
            return (
                acc +
                (coupon.possiblePayout ||
                    ((coupon.odds * coupon.stake) / 100).toFixed(2) ||
                    0)
            );
        }
        return acc + (coupon.amountWin || 0);
    }, 0);
    return totalWin === 0 ? '-' : `${formatDenmarkCurrency(totalWin)} kr.`;
};
