import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    renderIcon,
    renderStatus,
    getStatusBorder,
    systemNames,
} from '../../helpers/RetailTransferHelpers/TransferRender.js';
import { browserHistory } from 'react-router';
import {
    calculateOddsDisplay,
    calculateTotalWin,
} from '../../helpers/RetailTransferHelpers/TransferDataHelper.js';
import sportImg from 'images/sport-transfer.svg';
import PayoutStatusComponent from 'components/TransferRetail/PayoutStatusComponent';
import './styled.scss';
import * as UiActions from 'actions/UiActions';
import {
    formatDenmarkCurrency,
    isCanceledCoupon,
    showRetailTransferUi,
} from '../../utils/UiHelper';
import Wrapper from '../../ui/Wrapper';

const SportTransfer = ({ data, retailSalesHistory, uiActions }) => {
    const goToEventPage = (eventId) => {
        browserHistory.push(`/sport/kamp/${eventId}`);
        uiActions.hideModal('USER_MENU');
    };

    return (
        <>
            {data.map((item, index) => {
                const betCounts = {};
                const uniqueMarkets = new Set();
                const currentStatus = renderStatus(item.data.couponList);
                const winAmount = item.data.win
                    ? item.data.win === 0
                        ? '-'
                        : `${formatDenmarkCurrency(item.data.win)} kr.`
                    : calculateTotalWin(item.data.couponList);
                item.data.couponList.forEach((coupon) => {
                    const length = coupon.betList.length;
                    const nameIndex = Math.min(length, systemNames.length - 1);
                    const nameKey = systemNames[nameIndex];

                    if (betCounts[nameKey]) {
                        betCounts[nameKey] += 1;
                    } else {
                        betCounts[nameKey] = 1;
                    }
                });

                const betTypesString =
                    Object.entries(betCounts)
                        .map(([name, count]) => {
                            if (name === 'Trippel') {
                                return `${count} ${
                                    count > 1 ? 'Tripler' : 'Trippel'
                                }`;
                            }
                            const pluralSuffix = name.endsWith('e')
                                ? 'r'
                                : 'er';
                            return `${count} ${name}${
                                count > 1 ? pluralSuffix : ''
                            }`;
                        })
                        .join(', ') || 'Single';

                return (
                    <div key={index} className="sport__transfer__container">
                        <div
                            className="left-line"
                            style={{
                                background: getStatusBorder(
                                    item.data.couponList
                                ),
                            }}
                        />
                        <div className="sport__transfer__content">
                            <div className="header__container">
                                <div className="header-title">
                                    <img
                                        src={sportImg}
                                        alt="sport icon"
                                        width="49px"
                                        height="auto"
                                    />
                                </div>
                                <div className="transfer__line" />
                            </div>
                            {item.data.couponList.flatMap(
                                (coupon, couponIndex) =>
                                    coupon.betList
                                        .filter((bet) => {
                                            if (
                                                !uniqueMarkets.has(bet.marketId)
                                            ) {
                                                uniqueMarkets.add(bet.marketId);
                                                return true;
                                            }
                                            return false;
                                        })
                                        .map((bet, betIndex) => {
                                            const isCancelled =
                                                coupon.statusText ===
                                                    'Canceled' ||
                                                bet.resultText === 'Cancel' ||
                                                bet.status == 4 ||
                                                coupon.status === 8;
                                            const isActive =
                                                coupon.status === 0;
                                            return (
                                                <React.Fragment
                                                    key={bet.marketId}
                                                >
                                                    <div
                                                        className={`match ${
                                                            isCancelled
                                                                ? 'cancelled-match'
                                                                : ''
                                                        }`}
                                                        onClick={
                                                            isActive
                                                                ? () =>
                                                                      goToEventPage(
                                                                          bet.eventId
                                                                      )
                                                                : null
                                                        }
                                                        style={
                                                            isActive
                                                                ? {
                                                                      cursor:
                                                                          'pointer',
                                                                  }
                                                                : {}
                                                        }
                                                    >
                                                        <div className="sport__transfer__dark">
                                                            <div className="sport__transfer__icon">
                                                                {renderIcon(
                                                                    bet.status,
                                                                    isCancelled
                                                                )}
                                                                <span
                                                                    className={`${
                                                                        isCancelled
                                                                            ? 'text-cancelled'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {
                                                                        bet.competitor
                                                                    }
                                                                </span>
                                                                {isCancelled && (
                                                                    <span className="red-text">
                                                                        Annuleret
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div
                                                                className={`sport__transfer__gray ${
                                                                    isCancelled
                                                                        ? 'text-cancelled'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {bet.marketName}
                                                            </div>
                                                            <div
                                                                className={`sport__transfer__gray ${
                                                                    isCancelled
                                                                        ? 'text-cancelled'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {bet.eventName}
                                                            </div>
                                                            <div
                                                                className={`sport__transfer__gray ${
                                                                    isCancelled
                                                                        ? 'text-cancelled'
                                                                        : ''
                                                                }`}
                                                            >
                                                                Resultat:{' '}
                                                                {bet.resultText ||
                                                                    ''}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`sport__transfer__dark ${
                                                                isCancelled
                                                                    ? 'text-cancelled'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {bet.odds
                                                                ? bet.odds.toFixed(
                                                                      2
                                                                  )
                                                                : ''}
                                                        </div>
                                                    </div>
                                                    <div className="transfer__line" />
                                                </React.Fragment>
                                            );
                                        })
                            )}
                            <div className="sport__transfer__single">
                                {betTypesString}
                            </div>
                            <div className="transfer__line" />
                            <div className="financials">
                                <div className="financials__left">
                                    <div className="sport__transfer__gray">
                                        Indsats
                                    </div>
                                    <div className="sport__transfer__dark">
                                        {(item.data.stake / 100).toLocaleString(
                                            'da-DK',
                                            {
                                                style: 'currency',
                                                currency: 'DKK',
                                            }
                                        )}
                                    </div>
                                </div>
                                <div className="financials__right">
                                    <div className="sport__transfer__gray">
                                        Samlet odds
                                    </div>
                                    <div className="sport__transfer__dark">
                                        {item.data.couponList.length === 1
                                            ? item.data.couponList[0].odds.toFixed(
                                                  2
                                              )
                                            : calculateOddsDisplay(
                                                  item.data.couponList
                                              )}
                                    </div>
                                </div>
                            </div>
                            <div className="transfer__line" />
                            <div className="results">
                                <div className="results__left">
                                    <div className="sport__transfer__gray">
                                        Status
                                    </div>
                                    <div className="sport__transfer__dark">
                                        {currentStatus}
                                    </div>
                                </div>
                                <div className="results__right">
                                    <div className="sport__transfer__gray">
                                        {currentStatus === 'Aktiv'
                                            ? 'Mulig gevinst'
                                            : currentStatus === 'Annulleret'
                                                ? 'Indsats retur'
                                                : 'Gevinst'}
                                    </div>
                                    <div className="sport__transfer__dark">
                                        <strong>{winAmount}</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="transfer__line" />
                            {showRetailTransferUi(item) &&
                                !isCanceledCoupon(item) && (
                                    <Wrapper
                                        width={'100%'}
                                        padding={'0'}
                                        margin={'0'}
                                        flex
                                        justify={'center'}
                                        flexAlign={'center'}
                                        boxSizing={'border-box'}
                                    >
                                        <PayoutStatusComponent
                                            product={item.product}
                                            saleId={item.data.saleId}
                                            win={item.data.win}
                                            payoutStatus={item.payoutStatus}
                                            payoutType={item.payoutType}
                                        />
                                    </Wrapper>
                                )}
                            <div className="footer">
                                ID: {item.data.saleId} -{' '}
                                {new Date(
                                    item.data.saleDate
                                ).toLocaleDateString('da-DK', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                })}{' '}
                                -{' '}
                                {new Date(
                                    item.data.saleDate
                                ).toLocaleTimeString('da-DK', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

function mapStateToProps(state) {
    return {
        retailSalesHistory: state.retailSalesHistory,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SportTransfer);
