import {
    UI_SPORT_LIVE_ACTIVE_SPORT,
    UI_SPORT_LIVE_EVENTS_BY_SPORT,
    UI_SPORT_LIVE_SEARCH_TEXT,
} from 'constants/ui';

const initialState = {
    activeSportId: 0,
    liveEventsBySport: null,
    searchText: null,
};

export default function uiSportLive(state = initialState, action) {
    switch (action.type) {
        case UI_SPORT_LIVE_ACTIVE_SPORT:
            return { ...state, activeSportId: action.payload };
        case UI_SPORT_LIVE_EVENTS_BY_SPORT:
            return { ...state, liveEventsBySport: action.payload };
        case UI_SPORT_LIVE_SEARCH_TEXT:
            return { ...state, searchText: action.payload };
        default:
            return state;
    }
}
