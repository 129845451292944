import React from 'react';
import UIModalNew from 'components/UIModalNew';
import Wrapper from 'ui/Wrapper';
import Button from 'ui/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideModal, setLoginModalStatus } from 'actions/UiActions';
import { browserHistory } from 'react-router';
import { detectMobile } from 'utils/UiHelper';
import Text from 'ui/Text';

const LoginFirstPopup = ({ hideModal, loginModalStatus }) => {
    const closePopup = () => {
        browserHistory.push('/sport/');
        hideModal('USER_MENU');
        hideModal('LOGIN_FIRST');
    };
    const openLoginPage = () => {
        hideModal('LOGIN_FIRST');
        detectMobile()
            ? loginModalStatus(true)
            : browserHistory.push('/sport/log-ind');
    };

    return (
        <UIModalNew preventFullscreen={true}>
            <Text center bold color="#333" size="24">
                Log ind for at fortsætte
            </Text>
            <Wrapper display="flex" justify="space-between">
                <Button
                    grey
                    margin="0"
                    padding="15px"
                    width="45%"
                    onClick={closePopup}
                >
                    OK
                </Button>
                <Button
                    margin="0"
                    padding="15px"
                    active
                    width="45%"
                    onClick={openLoginPage}
                >
                    Log ind
                </Button>
            </Wrapper>
        </UIModalNew>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        hideModal: bindActionCreators(hideModal, dispatch),
        loginModalStatus: bindActionCreators(setLoginModalStatus, dispatch),
    };
}

export default connect(
    null,
    mapDispatchToProps
)(LoginFirstPopup);
