import homeIcon from 'images/icons/bottom-menu/home.svg';
import mineSpil from 'images/icons/bottom-menu/mine-spil.svg';
import live from 'images/icons/bottom-menu/live.svg';
import big9 from 'images/icons/bottom-menu/big9.svg';
import calendar from 'images/icons/bottom-menu/calendar.svg';
import football from 'images/icons/bottom-menu/football.svg';
import tennis from 'images/icons/bottom-menu/tennis.svg';
import basketball from 'images/icons/bottom-menu/basketball.svg';
import icehockey from 'images/icons/bottom-menu/icehockey.svg';
import handball from 'images/icons/bottom-menu/handball.svg';
import otherSports from 'images/icons/bottom-menu/anden.svg';
import liveCasino from 'images/icons/bottom-menu/live-casino.svg';
import spilID from 'images/icons/bottom-menu/spild_id.svg';
import { getCardFromStorage } from '../features/SpilID/useSpilIdCard';

export default (
    history,
    activeItem,
    activeBets,
    initialMenuPosition,
    showModal,
    auth
) => {
    const uniqItems = ['/kampagner']; // important for highlight "More" item

    const spilIDCardFromStorage = getCardFromStorage() || {};

    const isTWA = Boolean(JSON.parse(window.localStorage.getItem('isTWA')));

    const homeActiveItems = [
        '/sport',
        '/sport/fodbold',
        '/sport/pre',
        '/sport/tennis',
        '/sport/basketball',
        '/sport/ishockey',
        '/sport/haandbold',
        '/sport/andet-sport',
        '/sport/live-casino',
    ];

    return {
        backgroundColor: '#1C3E60',
        initialMenuPosition: initialMenuPosition,
        secondaryColor: '#A9C7E6',
        zIndexWrapper: 10,
        MenuItemsColor: '#20486F',
        menuItems: [
            {
                title: 'Hjem',
                icon: homeIcon,
                action: () => history.push('/sport/'),
                active: homeActiveItems.includes(activeItem),
            },
            {
                title: 'Mine spil',
                icon: mineSpil,
                action: () => history.push('/sport/historik/aktive'),
                active: activeItem === '/sport/historik',
                badge: activeBets,
            },
            {
                title: 'Big 9',
                icon: big9,
                action: () => history.push('/sport/big9'),
                active: activeItem === '/sport/big9',
            },
            {
                title: 'Live odds',
                icon: live,
                action: () => history.push('/sport/live'),
                active: activeItem === '/sport/live',
            },
        ],
        dropDownMenuItems: {
            campaignAction: () => history.push('/sport/kampagner'),
            intercomAction: () => window.Intercom('show'),
            uniqItemsActive: uniqItems.some((item) => activeItem === item),
            menuItems: [
                [
                    {
                        logo: calendar,
                        title: 'Starter snart',
                        action: () => history.push('/sport/pre'),
                    },
                    ...(!isTWA
                        ? [
                              {
                                  logo: liveCasino,
                                  title: 'Live casino',
                                  action: () =>
                                      history.push('/sport/live-casino'),
                              },
                          ]
                        : []),
                ],
                [
                    {
                        logo: spilID,
                        title: 'Spil ID',
                        action: () =>
                            auth?.user ||
                            Object.keys(spilIDCardFromStorage).length > 0
                                ? showModal('SPIL_ID_MODAL', auth)
                                : showModal('LOGIN_MOBILE'),
                    },
                ],
                [
                    {
                        logo: football,
                        title: 'Fodbold',
                        action: () => history.push('/sport/fodbold'),
                    },
                    {
                        logo: tennis,
                        title: 'Tennis',
                        action: () => history.push('/sport/tennis'),
                    },
                    {
                        logo: basketball,
                        title: 'Basketball',
                        action: () => history.push('/sport/basketball'),
                    },
                    {
                        logo: icehockey,
                        title: 'Ishockey',
                        action: () => history.push('/sport/ishockey'),
                    },
                    {
                        logo: handball,
                        title: 'Håndbold',
                        action: () => history.push('/sport/haandbold'),
                    },
                    {
                        logo: otherSports,
                        title: 'Anden sport',
                        action: () => history.push('/sport/andet-sport'),
                    },
                ],
            ],
        },
    };
};
