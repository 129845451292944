import { useState } from 'react';
import { SpilIdService } from '../../utils/SpilIdService';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

const serializeRequest = ({
    cardId,
    fullName,
    address,
    zipCode,
    birthdate,
    city,
}) => ({
    card_id: cardId,
    name: fullName,
    address,
    city_index: zipCode,
    app: 'bet_25',
    dob: moment(birthdate, 'DD-MM-YYYY').format('DDMMYYYY'), // Format of dob should be
    city,
});

const useOrderPhysicalCard = ({
    cardId,
    fullName,
    address,
    zipCode,
    birthdate,
    city,
}) => {
    const [pending, setPending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState();

    const requestCardOrder = async () => {
        setPending(true);
        SpilIdService.orderSpilIdPhysicalCard(
            serializeRequest({
                cardId,
                fullName,
                address,
                zipCode,
                birthdate,
                city,
            })
        )
            .then((res) => {
                if (!res.success) {
                    NotificationManager.error(res.msg);
                }
                setSuccess(res.success);
                setPending(false);
            })
            .catch((e) => {
                setError(e);
                NotificationManager.success(e);
                setPending(false);
            });
    };

    return { requestCardOrder, pending, error, success };
};

export default useOrderPhysicalCard;
