import { VAIX_TRACKING_TYPE } from '../../constants/vaix/VaixTrcakingConstants';

const getVaixDataTrackingImpressions = (
    location,
    page,
    eventsIds = [],
    userId = 0
) => {
    return {
        event_type: VAIX_TRACKING_TYPE.impressions,
        data: {
            location: location,
            page: page,
            events: eventsIds,
        },
        user_id: userId,
    };
};

const getVaixDataTrackingCLick = (
    location,
    page,
    eventId,
    userId = 0,
    position,
    isCustomBetBuilder = false
) => {
    let data = {
        location: location,
        page: page,
        event_id: isCustomBetBuilder ? eventId : `sr:match:${eventId}`,
        ...(position > 0 && { position }),
    };
    return {
        event_type: VAIX_TRACKING_TYPE.clickEvent,
        data,
        user_id: userId,
    };
};

const getVaixDataTrackingOutcome = (
    location,
    page,
    eventId,
    userId = 0,
    outcome,
    position
) => {
    let data = {
        location: location,
        page: page,
        event_id: `sr:match:${eventId}`,
        ...(outcome > 0 && { outcome_id: outcome }),
        ...(position > 0 && { position }),
    };
    return {
        event_type: VAIX_TRACKING_TYPE.clickOutcome,
        data,
        user_id: userId,
    };
};

const getVaixDataTrackingCombinations = (
    location,
    page,
    events,
    userId = 0,
    position
) => {
    return {
        event_type: VAIX_TRACKING_TYPE.clickCombinations,
        data: {
            location: location,
            page: page,
            position: position,
            selections: events,
        },
        user_id: userId,
    };
};

export const getVaixTrackingData = (
    events,
    eventType,
    location,
    page,
    userId,
    outcome,
    position,
    isCustomBetBuilder = false
) => {
    let vaixEventsIds = [];
    if (eventType === VAIX_TRACKING_TYPE.impressions && !isCustomBetBuilder) {
        events.map((item) => {
            vaixEventsIds.push({ id: `sr:match:${item.externalId}` });
        });
    }

    switch (eventType) {
        case VAIX_TRACKING_TYPE.impressions:
            return getVaixDataTrackingImpressions(
                location,
                page,
                isCustomBetBuilder ? events : vaixEventsIds,
                userId || 0
            );

        case VAIX_TRACKING_TYPE.clickEvent:
            return getVaixDataTrackingCLick(
                location,
                page,
                isCustomBetBuilder ? events : events.externalId,
                userId || 0,
                position
            );
        case VAIX_TRACKING_TYPE.clickOutcome:
            return getVaixDataTrackingOutcome(
                location,
                page,
                events.externalId,
                userId || 0,
                outcome,
                position
            );
        case VAIX_TRACKING_TYPE.clickCombinations:
            return getVaixDataTrackingCombinations(
                location,
                page,
                events,
                userId || 0,
                position
            );
        default:
            break;
    }
};
