import { createState, useState } from '@hookstate/core';

const initialState = {
    isShown: createState(false),
    isResult: createState(true),
};

const useBetslipState = () => {
    const isShown = useState(initialState.isShown);
    const isResult = useState(initialState.isResult);

    return {
        showBetslip: (state) => isShown.set(state),
        setEmptySellOffer: (state) => isResult.set(state),
        isShown: isShown.get(),
        isResult: isResult.get(),
    };
};

export default useBetslipState;
