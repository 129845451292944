import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ViewRadio, ViewTextInput } from 'components/ViewInput';
import { ViewButton } from 'components/ViewButton';
import ViewSpinner from 'components/ViewSpinner';
import { notification } from 'actions/UiActions';
import ViewPopup from 'components/ViewPopup';
import 'css/FeedbackContainer.css';
import RestService from 'utils/RestService';
import { UI_SUCCESS_MESSAGE } from 'constants/ui';
import UIModal from 'components/UIModal';
import {
    detectMobile,
    disableBodyScroll,
    enableBodyScroll,
} from 'utils/UiHelper';

class FeedbackContainer extends Component {
    constructor() {
        super();

        this.state = {
            radioGroup: null,
            text: '',
            isPending: false,
        };
    }

    onClosePopupHandler() {
        if (detectMobile()) {
            enableBodyScroll();
        }
        this.props.hideModal('FEEDBACK_MODAL');
    }

    sendFeedback() {
        if (this.state.radioGroup) {
            this.setState({
                isPending: true,
            });

            let step = this.props.auth.step1Success
                ? this.props.auth.step2Success
                    ? '3'
                    : '2'
                : '1';

            RestService.SendFeedback(
                this.state.radioGroup,
                this.state.text,
                step,
                (res) => {
                    if (detectMobile()) {
                        enableBodyScroll();
                    }

                    this.props.hideModal('FEEDBACK_MODAL');
                    this.props.notification(UI_SUCCESS_MESSAGE, res.data.msg);
                    this.setState({
                        isPending: false,
                    });
                }
            );
        }
    }

    componentDidMount() {
        disableBodyScroll();
    }

    onChangeRadioValue(val) {
        this.setState({ radioGroup: val });
    }

    onTextHandler(name, val) {
        this.setState({ text: val });
    }

    render() {
        if (detectMobile()) {
            return (
                <UIModal
                    className={detectMobile() ? 'scrolly bg-white' : ''}
                    onCloseModal={this.onClosePopupHandler.bind(this)}
                >
                    <div className="feedback-conteiner">
                        <h1 className="text-center blue">
                            VI HAR BRUG FOR DIN FEEDBACK
                        </h1>
                        <div className="divider" />
                        <div className="p10">
                            <p className="text-center">
                                Øv. Vi er kede af, at du forlader vores
                                oprettelsesproces.
                            </p>
                            <h4 className="text-center">
                                Hvad er årsagen til, at du forlader oprettelsen?
                            </h4>
                        </div>

                        <div className="radio-group">
                            <ViewRadio
                                name="type"
                                value="1"
                                onChangeHandler={this.onChangeRadioValue.bind(
                                    this
                                )}
                            >
                                Det er for besværligt med NemID
                            </ViewRadio>
                            <ViewRadio
                                name="type"
                                value="2"
                                onChangeHandler={this.onChangeRadioValue.bind(
                                    this
                                )}
                            >
                                Jeg har ikke mit NemID på mig
                            </ViewRadio>
                            <ViewRadio
                                name="type"
                                value="3"
                                onChangeHandler={this.onChangeRadioValue.bind(
                                    this
                                )}
                            >
                                Oprettelsesprocessen er for lang/besværlig
                            </ViewRadio>
                            <ViewRadio
                                name="type"
                                value="4"
                                onChangeHandler={this.onChangeRadioValue.bind(
                                    this
                                )}
                            >
                                Andre årsager:
                                <ViewTextInput
                                    disabled={this.state.radioGroup !== '4'}
                                    name="text-answer"
                                    valueHandler={this.onTextHandler.bind(this)}
                                />
                            </ViewRadio>
                        </div>

                        <div className="p10 mt35">
                            {!this.state.isPending ? (
                                <ViewButton
                                    onClickHandler={this.sendFeedback.bind(
                                        this
                                    )}
                                >
                                    Send Feedback
                                </ViewButton>
                            ) : (
                                <ViewSpinner />
                            )}
                        </div>
                    </div>
                </UIModal>
            );
        } else {
            return (
                <ViewPopup
                    className={detectMobile() ? 'scrolly bg-white' : ''}
                    onClosePopupHandler={this.onClosePopupHandler.bind(this)}
                >
                    <div className="feedback-conteiner">
                        <h1 className="text-center blue">
                            VI HAR BRUG FOR DIN FEEDBACK
                        </h1>
                        <div className="divider" />
                        <div className="p10">
                            <p className="text-center">
                                Øv. Vi er kede af, at du forlader vores
                                oprettelsesproces. Vi arbejder altid på at gøre
                                det nemt for nye brugere at oprette sig. Derfor
                                vil vi gerne høre fra dig.
                            </p>
                            <h4 className="text-center">
                                Hvad er årsagen til, at du forlader oprettelsen?
                            </h4>
                        </div>

                        <div className="radio-group">
                            <ViewRadio
                                name="type"
                                value="1"
                                onChangeHandler={this.onChangeRadioValue.bind(
                                    this
                                )}
                            >
                                Det er for besværligt med NemID
                            </ViewRadio>
                            <ViewRadio
                                name="type"
                                value="2"
                                onChangeHandler={this.onChangeRadioValue.bind(
                                    this
                                )}
                            >
                                Jeg har ikke mit NemID på mig
                            </ViewRadio>
                            <ViewRadio
                                name="type"
                                value="3"
                                onChangeHandler={this.onChangeRadioValue.bind(
                                    this
                                )}
                            >
                                Oprettelsesprocessen er for lang/besværlig
                            </ViewRadio>
                            <ViewRadio
                                name="type"
                                value="4"
                                onChangeHandler={this.onChangeRadioValue.bind(
                                    this
                                )}
                            >
                                Andre årsager:
                                <ViewTextInput
                                    disabled={this.state.radioGroup !== '4'}
                                    name="text-answer"
                                    valueHandler={this.onTextHandler.bind(this)}
                                />
                            </ViewRadio>
                        </div>

                        <div className="p10 mt35">
                            {!this.state.isPending ? (
                                <ViewButton
                                    onClickHandler={this.sendFeedback.bind(
                                        this
                                    )}
                                >
                                    Send Feedback
                                </ViewButton>
                            ) : (
                                <ViewSpinner />
                            )}
                        </div>
                    </div>
                </ViewPopup>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        signupModalStatus: state.ui.signupModalStatus,
        feedbackPopupStatus: state.ui.feedbackPopupStatus,
        auth: state.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        notification: (type, message) => dispatch(notification(type, message)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FeedbackContainer);
