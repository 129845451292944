import {
    LIVE_EVENT_ADD,
    LIVE_EVENT_ADD_FAVORITE,
    LIVE_EVENT_ANIMATION_STATUS,
    LIVE_EVENT_MERGE_MARKET_LIST,
    LIVE_EVENT_REMOVE,
    LIVE_EVENTS_VAIX_FAVORITE_ADD,
    LIVE_EVENT_SET,
    LIVE_EVENT_UPDATE,
    LIVE_EVENT_UPDATE_MAIN_MARKET,
    LIVE_EVENT_UPDATE_DATA_WITH_VAIX_SORTING,
    LIVE_EVENT_UPDATE_DATA,
    LIVE_EVENTS_FETCH_ERROR,
    LIVE_EVENTS_FETCH_PENDING,
    LIVE_EVENTS_VAIX_FAVORITE_FETCHED,
    LIVE_EVENTS_FETCH_SUCCESS,
    LIVE_EVENTS_VAIX_POPULAR_ADD,
    LIVE_EVENTS_VAIX_POPULAR_PENDING,
    LIVE_EVENTS_VAIX_POPULAR_FETCHED,
    LIVE_EVENTS_VAIX_POPULAR_ERROR,
} from 'constants/liveEvents';
import { mergeMarketLists, mergeVaixMarketListsScope } from 'utils/Helper';
import { sortDataByArrayOfEventIds } from '../utils/Helper';
import { ANBEFALET_SPORT_ID } from '../constants/appConfig';

let favoriteEvents = window.localStorage.getItem('favorite-events');
const initialState = {
    pending: false,
    fetched: false,
    data: {},
    eventSport: {},
    array: [],
    error: false,
    vaixFavoriteEvents: [],
    vaixFavoriteEventsFetched: false,
    vaixPopularEventsPending: false,
    vaixPopularEvents: [],
    vaixPopularEventsFetched: false, //TODO changed state it need to be tested
    updateDataWithVaixSorting: false,
    updateData: false,
    favorite: favoriteEvents ? JSON.parse(favoriteEvents) : [],
    animationStatus: false,
};

export default function liveEvents(state = initialState, action) {
    const dataState = { ...state.data };

    switch (action.type) {
        case LIVE_EVENT_SET:
            if (!dataState[action.payload.sportId]) {
                dataState[action.payload.sportId] = {};
            }
            let vaixPopularEventsTmp = [...state.vaixPopularEvents];
            let vaixFavoriteEventsTmp = [...state.vaixFavoriteEvents];

            let eventData = {
                marketList: [],
                ...action.payload,
            };

            try {
                if (action.payload.marketList) {
                    const prevMarketList =
                        action.payload.sportId &&
                        action.payload.eventId &&
                        dataState[action.payload.sportId][
                            action.payload.eventId
                        ]
                            ? dataState[action.payload.sportId][
                                  action.payload.eventId
                              ].marketList
                            : [];

                    eventData.marketList = mergeMarketLists(
                        prevMarketList,
                        action.payload.marketList
                    );
                }
            } catch (error) {
                console.warn(error, 'liveEvents: ', action.payload);
            }

            dataState[action.payload.sportId][action.payload.eventId] = {
                ...dataState[action.payload.sportId][action.payload.eventId],
                ...eventData,
                live: true,
            };

            if (
                dataState[ANBEFALET_SPORT_ID] &&
                dataState[ANBEFALET_SPORT_ID].length > 0 &&
                action.payload?.marketList
            ) {
                let index = dataState[ANBEFALET_SPORT_ID].findIndex(
                    (item) => item.externalId === action.payload.externalId
                );
                if (index !== -1) {
                    dataState[ANBEFALET_SPORT_ID][index] = {
                        ...dataState[ANBEFALET_SPORT_ID][index],
                        ...eventData,
                        live: true,
                    };
                }
            }

            if (vaixPopularEventsTmp.length > 0 && action.payload?.marketList) {
                let index = vaixPopularEventsTmp.findIndex(
                    (item) => item.externalId === action.payload.externalId
                );
                if (index !== -1) {
                    vaixPopularEventsTmp[index] = {
                        ...eventData,
                        live: true,
                    };
                }
            }

            if (
                vaixFavoriteEventsTmp.length > 0 &&
                action.payload?.marketList
            ) {
                let index = vaixFavoriteEventsTmp.findIndex(
                    (item) => item.externalId === action.payload.externalId
                );
                if (index !== -1) {
                    vaixFavoriteEventsTmp[index] = {
                        ...eventData,
                        live: true,
                    };
                }
            }

            return {
                ...state,
                pending: false,
                fetched: true,
                data: dataState,
                vaixPopularEventsFetched: true,
                eventSport: {
                    ...state.eventSport,
                    [action.payload.eventId]: action.payload.sportId,
                },
                vaixPopularEvents: vaixPopularEventsTmp,
                vaixFavoriteEvents: vaixFavoriteEventsTmp,
                error: false,
            };

        case LIVE_EVENTS_VAIX_POPULAR_PENDING:
            return {
                ...state,
                vaixPopularEventsPending: action.payload,
            };

        case LIVE_EVENTS_FETCH_PENDING:
            return {
                ...state,
                fetched: action.payload,
            };

        case LIVE_EVENTS_FETCH_SUCCESS:
            return {
                ...state,
                pending: false,
                fetched: true,
                data: action.payload.data,
                array: action.payload.array,
                eventSport: action.payload.eventSport,
                error: false,
            };

        case LIVE_EVENTS_FETCH_ERROR:
            return {
                ...state,
                pending: false,
                fetched: true,
                data: [],
                error: action.payload,
            };

        case LIVE_EVENT_ADD_FAVORITE:
            let items = [...state.favorite];
            const eventId = action.payload;

            if (items.indexOf(eventId) === -1) {
                items = [...items, eventId];
            } else {
                items = [...items.filter((i) => i !== eventId)];
            }

            window.localStorage.setItem(
                'favorite-events',
                JSON.stringify(items)
            );

            return {
                ...state,
                favorite: items,
            };
        case LIVE_EVENT_ANIMATION_STATUS:
            return {
                ...state,
                animationStatus: action.payload,
            };
        case LIVE_EVENT_UPDATE:
            const tmp = { ...state.data };

            if (
                tmp[action.payload.sportId] &&
                tmp[action.payload.sportId][action.payload.eventId] &&
                action.payload.status !== undefined
            ) {
                tmp[action.payload.sportId][action.payload.eventId].status =
                    action.payload.status;
            }

            return {
                ...state,
                pending: false,
                fetched: true,
                data: tmp,
                error: false,
            };

        case LIVE_EVENT_ADD:
            const tmp3 = { ...state.data };

            if (!tmp3[action.payload.sportId]) {
                tmp3[action.payload.sportId] = {};
            }

            tmp3[action.payload.sportId][action.payload.eventId] = {
                ...action.payload,
                live: true,
            };
            return {
                ...state,
                pending: false,
                fetched: true,
                data: tmp3,
                eventSport: {
                    ...state.eventSport,
                    [action.payload.eventId]: action.payload.sportId,
                },
                error: false,
            };

        case LIVE_EVENTS_VAIX_FAVORITE_ADD:
            //we add this event to the live events in order to open anabelet tab in horizontal navigation it can be simplify when we change logic for navigation
            const tmp6Data = { ...state.data };
            let vaixFavoriteEvents = sortDataByArrayOfEventIds(
                action.payload.data,
                action.payload.eventsIds
            );
            return {
                ...state,
                data: {
                    ...tmp6Data,
                    [ANBEFALET_SPORT_ID]: [...vaixFavoriteEvents],
                },
                vaixFavoriteEvents: [...vaixFavoriteEvents],
                vaixFavoriteEventsFetched: true,
                pending: false,
            };

        case LIVE_EVENTS_VAIX_POPULAR_ADD:
            let vaixPopularEvents = sortDataByArrayOfEventIds(
                action.payload.data,
                action.payload.eventsIds
            );

            return {
                ...state,
                vaixPopularEvents: vaixPopularEvents,
                vaixPopularEventsPending: false,
                vaixPopularEventsFetched: true,
            };

        case LIVE_EVENTS_VAIX_FAVORITE_FETCHED:
            return {
                ...state,
                vaixFavoriteEventsFetched: action.payload,
            };

        case LIVE_EVENTS_VAIX_POPULAR_FETCHED:
            return {
                ...state,
                vaixPopularEventsFetched: action.payload,
            };

        case LIVE_EVENTS_VAIX_POPULAR_ERROR:
            return {
                ...state,
                vaix: action.payload,
            };

        case LIVE_EVENT_REMOVE:
            const tmp4 = { ...state.data };
            const tmp5 = { ...state.eventSport };

            if (
                tmp4[state.eventSport[action.payload]] &&
                tmp4[state.eventSport[action.payload]][action.payload]
            ) {
                delete tmp4[state.eventSport[action.payload]][action.payload];

                if (
                    Object.keys(tmp4[state.eventSport[action.payload]])
                        .length === 0
                ) {
                    delete tmp4[state.eventSport[action.payload]];
                }

                delete tmp5[action.payload];
            }

            return {
                ...state,
                pending: false,
                fetched: true,
                data: tmp4,
                eventSport: tmp5,
                error: false,
            };

        case LIVE_EVENT_UPDATE_DATA_WITH_VAIX_SORTING:
            /*console.log(
                'LIVE_EVENT_UPDATE_DATA_WITH_VAIX_SORTING',
                action.payload
            );*/
            return {
                ...state,
                updateDataWithVaixSorting: action.payload,
            };

        case LIVE_EVENT_UPDATE_DATA:
            /*console.log('LIVE_EVENT_UPDATE_DATA', action.payload);*/
            return {
                ...state,
                updateData: action.payload,
            };

        case LIVE_EVENT_MERGE_MARKET_LIST: {
            const { eventId, marketList } = action.payload;
            const sportId = state.eventSport[eventId];
            try {
                if (state.data[sportId] && state.data[sportId][eventId]) {
                    return {
                        ...state,
                        data: {
                            ...state.data,
                            [sportId]: {
                                ...state.data[sportId],
                                [eventId]: {
                                    ...state.data[sportId][eventId],
                                    marketList: mergeMarketLists(
                                        state.data[sportId][eventId].marketList,
                                        marketList
                                    ),
                                },
                            },
                        },
                    };
                }
                return state;
            } catch (error) {
                console.warn(error, 'liveEvents: ', action.payload);
                return state;
            }
        }

        case LIVE_EVENT_UPDATE_MAIN_MARKET:
            const item = action.payload;
            const tmp2 = { ...state.data };

            if (
                tmp2[item.sportId] &&
                tmp2[item.sportId][item.eventId] &&
                item.marketName !== 'O/U 2.5'
            ) {
                if (!tmp2[item.sportId][item.eventId].marketList) {
                    tmp2[item.sportId][item.eventId].marketList = [];
                }

                tmp2[item.sportId][item.eventId].marketList[0] = item;
            }

            if (
                tmp2[item.sportId] &&
                tmp2[item.sportId][item.eventId] &&
                item.marketName !== 'O/U 2.5'
            ) {
                tmp2[item.sportId][item.eventId].marketList.map(
                    (m, index) =>
                        m.marketId === item.marketId
                            ? (tmp2[item.sportId][item.eventId].marketList[
                                  index
                              ] = item)
                            : m
                );
            }

            return {
                ...state,
                pending: false,
                fetched: true,
                data: tmp2,
                error: false,
            };

        default:
            return state;
    }
}
