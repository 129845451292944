import { getImageBasePath } from '../../utils/UiHelper';

const BASE_IMAGE_PATH = getImageBasePath();
export const getMenuItemsList = (handlers = {}) => [
    {
        icon: `${BASE_IMAGE_PATH}/img/menu-icons/hjem.svg`,
        title: 'Hjem',
        link: '/',
    },
    {
        icon: `${BASE_IMAGE_PATH}/img/menu-icons/news.svg`,
        title: '25 nyt',
        link: '/kampagner',
    },
    {
        icon: `${BASE_IMAGE_PATH}/img/menu-icons/chat.svg`,
        title: 'Hjælp',
        onClick: () => {
            handlers.openHelpDialog();
        },
    },
    {
        icon: `${BASE_IMAGE_PATH}/img/menu-icons/customer.svg`,
        title: 'Spil ID',
        onClick: handlers.showSpilID,
        delimiter: true,
    },
    {
        icon: `${BASE_IMAGE_PATH}/img/menu-icons/live.svg`,
        title: 'Live',
        link: '/live',
    },
    {
        icon: `${BASE_IMAGE_PATH}/img/menu-icons/big9.svg`,
        title: 'Big 9',
        link: '/spil',
        isNewElement: true,
    },
    {
        icon: `${BASE_IMAGE_PATH}/img/menu-icons/spilklub.svg`,
        title: 'SpilKlub25',
        link: '/spilklub25',
        isNewElement: false,
    },
    {
        icon: `${BASE_IMAGE_PATH}/img/menu-icons/pre.svg`,
        title: 'Starter snart',
        link: '/pre',
    },
    {
        icon: `${BASE_IMAGE_PATH}/img/menu-icons/mine_spil.svg`,
        title: 'Mine spil',
        onClick: () => {
            handlers.openUserHistory();
        },
        delimiter: true,
    },
    {
        icon: `${BASE_IMAGE_PATH}/img/menu-icons/live-casino.svg`,
        title: 'Live Casino',
        link: '/live-casino',
        delimiter: true,
    },
];
