import React from 'react';
import ModalsHandler from './ModalsHandler';
import { ModalsMapConfig } from 'constants/mobileModals';
import ModalContainer from './ModalContainer';

class MobileModals extends React.Component {
    render() {
        return (
            <ModalsHandler
                modalsMap={ModalsMapConfig}
                parent={this.props.parent}
            >
                {(modalProps) => <ModalContainer {...modalProps} />}
            </ModalsHandler>
        );
    }
}

export default MobileModals;
