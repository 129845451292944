import React, { useEffect, useState, useCallback } from 'react';
import ViewModal from 'components/ViewModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthActions from 'actions/AuthActions';

import NoMatchPage from 'pages/NoMatchPage';
import PaymentDepositPage from 'pages/PaymentDepositPage';
//import PaymentPayoutPage from 'pages/PaymentPayout/PaymentPayoutPage';
import PaymentPayoutPage from 'pages/PaymentPayout/PaymentPayoutPageNew';
import PaymentVoucherPage from 'pages/PaymentVoucherPage';
import TransactionHistory from 'pages/TransactionsHistory/TransactionHistory';
import IndbetalingsgraensePage from 'pages/IndbetalingsgraensePage';
import SelfExclusionPage from 'pages/SelfExclusionPage';
import BrugerinfoPage from 'pages/BrugerinfoPage';
import NewsPage from 'pages/NewsPage';
import BonusPage from 'pages/BonusPage';

import 'css/SectionUserArea.scss';
import {
    disableBodyScroll,
    enableBodyScroll,
    formatDate,
} from 'utils/UiHelper';
import MyBets from 'features/MyBets';
import Big9History from 'features/Big9History';
import { browserHistory } from 'react-router';
import RetailSalesHistory from '../features/RetailSalesHistory/RetailSalesHistory';

const menuPages = [
    '/sport/konto/indbetaling',
    '/sport/konto/udbetaling',
    '/sport/konto/bonus',
    '/sport/konto/voucher',
    '/sport/konto/kontohistorik',
    '/sport/konto/historik/aktive',
    '/sport/konto/historik/afsluttede',
    '/sport/konto/historik/big9',
    '/sport/konto/historik/butik',
    '/sport/konto/indbetalingsgraense',
    '/sport/konto/selvudelukkelse',
    '/sport/konto/brugerinfo',
    '/sport/konto/nyhedsbrev',
];

const menuConfig = [
    { title: 'Indbetaling', style: {}, url: '/sport/konto/indbetaling' },
    { title: 'Udbetaling', style: {}, url: '/sport/konto/udbetaling' },
    { title: 'Bonusomsætning', style: {}, url: '/sport/konto/bonus' },
    {
        title: 'Kontohistorik',
        url: '/sport/konto/kontohistorik',
    },
    {
        title: 'Indløs Voucher',
        style: { marginBottom: 16 + 'px' },
        url: '/sport/konto/voucher',
    },
    { title: 'Aktive spil', style: {}, url: '/sport/konto/historik/aktive' },
    {
        title: 'Afsluttede spil',
        style: {},
        url: '/sport/konto/historik/afsluttede',
    },
    {
        title: 'Big 9',
        style: {},
        url: '/sport/konto/historik/big9',
    },
    {
        title: 'Butik',
        style: { marginBottom: 16 + 'px' },
        url: '/sport/konto/historik/butik',
    },
    {
        title: 'Indbetalingsgrænse',
        style: {},
        url: '/sport/konto/indbetalingsgraense',
    },
    {
        title: 'Selvudelukkelse',
        style: { marginBottom: 16 + 'px' },
        url: '/sport/konto/selvudelukkelse',
    },

    { title: 'Dine oplysninger', style: {}, url: '/sport/konto/brugerinfo' },
    {
        title: 'Nyhedsbrev',
        style: { marginBottom: 16 + 'px' },
        url: '/sport/konto/nyhedsbrev',
    },
];

const SectionUserArea = ({ customUserAreaTab, ...props }) => {
    const [activePage, setActivePage] = useState('/sport/konto/indbetaling');
    const [containerView, setContainerView] = useState(null);
    // const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [veTransfers, setVeTransfers] = useState([]);

    const [beTransfers, setBeTransfers] = useState([]);
    const [hasMoreItems, setMoreItems] = useState([]);
    const user = props?.auth?.user;

    useEffect(() => {
        disableBodyScroll();
        if (customUserAreaTab) {
            _goToPage(customUserAreaTab);
        }

        return () => {
            enableBodyScroll();
        };
    }, []);

    useEffect(
        () => {
            const page = window.location.pathname;
            if (page && page !== activePage && menuPages.includes(page)) {
                _goToPage(page);
            }
        },
        [window.location.pathname]
    );

    useEffect(
        () => {
            switch (activePage) {
                case '/sport/konto/indbetaling':
                    setContainerView(
                        <PaymentDepositPage
                            closeModal={() => {
                                onCloseModalHandler();
                            }}
                        />
                    );
                    break;
                case '/sport/konto/udbetaling':
                    setContainerView(<PaymentPayoutPage />);
                    break;
                case '/sport/konto/bonus':
                    setContainerView(<BonusPage />);
                    break;
                case '/sport/konto/voucher':
                    setContainerView(<PaymentVoucherPage />);
                    break;
                case '/sport/konto/kontohistorik':
                    setContainerView(<TransactionHistory />);
                    break;
                case '/sport/konto/historik/aktive':
                    setContainerView(
                        <MyBets
                            active
                            key={'active'}
                            closeUserAreaPopup={() => {
                                props.hideModal('USER_MENU');
                            }}
                        />
                    );
                    break;
                case '/sport/konto/historik/afsluttede':
                    setContainerView(<MyBets key={'done'} />);
                    break;
                case '/sport/konto/historik/big9':
                    setContainerView(<Big9History key={'done'} />);
                    break;
                case '/sport/konto/historik/butik':
                    setContainerView(<RetailSalesHistory />);
                    break;

                case '/sport/konto/indbetalingsgraense':
                    setContainerView(<IndbetalingsgraensePage />);
                    break;
                case '/sport/konto/selvudelukkelse':
                    setContainerView(<SelfExclusionPage />);
                    break;
                case '/sport/konto/brugerinfo':
                    setContainerView(<BrugerinfoPage />);
                    break;
                case '/sport/konto/nyhedsbrev':
                    setContainerView(<NewsPage />);
                    break;
                default:
                    setContainerView(<NoMatchPage />);
            }
        },
        [activePage]
    );

    const onLogoutClick = () => {
        props.hideModal('USER_MENU');
        props.authActions.logout();
        browserHistory.push('/sport/');
    };

    const _goToPage = (link) => {
        setActivePage(link);
        browserHistory.push(link);
    };

    const onCloseModalHandler = () => {
        props.hideModal('USER_MENU');
        browserHistory.push('/sport/');
    };

    const getLastUserLoginDate = () => {
        let date = '';
        if (user) {
            user.extraInfo.lastLogin
                ? (date = user?.extraInfo?.lastLogin)
                : (date = new Date());
        }

        return `Sidste log ind: ${formatDate(date, 'DD. MMM [kl.] HH:mm')}`;
    };

    const isActivePage = (path) => (activePage === path ? 'active' : '');

    return (
        <section className="section-user-area">
            <ViewModal onCloseModal={onCloseModalHandler}>
                <div className="row wrapper">
                    <div className="col-2">
                        <nav className="navigation-wrapper">
                            <div className="user-info-top">
                                <div className="user-name">
                                    {user?.userName}
                                </div>
                                <div className="user-balance">
                                    {`${user?.balance} ${user?.currency}`}
                                </div>
                            </div>
                            <ul>
                                {menuConfig.map((el, index) => (
                                    <li
                                        key={index}
                                        style={el.style}
                                        className={isActivePage(el.url)}
                                        onClick={() => _goToPage(el.url)}
                                    >
                                        {el.title}
                                    </li>
                                ))}
                                <li onClick={onLogoutClick}>Log ud</li>
                            </ul>
                            <div className="user-info-bottom">
                                {getLastUserLoginDate()}

                                <div className="user-balance">
                                    {'Bruger-id: ' + user?.userId}
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div className="col-8">
                        <div className="section-user-area-container">
                            {containerView}
                        </div>
                    </div>
                </div>
            </ViewModal>
        </section>
    );
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionUserArea);
