import React, { Fragment } from 'react';
import ParentAuthPage from './ParentAuthPage';
import { ViewCheckbox } from 'components/ViewInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthActions from 'actions/AuthActions';
import * as UiActions from 'actions/UiActions';
import { UI_ERROR_MESSAGE, UI_INFO_MESSAGE } from 'constants/ui';
import $ from 'jquery';
import classNames from 'classnames';
import 'css/SelvudelukkelsePage.scss';
import UserService from 'utils/UserService';
import Button from 'ui/Button';
import { detectMobile, formatDate } from 'utils/UiHelper';
import Text from 'ui/Text';
import { browserHistory } from 'react-router';

class SelfExclusionPage extends ParentAuthPage {
    constructor() {
        super();

        this.selectOptions = [
            {
                value: 'ex_24h',
                label: '24 Timer',
                subLabel: 'Spilpause',
            },
            {
                value: 'ex_1m',
                label: '30 Dage',
                subLabel: 'Midlertidig Udelukkelse',
            },
            {
                value: '0',
                label: 'Permanent',
                subLabel: 'Ubegrænset Udelukkelse',
            },
        ];
        this.state = {
            ...this.state,
            isTemp: false,
            removeType: null,
            chb1: false,
            chb2: false,
            msg: false,
            excludedTill: null,
        };
    }

    componentDidMount() {
        this.setPageTitle('Selvudelukkelse');
        UserService.getUserStatus().then((res) => {
            let { data } = res;
            if (data && data.tm) {
                this.setState({ isTemp: true, excludedTill: data.tm });
            }
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.auth.user && this.props.auth.sessionError) {
            browserHistory.push('/sport/');
            this.props.uiActions.showModal('LOGIN_FIRST');
        }
    }

    onClickHandler() {
        if (
            (this.state.removeType &&
                this.state.removeType !== '0' &&
                this.state.chb1) ||
            (this.state.removeType === '0' &&
                this.state.chb1 &&
                this.state.chb2)
        ) {
            if (this.state.removeType === '0') {
                UserService.ExcludeUserPermanently().then((res) => {
                    if (res.success) {
                        this.props.uiActions.showModal('SELF_EXCLUSION_POPUP', {
                            type: 'permanent',
                        });

                        $.post(
                            'call.php',
                            {
                                rpc: 'permexclude',
                                userid: this.props.auth.user.userId,
                                username: this.props.auth.user.userName,
                            },
                            function(res) {
                                console.info(res);
                            }
                        );
                        this.props.authActions.logout();
                    }
                });
            } else {
                this.excludeUser();
            }
        } else {
            this.props.uiActions.notification(
                UI_ERROR_MESSAGE,
                'Vælg en type udelukke'
            );
        }
    }

    excludeUser() {
        UserService.ExcludeUserByPeriod(this.state.removeType).then((res) => {
            if (res.success) {
                let label = this.selectOptions.find(
                    (el) => el.value === this.state.removeType
                ).label;

                this.props.uiActions.showModal('SELF_EXCLUSION_POPUP');

                this.setState({
                    isTemp: true,
                });
                this.props.uiActions.hideModal('USER_MENU');

                browserHistory.push('/sport/');

                this.props.authActions.logout();
            } else {
                this.setErrorMessage('Something went wrong, try again later.');
            }
        });
    }

    changeHandler1(status) {
        this.setState({ chb1: status });
    }

    changeHandler2(status) {
        this.setState({ chb2: status });
    }

    selectHandler(key, value) {
        this.setState({ removeType: value });
    }

    render() {
        if (!this.state.isTemp) {
            return (
                <Fragment>
                    {this.getHeaderView(() => {
                        this.props.uiActions.showModal('USER_MENU');
                    })}
                    <div
                        className={classNames('selvudelukkelse-page', {
                            mobile: detectMobile(),
                        })}
                    >
                        <div className="header">Spær eller luk din konto</div>
                        <div className="info">
                            Du kan spærre din 25-Konto i en periode eller
                            permanent. Har du penge på din konto, vil pengene
                            blive på din 25-konto i perioden. Ønsker du pengene
                            udbetalt, skal du udbetale dit indestående, inden du
                            spærre din konto. Vælger du en endelig udelukkelse,
                            udbetaler vi automatisk dit indestående og
                            eventuelle gevinster fra igangværende spil, til din
                            NemKonto.
                            <p>
                                <a
                                    style={{
                                        color: '#1C3E60',
                                        textDecoration: 'underline',
                                    }}
                                    className="lnk"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://spillemyndigheden.dk/rofus"
                                >
                                    Yderligere oplysninger om det nationale
                                    register
                                </a>
                                .
                            </p>
                            {this.state.errorMessage
                                ? this.getErrorView()
                                : null}
                        </div>

                        <section className="select-container">
                            {this.selectOptions.map((option, index) => {
                                return (
                                    <Button
                                        padding="10px 25px"
                                        flexDirection="column"
                                        opacity={
                                            this.state.removeType ===
                                            option.value
                                                ? '0.7'
                                                : '1'
                                        }
                                        key={index}
                                        onClick={this.selectHandler.bind(
                                            this,
                                            'removeType',
                                            option.value
                                        )}
                                    >
                                        {option.label}
                                        <span
                                            style={{
                                                fontSize: '11px',
                                                fontWeight: 'lighter',
                                            }}
                                        >
                                            {option.subLabel}
                                        </span>
                                    </Button>
                                );
                            })}
                        </section>
                        <section className="accept-container">
                            <ViewCheckbox
                                changeHandler={this.changeHandler1.bind(
                                    this,
                                    !this.state.chb1
                                )}
                                checked={this.state.chb1}
                            >
                                Jeg er indforstået med at min spillekonto lukkes
                                i den valgte periode
                            </ViewCheckbox>
                            {this.state.removeType === '0' ? (
                                <ViewCheckbox
                                    className={!this.state.chb2 ? 'red' : ''}
                                    changeHandler={this.changeHandler2.bind(
                                        this,
                                        !this.state.chb2
                                    )}
                                    checked={this.state.chb2}
                                >
                                    Bekræft at du vil lukke din konto permanent.
                                    Din konto kan ikke genåbnes senere.
                                </ViewCheckbox>
                            ) : null}
                            {
                                <Button
                                    disabled={
                                        (this.state.removeType !== '0' &&
                                            !this.state.chb1) ||
                                        (this.state.removeType === '0' &&
                                            (!this.state.chb1 ||
                                                !this.state.chb2))
                                    }
                                    active={
                                        (this.state.removeType !== '0' &&
                                            this.state.chb1) ||
                                        (this.state.removeType === '0' &&
                                            this.state.chb1 &&
                                            this.state.chb2)
                                    }
                                    onClick={this.onClickHandler.bind(this)}
                                >
                                    Bekræft
                                </Button>
                            }
                        </section>
                    </div>
                </Fragment>
            );
        } else if (this.state.isTemp) {
            return (
                <Fragment>
                    {this.getHeaderView(() => {
                        this.props.uiActions.showModal('USER_MENU');
                    })}
                    <div
                        className={classNames('selvudelukkelse-page', {
                            mobile: detectMobile(),
                        })}
                    >
                        <div className="excluded-text-wrapper">
                            {this.state.excludedTill ? (
                                <Text size="x3" bold>
                                    Din konto er nu lukket til:{' '}
                                    {formatDate(
                                        this.state.excludedTill,
                                        'DD.MM.YY [kl] HH:mm'
                                    )}
                                </Text>
                            ) : null}

                            <Text>
                                Du har valgt at selvudelukke dig fra din konto.
                                Skulle du have spørgsmål, kan du kontakte vores
                                support. Har du spilleproblemer, eller er i
                                tvivl om du har spilleproblemer så kan du
                                henvende dig til <b>Center for ludomani</b> på
                                telefon: <a href="tel:70111810">70 11 18 10</a>{' '}
                                eller e-mail på{' '}
                                <a href="mailto:info@ludomani.dk">
                                    info@ludomani.dk
                                </a>
                                . Du kan læse mere på om ludomani på{' '}
                                <a
                                    href="https://ludomani.dk/"
                                    target={'_blank'}
                                >
                                    www.ludomani.dk
                                </a>{' '}
                                hvor du også kan teste dig selv for ludomani. Du
                                kan også besøge Center for Ludomani på følge
                                adresser:
                            </Text>

                            <ul>
                                <li>
                                    <Text>
                                        Center for ludomani i København
                                        Fiolstræde 17B st tv. 1171 København
                                    </Text>
                                </li>
                                <li>
                                    <Text>
                                        Center for ludomani i Odense Østergade
                                        42 5000 Odense
                                    </Text>
                                </li>
                                <li>
                                    <Text>
                                        Center for ludomani i Århus Gammel
                                        Munkegade 6D 8000 Århus C
                                    </Text>
                                </li>
                            </ul>

                            <Text>
                                Du kan ligeledes henvende dig til Frederiksberg
                                Centeret på telefon:{' '}
                                <a href="tel:33217300">33 21 73 00</a> eller
                                e-mail på{' '}
                                <a href="mailto:info@frederiksberg-centeret.dk">
                                    info@frederiksberg-centeret.dk
                                </a>{' '}
                                eller læse mere på{' '}
                                <a
                                    href="http://www.frederiksberg-centeret.dk/ludomani/"
                                    target={'_blank'}
                                >
                                    www.frederiksberg-centeret.dk/ludomani/
                                </a>
                            </Text>

                            <ul>
                                <li>
                                    <Text>
                                        Frederiksberg Centeret - København
                                        Afdeling Esplanaden 8C, 2. sal 1263
                                        København K.
                                    </Text>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
}

function mapStateToProps(state) {
    return { auth: state.auth };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelfExclusionPage);
