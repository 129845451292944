import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UiActions from 'actions/UiActions';
import * as AuthActions from 'actions/AuthActions';
import { browserHistory } from 'react-router';
import 'css/AppToolbar.scss';
import * as CampaignActions from 'actions/CampaignActions';
import { TopBar } from '@it25syv/25syv-ui';
import { desktopOnly } from 'utils/UiHelper';

const stayOnPageLinks = ['/big9/spil'];
class DesktopAppToolbar extends Component {
    componentDidMount() {
        if (!this.props.campaigns.fetched && !this.props.campaigns.pending) {
            this.props.campaignActions.getCampaigns();
        }
    }

    checkGeoblock() {
        return (
            this.props.serverConfig.geoFetched &&
            !this.props.serverConfig.geoData.allowed
        );
    }

    loginModalOpen() {
        if (this.checkGeoblock()) {
            this.props.uiActions.showModal('GEO_BLOCK');
        } else {
            this.props.uiActions.showModal('LOGIN', { stayOnPage: true });
            const currentLocation = browserHistory.getCurrentLocation()
                ?.pathname;
            if (stayOnPageLinks.includes(currentLocation)) {
                localStorage.setItem(
                    'stay_on_prev_page',
                    window.location.pathname
                );
            } else {
                localStorage.removeItem('stay_on_prev_page');
            }
            browserHistory.push('/sport/log-ind');
            // browserHistory.push('/login');
        }
    }

    signupModalOpen() {
        if (this.checkGeoblock()) {
            this.props.uiActions.showModal('GEO_BLOCK');
        } else {
            browserHistory.push('/sport/opret-konto');
            /*if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'bet25.clickToSignup',
                    userId: this.props.auth?.user?.userId,
                });
            }*/
        }
    }

    onUserBtnsClick() {
        if (this.props.onUserBtnsClick) {
            this.props.onUserBtnsClick();
        }
    }

    goToDeposit() {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'bet25.clickToPaymentSystem',
                userId: this.props.auth?.user?.userId,
            });
        }

        window.history.pushState('', '', '/sport/konto/indbetaling');
        this.props.uiActions.showModal('USER_MENU');
    }

    isFullWidth() {
        return (
            window.location.pathname === '/log-ind' ||
            window.location.pathname === '/sport/log-ind' ||
            window.location.pathname === '/opret-konto' ||
            window.location.pathname === '/sport/opret-konto'
        );
    }
    isPageWithoutSidebar() {
        const pages = ['/log-ind', '/sport/log-ind'];
        return pages.includes(window.location.pathname);
    }

    render() {
        return (
            <header
                ref={this.props.headerRef}
                style={this.props.style}
                className={`view-top-toolbar desktop ${
                    this.props.isLeftSideBarOpen && desktopOnly()
                        ? ''
                        : 'closed-sidebar'
                } ${this.isFullWidth() ? 'full-width' : ''}`}
            >
                <TopBar
                    withoutSidebar={this.isPageWithoutSidebar()}
                    background={'#1C3E60'}
                    onBurgerMenuClickHandler={this.props.onBurgerClickHandler}
                    sideNavHandler={this.props.onBurgerClickHandler}
                    sideNavOpen={this.props.isLeftSideBarOpen}
                    userEmail={
                        this.props.auth.user ? this.props.auth.user.email : ''
                    }
                    username={
                        this.props.auth.user
                            ? this.props.auth.user.userName
                            : ''
                    }
                    balance={
                        this.props.auth.user ? this.props.auth.user.balance : ''
                    }
                    secondColor={'#20486F'}
                    bankClickHandler={this.goToDeposit.bind(this)}
                    showUserArea={this.onUserBtnsClick.bind(this)}
                    signUpHandler={this.signupModalOpen.bind(this)}
                    showLoginModal={this.loginModalOpen.bind(this)}
                />
            </header>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        serverConfig: state.serverConfig,
        campaigns: state.campaigns,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
        authActions: bindActionCreators(AuthActions, dispatch),
        campaignActions: bindActionCreators(CampaignActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DesktopAppToolbar);
