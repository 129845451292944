export default [
    'fodbold',
    'basketball',
    'baseball',
    'ishockey',
    'tennis',
    'haandbold',
    'floorball',
    'golf',
    'motorsport',
    'rugby',
    'australsk-fodbold',
    'vintersport',
    'bandy',
    'amerikansk-fodbold',
    'cykling',
    'snooker',
    'bordtennis',
    'cricket',
    'dart',
    'volleyball',
    'beach-volleyball',
    'hockey',
    'vandpolo',
    'curling',
    'futsal',
    'olympiade',
    'badminton',
    'bowls',
    'pesapallo',
    'underholdning',
    'atletik',
    'moms',
    'boksning',
    'mma',
    'e-sport',
    'svomning',
    'fodboldgolf',
    'boxing',
    'motor-sport',
    'specials',
    'esport-counterstrike',
    'esport-dota',
    'andet-sport',
    'esport-league-of-legends',
    'biathlon',
    'alpine-skiing',
    'skiskydning',
    'langrend',
    'hestesport',
    'hestevaeddelob',
    'ski-jumping',
    'esport-starcraft',
    'alpint',
    'vm-boost',
    'vm-specials',
    'klaphat-odds',
    'esport-fifa',
    'esport-rocket-league',
    'esport-tekken',
    'pool',
    'esport-call-of-duty',
    'squash',
    'esport-rainbow-six',
    'formula-e',
    'esport-overwatch',
    'esports-pubg',
    'esport-heartstone',
    'esport-street-fighter-v',
    'esport-arena-ofvalor',
    'esport-wow',
    'sejlads',
    'boboll',
    'esport-fortnite',
    'esport-nba2k',
    'fyens-vaeddelobsbane',
    'esport-motorsport',
    'skihop',
    'beach-soccer',
    'trav',
    'olympics',
    'galop',
    'esport-king-of-glory',
    'greyhounds',
    'chess',
    'e-soccer',
    'ol-2020',
    'softball',
    'roning',
    'triatlon',
    'bueskydning',
    'ridning',
    'faegtning',
    'vaegtloftning',
    'speedway',
    'basketball-3x3',
    'skateboarding',
    'motorcycle-racing',
    'vinter-ol-2022',
    'padel',
];
