import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
    detectMobile,
    disableBodyScroll,
    enableBodyScroll,
    isBodyScrollDisabled,
} from 'utils/UiHelper';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ModalBody = styled.div`
    height: auto;
    width: 80%;
    max-width: ${({ maxWidth }) => maxWidth || '400px'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff;
    color: #333;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 10px;
    transform: translateY(-50%);
    ${({ noPadding }) =>
        !noPadding && `padding: ${detectMobile() ? '25px' : '40px'}`};
    box-sizing: border-box;
    overflow-x: hidden;
    z-index: 12;

    @media screen and (max-width: 370px) {
        padding: 20px;
    }
`;
const CloseBtn = styled.div`
    width: 27px;
    height: 27px;
    border-radius: 5px;
    color: #ffffff;
    background: #c1c1c1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        background: #585858;
    }
`;
const Title = styled.h3`
    font-size: 24px;
    margin: 0;
    font-family: 'Roboto Condensed', sans-serif;
    padding-left: 10px;
    text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

const UIModalNew = ({
    onCloseModal,
    closeBtn,
    title,
    noPadding = false,
    children,
    maxWidth = '',
}) => {
    const [
        isBodyScrollAlreadyDisabled,
        setIsBodyScrollAlreadyDisabled,
    ] = useState();

    useEffect(() => {
        setIsBodyScrollAlreadyDisabled(isBodyScrollDisabled());
        if (!isBodyScrollAlreadyDisabled) {
            disableBodyScroll();
        }

        return () => {
            if (!isBodyScrollAlreadyDisabled) {
                enableBodyScroll();
            }
        };
    }, []);

    const onCloseBtn = () => {
        if (onCloseModal) {
            onCloseModal();
        }
    };

    return (
        <Wrapper>
            <ModalBody noPadding={noPadding} maxWidth={maxWidth}>
                {closeBtn && (
                    <CloseBtn onClick={onCloseBtn}>
                        <i className="fa fa-times" aria-hidden="true" />
                    </CloseBtn>
                )}
                {title && <Title>{title}</Title>}
                {children}
            </ModalBody>
        </Wrapper>
    );
};

export default UIModalNew;
