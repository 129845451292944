import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { hideModal, showModal } from 'actions/UiActions';
import { connect } from 'react-redux';
import { NotificationContainer } from 'react-notifications';

class ModalsHandler extends React.Component {
    constructor(props) {
        super(props);

        this.el = document.createElement('div');
    }

    componentDidMount() {
        const rootElement = this.props.parent;
        setTimeout(() => {
            rootElement.current.appendChild(this.el);
        }, 500);
    }

    render() {
        const { modalsMap, showModal, hideModal, modals } = this.props;

        return ReactDOM.createPortal(
            <Fragment>
                {Object.values(modals).map((modalState) => {
                    return this.props.children({
                        ModalComponent: modalsMap[modalState.id],
                        actions: {
                            showModal,
                            hideModal,
                        },
                        modalId: modalState.id,
                        modalData: modalState.data,
                        key: modalState.id,
                        id: modalState.id,
                        popupMode: this.props.popupMode,
                        cameFrom: modalState.cameFrom,
                    });
                })}

                <NotificationContainer />
            </Fragment>,
            this.el
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modals: state.ui.modals,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (modalId, data = null, duration = 0) => {
            dispatch(showModal(modalId, data, duration));
        },
        hideModal: (modalId, meta) => {
            dispatch(hideModal(modalId, meta));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalsHandler);
