import React from 'react';
import {
    BetsLeft,
    BetsRight,
    EventNameWrapper,
    ResultsWrapper,
    SlipItem,
    Divider,
    WildCardResult,
    ResultsItem,
} from 'features/Betslip/styled';
import Text from 'ui/Text';
import { BIG9_EVENT_STATUS } from 'constants/big9Config';
import { isTerminal } from '../../utils/UiHelper';

const TableContent = ({
    item,
    outcomes,
    short = false,
    isHistoryOnTerminal,
}) => {
    const finishedCoupon = outcomes[item.nr]?.results?.length;
    const canceled =
        item.status === BIG9_EVENT_STATUS.canceled ||
        outcomes[item.nr]?.results?.includes('cancelled');

    const selectionView = (key, type) =>
        outcomes[key]?.marks?.includes(type) ? type : '-';

    const selectNewColor = (result, type, selection) => {
        return result === type && type === selection
            ? '#18AA1B'
            : result === type
                ? '#F60E0F'
                : 'transparent';
    };

    const BetSelections = ({ selections = [], res = '' }) => {
        return selections.map((selection, index) => {
            return (
                <div key={index}>
                    <ResultsItem
                        color={
                            res &&
                            selectNewColor(
                                res,
                                selection,
                                selectionView(item.nr, selection)
                            )
                        }
                        setted={res && res === selection}
                    >
                        <Text
                            condensed
                            size={
                                isTerminal() && !isHistoryOnTerminal
                                    ? '20'
                                    : 'x1'
                            }
                            color="#333333"
                            padding="4px"
                            align="center"
                            width="8px"
                        >
                            {selectionView(item.nr, selection)}
                        </Text>
                    </ResultsItem>
                </div>
            );
        });
    };

    return (
        <SlipItem>
            <EventNameWrapper
                canceled={canceled}
                short={short}
                terminal={isTerminal()}
                className={
                    isTerminal() && isHistoryOnTerminal
                        ? 'terminalHistory'
                        : null
                }
            >
                <Text
                    bold
                    condensed
                    size={isTerminal() && !isHistoryOnTerminal ? '20' : 'x07'}
                    color="#333333"
                    padding="0"
                >
                    {item.nr}
                </Text>
                <Text
                    capitalize
                    condensed
                    size={isTerminal() && !isHistoryOnTerminal ? '20' : 'x1'}
                    color="#333333"
                    padding="0"
                >
                    {item.name}
                </Text>
            </EventNameWrapper>
            <ResultsWrapper>
                {canceled ? (
                    <WildCardResult>
                        <Text
                            condensed
                            padding="0"
                            color={'#9198AA'}
                            bold={isTerminal()}
                            size={isTerminal() ? '20' : '14'}
                            margin={'0 6px 0 0'}
                        >
                            Aflyst kamp
                        </Text>
                    </WildCardResult>
                ) : outcomes[item.nr]?.marks?.includes('*') ? (
                    <WildCardResult result={finishedCoupon}>
                        <Text
                            bold
                            condensed
                            size={
                                isTerminal() && !isHistoryOnTerminal
                                    ? '20'
                                    : 'x1'
                            }
                            color={finishedCoupon ? '#fff' : '#333333'}
                            padding={finishedCoupon ? '5px 33px' : '0'}
                            margin={finishedCoupon ? '0' : '0 16px 0 0'}
                        >
                            Wildcard
                        </Text>
                    </WildCardResult>
                ) : (
                    <>
                        <BetsLeft center={finishedCoupon}>
                            {finishedCoupon ? (
                                outcomes[item.nr]?.results.map((res, index) => {
                                    if (
                                        res === '1' ||
                                        res === 'X' ||
                                        res === '2'
                                    ) {
                                        return (
                                            <BetSelections
                                                key={index}
                                                selections={['1', 'X', '2']}
                                                res={res}
                                            />
                                        );
                                    }
                                })
                            ) : (
                                <BetSelections selections={['1', 'X', '2']} />
                            )}
                        </BetsLeft>
                        <Divider />
                        <BetsRight center={finishedCoupon}>
                            {finishedCoupon ? (
                                outcomes[item.nr]?.results.map((res, index) => {
                                    if (res === 'U' || res === 'O') {
                                        return (
                                            <BetSelections
                                                key={index}
                                                selections={['O', 'U']}
                                                res={res}
                                            />
                                        );
                                    }
                                })
                            ) : (
                                <BetSelections selections={['O', 'U']} />
                            )}
                        </BetsRight>
                    </>
                )}
            </ResultsWrapper>
        </SlipItem>
    );
};

export default TableContent;
