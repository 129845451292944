import styled from 'styled-components';
import Text from 'ui/Text';

export const CardWrapper = styled.div`
    display: block;
    order: ${({ order }) => order || 2};
    background: #edf1f8;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 0 15px 38px;
    margin: ${({ margin }) => (margin ? margin : '20px auto')};
    ${({ done, leftBorderColor }) =>
        done ? 'border-left: 4px solid ' + leftBorderColor : null};
    &.terminal {
        width: 400px;
        margin: 0 auto;
        border: none !important;
    }
`;
export const BetCardItemWrapper = styled.div`
    width: 100%;
    border-bottom: 1px solid #d6e1ea;
    position: relative;
    box-sizing: border-box;
    padding: 10px 20px;
`;

export const CanceledLabel = styled.span`
    width: auto;
    box-sizing: border-box;
    display: inline-block;
    padding: 3px 10px;
    margin-left: 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    background: #aab6cc;
`;
export const StatusCircle = styled.div`
    position: absolute;
    left: 0;
    top: 12px;
    height: 14px;
    width: 14px;
    border-radius: 14px;
    color: white;
    text-align: center;
    line-height: 15px;
    font-size: 11px;
    background: ${({ status }) =>
        status === 'won'
            ? '#18AA1B'
            : status === 'lost'
                ? '#E24E4E'
                : '#B8C3D6'};
    border-bottom: 1px solid #e3e8f1;
`;
export const BetOpenButton = styled.div`
    position: absolute;
    color: #b8c3d6;
    right: 0;
    top: 50%;
    font-weight: bold;
    font-size: 16px;
    transform: translateY(-50%);
`;
export const ItemsRow = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    justify-content: ${({ justify }) => (justify ? justify : 'space-between')};
`;
export const Subtext = styled.span`
    font-weight: normal;
    position: absolute;
    ${({ top }) => (top ? `top: ${top}` : `top: 0`)};
    ${({ right }) => (right ? 'right: ' + right : null)};
    color: #8a8c8e;
    font-size: 12px;
`;

export const StyledEventName = styled(Text)`
    max-width: 65%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 10px;
`;

export const AsianBadge = styled.span`
    padding: 2px 5px;
    font-size: 10px;
    font-weight: bold;
    display: inline-block;
    color: white;
    vertical-align: middle;
    border-radius: 2px;
    background: ${({ background }) => (background ? background : '#B8C3D6')};
    margin-left: 5px;
    margin-bottom: 2px;
`;
