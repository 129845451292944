import React, { useState, useEffect, useRef } from 'react';
import noop from 'lodash/noop';
import Button from '../../ui/Button';
import { NotificationManager } from 'react-notifications';
import Spinner from 'ui/Spinner';

import {
    NavigationBar,
    Title,
    PreviewButtons,
    PhysicalCardFormWrapper,
    FormModalWrapper,
} from 'features/SpilID/styled';

import Input, { AddressSelect } from 'features/SpilID/Input';
import Wrapper from 'ui/Wrapper';
import useOrderPhysicalCard from 'features/SpilID/useOrderPhysicalCard';
import SuccessPopup from 'features/SpilID/SuccessPopup';
import {
    hideModal as HideModal,
    showModal as ShowModal,
} from '../../actions/UiActions';
import { connect } from 'react-redux';
import {
    detectMobile,
    disableBodyScroll,
    enableBodyScroll,
} from 'utils/UiHelper';
import withPopupWrapper from 'features/SpilID/withPopupWrapper';

const ZIP_CODE_REGEX = /\d{4,5}/;

const FULL_NAME_REGEX = /^[a-zA-Z æøåöÆØÅÖ-]+$/;

const FULL_NAME_MESSAGE = 'Feltet må kun indeholde bogstaver';

const ADDRESS_REQUIRED_MESSAGE = 'Begynd at skrive og vælg en adresse';

const inputExtra = { width: '100%', height: '55px' };

const PhysicalCardForm = ({ showModal, hideModal, modalData }) => {
    const {
        fullName: prefilledFullName,
        address: prefilledAddress,
        zipCode: prefilledZipCode,
        cardId,
        birthdate,
        city: prefilledCity,
    } = modalData;

    const [fullName, setFullName] = useState(prefilledFullName);
    const [address, setAddress] = useState(prefilledAddress);
    const [zipCode, setZipCode] = useState(prefilledZipCode);
    const [city, setCity] = useState(prefilledCity);

    const { requestCardOrder, pending, error, success } = useOrderPhysicalCard({
        cardId,
        address,
        fullName,
        zipCode,
        birthdate,
        city,
    });

    const fullNameRef = useRef(null);
    const addressRef = useRef(null);

    const enableEditingFullName = () => {
        setFullName('');
        fullNameRef.current.focus();
    };

    const enableEditingAddress = () => {
        setAddress('');
        addressRef.current.focus();
    };

    const backToSpilIdRoot = () => {
        hideModal('SPIL_ID_PC_PREVIEW');
        hideModal('SPIL_ID_PC_FORM');
    };

    useEffect(() => {
        disableBodyScroll();
    }, []);

    useEffect(
        () => {
            const zipCodeMatch = address.match(ZIP_CODE_REGEX);

            if (zipCodeMatch) {
                setZipCode(zipCodeMatch[0]);
            } else {
                setZipCode('');
            }
        },
        [address]
    );

    useEffect(
        () => {
            if (error) {
                NotificationManager.error(error);
                backToSpilIdRoot();
            }
        },
        [error]
    );

    const fullNameError = FULL_NAME_REGEX.test(fullName)
        ? null
        : FULL_NAME_MESSAGE;

    const addressError = zipCode ? null : ADDRESS_REQUIRED_MESSAGE;

    const formValid = !fullNameError && !addressError;

    return (
        <FormModalWrapper>
            <SuccessPopup shown={success} backToSpilIdRoot={backToSpilIdRoot} />

            <NavigationBar
                title={'Bestil Spil ID kort'}
                clickHandler={() => {
                    hideModal('SPIL_ID_PC_FORM');
                    enableBodyScroll();
                }}
                show={() => showModal('SPIL_ID_DESCRIPTION_MODAL')}
            />

            <Wrapper padding="30px 22px" style={{ flexGrow: 1 }}>
                <Title>
                    Bekræft nedenstående <br /> information.
                </Title>

                <PhysicalCardFormWrapper>
                    <Input
                        value={fullName}
                        inputRef={fullNameRef}
                        onChange={(fullName) => setFullName(fullName)}
                        onEdit={() => enableEditingFullName()}
                        label={'Fulde navn'}
                        placeholder={'Fulde navn'}
                        name="fullName"
                        style={inputExtra}
                        error={fullNameError}
                    />

                    <AddressSelect
                        value={address}
                        inputRef={addressRef}
                        onChange={(address) => setAddress(address)}
                        onEdit={() => enableEditingAddress()}
                        label={'Adresse'}
                        placeholder={'Adresse'}
                        name="address"
                        style={inputExtra}
                        setZipCode={setZipCode}
                        error={addressError}
                        setCity={setCity}
                    />
                </PhysicalCardFormWrapper>
            </Wrapper>

            <PreviewButtons padding={'0 22px 22px'}>
                <Button
                    onClick={() => {
                        hideModal('SPIL_ID_PC_FORM');
                        enableBodyScroll();
                    }}
                    fontSize="16px"
                    grey
                    fontWeight={'normal'}
                >
                    Annuller
                </Button>
                <Button
                    fontSize="16px"
                    disabled={!formValid}
                    onClick={formValid ? requestCardOrder : noop}
                    active
                    bold
                >
                    {pending ? <Spinner black size="x07" /> : 'Bekræft'}
                </Button>
            </PreviewButtons>
        </FormModalWrapper>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (modalId, data) => dispatch(ShowModal(modalId, data)),
        hideModal: (modalId) => dispatch(HideModal(modalId)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(detectMobile() ? PhysicalCardForm : withPopupWrapper(PhysicalCardForm));
