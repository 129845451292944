import React, { Component } from 'react';
import Button from 'ui/Button';

class PlayAndPlay extends Component {
    performSale = () => {
        this.props.onClick();
    };

    render() {
        return (
            <Button margin="10px 0" width="100%" onClick={this.performSale}>
                Print kupon
            </Button>
        );
    }
}

export default PlayAndPlay;
