import axios from 'axios';
import { isTerminal } from './UiHelper';

const jsonSerializer = (data) => JSON.stringify(data);
const formSerializer = (data) => {
    const p = Object.keys(data).map(
        (key) => `${key}=${decodeURIComponent(data[key].toString())}`
    );
    return p.join('&');
};

const dataSerializers = {
    'application/json': jsonSerializer,
    'application/x-www-form-urlencoded': formSerializer,
    'multipart/form-data': jsonSerializer,
    'multipart/form-data; boundary=----DerbyRequestBoundary----': jsonSerializer,
};

const makeRequest = (
    requestMethod,
    url,
    data = {},
    fetchParams = {},
    headerParams = {},
    vaix = false
) => {
    let cache = {};
    let creds = {};
    if (!isTerminal()) {
        cache = { Cache: 'no-cache' };
        if (!vaix) {
            creds = { credentials: 'include' };
        }
    }
    const params = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            // Cache: 'no-cache',
            ...cache,
            ...headerParams,
        },
        ...creds,
        // credentials: 'include',
        method: requestMethod,
        ...fetchParams,
    };

    if (
        requestMethod.toLowerCase() === 'post' ||
        requestMethod.toLowerCase() === 'put'
    ) {
        params['body'] = dataSerializers[params.headers['Content-Type']](data);
    } else if (requestMethod.toLowerCase() === 'get' && data) {
        const p = Object.keys(data).map(
            (key) => `${key}=${decodeURIComponent(data[key])}`
        );
        url = url + (p && p.length ? '?' + p.join('&') : '');
    }

    return fetch(url, params);
};

const makeJSONRequest = (...params) =>
    makeRequest(...params).then((response) => response.json());

const axiosRequest = (requestMethod, url, data = {}, headerParams = false) => {
    return !headerParams
        ? axios[requestMethod](url, data)
        : axios[requestMethod](url, data, headerParams);
};

export { makeRequest, axiosRequest, makeJSONRequest };
