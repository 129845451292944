import React from 'react';
import UIModalNew from 'components/UIModalNew';
import styled from 'styled-components';
import Button from 'ui/Button';
import Text from 'ui/Text';
import { browserHistory } from 'react-router';
import { detectMobile } from 'utils/UiHelper';
import { ALREADY_EXIST_MESSAGES } from '../constants/messages/alreadyExistMessages';

const Description = styled(Text)`
    margin: 19px 0;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
`;

const EmailAlreadyExistPopup = ({ hideModal, showModal, modalData }) => {
    const onLoginClick = () => {
        if (detectMobile()) {
            hideModal('EMAIL_EXIST');
            showModal('LOGIN_MOBILE');
        } else {
            hideModal('EMAIL_EXIST');
            browserHistory.push('/sport/log-ind');
        }
    };

    return (
        <UIModalNew
            title="Velkommen tilbage!"
            closeBtn
            onCloseModal={() => {
                hideModal('EMAIL_EXIST');
            }}
        >
            <Description>
                {ALREADY_EXIST_MESSAGES[modalData.message]}
            </Description>

            <ButtonsWrapper>
                <Button
                    margin="0"
                    width="45%"
                    grey
                    onClick={() => {
                        hideModal();
                        window.history.pushState({}, '', '/sport/');
                        window.Intercom('show');
                    }}
                >
                    Kontakt os
                </Button>

                <Button margin="0" width="45%" active onClick={onLoginClick}>
                    Log ind
                </Button>
            </ButtonsWrapper>
        </UIModalNew>
    );
};

export default EmailAlreadyExistPopup;
