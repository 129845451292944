import { Link } from 'react-router';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: scale(0);
        position:absolute;

    }
    100% {
        opacity: 1;
        transform: scale(1);
        position:relative;

    }
`;
const fadeOut = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0);
        position:absolute;

    }
`;

export const SideBarWrapper = styled.div`
    color: white;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-content: start;
    padding: 0 0 100px;
    transition: all 0.1s ease;
    height: 100%;
    scrollbar-width: thin;
    overflow: hidden;

    &:hover {
        -webkit-overflow-scrolling: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &::-webkit-scrollbar {
        width: 2px;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
    }

    ${({ isOpen }) =>
        isOpen
            ? ''
            : `a {
            width: 46px;
            height: 46px;
        }`}
    }

    .closed {
        animation: ${fadeOut} 0.1s linear;
        animation-fill-mode: forwards;
    }
    .open {
        animation: ${fadeIn} 0.1s linear;
        animation-fill-mode: forwards;
    }
    .tooltip {
      opacity: 1!important;
      font-size: 14px;
      font-weight: bold;
      color: #505050;
      
    }
`;
export const SideBarContent = styled.div`
    padding: 10px 9px 120px;
`;
export const StyledLink = styled.a`
    height: ${({ height }) => (height ? height : '30px')};
    width: 236px;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.1s linear;
    position: relative;
    &:hover {
        background-color: #20486f;
        svg {
            background: white !important;
        }
    }
    .fa-chevron-right {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 9px;
        opacity: 0;
        transition: all 0.3s ease;
    }
    &:hover .fa-chevron-right {
        opacity: 0.2;
    }
    &:active {
        text-decoration: none;
    }
    svg {
        vertical-align: middle;
        margin-right: 20px;
        display: inline-block;
    }
    span {
        display: inline-block;
        font-size: 14px;
        text-decoration: none;
    }
    &:before {
        content: ${({ activelink }) => (activelink === 'true' ? "' '" : '')};
        position: absolute;
        left: -9px;
        top: 0;
        height: ${({ height }) => (height ? height : '30px')};
        width: 3px;
        background: #fbd600;
        border-radius: 0 3px 3px 0;
    }
    &:after {
        position: absolute;
        margin-top: 5px;
        display: block;
        left: -7px;
        bottom: -5px;
        content: ${({ delimiter }) => (delimiter ? `' '` : '')};
        height: 1px;
        background-color: rgba(255, 255, 255, 0.15);
        width: 250px;
    }
`;
export const MenuItem = styled(Link)`
    height: ${({ height }) => (height ? height : '30px')};
    width: 236px;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 5px 15px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.1s linear;
    position: relative;
    color: white;
    overflow: hidden;
    .fa-chevron-right {
        /*animation: ${fadeIn} 0.1s linear;*/
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 9px;

        transition: all 0.3s ease;
        display: none;
    }
    &:hover {
        background-color: ${({ activelink }) =>
            activelink === 'true' ? '#183451' : '#20486F'};
        svg {
            background: white !important;
        }
        span {
            ${({ hoveranimation }) =>
                hoveranimation ? 'margin-left: 10px;' : null};
        }
        .fa-chevron-right {
            opacity: 0.2;
            display: block;
        }
    }
    &:active {
        text-decoration: none;
    }
    span {
        display: inline-block;
        color: white;
        font-size: 14px;
        text-decoration: none;
        transition: all 0.3s ease;
    }

    &:before {
        content: ${({ activelink }) => (activelink === 'true' ? "' '" : '')};
        position: absolute;
        left: -9px;
        top: 0;
        height: ${({ height }) => (height ? height : '30px')};
        width: 3px;
        background: #fbd600;
        border-radius: 0 3px 3px 0;
    }
    &:after {
        margin-top: 5px;
        position: absolute;
        display: block;
        left: -7px;
        bottom: -5px;
        content: ${({ delimiter }) => (delimiter ? `' '` : '')};
        height: 1px;
        background-color: #2b4b6b;
        width: 250px;
    }
    .isNewItem {
        margin-left: 5px;
        padding: 2px 5px;
        text-transform: uppercase;
        text-align: center;
        border-radius: 2px;
        font-size: 10px;
        line-height: 10px;
        color: #333;
        display: inline-block;
        background: #fed302;
        &.sm {
            width: auto;
            display: inline-block;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 2;
        }
    }
`;
