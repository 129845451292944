import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import Layout from './Layout';
import BetslipNotification from '../containers/BetslipNotification';
import '../css/App.css';
import * as UiActions from '../actions/UiActions';
import * as AuthActions from '../actions/AuthActions';
import * as LiveEventsActions from '../actions/LiveEventsActions';
import * as PreEventsActions from '../actions/PreEventsActions';
import * as PopularEventsActions from '../actions/PopularEventsActions';
import * as BetslipActions from '../actions/BetslipActions';
import * as EventObjectActions from '../actions/EventObjectActions';
import * as CampaignActions from '../actions/CampaignActions';
import * as ServerConfigActions from '../actions/ServerConfigActions';
import {
    UI_ERROR_MESSAGE,
    UI_SUCCESS_MESSAGE,
    UI_WARNING_MESSAGE,
} from 'constants/ui';
import { isAndroid } from 'react-device-detect';
import { CookiesProvider } from 'react-cookie';
import RestService from '../utils/RestService';
import MetaTags from 'react-meta-tags';
import Firebase from '../features/Firebase';
import {
    FETCH_DATA_INTERVAL_MS,
    LOCAL_STORAGE_KEYS,
} from 'constants/appConfig';
import { isMTSStakeError } from 'utils/Helper';
import 'css/HomePageContainer/HomePageContainer.scss';
import NotificationMessage from '../components/NotificationMessage/NotificationMessage';
import { formatDate } from '../utils/UiHelper';

class App extends Component {
    notifications = {};

    constructor(props) {
        super(props);
        this.routeListWithPreEvents = ['/', '/pre'];

        window.locationHistory = [];
        this.state = {
            deferredPrompt: null,
        };
    }

    isBetListContainPreEvents() {
        const { preEvents, betslip } = this.props;
        return preEvents.data.some((event) =>
            betslip.betList.find((bet) => bet.eventId === event.eventId)
        );
    }

    intervalFetchData = () => {
        window.clearTimeout(this.fetchDataTimerlId);
        this.props.authActions.checkUserLoggedStatus();
        if (
            this.isBetListContainPreEvents() ||
            this.routeListWithPreEvents.includes(this.props.location.pathname)
        ) {
            this.fetchPreEvents();
        }
        if (this.props.location.pathname === '/') {
            this.fetchPopularEvents();
        }
        this.fetchDataTimerlId = window.setTimeout(
            this.intervalFetchData,
            FETCH_DATA_INTERVAL_MS
        );
    };

    fetchPreEvents = () => {
        this.props.preEventsActions.getTodayEvents(this.props.sportData);
    };

    fetchPopularEvents = () => {
        this.props.popularEventsActions.getPopularEvents(
            true,
            this.props.auth?.user?.userId
        );
    };

    componentWillUnmount() {
        window.clearTimeout(this.fetchDataTimerlId);
    }

    onCloseSignupModal() {
        this.props.authActions.reset();
        this.props.uiActions.setSignupModalStatus(false);
        this.props.uiActions.showModal('FEEDBACK_MODAL');
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            window.locationHistory.unshift(this.props.location.pathname);

            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'page_view',
                    type: 'sport',
                    eventUrl: nextProps.location.pathname, //variable for URL
                    userId: this.props.auth?.user?.userId || 0,
                });
            }
        }

        if (!this.props.betslip.success && nextProps.betslip.success) {
            this.props.authActions.updateBalance();
        } else if (nextProps.betslip.error) {
            setTimeout(() => {
                this.notifications['betslip'] = {
                    error: false,
                };

                this.props.betslipActions.clearError();
            }, 4000);

            if (
                !this.notifications.betslip ||
                !this.notifications.betslip.error
            ) {
                if (
                    !isMTSStakeError(nextProps.betslip.error) &&
                    nextProps.betslip.error !== 'Saldoen er for lav'
                ) {
                    NotificationManager.error(
                        '',
                        nextProps.betslip.error,
                        4000
                    );
                }
                this.notifications['betslip'] = {
                    error: true,
                };
            }
        } else if (nextProps.betslip.addError) {
            this.props.betslipActions.clearError();
            NotificationManager.error('', nextProps.betslip.addError, 4000);
        } else if (nextProps.auth.loginError) {
            NotificationManager.error('', nextProps.auth.loginError, 4000);
            this.props.authActions.reset();
        } else if (nextProps.ui.messageText) {
            switch (nextProps.ui.messageType) {
                case UI_SUCCESS_MESSAGE:
                    NotificationManager.success(
                        '',
                        nextProps.ui.messageText,
                        4000
                    );
                    break;
                case UI_WARNING_MESSAGE:
                    NotificationManager.warning(
                        '',
                        nextProps.ui.messageText,
                        4000
                    );
                    break;
                case UI_ERROR_MESSAGE:
                    NotificationManager.error(
                        '',
                        nextProps.ui.messageText,
                        4000
                    );
                    break;
                default:
                    NotificationManager.info(
                        '',
                        nextProps.ui.messageText,
                        4000
                    );
            }

            this.props.uiActions.clearNotification();
        }

        if (
            this.props.location.pathname !== nextProps.location.pathname &&
            nextProps.location.pathname.indexOf('/kampagner/') === -1
        ) {
            if (window.adf) window.adf.track(217163, null, {});
        }
    }

    render() {
        const betslipNotification =
            window.location.pathname !== '/sport/kupon' &&
            !window.location.pathname.includes('/big9') &&
            (this.props.betslip.betList.length > 0 ||
                this.props.betslip.pending) ? (
                <BetslipNotification />
            ) : null;

        const seoData = this.props.serverConfig.seoData[
            window.location.pathname
        ];

        if (isAndroid) {
            window.addEventListener('beforeinstallprompt', (event) => {
                event.preventDefault();
                if (
                    !(
                        window.matchMedia('(display-mode: standalone)')
                            .matches || window.navigator.standalone === true
                    ) &&
                    !localStorage.getItem('hidePWAPrompt')
                ) {
                    this.props.uiActions.showModal('INSTALL_PWA_MODAL', {
                        deferredPrompt: event,
                    });
                } else {
                    return false;
                }
            });
        }
        let lastLoginNotification = this.props.auth.showLastLoginPopup && (
            <NotificationMessage
                date={`${formatDate(
                    this.props.auth?.user?.extraInfo?.lastLogin || new Date(),
                    'DD/MM-YYYY [kl.] HH:mm:ss'
                )}`}
                fs={'10'}
                lh={'10'}
                padding={'12px 20px'}
                position={'top'}
            />
        );

        return (
            <Firebase>
                {(eventsListener, marketsListener) => {
                    return (
                        <CookiesProvider>
                            <div className={'app'}>
                                <Layout>{this.props.children}</Layout>
                                {seoData ? (
                                    <MetaTags>
                                        <title>
                                            Bet25.dk/sport - {seoData.title}
                                        </title>
                                        <meta
                                            name="description"
                                            content={seoData.description}
                                        />
                                        <meta
                                            name="keywords"
                                            content={seoData.keywords}
                                        />
                                    </MetaTags>
                                ) : (
                                    <MetaTags>
                                        <title>
                                            Bet25.dk/sport - Odds og Livebetting
                                            – Spil dansk!
                                        </title>
                                        <meta
                                            name="description"
                                            content="Bet25 Sport tilbyder odds og livebetting på et utal af sportsbegivenheder på fodbold, tennis, håndbold, basketball, osv. Danmarks bedste på dansk fodbold."
                                        />
                                    </MetaTags>
                                )}

                                {betslipNotification}

                                {lastLoginNotification}

                                <NotificationContainer />
                            </div>
                        </CookiesProvider>
                    );
                }}
            </Firebase>
        );
    }

    componentDidUpdate(prevProps) {
        const serverConfig = this.props.serverConfig;
        if (
            serverConfig.geoFetched &&
            !serverConfig.geoData.allowed &&
            (this.props.ui.modals.LOGIN_MOBILE ||
                this.props.ui.modals.SIGNUP_MODAL)
        ) {
            this.props.uiActions.hideModal();
            this.props.uiActions.showModal('GEO_BLOCK');
        }
        if (
            this.props.auth.user &&
            this.props.betslip.error === 'Session udløbet'
        ) {
            this.props.authActions.logout();
        }
        if (this.props.sportTree.fetched && !prevProps.sportTree.fetched) {
            this.intervalFetchData();
        }
        if (prevProps.preEvents.data !== this.props.preEvents.data) {
            if (this.isBetListContainPreEvents()) {
                this.props.betslipActions.updateMarketsWithOddsChangeCheck(
                    this.props.preEvents.data
                );
            }
        }
        if (!prevProps.betslip.error && this.props.betslip.error) {
            this.intervalFetchData();
        }
        if (
            prevProps.location.pathname !== this.props.location.pathname &&
            this.routeListWithPreEvents.includes(this.props.location.pathname)
        ) {
            this.intervalFetchData();
        }

        if (!prevProps.auth.loginSuccess && this.props.auth.loginSuccess) {
            this.userLoggedInHandler();
        }

        if (
            (!prevProps.auth.loginSuccess && this.props.auth.loginSuccess) ||
            (!prevProps.auth.step3Success && this.props.auth.step3Success)
        ) {
            this.checkIsUserFirstLoggedIn();
        }

        if (
            !prevProps.auth.showLastLoginPopup &&
            this.props.auth.showLastLoginPopup
        ) {
            if (this.props.auth.showLastLoginPopup) {
                setTimeout(() => {
                    this.props.authActions.showLastLoginPopupStatus(false);
                }, 5000);
            }
        }

        if (
            prevProps.betslip.regularBetBetslipFetched !==
            this.props.betslip.regularBetBetslipFetched
        ) {
            if (this.props.betslip.hasMarkets.length === 0) {
                this.props.betslipActions.getCustomBetBetslip();
            }
        }
    }

    checkIsUserFirstLoggedIn() {
        const loggedAt = window.localStorage.getItem(
            LOCAL_STORAGE_KEYS.FIRST_USER_LOGGED_IN.replace(
                '{USER_ID}',
                this.props.auth.user.userId
            )
        );
        if (!loggedAt) {
            window.localStorage.setItem(
                LOCAL_STORAGE_KEYS.FIRST_USER_LOGGED_IN.replace(
                    '{USER_ID}',
                    this.props.auth.user.userId
                ),
                new Date().toISOString()
            );
            if (
                this.props.auth.user &&
                this.props.auth.user.limits &&
                (this.props.auth.user.limits.dailyAmountLimit ||
                    this.props.auth.user.limits.weeklyAmountLimit ||
                    this.props.auth.user.limits.monthlyAmountLimit)
            ) {
                setTimeout(() => {
                    this.props.uiActions.showModal('PIN_ADVICE_POPUP');
                }, 2000);
            }
        }
    }

    userLoggedInHandler() {
        window.localStorage.setItem(
            LOCAL_STORAGE_KEYS.LAST_LOGGED_IN_USER_NAME,
            this.props.auth.user.userName
        );

        if (
            this.props.auth.user.status === 'ex_24h' ||
            this.props.auth.user.status === 'ex_1m'
        ) {
            this.props.uiActions.showModal('SELF_EXCLUSION_POPUP');
        }
    }

    componentDidMount() {
        console.log('APP');
        this.props.liveEventsActions.getLiveEvents(this.props.sportData);
        this.intervalFetchData();
        this.props.betslipActions.setup();
        this.props.campaignActions.getCampaigns();
        this.props.serverConfigActions.getServerConfig();

        if (window.location.hash === '#TWA') {
            window.localStorage.setItem('isTWA', true);
        }

        let cb = () => {
            let id = null;
            RestService.RegisterDevice(id, 'bet25-web');
        };

        window._pcq.push(['subscriptionSuccessCallback', cb]);
        window._pcq.push(['APIReady', cb, () => {}]);
        try {
            window.acq('init', {
                endpoint: 'fpc.www.bet25.dk',
                safariOnly: false,
            });
        } catch (e) {
            console.log(e);
        } finally {
            window.acq('addCookie', {
                name: '_ga',
                expirationDays: 730,
            });
            window.acq('addCookie', {
                name: '_fbp',
                expirationDays: 90,
            });
            window.acq('addCookie', {
                name: '_fbc',
                expirationDays: 90,
            });
            window.acq('start');
        }
    }
}

function mapStateToProps(state) {
    return {
        loginModalStatus: state.ui.loginModalStatus,
        signupModalStatus: state.ui.signupModalStatus,
        auth: state.auth,
        liveEvents: state.liveEvents,
        preEvents: state.preEvents,
        sportTree: state.sportTree,
        sportData: state.sportTree.sportData,
        betslip: state.betslip,
        eventObject: state.eventObject,
        ui: state.ui,
        serverConfig: state.serverConfig,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
        authActions: bindActionCreators(AuthActions, dispatch),
        liveEventsActions: bindActionCreators(LiveEventsActions, dispatch),
        preEventsActions: bindActionCreators(PreEventsActions, dispatch),
        popularEventsActions: bindActionCreators(
            PopularEventsActions,
            dispatch
        ),
        betslipActions: bindActionCreators(BetslipActions, dispatch),
        eventObjectActions: bindActionCreators(EventObjectActions, dispatch),
        serverConfigActions: bindActionCreators(ServerConfigActions, dispatch),
        campaignActions: bindActionCreators(CampaignActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
