import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import {
    showModal as ShowModal,
    hideModal as HideModal,
} from 'actions/UiActions';
import { reset } from 'actions/AuthActions';
import { NotificationManager } from 'react-notifications';
import Signup from '@it25syv/sign-up';
import styled, { keyframes } from 'styled-components';

export const slideToLeft = keyframes`
    0% { transform: translateX(100%); }
    100% { transform: translateX(0%); }
`;

export const slideToTop = keyframes`
    0% { transform: translateY(100%); }
    100% { transform: translateY(0%); }
`;

const SignupPopupWrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
`;

const SignupPopupContent = styled.div`
    height: auto;
    min-height: 100%;
    width: 400px;
    box-sizing: border-box;
    padding: 20px 15px;
    display: block;
    background: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    /*right: 0;
    top: 0;*/
    z-index: 101;
    /*animation: ${slideToLeft} 300ms;
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-fill-mode: forwards;
    animation-direction: ${({ close }) => (close ? 'reverse' : 'normal')};*/
    @media screen and (max-width: 480px) {
        width: 100%;
        /*animation: ${slideToTop} 300ms;*/
    }
`;

const SignupPopup = ({ user, showModal, hideModal }) => {
    const [close, setClose] = useState(false);
    const scrollRef = useRef(null);

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'bet25.clickToSignup',
                userId: 0,
            });
        }
    }, []);

    useEffect(
        () => {
            if (user?.userId) {
                browserHistory.push('/sport/');
                hideModal('SIGNUP_MODAL');
            }
        },
        [user]
    );

    const showErrorMessage = (error) => {
        NotificationManager.error('', error, 4000);
    };
    /**
     *
     * @param payload
     * @param registerUser
     */

    return (
        <SignupPopupWrapper>
            <SignupPopupContent
                ref={scrollRef}
                close={close}
                key={`close-animation-${close}`}
            >
                <Signup
                    project="bet25"
                    showError={(error) => {
                        showErrorMessage(error);
                    }}
                    onEmailExists={() =>
                        showModal('EMAIL_EXIST', {
                            message: 'email',
                        })
                    }
                    onCPRExists={() => {
                        showModal('EMAIL_EXIST', {
                            message: 'cpr',
                        });
                    }}
                    onClose={() => {
                        setClose(true);
                        hideModal('SIGNUP_MODAL');
                        browserHistory.push('/sport/');
                    }}
                    minimumDepositLimit={50}
                    scrollableWrapperRef={scrollRef}
                    step1Headline={'Modtag nyheder og tilbud'}
                />
            </SignupPopupContent>
        </SignupPopupWrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state?.auth?.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        showModal: bindActionCreators(ShowModal, dispatch),
        hideModal: bindActionCreators(HideModal, dispatch),
        authReset: bindActionCreators(reset, dispatch),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignupPopup);
