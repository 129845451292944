import styled, { css } from 'styled-components';
import {
    BetCardItemWrapper,
    ItemsRow,
    StatusCircle,
} from 'features/MyBets/styled';
import Icon from '../../ecoui/Icon';

export const StyledBetCardItemWrapper = styled(BetCardItemWrapper)`
    padding: ${({ padding }) => padding || '10px'};
    width: 100%;
    max-height: ${({ hide, spilklub }) =>
        spilklub ? 'auto' : hide ? '0' : '60px'};
    ${({ hide }) =>
        hide &&
        css`
            opacity: 0;
            padding: 0;
        `};
    overflow: hidden;
    &:first-child {
        padding: 0 20px 15px;
    }
    ${({ slipOpened }) =>
        slipOpened &&
        css`
            transition: max-height 700ms ease-in-out;
            max-height: 1200px;
            padding: 0;
            ${({ hide }) => hide && 'opacity: 1'};
        `};
`;
export const StyledStatusCircle = styled(StatusCircle)`
    top: 0;
`;

export const StartTimeWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledItemsRow = styled(ItemsRow)`
    align-items: center;

    ${({ slipOpened }) =>
        slipOpened &&
        css`
            flex-direction: column;
            align-items: stretch;
        `};
`;

export const ArrowIcon = styled(Icon)`
    position: absolute;
    right: 15px;
    top: calc(50% - 8px);
    transition: all 200ms ease-in-out;
    ${({ slipOpened }) =>
        slipOpened
            ? css`
                  transform: rotate(-90deg) translateY(-50%);
              `
            : css`
                  transform: rotate(90deg) translateY(-50%);
                  right: 21px;
                  top: calc(50% - 8px);
              `};
    &.spilklub {
        right: 14px;
        transform: rotate(0deg) translateY(-50%);
    }
`;

export const ResultWrapper = styled.div`
    padding: ${({ spilklub, coupon }) =>
        spilklub ? (coupon ? '0 0 0 0' : '0 0 20px 0') : '15px 0 20px 0'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const CouponTitle = styled.div`
    width: 400px;
    margin: 0 auto;
`;

export const ShareWrapper = styled.div`
    width: 50%;
    display: flex;
    padding-bottom: 0;
    flex-direction: column;
    box-sizing: border-box;
`;

export const EffortsWrapper = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0;
`;

export const TableHeaderWrapper = styled.div`
    padding: 8px 15px 8px 0;
    border-bottom: 1px solid #d6e1ea;
    display: flex;
    justify-content: space-between;
`;

export const LoadWrapper = styled.div`
    margin: 10px auto;
    order: 3;
`;
