import React from 'react';
import ParentAuthPage from './ParentAuthPage';
import ViewSpinner from 'components/ViewSpinner';
import { ViewNumberInput, ViewTextInput } from 'components/ViewInput';
import { NotificationManager } from 'react-notifications';
import UserService from '../utils/UserService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthActions from 'actions/AuthActions';
import * as UiActions from 'actions/UiActions';
import classNames from 'classnames';
import '../css/PaymentVoucherPage.css';
import Button from 'ui/Button';
import { detectMobile } from 'utils/UiHelper';
import { browserHistory } from 'react-router';

class PaymentVoucherPage extends ParentAuthPage {
    fieldsValidator = {};

    constructor() {
        super();

        this.state = {
            ...this.state,
            error: null,
            result: null,
            pending: false,
            code: null,
            number: null,
        };
    }

    componentDidMount() {
        this.setPageTitle('Voucher Indbetaling');
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.auth.user && this.props.auth.sessionError) {
            browserHistory.push('/sport/');
            this.props.uiActions.showModal('LOGIN_FIRST');
        }
    }

    valueHandler(name, val) {
        if (name === 'code') {
            this.setState({ code: val });
        } else {
            this.setState({ number: val });
        }
    }

    checkHandler(key, fn) {
        this.fieldsValidator[key] = fn;
    }

    validateFields() {
        Object.keys(this.fieldsValidator).map((key) =>
            this.fieldsValidator[key]()
        );
    }

    onClickHandler() {
        if (this.state.code && this.state.number) {
            this.setState({ pending: true });

            UserService.DepositVoucher(this.state.number, this.state.code)
                .then((res) => {
                    if (res.data) {
                        this.setState({ result: res.data });
                        NotificationManager.success(
                            `Din voucher indbetaling på ${
                                res.data
                            } Kr. er tilføjet din spille konto.`
                        );
                        browserHistory.push('/sport/konto');
                    } else {
                        const { errorMessage } = res;
                        this.setState({ error: errorMessage });
                    }
                    this.setState({ pending: false });

                    this.props.authActions.updateBalance();
                })
                .catch((error) => {
                    const { errorMessage } = error;
                    this.setState({ error: errorMessage });
                    this.setState({ pending: false });
                });
        } else {
            this.validateFields();
        }
    }

    render() {
        const errorMsg = this.getErrorView(
            this.state.error ? this.state.error : false
        );

        const successMsg = this.state.result ? (
            <p className="success message p10">{this.state.result}</p>
        ) : null;

        return (
            <React.Fragment>
                {this.getHeaderView(() => {
                    this.props.uiActions.showModal('USER_MENU');
                })}
                <div
                    className={classNames('payment-voucher-page', {
                        mobile: detectMobile(),
                    })}
                >
                    <div className="voucher-container">
                        <div>
                            <div className="header">
                                Indtast nummer og kode og modtag din bonus
                            </div>
                            <div className="info">
                                Har du modtaget en voucher kan du indøse den
                                herunder. Du kan se regler og vilkår på din
                                voucher
                            </div>
                        </div>

                        {errorMsg}

                        <ViewNumberInput
                            name="number"
                            valueHandler={this.valueHandler.bind(this)}
                            checkHandler={this.checkHandler.bind(this)}
                            placeholder="Nummer"
                            placeholderLabel=" "
                        />
                        <ViewTextInput
                            name="code"
                            valueHandler={this.valueHandler.bind(this)}
                            checkHandler={this.checkHandler.bind(this)}
                            placeholder="Kode"
                            placeholderLabel=" "
                        />

                        {this.state.pending ? (
                            <ViewSpinner />
                        ) : (
                            <Button
                                active
                                margin="0 auto"
                                onClick={this.onClickHandler.bind(this)}
                            >
                                Bekræft
                            </Button>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return { auth: state.auth };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentVoucherPage);
