import React from 'react';
import styled, { css } from 'styled-components';
import Text from 'ui/Text';
import tickIcon from 'images/tick.svg';
import Icon from '../ecoui/Icon';
import { formatDate, formatDenmarkCurrency } from 'utils/UiHelper';
import Button from 'ui/Button';
import { bindActionCreators } from 'redux';
import { hideModal } from 'actions/UiActions';
import { connect } from 'react-redux';

const PopupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 8px 17px;
    height: calc(100% - 42px);
    justify-content: end;
    margin: 0 auto;
    position: fixed;
    left: ${({ left }) => (left ? `calc(${left}px - 8px)` : '200px')};
    width: ${({ width }) => (width ? `${width}px` : '477px')};

    @media screen and (max-width: 768px) {
        position: relative;
        width: auto;
        max-width: 420px;
        left: auto;
    }
`;
const PopupHeader = styled.div`
    background-color: #18aa1b;
    padding: 16px 0;
    margin-bottom: 10px;
    border-radius: 5px;
`;
const HeaderContent = styled.div`
    display: flex;
    align-items: center;
    width: max-content;
    margin: 0 auto;
`;
const IconWrapper = styled.div`
    border-radius: 50%;
    background-color: white;
    padding: 5px;
    display: flex;
    margin-left: 10px;
    svg {
        min-width: auto;
    }
`;
const PopupBody = styled.div`
    background-color: #edf1f8;
    border-radius: 5px;
    padding: 17px 29px;
`;
const BodyTitle = styled.div``;
const Divider = styled.div`
    opacity: 0.2;
    height: 1px;
    width: auto;
    background-color: #8a8c8e;
    margin: 18px 0 17px;
`;

const ReceiptPopup = ({ hideModal, modalData }) => {
    const { amount = '', id = '', position } = modalData;
    return (
        <PopupWrapper left={position.left} width={position.width}>
            <PopupHeader>
                <HeaderContent>
                    <Text
                        condensed
                        size="x2"
                        color="#FFFFFF"
                        padding="0"
                        align="center"
                        bold
                    >
                        Dit spil er placeret
                    </Text>
                    <IconWrapper>
                        <Icon
                            src={tickIcon}
                            alt="tick-icon"
                            color={'#18AA1B'}
                            height={'10px'}
                            width={'10px'}
                        />
                    </IconWrapper>
                </HeaderContent>
            </PopupHeader>
            <PopupBody>
                <BodyTitle>
                    <Text
                        condensed
                        size="x15"
                        color="#333333"
                        bold
                        padding="0 0 11px 0"
                    >
                        BIG 9
                    </Text>
                    <Text
                        condensed
                        size="x07"
                        color="#8A8C8E"
                        padding="0 0 4px 0"
                    >
                        Indsats
                    </Text>
                    {amount ? (
                        <Text
                            condensed
                            size="x15"
                            color="#333333"
                            padding="0"
                            bold
                        >
                            {formatDenmarkCurrency(amount)} kr.
                        </Text>
                    ) : null}
                    <Divider />
                    <Button
                        margin="0"
                        fontSize="16px"
                        width="100%"
                        onClick={() => {
                            hideModal('RECEIPT_POPUP');
                        }}
                        color={'#333333'}
                        active
                    >
                        Luk
                    </Button>
                    {id ? (
                        <Text
                            padding="16px 0 0 0"
                            color="#8C93A0"
                            size="x07"
                            align="center"
                            condensed
                            capitalize
                        >
                            {`ID: ${id} - ${formatDate(
                                Date.now(),
                                'DD. MMMM HH:mm'
                            )}`}
                        </Text>
                    ) : null}
                </BodyTitle>
            </PopupBody>
        </PopupWrapper>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        hideModal: bindActionCreators(hideModal, dispatch),
    };
}

export default connect(
    null,
    mapDispatchToProps
)(ReceiptPopup);
