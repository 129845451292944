import React, { useEffect, useState } from 'react';
import bottomMenuConfig from 'constants/bottomMenuConfig';
import { BottomMenu } from '@it25syv/bottom-menu';
import { browserHistory } from 'react-router';
import useHistoryItemsCount from 'features/BottomNavigation/useHistoryItemsCount';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showModal as ShowModal } from '../../actions/UiActions';

const BottomNavigationMenu = ({ auth, activeMenuItem, showModal }) => {
    const [intervalId, setIntervalId] = useState(null);
    const [activeBets, setActiveBets] = useState(0);
    const [initialMenuPosition, setInitialMenuPosition] = useState(0);

    const { count, refetch } = useHistoryItemsCount();

    useEffect(
        () => {
            if (auth.user) {
                if (intervalId) {
                    clearInterval(intervalId);
                }
                let interval = setInterval(() => {
                    refetch();
                }, 60000);
                setIntervalId(interval);
            }

            getMenuPosition();

            return () => {
                clearInterval(intervalId);
            };
        },
        [auth.user]
    );

    useEffect(
        () => {
            getMenuPosition();
        },
        [window.innerHeight]
    );

    useEffect(
        () => {
            // getActiveBets();
            if (!auth.user) {
                setActiveBets(0);
            }
        },
        [auth?.user]
    );

    useEffect(
        () => {
            setActiveBets(count);
        },
        [count]
    );

    // const getActiveBets = () => {
    //     UserService.GetMergedSalesPage()
    //         .then(([p1, p2]) => {
    //             if (p1?.data && p2?.data) {
    //                 const mergedCount = p1.data.count + p2.data.count;
    //                 setActiveBets(mergedCount);
    //             } else {
    //                 console.error('Failed active bets count');
    //             }
    //         })
    //         .catch((e) => console.error(e));
    // }; // todo fix multiple calls

    const getMenuPosition = () => {
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        if (
            windowHeight > 320 &&
            windowHeight < 768 &&
            windowWidth > 650 &&
            windowWidth < 1200
        ) {
            setInitialMenuPosition(200);
        } else if (windowWidth > 767 && windowWidth < 1400) {
            setInitialMenuPosition(527);
        } else {
            setInitialMenuPosition(505);
        }
    };

    return (
        <BottomMenu
            id={'navigation-mobile-new'}
            config={bottomMenuConfig(
                browserHistory,
                activeMenuItem,
                activeBets,
                initialMenuPosition,
                showModal,
                auth
            )}
        />
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: bindActionCreators(ShowModal, dispatch),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(BottomNavigationMenu);
