import {
    BETSLIP_ACCEPT_CHANGES,
    BETSLIP_ADDED_ERROR,
    BETSLIP_ADDED_OK,
    BETSLIP_CUSTOMBET_ADDED_OK,
    BETSLIP_CUSTOMBET_ADDED_ERROR,
    BETSLIP_ERROR,
    BETSLIP_ITEM_ERROR,
    BETSLIP_NORMALIZE_CHANGES,
    BETSLIP_CUSTOM_BET_NORMALIZE_CHANGES,
    BETSLIP_PENDING,
    BETSLIP_RESET,
    BETSLIP_CUSTOM_BET_RESET,
    BETSLIP_RESET_SINGLE_STAKE,
    BETSLIP_SET_SLIP,
    BETSLIP_SET_LAST_RESULT,
    BETSLIP_SET_LIVE_MARKETS,
    BETSLIP_SET_SUGGESTION,
    BETSLIP_SET_VALUE,
    BETSLIP_SET_CUSTOM_BET_VALUE,
    BETSLIP_UPDATE_TOTAL_ODDS_VALUE,
    BETSLIP_UPDATE_TOTAL_ODDS_VALUE_PENDING,
    BETSLIP_SUCCESS,
    BETSLIP_ADD_CUSTOMBET_RECOMMENDATION,
    BETSLIP_STATUS_CHANGED_FROM_LINK,
    BETSLIP_REGULAR_FETCHED_STATUS,
    BETSLIP_CUSTOMBET_FETCHED_STATUS,
} from 'constants/betslip';

const initialState = {
    success: false,
    error: false,
    pending: false,
    timer: null,
    added: false,
    addError: false,
    changed: false,
    disabled: false,
    markets: [],
    liveMarkets: {},
    systemName: null,
    totalOdds: 0,
    betList: [],
    hasMarkets: [],
    singleStake: 0,
    singlePrize: 0,
    slip: null,
    system: {
        stake: [],
        prize: [],
        multi: [],
        names: [],
        totalPrize: 0,
        totalStake: 0,
        accum: [],
        stakeFreeBet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    itemError: null,
    lastResult: null,
    setMarkets: {},
    setMarketsNew: [],
    placedCustomBetRecommendations: [],
    placedCustomBetMarkets: [],
    totalOddsUpdatePending: false,
    customBetEventId: null,
    customBetStake: 0,
    customBetPrize: 0,
    isCustomBet: false,
    customBetEventName: '',
    regularBetBetslipFetched: false,
    customBetBetslipFetched: false,
    betslipResetedFromLink: false,
};

export default function betslip(state = initialState, action) {
    switch (action.type) {
        case BETSLIP_PENDING:
            return {
                ...state,
                pending: action.payload,
                error: false,
                added: false,
            };
        case BETSLIP_ADDED_OK:
            const data = action.payload;
            let sm = [];
            data.betList.map((item) => {
                sm.push(
                    `REGULAR_BET_${item.gameId}_${
                        item.betNr
                    }_${item.marketName.replace(/\s/g, '')}`
                );
            });

            return {
                ...state,
                error: false,
                pending: false,
                added: true,
                betList: data.betList,
                totalOdds: data.totalOdds,
                hasMarkets: data.betList.map((item) => {
                    return item.gameId;
                }),
                setMarketsNew: sm,
            };

        case BETSLIP_CUSTOMBET_ADDED_OK:
            const customBetData = action.payload;

            //We make modification of this list to have possibility to subscribe on live events
            let selectionListModified = customBetData.selectionList.map(
                (item) => {
                    return {
                        ...item,
                        eventId: customBetData.eventId,
                        gameId: item.marketId,
                    };
                }
            );

            let customBetSm = [];

            customBetData.selectionList.map((item) => {
                customBetSm.push(
                    `CUSTOM_BET_${item.marketId}_${
                        item.betNr
                    }_${item.marketName.replace(/\s/g, '')}`
                );
            });

            return {
                ...state,
                error: false,
                pending: false,
                added: true,
                isCustomBet: true,
                betList: selectionListModified,
                totalOdds: customBetData.totalOdds,
                customBetEventId:
                    customBetData.selectionList.length > 0
                        ? customBetData.eventId
                        : null,
                customBetStake: customBetData.stake,
                customBetEventName:
                    customBetData.selectionList.length > 0
                        ? customBetData.eventName
                        : null,
                hasMarkets: customBetData.selectionList.map((item) => {
                    return item.marketId;
                }),
                setMarketsNew: customBetSm,
            };
        case BETSLIP_ADD_CUSTOMBET_RECOMMENDATION:
            const customBetRecommendationData = action.payload;

            //We make modification of this list to have possibility to subscribe on live events
            let selectionListModifiedCBR = customBetRecommendationData.selectionList.map(
                (item) => {
                    return {
                        ...item,
                        eventId: customBetRecommendationData.eventId,
                        gameId: item.marketId,
                    };
                }
            );

            let customBetRec = [];

            customBetRecommendationData.selectionList.map((item) => {
                customBetRec.push(
                    `CUSTOM_BET_${item.marketId}_${
                        item.betNr
                    }_${item.marketName.replace(/\s/g, '')}`
                );
            });

            return {
                ...state,
                error: false,
                pending: false,
                added: true,
                isCustomBet: true,
                betList: selectionListModifiedCBR,
                totalOdds: customBetRecommendationData.totalOdds,
                hasMarkets: customBetRecommendationData.selectionList.map(
                    (item) => {
                        return item.marketId;
                    }
                ),
                customBetEventName: customBetRecommendationData.eventName,
                setMarketsNew: customBetRec,
            };

        case BETSLIP_ADDED_ERROR:
            return {
                ...state,
                error: false,
                addError: action.payload,
                pending: false,
                added: false,
            };

        case BETSLIP_CUSTOMBET_ADDED_ERROR:
            return {
                ...state,
                error: false,
                addError: action.payload,
                pending: false,
                added: false,
            };

        case BETSLIP_SET_VALUE:
            return {
                ...state,
                ...action.payload,
            };

        case BETSLIP_REGULAR_FETCHED_STATUS:
            return {
                ...state,
                regularBetBetslipFetched: true,
            };
        case BETSLIP_CUSTOMBET_FETCHED_STATUS:
            return {
                ...state,
                customBetBetslipFetched: true,
            };

        case BETSLIP_SET_CUSTOM_BET_VALUE:
            return {
                ...state,
                ...action.payload,
            };

        case BETSLIP_ERROR:
            const error =
                action.payload === 'INVALIDSESSION'
                    ? 'Session udløbet'
                    : action.payload;
            return {
                ...state,
                success: false,
                pending: false,
                timer: null,
                added: false,
                changed: false,
                disabled: false,
                error: error,
            };

        case BETSLIP_RESET:
            return {
                ...initialState,
                pending: false,
                betslipResetedFromLink: action.payload,
                lastResult: state.lastResult,
            };

        case BETSLIP_RESET_SINGLE_STAKE:
            return {
                ...state,
                singleStake: 0,
            };

        case BETSLIP_CUSTOM_BET_RESET:
            return {
                ...initialState,
                pending: false,
                betslipResetedFromLink: action.payload,
                lastResult: state.lastResult,
            };

        case BETSLIP_UPDATE_TOTAL_ODDS_VALUE:
            return {
                ...state,
                totalOdds: action.payload,
                customBetPrize: action.payload * state.customBetStake,
            };

        case BETSLIP_UPDATE_TOTAL_ODDS_VALUE_PENDING:
            return {
                ...state,
                pending: false,
                totalOddsUpdatePending: action.payload,
            };

        case BETSLIP_SUCCESS:
            return {
                ...state,
                success: action.payload,
            };

        case BETSLIP_ACCEPT_CHANGES:
            return {
                ...state,
                changed: false,
            };

        case BETSLIP_NORMALIZE_CHANGES:
            let _prevSetMarkets = [...state.setMarketsNew];
            let _prevHasMarkets = [...state.hasMarkets];

            const _marketId = action.payload.marketId;
            const _betList = action.payload.betList;

            let _newSetMarkets = _prevSetMarkets.filter(
                (item) => !item.includes(`${_marketId}`)
            );
            let _newHasMarkets = _prevHasMarkets.filter(
                (item) => item !== _marketId
            );

            return {
                ...state,
                setMarketsNew: _newSetMarkets,
                hasMarkets: _newHasMarkets,
                betList: _betList,
            };

        case BETSLIP_CUSTOM_BET_NORMALIZE_CHANGES:
            const {
                selectionList,
                live,
                totalOdds,
                eventName,
                eventId,
            } = action.payload.data;
            const _removedMarketId = action.payload.marketId;
            let _prevSetMarketsCB = state.setMarketsNew;

            let _newSetMarketsCB = _prevSetMarketsCB.filter(
                (item) => !item.includes(`${_removedMarketId}`)
            );

            let selectionListNormalize = selectionList.map((item) => {
                return {
                    ...item,
                    eventId: eventId,
                    gameId: item.marketId,
                };
            });

            const liveMarkets =
                live && selectionList.length ? { ...state.liveMarkets } : {};
            if (live && selectionList.length)
                delete liveMarkets[action.payload.removedMarkedId];
            let result = {
                isCustomBet: Boolean(selectionList.length),
                betList: selectionListNormalize,
                hasMarkets: selectionList.map((item) => item.marketId),
                customBetEventName: selectionList.length ? eventName : null,
                customBetEventId: selectionList.length ? eventId : null,
                setMarketsNew: selectionList.length ? _newSetMarketsCB : [],
                totalOdds,
                liveMarkets,
                customBetStake:
                    state.customBetStake > 0 && selectionList.length
                        ? state.customBetStake
                        : 0,
                customBetPrize: state.customBetStake * totalOdds,
            };

            return {
                ...state,
                ...result,
            };

        case BETSLIP_ITEM_ERROR:
            return {
                ...state,
                itemError: action.payload,
            };

        case BETSLIP_SET_SUGGESTION:
            return {
                ...action.payload,
            };

        case BETSLIP_STATUS_CHANGED_FROM_LINK:
            return {
                ...state,
                betslipResetedFromLink: action.payload,
            };

        case BETSLIP_SET_LAST_RESULT:
            return {
                ...state,
                lastResult: action.payload,
            };
        case BETSLIP_SET_SLIP:
            return {
                ...state,
                slip: action.payload,
            };
        case BETSLIP_SET_LIVE_MARKETS:
            return {
                ...state,
                liveMarkets: action.payload,
            };

        default:
            return state;
    }
}
