import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import * as AuthActions from 'actions/AuthActions';
import * as UiActions from 'actions/UiActions';
import { connect } from 'react-redux';
import UserService from 'utils/UserService';
import {
    desktopOnly,
    detectMobile,
    formatDenmarkCurrency,
    scrollToTop,
} from 'utils/UiHelper';
import { getWinTeamOrBet } from 'utils/Helper';
import {
    BetCardItemWrapper,
    CardWrapper,
    ItemsRow,
    StatusCircle,
    Subtext,
    StyledEventName,
    AsianBadge,
} from 'features/MyBets/styled';
import {
    getStatus,
    formatBetMarketLabel,
    formatSaleTitle,
    getStatusIcon,
    showInfo,
    getTotalOdds,
    systemNames,
} from 'features/MyBets/common';
import infoIcon from 'images/info.png';
import ViewSpinner from 'components/ViewSpinner';
import Text from 'ui/Text';
import Wrapper from 'ui/Wrapper';
import { browserHistory } from 'react-router';
import CashoutService from 'utils/CashoutService';
import { NotificationManager } from 'react-notifications';
import Button from 'ui/Button';
import { CanceledLabel } from './styled';

const MyBets = ({ active, closeUserAreaPopup, authActions, uiActions }) => {
    const cashTimer = {};
    let dataTimer = null;
    let timer = null;
    let couponsFlags = {};

    /*State values*/
    const [salesClosed, setSalesClosed] = useState({});
    const [isFetching, setIsFetching] = useState(true);
    const [pageIndex, setPageIndex] = useState(1);
    const [isAllData, setIsAllData] = useState(false);
    const [cashoutSales, setCashoutSales] = useState(null);
    const [isConfirm, setIsConfirm] = useState({});
    const [inProgress, setInProgress] = useState({});
    /*****/

    /*Effects*/
    useEffect(
        () => {
            if (active) {
                const cashData = () => {
                    UserService.CashoutData((sales) => {
                        if (sales) {
                            setCashoutSales(sales);
                        }
                    });
                };

                cashData();

                dataTimer = window.setInterval(() => {
                    cashData();
                }, 30000);

                timer = window.setInterval(() => {
                    getData();
                }, 20000);
            }

            return () => {
                window.clearInterval(dataTimer);
                window.clearInterval(timer);
            };
        },
        [pageIndex]
    );

    useEffect(
        () => {
            window.addEventListener('scroll', windowScrollHandler);
            const nodeSelector = document.querySelector(
                '.section-user-area-container'
            );
            nodeSelector &&
                nodeSelector.addEventListener('scroll', () => {
                    nodeScrollHandler(nodeSelector);
                });
            return () => {
                nodeSelector &&
                    nodeSelector.removeEventListener(
                        'scroll',
                        nodeScrollHandler
                    );
                window.removeEventListener('scroll', windowScrollHandler);
            };
        },
        [salesClosed, isFetching]
    );

    useEffect(
        () => {
            if (isFetching && !isAllData) {
                getData();
            }
        },
        [isFetching]
    );
    /********/

    const windowScrollHandler = () => {
        if (
            !isAllData &&
            !isFetching &&
            window.scrollY + window.innerHeight >=
                document.body.clientHeight - 250
        ) {
            setIsFetching(true);
            setPageIndex(pageIndex + 1);
        } else {
            setIsFetching(false);
        }
    };
    const nodeScrollHandler = (selector) => {
        if (
            !isAllData &&
            !isFetching &&
            selector?.scrollTop + selector?.offsetHeight ===
                selector?.scrollHeight
        ) {
            setIsFetching(true);
            setPageIndex(pageIndex + 1);
        }
    };

    const prepareData = (data) => {
        let sales = {};

        setIsAllData(!data.count || data.count < 10);
        data = data.salesList.reverse();

        data.map((saleData) => {
            sales[saleData.saleId] = {
                ...saleData,
                coupons: saleData.couponList,
                id: saleData.saleId,
                saleDate: saleData.saleDate,
                system: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                systemText: '',
                totalStake: 0,
                totalWin: 0,
                totalCashout: 0,
                freeBet: !!saleData.freeBet,
                extraBet: !!saleData.extraBet,
                eventNames: [],
            };

            saleData.couponList.map((coupon) => {
                sales[saleData.saleId].system[coupon.betList.length]++;
                sales[saleData.saleId].totalStake += coupon.amount;
                sales[saleData.saleId].totalWin += coupon.amountWin;
                sales[saleData.saleId].totalCashout += coupon.amountWinCashout;
                sales[saleData.saleId].status = coupon.status;

                Object.keys(coupon.betList).map((sk) => {
                    const betlistItem = coupon.betList[sk];

                    let eventName = betlistItem.eventName;

                    if (
                        sales[saleData.saleId].eventNames.indexOf(eventName) ===
                        -1
                    ) {
                        sales[saleData.saleId].eventNames.push(eventName);
                    }

                    return betlistItem;
                });

                return coupon;
            });

            const sale = sales[saleData.saleId];

            Object.keys(sale.system).map((l) => {
                const s = sale.system[l];

                if (s >= 1) {
                    if (sale.systemText !== '') {
                        sale.systemText +=
                            ', ' +
                            s +
                            ' ' +
                            systemNames[l] +
                            (Number(s) > 1 ? 'r' : '');
                    } else {
                        sale.systemText +=
                            s +
                            ' ' +
                            systemNames[l] +
                            (Number(s) > 1 ? 'r' : '');
                    }
                }

                return s;
            });
            if (!saleData.systemText) {
                saleData.systemText = 'Single';
            }
        });

        setSalesClosed({
            ...salesClosed,
            ...sales,
        });
        setIsFetching(false);
    };
    const getData = () => {
        active
            ? UserService.HistoryBettingOpenedEx(pageIndex)
                  .then(({ data }) => {
                      prepareData(data);
                  })
                  .catch((e) => {
                      console.log(e);
                  })
            : UserService.HistoryBettingClosedEx(pageIndex)
                  .then(({ data }) => {
                      prepareData(data);
                  })
                  .catch((e) => {
                      console.log(e);
                  });
    };
    const getAsianBadge = (sale, coupon, bet) => {
        const getOdds = coupon.betlist > 1 ? getTotalOdds(sale) : bet.odds;
        if (coupon?.win && coupon?.win === coupon?.stake) {
            return <AsianBadge background="#B8C3D6">Indsats retur</AsianBadge>;
        } else if (coupon?.win && coupon?.win === coupon?.stake / 2) {
            return <AsianBadge background="#E24E4E">1/2 tabt</AsianBadge>;
        } else if (
            coupon?.win &&
            coupon?.win ===
                Math.round(coupon?.stake * ((getOdds - 1) / 2) + 100)
        ) {
            return <AsianBadge background="#18AA1B">1/2 vundet</AsianBadge>;
        }
    };
    const onClickHandler = (sale, bet) => {
        if (active && bet && bet.status === -1) {
            desktopOnly() && closeUserAreaPopup ? closeUserAreaPopup() : null;
            browserHistory.push('/sport/kamp/' + bet.eventId);
        }
    };
    const onClickCashoutHandler = (sale) => {
        if (!isConfirm[sale.id]) {
            let tmp = isConfirm || {};

            tmp[sale.id] = true;

            setIsConfirm({ ...tmp });

            cashTimer[sale.id] = setTimeout(() => {
                let tmp = isConfirm;

                tmp[sale.id] = false;
                setIsConfirm(tmp);
            }, 5000);
        } else {
            window.clearTimeout(cashTimer[sale.id]);

            let inProg = inProgress;
            inProg[sale.id] = true;
            setInProgress({ ...inProg });

            sale.coupons.map((v) => {
                makeCashout(sale.id, v.couponId, v.amountCashoutPotential);

                return v;
            });
        }
    };
    const makeCashout = (saleId, cpn, amountCashoutPotential) => {
        CashoutService.makeCashout(cpn, amountCashoutPotential).then((res) => {
            let { data } = res;
            let allSales = salesClosed;
            scrollToTop();

            if (res.success) {
                if (data === undefined || data === null) {
                    NotificationManager.error('', 'Server error', 4000);
                } else {
                    UserService.CashoutData((sales) => {
                        if (sales) {
                            setCashoutSales(sales);
                        }
                    });
                    if (data.cashout) {
                        delete allSales[saleId];
                        setSalesClosed(allSales);
                        authActions.updateBalance();

                        NotificationManager.success(
                            '',
                            'Væddemål lukket',
                            4000
                        );
                    } else {
                        NotificationManager.error(
                            '',
                            'Oddset har ændret sig',
                            4000
                        );
                    }
                }
            } else {
                NotificationManager.error('', res.errorMessage, 4000);
            }
            UserService.GetSalesPage().then(({ data }) => {
                if (data) {
                    uiActions.setActiveBets(data.count);
                }
            });
            setInProgress({
                ...inProgress,
                [saleId]: false,
            });
        });
    };
    const preparePossibleWin = (sale) => {
        let possibleWin = 0;
        sale.coupons.forEach((coupon) => {
            if (coupon.possiblePayout > 0) {
                possibleWin += coupon.possiblePayout;
            }
        });
        return formatDenmarkCurrency(possibleWin);
    };
    const couponsTemplate = (sale, key) => {
        const getStatusBorder = () => {
            let background = '#B8C3D6';
            if (sale.coupons.every((coupon) => coupon.status === 2)) {
                background = '#18AA1B';
            } else if (sale.coupons.every((coupon) => coupon.status === 3)) {
                background = '#E24E4E';
            }
            return background;
        };

        let isPresentCanceledItem = [];
        sale.coupons[0].betList.map((item) =>
            isPresentCanceledItem.push(item.status === 4)
        );

        return (
            <CardWrapper
                done={true}
                leftBorderColor={getStatusBorder()}
                key={key}
            >
                {sale.coupons.map((item) => {
                    return item.betList
                        .filter((item) => {
                            const id = `${item.competitor}__${
                                item.marketName
                            }_${item.eventName}_${item.odds}_${sale.id}`;
                            if (couponsFlags[id]) {
                                return false;
                            } else {
                                couponsFlags[id] = true;
                                return true;
                            }
                        })
                        .map((bet, i) => {
                            return couponItem(
                                sale,
                                bet,
                                i,
                                item,
                                item.type === 5
                            );
                        });
                })}
                <ItemsRow>
                    <Text
                        padding="15px 5px 10px 20px"
                        color="#333333"
                        size="x15"
                        width="50%"
                        bold
                    >
                        {sale.coupons[0].type === 5
                            ? 'Byg væddemål'
                            : sale.systemText
                                ? sale.systemText
                                : null}
                        {(sale.coupons[0].type === 5 &&
                            sale.coupons[0].status === 8) ||
                        (sale.coupons[0].type === 5 &&
                            sale.coupons[0].status === 4) ||
                        (sale.coupons[0].type === 5 &&
                            isPresentCanceledItem.includes(true)) ? (
                            <CanceledLabel>Anulleret</CanceledLabel>
                        ) : null}
                        {sale?.retailBet && (
                            <CanceledLabel>Spillet i butik</CanceledLabel>
                        )}
                    </Text>
                </ItemsRow>
                <ItemsRow>
                    <Text
                        padding="15px 5px 15px 20px"
                        color="#333333"
                        size="x15"
                        bold
                    >
                        <Subtext>Indsats</Subtext>
                        {formatDenmarkCurrency(sale.totalStake)} kr.
                        {sale.freeBet && sale.extraBet ? (
                            <span style={{ color: '#1C3E60' }}>
                                {' '}
                                (Ekstra bet)
                            </span>
                        ) : sale.freeBet ? (
                            <span style={{ color: '#1C3E60' }}>
                                {' '}
                                (Free bet)
                            </span>
                        ) : null}
                    </Text>

                    {sale.coupons[0].type === 5 ||
                    sale.couponList?.length === 1 ? (
                        <Text
                            padding="15px 30px 5px 15px"
                            align="right"
                            color="#333333"
                            size="x15"
                            bold
                        >
                            <Subtext right="30px">Samlet odds</Subtext>
                            {getTotalOdds(sale, sale.coupons[0].type === 5)}
                        </Text>
                    ) : null}
                </ItemsRow>

                <ItemsRow>
                    {sale.totalCashout > 0 ? (
                        <Text
                            padding="15px 30px 0 20px"
                            align="left"
                            color={'#18AA1B'}
                            size="x15"
                            bold
                        >
                            <Subtext left="15px">Cashed out</Subtext>
                            {formatDenmarkCurrency(sale?.totalCashout)} kr.
                        </Text>
                    ) : (
                        <Text
                            padding="15px 30px 0 20px"
                            align="left"
                            color={sale?.totalWin > 0 ? '#18AA1B' : '#333333'}
                            size="x15"
                            bold
                        >
                            <Subtext left="15px">
                                {active ? 'Mulig gevinst' : 'Gevinst'}
                            </Subtext>
                            {active
                                ? preparePossibleWin(sale)
                                : formatDenmarkCurrency(sale?.totalWin)}
                            kr.
                        </Text>
                    )}
                </ItemsRow>

                {/*{getTotalOdds(sale) > 1 && sale.coupons[0].type !== 5 ? (
                    <Text
                        padding="15px 30px 0 10px"
                        align="right"
                        color="#333333"
                        size="x15"
                        bold
                    >
                        Odds: {getTotalOdds(sale)}
                    </Text>
                ) : null}*/}
                {cashoutSales &&
                cashoutSales[key] &&
                sale.coupons.length === 1 ? (
                    !inProgress[key] ? (
                        <div className="text-center">
                            <Button
                                width="100%"
                                active
                                margin="10px auto"
                                fontSize="16px"
                                onClick={() => {
                                    onClickCashoutHandler(cashoutSales[key]);
                                }}
                            >
                                {!isConfirm[key]
                                    ? 'Cashout: '
                                    : 'Bekræft cashout for '}{' '}
                                {formatDenmarkCurrency(
                                    cashoutSales[key].totalCashout
                                ) + ' kr.'}
                            </Button>
                        </div>
                    ) : sale.coupons[0].type !== 1 ? (
                        <ViewSpinner />
                    ) : null
                ) : null}
                {
                    <Text center color="#8C93A0" capitalize size="12px">
                        {formatSaleTitle(sale)}
                    </Text>
                }
            </CardWrapper>
        );
    };
    const couponItem = (sale, bet, i, coupon, isCustomBet) => {
        return (
            <BetCardItemWrapper
                key={i}
                onClick={() => {
                    onClickHandler(sale, bet);
                }}
            >
                <StatusCircle status={getStatus(bet)}>
                    {getStatusIcon(bet)}
                </StatusCircle>
                <ItemsRow>
                    <Text
                        capitalize
                        padding="0 0 5px"
                        color="#333333"
                        size="x15"
                        bold
                    >
                        {getWinTeamOrBet(bet)}
                        {(sale.coupons[0].type !== 5 &&
                            sale.coupons[0]?.status === 8 &&
                            sale.couponList?.length === 1) ||
                        (sale.coupons[0].type !== 5 &&
                            bet?.status === 4 &&
                            sale.couponList?.length === 1) ? (
                            <CanceledLabel>Anulleret</CanceledLabel>
                        ) : null}
                        {bet.marketType === 4 &&
                            getAsianBadge(sale, coupon, bet)}
                    </Text>

                    <Text
                        align="right"
                        padding="0 15px 5px"
                        color="#333333"
                        size="x15"
                        bold
                    >
                        {isCustomBet ? '' : bet.odds.toFixed(2)}
                    </Text>
                    {bet.freeText ? (
                        <img
                            src={infoIcon}
                            onClick={(e) => {
                                showInfo(bet.freeText, e);
                            }}
                            height="16px"
                            width="16px"
                            alt="ingo"
                        />
                    ) : null}
                </ItemsRow>
                <ItemsRow>
                    <Text size="x07" padding="0 0 3px" color="#8C93A0">
                        {formatBetMarketLabel(bet)}
                    </Text>
                </ItemsRow>
                <ItemsRow justify="flex-start">
                    <StyledEventName
                        flex="0 0 auto"
                        size="x07"
                        padding="0 0 3px"
                        color="#8C93A0"
                    >
                        {bet.eventName}
                    </StyledEventName>
                </ItemsRow>
                {bet.resultText ? (
                    <ItemsRow justify="flex-start">
                        <Text
                            size="x07"
                            padding="0 0 3px"
                            color="#8C93A0"
                            capitalize
                        >
                            Resultat: {bet.resultText}
                        </Text>
                    </ItemsRow>
                ) : null}
            </BetCardItemWrapper>
        );
    };

    return (
        <Wrapper padding={desktopOnly() ? '20px 50px' : '0'}>
            {!detectMobile() && (
                <Text size="x2" color="#333" padding={'10px 0 0'} center bold>
                    Mine væddemål
                </Text>
            )}

            {Object.keys(salesClosed).length > 0 ? (
                Object.keys(salesClosed)
                    .reverse()
                    .map((key) => {
                        let sale = salesClosed[key];
                        return couponsTemplate(sale, key);
                    })
            ) : !isFetching ? (
                <Text center padding="10px" size="x15">
                    Du har ingen aktive væddemål i øjeblikket
                </Text>
            ) : null}
            {isFetching && <ViewSpinner />}
        </Wrapper>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}
export default connect(
    null,
    mapDispatchToProps
)(MyBets);
