import { makeRequest } from 'utils/REST';
import { PUNTER_PATH } from 'constants/appConfig';

class BonusService {
    request(requestType, url, data) {
        return makeRequest(requestType, url, data)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
    }

    post(method, data) {
        return this.request('post', PUNTER_PATH + method, data);
    }

    get(method, data) {
        return this.request('get', PUNTER_PATH + method, data);
    }

    getActiveBonus() {
        return this.get('activeBonus');
    }

    feitBonuses() {
        return this.post('forfeitBonus');
    }
}

export default new BonusService();
