import React, { Component } from 'react';
import classNames from 'classnames';
import './css/UISpinner.css';

export default class UISpinner extends Component {
    render() {
        const cls = classNames(
            'fa fa-circle-o-notch fa-spin fa-fw',
            this.props.className,
            this.props.size ? `fa-${this.props.size}` : 'fa-2x'
        );
        return this.props.plain ? (
            <i className={cls} />
        ) : (
            <div className="view-spinner">
                <i className={cls} />
            </div>
        );
    }
}
