import styled from 'styled-components';
import { breakpoint } from '../../constants/breakpoints';

export const HR = styled.div`
    margin: ${({ margin }) => (margin ? margin : '0 auto')};
    border: none;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 1px;
    background: ${({ bg }) => (bg ? bg : '#dadfea')};
    &.isResultPage {
        margin: 0 auto;
    }
    @media screen and ${breakpoint.L} {
        &.isResultPage {
            width: calc(100% - 20px);
        }
    }
`;

export const WrapperDescription = styled.div`
    height: auto;
    width: 475px;
    background: #edf1f8;
    display: block;
    padding: 20px;
    margin: 30px 0 0;
    border-radius: 5px;
    text-align: center;
    box-sizing: border-box;
    @media screen and ${breakpoint.L} and (min-width: 490px) {
        width: 380px;
    }
    @media screen and ${breakpoint.S} {
        width: 100%;
    }
`;

export const BetLineItemWrapper = styled.div`
    height: auto;
    width: 100%;
    margin: ${({ margin }) => (margin ? margin : '25px auto 0')};
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: ${({ justifyContent }) =>
        justifyContent ? justifyContent : 'space-between'};
    align-items: center;
    position: relative;
    &.sm {
        max-width: 340px;
    }
    &.xl {
        max-width: 435px;
    }
    .arrowRight {
        position: absolute;
        right: 30px;
        bottom: 0;
        transform: translateY(-50%);
    }
    @media screen and ${breakpoint.S} {
        &.sm {
            max-width: 100%;
        }
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: ${({ margin }) => (margin ? margin : '0')};
    box-sizing: border-box;
    @media screen and ${breakpoint.L} {
        margin: 0;
        &.three {
            width: 45%;
            padding-right: 5px;
            div {
                width: calc((100% / 3) - 5px);
            }
        }
        &.two {
            width: calc(55% - 55px);
            padding: 0 5px;
            div {
                width: calc(50% - 5px);
            }
        }
    }
`;

export const BetDefault = styled.div`
    height: 40px;
    width: ${({ width }) => (width ? width : '54px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${({ color }) => (color ? color : '#fed300')};
    box-sizing: border-box;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
    line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '12px')};
    font-weight: 700;
    border-radius: 5px;
    margin: ${({ margin }) => (margin ? margin : '0 3px')};
    background: ${({ bg }) => (bg ? bg : '#1c3e60')};
    opacity: ${({ disabled }) => (disabled ? 0.09 : 1)};
    &:first-child {
        margin-left: 0;
    }
    &:last-of-type {
        margin-right: 0;
    }
    &.active {
        background: #fed300;
        color: #333;
    }
    &.results {
        background: #edf1f8;
        border: ${({ activeResult }) =>
            activeResult ? 'none' : '0.5px solid #C6CCDB'};
        color: #333333;
    }
    &.activeResult {
        border: 0.5px solid transparent;
        background: #18aa1b;
        color: #fff;
    }
    &.terminal {
        height: 50px;
    }

    @media screen and ${breakpoint.L} {
        &.isDescription {
            width: 42px;
        }
    }
`;

export const BetWider = styled(BetDefault)`
    width: 80px;
    @media screen and ${breakpoint.L} and (min-width: 490px) {
        width: 55px;
    }
    @media screen and ${breakpoint.L} {
        &.isDescription {
            width: 55px;
        }
    }
`;

export const WrapperResults = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 52px 20px;
    margin: 0 0 30px;
    border-radius: 5px;
    text-align: center;
    box-sizing: border-box;
    @media screen and ${breakpoint.B} {
        padding: 20px 0 50px;
    }
    @media screen and ${breakpoint.L} {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const ResultsDescr = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0;
    width: 100%;
`;

export const ResultsCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0;
    width: 477px;
    overflow-x: hidden;
    @media screen and ${breakpoint.S} {
        width: 100%;
    }
    @media screen and ${breakpoint.L} and (min-width: 490px) {
        width: 100%;
    }
    &.terminal {
        width: 100%;
    }
`;

export const WrapperResultsCards = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0;
    width: 100%;
`;

export const WrapperResultsTopBar = styled.div`
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    @media screen and ${breakpoint.L} {
        padding: 0 0 0 17px;
    }
`;

export const ControlsWrapper = styled.div`
    display: block;
    height: ${({ height }) => (height ? height : '44px')};
    width: 44px;
    box-sizing: border-box;
    margin: 0 8px 0 0;
    button {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0 5px 5px 0;
        background: ${({ bg }) => (bg ? bg : 'transparent')};
        &:disabled {
            img {
                opacity: 0.3;
            }
        }
    }
    &.left {
        button {
            transform: rotate(180deg);
            border-radius: 5px 0 0 5px;
        }
    }
    &:last-of-type {
        margin: 0 0 0 8px;
    }
    /*@media screen and ${breakpoint.L} {
        display: none;
    }*/
`;

export const BackArrow = styled.img`
    display: inline-block;
    margin-right: 5px;
    position: relative;
    color: #333;
`;

export const ResultsTopBar = styled.div`
    height: 44px;
    width: calc(100% - 104px);
    overflow: hidden;
    &.terminal {
        width: 100%;
    }
    @media screen and ${breakpoint.L} {
        width: 100%;
    }
`;

export const ResultsTopBarCont = styled.ul`
    height: 64px;
    width: auto;
    list-style: none;
    padding: 0 0 20px;
    margin: 0;
    overflow-x: scroll;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    transition: all 0.4s ease-in-out;
    &::-webkit-scrollbar {
        display: none;
    }
    li {
        height: 44px;
        width: auto;
        list-style-type: none;
        margin: 0 7px 0 0;
        &:last-of-type {
            margin: 0;
        }
        button {
            margin: 0;
        }
    }
    @media screen and ${breakpoint.L} {
        padding-right: 17px;
        li {
            margin-right: 10px;
            &:last-of-type {
                margin: 0;
            }
        }
    }
`;

export const WrapperResultsContent = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: 100%;
    @media screen and ${breakpoint.L} {
        padding: 0 7px;
    }
`;

export const Overlay = styled.div`
    height: 100vh;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
`;

export const ContentWidget = styled.div`
    height: 100vh;
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    z-index: 3;
`;

export const Divider = styled.div`
    width: 50%;
    padding: ${({ padding }) => padding || '0'};
    display: block;
    box-sizing: border-box;
    position: relative;
    margin: ${({ margin }) => margin || '0'};
    @media screen and (min-width: 769px) {
        &:first-child {
            width: 477px;
        }
    }

    &.sportRadarWrapper {
        max-width: 600px;
        width: calc(100% - 500px);

        .widget-container {
            padding: 20px 25px 100px;
            box-shadow: 0 5px 25px #cdd6f2;
            border-radius: 5px;
        }
        @media screen and ${breakpoint.N} {
            display: ${({ display }) => display || 'flex'};
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
            background: rgba(0, 0, 0, 0.3);
            position: fixed;
            left: 0;
            top: 0;
            z-index: 20;
            padding: 20px 15px;
            .widget-container {
                margin: 15px 0 0;
                padding: 0;
                max-width: 450px;
                width: 100%;
                background: #fff;
                box-shadow: none;
                position: relative;
                z-index: 5;
            }
        }
    }
    &.isResults {
        padding: 0;
    }
    &.terminal {
        &.bets {
            width: 985px;
        }
        &.betSlip {
            width: 600px;
            padding-left: 30px;
            position: relative;
            min-height: 100vh;
        }
        &.sportRadarWrapper {
            display: ${({ display }) => display || 'flex'};
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
            background: rgba(0, 0, 0, 0.3);
            position: fixed;
            left: 0;
            top: 0;
            z-index: 99999999999;
            padding: 20px 15px;
            .widget-container {
                margin: 15px 0 0;
                padding: 0;
                max-width: 600px;
                width: 100%;
                background: #fff;
                box-shadow: none;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 5;
            }
        }
        &.results {
            width: 985px;
        }
    }
    @media screen and ${breakpoint.B} {
        padding: 0 15px 0 0;
    }
    @media screen and ${breakpoint.M} {
        padding: 0;
    }
    @media screen and ${breakpoint.L} {
        &.isResults {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
    }

    @media screen and ${breakpoint.L} and (min-width: 490px) {
        &:first-child {
            width: 100%;
            max-width: 100%;
        }
    }
    /*@media screen and (max-width: 599px) and (min-width: 480px) {
        width: calc(50% - 60);
        &:first-child {
            width: 50%;
        }
        &:last-of-type {
            width: 60px;
        }
    }*/
    @media screen and ${breakpoint.N} {
        width: 100%;
        padding: 0;
    }
`;

export const ResultedTicket = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 25px 30px;
    margin: ${({ margin }) => margin || '15px 0 0'};
    border-radius: 5px;
    width: 100%;
    background: #edf1f8;
    &.isResultPage {
        @media screen and ${breakpoint.L} {
            width: calc(100% - 20px);
            display: block;
            margin: 15px auto 0;
        }
    }
`;

export const ResultedTicketItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;

    padding: 0;
    margin: ${({ margin }) => margin || '0'};
`;

export const Big9OmWrapper = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 60px 30px;
    margin: 0;
    @media screen and ${breakpoint.S} {
        padding: 20px 15px;
    }
`;

export const Big9OmContent = styled.div`
    height: auto;
    display: block;
    box-sizing: border-box;
    max-width: 675px;
    width: 100%;
    @media screen and ${breakpoint.L} {
        max-width: 576px;
    }
`;

export const Big9OmImageWrapper = styled.div`
    margin: 40px 0 0;
`;

export const Big9OmLeagueIcon = styled.img`
    height: 12px;
    width: 19px;
    margin-right: 5px;
    border-radius: 1px;
`;

export const ArrowTop = styled.img`
    transform: rotate(-90deg);
    position: absolute;
    right: 30px;
    top: 50%;
`;

export const WildcardActive = styled.div`
    height: 40px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fed300;
    border-radius: 5px;
`;

export const Big9OmSetBetWrapper = styled.div`
    display: block;
    max-width: 435px;
    width: 100%;
    margin: 40px 0 0;
    background: #edf1f8;
    @media screen and ${breakpoint.L} and (min-width: 490px) {
        max-width: 350px;
    }
    @media screen and ${breakpoint.S} {
        max-width: 100%;
    }
`;

export const Big9OmSetBetTopLine = styled.div`
    height: 8px;
    display: block;
    background: #1c3e60;
    border-radius: 5px 5px 0 0;
`;

export const Big9OmSetBetSettings = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 70px 0;
    position: relative;
    @media screen and ${breakpoint.L} {
        padding: 5px 40px;
    }
`;

export const Big9OmSetBetSettingsItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 92px;
    height: auto;
    padding: 10px 0;
    box-sizing: border-box;
    border-radius: 5px;
    &.active {
        background: rgba(28, 62, 96, 0.11);
    }
`;

export const Big9OmSetBetAmountWrapper = styled.div`
    height: auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 20px 10px;
    @media screen and ${breakpoint.L} {
        padding: 0 10px 10px;
    }
`;

export const Big9OmSetBetAmountItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 44px;
    width: 105px;
    margin: 10px 0 0;
    border: 1px solid #c6ccdb;
    border-radius: 5px;
    font-size: 16px;
    line-height: 16px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    background: transparent;
    &.active {
        background: rgba(28, 62, 96, 0.11);
    }
    @media screen and ${breakpoint.S} {
        width: calc((100% / 3) - 10px);
    }
`;

export const Big9OmRigtigeWrapper = styled.div`
    height: auto;
    width: 100%;
    max-width: 352px;
    margin: 35px 0 0;
    @media screen and ${breakpoint.S} {
        max-width: 100%;
    }
`;

export const Big9OmRigtigeTop = styled.div`
    height: auto;
    width: 100%;
`;

export const Big9OmRigtigeMiddle = styled.div`
    height: auto;
    width: 100%;
    display: block;
    margin: 5px 0 0;
    position: relative;
    box-sizing: border-box;
`;

export const Big9OmRigtigeImage = styled.img`
    height: auto;
    width: 100%;
    display: block;
`;

export const Big9OmRigtigeMiddleCont = styled.div`
    height: 96px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    @media screen and ${breakpoint.S} {
        height: 100%;
    }
`;

export const Big9OmRigtigeBottom = styled.div`
    height: auto;
    width: 100%;
    margin: 5px 0 0;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const EarningsWrapper = styled.div`
    height: auto;
    width: ${({ width }) => width || '100%'};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    margin: 20px 0 0;
`;

export const EarningsTop = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 13px 20px;
    margin: 0 0 20px;
    background: #1c3e60;
    border-radius: 5px;
    box-sizing: border-box;
    position: relative;
    &:hover {
        cursor: pointer;
    }
`;

export const EarningsArrow = styled.img`
    height: 8px;
    width: 8px;
    position: absolute;
    right: 10px;
    top: calc(50% - 2px);
    transform: rotate(180deg);
    &.active {
        transform: rotate(-90deg);
    }
`;

export const EarningsItemsWrapper = styled.div`
    height: ${({ height }) => height || 'auto'};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    position: relative;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
`;

export const EarningsItem = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 13px 20px;
    background: transparent;
    border-radius: 5px;
    box-sizing: border-box;
    position: relative;
    background: ${({ bg }) => bg || '#f5f7fc'};
`;

export const VideoElement = styled.iframe`
    height: auto;
    min-height: 400px;
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin: 40px 0 0;
    border: none;
    outline: none;
    @media screen and ${breakpoint.S} {
        min-height: 250px;
    }
`;
