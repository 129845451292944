import {
    SET_BONUS,
    PENDING_BONUS,
    REMOVE_BONUS,
    RESET_BONUS_STATE,
} from '../constants/bonuses';

let initialState = {
    bonuses: [],
    fetched: false,
    pending: false,
    bonusWasRemovedSuccessfully: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BONUS:
            return {
                ...state,
                pending: false,
                fetched: true,
                bonuses: action.payload,
            };
        case PENDING_BONUS:
            return {
                ...state,
                pending: action.payload,
            };
        case REMOVE_BONUS:
            return {
                ...state,
                bonusWasRemovedSuccessfully: action.payload,
                pending: false,
            };
        case RESET_BONUS_STATE:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default reducer;
