import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import UIModalNew from 'components/UIModalNew';
import * as UiActions from 'actions/UiActions';
import { bindActionCreators } from 'redux';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import { hideModal } from 'actions/UiActions';
import * as AuthActions from 'actions/AuthActions';
import Wrapper from 'ui/Wrapper';
import { detectMobile } from 'utils/UiHelper';
import UserService from 'utils/UserService';
import Text from 'ui/Text';
import checkmark from './../lottie/checkmark-circle.json';
import xmarkImg from 'images/xmark-circle.svg';
import CloseIcon from 'images/close-icon.svg';
import Icon from '../ecoui/Icon';
import Button from 'ui/Button';
import Lottie from 'lottie-react';
import { SetTransactionSaleId } from 'actions/RetailSalesHistoryActions';
import { formatDenmarkCurrency } from '../utils/UiHelper';

const MobileModalWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px;
    border-radius: 10px 10px 0 0;
    background: white;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
    max-height: 90%;
    overflow-y: auto;
`;

const DesktopModalWrapper = styled(UIModalNew)``;
const Top = styled.div`
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
`;

const Link = styled.a`
    color: var(--Neutral-black_font_900, #1e1e1e);
    font-family: 'Roboto Condensed';
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

const StyledCheckbox = styled.input`
    width: 18px;
    height: 18px;
    background: #eceff5;
    border: 1px solid #cbcccc;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
    margin-right: 8px;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    &:checked {
        background: #34be7a
            url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"/></svg>')
            center/16px 16px no-repeat;
    }
`;

const StyledText = styled(Text)`
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    padding: 0;
    color: #818181;
`;

const StyledStrong = styled.strong`
    font-size: 14px;
    color: #111111;
`;

const TransferRetailModal = (props) => {
    const [isChecked, setIsChecked] = useState(false);
    const [modalState, setModalState] = useState('confirm');
    const [errorMessage, setErrorMessage] = useState('');
    const winAmount = `${formatDenmarkCurrency(props.modalData.win)} kr.`;
    const onCloseModal = () => {
        props.hideModal('TRANSFER_RETAIL');
    };
    useEffect(() => {
        const showTransferModal =
            localStorage.getItem('showTransferModal') === 'true';
        if (showTransferModal) {
            setModalState(props.modalData.isSuccess ? 'success' : 'error');
            if (!detectMobile()) {
                onCloseModal();
            }
        }
    }, []);
    const onDepositRedirect = () => {
        UserService.TransferRetailMoney(
            props.modalData.product,
            props.modalData.saleId
        )
            .then((res) => {
                if (res.success) {
                    localStorage.setItem('showTransferModal', isChecked);
                    props.setTransactionSaleId(props.modalData.saleId);
                    const isLoggedIn = () => props.auth && props.auth.user;
                    if (!detectMobile()) {
                        NotificationManager.success(
                            '',
                            `${winAmount} er blevet overført til din spillekonto.`,
                            4000
                        );
                    }
                    isLoggedIn();
                    props.authActions.updateBalance();
                    if (detectMobile()) {
                        setModalState('success');
                    } else {
                        onCloseModal();
                    }
                } else {
                    if (detectMobile()) {
                        setErrorMessage(res.errorMessage);
                        setModalState('error');
                    } else {
                        onCloseModal();
                        if (!detectMobile()) {
                            NotificationManager.error(
                                '',
                                `${res.errorMessage}`
                            );
                        }
                    }
                }
            })
            .catch((err) => {
                onCloseModal();
                if (!detectMobile()) {
                    NotificationManager.error('', `${res.errorMessage}`);
                }
            });
        // onCloseModal();
    };

    const handleCheckboxChange = (e) => {
        const checked = e.target.checked;
        setIsChecked(checked);
        localStorage.setItem('showTransferModal', !checked);
    };

    const ModalComponent = detectMobile()
        ? MobileModalWrapper
        : DesktopModalWrapper;

    return (
        <ModalComponent>
            {modalState === 'confirm' && (
                <>
                    <Text
                        bold
                        margin={'0'}
                        align={'left'}
                        size="24"
                        color="#000000"
                    >
                        Bekræft din overførsel
                    </Text>
                    <Text
                        color="#818181"
                        margin={'16px 0 0 0'}
                        padding={'0'}
                        align={'left'}
                        size="1rem"
                    >
                        Du er ved at overføre din gevinst på{' '}
                        <StyledStrong>{winAmount} </StyledStrong>
                        til din spillekonto. Du kan efterfølgende udbetale
                        beløbet eller bruge det på nye væddemål.
                    </Text>
                    <Text
                        color="#818181"
                        margin={'16px 0 16px 0'}
                        padding={'0'}
                        align={'left'}
                        size="1rem"
                    >
                        <strong>Bemærk:</strong> Denne handling kan ikke
                        fortrydes.
                    </Text>
                    <Wrapper
                        display="flex"
                        justify="left"
                        alignItems="center"
                        margin={'0'}
                        padding={'0'}
                    >
                        <label
                            htmlFor="transferCheckbox"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            <StyledCheckbox
                                id="transferCheckbox"
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <StyledText>Vis ikke igen</StyledText>
                        </label>
                    </Wrapper>
                    <Wrapper
                        display="flex"
                        justify="space-between"
                        margin={'16px 0 0 0'}
                        padding={'0'}
                    >
                        <Button
                            onClick={onCloseModal}
                            fontSize="16px"
                            grey
                            width={detectMobile() ? '100%' : '150px'}
                            margin={detectMobile() ? '0 10px 0 0' : '0'}
                            fontWeight={'normal'}
                        >
                            Annuller
                        </Button>
                        <Button
                            margin={'0'}
                            fontSize="16px"
                            width={detectMobile() ? '100%' : '150px'}
                            active
                            onClick={onDepositRedirect}
                        >
                            <strong>Overfør</strong>
                        </Button>
                    </Wrapper>
                </>
            )}
            {modalState === 'success' &&
                detectMobile() && (
                    <MobileModalWrapper>
                        <Top>
                            <Icon
                                src={CloseIcon}
                                onClick={() => onCloseModal('TRANSFER_RETAIL')}
                                color={'#c6ccdb'}
                                width={'20px'}
                                height={'20px'}
                                style={{ cursor: 'pointer' }}
                            />
                        </Top>
                        <Wrapper
                            display="flex"
                            justify="center"
                            flexAlign={'center'}
                            margin={'0'}
                            flexDirection={'column'}
                            align={'center'}
                            padding={'20px'}
                        >
                            <Lottie
                                animationData={checkmark}
                                loop={false}
                                style={{
                                    width: '80px',
                                    height: '80px',
                                    display: 'block',
                                    margin: '0 auto 0',
                                }}
                            />
                            <Text
                                bold
                                margin={'30px 0px 0px 0px'}
                                size="16"
                                color="#111111"
                            >
                                Gevinst overført
                            </Text>
                            <Text size="14">
                                Din gevinst på{' '}
                                <StyledStrong>{winAmount} </StyledStrong>
                                er blevet overført til din online spillekonto.
                            </Text>
                        </Wrapper>
                        <Button
                            fontSize="16px"
                            active
                            width="100%"
                            margin="0 auto"
                            onClick={onCloseModal}
                        >
                            OK
                        </Button>
                    </MobileModalWrapper>
                )}
            {modalState === 'error' &&
                detectMobile() && (
                    <MobileModalWrapper>
                        <Top>
                            <Icon
                                src={CloseIcon}
                                onClick={() => onCloseModal('TRANSFER_RETAIL')}
                                color={'#c6ccdb'}
                                width={'20px'}
                                height={'20px'}
                                style={{ cursor: 'pointer' }}
                            />
                        </Top>
                        <Wrapper
                            display="flex"
                            justify="center"
                            flexAlign={'center'}
                            margin={'0'}
                            flexDirection={'column'}
                            align={'center'}
                            padding={'20px'}
                        >
                            <img
                                src={xmarkImg}
                                alt="xmark icon"
                                width="80px"
                                height="80px"
                            />
                            <Text
                                bold
                                margin={'30px 0px 0px 0px'}
                                size="16"
                                color="#111111"
                            >
                                Overførsel afbrudt, prøv igen
                            </Text>
                            <Text size="14">{errorMessage}</Text>
                        </Wrapper>
                        <Button
                            fontSize="16px"
                            active
                            width="100%"
                            margin="0 auto"
                            onClick={onCloseModal}
                        >
                            OK
                        </Button>
                    </MobileModalWrapper>
                )}
        </ModalComponent>
    );
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
        hideModal: bindActionCreators(hideModal, dispatch),
        setTransactionSaleId: bindActionCreators(
            SetTransactionSaleId,
            dispatch
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransferRetailModal);
