import {
    POPULAR_EVENTS_FETCH_ERROR,
    POPULAR_EVENTS_FETCH_PENDING,
    POPULAR_EVENTS_FETCH_SUCCESS,
} from 'constants/popularEvents';

const initialState = {
    pending: false,
    fetched: false,
    data: [],
    vaixEventsFetched: false,
    error: false,
};

export default function popularEvents(state = initialState, action) {
    switch (action.type) {
        case POPULAR_EVENTS_FETCH_PENDING:
            return {
                ...state,
                pending: true,
                fetched: false,
                data: [],
                error: false,
            };

        case POPULAR_EVENTS_FETCH_SUCCESS:
            return {
                ...state,
                pending: false,
                fetched: true,
                data: action.payload.data,
                vaixEventsFetched: action.payload.vaixIsFetched,
                error: false,
            };

        case POPULAR_EVENTS_FETCH_ERROR:
            return {
                ...state,
                pending: false,
                fetched: false,
                data: [],
                error: action.payload,
            };

        default:
            return state;
    }
}
