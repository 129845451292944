import React, { useEffect, useState } from 'react';
import 'css/ClearYourCouponModal.scss';
import Text from '../ui/Text';
import { COLORS } from '../themes/colors';
import Checkbox from '../features/SpilKlub/components/Checkbox';
import Divider from '../ui/Divider';
import Button from '../ui/Button';
import tickIcon from 'images/tick-rounded.svg';
import { hideModal as HideModal } from '../actions/UiActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearAllMarkets as ClearAllMarkets } from 'actions/BetslipActions';
import * as BetslipActions from 'actions/BetslipActions';

const ClearYourCouponModal = ({
    clearAllMarkets,
    modalData,
    hideModal,
    betslipActions,
}) => {
    const [checkboxStatus, setCheckboxStatus] = useState(false);

    useEffect(() => {}, []);

    const dontShowModal = (e) => {
        setCheckboxStatus(!checkboxStatus);
    };

    const clearMyCoupon = () => {
        if (checkboxStatus) {
            let modalData = {
                show: !checkboxStatus,
                action: 'clearBetslip',
            };
            localStorage.setItem(
                'clearRegularBetModalData',
                JSON.stringify(modalData)
            );
        }
        clearAllMarkets();

        setTimeout(() => {
            if (modalData.betData.isCustomBet) {
                betslipActions.addCustomBetMarket(
                    modalData.betData.marketId,
                    modalData.betData.betNr,
                    modalData.betData.odds,
                    modalData.betData.isLive,
                    [],
                    modalData.betData.isCustomBet
                );
            } else {
                betslipActions.addCustomBetRecommendation(
                    modalData.betData.vaixEventId,
                    modalData.betData.vaixExternalEventId
                );
            }
        }, 1000);
        closeModal();
    };

    const closeModal = () => {
        hideModal('CLEAR_YOUR_COUPON');
    };

    return (
        <div className={'clear-coupon'}>
            <div className="clear-coupon__cont">
                <Text
                    size={'20'}
                    lh={'26'}
                    bold
                    color={COLORS.dark}
                    margin={'0'}
                    padding={'0'}
                >
                    Ryd din kupon først
                </Text>
                <Text
                    size={'14'}
                    lh={'20'}
                    margin={'20px 0 0'}
                    padding={'0'}
                    color={COLORS.dark}
                >
                    Byg væddemål kan ikke kombineres med andre spil. For at
                    kunne tilføje odds fra Byg væddemål skal du først fjerne de
                    valg du har på dit betslip.
                </Text>
                <div
                    className={`${
                        checkboxStatus ? 'active' : ''
                    }  clear-coupon__checkbox-wrapper`}
                    onClick={() => setCheckboxStatus(!checkboxStatus)}
                >
                    <Text
                        size={'14'}
                        margin={'00 0'}
                        padding={'0'}
                        color={COLORS.dark}
                    >
                        Vis ikke denne besked igen.
                    </Text>
                    <div className="clear-coupon__checkbox-icon">
                        {checkboxStatus ? (
                            <img
                                className={'clear-coupon__checkbox-icon'}
                                src={tickIcon}
                                alt="icon"
                            />
                        ) : null}
                    </div>
                </div>
                <Divider className={'clear-coupon__line'} margin={'25px 0 0'} />
                <div className="clear-coupon__controls-wrapper">
                    <Button active onClick={clearMyCoupon} margin={'0'}>
                        Ryd min kupon
                    </Button>
                    <Button
                        margin={'5px 0 0 '}
                        transparent
                        color={COLORS.dark}
                        onClick={closeModal}
                    >
                        Behold mine valg
                    </Button>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: (modalId) => dispatch(HideModal(modalId)),
        clearAllMarkets: bindActionCreators(ClearAllMarkets, dispatch),
        betslipActions: bindActionCreators(BetslipActions, dispatch),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(ClearYourCouponModal);
