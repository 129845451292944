import React, { useEffect, useState } from 'react';
import dankortCredit from './../images/footer/credits/dankort.svg';
import dinersclubCredit from './../images/footer/credits/dinersclub.svg';
import maestroCredit from './../images/footer/credits/maestro.svg';
import mastercardCredit from './../images/footer/credits/mastercard.svg';
import visaCredit from './../images/footer/credits/visa.svg';
import visaECredit from './../images/footer/credits/visa-e.svg';

import logo from './../images/bet25sport_logo.svg';
import stopSpilled from './../images/footer/stopspillet.svg';
import centerLudomani from './../images/footer/center_ludomani.png';
import rofus from './../images/footer/rofus.png';
import betradar from './../images/footer/betradar.png';
import footerSpl from './../images/footer/spillemyndigheden.png';

import styled from 'styled-components';
import { Img } from '@it25syv/25syv-ui';
import { DigitalClock } from '@it25syv/25syv-ui';
import { WithClock } from '@it25syv/25syv-ui';
import { Link } from 'react-router';
const Clock = WithClock(DigitalClock);

const breakpoint = {
    S: `(max-width: 475px)`,
    M: `(max-width: 768px)`,
    L: `(max-width: 1024px)`,
};

const FooterWrapper = styled.footer`
    transition: all 0.4s linear;
    @media screen and (min-width: 1025px) {
        margin-left: ${({ isOpened, isAsideBarsHide }) =>
            isAsideBarsHide ? 0 : isOpened ? 250 + 'px' : 60 + 'px'};
    }

    @media screen and ${breakpoint.L} {
        margin-top: 30px;
    }

    * {
        font-weight: 300;
        font-family: 'Roboto Condensed';
    }
    strong {
        font-weight: 600;
    }
`;

const FooterTop = styled.div`
    width: 100%;
    background: #404040;
    min-height: 220px;
    box-sizing: border-box;
`;

const FooterCentered = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;

    @media screen and ${breakpoint.L} {
        padding-left: 18px;
        padding-right: 18px;
    }
`;

const FooterTopContent = styled(FooterCentered)`
    color: #fff;
    padding-top: 24px;
    padding-bottom: 60px;

    @media screen and ${breakpoint.L} {
        text-align: center;
        padding-bottom: 30px;
    }
`;

const FooterTopMenuWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media screen and ${breakpoint.L} {
        flex-direction: column;
        align-items: center;
    }
`;

const FooterTopMenu = styled.div`
    width: ${(props) => (props.width ? props.width : 'auto')};
    @media screen and ${breakpoint.L} {
        :not(.show-on-mobile) {
            display: none;
        }

        width: 100%;
    }
`;

const FooterTopMenuText = styled.p`
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    padding: 0 0 10px;
    margin: 0;
    line-height: 20px;

    @media screen and ${breakpoint.L} {
        font-size: 12px;
    }
`;

const FooterTopMenuHeader = styled.h4`
    color: #ffffff;
    font-size: 18px;
    padding: 12px 0;
    margin: 0;
`;

const FooterTopMenuList = styled.ul`
    padding: 0 0 0 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;

    li {
        list-style: disc;

        @media screen and ${breakpoint.L} {
            list-style: none;
        }
    }
`;

const FooterTopMenuItem = styled(Link)`
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
    margin-left: -5px;
    line-height: 22px;
`;

const FooterTopMenuItemLink = styled.a`
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
    margin-left: -5px;
    line-height: 22px;
`;

const FooterPaymentWrapper = styled.div`
    background: #333333;
`;

const FooterPaymentCentered = styled(FooterCentered)`
    display: flex;
    min-height: 220px;
    align-items: center;
    justify-content: center;

    @media screen and ${breakpoint.L} {
        padding: 20px 0;
        min-height: 260px;
        max-width: 500px;
        flex-wrap: wrap;
    }

    @media screen and ${breakpoint.M} {
        min-height: 200px;
    }

    @media screen and ${breakpoint.S} {
        min-height: 260px;
    }
`;

const FooterPaymentItem = styled.div`
    width: 157px;
    height: 90px;
    background: #ffffff;
    box-shadow: 0 0 10px #333;
    border-radius: 5px;
    margin: 0 20px;
    text-align: center;

    img {
        height: 100%;
    }

    @media screen and ${breakpoint.L} {
        width: 118px;
        height: 68px;
        flex-wrap: wrap;
    }
`;

const FooterProviders = styled.div`
    background: #0e0e0e;
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and ${breakpoint.L} {
        display: none;
    }
`;

const FooterProvidersItem = styled.div`
    margin: 0 34px;
`;

const FooterTextBlock = styled.div`
    background: #212121;
    min-height: 180px;
`;

const FooterText = styled(FooterCentered)`
    column-count: 2;
    column-gap: 145px;
    font-size: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
    color: #a6a6a6;
    line-height: 24px;

    h3 {
        padding: 0;
        margin: 0 0 20px;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
    }

    p {
        margin: 0 0 40px;
        display: inline-block;
    }

    a {
        color: #a6a6a6;
    }

    @media screen and ${breakpoint.L} {
        column-count: 1;
    }
`;

const FooterDGA = styled.div`
    background: #404040;
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and ${breakpoint.L} {
        min-height: 112px;
    }
`;

const FooterDGABlock = styled.div`
    height: 100%;
    a {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
    }

    @media screen and ${breakpoint.L} {
        line-height: 18px;
    }

    img {
        height: 80px;
        @media screen and ${breakpoint.L} {
            height: 60px;
        }
    }
`;

const FooterDGATitle = styled.div`
    width: 200px;
    margin-left: 10px;
    font-size: 18px;
    color: #fff;
    @media screen and ${breakpoint.L} {
        font-size: 16px;
    }
`;

const FooterBottom = styled.div`
    background: #333333;
    padding: 48px 0 60px;

    @media screen and ${breakpoint.L} {
        padding: 28px 0 60px;
    }
`;

const FooterBottomSupport = styled.div`
    display: flex;
    justify-content: space-around;
    @media screen and ${breakpoint.L} {
        flex-direction: column;
    }
`;

const FooterBottomSupportItem = styled.div`
    border-radius: 5px;
    background: #585858;
    width: 33%;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    img {
        height: 35px;
    }

    @media screen and ${breakpoint.L} {
        width: 100%;
        margin: 0 0 17px;
        justify-content: space-between;
        padding: 0 20px;
    }
`;

const FooterBottomSupportBtn = styled.a`
    padding: 0 15px;
    height: 35px;
    line-height: 35px;
    background: rgba(256, 256, 256, 0.25);
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    margin: 0 0 0 15px;
    font-size: 15px;
    font-weight: bold;

    @media screen and ${breakpoint.L} {
        width: 165px;
        text-align: center;
        font-size: 14px;
        box-sizing: border-box;
    }
`;

const FooterBottomCopyright = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 0;
    line-height: 20px;

    @media screen and ${breakpoint.L} {
        flex-direction: column;
        text-align: center;
        line-height: 16px;
    }
`;

const FooterBottomCopyrightItem = styled.div`
    width: 220px;
    color: #adadad;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and ${breakpoint.L} {
        width: 100%;
        margin: 6px 0 0;
        font-size: 12px;
    }
`;

const FooterBottomAgeRestrictItem = styled(FooterBottomCopyrightItem)`
    text-align: right;

    @media screen and ${breakpoint.L} {
        font-size: 14px;
        text-align: left;
        margin: 20px 0 0;
    }
`;

const FooterBottomAgeRestrict = styled.div`
    min-width: 35px;
    width: 35px;
    height: 35px;
    background: #fff;
    font-size: 15px;
    line-height: 35px;
    font-weight: bold;
    text-align: center;
    color: #333;
    border-radius: 50%;
    margin: 0 8px 0 0;
`;

const FooterClockDesktop = styled.div`
    color: #fff;
    font-size: 24px;
    @media screen and ${breakpoint.L} {
        display: none;
    }
`;

const FooterClockMobile = styled(FooterClockDesktop)`
    display: none;
    @media screen and ${breakpoint.L} {
        display: block;
    }
`;

const getMenuItemsConfig = (handlers = {}) => [
    // Information
    {
        section: 'Information',
        showOnMobile: true,
        items: [
            {
                title: 'Vilkår og betingelser',
                path:
                    'https://intercom.help/25syv/da/articles/3493559-generelle-vilkar-betingelser',
                blank: true,
            },
            {
                title: 'Cookiepolitik',
                onClick: handlers.cookiesHandler,
            },
            {
                title: 'Ansvarligt spil',
                path:
                    'https://intercom.help/25syv/da/articles/3493600-ansvarligt-spil-25syv',
                blank: true,
            },
            {
                title: 'Persondata politik',
                path:
                    'https://intercom.help/25syv/da/articles/3870767-persondatapolitik-for-25syv-a-s',
                blank: true,
            },
            {
                title: 'Regler for væddemål',
                path:
                    'https://intercom.help/25syv/da/articles/3493498-generelle-regler-for-vaeddemal',
                blank: true,
            },
            {
                title: 'Kalaallisut',
                path:
                    'https://intercom.help/25syv/da/articles/4425281-kalaallisut',
                blank: true,
            },
        ],
    },
    {
        section: 'Vigtige punkter',
        showOnMobile: false,
        items: [
            {
                title: 'Live sport',
                path: '/live',
            },
            {
                title: 'Dagens kampe',
                path: '/pre',
            },
            {
                title: 'Fodbold',
                path: '/fodbold',
            },
            {
                title: '25-nyt',
                path: '/kampagner',
            },
        ],
    },
    {
        section: 'Quick links',
        showOnMobile: false,
        items: [
            {
                title: 'Bet25 Heste',
                path: '/heste/',
            },
            {
                title: 'Bet25 Casino',
                path: '/casino/',
            },
        ],
    },
    {
        section: 'Kundeservice',
        showOnMobile: false,
        items: [
            {
                title: 'Udbetaling',
                path:
                    'https://intercom.help/25syv/da/collections/559324-indbetaling-udbetaling',
                blank: true,
            },
            {
                title: 'Konto verificering',
                path:
                    'https://intercom.help/25syv/da/collections/559314-min-25syv-konto-rod25-bet25-derby25',
                blank: true,
            },
            {
                title: 'Bonusomsætning',
                path:
                    'https://intercom.help/25syv/da/collections/2020208-kampagner-bonusser',
                blank: true,
            },
        ],
    },
];

const Footer = (props) => {
    const [footerTextType, setFooterTextType] = useState('default');

    useEffect(
        () => {
            const path = window.location.pathname.split('/')[2];
            if (path === 'ishockey') {
                setFooterTextType('ishockey');
            } else if (path === 'haandbold' || path === 'handball') {
                setFooterTextType('haandbold');
            } else {
                setFooterTextType('default');
            }
        },
        [window.location.pathname]
    );

    const cookiesHandler = window.CookieConsent
        ? window.CookieConsent.renew
        : null;

    const menuConfig = getMenuItemsConfig({ cookiesHandler });
    const year = new Date().getFullYear();

    const isTWA = Boolean(JSON.parse(window.localStorage.getItem('isTWA')));

    const getFooterTextBlock = () => {
        if (footerTextType === 'ishockey') {
            return (
                <FooterTextBlock>
                    <FooterText>
                        <p>
                            Hos Bet25 får du en lang række slagskud af
                            kraftfulde odds samt livebets på Ishockey både på
                            den danske metalliga, verdens største ishockeyliga -
                            NHL og mange andre store, glorværdige turneringer og
                            ligaer, som der spilles rundt omkring i verden.
                        </p>
                        <p>
                            Hvis du har appetit på endnu flere fede bets og spil
                            på ishockey, tilbyder vi også masser af spændende
                            spil og specials på EM, VM og OL. Så spænd skøjterne
                            og sus ind og spil på, hvilken nation, der ender med
                            at løfte trofæet. Giv den et skud og se om ikke
                            heldet tilsmiler dig, og du kan glæde dig over endnu
                            en gevinst – store som små.
                        </p>
                        <p>
                            Hos Bet25 Sport tilbyder vi altid stribevis af gode
                            odds både før og under kampen.
                        </p>
                        <p>
                            Ishockey er populært i store dele af verden,
                            samtidig er det en sport, hvor tempoet er enormt og
                            uforudsigeligheden er til at tage at føle på. Hårde
                            dueller og boblende temperament medfører ofte
                            udvisninger, som kan vende kampen fuldstændig på
                            hovedet.
                        </p>
                        <p>
                            Hos Bet25 Sport finder du selvfølgelig en lang række
                            af fede og spændende odds på det absolut bedste, som
                            ishockeyens topligaer har at byde på såsom:
                            Champions Hockey League. Den finske SM Liiga, den
                            svenske liga (SEL) og den kontinentale hockeyliga
                            (KHL).
                        </p>
                        <p>
                            Hos Bet25 Sport gør vi det nemt og hyggeligt at
                            spille hos os og med vores app, er det nemt og
                            bekvemt at spille på din nation, din yndlingsspiller
                            eller favorithold, lige når det passer dig.
                        </p>
                        <p>
                            Hvis du ikke allerede har en konto hos Bet25 Sport,
                            kan du nemt og enkelt oprette den med det samme, så
                            du kan spille på spandevis af høje odds på lige dét,
                            der interesserer dig. Foruden et hav af odds på alt
                            det fede fra ishockeyens verdens, tilbyder vi hos
                            Bet25 Sport odds på en bred vifte af spændende
                            sportsgrene, de største turneringer og de mest
                            prestigefyldte ligaer.
                        </p>
                    </FooterText>
                </FooterTextBlock>
            );
        } else if (footerTextType === 'haandbold') {
            return (
                <FooterTextBlock>
                    <FooterText>
                        <p>
                            Hos Bet25 Sport kan vi slet ikke få nok af håndbold,
                            så derfor tilbyder vi selvfølgelig odds og
                            livebetting på de største og mest populære ligaer,
                            kampe og turneringer ikke bare i Danmark, men i hele
                            verden. Spil på dit danske favorithold eller giv
                            spændingen et ekstra nøk op og spil på den tyske
                            Bundesliga, den franske LNH eller de spanske ASOBAL
                            liga.
                        </p>
                        <p>
                            Hos Bet25 Sport løber du sjældent tør for sjove og
                            spændende betting-muligheder med vores mange
                            vinderspil og specials. Spil på, hvem som bliver
                            topscorer eller hvem, der går hele vejen og løfter
                            trofæet.
                        </p>
                        <p>
                            Vi har selvfølgelig spil til alle de største
                            turneringer i ind- og udland så kig forbi Bet25
                            Sport, næste gang der er EM, VM, OL eller Champions
                            League og spil på alle de store klubber og nationer
                            og se, om du ikke får ret og kan smile over endnu en
                            gevinst – store som små.
                        </p>
                        <p>
                            Hos Bet25 Sport har vi ligeledes masser af fede spil
                            til dig, som også vil spille på den danske 1.
                            division.
                        </p>
                        <p>
                            Hos Bet25 Sport gør vi det let og hyggeligt at
                            spille, når det passer dig. Med vores app er det
                            altid nemt og bekvemt at spille på din nation, din
                            yndlingsspiller eller favorithold.
                        </p>
                        <p>
                            Hvis du ikke allerede har en konto hos Bet25 Sport,
                            kan du nemt og enkelt oprette den med det samme, så
                            du kan spille på spandevis af høje odds på lige dét,
                            der interesserer dig. Foruden et hav af odds på alt
                            det fede fra ishockeyens verdens, har vi hos Bet25
                            Sport odds på en bred vifte af spændende
                            sportsgrene, de største turneringer og de mest
                            prestigefyldte ligaer.
                        </p>
                    </FooterText>
                </FooterTextBlock>
            );
        } else {
            return (
                <FooterTextBlock>
                    <FooterText>
                        <h3>Mange sportsgrene og væddemål</h3>
                        <p>
                            Hos Bet25 Sport ønsker vi at forkæle vores brugere
                            med alt det bedste fra sportens verden. Derfor
                            tilbyder vi dig et hav af forskellige spilmuligheder
                            på en lang række forskellige sportsgrene. Her finder
                            du alt, lige fra odds på populære sportsgrene som{' '}
                            <Link to="/fodbold">fodbold</Link>,{' '}
                            <Link to="/tennis">tennis</Link> og{' '}
                            <Link to="/haandbold">håndbold</Link> til mere
                            nichéprægede sportsgrene som{' '}
                            <Link to="/esport-counterstrike">eSport</Link> og{' '}
                            <a href={isTWA ? '' : '/heste'} target="_blank">
                                trav
                            </a>
                            .
                        </p>
                        <h3>Dansk fodbold er i højsædet</h3>
                        <p style={{ breakInside: 'avoid' }}>
                            Et af de steder, hvor Bet25 Sport ønsker at have
                            fokus er dansk fodbold. Danmarks bedste
                            fodboldrække,{' '}
                            <Link to="/fodbold/danmark/superligaen">
                                den danske Superliga
                            </Link>
                            , har en stor plads i Bet25 Sport’s udvalg. Det
                            betyder, at du altid vil kunne finde gode
                            spilmuligheder på den næste Superliga-runde. Her vil
                            det både være muligt at spille på kampens vinder,
                            antal mål, første målscorer, men også et utal af
                            spillerspecials såsom antal skud på mål, tacklinger,
                            afleveringer og meget andet.
                        </p>
                        <h3>Spilmuligheder for alle</h3>
                        <p>
                            Du har mulighed for altid, at have Bet25 Sport ved
                            hånden, hvis du henter vores app og så har du også
                            mulighed for at spille på vores andre platforme med
                            dit Bet25 Sport-login. Hos Bet25 Sport tilbyder vi
                            nemlig vores kunder “en bruger, tre spilmuligheder”,
                            hvilket betyder, at du med én og samme bruger også
                            kan spille både på{' '}
                            <a href={isTWA ? '' : '/heste'} target="_blank">
                                Bet25 Heste
                            </a>{' '}
                            og{' '}
                            <a href={isTWA ? '' : '/casino'} target="_blank">
                                Bet25 Casino,
                            </a>{' '}
                            der ligesom Bet25 Sport er en del af Danmarks
                            sjoveste spilfamilie. Du kan dermed være sikker på,
                            at der er noget for enhver smag, hvis du vælger at
                            oprette en bruger hos os.
                        </p>
                    </FooterText>
                </FooterTextBlock>
            );
        }
    };

    return (
        <FooterWrapper
            isOpened={props.isOpened}
            isAsideBarsHide={props.isAsideBarsHide}
        >
            <FooterTop>
                <FooterTopContent>
                    <Img height="35px" src={logo} />
                    <FooterTopMenuWrapper>
                        <FooterTopMenu
                            width={'265px'}
                            className={'show-on-mobile'}
                        >
                            <FooterTopMenuHeader>
                                Udbydes af 25syv
                            </FooterTopMenuHeader>
                            <FooterTopMenuText>
                                25syv A/S, Mariane Thomsens Gade, 8000 Aarhus C.
                            </FooterTopMenuText>
                        </FooterTopMenu>
                        {menuConfig.map((section, index) => (
                            <FooterTopMenu
                                className={
                                    section.showOnMobile && 'show-on-mobile'
                                }
                                key={index}
                            >
                                <FooterTopMenuHeader>
                                    {menuConfig[index].section}
                                </FooterTopMenuHeader>
                                {menuConfig[index].section === 'Information' ||
                                menuConfig[index].section === 'Quick links' ||
                                menuConfig[index].section === 'Kundeservice' ? (
                                    <FooterTopMenuList>
                                        {menuConfig[index].items.map(
                                            (item, ind) => (
                                                <li key={ind}>
                                                    <FooterTopMenuItemLink
                                                        href={item.path || null}
                                                        target={'_blank'}
                                                        onClick={
                                                            item.onClick || null
                                                        }
                                                        {...item.props}
                                                    >
                                                        {item.title}
                                                    </FooterTopMenuItemLink>
                                                </li>
                                            )
                                        )}
                                    </FooterTopMenuList>
                                ) : (
                                    <FooterTopMenuList>
                                        {menuConfig[index].items.map(
                                            (item, ind) => (
                                                <li key={ind}>
                                                    <FooterTopMenuItem
                                                        to={item.path || null}
                                                        /*target={
                                                        item.blank
                                                            ? '_blank'
                                                            : ''
                                                    }*/
                                                        onClick={
                                                            item.onClick || null
                                                        }
                                                        {...item.props}
                                                    >
                                                        {item.title}
                                                    </FooterTopMenuItem>
                                                </li>
                                            )
                                        )}
                                    </FooterTopMenuList>
                                )}
                            </FooterTopMenu>
                        ))}
                    </FooterTopMenuWrapper>
                </FooterTopContent>
            </FooterTop>
            <FooterPaymentWrapper>
                <FooterPaymentCentered>
                    <FooterPaymentItem>
                        <img src={dankortCredit} alt="Dankort" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={visaCredit} alt="Visa" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={visaECredit} alt="Visa Electron" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={mastercardCredit} alt="Master Card" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={maestroCredit} alt="Maestro" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={dinersclubCredit} alt="Dinersclub" />
                    </FooterPaymentItem>
                </FooterPaymentCentered>
            </FooterPaymentWrapper>
            <FooterProviders>
                <FooterProvidersItem>
                    <img src={betradar} height={'35px'} alt="betradar" />
                </FooterProvidersItem>
            </FooterProviders>

            {getFooterTextBlock()}

            <FooterDGA>
                <FooterDGABlock>
                    <a href="https://www.spillemyndigheden.dk/" target="_blank">
                        <img
                            src={footerSpl}
                            alt="Tilladelse fra og under tilsyn af Spillemyndigheden."
                        />
                        <FooterDGATitle>
                            Tilladelse fra og under tilsyn af Spillemyndigheden.
                        </FooterDGATitle>
                    </a>
                </FooterDGABlock>
            </FooterDGA>

            <FooterBottom>
                <FooterCentered>
                    <FooterBottomSupport>
                        <FooterBottomSupportItem>
                            <a
                                href="https://www.stopspillet.dk/"
                                target={'_blank'}
                            >
                                <img height="35px" src={stopSpilled} alt="" />
                            </a>
                            <FooterBottomSupportBtn
                                href="tel:70-22-28-25"
                                className="footerBottomSupportBtn"
                            >
                                Ring til 70 22 28 25
                            </FooterBottomSupportBtn>
                        </FooterBottomSupportItem>
                        <FooterBottomSupportItem>
                            <a href="https://ludomani.dk/" target={'_blank'}>
                                <img
                                    height="35px"
                                    src={centerLudomani}
                                    alt=""
                                />
                            </a>
                            <FooterBottomSupportBtn
                                href="https://ludomani.dk/"
                                target={'_blank'}
                            >
                                Test dig selv
                            </FooterBottomSupportBtn>
                        </FooterBottomSupportItem>
                        <FooterBottomSupportItem>
                            <a href="https://www.rofus.nu/" target={'_blank'}>
                                <img height="35px" src={rofus} alt="" />
                            </a>
                            <FooterBottomSupportBtn
                                href="https://www.rofus.nu/"
                                target={'_blank'}
                            >
                                Opret registrering
                            </FooterBottomSupportBtn>
                        </FooterBottomSupportItem>
                    </FooterBottomSupport>
                    <FooterBottomCopyright>
                        <FooterClockMobile>{<Clock />}</FooterClockMobile>
                        <FooterBottomCopyrightItem>
                            © {year} 25syv A/S. <br />
                            Alle rettigheder forbeholdes.
                        </FooterBottomCopyrightItem>
                        <FooterClockDesktop>{<Clock />}</FooterClockDesktop>
                        <FooterBottomAgeRestrictItem>
                            <FooterBottomAgeRestrict>
                                18+
                            </FooterBottomAgeRestrict>
                            Spil kan være vanedannende, spil altid med omtanke.
                        </FooterBottomAgeRestrictItem>
                    </FooterBottomCopyright>
                </FooterCentered>
            </FooterBottom>
        </FooterWrapper>
    );
};
export default Footer;
