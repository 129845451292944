import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showModal } from 'actions/UiActions';
import {
    detectMobile,
    disableBodyScroll,
    enableBodyScroll,
} from 'utils/UiHelper';
import { browserHistory } from 'react-router';
import { Login as NewLogin, WithClearSafariPageCache } from '@it25syv/25syv-ui';
import loginBanner from 'images/banners/login_mobile_banner.jpg';
import { login } from 'actions/AuthActions';
import { setCookie, getCookie } from '../../utils/Helper';

const LOGIN_TYPES = {
    LOGIN: 1,
    MIT_ID: 2,
    PIN_LOGIN: 3,
};
const stayOnPageLinks = ['/sport/big9/spil'];

const LoginMobile = ({
    hideModal = () => {},
    showModal = () => {},
    onCloseModal = null,
    login = () => {},
    auth = {},
    modalData = {},
}) => {
    onCloseModal = onCloseModal || modalData?.onCloseModal;
    // close the modal automatically so onCloseModal implementation won't need calling hideModal itself
    const onCloseModalAutoHide = modalData?.onCloseModalAutoHide;
    // stayOnPage works only when log in modal don't pushes its path to history
    // redirectBackPath may also help for some specific paths to redirect after login
    const redirectBackPath = modalData?.redirectBackPath;

    const [loginType, setLoginType] = useState(LOGIN_TYPES.LOGIN);

    const stayOnPage = Boolean(modalData?.stayOnPage);
    const currentLocation = browserHistory.getCurrentLocation()?.pathname;
    const stayOnPrevPage =
        stayOnPage || stayOnPageLinks.includes(currentLocation);
    const path = window.location.pathname.split('/');
    const isItInitialLogin =
        (path.includes('testing') && path.includes('log-ind')) ||
        window.location.hostname === 'bs-local.com'
            ? true
            : !!getCookie('isItInitialLogin');

    useEffect(() => {
        let pin =
            localStorage.getItem('pin-token') &&
            localStorage.getItem('pin-token') !== 'null';

        if (
            isItInitialLogin === false &&
            window.location.pathname !== '/sport/testing/log-ind'
        ) {
            setLoginType(LOGIN_TYPES.MIT_ID);
        } else {
            if (pin && !auth.user) {
                hideModal('LOGIN');
                showModal('PIN_LOGIN', {
                    openMitId: () => {
                        setLoginType(LOGIN_TYPES.MIT_ID);
                    },
                    stayOnPage: stayOnPrevPage,
                    redirectBackPath,
                    // PIN modal will pass the following 2 attributes to LOGIN_MOBILE modalData in case of closing PIN modal
                    onCommonLoginCloseModal: onCloseModal,
                    onCloseModalAutoHide,
                });
            }
        }

        disableBodyScroll();
        return () => {
            enableBodyScroll();
        };
    }, []);

    const onSignupClick = () => {
        browserHistory.push('/sport/opret-konto');
        hideModal('LOGIN_MOBILE');
    };
    const onClose = () => {
        hideModal('LOGIN_MOBILE');
        browserHistory.push('/sport/');
    };
    const onLoginClick = (userName, password) => {
        login(userName, password, stayOnPrevPage).then(() => {
            if (redirectBackPath) {
                browserHistory.push(redirectBackPath);
            }
        });
    };

    const forgotPassHandler = () => {
        hideModal('LOGIN_MOBILE');
        browserHistory.push('/sport/forgot-password');
    };

    return (
        <NewLogin
            closeAction={onClose}
            isMitIdLogin={loginType === LOGIN_TYPES.MIT_ID || !isItInitialLogin}
            bgImage={loginBanner}
            showRegisterModal={onSignupClick}
            loginHandler={onLoginClick}
            forgotPassHandler={forgotPassHandler}
            stayOnPrevPage={stayOnPrevPage}
            showResetPassword
            site={'sport'}
        />
    );
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        showModal: bindActionCreators(showModal, dispatch),
        login: bindActionCreators(login, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithClearSafariPageCache(LoginMobile));
