import React, { useEffect } from 'react';
import styled from 'styled-components';
import arrow from 'images/icons/arrowLeft.svg';
import Button from 'ui/Button';
import { bindActionCreators } from 'redux';
import { hideModal } from 'actions/UiActions';
import { connect } from 'react-redux';
import SportRadarWidget from './../components/SportRadarWidget';

const PopupWrapper = styled.div`
    display: block;
    padding: 20px 15px;
    position: relative;
    z-index: 50;
    .widget-container {
        height: auto;
        width: 100%;
        display: block;
        box-sizing: border-box;
    }
`;
const WidgetWrapper = styled.div`
    height: 300px;
    width: 100%;
    margin: 15px 0 0;
    display: block;
    background: #fff;
    border-radius: 5px;
`;
const ArrowLeft = styled.img`
    display: inline-block;
    margin-right: 5px;
`;

const SportRadarWidgetPopup = ({ hideModal, modalData }) => {
    return (
        <PopupWrapper>
            <Button
                margin="0"
                padding="10px 15px"
                fontSize="12px"
                onClick={() => {
                    hideModal('SPORT_RADAR_WIDGET_POPUP');
                }}
                lynch
            >
                <ArrowLeft src={arrow} />
                <span>Tilbage</span>
            </Button>
            <WidgetWrapper>
                <SportRadarWidget
                    externalId={modalData.externalId}
                    isLive={modalData.isLive}
                    layout={modalData.layout}
                />
            </WidgetWrapper>
        </PopupWrapper>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        hideModal: bindActionCreators(hideModal, dispatch),
    };
}

export default connect(
    null,
    mapDispatchToProps
)(SportRadarWidgetPopup);
