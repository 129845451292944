import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import UIModalNew from 'components/UIModalNew';
import { bindActionCreators } from 'redux';
import { hideModal } from 'actions/UiActions';
import Button from 'ui/Button';
import { browserHistory } from 'react-router';
import UserService from '../utils/UserService';

const Text = styled.p`
    font-size: 1rem;
    color: #818181;
    font-family: 'Roboto Condensed', sans-serif;
    padding-left: 10px;
`;
const Description = styled(Text)`
    margin: 19px 0;
    color: #333333;
`;

const DepositLimitReached = (props) => {
    const [userDepositLimits, setUserDepositLimits] = useState({});

    useEffect(() => {
        UserService.LegalLimit().then((res) => {
            if (res.data) {
                setUserDepositLimits(res.data);
            }
        });
    }, []);

    const onCloseModal = () => {
        props.hideModal('DEPOSIT_LIMIT_REACHED');
    };
    const openDepositLimits = () => {
        onCloseModal();
        browserHistory.push('/sport/konto/indbetalingsgraense');
    };

    return (
        <UIModalNew title="Det’ lige over grænsen!">
            <Description>
                Din indbetalingsgrænse er nået og du kan derfor i øjeblikket kun
                indbetale <b>{userDepositLimits.maxDeposit}</b> kr. Du kan altid
                hæve din grænse men ændringen træder første i kraft efter 24
                timer.
            </Description>
            <Button margin="10px 0" width="100%" onClick={openDepositLimits}>
                Ændre grænse
            </Button>
            <Button margin="10px 0" width="100%" active onClick={onCloseModal}>
                OK
            </Button>
        </UIModalNew>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        hideModal: bindActionCreators(hideModal, dispatch),
    };
}

export default connect(
    null,
    mapDispatchToProps
)(DepositLimitReached);
