import {
    SERVER_CONFIG_FETCH_ERROR,
    SERVER_CONFIG_FETCH_PENDING,
    SERVER_CONFIG_FETCH_SUCCESS,
    SERVER_GEO_FETCH_ERROR,
    SERVER_GEO_FETCH_PENDING,
    SERVER_GEO_FETCH_SUCCESS,
    SERVER_SEO_FETCH_ERROR,
    SERVER_SEO_FETCH_PENDING,
    SERVER_SEO_FETCH_SUCCESS,
} from 'constants/serverConfig';

import RestService from '../utils/RestService';

export function getServerConfig() {
    return (dispatch) => {
        dispatch({
            type: SERVER_CONFIG_FETCH_PENDING,
            payload: true,
        });

        RestService.GetConfig((data, error) => {
            if (error) {
                dispatch({
                    type: SERVER_CONFIG_FETCH_ERROR,
                    payload: error.toString(),
                });
            } else {
                dispatch({
                    type: SERVER_CONFIG_FETCH_SUCCESS,
                    payload: data,
                });
            }
        });
    };
}
