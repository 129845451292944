import { getVaixTrackingData } from './VaixDataHelper';
import VAIXService from '../../utils/VAIXService';

export const trackEvents = (
    events,
    eventType,
    location,
    page,
    userId,
    outcome,
    position,
    isCustomBetBuilder
) => {
    let data = getVaixTrackingData(
        events,
        eventType,
        location,
        page,
        userId,
        outcome,
        position,
        isCustomBetBuilder
    );

    try {
        VAIXService.createTrackerEvent(data);
    } catch (error) {
        console.error('Error tracking events:', error);
    }
};
