import {
    SHOP_CONFIG_ERROR,
    SHOP_CONFIG_PENDING,
    SHOP_CONFIG_SUCCESS,
} from '../constants/shopConfig';

const initialState = {
    pending: false,
    fetched: false,
    data: {
        startScreen: '',
        playAndPayAllowed: false,
        sections: {},
        allowedSectionsCount: 0,
        searchBarAllowed: false,
    },
};

export default function shopConfig(state = initialState, action) {
    switch (action.type) {
        case SHOP_CONFIG_PENDING:
            return {
                ...state,
                pending: true,
                fetched: false,
                error: false,
            };
        case SHOP_CONFIG_SUCCESS:
            const playAndPayAllowed = action.payload.playAndPayAllowed;
            const sections = action.payload.sections || {};
            const allowedSections = Object.values(sections).filter(
                (item) => item
            );
            const searchBarAllowed = action.payload.searchBarAllowed;
            const startScreen = action.payload.startScreen;
            const minOdds = action.payload.minOdds;

            return {
                ...state,
                pending: false,
                fetched: true,
                data: {
                    startScreen,
                    playAndPayAllowed,
                    sections,
                    allowedSectionsCount: allowedSections.length,
                    searchBarAllowed,
                    minOdds,
                },
                error: false,
            };
        case SHOP_CONFIG_ERROR:
            return {
                ...state,
                pending: false,
                fetched: false,
                data: {
                    playAndPayAllowed: false,
                    sections: {},
                    allowedSectionsCount: 0,
                },
                error: action.payload,
            };
        default:
            return state;
    }
}
