import {
    EVENT_OBJECT_CHECK_MARKETS,
    EVENT_OBJECT_FETCH_ERROR,
    EVENT_OBJECT_FETCH_PENDING,
    EVENT_OBJECT_FETCH_SUCCESS,
    EVENT_OBJECT_PATCH_DATA,
    EVENT_OBJECT_REMOVE_MARKET,
    EVENT_OBJECT_UPDATE_MARKET,
    EVENT_OBJECT_IS_UPDATED,
    EVENT_IS_FINISHED,
    EVENT_OBJECT_UPDATE_CUSTOMBET_MARKETS,
    EVENT_OBJECT_PENDING_CUSTOMBET_MARKETS,
    EVENT_OBJECT_UPDATE_STATUS,
    EVENT_OBJECT_RESET_MARKETS,
} from 'constants/eventObject';
import { ParsproService } from 'utils/ParsproService';
import TerminalRestService from 'utils/TerminalRestService';
import { isTerminal } from 'utils/UiHelper';
import CustomBetService from '../utils/CustomBetService';
import {
    CUSTOM_BET_ALLOWED_PRE_SPORT_ID,
    MARKET_TYPE_GROUPS_ID,
} from '../constants/appConfig';

let timer = null;

export function getEventObject(id) {
    return (dispatch) => {
        dispatch({
            type: EVENT_OBJECT_FETCH_PENDING,
            payload: true,
        });

        if (!isTerminal()) {
            ParsproService.getEventById(id)
                .then((res) => {
                    const betsData = res?.data;
                    if (betsData?.eventId && betsData?.marketList.length > 0) {
                        dispatch({
                            type: EVENT_IS_FINISHED,
                            payload: false,
                        });
                        if (
                            CUSTOM_BET_ALLOWED_PRE_SPORT_ID.includes(
                                betsData.sportId
                            )
                        ) {
                            console.log('id', id);
                            CustomBetService.getCustomBetOffer(id)
                                .then((res) => {
                                    if (
                                        res?.success &&
                                        res?.data?.markets?.length > 0
                                    ) {
                                        let customBetData = res.data;
                                        let customBetFormatedData = [];

                                        customBetData.markets.map((item) => {
                                            customBetFormatedData.push({
                                                marketTypeGroupName:
                                                    'Byg væddemål',
                                                marketTypeGroup:
                                                    MARKET_TYPE_GROUPS_ID.CUSTOM_BET,
                                                marketTypePriority:
                                                    item?.marketType?.priority,
                                                itemList: item?.itemList || [],
                                                conflict:
                                                    item?.conflict || false,
                                                freeText: item.freeText,
                                                marketId: item.marketId,
                                                marketName: item.marketName,
                                                status: item?.status || 0,
                                                marketType:
                                                    item?.marketType
                                                        ?.marketTypeGroupName,
                                            });
                                        });

                                        const data = {
                                            ...betsData,
                                            customBetMarkets: [
                                                ...customBetFormatedData,
                                            ],
                                        };

                                        if (data) {
                                            dispatch({
                                                type: EVENT_OBJECT_FETCH_SUCCESS,
                                                payload: data,
                                            });
                                        } else {
                                            dispatch({
                                                type: EVENT_OBJECT_FETCH_ERROR,
                                                payload: 'Ugyldig kamp',
                                            });
                                        }
                                    } else {
                                        if (betsData) {
                                            dispatch({
                                                type: EVENT_OBJECT_FETCH_SUCCESS,
                                                payload: betsData,
                                            });
                                        } else {
                                            dispatch({
                                                type: EVENT_OBJECT_FETCH_ERROR,
                                                payload: error.errorMessage,
                                            });
                                        }
                                    }
                                })
                                .catch((e) => {
                                    if (betsData) {
                                        dispatch({
                                            type: EVENT_OBJECT_FETCH_SUCCESS,
                                            payload: betsData,
                                        });
                                    }
                                });
                        } else {
                            if (betsData) {
                                dispatch({
                                    type: EVENT_OBJECT_FETCH_SUCCESS,
                                    payload: betsData,
                                });
                            }
                        }
                    } else {
                        if (betsData?.eventId > 0) {
                            dispatch({
                                type: EVENT_OBJECT_FETCH_SUCCESS,
                                payload: betsData,
                            });
                            dispatch({
                                type: EVENT_OBJECT_FETCH_PENDING,
                                payload: false,
                            });
                        } else {
                            dispatch({
                                type: EVENT_OBJECT_FETCH_PENDING,
                                payload: false,
                            });
                        }
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: EVENT_OBJECT_FETCH_ERROR,
                        payload: error.errorMessage,
                    });
                    dispatch({
                        type: EVENT_OBJECT_FETCH_PENDING,
                        payload: false,
                    });
                });
        } else {
            TerminalRestService.getEvent(id, (data) => {
                if (data) {
                    dispatch({
                        type: EVENT_OBJECT_FETCH_SUCCESS,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: EVENT_OBJECT_FETCH_ERROR,
                        payload: 'Ugyldig kamp',
                    });
                }
            });
        }

        if (timer) {
            window.clearInterval(timer);
        }

        timer = setInterval(() => {
            dispatch({
                type: EVENT_OBJECT_CHECK_MARKETS,
                payload: true,
            });
        }, 2000);
    };
}

export function updateCustomBetMarkets(id, isEmptyBetlist) {
    return (dispatch) => {
        dispatch({
            type: EVENT_OBJECT_PENDING_CUSTOMBET_MARKETS,
            payload: true,
        });
        if (!isTerminal()) {
            CustomBetService.getCustomBetOffer(id).then((res) => {
                if (
                    (res?.success && res?.data?.calc) ||
                    (res?.success && isEmptyBetlist)
                ) {
                    let markets = [];
                    markets = res.data.markets;
                    let formated = [];

                    //we do this filtering because we need to set them on the beginning but marketTypeGroup does not correspond to this order
                    markets.map((item) => {
                        formated.push({
                            marketTypeGroupName: 'Byg væddemål',
                            marketTypeGroup: MARKET_TYPE_GROUPS_ID.CUSTOM_BET,
                            marketTypePriority: item?.marketType?.priority,
                            itemList: item?.itemList || [],
                            conflict: item?.conflict || false,
                            freeText: item?.freeText,
                            marketId: item?.marketId,
                            marketName: item.marketName,
                            status: item?.status || 0,
                            marketType: item?.marketType?.marketTypeGroupName,
                        });
                    });

                    if (formated.length > 0) {
                        dispatch({
                            type: EVENT_OBJECT_UPDATE_CUSTOMBET_MARKETS,
                            payload: formated,
                        });
                    }
                    dispatch({
                        type: EVENT_OBJECT_PENDING_CUSTOMBET_MARKETS,
                        payload: false,
                    });
                } else {
                    dispatch({
                        type: EVENT_OBJECT_PENDING_CUSTOMBET_MARKETS,
                        payload: false,
                    });
                }
            });
        }
    };
}

export function updateMarket(data) {
    return (dispatch) => {
        dispatch({
            type: EVENT_OBJECT_IS_UPDATED,
            payload: false,
        });
        dispatch({
            type: EVENT_OBJECT_UPDATE_MARKET,
            payload: data,
        });
        dispatch({
            type: EVENT_OBJECT_IS_UPDATED,
            payload: true,
        });
    };
}

export function removeMarket(marketId) {
    return (dispatch) => {
        dispatch({
            type: EVENT_OBJECT_REMOVE_MARKET,
            payload: marketId,
        });
    };
}

export function updateEventStatus(data) {
    return (dispatch) => {
        dispatch({
            type: EVENT_OBJECT_UPDATE_STATUS,
            payload: data,
        });
    };
}

export function patchEventData(data) {
    return (dispatch) => {
        dispatch({
            type: EVENT_OBJECT_PATCH_DATA,
            payload: data,
        });
    };
}
export function resetMarkets() {
    return (dispatch) => {
        dispatch({
            type: EVENT_OBJECT_RESET_MARKETS,
        });
    };
}
