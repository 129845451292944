import { createState, useState } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';

const STORAGE_KEY_PREFIX = 'SK.tweaks';

const initialState = {
    selectedGroup: createState(null),
    createPoolWithCouponMode: createState(false),
    uiGroupId: createState(0),
    backButtonHidden: createState(false),
};

const useTweaks = () => {
    const createPoolWithCouponMode = useState(
        initialState.createPoolWithCouponMode
    );
    const enablePoolWithCoupon = () => createPoolWithCouponMode.set(true);
    const disablePoolWithCoupon = () => createPoolWithCouponMode.set(false);

    const uiGroupId = useState(initialState.uiGroupId);
    uiGroupId.attach(Persistence(`${STORAGE_KEY_PREFIX}.uiGroupId`));
    const setUIGroupId = (groupId) => uiGroupId.set(groupId);

    return {
        createPoolWithCouponMode: createPoolWithCouponMode.get(),
        enablePoolWithCoupon,
        disablePoolWithCoupon,

        uiGroupId: uiGroupId.get(),
        setUIGroupId,
    };
};

export default useTweaks;
