import React, { useEffect } from 'react';
import {
    EffortsWrapper,
    ResultWrapper,
    ShareWrapper,
} from 'features/Big9History/styled';
import Text from 'ui/Text';
import { formatDenmarkCurrency } from 'utils/UiHelper';
import Wrapper from '../../ui/Wrapper';
import { HISTORY_STATUSES } from '../../constants/historyStatuses';

const BettingHistoryResults = ({
    spilklub = false,
    winResults = false,
    win = false,
    amountWin = 0,
    amount = 0,
    coupon = false,
    isTerminal = false,
    amountForTerminal = 0,
    isCouponPlaced = true,
    isPayoutInShop = false,
    status = 0,
    isCanceledCoupon = false,
}) => {
    const isWinCoupon = () => {
        return (
            (amountWin > 0 && isCouponPlaced && isPayoutInShop) ||
            (spilklub && isCouponPlaced)
        );
    };

    return (
        <ResultWrapper spilklub={spilklub && winResults} coupon={coupon}>
            <Wrapper
                boxSizing={'border-box'}
                width={'100%'}
                padding={'0 25px 16px 0 '}
                margin={'0'}
                flex
                justify={'space-between'}
                flexAlign={'center'}
                borderBottom={'1px solid #d6e1ea'}
            >
                <ShareWrapper>
                    <Text
                        size="x07"
                        color={'#9198AA'}
                        condensed
                        padding="0 0 5px 0"
                    >
                        {!spilklub
                            ? 'Indsats'
                            : winResults
                                ? 'Din gevinst'
                                : 'Din andel'}
                    </Text>
                    <Text
                        size="x15"
                        color={spilklub && win ? '#18AA1B' : '#333333'}
                        condensed
                        padding="0"
                    >
                        {formatDenmarkCurrency(amount)} kr.
                    </Text>

                    {isTerminal && (
                        <Text
                            size="x07"
                            color="#333333"
                            condensed
                            padding="0 0 5px 0"
                            margin={'5px 0 0'}
                        >
                            {`Rækkepris: ${formatDenmarkCurrency(
                                amountForTerminal
                            )} kr.`}
                        </Text>
                    )}
                </ShareWrapper>
                {isWinCoupon() && (
                    <EffortsWrapper>
                        <Text
                            size="x07"
                            color={'#9198AA'}
                            condensed
                            padding="0 0 5px 0"
                            align="right"
                        >
                            {!spilklub || coupon
                                ? 'Gevinst'
                                : winResults
                                    ? 'Holdets gevinst'
                                    : 'Holdets indsats'}
                        </Text>
                        <Text
                            size="x15"
                            color={spilklub && win ? '#18AA1B' : '#333333'}
                            condensed
                            padding="0"
                            align="right"
                            bold
                        >
                            {formatDenmarkCurrency(amountWin)} kr.
                        </Text>
                    </EffortsWrapper>
                )}
            </Wrapper>

            {!spilklub &&
                !winResults &&
                !isPayoutInShop && (
                    <Wrapper
                        width={'100%'}
                        boxSizing={'border-box'}
                        padding={'16px 25px 16px 0 '}
                        margin={'0'}
                        flex
                        justify={'space-between'}
                        flexAlign={'center'}
                        borderBottom={'1px solid #d6e1ea'}
                    >
                        <Wrapper
                            boxSizing={'border-box'}
                            padding={'0'}
                            margin={'0'}
                            flex
                            flexDirection={'column'}
                            flexAlign={'flex-start'}
                            width={'50%'}
                        >
                            <Text
                                size="x07"
                                color={'#9198AA'}
                                condensed
                                padding="0 0 5px 0"
                            >
                                Status
                            </Text>
                            <Text
                                size="x15"
                                color={spilklub && win ? '#18AA1B' : '#333333'}
                                condensed
                                padding="0"
                            >
                                {isCanceledCoupon
                                    ? 'Annulleret'
                                    : HISTORY_STATUSES[status]}
                            </Text>
                        </Wrapper>
                        {(amountWin > 0 ||
                            isCanceledCoupon ||
                            HISTORY_STATUSES[status] ===
                                HISTORY_STATUSES[3]) && (
                            <Wrapper
                                width={'50%'}
                                padding={'0'}
                                margin={'0'}
                                flex
                                flexDirection={'column'}
                                flexAlign={'flex-end'}
                                boxSizing={'border-box'}
                            >
                                <Text
                                    size="x07"
                                    color={'#9198AA'}
                                    condensed
                                    padding="0 0 5px 0"
                                >
                                    {isCanceledCoupon
                                        ? 'Indsats retur'
                                        : 'Gevinst'}
                                </Text>
                                <Text
                                    size="x15"
                                    color={
                                        spilklub && win
                                            ? '#18AA1B'
                                            : amountWin > 0 || isCanceledCoupon
                                                ? '#333333'
                                                : '#9198AA'
                                    }
                                    condensed
                                    padding="0"
                                    bold
                                >
                                    {isCanceledCoupon
                                        ? `${formatDenmarkCurrency(amount)} kr.`
                                        : HISTORY_STATUSES[3] ===
                                          HISTORY_STATUSES[status]
                                            ? '-'
                                            : `${formatDenmarkCurrency(
                                                  amountWin
                                              )} kr.`}
                                </Text>
                            </Wrapper>
                        )}
                    </Wrapper>
                )}
        </ResultWrapper>
    );
};

export default BettingHistoryResults;
