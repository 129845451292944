import {
    TERMINAL_BETSLIP_ADD_MARKET,
    TERMINAL_BETSLIP_ERROR,
    TERMINAL_BETSLIP_PENDING,
    TERMINAL_BETSLIP_PROCEED,
    TERMINAL_BETSLIP_REMOVE_MARKET,
    TERMINAL_BETSLIP_RESET,
    TERMINAL_BETSLIP_SAVE_UNPAID,
    TERMINAL_BETSLIP_SET_LIVE_MARKETS,
    TERMINAL_BETSLIP_SET_MARKET,
    TERMINAL_BETSLIP_SET_SYSTEM_MARKET,
    TERMINAL_BETSLIP_SET_VALUE,
    TERMINAL_BETSLIP_UPDATE_MARKET,
} from 'constants/terminalBetslip';
import { UI_ERROR_MESSAGE } from 'constants/ui';
import TerminalRestService from 'utils/TerminalRestService';
import { detectError } from 'utils/Helper';
import { convertFloatToInt } from 'utils/UiHelper';

export function addMarket(
    betslip,
    eventId,
    eventName,
    marketId,
    betNr,
    stake,
    odds,
    betText,
    marketName,
    isLive,
    event
) {
    const sport = event && event.data ? event.data.sport : event.sport;
    const competition =
        event && event.data ? event.data.competition : event.competition;
    const groupName =
        event && event.data ? event.data.groupName : event.groupName;

    event = {
        sport,
        competition,
        groupName,
    };

    return (dispatch) => {
        dispatch({
            type: TERMINAL_BETSLIP_ADD_MARKET,
            payload: {
                eventId,
                marketId,
                betNr,
                stake,
                odds,
                gameId: marketId,
                marketName,
                betText,
                eventName,
                live: isLive,
                event: event,
            },
        });
    };
}

export function removeMarket(marketId) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_BETSLIP_REMOVE_MARKET,
            payload: marketId,
        });
    };
}

export function clearAllMarkets() {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_BETSLIP_RESET,
            payload: true,
        });
    };
}

export function setMarket(value, odds) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_BETSLIP_SET_MARKET,
            payload: {
                value,
                odds,
            },
        });
    };
}

export function setSystemMarket(value, index) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_BETSLIP_SET_SYSTEM_MARKET,
            payload: {
                value,
                index,
            },
        });
    };
}

export function proceed(
    betModel,
    isAcceptedBetslipChanges = false,
    isRememberCoupon = false,
    isPlayAndPay,
    minOdds = 1.2
) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_BETSLIP_PENDING,
            payload: true,
        });

        let betList = [];
        let isSimpleAccum = false;
        let acumStake = 0;
        let totalStake = 0;

        let minValue = 1000;
        let maxValue = 100000;

        let isValid = true;
        let isLive = false;

        if (betModel.totalOdds < minOdds) {
            dispatch({
                type: TERMINAL_BETSLIP_ERROR,
                payload: 'MIN_ODDS',
            });
            dispatch({
                type: UI_ERROR_MESSAGE,
                payload: 'Det kræves minimum et samlet total odds: ' + minOdds,
            });
            return;
        }

        if (betModel.betList.length > 1) {
            console.info(betModel);
            // check for minimum odds if system contains Singler bets
            if (betModel.system.stake[0] > 0) {
                var minOddsFound = false;
                betModel.betList.forEach(function(v, i) {
                    if (v.odds < minOdds) {
                        minOddsFound = true;
                        return false;
                    }
                });
                if (minOddsFound === true) {
                    dispatch({
                        type: TERMINAL_BETSLIP_ERROR,
                        payload: 'MIN_ODDS',
                    });
                    dispatch({
                        type: UI_ERROR_MESSAGE,
                        payload:
                            'Det kræves minimum et samlet total odds: ' +
                            minOdds,
                    });
                    return;
                }
            }

            isSimpleAccum = true;

            betModel.system.names.map((name, index) => {
                if (
                    index < betModel.system.names.length - 1 &&
                    betModel.system.stake[index]
                ) {
                    isSimpleAccum = false;
                }

                return name;
            });

            if (isSimpleAccum) {
                acumStake =
                    betModel.system.stake[betModel.system.names.length - 1];
            }
        }

        /** save unchanged betModel for unpaid-sale request below */
        const savedBetModel = Object.assign({}, betModel);
        const singleStake = betModel.singleStake;
        const outcomes = [];
        const domOutcomes = document.querySelectorAll('.betslip-outcome');

        betModel.betList.map((bet, i) => {
            let outcome = {};
            outcome[bet.marketId] =
                domOutcomes.length && domOutcomes[i]
                    ? domOutcomes[i].innerText
                    : null;
            outcomes.push(outcome);

            let serverBet = {};

            serverBet['marketId'] = bet.marketId;
            serverBet['betNr'] = bet.betNr;
            serverBet['odds'] = bet.odds;

            if (bet.live && bet.live !== '0') {
                isLive = true;
            }

            if (!isSimpleAccum) {
                serverBet['stake'] = convertFloatToInt(
                    betModel.betList.length > 1
                        ? betModel.system.stake[i]
                        : singleStake
                );

                if (
                    serverBet['stake'] !== 0 &&
                    (serverBet['stake'] < minValue ||
                        serverBet['stake'] > maxValue)
                ) {
                    isValid = false;
                }
            }

            betList.push(serverBet);

            return bet;
        });

        let betBody = {
            betList: betList,
            type: isSimpleAccum ? 0 : betModel.betList.length > 1 ? 2 : 1,
            currency: 'DKK',
        };

        if (isSimpleAccum) {
            betBody['stake'] = convertFloatToInt(acumStake);

            if (
                betBody['stake'] !== 0 &&
                (betBody['stake'] < minValue || betBody['stake'] > maxValue)
            ) {
                isValid = false;
            }
        }

        if (isValid) {
            totalStake = convertFloatToInt(
                betList.length > 1 ? betModel.system.totalStake : singleStake
            );
            if (totalStake <= 0) {
                showLowAmountMessage(dispatch);

                return;
            }

            let betslip = {
                outcomes: outcomes,
                live: isLive,
                body: betBody,
                session_id: window.localStorage.getItem('token'),
                totalOdds: betModel.totalOdds,
                totalStake,
                singleStake: convertFloatToInt(singleStake),
                singlePrize: betModel.singlePrize,
            };

            /** condition for PlayAndPay workflow, when user plays without money */
            if (isPlayAndPay) {
                const data = {
                    hostname: window.localStorage.getItem('printerHost'),
                    payload: {
                        betslipSale: betslip,
                        betslip: savedBetModel,
                    },
                    type: 'bet25',
                };

                TerminalRestService.newUnpaidSale(data, (response) => {
                    if (response?.success || response?.response?.success) {
                        if (!isRememberCoupon) {
                            dispatch({
                                type: TERMINAL_BETSLIP_RESET,
                                payload: response.data,
                            });
                        } else {
                            dispatch({
                                type: TERMINAL_BETSLIP_SAVE_UNPAID,
                                payload: response.data,
                            });
                        }
                    } else {
                        dispatch({
                            type: UI_ERROR_MESSAGE,
                            payload: ((response || {}).response || {})
                                .error_message,
                        });
                        dispatch({
                            type: TERMINAL_BETSLIP_ERROR,
                            payload: '',
                        });
                    }
                });
            } else {
                TerminalRestService.newSale(betslip, isAcceptedBetslipChanges)
                    .then((res) => {
                        if (res.success) {
                            dispatch({
                                type: TERMINAL_BETSLIP_PROCEED,
                                payload: { res, isRememberCoupon },
                            });
                        } else {
                            dispatch({
                                type: TERMINAL_BETSLIP_SET_VALUE,
                                payload: {
                                    pending: false,
                                },
                            });
                            dispatch({
                                type: UI_ERROR_MESSAGE,
                                payload: detectError(res.error_message),
                            });
                            dispatch({
                                type: TERMINAL_BETSLIP_ERROR,
                                payload: res.error_message,
                            });
                        }
                    })
                    .catch((error) => {
                        dispatch({
                            type: TERMINAL_BETSLIP_ERROR,
                            payload: error.statusText,
                        });
                        dispatch({
                            type: UI_ERROR_MESSAGE,
                            payload:
                                error.statusText === 'error'
                                    ? 'Netværksforbindelsen fejler.'
                                    : error.statusText,
                        });
                    });
            }

            if (isLive) {
                dispatch({
                    type: TERMINAL_BETSLIP_SET_VALUE,
                    payload: {
                        timer: null,
                    },
                });
            }
        } else {
            showLowAmountMessage(dispatch);
        }
    };
}

function showLowAmountMessage(dispatch) {
    dispatch({
        type: TERMINAL_BETSLIP_SET_VALUE,
        payload: {
            pending: false,
        },
    });

    dispatch({
        type: UI_ERROR_MESSAGE,
        payload: 'Indsats er ugyldig',
    });
}

export function acceptMarkets() {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_BETSLIP_SET_VALUE,
            payload: {
                changed: false,
            },
        });
    };
}

export const updateMarketsWithOddsChangeCheck = (events) => (
    dispatch,
    getState
) => {
    const { betslip } = getState();
    events = events && events.length ? events : [];

    events.forEach((event) =>
        event.marketList
            .filter((market) => betslip.hasMarkets.includes(market.marketId))
            .forEach((market) => {
                const bet = betslip.betList.find(
                    ({ gameId }) => gameId === market.marketId
                );
                const marketItem = market.itemList.find(
                    (item) => item.betNr === bet.betNr
                );
                if (marketItem.odds !== bet.odds) {
                    updateMarket(betslip, market)(dispatch);
                }
            })
    );
};

export function updateMarket(betslip, data, eventId, sportId) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_BETSLIP_UPDATE_MARKET,
            payload: {
                data,
                eventId,
                sportId,
            },
        });
    };
}

export function resetSuccess() {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_BETSLIP_SET_VALUE,
            payload: {
                pending: false,
                success: false,
            },
        });
    };
}

export function setLiveMarkets(markets) {
    return (dispatch) => {
        dispatch({
            type: TERMINAL_BETSLIP_SET_LIVE_MARKETS,
            payload: markets,
        });
    };
}
