import React from 'react';
import ParentAuthPage from './ParentAuthPage';
import { ViewPasswordInput, ViewTextInput } from 'components/ViewInput';
import UserService from 'utils/UserService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthActions from 'actions/AuthActions';
import * as UiActions from 'actions/UiActions';
import { getErrorByCode } from 'utils/Helper';
import classNames from 'classnames';
import 'css/BrugerinfoPage.css';
import Button from 'ui/Button';
import { detectMobile } from 'utils/UiHelper';
import { browserHistory } from 'react-router';
import { NotificationManager } from 'react-notifications';
/*const PASSWORD = 'password';
const disallowedChars = /[^!&;%\\]/g;
const passwordRegex = /^(([a-z]?)(?=.*[A-Z])(?=.*[0-9]))(?=.{10,})/;

const validatePassword = (value) => {
    let valid = true;

    if (!passwordRegex.test(value)) {
        valid = false;
        setError(
            'Kodeord skal minimum indeholde 1 stort bogstav, 1 tal og 10 tegn'
        );
    }
    if (value.match(disallowedChars)?.length !== value?.length) {
        valid = false;
        setError('Kodeord må ikke indeholde ! & ; \\ %');
    }
};*/

class BrugerinfoPage extends ParentAuthPage {
    fieldsValidator = {};
    fieldsValidator2 = {};

    constructor() {
        super();

        this.state = {
            ...this.state,
            address: '',
            addressRequest: '',
            zip: '',
            zipRequest: '',
            city: '',
            cityRequest: '',
            email: '',
            phoneNr: '',
            oldPwd: '',
            newPwd: '',
            rptPwd: '',
            showPasswordError: false,
        };
    }

    componentDidMount() {
        this.setPageTitle('Brugerinfo');

        UserService.InfoGet().then((response) => {
            const res = response.data;
            this.setState({
                address: res?.address || '',
                addressRequest: res?.address || '',
                zip: res?.zip || '',
                zipRequest: res?.zip || '',
                city: res?.city || '',
                cityRequest: res?.city || '',
                email: res?.email || '',
                phoneNr: res?.phoneNr || '',
            });
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.auth.user && this.props.auth.sessionError) {
            browserHistory.push('/sport/');
            this.props.uiActions.showModal('LOGIN_FIRST');
        }
    }

    checkHandler(key, fn) {
        this.fieldsValidator[key] = fn;
    }

    checkHandler2(key, fn) {
        this.fieldsValidator2[key] = fn;
    }

    validateFields() {
        Object.keys(this.fieldsValidator).map((key) =>
            this.fieldsValidator[key]()
        );
    }

    validateFields2() {
        Object.keys(this.fieldsValidator2).map((key) =>
            this.fieldsValidator2[key]()
        );
    }

    valueHandler(key, val) {
        this.setState({ [key]: val });

        if (this.state.successMessage || this.state.errorMessage) {
            this.setState({
                successMessage: null,
                errorMessage: null,
            });
        }
    }

    onClickHandler(index) {
        if (index === 1) {
            this.validateFields();

            const address = this.state.address;
            const zip = this.state.zip;
            const city = this.state.city;
            const email = this.state.email;
            const phoneNr = this.state.phoneNr;

            if (email && phoneNr) {
                let data = { address, zip, city, email, phoneNr };
                UserService.InfoSet(data)
                    .then((res) => {
                        if (res.success) {
                            NotificationManager.success(
                                'Dine ændringer er bekræftet'
                            );
                            this.setState({
                                addressRequest: address,
                                zipRequest: zip,
                                cityRequest: city,
                            });
                        } else {
                            NotificationManager.error(
                                getErrorByCode(res.errorMessage)
                            );
                        }
                    })
                    .catch((error) => {
                        NotificationManager.error(getErrorByCode(error));
                    });
            } else {
                NotificationManager.error('Udfyld venligst alle data');
            }
        } else {
            this.validateFields2();

            const oldPwd = this.state.oldPwd;
            const newPwd = this.state.newPwd;
            const rptPwd = this.state.rptPwd;

            if (oldPwd && newPwd && rptPwd) {
                if (newPwd.length < 10) {
                    this.setState({
                        showPasswordError: true,
                    });
                } else {
                    this.setState({
                        showPasswordError: false,
                    });
                    if (newPwd === rptPwd) {
                        UserService.ChangePassword({
                            current: oldPwd,
                            edit: newPwd,
                            confirm: rptPwd,
                        }).then((res) => {
                            if (res.success) {
                                this.setSuccessMessage(
                                    'Dit kodeord er nu ændret'
                                );
                                this.setState({
                                    oldPwd: '',
                                    newPwd: '',
                                    rptPwd: '',
                                });
                            } else {
                                this.setErrorMessage(
                                    getErrorByCode(res.errorMessage)
                                );
                            }
                        });
                    } else {
                        this.setErrorMessage(
                            'Ny kode og gentagelse er ikke ens.'
                        );
                    }
                }
            }
        }
    }

    render() {
        if (this.props.auth.user) {
            return (
                <React.Fragment>
                    {this.getHeaderView(() => {
                        this.props.uiActions.showModal('USER_MENU');
                    })}
                    <div
                        className={classNames('brugerinfo-page', {
                            mobile: detectMobile(),
                        })}
                    >
                        <div className="header">Ret dine oplysninger</div>

                        <div className="fields-container">
                            <div className="row">
                                <ViewTextInput
                                    name="address"
                                    placeholderLabel="Adresse"
                                    disabled={
                                        this.state.addressRequest.length > 0
                                    }
                                    validate={'empty'}
                                    value={this.state.address}
                                    checkHandler={this.checkHandler.bind(this)}
                                    valueHandler={this.valueHandler.bind(this)}
                                />
                                <ViewTextInput
                                    name="zip"
                                    placeholderLabel="Postnr"
                                    value={this.state.zip}
                                    validate={'empty'}
                                    disabled={this.state.zipRequest.length > 0}
                                    checkHandler={this.checkHandler.bind(this)}
                                    valueHandler={this.valueHandler.bind(this)}
                                />
                            </div>
                            <div className="row">
                                <ViewTextInput
                                    name="city"
                                    placeholderLabel="By"
                                    value={this.state.city}
                                    validate={'empty'}
                                    disabled={this.state.cityRequest.length > 0}
                                    checkHandler={this.checkHandler.bind(this)}
                                    valueHandler={this.valueHandler.bind(this)}
                                />
                                <ViewTextInput
                                    name="phoneNr"
                                    placeholderLabel="Mobil"
                                    value={this.state.phoneNr}
                                    checkHandler={this.checkHandler.bind(this)}
                                    valueHandler={this.valueHandler.bind(this)}
                                />
                            </div>
                            <ViewTextInput
                                name="email"
                                placeholderLabel="E-mail"
                                value={this.state.email}
                                checkHandler={this.checkHandler.bind(this)}
                                valueHandler={this.valueHandler.bind(this)}
                            />
                        </div>

                        <div className="info">
                            Hvis du ændrer din adresse, skal du efterfølgende
                            indsende et bevis for den nye adresse, enten i form
                            af et billede af dit nye sygesikringsbevis, eller
                            ved at sende en husstandsregning til &nbsp;
                            <a
                                href="mailto:support@bet25.dk"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                support@bet25.dk
                            </a>
                        </div>

                        <Button
                            active
                            padding="15px 50px"
                            margin="10px auto"
                            onClick={this.onClickHandler.bind(this, 1)}
                        >
                            Ret
                        </Button>

                        <div className="header">Adgangskode til mobil</div>
                        <div className="info">
                            Skal være mindst 10 tegn og indeholde et stort
                            bogstav og 1 tal
                        </div>

                        {this.state.errorMessage
                            ? this.getErrorView(this.state.errorMessage)
                            : null}
                        {this.state.successMessage
                            ? this.getSuccessView(this.state.successMessage)
                            : null}

                        <div className="fields-container">
                            <div className="current-pass">
                                <ViewPasswordInput
                                    name="oldPwd"
                                    placeholder="Nuværende kode"
                                    placeholderLabel=" "
                                    checkHandler={this.checkHandler2.bind(this)}
                                    valueHandler={this.valueHandler.bind(this)}
                                />
                            </div>
                            <div className="row">
                                <div>
                                    <ViewPasswordInput
                                        name="newPwd"
                                        placeholder="Ny kode"
                                        placeholderLabel=" "
                                        checkHandler={this.checkHandler2.bind(
                                            this
                                        )}
                                        valueHandler={this.valueHandler.bind(
                                            this
                                        )}
                                    />
                                    {this.state.showPasswordError && (
                                        <div className="info red">
                                            Kodeord skal minimum indeholde 10
                                            tegn
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <ViewPasswordInput
                                        name="rptPwd"
                                        placeholder="Gentag ny kode"
                                        placeholderLabel=" "
                                        checkHandler={this.checkHandler2.bind(
                                            this
                                        )}
                                        valueHandler={this.valueHandler.bind(
                                            this
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="p10">
                            <Button
                                padding="15px 50px"
                                active
                                margin="10px auto"
                                onClick={this.onClickHandler.bind(this, 2)}
                            >
                                Ret
                            </Button>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <div className="brugerinfo-page pb80">
                    {this.getHeaderView(() => {
                        this.props.uiActions.showModal('USER_MENU');
                    })}

                    {this.getErrorView('Du skal først logge ind')}
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return { auth: state.auth };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BrugerinfoPage);
