import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { browserHistory, withRouter } from 'react-router';
import * as SportTreeActions from 'actions/SportTreeActions';
import * as AuthActions from 'actions/AuthActions';
import * as UiActions from 'actions/UiActions';
import AppToolbar from 'components/AppToolbar';
import 'css/Layout.scss';
import VerificationModal from 'modals/VerificationModal';
import BetslipRecieptModal from 'modals/BetslipRecieptModal';
import classNames from 'classnames';
import { ProductsTransitionBar } from '@it25syv/25syv-ui';
import styled from 'styled-components';
import rofus from 'images/footer/spillemyndigheden.png';
import betImg from 'images/bet.png';
import rodImg from 'images/top-bar/rod.png';
import derbyImg from 'images/derby.png';
// import appLogo from 'images/sport_app_logo.png';
import { desktopOnly, detectMobile, isTerminal } from 'utils/UiHelper';
import MobileModals from 'modals/MobileModals';
import { debounce } from 'utils/Helper';
import BottomNavigationMenu from 'features/BottomNavigation/BottomNavigationMenu';
import CreateGroupButton from 'features/SpilKlub/components/CreateGroupButton';

const FixedWrapper = styled.div`
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
`;

const StyledProductsTransitionBar = styled(ProductsTransitionBar)`
    z-index: 10;
    box-sizing: border-box;
    padding: 0 8px;

    span,
    div,
    svg {
        color: white;
        @media screen and (max-width: 370px) {
            font-size: 10px;
        }
        @media screen and (max-width: 350px) {
            font-size: 9px;
        }
    }
    img {
        @media screen and (max-width: 370px) {
            height: 12px;
            width: auto;
        }
    }
    svg {
        background: white;
        @media screen and (max-width: 370px) {
            height: 16px;
            width: 12px;
        }
    }
`;

class Layout extends Component {
    constructor() {
        super();

        this.state = {
            leftSidebar: '',
            screenWidth: 0,
            activeMenuItem: '',
            isDisabledMineSpil: true,
            activeBets: 0,
        };

        this.layoutRef = React.createRef();
        this.topbarRef = React.createRef();
        this.headerRef = React.createRef();
    }

    userBtnsClickHandler() {
        const currentPath = window.location.pathname;
        const kontoBasePath = '/sport/konto';
        if (!currentPath.startsWith(kontoBasePath)) {
            browserHistory.push(`${kontoBasePath}`);
        }
    }

    debouncedResizeHandler = debounce(() => {
        this.setState({ screenWidth: window.innerWidth });
    }, 100);

    componentWillMount() {
        this.props.sportTreeAction.getSportTree();

        if (location.pathname.indexOf('/mitid-verify/confirm') > -1) {
            const idp = location.pathname.split('/').reverse()[0]; // todo rework ???
            this.props.uiActions.showModal('CPR_CONFIRMATION', { idp });
        }
        if (
            window.location.pathname === '/log-ind' ||
            window.location.pathname === '/sport/log-ind' ||
            window.location.pathname === '/sport/testing/log-ind'
        ) {
            this.props.uiActions.showModal('LOGIN_MOBILE');
        }

        if (window.location.pathname === '/opret-bruger') {
            this.props.uiActions.setSignupModalStatus(true);
        }
    }
    componentDidMount() {
        window.addEventListener('resize', this.debouncedResizeHandler);

        window.Intercom('boot', {
            app_id: 'qkqulpbi',
            last_active_on: 'bet25_mob',
            hide_default_launcher: true,
        });

        this.setState({
            activeMenuItem: this.checkPath(),
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.auth.user &&
            !this.props.auth.user &&
            this.props.auth.sessionError
        ) {
            this.props.uiActions.showModal('SESSION_POPUP');
        }

        if (prevState.activeMenuItem !== this.checkPath()) {
            this.setState({
                activeMenuItem: this.checkPath(),
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedResizeHandler);
    }

    checkPath() {
        const urlParts = window.location.pathname.split('/').filter((e) => e);
        if (urlParts.length > 1) {
            return `/${urlParts[0]}/${urlParts[1]}`;
        } else {
            return `/${urlParts[0]}`;
        }
    }

    render() {
        const getTopbarHeight = (withHeader = false) => {
            return withHeader
                ? this.topbarRef?.current?.clientHeight +
                      this.headerRef?.current?.clientHeight ?? 140
                : this.topbarRef?.current?.clientHeight ?? 80;
        };
        const authUser = this.props.auth.user;
        const loginTime =
            authUser && authUser.extraInfo
                ? authUser.extraInfo.loginTime
                : null;

        const paddingClass = this.props.betlist.length
            ? 'additional-padding'
            : '';

        const isTWA = Boolean(JSON.parse(window.localStorage.getItem('isTWA')));

        return (
            <div
                ref={this.layoutRef}
                className={classNames({
                    mobile: detectMobile(),
                    desktop: desktopOnly(),
                    terminal: isTerminal(),
                })}
            >
                <div className="layout">
                    <FixedWrapper ref={this.topbarRef}>
                        <StyledProductsTransitionBar
                            px={2}
                            rodLink={'/casino'}
                            derbyLink={'/heste'}
                            betLink={'/sport'}
                            logoOnClickHandler={() =>
                                browserHistory.push('/sport')
                            }
                            betMobLink="/"
                            rofusLogo={rofus}
                            lastLoginTime={loginTime}
                            showTimer={loginTime}
                            clockFormat={'HH:mm'}
                            timerFormat={'HH:mm'}
                            betImage={betImg}
                            rodImage={rodImg}
                            derbyImage={derbyImg}
                            activeRodColor={'#c63328'}
                            activeBetColor={'#1C3E60'}
                            activeDerbyColor={'#299648'}
                            activeProject={'bet'}
                            background={'#1C3E60'}
                            color={'#c1afaf'}
                            hideMiddlePart={isTWA}
                        />
                    </FixedWrapper>
                    <AppToolbar
                        style={{ top: getTopbarHeight() + 'px' }}
                        headerRef={this.headerRef}
                        /*burgerMenuHandler={this.burgerMenuHandler.bind(this)}*/
                        onUserBtnsClick={this.userBtnsClickHandler.bind(this)}
                        isTWA={isTWA}
                        // appLogo={appLogo} return after fixing in new ui package version
                    />

                    <div
                        style={{
                            marginTop: getTopbarHeight(true) + 'px',
                        }}
                        className={'layout-content ' + paddingClass}
                    >
                        {this.props.children}
                    </div>
                    <MobileModals parent={this.layoutRef} />
                </div>
                <BetslipRecieptModal />

                {this.props.auth.user && (
                    <VerificationModal user={this.props.auth.user} />
                )}

                {detectMobile() ? (
                    <BottomNavigationMenu
                        auth={this.props.auth}
                        activeMenuItem={this.state.activeMenuItem}
                    />
                ) : null}

                {detectMobile() &&
                    (window.location.pathname === '/sport/big9/spil' ||
                        window.location.pathname === '/sport/big9/spil/') && (
                        <CreateGroupButton />
                    )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        betslip: state.betslip,
        lastResult: state.betslip.lastResult,
        betlist: state.betslip.betList,
        sportTree: state.sportTree,
        auth: state.auth,
        successBetslipPopupStatus: state.ui.successBetslipPopupStatus,
        serverConfig: state.serverConfig,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sportTreeAction: bindActionCreators(SportTreeActions, dispatch),
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Layout)
);
