import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UiActions from 'actions/UiActions';
import virtuelImg from 'images/virtuel-transfer.svg';
import { browserHistory } from 'react-router';
import {
    calculateOddsDisplay,
    calculateTotalWin,
} from '../../helpers/RetailTransferHelpers/TransferDataHelper.js';
import {
    renderIcon,
    renderStatus,
    getStatusBorder,
    systemNames,
} from '../../helpers/RetailTransferHelpers/TransferRender.js';
import PayoutStatusComponent from 'components/TransferRetail/PayoutStatusComponent';
import './styled.scss';
import Wrapper from '../../ui/Wrapper';
import {
    formatDenmarkCurrency,
    isCanceledCoupon,
    showRetailTransferUi,
} from '../../utils/UiHelper';

const VirtualTransfer = ({ data, uiActions, retailSalesHistory }) => {
    const isHomeDrawAway = (marketName, selection, selectionText) => {
        const validSelections = ['1', 'X', '2'];
        if (!validSelections.includes(selectionText)) {
            return false;
        }

        const selectionIndex = validSelections.indexOf(selectionText) + 1;
        return selectionIndex === Number(selection);
    };

    const homeDrawAwayName = (eventName, selection, defaultValue) => {
        const competitors = eventName.split(' v ');
        if (competitors.length === 2) {
            switch (selection) {
                case '1':
                    return competitors[0];
                case 'X':
                    return 'Uafgjort';
                case '2':
                    return competitors[1];
                default:
                    return 'Uafgjort';
            }
        }
        return defaultValue;
    };

    const isOverUnder = (marketName, selectionText) => {
        return (
            marketName.includes('O/U') &&
            (selectionText === 'O' || selectionText === 'U')
        );
    };

    const overUnderName = (selectionText, defaultValue) => {
        switch (selectionText) {
            case 'O':
                return 'Over';
            case 'U':
                return 'Under';
            default:
                return defaultValue;
        }
    };

    const getBetName = (marketName, eventName, selection, selectionText) => {
        if (isHomeDrawAway(marketName, selection, selectionText)) {
            return homeDrawAwayName(eventName, selectionText, selectionText);
        }

        if (isOverUnder(marketName, selectionText)) {
            return overUnderName(selectionText, selectionText);
        }

        return selectionText;
    };

    const getResultName = (marketName, eventName, result, selectionText) => {
        if (result === 'X') {
            return 'Uafgjort';
        }
        if (isHomeDrawAway(marketName, result, selectionText)) {
            return homeDrawAwayName(eventName, result, result);
        }
        return result;
    };

    const goToEventPage = (eventId) => {
        browserHistory.push(`/sport/kamp/${eventId}`);
        uiActions.hideModal('USER_MENU');
    };
    return (
        <>
            {data.map((item, index) => {
                const betCounts = {};
                const uniqueMarkets = new Set();
                const currentStatus = renderStatus(item.data.couponList);
                const winAmount = item.data.win
                    ? item.data.win === 0
                        ? '-'
                        : `${formatDenmarkCurrency(item.data.win)} kr.`
                    : calculateTotalWin(item.data.couponList);
                item.data.couponList.forEach((coupon) => {
                    const length = coupon.betList.length;
                    const nameIndex = Math.min(length, systemNames.length - 1);
                    const nameKey = systemNames[nameIndex];

                    if (betCounts[nameKey]) {
                        betCounts[nameKey] += 1;
                    } else {
                        betCounts[nameKey] = 1;
                    }
                });

                const betTypesString =
                    Object.entries(betCounts)
                        .map(([name, count]) => {
                            if (name === 'Trippel') {
                                return `${count} ${
                                    count > 1 ? 'Tripler' : 'Trippel'
                                }`;
                            }
                            const pluralSuffix = name.endsWith('e')
                                ? 'r'
                                : 'er';
                            return `${count} ${name}${
                                count > 1 ? pluralSuffix : ''
                            }`;
                        })
                        .join(', ') || 'Single';

                return (
                    <div key={index} className="virtual__transfer__container">
                        <div
                            className="left-line"
                            style={{
                                background: getStatusBorder(
                                    item.data.couponList
                                ),
                            }}
                        />
                        <div className="virtual__transfer__content">
                            <div className="header__container">
                                <div className="header-title">
                                    <img
                                        src={virtuelImg}
                                        alt="virtuel icon"
                                        width="63px"
                                        height="auto"
                                        className=""
                                    />
                                </div>
                                <div className="transfer__line" />
                            </div>
                            {item.data.couponList.flatMap(
                                (coupon, couponIndex) =>
                                    coupon.betList
                                        .filter((bet) => {
                                            if (
                                                !uniqueMarkets.has(bet.marketId)
                                            ) {
                                                uniqueMarkets.add(bet.marketId);
                                                return true;
                                            }
                                            return false;
                                        })
                                        .map((bet, betIndex) => {
                                            const isActive =
                                                coupon.status === 0;
                                            return (
                                                <React.Fragment
                                                    key={bet.marketId}
                                                >
                                                    <div
                                                        className="match"
                                                        onClick={
                                                            isActive
                                                                ? () =>
                                                                      goToEventPage(
                                                                          bet.eventId
                                                                      )
                                                                : null
                                                        }
                                                        style={
                                                            isActive
                                                                ? {
                                                                      cursor:
                                                                          'pointer',
                                                                  }
                                                                : {}
                                                        }
                                                    >
                                                        <div className="virtual__transfer__dark">
                                                            <div className="virtual__transfer__icon">
                                                                {renderIcon(
                                                                    item.data
                                                                        .couponList[0]
                                                                        .status
                                                                )}{' '}
                                                                {getBetName(
                                                                    bet.marketName,
                                                                    bet.eventName,
                                                                    bet.selection,
                                                                    bet.selectionText
                                                                )}
                                                            </div>
                                                            <div className="virtual__transfer__gray">
                                                                {bet.marketName}
                                                            </div>
                                                            <div className="virtual__transfer__gray">
                                                                {bet.eventName}
                                                            </div>
                                                            <div className="virtual__transfer__gray">
                                                                Resultat:{' '}
                                                                {getResultName(
                                                                    bet.marketName,
                                                                    bet.eventName,
                                                                    bet.result,
                                                                    bet.selectionText
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="virtual__transfer__dark">
                                                            {bet.odds
                                                                ? bet.odds.toFixed(
                                                                      2
                                                                  )
                                                                : ''}
                                                        </div>
                                                    </div>
                                                    <div className="transfer__line" />
                                                </React.Fragment>
                                            );
                                        })
                            )}
                            <div className="virtual__transfer__single">
                                {' '}
                                {betTypesString}
                            </div>
                            <div className="transfer__line" />
                            <div className="financials">
                                <div className="financials__left">
                                    <div className="virtual__transfer__gray">
                                        Indsats
                                    </div>
                                    <div className="virtual__transfer__dark">
                                        {item.data.stake.toLocaleString(
                                            'da-DK',
                                            {
                                                style: 'currency',
                                                currency: 'DKK',
                                            }
                                        )}
                                    </div>
                                </div>
                                <div className="financials__right">
                                    <div className="virtual__transfer__gray">
                                        Samlet odds
                                    </div>
                                    <div className="virtual__transfer__dark">
                                        {item.data.couponList.length === 1
                                            ? item.data.couponList[0].odds.toFixed(
                                                  2
                                              )
                                            : calculateOddsDisplay(
                                                  item.data.couponList
                                              )}
                                    </div>
                                </div>
                            </div>
                            <div className="transfer__line" />
                            <div className="results">
                                <div className="results__left">
                                    <div className="virtual__transfer__gray">
                                        Status
                                    </div>
                                    <div className="virtual__transfer__dark">
                                        {currentStatus}
                                    </div>
                                </div>
                                <div className="results__right">
                                    <div className="virtual__transfer__gray">
                                        {currentStatus === 'Aktiv'
                                            ? 'Mulig gevinst'
                                            : currentStatus === 'Annulleret'
                                                ? 'Indsats retur'
                                                : 'Gevinst'}
                                    </div>
                                    <div className="virtual__transfer__dark">
                                        <strong>{winAmount}</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="transfer__line" />
                            {showRetailTransferUi(item) &&
                                !isCanceledCoupon(item) && (
                                    <Wrapper
                                        width={'100%'}
                                        padding={'0'}
                                        margin={'0'}
                                        flex
                                        justify={'center'}
                                        flexAlign={'center'}
                                        boxSizing={'border-box'}
                                    >
                                        <PayoutStatusComponent
                                            product={item.product}
                                            saleId={item.data.saleId}
                                            win={item.data.win}
                                            payoutStatus={item.payoutStatus}
                                            payoutType={item.payoutType}
                                        />
                                    </Wrapper>
                                )}
                            <div className="footer">
                                ID: {item.data.saleId} -{' '}
                                {new Date(
                                    item.data.saleDate
                                ).toLocaleDateString('da-DK', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                })}{' '}
                                -{' '}
                                {new Date(
                                    item.data.saleDate
                                ).toLocaleTimeString('da-DK', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

function mapStateToProps(state) {
    return {
        retailSalesHistory: state.retailSalesHistory,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VirtualTransfer);
