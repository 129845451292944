export const COLORS = {
    primary: '#000',
    accent: '#000',
    secondary: '#000',
    primaryText: '#000',
    placeholderText: '#000',
    accentText: '#000',
    secondaryText: '#000',
    red: '#d13435',
    blue: '#1B4EF7',
    gray: '#575C69',
    darkGray: '#9198AA',
    lightGreen: '#000',
    lightGray: '#ECEFF6',
    white: '#fff',
    dark: '#333',
    black: '#000',
    special: 'red',
    blockShadow: '#000',
};

export const BACKGROUNDS = {
    primary: '#1C3E60',
    primaryFlat: 'blue',
    accent: 'blue',
    accentAlt: 'blue',
    secondary: 'blue',
    red: 'blue',
    blue: 'blue',
    gray: '575C69',
    darkGray: '#9198AA',
    lightGray: '#ECEFF6',
    white: '#fff',
    black: 'blue',
    darkgreen: 'blue',
    none: 'blue',
    hugeBlockGradient: 'blue',
    navigation: 'blue',
    navigationActiveElement: 'blue',
};
