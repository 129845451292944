import { browserHistory } from 'react-router';

export const SPILKLUB_URL = '/sport/big9/spilklub25';

export const GROUP_LIST_SLUG = 'groups';
export const CREATE_GROUP_SLUG = 'createGroup';
export const POOL_LIST_SLUG = 'pools';
export const GROUP_EDIT_SLUG = 'edit';
export const POOL_DETAILS_SLUG = 'pool';

const SCREENS_WITHOUT_AUTH = [
    GROUP_LIST_SLUG,
    POOL_LIST_SLUG,
    POOL_DETAILS_SLUG,
    // this actually needs auth, but has its separate handlers
    CREATE_GROUP_SLUG,
];

export const doesScreenNeedLogin = (screen) =>
    SCREENS_WITHOUT_AUTH.includes(screen);

export function getScreenUrl(slug, entityId, entityId2) {
    return `${SPILKLUB_URL}/${slug}${entityId ? '/' + entityId : ''}${
        entityId2 ? '/' + entityId2 : ''
    }`;
}

const useScreens = () => {
    return {
        setScreen: (slug, entityId, entityId2, replace = false) => {
            window.scrollTo(0, 0);
            const screenUrl = getScreenUrl(slug, entityId, entityId2);

            replace
                ? browserHistory.replace(screenUrl)
                : browserHistory.push(screenUrl);
        },
        goBack: browserHistory.goBack,
    };
};

export default useScreens;
