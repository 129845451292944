import React, { Component } from 'react';
import 'css/UIModal.css';
import imgLogo from 'images/bet25sport_logo.svg';
import {
    desktopOnly,
    disableBodyScroll,
    enableBodyScroll,
    isBodyScrollDisabled,
} from 'utils/UiHelper';

class UIModal extends Component {
    componentDidMount() {
        this.isBodyScrollAlreadyDisabled = isBodyScrollDisabled();
        if (!this.isBodyScrollAlreadyDisabled) {
            disableBodyScroll();
        }
    }

    componentWillUnmount() {
        if (!this.isBodyScrollAlreadyDisabled) {
            enableBodyScroll();
        }
    }

    onCloseBtn() {
        if (this.props.onCloseModal) {
            this.props.onCloseModal();
        }
    }

    render() {
        const headerView = (
            <div className={`row header ${this.props.headerClass || ''}`}>
                {this.props.mode !== 'CLOSE_BTN' ? (
                    <div
                        className="col-2 go-back"
                        onClick={this.onCloseBtn.bind(this)}
                    >
                        <i className="fa fa-angle-left" aria-hidden="true" />
                    </div>
                ) : (
                    <div className="col-2 go-back">&nbsp;</div>
                )}
                <div
                    className={
                        this.props.title
                            ? 'col-6 text-center'
                            : 'col-6 text-center logo'
                    }
                >
                    {!this.props.title ? (
                        <img src={imgLogo} height="50" alt="Bet25" />
                    ) : (
                        <span className="ui-modal-title">
                            {this.props.title}
                        </span>
                    )}
                </div>
                {this.props.mode !== 'CLOSE_BTN' ? (
                    <div className="col-2" />
                ) : (
                    <div
                        className="col-2 close-btn"
                        onClick={this.onCloseBtn.bind(this)}
                    >
                        <i className="fa fa-times" aria-hidden="true" />
                    </div>
                )}
            </div>
        );

        if (desktopOnly()) {
            return (
                <div className="ui-modal-wrapper">
                    <div className="ui-modal">
                        {headerView}
                        {this.props.children}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="ui-modal .animation-step1">
                    {headerView}
                    {this.props.children}
                </div>
            );
        }
    }
}

export default UIModal;
