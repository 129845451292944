import {
    AUTH_INFO,
    AUTH_LOGIN_ERROR,
    AUTH_LOGIN_PENDING,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT,
    AUTH_RESET,
    AUTH_SESSION_ERROR,
    AUTH_SESSION_PENDING,
    AUTH_SESSION_SUCCESS,
    AUTH_USER_BALANCE,
    AUTH_USER_NEWSLATER,
    AUTH_SHOW_FORGOT_PASSWORD,
    AUTH_SHOW_LAST_LOGIN_POPUP,
} from 'constants/auth';
import {
    SET_DERBY_PERMISSION_PENDING,
    SET_DERBY_PERMISSION_SUCCESS,
    SET_DERBY_PERMISSION_ERROR,
} from 'constants/derby';
import { UI_LOGIN_MODAL_STATUS } from 'constants/ui';

import AuthService from 'utils/AuthService';
import UserService from 'utils/UserService';
import { browserHistory } from 'react-router';
import RestService from 'utils/RestService';
import cookie from 'react-cookies';
import { checkUserExclusionStatus, sha256 } from 'utils/Helper';
import { LOCAL_STORAGE_KEYS } from 'constants/appConfig';
import { desktopOnly } from 'utils/UiHelper';
import { hideModal } from 'actions/UiActions';
import { detectMobile } from '../utils/UiHelper';
import { isAuthorizedPage } from '../utils/Helper';

const addShaData = (user) => {
    sha256(user.firstName).then((res) => {
        localStorage.setItem('fn', res);
    });
    sha256(user.lastName).then((res) => {
        localStorage.setItem('ln', res);
    });
    sha256(user.email).then((res) => {
        localStorage.setItem('em', res);
    });
    sha256(user.phoneNr).then((res) => {
        localStorage.setItem('Pn', res);
    });
};
let Validate = {
    CPR: function(cpr) {
        cpr = '' + cpr;

        return cpr.length === 10 && /\d{10}/.test(cpr) === true;
    },

    Age: function(cpr) {
        cpr = '' + cpr;
        var year = cpr.substr(4, 2);
        if (year > new Date().getFullYear() % 100) {
            year = '19' + year;
        } else {
            year = '20' + year;
        }
        var age = 568021248;
        var now = new Date().getTime() / 1000;
        var born =
            new Date(
                year + '/' + cpr.substr(2, 2) + '/' + cpr.substr(0, 2)
            ).getTime() / 1000;
        return Math.abs(now - born) > age;
    },
};

function attachUser(balance) {
    UserService.InfoGet().then((res) => {
        const account = res.data;
        if (account) {
            RestService.AtachUser('bet25-web', account, balance);
            RestService.LastLogin('bet25-web', account);
        }
    });
}

/*function checkUserLimits(dispatch, data) {
    const user = data.limits ? data : data.data;
    if (
        user &&
        user.limits &&
        !user.limits.dailyAmountLimit &&
        !user.limits.weeklyAmountLimit &&
        !user.limits.monthlyAmountLimit
    ) {
        dispatch(showModal('FAST_DEPOSIT_POPUP'));
    }
}*/

export function setAppToolbarToPendingStatus(status) {
    return (dispatch) => {
        dispatch({
            type: AUTH_LOGIN_PENDING,
            payload: status,
        });
    };
}

export function login(username, password, stayOnPage, type) {
    return (dispatch) => {
        dispatch({
            type: AUTH_LOGIN_PENDING,
            payload: true,
        });

        let channel = 8;
        detectMobile() ? (channel = 8) : (channel = 0);
        return AuthService.login(username, password, channel)
            .then((res) => {
                if (res.data === true) {
                    browserHistory.push('/sport/forgot-password');
                    dispatch({
                        type: AUTH_LOGIN_PENDING,
                        payload: false,
                    });
                } else {
                    if (res.success) {
                        dispatch({
                            type: AUTH_LOGIN_SUCCESS,
                            payload: res.data,
                        });

                        dispatch({
                            type: AUTH_SHOW_LAST_LOGIN_POPUP,
                            payload: true,
                        });

                        AuthService.fetchHash().then((res) => {
                            window.localStorage.setItem(
                                LOCAL_STORAGE_KEYS.PIN_TOKEN,
                                res.data
                            );
                            window.localStorage.setItem(
                                LOCAL_STORAGE_KEYS.PIN_USERNAME,
                                username
                            );
                        });

                        dispatch({
                            type: UI_LOGIN_MODAL_STATUS,
                            payload: false,
                        });

                        if (!stayOnPage) {
                            browserHistory.push('/sport/');
                        }

                        detectMobile()
                            ? dispatch(hideModal('LOGIN_MOBILE'))
                            : dispatch(hideModal('LOGIN'));

                        attachUser(res.data.balance);
                        // checkUserLimits(dispatch, res.data);
                        UserService.InfoGet().then((res) => {
                            const user = res.data;
                            addShaData(user);
                            if (window?.dataLayer) {
                                window.dataLayer.push({
                                    event: 'login',
                                    userId: res?.data?.userId,
                                });
                            }
                            dispatch({
                                type: AUTH_INFO,
                                payload: user,
                            });
                            if (window.Intercom && user) {
                                window.Intercom('update', {
                                    app_id: 'qkqulpbi',
                                    name: user.firstName + ' ' + user.lastName,
                                    phone: user.phoneNr,
                                    email: user.email,
                                    username: user.userName,
                                    user_id: user.userId,
                                    postal_code: user.zip,
                                    last_login_bet25_at: Math.round(
                                        Date.now() / 1000
                                    ),
                                    last_active_on: desktopOnly()
                                        ? 'bet25_web'
                                        : 'bet25_mob',
                                });
                            }
                        });

                        checkUserExclusionStatus();

                        if (window.adf) window.adf.track(217163, 4836002, {});

                        window.localStorage.setItem('', res.data.userId);

                        let d = new Date();

                        d.setTime(d.getTime() + 180 * 24 * 60 * 60 * 1000);

                        cookie.save('last_user', res.data.userId, {
                            expires: d,
                        });
                    } else {
                        if (res.errorMessage === 'Fejl99') {
                            window.Intercom('update', {
                                app_id: 'qkqulpbi',
                                user_status: 'excluded',
                                username: username,
                            });
                        }

                        dispatch({
                            type: AUTH_LOGIN_ERROR,
                            payload: {
                                errorMsg: res.errorMessage,
                                message: res.errorMessage,
                            },
                        });
                    }
                }
            })
            .catch((error) => {
                dispatch({
                    type: AUTH_LOGIN_ERROR,
                    payload: { errorMsg: error.errorMessage },
                });
                if (type === 'desktop') {
                    No;
                }
            });
    };
}

export function pinLogin(pin, hash, username) {
    return (dispatch) => {
        dispatch({
            type: AUTH_LOGIN_PENDING,
            payload: true,
        });

        AuthService.LoginPIN(pin, hash, username)
            .then((res) => {
                if (res.data === true) {
                    browserHistory.push('/sport/forgot-pin');
                    dispatch({
                        type: AUTH_LOGIN_PENDING,
                        payload: false,
                    });
                } else {
                    if (res.success) {
                        dispatch({
                            type: AUTH_LOGIN_SUCCESS,
                            payload: res.data,
                        });

                        dispatch({
                            type: AUTH_SHOW_LAST_LOGIN_POPUP,
                            payload: true,
                        });

                        if (window.dataLayer) {
                            dataLayer.push({
                                event: 'login',
                                userId: res.data.userId,
                            });
                        }
                        AuthService.fetchHash().then((res) => {
                            window.localStorage.setItem(
                                LOCAL_STORAGE_KEYS.PIN_TOKEN,
                                res.data
                            );
                            window.localStorage.setItem(
                                LOCAL_STORAGE_KEYS.PIN_USERNAME,
                                username
                            );
                        });

                        dispatch({
                            type: UI_LOGIN_MODAL_STATUS,
                            payload: false,
                        });
                        // checkUserLimits(dispatch, res.data);

                        UserService.InfoGet((res) => {
                            const data = res.data;

                            addShaData(data);
                            dispatch({
                                type: AUTH_INFO,
                                payload: data,
                            });
                            if (window.Intercom && data) {
                                window.Intercom('update', {
                                    app_id: 'qkqulpbi',
                                    name: data.firstName + ' ' + data.lastName,
                                    phone: data.phoneNr,
                                    email: data.email,
                                    username: data.userName,
                                    postal_code: data.zip,
                                    last_login_bet25_at: Math.round(
                                        Date.now() / 1000
                                    ),
                                });
                            }
                        });

                        checkUserExclusionStatus();

                        if (window.adf) window.adf.track(217163, 4836002, {});

                        window.localStorage.setItem(
                            'last-user-id',
                            res.data.userId
                        );

                        let d = new Date();

                        d.setTime(d.getTime() + 180 * 24 * 60 * 60 * 1000);

                        cookie.save('last_user', res.data.userId, {
                            expires: d,
                        });
                    } else {
                        dispatch({
                            type: AUTH_LOGIN_ERROR,
                            payload: {
                                errorMsg:
                                    res.errorMessage ===
                                    'ERROR_LOGIN_WRONGPINORHASH'
                                        ? ' Forkert PIN'
                                        : res.errorMessage,
                            },
                        });

                        if (isAuthorizedPage()) {
                            browserHistory.push('/sport');
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch({
                    type: AUTH_LOGIN_ERROR,
                    payload: { errorMsg: error.errorMessage },
                });
            });
    };
}

export function nemIdLoginSuccess(data) {
    return (dispatch) => {
        dispatch({
            type: AUTH_LOGIN_SUCCESS,
            payload: data,
        });
        // checkUserLimits(dispatch, data);
        attachUser(data.balance);

        AuthService.fetchHash().then((res) => {
            window.localStorage.setItem(LOCAL_STORAGE_KEYS.PIN_TOKEN, res.data);
            window.localStorage.setItem(
                LOCAL_STORAGE_KEYS.PIN_USERNAME,
                data.userName
            );
        });

        UserService.InfoGet().then((res) => {
            const { data } = res;
            addShaData(data);

            dispatch({
                type: AUTH_INFO,
                payload: data,
            });
            if (window.Intercom && data) {
                window.Intercom('update', {
                    app_id: 'qkqulpbi',
                    name: data.firstName + ' ' + data.lastName,
                    phone: data.phoneNr,
                    email: data.email,
                    username: data.userName,
                    postal_code: data.zip,
                    last_login_bet25_at: Math.round(Date.now() / 1000),
                });
            }
        });

        checkUserExclusionStatus();

        if (window.adf) window.adf.track(217163, 4836002, {});

        dispatch({
            type: UI_LOGIN_MODAL_STATUS,
            payload: false,
        });
    };
}

const prepareIntercom = (dispatch) => {
    UserService.InfoGet().then((res) => {
        dispatch({
            type: AUTH_INFO,
            payload: res.data,
        });
        if (window.Intercom && res.data) {
            window.Intercom('update', {
                app_id: 'qkqulpbi',
                name: res.data.firstName + ' ' + res.data.lastName,
                phone: res.data.phoneNr,
                email: res.data.email,
                username: res.data.userName,
                user_id: res.data.userId,
                postal_code: res.data.zip,
                last_login_bet25_at: Math.round(Date.now() / 1000),
                last_active_on: desktopOnly() ? 'bet25_web' : 'bet25_mob',
            });
        }
    });
};

export function checkSession() {
    return (dispatch) => {
        dispatch({
            type: AUTH_SESSION_PENDING,
            payload: true,
        });
        AuthService.isUserLoggedIn()
            .then((res) => {
                if (res.success && res.data) {
                    dispatch({
                        type: AUTH_SESSION_SUCCESS,
                        payload: res.data,
                    });
                    AuthService.fetchHash().then((res) => {
                        window.localStorage.setItem(
                            LOCAL_STORAGE_KEYS.PIN_TOKEN,
                            res.data
                        );
                        window.localStorage.setItem(
                            LOCAL_STORAGE_KEYS.PIN_USERNAME,
                            res.data.userName
                        );
                    });

                    prepareIntercom(dispatch);

                    let d = new Date();

                    d.setTime(d.getTime() + 180 * 24 * 60 * 60 * 1000);

                    cookie.save('last_user', res.data.userId, {
                        expires: d,
                    });
                } else {
                    dispatch({
                        type: AUTH_SESSION_ERROR,
                        payload: 'Session Error',
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: AUTH_SESSION_ERROR,
                    payload: error.toString(),
                });
            });
    };
}

export function checkUserLoggedStatus() {
    return (dispatch) => {
        dispatch({
            type: AUTH_SESSION_PENDING,
            payload: true,
        });
        return AuthService.isUserLoggedIn()
            .then((res) => {
                if (res.success && res.data) {
                    dispatch({
                        type: AUTH_SESSION_SUCCESS,
                        payload: res.data,
                    });

                    AuthService.fetchHash().then((res) => {
                        window.localStorage.setItem(
                            LOCAL_STORAGE_KEYS.PIN_TOKEN,
                            res.data
                        );
                    });
                    window.localStorage.setItem(
                        LOCAL_STORAGE_KEYS.PIN_USERNAME,
                        res.data.userName
                    );
                } else {
                    dispatch({
                        type: AUTH_SESSION_ERROR,
                        payload: 'Session Error',
                    });
                }
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: AUTH_SESSION_ERROR,
                    payload: error.toString(),
                });
            });
    };
}

export function logout() {
    return (dispatch) => {
        AuthService.logout().then((res) => {
            if (res.success) {
                dispatch({
                    type: AUTH_LOGOUT,
                    payload: true,
                });
            } else {
                dispatch({
                    type: AUTH_LOGIN_ERROR,
                    payload: {
                        errorMsg: res.errorMessage,
                        message: res.errorMessage,
                    },
                });
            }
        });
    };
}

export function reset() {
    return (dispatch) => {
        dispatch({
            type: AUTH_RESET,
            payload: true,
        });
    };
}

export function updateBalance() {
    return (dispatch) => {
        dispatch({
            type: AUTH_SESSION_PENDING,
            payload: true,
        });
        AuthService.isUserLoggedIn().then((res) => {
            if (res.success) {
                dispatch({
                    type: AUTH_USER_BALANCE,
                    payload: res.data.balance,
                });
            }
        });
    };
}

export function updateNewslater(status) {
    return (dispatch) => {
        dispatch({
            type: AUTH_USER_NEWSLATER,
            payload: status,
        });
    };
}

export function changeStatusForForgotPassword(status) {
    return (dispatch) => {
        dispatch({
            type: AUTH_SHOW_FORGOT_PASSWORD,
            payload: status,
        });
    };
}

export function showLastLoginPopupStatus(status) {
    return (dispatch) => {
        dispatch({
            type: AUTH_SHOW_LAST_LOGIN_POPUP,
            payload: status,
        });
    };
}

export function setDerbyPermission(on, email) {
    return (dispatch) => {
        dispatch({
            type: SET_DERBY_PERMISSION_PENDING,
        });
        return AuthService.isUserDerbyPermission(on, email)

            .then((response) => {
                if (response.success) {
                    dispatch({
                        type: SET_DERBY_PERMISSION_SUCCESS,
                        payload: response.data,
                    });
                } else {
                    dispatch({
                        type: SET_DERBY_PERMISSION_ERROR,
                        error:
                            response.errorMessage || 'Unknown error occurred',
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SET_DERBY_PERMISSION_ERROR,
                    error: error.message || 'An error occurred',
                });
            });
    };
}
