import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as LiveEventsActions from 'actions/LiveEventsActions';
import * as EventObjectActions from 'actions/EventObjectActions';
import * as BetslipActions from 'actions/BetslipActions';
import * as TerminalBetslipActions from 'actions/TerminalBetslipActions';
import * as SportTreeActions from 'actions/SportTreeActions';
import { isTerminal } from 'utils/UiHelper';
import { EVENT_STATUS } from 'constants/appConfig';
import {
    getVaixLiveFavoriteEventsIds as GetVaixLiveFavoriteEventsIds,
    getVaixLiveNavigationOrder as GetVaixLiveNavigationOrder,
} from '../../actions/VaixEventsActions';
import { getLivePopularVaixEvents as GetLivePopularVaixEvents } from '../../actions/LiveEventsActions';
import { ANBEFALET_SPORT_ID } from '../../constants/appConfig';

if (window.config.firebaseDevel && window.config.firebaseDevel === true) {
    const devconfig = {
        apiKey: 'AIzaSyCsd4Ufc0cjdZ2v0y6pCX52OHr_BRLSZZw',
        authDomain: 'livebet25test.firebaseapp.com',
        databaseURL: 'https://livebet25test.firebaseio.com',
        projectId: 'livebet25test',
        storageBucket: 'livebet25test.appspot.com',
        messagingSenderId: '754420801812',
        appId: '1:754420801812:web:01e7993f7ceba815b82201',
        measurementId: 'G-KMCY6T654F',
    };

    window.firebase.initializeApp(devconfig);
} else {
    const config = {
        apiKey: 'AIzaSyASzlbBci_IWERQtcsH1wrtOfCF7NutVxQ',
        authDomain: 'livebet25-2cb17.firebaseapp.com',
        databaseURL: 'https://livebet25-2cb17.firebaseio.com',
        projectId: 'livebet25-2cb17',
        storageBucket: 'livebet25-2cb17.appspot.com',
        messagingSenderId: '218874853780',
    };

    window.firebase.initializeApp(config);
}

class Firebase extends React.Component {
    events = null;
    markets = null;
    market = {};
    marketsByEvent = {};
    liveScoreStatuses = ['ended', 'interrupted', 'not_started'];

    componentDidMount() {
        this.events = window.firebase.database().ref('events');

        this.events.on('child_added', (data) => {
            const event = data.val();
            if (
                event.liveScore &&
                event.liveScore.homeScore &&
                (event.status === EVENT_STATUS.IN_PROGRESS ||
                    event.status === EVENT_STATUS.PAUSE) &&
                this.liveScoreStatuses.indexOf(event?.liveScore?.status) < 0
            ) {
                this.props.liveEventsActions.addEvent(event);
            }
            if (this.checkConditionForLiveUpdate(event)) {
                this.props.liveEventsActions.updateLiveDataWithVaixSorting(
                    true
                );
            }

            this.updateVaixEvents();

            if (
                this.props.sportData &&
                !this.props.sportTreePending &&
                !this.props.sportData.find((item) => event.sportId === item.id)
            ) {
                this.props.sportTreeActions.getSportTree();
            }
        });

        this.events.on('child_changed', (data) => {
            const event = data.val();
            if (event.liveScore && event.liveScore.score) {
                this.props.liveEventsActions.setEvent(event);
                if (this.checkConditionForLiveUpdate(event)) {
                    this.props.liveEventsActions.updateLiveData(true);
                }

                if (
                    this.props.eventObject.fetched &&
                    this.props.eventObject.data.liveScore &&
                    event.eventId === this.props.eventObject.data.eventId
                ) {
                    this.props.eventObjectActions.patchEventData(event);
                }

                if (
                    event.marketList &&
                    event.marketList[0] &&
                    !this.props.betslip.disabled &&
                    this.props.betslip.hasMarkets &&
                    this.props.betslip.hasMarkets.indexOf(
                        event.marketList[0].marketId
                    ) > -1
                ) {
                    this.props.betslipActions.updateMarket(
                        this.props.betslip,
                        event.marketList[0],
                        this.props.eventObject.eventId,
                        this.props.eventObject.sportId
                    );
                }
            }
        });

        this.events.on('child_removed', (data) => {
            const event = data.val();
            this.updateVaixEvents();
            if (this.checkConditionForLiveUpdate(event)) {
                this.props.liveEventsActions.updateLiveDataWithVaixSorting(
                    true
                );
            }

            if (
                event.marketList &&
                event.marketList[0] &&
                !this.props.betslip.disabled &&
                this.props.betslip.hasMarkets &&
                this.props.betslip.hasMarkets.indexOf(
                    event.marketList[0].marketId
                ) > -1
            ) {
                if (this.props.betslip.hasMarkets.length > 1) {
                    this.props.betslipActions.clearSystemMarket(
                        event.eventId,
                        event.marketList[0].marketId
                    );
                } else {
                    this.props.betslipActions.clearSingleMarket(
                        event.eventId,
                        event.marketList[0].marketId
                    );
                }
            }
            if (this.props.sportData && !this.props.sportTreePending) {
                this.props.sportTreeActions.getSportTree();
            }
            this.props.liveEventsActions.removeEvent(event.eventId);
        });
    }

    subscribeSingleMarket = (eventId, marketId, handler) => {
        const firbaseLink = window.firebase
            .database()
            .ref('markets/' + eventId + '/' + marketId);

        firbaseLink.on('child_changed', handler);

        return firbaseLink;
    };

    subscribeOnMarketUpdatesByEventId = (eventId) => {
        const markets = window.firebase.database().ref('markets/' + eventId);

        markets.on('child_added', this.firebaseMarketAddHandler);
        markets.on('child_changed', this.firebaseMarketChangeHandler);
        markets.on('child_removed', this.firebaseMarketRemoveHandler);

        return () => {
            markets.off('child_added', this.firebaseMarketAddHandler);
            markets.off('child_changed', this.firebaseMarketChangeHandler);
            markets.off('child_removed', this.firebaseMarketRemoveHandler);
        };
    };

    firebaseMarketAddHandler = (data) => {
        if (this.props.betslip.hasMarkets.includes(Number(data.key))) {
            const liveMarkets = { ...this.props.betslip.liveMarkets };
            liveMarkets[data.key] = data.val();
            this.props.betslipActions.setLiveMarkets(liveMarkets);
        }
    };

    firebaseMarketRemoveHandler = (data) => {
        if (this.props.betslip.hasMarkets.includes(Number(data.key))) {
            const liveMarkets = { ...this.props.betslip.liveMarkets };
            delete liveMarkets[data.key];
            this.props.betslipActions.setLiveMarkets(liveMarkets);
        }
    };

    firebaseMarketChangeHandler = (data) => {
        if (this.props.betslip.hasMarkets.includes(Number(data.key))) {
            const liveMarkets = { ...this.props.betslip.liveMarkets };
            liveMarkets[data.key] = data.val();
            this.props.betslipActions.setLiveMarkets(liveMarkets);

            if (
                this.props.betslip.customBetEventId !== null &&
                !this.props.betslip.totalOddsUpdatePending
            ) {
                this.props.betslipActions.updateCustomBetTotalOdds();
            }
        }
    };

    checkConditionForLiveUpdate = (event) =>
        this.props.activeSportId === event.sportId ||
        this.props.activeSportId === ANBEFALET_SPORT_ID ||
        this.props.activeSportId === 0;

    updateVaixEvents = () => {
        //add window.location.pathname.split('/').includes('live') to avoid additional requests
        if (
            !this.props.vaixEvents.pending &&
            window.location.pathname.split('/').includes('live')
        ) {
            this.props.getVaixLiveFavoriteEventsIds(
                this.props.auth?.user?.userId,
                this.props.vaixEvents.vaixLiveFavoriteEventsIds
            );
            setTimeout(() => {
                this.props.getVaixLiveNavigationOrder(
                    this.props.auth?.user?.userId
                );
            }, 5);
        }

        //add !window.location.pathname.split('/')[2].length > 0 to avoid additional requests
        if (
            !this.props.liveEvents.vaixPopularEventsPending &&
            !window.location.pathname.split('/')[2]?.length > 0
        ) {
            this.props.getLivePopularVaixEvents(this.props.auth?.user?.userId);
        }
    };

    componentDidUpdate(prevProps) {
        let _market = this.market;
        if (this.props.betslip.hasMarkets.length > 0) {
            this.props.betslip.hasMarkets.forEach((marketId) => {
                if (!_market[marketId]) {
                    const betItem = this.props.betslip.betList.find(
                        (item) => item.gameId === marketId
                    );

                    if (betItem) {
                        _market[marketId] = {};

                        _market[marketId].handler = (data) => {
                            if (data.val()) {
                                this.props.betslipActions.updateMarket(
                                    this.props.betslip,
                                    { [data.key]: data.val(), marketId },
                                    betItem.eventId,
                                    betItem.sportId
                                );
                            }
                        };

                        _market[marketId].firebase = this.subscribeSingleMarket(
                            betItem.eventId,
                            marketId,
                            _market[marketId].handler
                        );
                    }
                }
            });
        } else {
            if (Object.keys(this.market).length > 0) {
                Object.keys(this.market).forEach((marketId) => {
                    _market[marketId].firebase.off(
                        'child_changed',
                        _market[marketId].handler
                    );
                });

                this.market = {};
            }
        }
        if (prevProps.betslip.hasMarkets !== this.props.betslip.hasMarkets) {
            Object.keys(this.marketsByEvent)
                .filter(
                    (eventId) =>
                        !this.props.betslip.betList.find(
                            (bet) => bet.eventId === Number(eventId)
                        )
                )
                .forEach((eventId) => {
                    this.marketsByEvent[eventId]();
                    delete this.marketsByEvent[eventId];
                });

            this.props.betslip.betList
                .filter(({ eventId }) => !this.marketsByEvent[eventId])
                .forEach(({ eventId }) => {
                    this.marketsByEvent[
                        eventId
                    ] = this.subscribeOnMarketUpdatesByEventId(eventId);
                });
        }
    }

    render() {
        return this.props.children(this.events, this.markets);
    }
}

function mapStateToProps(state) {
    return {
        liveEvents: state.liveEvents,
        sportTreePending: state.sportTree.pending,
        sportData: state.sportTree.sportData,
        betslip: state.betslip,
        vaixEvents: state.vaixEvents,
        eventObject: state.eventObject,
        auth: state.auth,
        activeSportId: state.uiSportLive.activeSportId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        liveEventsActions: bindActionCreators(LiveEventsActions, dispatch),
        sportTreeActions: bindActionCreators(SportTreeActions, dispatch),
        getVaixLiveFavoriteEventsIds: bindActionCreators(
            GetVaixLiveFavoriteEventsIds,
            dispatch
        ),
        getVaixLiveNavigationOrder: bindActionCreators(
            GetVaixLiveNavigationOrder,
            dispatch
        ),
        getLivePopularVaixEvents: bindActionCreators(
            GetLivePopularVaixEvents,
            dispatch
        ),
        betslipActions: bindActionCreators(
            isTerminal() ? TerminalBetslipActions : BetslipActions,
            dispatch
        ),
        eventObjectActions: bindActionCreators(EventObjectActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Firebase);
