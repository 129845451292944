import { keyframes } from 'styled-components';

export const fadeInTopToBottom = keyframes`
    0% {
        top: -30px;
        opacity: 0;
    }
    10% {
        top: 60px;
        opacity: 1;
    }
    90% {
        top: 60px;
        opacity: 1;
    }
    100% {
        top: -30px;
        opacity: 0;
    }
`;
