import {
    SET_BONUS,
    REMOVE_BONUS,
    PENDING_BONUS,
    FETCHED_BONUS,
    RESET_BONUS_STATE,
} from '../constants/bonuses';
import UserService from '../utils/UserService';
import BonusService from '../utils/BonusService';
import { NotificationManager } from 'react-notifications';

export const getBonus = () => {
    return (dispatch) => {
        dispatch({
            type: PENDING_BONUS,
            payload: true,
        });
        UserService.GetActiveBonusList()
            .then((res) => {
                const { data, success } = res;
                if (data && success) {
                    dispatch({
                        type: SET_BONUS,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: FETCHED_BONUS,
                        payload: true,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: FETCHED_BONUS,
                    payload: true,
                });
            });
    };
};

export const removeBonus = () => {
    return (dispatch) => {
        dispatch({
            type: PENDING_BONUS,
            payload: true,
        });
        BonusService.feitBonuses().then((res) => {
            if (res.success) {
                dispatch({
                    type: REMOVE_BONUS,
                    payload: true,
                });
            } else {
                NotificationManager.error('Fejle');
            }
        });
    };
};

export const resetBonusState = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_BONUS_STATE,
        });
    };
};

export const changeRemoveBonusStatus = (status) => {
    return (dispatch) => {
        dispatch({
            type: PENDING_BONUS,
            payload: true,
        });
        dispatch({
            type: REMOVE_BONUS,
            payload: status,
        });
    };
};
