export const FETCH_RETAIL_BETS_HISTORY_PENDING =
    'FETCH_RETAIL_BETS_HISTORY_PENDING';
export const FETCH_RETAIL_BETS_HISTORY_SUCCESS =
    'FETCH_RETAIL_BETS_HISTORY_SUCCESS';
export const FETCH_MORE_RETAIL_BETS_HISTORY_SUCCESS =
    'FETCH_MORE_RETAIL_BETS_HISTORY_SUCCESS';
export const SET_TRANSACTION_SALE_ID = 'SET_TRANSACTION_SALE_ID';
export const FETCH_RETAIL_BETS_HISTORY_ERROR =
    'FETCH_RETAIL_BETS_HISTORY_ERROR';
export const RESET_RETAIL_BETS_HISTORY = 'RESET_RETAIL_BETS_HISTORY';
