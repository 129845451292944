export const LIVE_EVENTS_FETCH_PENDING = 'LIVE_EVENTS_FETCH_PENDING';
export const LIVE_EVENTS_FETCH_SUCCESS = 'LIVE_EVENTS_FETCH_SUCCESS';
export const LIVE_EVENTS_FETCH_ERROR = 'LIVE_EVENTS_FETCH_ERROR';
export const LIVE_EVENT_ADD = 'LIVE_EVENT_ADD';
export const LIVE_EVENT_UPDATE = 'LIVE_EVENT_UPDATE';
export const LIVE_EVENT_REMOVE = 'LIVE_EVENT_REMOVE';
export const LIVE_EVENT_UPDATE_MAIN_MARKET = 'LIVE_EVENT_UPDATE_MAIN_MARKET';
export const LIVE_EVENT_ADD_FAVORITE = 'LIVE_EVENT_ADD_FAVORITE';
export const LIVE_EVENT_ANIMATION_STATUS = 'LIVE_EVENT_ANIMATION_STATUS';
export const LIVE_EVENT_MERGE_MARKET_LIST = 'LIVE_EVENT_MERGE_MARKET_LIST';
export const LIVE_EVENT_SET = 'LIVE_EVENT_SET';
export const LIVE_EVENTS_VAIX_FAVORITE_ADD = 'LIVE_EVENTS_VAIX_FAVORITE_ADD';
export const LIVE_EVENTS_VAIX_FAVORITE_FETCHED =
    'LIVE_EVENTS_VAIX_FAVORITE_FETCHED';
export const LIVE_EVENTS_VAIX_POPULAR_ADD = 'LIVE_EVENTS_VAIX_POPULAR_ADD';
export const LIVE_EVENTS_VAIX_POPULAR_FETCHED =
    'LIVE_EVENTS_VAIX_POPULAR_FETCHED';
export const LIVE_EVENTS_VAIX_POPULAR_PENDING =
    'LIVE_EVENTS_VAIX_POPULAR_PENDING';
export const LIVE_EVENTS_VAIX_POPULAR_ERROR = 'LIVE_EVENTS_VAIX_POPULAR_ERROR';
export const LIVE_EVENT_UPDATE_DATA_WITH_VAIX_SORTING =
    'LIVE_EVENT_UPDATE_DATA_WITH_VAIX_SORTING';
export const LIVE_EVENT_UPDATE_DATA = 'LIVE_EVENT_UPDATE_DATA';
