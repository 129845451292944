import React, { Component, Fragment } from 'react';
import {
    formatPlayerName,
    formatSpillerPlayer,
    performTerminalBetText,
    shortHandicap,
} from 'utils/Helper';
import infoIcon from 'images/info.png';
import { NotificationManager } from 'react-notifications';
import Text from 'ui/Text';
import Wrapper from 'ui/Wrapper';
import { RemoveCouponButton } from 'features/RightSidebar/styled';
import styled from 'styled-components';
import { oddsName } from 'utils/VirtualHelper';
import { isTerminal } from 'utils/UiHelper';

const StyledWrapper = styled(Wrapper)`
    border-bottom: none;
`;
const ItemRow = styled.div`
    height: auto;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    box-sizing: border-box;
    background: #fff;
`;

const ItemRowTop = styled(ItemRow)`
    background: #f0f2f8;
    padding: 9px 20px;
    align-items: center;
    p {
        width: calc(100% - 35px);
    }
`;

const OddsWrapper = styled.div`
    background: ${({ active }) => (active ? '#FED300' : 'none')};
    padding: 5px 7px;
    margin: 0 -7px -5px 0;
    height: auto;
    border-radius: 5px;
    box-sizing: border-box;
`;

const ItemCol = styled.div`
    height: auto;
    width: 70%;
    max-width: 70%;
    padding: 0 5px 0 0;
    display: flex;
    flex-direction: column;
    background: #fff;
`;

export default class BetslipItem extends Component {
    onClickRemoveBtn() {
        if (this.props.onRemoveHandler) {
            this.props.onRemoveHandler(this.props.item);
        }
    }

    showInfo(text, e) {
        NotificationManager.warning('', text, 7000);
        e.stopPropagation();
        e.preventDefault();
    }

    getMarketType(item, isVirtual, tempName) {
        return (
            <Fragment>
                <Text condensed size="14" color="#333333" padding="0" lh="20">
                    {item.marketTypeName &&
                        item.marketTypeName.endsWith('.Halvleg') &&
                        `${item.marketTypeName} `}
                    {this.props.isCustomBet
                        ? item.marketName
                            ? item.marketName
                            : 'n/a'
                        : item.eventName
                            ? formatSpillerPlayer(
                                  shortHandicap(
                                      item,
                                      item.betText,
                                      false,
                                      true
                                  ),
                                  tempName[0],
                                  tempName[1]
                              )
                            : 'n/a'}
                </Text>
            </Fragment>
        );
    }

    render() {
        const item = this.props.item;
        const changed = this.props.changed;
        let betText = item.eventName;
        const isVirtual = !!(item.eventName && item.eventName.split(' v ')[1]);
        const isCustomBet = this.props.isCustomBet;
        const customBetEventName = this.props.customBetEventName;

        if (isTerminal() && !betText) {
            betText = performTerminalBetText(betText, item);
        }

        const tempName = isCustomBet
            ? customBetEventName
                ? customBetEventName.split(isVirtual ? ' v ' : ' - ')
                : ''
            : item.competitor
                ? item.competitor.split(isVirtual ? ' v ' : ' - ')
                : '';

        let eventName = item.competitor;
        if (isTerminal()) {
            eventName = (
                <Fragment>
                    {tempName && tempName[0]
                        ? formatPlayerName(tempName[0])
                        : 'n/a'}
                    {' - '}
                    {tempName && tempName[1]
                        ? formatPlayerName(tempName[1])
                        : 'n/a'}
                </Fragment>
            );
        }

        let isItemError = false;

        if (this.props.itemError && this.props.itemError.length > 0) {
            this.props.itemError.map((b) => {
                if (item.eventId === b.eventId && b.error !== '') {
                    isItemError = true;
                }
                return b;
            });
        }

        const virtualSpillerPlayer = formatSpillerPlayer(
            oddsName(betText),
            tempName[0],
            tempName[1]
        );
        const regularSpillerPlayer = formatSpillerPlayer(
            betText,
            tempName[0],
            tempName[1],
            item
        );
        const text = this.props.isVirtual
            ? virtualSpillerPlayer
            : regularSpillerPlayer;

        const spillerPlayerText = isCustomBet
            ? customBetEventName
                ? customBetEventName
                : 'n/a'
            : item && item.eventName && tempName
                ? item.eventName
                : 'n/a';

        return (
            <StyledWrapper
                flex
                padding="0"
                position="relative"
                opacity={this.props.isLiveBetDisabled ? '0.5' : '1'}
            >
                <Wrapper padding="0" flex width="100%" flexDirection="column">
                    <ItemRowTop>
                        <Text
                            capitalize
                            condensed
                            size="14"
                            color="#333333"
                            padding="0 5px 0 0"
                            margin="0"
                            lh="14"
                            bold
                        >
                            {spillerPlayerText}
                        </Text>
                        <RemoveCouponButton
                            onClick={this.onClickRemoveBtn.bind(this)}
                        >
                            <i className="fa fa-times" aria-hidden="true" />
                        </RemoveCouponButton>
                    </ItemRowTop>
                    <ItemRow>
                        <ItemCol>
                            {this.getMarketType(item, isVirtual, tempName)}
                            <Text
                                lh="20"
                                condensed
                                size="14"
                                color="#333333"
                                padding="0"
                                bold
                            >
                                {eventName}
                            </Text>
                        </ItemCol>
                        <OddsWrapper
                            active={changed[item.gameId] === item.betNr}
                        >
                            <Text
                                color="#333333"
                                capitalize
                                padding="0"
                                lh="14"
                                size="14"
                                bold
                            >
                                {item.odds.toFixed(2)}
                            </Text>
                        </OddsWrapper>
                    </ItemRow>
                </Wrapper>
            </StyledWrapper>
        );
    }
}
