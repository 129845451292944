import React, { Component } from 'react';
import 'css/SportradarWidget-blue.css';

export default class SportRadarWidget extends Component {
    static defaultProps = {
        isLive: false,
        styleProps: {},
        options: {},
        showTabSwitcher: false,
        container: 'sport-radar-container',
    };

    constructor(props) {
        super(props);
        this.options = {
            matchId: props.externalId,
            logo: ['/sport/img/logo/bet25_logo.svg'],
            goalBannerImage: '/sport/img/bet25sport_logo.svg',
            showTabs: props.isLive
                ? [
                      'statistics',
                      'commentary',
                      'head_to_head',
                      'lineups',
                      'table',
                  ]
                : ['head_to_head', 'table'],
            showOdds: false,
            adsFrequency: false,
            scoreboard: 'extended',
            scoreboardLargeJerseys: true,
            detailedScoreboard: 'enabled',
            layout: props.layout ? props.layout : 'single',
            activeSwitcher: 'scoreDetails',
            momentum: props.isLive ? 'compact' : 'disable',
            //layout: 'topdown',
            collapseTo: props.collapseTo || 'disable',
            disablePitchNoise: true,
            disablePitchOverlay: true,
            disablePitchSpotlights: true,
            tabsPosition: 'top',
            branding: {
                tabs: {
                    option: 'text',
                    iconPosition: 'end',
                    variant: 'fullWidth',
                    align: 'center',
                },
            },
            themes: [
                {
                    colors: {
                        away: '#A4B8CC',
                        background: '#FFFFFF',
                        base: '#1E1E1E',
                        home: '#1C3F61',
                        primary: '#1C3F61',
                    },
                    formula: 'solid',
                    id: 1,
                },
            ],
            auto_increment: 1,
        };

        this.state = {
            containerStyle: {},
        };
    }

    componentDidMount() {
        this.renderWidget(this.props.externalId, this.props.layout);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.externalId !== this.props.externalId ||
            prevProps.layout !== this.props.layout
        ) {
            this.renderWidget(
                this.props.externalId || this.props.options.matchId,
                this.props.layout
            );
        }
    }

    render() {
        const style = {
            overflow: 'hidden',
            ...this.props.styleProps,
        };

        return (
            <div className="widget-container">
                <div
                    className="sport-radar-widget-wrapper"
                    style={this.state.containerStyle}
                >
                    <div style={style} className="sport-radar-widget">
                        <div className={this.props.container} />
                    </div>
                </div>
            </div>
        );
    }

    renderWidget(matchId, layout) {
        try {
            window.SIR(
                'addWidget',
                '.' + this.props.container,
                'match.lmtPlus',

                {
                    ...this.options,
                    matchId: matchId,
                    layout: layout,
                }
            );
        } catch (error) {
            console.log('error Plugin', error);
            console.error(error);
        }
    }
}
