import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
    font-size: ${({ fontSize }) => `${fontSize}` || '12px'};
    margin: ${(props) => (props.margin ? props.margin : '0px')};
    padding: ${(props) => (props.padding ? props.padding : '10px')};
    display: ${(props) => (props.display ? props.display : 'block')};
    text-align: ${(props) => (props.align ? props.align : 'left')};
    ${(props) => (props.width ? 'width: ' + props.width + ';' : '')};
    min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'auto')};
    border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : 'none'};
    ${(props) => (props.height ? 'height: ' + props.height + ';' : 'auto')};
    ${(props) =>
        props.maxHeight ? 'max-height: ' + props.maxHeight + ';' : 'auto'};
    ${(props) =>
        props.minHeight ? 'min-height: ' + props.minHeight + ';' : ''};
    ${(props) => (props.nowrap ? 'white-space: nowrap;' : '')};
    ${(props) =>
        props.shadow
            ? 'box-shadow: 0px 0px ' +
              (props.shadow ? props.shadow : 0) +
              'px rgba(0,0,0,0.7);'
            : null};
    ${(props) =>
        props.rounded
            ? 'border-radius: ' + (props.rounded ? props.rounded : 10) + 'px;'
            : null};
    ${(props) => (props.flex ? 'display: flex' : '')};
    ${(props) => (props.flexBasis ? 'flex-basis: ' + props.flexBasis : '')};
    ${(props) =>
        props.flexDirection ? 'flex-direction: ' + props.flexDirection : ''};
    ${(props) => (props.opacity ? 'opacity: ' + props.opacity : '')};
    ${(props) => (props.border ? 'border: ' + props.border : '')};
    ${(props) =>
        props.borderBottom ? 'border-bottom: ' + props.borderBottom : ''};
    ${(props) => (props.borderTop ? 'border-top: ' + props.borderTop : '')};
    ${(props) => (props.justify ? 'justify-content: ' + props.justify : '')};
    ${(props) => (props.flexWrap ? 'flex-wrap: ' + props.flexWrap : '')};
    ${(props) => (props.flexAlign ? 'align-items: ' + props.flexAlign : '')};
    //bad value for flex  = flexHeight but flex was used
    ${(props) => (props.flexHeight ? `flex: ${props.flexHeight}` : '')};

    ${(props) =>
        props.transition
            ? 'transition: ' + (props.transition ? props.transition : '') + ';'
            : null};
    ${(props) =>
        props.overflow
            ? 'overflow: ' + (props.overflow ? props.overflow : 'auto') + ';'
            : null};
    ${(props) => (props.position ? 'position: ' + props.position + ';' : null)};
    ${(props) => (props.boxSizing ? 'box-sizing:' + props.boxSizing : '')};
    ${(props) => (props.boxShadow ? 'box-shadow:' + props.boxShadow : '')};
    ${(props) => (props.bgColor ? 'background-color:' + props.bgColor : '')};
    ${(props) => (props.bg ? 'background:' + props.bg : '')};
    ${(props) => (props.bgSize ? 'background-size:' + props.bgSize : '')};
    ${(props) => (props.cursor ? 'cursor:' + props.cursor : '')};
    ${(props) => (props.zIndex ? 'z-index:' + props.zIndex : '')};
    ${(props) => (props.left ? 'left:' + props.left : null)};
    ${(props) => (props.top ? 'top:' + props.top : null)};
`;
Wrapper.propTypes = {
    flexHeight: PropTypes.string,
    fontSize: PropTypes.string,
    width: PropTypes.string,
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    align: PropTypes.string,
    padding: PropTypes.string,
    margin: PropTypes.string,
    display: PropTypes.string,
    flex: PropTypes.bool,
    shadow: PropTypes.bool,
    flexBasis: PropTypes.string,
    flexDirection: PropTypes.string,
    flexWrap: PropTypes.string,
    justify: PropTypes.string,
    height: PropTypes.string,
    minHeight: PropTypes.string,
    maxHeight: PropTypes.string,
    overflow: PropTypes.string,
    flexAlign: PropTypes.string,
    transition: PropTypes.string,
    opacity: PropTypes.string,
    position: PropTypes.string,
    nowrap: PropTypes.string,
    cursor: PropTypes.string,
    border: PropTypes.string,
    borderTop: PropTypes.string,
    borderBottom: PropTypes.string,
    borderRadius: PropTypes.string,
    bgColor: PropTypes.string,
    bg: PropTypes.string,
    bgSize: PropTypes.string,
    rounded: PropTypes.number,
    zIndex: PropTypes.number,
    boxShadow: PropTypes.string,
    boxSizing: PropTypes.string,
    left: PropTypes.string,
    top: PropTypes.string,
};
export default Wrapper;
