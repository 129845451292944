import {
    UI_SPORT_PRE_ACTIVE_SPORT,
    UI_SPORT_PRE_EVENTS_BY_SPORT_AND_TIME,
    UI_SPORT_PRE_SEARCH_TEXT,
} from '../constants/ui';

const initialState = {
    activeSportId: 0,
    preEventsBySportAndTime: null,
    searchText: null,
};

export default function uiSportPre(state = initialState, action) {
    switch (action.type) {
        case UI_SPORT_PRE_ACTIVE_SPORT:
            return { ...state, activeSportId: action.payload };
        case UI_SPORT_PRE_EVENTS_BY_SPORT_AND_TIME:
            return { ...state, preEventsBySportAndTime: action.payload };
        case UI_SPORT_PRE_SEARCH_TEXT:
            return { ...state, searchText: action.payload };
        default:
            return state;
    }
}
