import React from 'react';
import styled from 'styled-components';
import Text from '../../ui/Text';
import { COLORS } from '../../ui/topbar';
import CloseIcon from './assets/closeDark.svg';
import { hideModal as HideModal } from '../../actions/UiActions';
import { connect } from 'react-redux';
import { FadeInSpan } from '../../ui/animations/FadeIn2';
import UIModalNew from 'components/UIModalNew';
import withPopupWrapper from 'features/SpilID/withPopupWrapper';
import { detectMobile } from 'utils/UiHelper';
import Icon from '../../ecoui/Icon';

const StyledWrapper = styled.div`
    height: ${detectMobile() ? 'calc(100vh - 20px)' : '700px'};
    width: 100%;
    padding: 20px 25px;
    margin: ${detectMobile() ? '20px 0 0' : '0'};
    border-radius: 20px 20px 0 0;
    box-sizing: border-box;
    background: ${COLORS.white};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    overflow: auto;
`;

const Top = styled.div`
    height: auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin: 0 0 32px;
    position: relative;
`;

const Description = styled.div`
    height: auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 0 32px;
    position: relative;
`;

const Wrapper = detectMobile()
    ? StyledWrapper
    : withPopupWrapper(StyledWrapper);

const SpilIdDescriptionModal = ({ hideModal }) => {
    return (
        <FadeInSpan>
            <Wrapper>
                <Top>
                    <Icon
                        src={CloseIcon}
                        onClick={() => hideModal('SPIL_ID_DESCRIPTION_MODAL')}
                        color={'#c6ccdb'}
                        width={'20px'}
                        height={'20px'}
                        style={{ cursor: 'pointer', zIndex: 1 }}
                    />
                    <Text
                        align={'center'}
                        size={'20'}
                        lh={'26'}
                        weight={'bold'}
                        color={COLORS.dark}
                        margin={'0'}
                        padding={'0'}
                        style={
                            !detectMobile()
                                ? {
                                      position: 'relative',
                                      right: '10px',
                                  }
                                : {}
                        }
                    >
                        Spil ID
                    </Text>
                </Top>

                <Description>
                    <Text
                        align={'left'}
                        size={'22'}
                        lh={'26'}
                        weight={'bold'}
                        color={COLORS.dark}
                        margin={'0'}
                        padding={'0'}
                    >
                        Hvordan spiller jeg med Spil ID?
                    </Text>

                    <Text
                        align={'left'}
                        size={'16'}
                        lh={'21'}
                        color={COLORS.dark}
                        margin={'16px 0 0'}
                        padding={'0'}
                    >
                        Dit Spil ID består af en unik stregkode, som du skal
                        scanne på terminalen ved hvert køb hos forhandleren.
                        Følg instruktionen på skærmen, når du enten trykker
                        ‘Placer væddemål’ eller ‘Print kupon’.
                    </Text>
                    <Text
                        align={'left'}
                        size={'22'}
                        lh={'26'}
                        weight={'bold'}
                        color={COLORS.dark}
                        margin={'32px 0 0 '}
                        padding={'0'}
                    >
                        Hvorfor skal jeg benytte Spil ID hos forhandlerne?
                    </Text>

                    <Text
                        align={'left'}
                        size={'16'}
                        lh={'21'}
                        color={COLORS.dark}
                        margin={'16px 0 0'}
                        padding={'0'}
                    >
                        Det skal være trygt og sikkert at spille, og vi ønsker
                        ikke, at vores spil benyttes af unge under 18 år eller
                        er genstand for hvidvask og matchfixing. Derfor er Spil
                        ID obligatorisk, når du spiller sport betting (Bet25
                        Sport) eller hestevæddeløb (Bet25 Heste) hos
                        forhandlerne.
                    </Text>
                </Description>
            </Wrapper>
        </FadeInSpan>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: (modalId) => dispatch(HideModal(modalId)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(SpilIdDescriptionModal);
