import { useState } from 'react';
import storage from './../../features/PersistentStorage';
import { dateFromString } from '../../utils/DateHelper';
import { NotificationManager } from 'react-notifications';
import { SpilIdService } from '../../utils/SpilIdService';
import useAuthEffect from './useAuthEffect';
import UserService from '../../utils/UserService';

const COMMON_SPILID_ERROR =
    'Kan ikke oprette et spil-id-kort. Kontakt venligst vores support.';

const saveCardToStorage = (data) => {
    if (data.barcode && data.barcode.length > 10) {
        storage.saveLS({ spilIdCard: JSON.stringify(data) });
    }
};

export const getCardFromStorage = () => {
    const localStorageValue = storage.getLS('spilIdCard');
    if (localStorageValue) {
        return JSON.parse(localStorageValue);
    }
};

export const clearCachedStrangerSpilIdCard = (userId) => {
    const spilIdCard = getCardFromStorage();

    if (userId !== spilIdCard.user_id) {
        storage.removeLS('spilIdCard');
    }
};

const addBirthYearToCard = (spilIdCard, birthdate) => ({
    ...spilIdCard,
    year: dateFromString(birthdate).year(),
});

const useSpilIdCard = (auth) => {
    const loggedIn = Boolean(auth?.user); // TODO rewrite to if else user

    const [spilIdCard, setSpilIdCard] = useState(
        loggedIn ? {} : getCardFromStorage() || {}
    );

    const [pending, setPending] = useState(false);
    const [failed, setFailed] = useState(false);

    const [fullName, setFullName] = useState('');
    const [address, setAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [birthdate, setBirthdate] = useState('');
    //const [userInfoPending, setUserInfoPending] = useState(false);
    const [city, setCity] = useState('');
    const [retailPin, setRetailPin] = useState(0);

    let userInfoPending = false;

    const getAccauntInfo = async () =>
        UserService.InfoGet().then((res) => {
            if (res?.data) {
                setBirthdate(res.data?.birthDate);
                setCity(res.data?.city === null ? '' : res.data?.city);
                res.data.retailPin > 0 || '0000' === `${res.data.retailPin}`
                    ? setRetailPin(res.data.retailPin)
                    : setRetailPin(-1);
                userInfoPending = false;
                return res.data;
            }
        });

    async function fetchData() {
        try {
            setPending(true);
            //setUserInfoPending(true);
            userInfoPending = true;
            const user = await getAccauntInfo();

            let cachedSpilIdCard = getCardFromStorage();

            if (user && cachedSpilIdCard) {
                clearCachedStrangerSpilIdCard(user.id);
                cachedSpilIdCard = null;
            }

            const fullName = `${user?.firstName} ${user?.lastName}`;
            const address =
                user?.address === null || user?.zip === null
                    ? ''
                    : `${user?.address}, ${user?.zip}`;

            setFullName(fullName);
            setAddress(address);
            setZipCode(user.zip);

            if (cachedSpilIdCard) {
                setSpilIdCard(cachedSpilIdCard);
                return setPending(false);
            }
            const pin =
                user?.retailPin > 0 || '0000' === `${user.retailPin}`
                    ? user?.retailPin
                    : user.cprNumber?.slice(-4);

            SpilIdService.getSpilIdCardInfo({
                token: user.retailHash,
                userId: user.userId,
                pin: pin,
                app: 'bet25',
            }).then((response) => {
                if (response.success || response?.data?.found) {
                    const spilIdCard = addBirthYearToCard(
                        response.data,
                        user?.birthDate ? user?.birthDate : '',
                        retailPin
                    );
                    setSpilIdCard(spilIdCard);
                    saveCardToStorage(spilIdCard);
                } else {
                    SpilIdService.createSpilIdCard({
                        token: user.retailHash,
                        userId: user.userId,
                        app: 'bet25',
                        fullName,
                    }).then((response) => {
                        if (response.success) {
                            SpilIdService.getSpilIdCardInfo({
                                token: user.retailHash,
                                userId: user.userId,
                                app: 'bet25',
                                pin: user.cprNumber?.slice(-4),
                            }).then((response) => {
                                if (!response.success) {
                                    setFailed(true);
                                    return NotificationManager.error(
                                        COMMON_SPILID_ERROR
                                    );
                                }

                                const spilIdCard = addBirthYearToCard(
                                    response.data,
                                    user?.birthDate ? user?.birthDate : '',
                                    retailPin
                                );

                                setSpilIdCard(spilIdCard);
                                saveCardToStorage(spilIdCard);
                            });
                        }
                    });
                }
            });
        } catch (e) {
            console.log(e);
        } finally {
            setPending(false);
        }
    }

    useAuthEffect(fetchData, auth);

    return {
        pending,
        userInfoPending,
        failed,
        spilIdCard,
        fullName,
        address,
        zipCode,
        birthdate,
        city,
        retailPin,
    };
};

export default useSpilIdCard;
