import { makeRequest } from 'utils/REST';
import { PUNTER_PATH } from 'constants/appConfig';

class AuthService {
    request(requestType, url, data) {
        return makeRequest(requestType, url, data)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                return data;
            });
    }

    post(method, data) {
        return this.request('post', PUNTER_PATH + method, data);
    }

    get(method, data) {
        return this.request('get', PUNTER_PATH + method, data);
    }

    isUserLoggedIn() {
        return this.get('isLoggedIn');
    }

    isUserDerbyPermission(on, email) {
        return this.post('derbyPermission', {
            on: on ? 1 : 0,
            email: email,
        });
    }

    login(userName, password, channel) {
        return this.post('channelLogin', {
            userName,
            password,
            channel: channel,
            version: 2,
        });
    }

    initLoginNemID() {
        return this.get('getNemidParameters', { mode: 'lmt', language: 'da' });
    }

    loginNemID(result, signature) {
        return this.post('nemid_login', { result, signature });
    }

    logout() {
        return this.post('logout');
    }

    resetPassword(username, email) {
        return this.post('resetPassword', { userName: username, email });
    }

    signupStep1(cpr) {
        return this.post('signup_step1', { cpr });
    }

    signupStep2(result, signature) {
        return this.post('signup_step2', { result, signature });
    }
    getSecurityQuestionList() {
        return this.get('getSecurityQuestionList');
    }
    getExpectedBetting() {
        return this.get('getExpectedBetting');
    }

    signupStep3(vars, userData) {
        const step2Data = vars;

        if (
            step2Data.firstname !== userData.firstName ||
            step2Data.lastname !== userData.lastName ||
            step2Data.address !== userData.address ||
            step2Data.zip !== userData.zip ||
            step2Data.city !== userData.city
        ) {
            vars.changed = true;
        }

        vars.expected = userData.expectedBet;
        vars.newsletter = userData.newsletter;

        userData = {
            userName: userData.username,
            password: userData.password,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            currency: vars.currency,
            birthDate: vars.birthdate,
            gender: vars.gender,
            address: userData.address,
            city: userData.city,
            zip: userData.zip,
            country: vars.country,
            phoneNr: userData.mobileNr,
            mobileNr: userData.mobileNr,
            securityQuestion: userData.securityQuestion,
            securityAnswer: userData.securityAnswer,
            temp: userData.temp,
        };

        return this.post('signup_step3', { ...userData });
    }

    register() {
        return this.post('register');
    }

    subscription(vars) {
        if (vars.newsletter === true) {
            this.post('newsletterSubscription', { on: 1 }).then((res) => {
                res.success
                    ? console.log('Newsletter Subscription OK')
                    : console.error(
                          'Newsletter Unsubscription err: ' + res.errorMessage
                      );
            });
        } else {
            this.post('newsletterSubscription', { on: 0 }).then(
                (res) =>
                    res.success
                        ? console.log('Newsletter Unsubscription OK')
                        : console.error(
                              'Newsletter Unsubscription err: ' +
                                  res.errorMessage
                          )
            );
        }
    }

    expectedBetting(expectBetting) {
        this.post('setExpectedBetting', { expectBetting }).then(() => {});
    }

    CreateHash(pin) {
        return this.post('createHash', { pin });
    }
    ClearHash(pin) {
        return this.post('clearHash', { pin });
    }

    fetchHash() {
        return this.get('fetchHash');
    }

    LoginPIN(pin, hash, userName) {
        return this.post('loginPin', { pin, hash, userName, version: '2' });
    }

    SetRetailPin(pin) {
        return this.post('setRetailPin', { retailPin: pin });
    }
}

export default new AuthService();
