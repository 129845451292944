import styled from 'styled-components';
import CloseIcon from './../images/close.png';

const DesktopModalWrapper = styled.div`
    width: ${(props) => (props.width ? props.width : 'auto')};
    height: ${(props) => (props.height ? props.height : 'auto')};
    min-height: ${(props) => (props.minHeight ? props.minHeight : 'auto')};
    padding: 10px;
    background: ${(props) =>
        props.color ? props.color : !props.popupMode ? '#fff' : 'none'};
    margin: 10px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    position: relative;
`;

const CloseModalBtn = styled.div`
    width: 30px;
    height: 30px;
    background: url(${CloseIcon}) center center #fff no-repeat;
    background-size: 12px;
    border-radius: 15px;
    position: absolute;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    right: -10px;
    top: -15px;
    cursor: pointer;
    z-index: 2;
`;

const CommonDesktopModalLayer = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: ${(props) => props.bg || 'rgba(0,0,0, 0.79)'};
    overflow: auto;
    z-index: 111;
    &:before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        filter: blur(1px);
    }
`;
const TransparentDesktopModalLayer = styled(CommonDesktopModalLayer)`
    background: rgba(0, 0, 0, 0.7);
`;

const CommonDesktopModalWrapper = styled(DesktopModalWrapper)`
    height: ${({ height = '900px' }) => height};
    max-height: 90%;
    width: ${({ width = '600px' }) => width};
    border-radius: 10px;
`;

export {
    CloseModalBtn,
    CommonDesktopModalLayer,
    TransparentDesktopModalLayer,
    CommonDesktopModalWrapper,
};

export default DesktopModalWrapper;
