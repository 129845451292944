import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as SportTreeActions from '../actions/SportTreeActions';
import * as AuthActions from '../actions/AuthActions';
import * as UiActions from '../actions/UiActions';
import DesktopAppToolbar from '../components/DesktopAppToolbar';
import DesktopLeftSidebar from 'features/DesktopLeftSidebar';
import SectionRightSidebar from 'features/RightSidebar';
import '../css/DesktopLayout.scss';
import VerificationModal from '../modals/VerificationModal';
import CasinoContainer from '../containers/CasinoContainer';
import { ProductsTransitionBar } from '@it25syv/25syv-ui';
import styled from 'styled-components';
import rofus from 'images/footer/spillemyndigheden.png';
import betImg from 'images/bet.png';
import rodImg from 'images/top-bar/rod.png';
import derbyImg from 'images/derby.png';
import Footer from 'components/Footer';
import DesktopModals from 'modals/DesktopModals';
import { debounce } from 'utils/Helper';
import { browserHistory } from 'react-router';
import { NotificationManager } from 'react-notifications';

//TODO images

const FixedWrapper = styled.div`
    position: fixed;
    height: 60px;
    z-index: 10;
    width: calc(
        100% -
            ${({ isLeftSideBarOpen, isFullWidth }) =>
                isFullWidth ? '0px' : isLeftSideBarOpen ? '250px' : '60px'}
    );
    left: ${({ isLeftSideBarOpen, isFullWidth }) =>
        isFullWidth ? '0' : isLeftSideBarOpen ? '250px' : '60px'};
    transition: all 0.2s ease;
    @media screen and (max-width: 700px) {
        height: 80px;
    }
`;

const StyledProductsTransitionBar = styled(ProductsTransitionBar)`
    z-index: 10;
    box-sizing: border-box;
    height: 60px;
    @media screen and (max-width: 700px) {
        height: 80px;
    }
    span,
    div,
    svg {
        color: white;
        @media screen and (max-width: 1450px) {
            font-size: 12px;
        }
        @media screen and (max-width: 1290px) {
            font-size: 10px;
        }
    }
`;

class DesktopLayout extends Component {
    constructor() {
        super();

        this.state = {
            higlight: true,
            topBarShow: true,
            screenWidth: 0,
            customUserAreaTab: '',
        };
        this.routesWithoutSidebars = ['/log-ind', '/sport/log-ind'];
        this.topbarRef = React.createRef();
        this.headerRef = React.createRef();
        this.layoutRef = React.createRef();
    }

    debouncedResizeHandler = debounce(() => {
        this.setState({ screenWidth: window.innerWidth });
    }, 100);

    componentDidMount() {
        window.addEventListener('resize', this.debouncedResizeHandler);

        this.props.sportTreeAction.getSportTree();

        if (window.location.pathname === '/opret-bruger') {
            this.props.uiActions.setSignupModalStatus(true);
        }
        if (location.pathname.indexOf('/mitid-verify/confirm') > -1) {
            const idp = location.pathname.split('/').reverse()[0]; // todo rework ???
            this.props.uiActions.showModal('CPR_CONFIRMATION', { idp });
        }
        if (
            window.location.pathname === '/sport/log-ind' ||
            window.location.pathname === '/log-ind' ||
            window.location.pathname === '/login'
        ) {
            this.props.uiActions.showModal('LOGIN', { stayOnPage: true });
        }

        let isLeftSideBarOpen =
            window.localStorage.getItem('isLeftSideBarOpen') == null
                ? false
                : window.localStorage.getItem('isLeftSideBarOpen') === 'true';
        this.props.uiActions.setLeftSidebarStatus(isLeftSideBarOpen);

        window.Intercom('boot', {
            app_id: 'qkqulpbi',
            last_active_on: 'bet25_web',
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        /*let user = this.props.auth.user;
        if (
            !prevProps.auth.user &&
            this.props.auth.user &&
            user?.limits?.dailyAmount === 0 &&
            user?.limits?.weeklyAmount === 0 &&
            user?.limits?.monthlyAmount === 0 &&
            user?.limits?.dailyAmountChange === 0 &&
            user?.limits?.weeklyAmountChange === 0 &&
            user?.limits?.monthlyAmountChange === 0
        ) {
            this.props.uiActions.showModal('FAST_DEPOSIT_POPUP');
        }*/

        if (
            window.location.pathname === '/sport/log-ind' ||
            window.location.pathname === '/log-ind' ||
            window.location.pathname === '/login'
        ) {
            this.props.uiActions.showModal('LOGIN', { stayOnPage: true });
        }

        if (
            [
                '/sport/konto/indbetaling',
                '/sport/konto/udbetaling',
                '/sport/konto/bonus',
                '/sport/konto/voucher',
                '/sport/konto/kontohistorik',
                '/sport/konto/historik/aktive',
                '/sport/konto/historik/afsluttede',
                '/sport/konto/historik/big9',
                '/sport/konto/historik/butik',
                '/sport/konto/indbetalingsgraense',
                '/sport/konto/selvudelukkelse',
                '/sport/konto/brugerinfo',
                '/sport/konto/klubbet-tilmelding',
                '/sport/konto/hold-kode',
                '/sport/konto/nyhedsbrev',
            ].indexOf(window.location.pathname) > -1
        ) {
            if (!this.props.auth.user && this.props.auth.sessionError) {
                this.props.uiActions.showModal('LOGIN_FIRST');
            } else if (this.props.auth.user) {
                this.props.uiActions.showModal('USER_MENU');
            }
        }
        if (
            prevProps.auth.user &&
            !this.props.auth.user &&
            this.props.auth.sessionError
        ) {
            this.props.uiActions.showModal('SESSION_POPUP');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.loginError) {
            NotificationManager.error('', nextProps.auth.loginError, 4000);
            this.props.authActions.reset();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedResizeHandler);
    }

    onUserBtnsClickHandler() {
        const currentPath = window.location.pathname;
        const kontoBasePath = '/sport/konto';
        if (!currentPath.startsWith(kontoBasePath)) {
            browserHistory.push(`${kontoBasePath}/indbetaling`);
        }
        this.props.uiActions.showModal('USER_MENU');
    }

    onBurgerClickHandler() {
        window.localStorage.setItem(
            'isLeftSideBarOpen',
            (!this.props.isLeftSideBarOpen).toString()
        );
        this.props.uiActions.setLeftSidebarStatus(
            !this.props.isLeftSideBarOpen
        );
    }

    isAsideBarsHide() {
        const { pathname } = this.props.routing.locationBeforeTransitions;
        return this.routesWithoutSidebars.indexOf(pathname) !== -1;
    }
    isFullWidth() {
        return (
            window.location.pathname === '/log-ind' ||
            window.location.pathname === '/sport/log-ind'
        );
    }

    render() {
        const getTopbarHeight = (withHeader = false) => {
            return withHeader
                ? this.topbarRef?.current?.clientHeight +
                      this.headerRef?.current?.clientHeight ?? 120
                : this.topbarRef?.current?.clientHeight ?? 60;
        };
        const isAsideBarsHide = this.isAsideBarsHide();
        const authUser = this.props.auth.user;
        const loginTime =
            authUser && authUser.extraInfo
                ? authUser.extraInfo.loginTime
                : null;

        return (
            <div>
                <div
                    className={classNames('layout desktop', {
                        'hide-sidebars': isAsideBarsHide,
                        'mobile-site-page':
                            this.props.routing.locationBeforeTransitions
                                .pathname === '/mobil',
                        'login-page':
                            this.props.routing.locationBeforeTransitions
                                .pathname === '/log-ind' ||
                            this.props.routing.locationBeforeTransitions
                                .pathname === '/sport/log-ind',
                        'signup-page':
                            this.props.routing.locationBeforeTransitions
                                .pathname === '/sport/opret-konto' ||
                            this.props.routing.locationBeforeTransitions
                                .pathname === '/opret-konto',
                        'with-top-bar': this.state.topBarShow,
                    })}
                    ref={this.layoutRef}
                >
                    {this.state.topBarShow && (
                        <FixedWrapper
                            ref={this.topbarRef}
                            isLeftSideBarOpen={this.props.isLeftSideBarOpen}
                            isFullWidth={this.isFullWidth()}
                        >
                            <StyledProductsTransitionBar
                                rodLink={'/casino'}
                                derbyLink={'/heste'}
                                betLink={'/sport/'}
                                logoOnClickHandler={() =>
                                    browserHistory.push('/sport/')
                                }
                                px={2}
                                betImage={betImg}
                                rodImage={rodImg}
                                derbyImage={derbyImg}
                                rofusLogo={rofus}
                                lastLoginTime={loginTime}
                                showTimer={loginTime}
                                clockFormat={'HH:mm'}
                                timerFormat={'HH:mm'}
                                activeRodColor={'#c63328'}
                                activeBetColor={'#1C3E60'}
                                activeDerbyColor={'#299648'}
                                activeProject={'bet'}
                                background={'#1C3E60'}
                                color={'#c1afaf'}
                            />
                        </FixedWrapper>
                    )}

                    <DesktopAppToolbar
                        headerRef={this.headerRef}
                        style={{
                            top: getTopbarHeight() + 'px',
                        }}
                        withoutLogo={true}
                        onBurgerClickHandler={this.onBurgerClickHandler.bind(
                            this
                        )}
                        isLeftSideBarOpen={this.props.isLeftSideBarOpen}
                        onUserBtnsClick={this.onUserBtnsClickHandler.bind(this)}
                    />

                    <div
                        style={{
                            marginTop: getTopbarHeight(true) + 'px',
                        }}
                        className="layout-content"
                    >
                        <div className="middle-content-wrapper">
                            <div className="middle">
                                {!isAsideBarsHide && (
                                    <aside
                                        className={`left-sidebar ${
                                            this.props.isLeftSideBarOpen
                                                ? ''
                                                : 'closed-sidebar'
                                        }`}
                                    >
                                        <DesktopLeftSidebar
                                            onBurgerClickHandler={this.onBurgerClickHandler.bind(
                                                this
                                            )}
                                            setCustomUserAreaTab={(
                                                customUserAreaTab
                                            ) => {
                                                this.setState({
                                                    customUserAreaTab,
                                                });
                                            }}
                                            isOpen={
                                                this.props.isLeftSideBarOpen
                                            }
                                            sportTree={this.props.sportTree}
                                        />
                                    </aside>
                                )}
                                {!isAsideBarsHide && (
                                    <aside className="right-sidebar">
                                        <SectionRightSidebar
                                            highlightStatus={
                                                this.state.higlight
                                            }
                                        />{' '}
                                    </aside>
                                )}

                                <div className="container">
                                    <main
                                        className={`content ${
                                            this.props.isLeftSideBarOpen
                                                ? ''
                                                : 'closed-sidebar'
                                        } ${
                                            this.isFullWidth()
                                                ? 'full-width'
                                                : ''
                                        }`}
                                    >
                                        <div
                                            className={`white-container ${
                                                window.location.pathname.split(
                                                    '/'
                                                )[2] === 'big9' ||
                                                window.location.pathname.split(
                                                    '/'
                                                )[1] === 'kamp' ||
                                                window.location.pathname.split(
                                                    '/'
                                                )[2] === 'kamp'
                                                    ? 'big9Page'
                                                    : ''
                                            }`}
                                        >
                                            {this.props.children}
                                        </div>
                                    </main>
                                    <Footer
                                        isAsideBarsHide={isAsideBarsHide}
                                        isOpened={this.props.isLeftSideBarOpen}
                                        notTest={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="clearfix" />
                    <DesktopModals parent={this.layoutRef} />
                </div>

                <CasinoContainer />

                {this.props.auth.user && (
                    <VerificationModal
                        verificationModalStatus
                        user={this.props.auth.user}
                    />
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        sportTree: state.sportTree,
        auth: state.auth,
        betslip: state.betslip,
        routing: state.routing,
        isLeftSideBarOpen: state.ui.leftSidebarOpen,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sportTreeAction: bindActionCreators(SportTreeActions, dispatch),
        authActions: bindActionCreators(AuthActions, dispatch),
        uiActions: bindActionCreators(UiActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DesktopLayout);
