import {
    POPULAR_EVENTS_FETCH_ERROR,
    POPULAR_EVENTS_FETCH_PENDING,
    POPULAR_EVENTS_FETCH_SUCCESS,
} from 'constants/popularEvents';
import { ParsproService } from 'utils/ParsproService';
import TerminalRestService from 'utils/TerminalRestService';
import { isTerminal } from 'utils/UiHelper';
import VAIXService from '../utils/VAIXService';
import { getEventsByVaixIds } from '../utils/Helper';

export function getPopularEvents(withoutPending, userId) {
    return async (dispatch) => {
        if (!withoutPending) {
            dispatch({
                type: POPULAR_EVENTS_FETCH_PENDING,
                payload: true,
            });
        }

        if (!isTerminal()) {
            VAIXService.getVaixPopularEvents(userId || 0, 48, 25)
                .then(async (res) => {
                    const { data } = res;
                    if (data) {
                        let events = await getEventsByVaixIds(data);
                        dispatch({
                            type: POPULAR_EVENTS_FETCH_SUCCESS,
                            payload: {
                                data: events,
                                vaixIsFetched: true,
                            },
                        });
                    } else {
                        try {
                            ParsproService.getPopularEvents().then((res) => {
                                const { data } = res;
                                dispatch({
                                    type: POPULAR_EVENTS_FETCH_SUCCESS,
                                    payload: {
                                        data: data,
                                        vaixIsFetched: false,
                                    },
                                });
                            });
                        } catch (error) {
                            dispatch({
                                type: POPULAR_EVENTS_FETCH_ERROR,
                                payload: error.toString(),
                            });
                        }
                    }
                })
                .catch((error) => {
                    try {
                        ParsproService.getPopularEvents().then((res) => {
                            const { data } = res;
                            dispatch({
                                type: POPULAR_EVENTS_FETCH_SUCCESS,
                                payload: {
                                    data,
                                    vaixIsFetched: false,
                                },
                            });
                        });
                    } catch (error) {
                        dispatch({
                            type: POPULAR_EVENTS_FETCH_ERROR,
                            payload: error.toString(),
                        });
                    }
                });
        } else {
            TerminalRestService.getPopularEvents((data) => {
                dispatch({
                    type: POPULAR_EVENTS_FETCH_SUCCESS,
                    payload: {
                        data,
                        vaixIsFetched: false,
                    },
                });
            });
        }
    };
}
