import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { browserHistoryGoBack } from 'utils/UiHelper';
import * as UiActions from '../actions/UiActions';
import { setAppToolbarToPendingStatus } from 'actions/AuthActions';
import RestService from 'utils/RestService';
import ViewSpinner from 'components/ViewSpinner';
import 'css/AppToolbar.scss';
import { TopBar } from '@it25syv/25syv-ui';

class AppToolbar extends Component {
    constructor() {
        super();
    }

    goToDeposit = () => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'bet25.clickToPaymentSystem',
                userId: this.props.auth?.user?.userId,
            });
        }

        browserHistory.push('/sport/konto/indbetaling');
    };

    loginModalOpen() {
        /**
         * shows spinner during RegisterDevice req
         */
        this.props.setAppToolbarToPendingStatus(true);

        /**
         * with requestion RegisterDevice we will know if there pin set (f.e from derby)
         */
        RestService.RegisterDevice(null, 'bet25-web', (pin_set) => {
            this.props.setAppToolbarToPendingStatus(false);

            browserHistory.push('/sport/log-ind');
            this.props.uiActions.showModal('LOGIN_MOBILE');
        });
    }

    signupModalOpen() {
        browserHistory.push('/sport/opret-konto');
        /*if (window.dataLayer) {
            window.dataLayer.push({
                event: 'bet25.clickToSignup',
            });
        }*/
    }

    onUserBtnsClick() {
        if (this.props.onUserBtnsClick) {
            this.props.onUserBtnsClick();
        }
    }

    onBackClick() {
        browserHistoryGoBack('back');
    }

    /*onBurgerMenuClick() {
        this.props.burgerMenuHandler();
    }*/

    render() {
        return (
            <header
                style={this.props.style}
                className="view-top-toolbar"
                ref={this.props.headerRef}
            >
                {this.props.auth.loginPending ? (
                    <div className="toolbar-buttons">
                        <ViewSpinner />
                    </div>
                ) : (
                    <>
                        <TopBar
                            background={'#1C3E60'}
                            userEmail={
                                this.props.auth.user
                                    ? this.props.auth.user.email
                                    : ''
                            }
                            username={
                                this.props.auth.user
                                    ? this.props.auth.user.userName
                                    : ''
                            }
                            balance={
                                this.props.auth.user
                                    ? this.props.auth.user.balance
                                    : ''
                            }
                            secondColor={'#20486F'}
                            bankClickHandler={this.goToDeposit}
                            showUserArea={this.onUserBtnsClick.bind(this)}
                            signUpHandler={this.signupModalOpen.bind(this)}
                            showLoginModal={this.loginModalOpen.bind(this)}
                            withoutSidebar={true}
                            isTWA={this.props.isTWA}
                            // appLogo={this.props.appLogo}
                        />
                        {window.location.pathname.indexOf('kamp/') >= 0 ? (
                            <div
                                className="back-menu"
                                onClick={this.onBackClick.bind(this)}
                            >
                                <i
                                    className="fa fa-angle-left"
                                    aria-hidden="true"
                                />
                            </div>
                        ) : null}
                    </>
                )}
            </header>
        );
    }
}

function mapStateToProps(state) {
    return {
        loginModalStatus: state.ui.loginModalStatus,
        auth: state.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UiActions, dispatch),
        setAppToolbarToPendingStatus: () => {
            return {
                setAppToolbarToPendingStatus: dispatch(
                    setAppToolbarToPendingStatus()
                ),
            };
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppToolbar);
