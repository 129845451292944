import React, { Component } from 'react';
import classNames from 'classnames';
import '../css/ViewTabs.css';

let currentTabIndex = 0;

class ViewTabs extends Component {
    tabSelectedHandlers = new Map();

    constructor() {
        super();

        this.state = {
            currentTab: currentTabIndex,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.onTabSelected(nextProps.currentTab);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentDidMount() {
        currentTabIndex = this.props.currentTab;

        this.setState({ currentTab: currentTabIndex });
    }

    onTabSelected = (index) => {
        currentTabIndex = index;

        this.setState(() => ({ currentTab: currentTabIndex }));

        this.tabSelectedHandlers.forEach((handler) => handler());

        if (this.props.onTabChanged) {
            this.props.onTabChanged(currentTabIndex);
        }
    };

    onTabReady = (index, handler) => {
        this.tabSelectedHandlers.set(index, handler);
    };

    render() {
        return (
            <section
                className={classNames('view-tabs', { light: this.props.light })}
            >
                <header
                    className={classNames({
                        [this.props.className]: this.props.className,
                    })}
                >
                    {this.props.children.map((child, index) =>
                        React.cloneElement(child, {
                            onTabSelected: this.onTabSelected,
                            onTabReady: this.onTabReady,
                            index: String(index),
                            key: index,
                            tabsNumber: this.props.children.length,
                        })
                    )}
                </header>
                <section className="view-tabs-content">
                    {this.props.children[this.state.currentTab].props.children}
                </section>
            </section>
        );
    }
}

class ViewTab extends Component {
    constructor() {
        super();

        this.state = {
            currentTab: currentTabIndex,
        };
    }

    componentDidMount() {
        this.props.onTabReady(
            this.props.index,
            this.handlerTabSelected.bind(this)
        );
    }

    render() {
        return (
            <div
                className={classNames('view-tab', {
                    [this.props.className]: this.props.className,
                    active: this.props.index === this.state.currentTab,
                })}
                onClick={this.handleTabClick.bind(this)}
            >
                {this.props.label}
                {!!this.props.badge && (
                    <div className="notification-badge">{this.props.badge}</div>
                )}
            </div>
        );
    }

    handlerTabSelected() {
        this.setState({ currentTab: currentTabIndex });
    }

    handleTabClick() {
        currentTabIndex = this.props.index;
        this.props.onTabSelected(currentTabIndex);
        this.setState(() => ({ currentTab: currentTabIndex }));
    }
}

class ViewIconClassTab extends ViewTab {
    render() {
        const style = {
            width: 100 / this.props.tabsNumber + '%',
        };

        return (
            <div
                style={style}
                className={classNames('view-tab', {
                    active: this.props.index === this.state.currentTab,
                })}
                onClick={this.handleTabClick.bind(this)}
            >
                <div
                    className={classNames({
                        [this.props.className]: this.props.className,
                        active: this.props.index === this.state.currentTab,
                    })}
                />
            </div>
        );
    }
}

export { ViewTabs, ViewTab, ViewIconClassTab };
