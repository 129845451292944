import { useQuery } from '@tanstack/react-query';
import Big9Service from 'utils/Big9Service';
import { useEffect, useState } from 'react';
import { isTerminal } from 'utils/UiHelper';
import Big9ServiceTerminal from 'utils/Big9ServiceTerminal';

const requestByPlatform = isTerminal() ? Big9ServiceTerminal : Big9Service;

const useOffersData = () => {
    const [extId, setExtId] = useState('');

    const fetchOffers = async () => {
        const res = await requestByPlatform.getOffers();
        return res.data;
    };

    const { status, data, error, refetch } = useQuery(['offers'], fetchOffers, {
        keepPreviousData: true,
        staleTime: 60 * 1000,
    });

    useEffect(
        () => {
            if (status === 'success') {
                const offer = data.filter(
                    (offer) => offer.status === 'SELL_OPEN'
                )[0];
                if (offer) {
                    setExtId(offer.extId);
                }
            }
        },
        [data]
    );

    return { offers: data, status, error, refetch, extId };
};

export default useOffersData;
