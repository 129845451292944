import {
    PRE_EVENTS_FETCH_ERROR,
    PRE_EVENTS_FETCH_PENDING,
    PRE_EVENTS_FETCH_SUCCESS,
} from 'constants/preEvents';

import { ParsproService } from 'utils/ParsproService';
import TerminalRestService from 'utils/TerminalRestService';
import { isTerminal } from 'utils/UiHelper';

export function getPreEvents(sports) {
    return (dispatch) => {
        dispatch({
            type: PRE_EVENTS_FETCH_PENDING,
            payload: true,
        });

        if (!isTerminal()) {
            ParsproService.getPreEvents()
                .then((res) => {
                    const data = res.data;
                    dispatch({
                        type: PRE_EVENTS_FETCH_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: PRE_EVENTS_FETCH_ERROR,
                        payload: error.errorMessage.toString(),
                    });
                });
        } else {
            TerminalRestService.getPreEvents((data) => {
                dispatch({
                    type: PRE_EVENTS_FETCH_SUCCESS,
                    payload: data,
                });
            });
        }
    };
}

const filterDuplicates = (events) =>
    events?.reduce((acc, event) => {
        if (!acc.find((item) => item.eventId === event.eventId)) {
            acc.push(event);
        }
        return acc;
    }, []);

export function getTodayEvents(sports) {
    return async (dispatch) => {
        dispatch({
            type: PRE_EVENTS_FETCH_PENDING,
            payload: true,
        });

        if (!isTerminal()) {
            try {
                ParsproService.getTodayEvents(sports).then((res) => {
                    const data = res.data;
                    dispatch({
                        type: PRE_EVENTS_FETCH_SUCCESS,
                        payload: filterDuplicates(data),
                    });
                });
            } catch (error) {
                dispatch({
                    type: PRE_EVENTS_FETCH_ERROR,
                    payload: error.toString(),
                });
            }
        } else {
            TerminalRestService.getTodayEvents((data) => {
                dispatch({
                    type: PRE_EVENTS_FETCH_SUCCESS,
                    payload: data,
                });
            });
        }
    };
}

export function getBetslipEvents() {
    return async (dispatch) => {
        dispatch({
            type: PRE_EVENTS_FETCH_PENDING,
            payload: true,
        });

        TerminalRestService.getBetslipEvents((data) => {
            dispatch({
                type: PRE_EVENTS_FETCH_SUCCESS,
                payload: data,
            });
        });
    };
}
