const SET_MARKS = 'SET_MARKS';
const SET_COUPON_DATA = 'SET_COUPON_DATA';
const SET_COUPON_ITEMS = 'SET_COUPON_ITEMS';
const CLEAR_MARKS = 'CLEAR_MARKS';
const COMPLETE_COUPON = 'COMPLETE_COUPON';
const VALIDATE_COUPON = 'VALIDATE_COUPON';
const FORMATTING_MARKS = 'FORMATTING_MARKS';
const SET_WILDCARD = 'SET_WILDCARD';
const SET_VALID = 'SET_VALID';
const CHANGE_COMBINATION_PRICE = 'CHANGE_COMBINATION_PRICE';
const SET_COMBINATION_COUNT = 'SET_COMBINATION_COUNT';

export const setMarks = (outcomes) => {
    return (dispatch) => {
        dispatch({
            type: SET_MARKS,
            payload: outcomes,
        });
    };
};
export const setCouponData = (couponData) => {
    return (dispatch) => {
        dispatch({
            type: SET_COUPON_DATA,
            payload: couponData,
        });
    };
};
export const setInitialCouponItems = (items) => {
    return (dispatch) => {
        dispatch({
            type: SET_COUPON_ITEMS,
            payload: items,
        });
    };
};
export const clearMarks = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_MARKS,
        });
    };
};
export const completeCoupon = () => {
    return (dispatch) => {
        dispatch({
            type: COMPLETE_COUPON,
        });
    };
};

export const validateCoupon = (marks) => {
    return (dispatch) => {
        dispatch({
            type: VALIDATE_COUPON,
            payload: marks,
        });
    };
};

export const formattingMarks = (marks) => {
    return (dispatch) => {
        dispatch({
            type: FORMATTING_MARKS,
            payload: marks,
        });
    };
};
export const setWildcard = (key) => {
    return (dispatch) => {
        dispatch({
            type: SET_WILDCARD,
            payload: key,
        });
    };
};
export const setValidState = (validationState) => {
    return (dispatch) => {
        dispatch({
            type: SET_VALID,
            payload: validationState,
        });
    };
};
export const changeCombinationPrice = (price) => {
    return (dispatch) => {
        dispatch({
            type: CHANGE_COMBINATION_PRICE,
            payload: price,
        });
    };
};
export const setCombinationCount = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_COMBINATION_COUNT,
            payload: data,
        });
    };
};

export {
    SET_MARKS,
    SET_COUPON_DATA,
    SET_COUPON_ITEMS,
    CLEAR_MARKS,
    COMPLETE_COUPON,
    VALIDATE_COUPON,
    FORMATTING_MARKS,
    SET_WILDCARD,
    SET_VALID,
    CHANGE_COMBINATION_PRICE,
    SET_COMBINATION_COUNT,
};
