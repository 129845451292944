import {
    VAIX_EVENTS_FETCH_PENDING,
    VAIX_LIVE_FAVORITE_EVENTS_SHOULD_BE_UPDATED,
    VAIX_LIVE_FAVORITE_EVENTS_IDS_SET,
    VAIX_LIVE_FAVORITE_EVENTS_IDS_FETCHED,
    VAIX_LIVE_EVENTS_IDS_RESET,
    VAIX_LIVE_EVENTS_NAVIGATION_ORDER_SET,
    VAIX_LIVE_EVENTS_NAVIGATION_ORDER_RESET,
    VAIX_LIVE_EVENTS_NAVIGATION_ORDER_FETCHED,
} from 'constants/vaix/vaixEvents';
import VAIXService from '../utils/VAIXService';
import {
    getArrayOfNumbersFromStringInArrayOfObjects,
    sortEventsByVaixPriority,
} from '../utils/Helper';

export function getVaixLiveFavoriteEventsIds(
    userId = 0,
    prevStateFavoriteEventsIds = []
) {
    return (dispatch) => {
        dispatch({
            type: VAIX_EVENTS_FETCH_PENDING,
            payload: true,
        });
        dispatch({
            type: VAIX_LIVE_FAVORITE_EVENTS_IDS_FETCHED,
            payload: false,
        });
        VAIXService.getVaixLiveFavoriteEvents(userId, -3, 0, 30)
            .then((res) => {
                let { data } = res;
                if (data) {
                    data.filter((item) => item.status === 'live');
                    let sortedData = sortEventsByVaixPriority(data);

                    let eventsIds = getArrayOfNumbersFromStringInArrayOfObjects(
                        sortedData,
                        'event_id'
                    );

                    dispatch({
                        type: VAIX_LIVE_FAVORITE_EVENTS_IDS_SET,
                        payload: eventsIds,
                    });
                    dispatch({
                        type: VAIX_LIVE_FAVORITE_EVENTS_SHOULD_BE_UPDATED,
                        payload: true,
                    });
                } else {
                    dispatch({
                        type: VAIX_EVENTS_FETCH_PENDING,
                        payload: false,
                    });
                    dispatch({
                        type: VAIX_LIVE_FAVORITE_EVENTS_IDS_FETCHED,
                        payload: true,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: VAIX_EVENTS_FETCH_PENDING,
                    payload: false,
                });
                dispatch({
                    type: VAIX_LIVE_FAVORITE_EVENTS_IDS_FETCHED,
                    payload: true,
                });
            });
    };
}

export function resetVaixLiveEventsIds() {
    return (dispatch) => {
        dispatch({
            type: VAIX_EVENTS_FETCH_PENDING,
            payload: true,
        });
        dispatch({
            type: VAIX_LIVE_EVENTS_IDS_RESET,
            payload: [],
        });
    };
}

export function getVaixLiveNavigationOrder(userId = 0) {
    return (dispatch) => {
        dispatch({
            type: VAIX_EVENTS_FETCH_PENDING,
            payload: true,
        });
        dispatch({
            type: VAIX_LIVE_EVENTS_NAVIGATION_ORDER_FETCHED,
            payload: false,
        });
        VAIXService.getVaixNavigationOrder(userId, -3, 0, 30)
            .then((res) => {
                let { data } = res;
                if (data) {
                    let order = getArrayOfNumbersFromStringInArrayOfObjects(
                        data.sort((a, b) => b.confidence - a.confidence),
                        'sport_id'
                    );

                    dispatch({
                        type: VAIX_LIVE_EVENTS_NAVIGATION_ORDER_SET,
                        payload: order,
                    });
                } else {
                    dispatch({
                        type: VAIX_LIVE_EVENTS_NAVIGATION_ORDER_FETCHED,
                        payload: true,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: VAIX_LIVE_EVENTS_NAVIGATION_ORDER_FETCHED,
                    payload: true,
                });
            });
    };
}

export function resetVaixLiveNavigationOrder() {
    return (dispatch) => {
        dispatch({
            type: VAIX_EVENTS_FETCH_PENDING,
            payload: true,
        });
        dispatch({
            type: VAIX_LIVE_EVENTS_NAVIGATION_ORDER_RESET,
            payload: [],
        });
    };
}

export function updateVaixLiveEventsComponentStatus(data) {
    return (dispatch) => {
        dispatch({
            type: VAIX_LIVE_FAVORITE_EVENTS_SHOULD_BE_UPDATED,
            payload: data,
        });
    };
}
