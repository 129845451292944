import { formatDenmarkCurrency } from '../../utils/UiHelper';

export const getButtonAmount = (betslip, auth, freeBetEnabled) => {
    if (freeBetEnabled && auth?.user?.freeBet > 0) {
        return `${auth?.user?.freeBet} kr.`;
    } else {
        if (betslip.hasMarkets.length > 1 && !betslip?.customBetEventId > 0) {
            return `${formatDenmarkCurrency(betslip.system.totalStake)} kr.`;
        } else {
            if (betslip?.singleStake > 0 || betslip?.customBetStake > 0) {
                if (betslip?.customBetEventId > 0) {
                    return `${formatDenmarkCurrency(
                        betslip.customBetStake
                    )} kr.`;
                } else {
                    return `${formatDenmarkCurrency(betslip?.singleStake)} kr.`;
                }
            } else {
                return '';
            }
        }
    }
};

export const getButtonFreeBetLabel = (auth, freeBetEnabled) => {
    if (
        (auth?.user?.freeBetSettleType ||
            auth?.user?.freeBetType ||
            auth?.user?.freeBetLive ||
            auth?.user?.freeBet > 0) &&
        freeBetEnabled
    ) {
        if (auth?.user?.freeBetSettleType === 1) {
            return 'Freebet';
        } else {
            if (auth?.user?.freeBetLive === 1) {
                return 'Live Bet';
            } else {
                return 'Ekstra Bet';
            }
        }
    } else {
        return '';
    }
};

export const isPlaceBetDisabled = (betslip, user, freeBetEnabled) => {
    if (freeBetEnabled && user?.freeBet > 0) {
        return false;
    } else if (
        betslip.hasMarkets.length > 1 &&
        !betslip?.customBetEventId > 0
    ) {
        return (
            betslip.system.stake.every((bet) => bet === 0 || bet < 5) ||
            betslip.system.stake.some((bet) => bet > 0 && bet < 5)
        );
    } else {
        return betslip?.customBetEventId > 0
            ? betslip?.customBetStake < 10 ||
                  typeof betslip?.customBetStake === 'undefined'
            : betslip?.singleStake < 10;
    }
};

export const showSingleCustomBetErrorMessage = (betslip, freeBetEnabled) => {
    return (
        (betslip.singleStake > 0 &&
            betslip.singleStake < 10 &&
            !freeBetEnabled) ||
        (betslip?.customBetStake > 0 &&
            betslip?.customBetStake < 10 &&
            !freeBetEnabled)
    );
};

export const getPossibleWinAmount = (
    isMultiBet,
    freeBetEnabled,
    freeBetAmount,
    isLiveFreeBet,
    betslip
) => {
    if (isMultiBet) {
        return freeBetEnabled && freeBetAmount > 0
            ? `${(freeBetAmount * betslip.totalOdds - freeBetAmount).toFixed(
                  2
              )}`
            : betslip.system.totalPrize.toFixed(2);
    } else {
        if (freeBetEnabled && freeBetAmount > 0) {
            if (isLiveFreeBet) {
                return `${(freeBetAmount * betslip.totalOdds).toFixed(2)}`;
            } else {
                return `${(
                    freeBetAmount * betslip.totalOdds -
                    freeBetAmount
                ).toFixed(2)}`;
            }
        } else {
            return betslip.isCustomBet
                ? betslip.customBetPrize > 0
                    ? betslip.customBetPrize.toFixed(2)
                    : '0.00'
                : betslip.singlePrize.toFixed(2);
        }
    }
};
