import React from 'react';
import styled, { css } from 'styled-components';
import ArrowIcon from './assets/arrowWhite.svg';
import QuestionIcon from './assets/question.svg';
import CloseIcon from './assets/close.svg';

import cardsImage from './assets/cards.jpg';
import { rowPreloaderAnimation } from 'ui/animations/RowPreloader';
import Text from '../../ui/Text';
import { BACKGROUNDS } from '../../themes/colors';
import Icon from '../../ecoui/Icon';
import { detectMobile } from 'utils/UiHelper';
import { breakpoint } from 'constants/breakpoints';

export const Title = styled.div`
    font-size: 22px;
    font-weight: 900;
`;

export const Wrapper = styled.div`
    height: ${({ height }) => height || 'auto'};
    padding: ${({ padding }) => padding || '20px 0'};
    width: 100%;
    font-size: 20px;
    color: #fff;
    font-weight: 900;
    display: flex;
    box-sizing: border-box;
    flex-direction: ${({ fDirection }) => fDirection || 'row'};
    align-items: ${({ aItems }) => aItems || 'center'};
    justify-content: space-between;
    z-index: 1;
`;

export const PhysicalCardWrapper = styled.div`
    height: auto;
    min-height: ${detectMobile() ? '100%' : '700px'};
    min-height: ${detectMobile() && 'var(--app-height)'};
    background: ${BACKGROUNDS.white};
    width: 100%;
    box-sizing: border-box;
`;

const StyledIcon = styled(Icon)`
    cursor: pointer;
    ${({ isBack }) =>
        isBack &&
        css`
            transform: rotate(180deg);
        `};
`;

export const CardsImage = styled.div`
    background: url(${cardsImage}) center center / cover no-repeat;
    flex: ${detectMobile() ? '1 1 auto' : '3 1 auto'};
`;

export const PreviewButtons = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: ${({ padding }) => padding || '0'};
    margin: 0;

    & button {
        max-width: calc(50% - 5px);
        width: 100%;
        flex-grow: 1;
        margin: 0;
        height: 55px;
    }
`;

export const PhysicalCardFormWrapper = styled.div`
    padding-top: 40px;
`;

export const FormModalWrapper = styled.div`
    display: flex;
    height: auto;
    min-height: ${detectMobile() ? '100%' : '700px'};
    min-height: ${detectMobile() && 'var(--app-height)'};
    justify-content: space-between;
    flex-direction: column;
    background: ${BACKGROUNDS.white};
`;

export const FadedBackground = styled.div`
    z-index: 5;
    background: ${(props) =>
        props.shown ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0)'};
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    min-height: 100%;
    width: 100%;
    transition: 500ms ease all;
    visibility: ${(props) => (props.shown ? 'shown' : 'hidden')};
`;

export const SuccessPopupFaded = styled(FadedBackground)`
    box-sizing: border-box;
    padding: 10px;
`;

export const SuccessPopupContainer = styled.div`
    transition: 500ms ease all;
    background: #fff;
    border-radius: 8px;
    position: fixed;
    bottom: ${(props) => (props.shown ? '16px' : '-300px')};
    visibility: ${(props) => (props.shown ? 'shown' : 'hidden')};
    left: 10px;
    width: calc(100% - 20px);
    height: auto;
    box-sizing: border-box;
    padding: 16px 22px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const SuccessPopupDescription = styled.div`
    margin: 35px 0 38px;
    padding: 0 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-width: 335px;
    height: auto;
    box-sizing: border-box;

    @media screen and ${breakpoint.XS} {
        min-width: auto;
        padding: 0;
    }
`;

export const SpilIdBarcodePreloader = styled.div`
    border-radius: 14px;
    width: 100%;
    height: 80px;
    padding: 0;

    ${rowPreloaderAnimation};
`;

export const NavigationBar = ({
    title,
    clickHandler,
    show,
    isClose = false,
    iconColor = '#c6ccdb',
    titleColor = '#333',
    isAbove = false,
}) => (
    <Wrapper
        fDirection={'row'}
        padding={'20px'}
        style={{ position: isAbove ? 'absolute' : 'relative' }}
    >
        <StyledIcon
            src={isClose ? CloseIcon : ArrowIcon}
            color={iconColor}
            width={isClose ? '20px' : '22px'}
            height={isClose ? '20px' : '22px'}
            onClick={clickHandler}
            isBack={!isClose}
        />
        <Text
            padding={'0'}
            margin={'0'}
            size={'20'}
            lh={'26'}
            color={titleColor}
            align={'center'}
            bold
        >
            {title}
        </Text>
        <StyledIcon
            src={QuestionIcon}
            width={'22px'}
            height={'22px'}
            color={iconColor}
            onClick={show}
        />
    </Wrapper>
);
