export const breakpoint = {
    NFH: `(max-width: 1919px)`,
    B: `(max-width: 1600px)`,
    N: `(max-width: 1366px)`,
    L: `(max-width: 1024px)`,
    M: `(max-width: 768px)`,
    S: `(max-width: 490px)`,
    XS: `(max-width: 360px)`,
    MinNFH: `(min-width: 1919px)`,
    MinB: `(min-width: 1600px)`,
    MinN: `(min-width: 1366px)`,
    MinL: `(min-width: 1024px)`,
    MinM: `(min-width: 768px)`,
    MinS: `(min-width: 490px)`,
};
