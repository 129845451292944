import {
    UI_SPORT_OVERVIEW_ACTIVE_GROUP,
    UI_SPORT_OVERVIEW_ACTIVE_SPORT,
    UI_SPORT_OVERVIEW_ANOTHER_GROUPS,
    UI_SPORT_OVERVIEW_BACK_BTN,
    UI_SPORT_OVERVIEW_NAVIGATION_TYPE,
    UI_SPORT_OVERVIEW_PARAMS,
    UI_SPORT_OVERVIEW_RESET,
    UI_SPORT_OVERVIEW_REST_EUROPE,
    UI_SPORT_OVERVIEW_REST_WORLD,
    UI_SPORT_OVERVIEW_SEARCH_TEXT,
    UI_SPORT_OVERVIEW_SHOW_ACTUAL_EVENTS,
    UI_SPORT_OVERVIEW_TOURANMENT_ID,
    UI_SPORT_OVERVIEW_TOURANMENT_STATUS,
} from '../constants/ui';

const initialState = {
    overviewNavType: 'main-sports',
    overviewActiveSport: null,
    overviewBackBtn: false,
    overviewActiveGroup: null,
    overviewShowActualEvents: 'yes',
    overviewRestEurope: null,
    overviewRestWorld: null,
    overviewAnotherGroups: null,
    searchText: null,
    tournamentId: 0,
    tournamentViewStatus: {},
};

export default function ui(state = initialState, action) {
    switch (action.type) {
        case UI_SPORT_OVERVIEW_NAVIGATION_TYPE:
            return { ...state, overviewNavType: action.payload };
        case UI_SPORT_OVERVIEW_ACTIVE_SPORT:
            return { ...state, overviewActiveSport: action.payload };
        case UI_SPORT_OVERVIEW_BACK_BTN:
            return { ...state, overviewBackBtn: action.payload };
        case UI_SPORT_OVERVIEW_ACTIVE_GROUP:
            return { ...state, overviewActiveGroup: '' + action.payload };
        case UI_SPORT_OVERVIEW_SHOW_ACTUAL_EVENTS:
            return { ...state, overviewShowActualEvents: '' + action.payload };
        case UI_SPORT_OVERVIEW_PARAMS:
            return { ...state, ...action.payload };
        case UI_SPORT_OVERVIEW_REST_EUROPE:
            return { ...state, overviewRestEurope: action.payload };
        case UI_SPORT_OVERVIEW_REST_WORLD:
            return { ...state, overviewRestWorld: action.payload };
        case UI_SPORT_OVERVIEW_ANOTHER_GROUPS:
            return { ...state, overviewAnotherGroups: action.payload };
        case UI_SPORT_OVERVIEW_SEARCH_TEXT:
            return { ...state, searchText: action.payload };
        case UI_SPORT_OVERVIEW_TOURANMENT_ID:
            return { ...state, tournamentId: action.payload };
        case UI_SPORT_OVERVIEW_TOURANMENT_STATUS:
            return {
                ...state,
                tournamentViewStatus: {
                    ...state.tournamentViewStatus,
                    ...action.payload,
                },
            };
        case UI_SPORT_OVERVIEW_RESET:
            return {
                ...state,
                overviewNavType: 'main-sports',
                overviewActiveSport: null,
                overviewActiveGroup: null,
                tournamentId: 0,
                tournamentViewStatus: {},
            };
        default:
            return state;
    }
}
