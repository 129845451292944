export const VAIX_LIVE_FAVORITE_EVENTS_IDS_SET =
    'VAIX_LIVE_FAVORITE_EVENTS_IDS_SET';
export const VAIX_LIVE_EVENTS_IDS_RESET = 'VAIX_LIVE_EVENTS_IDS_RESET';
export const VAIX_EVENTS_FETCH_PENDING = 'VAIX_EVENTS_FETCH_PENDING';
export const VAIX_EVENTS_FETCH_POPULAR_PENDING =
    'VAIX_EVENTS_FETCH_POPULAR_PENDING';
export const VAIX_LIVE_FAVORITE_EVENTS_SHOULD_BE_UPDATED =
    'VAIX_LIVE_FAVORITE_EVENTS_SHOULD_BE_UPDATED';

export const VAIX_LIVE_EVENTS_NAVIGATION_ORDER_SET =
    'VAIX_LIVE_EVENTS_NAVIGATION_ORDER_SET';
export const VAIX_LIVE_EVENTS_NAVIGATION_ORDER_RESET =
    'VAIX_LIVE_EVENTS_NAVIGATION_ORDER_RESET';
export const VAIX_LIVE_EVENTS_NAVIGATION_ORDER_FETCHED =
    'VAIX_LIVE_EVENTS_NAVIGATION_ORDER_FETCHED';
export const VAIX_LIVE_FAVORITE_EVENTS_IDS_FETCHED =
    'VAIX_LIVE_FAVORITE_EVENTS_IDS_FETCHED';
//vaixLiveFavoriteEventsIdsFetched

/*export const VAIX_EVENTS_FETCH_SUCCESS = 'VAIX_EVENTS_FETCH_SUCCESS';
export const VAIX_LIVE_EVENTS_FETCH_ERROR = 'VAIX_LIVE_EVENTS_FETCH_ERROR';
export const VAIX_LIVE_POPULAR_EVENTS_FETCH_ERROR =
    'VAIX_LIVE_POPULAR_EVENTS_FETCH_ERROR';
export const VAIX_LIVE_POPULAR_EVENTS_SET = 'VAIX_LIVE_POPULAR_EVENTS_SET';
export const VAIX_LIVE_POPULAR_EVENTS_RESET = 'VAIX_LIVE_POPULAR_EVENTSRE_SET';
export const VAIX_LIVE_EVENTS_RESET = 'VAIX_LIVE_EVENTS_RESET';
export const VAIX_LIVE_EVENTS_IDS_SET = 'VAIX_LIVE_EVENTS_IDS_SET';*/
