export const TERMINAL_SESSION_PENDING = 'TERMINAL_SESSION_PENDING';
export const TERMINAL_SESSION_SUCCESS = 'TERMINAL_SESSION_SUCCESS';
export const TERMINAL_SESSION_ERROR = 'TERMINAL_SESSION_ERROR';
export const TERMINAL_SESSION_LOGOUT = 'TERMINAL_SESSION_LOGOUT';
export const TERMINAL_SESSION_RESET = 'TERMINAL_SESSION_RESET';
export const TERMINAL_APPLY_VOUCHER = 'TERMINAL_APPLY_VOUCHER';
export const TERMINAL_APPLY_VOUCHER_ERROR = 'TERMINAL_APPLY_VOUCHER_ERROR';
export const TERMINAL_APPLY_COUPON_ERROR = 'TERMINAL_APPLY_COUPON_ERROR';
export const TERMINAL_APPLY_COUPON = 'TERMINAL_APPLY_COUPON';
export const TERMINAL_CLEAR_APPLY_VOUCHER_ERROR =
    'TERMINAL_CLEAR_APPLY_VOUCHER_ERROR';
