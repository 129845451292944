import {
    TRANSACTION_HISTORY_PENDING,
    TRANSACTION_HISTORY_FETCH_SUCCESS,
    TRANSACTION_HISTORY_ERROR,
    TRANSACTION_HISTORY_DATE,
    TRANSACTION_HISTORY_SET_INITIAL_CONFIG,
    TRANSACTION_HISTORY_RESET_DATA,
    TRANSACTION_HISTORY_FETCH_SUCCESS_WITH_FILTER,
    TRANSACTION_HISTORY_DATA_IS_UPDATED,
} from '../constants/transactionHistory';
import moment from 'moment';
//const transactionDataFormat = 'DDMMYYYY'

const initialState = {
    listOfTransactions: {
        0: {
            count: 0,
            offset: 0,
            transactions: [],
        },
        1: {
            count: 0,
            offset: 0,
            transactions: [],
        },
        2: {
            count: 0,
            offset: 0,
            transactions: [],
        },
    },
    pending: false,
    error: false,
    date: {
        dateFrom: null,
        dateTo: null,
    },
    //this variable is using in order to update data when date in filter changed after setup data for initial config
    dateIsUpdated: false,
    rowCount: null,
    isInitialDataSet: false,
};

export default function transactionHistory(state = initialState, action) {
    switch (action.type) {
        case TRANSACTION_HISTORY_PENDING:
            return {
                ...state,
                pending: action.payload,
            };

        case TRANSACTION_HISTORY_FETCH_SUCCESS:
            const { data, group } = action.payload;
            return {
                ...state,
                pending: false,
                listOfTransactions: {
                    ...state.listOfTransactions,
                    [group]: {
                        ...state.listOfTransactions[group],
                        transactions: [
                            ...state.listOfTransactions[group].transactions,
                            ...data.transactions,
                        ],
                        count: data.count,
                        offset: state.listOfTransactions[group].offset + 1,
                    },
                },
                dateIsUpdated: false,
            };

        case TRANSACTION_HISTORY_FETCH_SUCCESS_WITH_FILTER:
            let groupL = action.payload.group;
            let dataL = action.payload.data;

            return {
                ...state,
                pending: false,
                listOfTransactions: {
                    ...initialState.listOfTransactions,
                    [groupL]: {
                        ...initialState.listOfTransactions[groupL],
                        transactions: [...dataL.transactions],
                        count: dataL.count,
                        offset: state.listOfTransactions[groupL].offset + 1,
                    },
                },
            };
        case TRANSACTION_HISTORY_DATE:
            return {
                ...state,
                listOfTransactions: {
                    ...initialState.listOfTransactions,
                },
                pending: false,
                date: {
                    dateFrom: action.payload.dateFrom,
                    dateTo: action.payload.dateTo,
                },
            };

        case TRANSACTION_HISTORY_DATA_IS_UPDATED:
            return {
                ...state,
                dateIsUpdated: action.payload,
            };

        case TRANSACTION_HISTORY_SET_INITIAL_CONFIG:
            const { dateFrom, dateTo, rowCount } = action.payload;
            return {
                ...state,
                pending: false,
                date: {
                    dateFrom,
                    dateTo,
                },
                rowCount: rowCount || 20,
                dateIsUpdated: false,
                isInitialDataSet: true,
            };
        case TRANSACTION_HISTORY_RESET_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}
