import React, { Component } from 'react';
import classNames from 'classnames';
import 'css/ViewPageToolbar.scss';

const HIGHER_TITLES = ['Kontohistorik', 'Udbetaling'];

export default class ViewPageToolbar extends Component {
    onClickBackBtn() {
        if (typeof this.props.onBackBtn === 'function') {
            this.props.onBackBtn();
        }
    }

    onClickCloseBtn() {
        if (typeof this.props.onCloseBtn === 'function') {
            this.props.onCloseBtn();
        }
    }

    render() {
        return (
            <div
                className={classNames('view-page-toolbar', {
                    'with-back-btn': !!this.props.onBackBtn,
                    higher: HIGHER_TITLES.includes(this.props.title),
                })}
            >
                {this.props.onBackBtn && (
                    <div
                        className="back-btn"
                        onClick={this.onClickBackBtn.bind(this)}
                    >
                        <i
                            className="fa fa-angle-left fa-2x"
                            aria-hidden="true"
                        />
                    </div>
                )}
                <h1
                    className="three-dots"
                    onClick={this.onClickBackBtn.bind(this)}
                >
                    {this.props.title}
                </h1>
                {this.props.onCloseBtn && (
                    <div
                        className="close-btn"
                        onClick={this.onClickCloseBtn.bind(this)}
                    >
                        <i className="fa fa-times" aria-hidden="true" />
                    </div>
                )}
            </div>
        );
    }
}
