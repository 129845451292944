import {
    TRANSACTION_HISTORY_PENDING,
    TRANSACTION_HISTORY_FETCH_SUCCESS,
    TRANSACTION_HISTORY_DATE,
    TRANSACTION_HISTORY_SET_INITIAL_CONFIG,
    TRANSACTION_HISTORY_RESET_DATA,
    TRANSACTION_HISTORY_FETCH_SUCCESS_WITH_FILTER,
    TRANSACTION_HISTORY_DATA_IS_UPDATED,
} from '../constants/transactionHistory';
import UserService from '../utils/UserService';
import { NotificationManager } from 'react-notifications';

export function GetTransactions(
    group = 2,
    dateFrom = null,
    dateTo = null,
    offset = 0,
    rowCount = 5,
    isFilter = false
) {
    return (dispatch) => {
        dispatch({
            type: TRANSACTION_HISTORY_PENDING,
            payload: true,
        });
        if (dateFrom !== null || dateTo !== null) {
            UserService.getTransactionList(
                group,
                dateFrom,
                dateTo,
                offset,
                rowCount
            )
                .then((res) => {
                    const { data } = res;
                    if (data) {
                        if (isFilter) {
                            dispatch({
                                type: TRANSACTION_HISTORY_FETCH_SUCCESS_WITH_FILTER,
                                payload: {
                                    data,
                                    group,
                                },
                            });
                        } else {
                            dispatch({
                                type: TRANSACTION_HISTORY_FETCH_SUCCESS,
                                payload: {
                                    data,
                                    group,
                                },
                            });
                        }
                    }
                })
                .catch((error) => {
                    NotificationManager.error(error);
                });
        } else {
            dispatch({
                type: TRANSACTION_HISTORY_PENDING,
                payload: false,
            });
        }
    };
}

export function SetTransactionHistoryDate(date) {
    return (dispatch) => {
        dispatch({
            type: TRANSACTION_HISTORY_PENDING,
            payload: true,
        });
        dispatch({
            type: TRANSACTION_HISTORY_DATA_IS_UPDATED,
            payload: false,
        });
        dispatch({
            type: TRANSACTION_HISTORY_DATE,
            payload: date,
        });
        dispatch({
            type: TRANSACTION_HISTORY_DATA_IS_UPDATED,
            payload: true,
        });
    };
}

export function SetInitialConfig(data) {
    return (dispatch) => {
        dispatch({
            type: TRANSACTION_HISTORY_PENDING,
            payload: true,
        });
        dispatch({
            type: TRANSACTION_HISTORY_SET_INITIAL_CONFIG,
            payload: data,
        });
    };
}

export function ResetTransactions(data) {
    return (dispatch) => {
        dispatch({
            type: TRANSACTION_HISTORY_RESET_DATA,
            payload: data,
        });
    };
}
