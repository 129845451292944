import React, { Component } from 'react';
import ViewSpinner from './ViewSpinner';
import '../css/ViewOverlayLoader.css';

export default class ViewOverlayLoader extends Component {
    render() {
        const label = this.props.label ? (
            <div className="label">
                ...
                {this.props.label}
            </div>
        ) : null;

        return (
            <section className="view-overlay-loader">
                {!this.props.removeLoader && <ViewSpinner />}
                {label}
            </section>
        );
    }
}
