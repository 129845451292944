import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import {
    browserHistory,
    IndexRoute,
    Redirect,
    Route,
    Router,
} from 'react-router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'react-notifications/dist/react-notifications.css';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { routerReducer, syncHistoryWithStore } from 'react-router-redux';
import './css/index.css';
import reducers from './reducers';
import UISpinner from './ecoui/UISpinner';
import { useBasename } from 'history';
import '@hookstate/devtools';

import App from './containers/App';
import DesktopApp from './containers/DesktopApp';
import { SEO_SPORTS } from 'constants/appConfig';
import AuthService from 'utils/AuthService';
import * as serviceWorker from './registerServiceWorker';
import { desktopOnly, detectMobile } from 'utils/UiHelper';

const queryClient = new QueryClient();
const HomePage = lazy(() => import('./pages/HomePage'));
const LiveEventsPage = lazy(() => import('./pages/LiveEventsPage'));
const PreEventsPage = lazy(() => import('./pages/PreEventsPage'));
const CampaignsPage = lazy(() => import('./pages/CampaignsPage'));
const CampaignPage = lazy(() => import('./pages/CampaignPage'));
const HistoryPage = lazy(() => import('./pages/HistoryPage'));
const GamePage = lazy(() => import('./pages/GamePage/GamePageLayout'));
const NoMatchPage = lazy(() => import('./pages/NoMatchPage'));
const PaymentDepositPage = lazy(() => import('./pages/PaymentDepositPage'));
const PaymentPayoutPage = lazy(() =>
    import('./pages/PaymentPayout/PaymentPayoutPageNew')
);
const PaymentVoucherPage = lazy(() => import('./pages/PaymentVoucherPage'));
const UnsubscribePage = lazy(() => import('features/UnsubscribePage'));
const TransactionHistory = lazy(() =>
    import('./pages/TransactionsHistory/TransactionHistory')
);
const IndbetalingsgraensePage = lazy(() =>
    import('./pages/IndbetalingsgraensePage')
);
const SelfExclusionPage = lazy(() => import('pages/SelfExclusionPage'));
const BrugerinfoPage = lazy(() => import('./pages/BrugerinfoPage'));
const NewsPage = lazy(() => import('./pages/NewsPage'));
const BonusPage = lazy(() => import('./pages/BonusPage'));
const InfoPage = lazy(() => import('./pages/InfoPage'));
const BetslipPage = lazy(() => import('./pages/BetslipPage'));
const ViewUserMenu = lazy(() => import('./components/ViewUserMenu'));
const BigNinePage = lazy(() => import('./pages/BigNinePage'));
const LiveCasinoPage = lazy(() => import('./pages/LiveCasinoPage'));
const PromoWelcome = lazy(() => import('./features/PromoWelcome'));
const Success = lazy(() => import('features/UserDeposits/Components/Success'));
const Error = lazy(() => import('features/UserDeposits/Components/Error'));

if (!detectMobile()) {
    window.document.getElementsByTagName('body')[0].className = 'desktop';
}
const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
});
const store = createStore(
    combineReducers({
        ...reducers,
        routing: routerReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
);

const isTWA = Boolean(JSON.parse(window.localStorage.getItem('isTWA')));

if (process.env.NODE_ENV !== 'production') {
    window.__store__ = store;
}

const history = syncHistoryWithStore(
    useBasename(() => browserHistory)({ basename: '/sport' }),
    store
);

let rootComponent = App;

const renderWithSuspense = (
    Component,
    routerProps = {},
    fallback = <UISpinner />
) => (
    <Suspense fallback={fallback}>
        <Component {...routerProps} />
    </Suspense>
);

if (desktopOnly()) {
    rootComponent = DesktopApp;
    ReactDOM.render(
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Router history={history}>
                    <Redirect from="/home" to="/" />
                    <Redirect from="/mobil" to="/" />
                    <Redirect from="/live-casino" to="/live-casino/alle" />
                    <Redirect from="/velkommen" to="/" />
                    <Redirect from="/konto" to="/konto/indbetaling" />
                    <Redirect from="/indbetaling" to="/konto/indbetaling" />
                    <Redirect from="/udbetaling" to="/konto/udbetaling" />
                    <Redirect from="/bonus" to="/konto/bonus" />
                    <Redirect from="/voucher" to="/konto/voucher" />
                    <Redirect from="/big9" to="/big9/spil" />
                    <Redirect from="/spil" to="/big9/spil" />
                    <Redirect from="/spilklub25" to="/big9/spilklub25" />
                    <Redirect from="/kontohistorik" to="/konto/kontohistorik" />
                    <Redirect
                        from="/indbetalingsgraense"
                        to="/konto/indbetalingsgraense"
                    />
                    <Redirect
                        from="/selvudelukkelse"
                        to="/konto/selvudelukkelse"
                    />
                    <Redirect from="/brugerinfo" to="/konto/brugerinfo" />
                    <Redirect from="/nyhedsbrev" to="/konto/nyhedsbrev" />
                    <Redirect
                        from="/historik/aktive"
                        to="/konto/historik/aktive"
                    />
                    <Redirect
                        from="/historik/afsluttede"
                        to="/konto/historik/afsluttede"
                    />
                    <Redirect
                        from="/historik/butik"
                        to="/konto/historik/butik"
                    />
                    <Redirect from="/historik/big9" to="/konto/historik/big9" />

                    <Route
                        path="/promo/:promo"
                        component={(props) =>
                            renderWithSuspense(PromoWelcome, props)
                        }
                    />
                    <Route path="/" component={rootComponent}>
                        <IndexRoute
                            component={(props) =>
                                renderWithSuspense(HomePage, props, null)
                            }
                        />
                        <Route
                            path="/splash-test-mode"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/afmeld-nyhedsbrev"
                            component={(props) =>
                                renderWithSuspense(UnsubscribePage, props)
                            }
                        />
                        <Route
                            path="/live"
                            component={(props) =>
                                renderWithSuspense(LiveEventsPage, props, null)
                            }
                        />
                        <Route
                            path="/pre"
                            component={(props) =>
                                renderWithSuspense(PreEventsPage, props, null)
                            }
                        />
                        <Route
                            path="/velkomsttilbud"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/404"
                            component={(props) =>
                                renderWithSuspense(NoMatchPage, props)
                            }
                        />
                        <Route
                            path="/kamp/:id"
                            component={(props) =>
                                renderWithSuspense(GamePage, props, null)
                            }
                        />
                        <Route
                            path="/kampagner"
                            component={(props) =>
                                renderWithSuspense(CampaignsPage, props)
                            }
                        >
                            <Route
                                path="/kampagner/:seoUrl"
                                component={(props) =>
                                    renderWithSuspense(CampaignPage, props)
                                }
                            />
                        </Route>
                        <Route
                            path="/historik"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />

                        <Route
                            path="/konto/historik/aktive"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/konto/historik/afsluttede"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/konto/historik/big9"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/konto/historik/butik"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/konto/indbetaling"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/deposit/success"
                            component={(props) =>
                                renderWithSuspense(Success, props)
                            }
                        />
                        <Route
                            path="/deposit/error"
                            component={(props) =>
                                renderWithSuspense(Error, props)
                            }
                        />
                        <Route
                            path="/konto/udbetaling"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/konto/bonus"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/konto/voucher"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/konto/kontohistorik"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/konto/indbetalingsgraense"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/konto/selvudelukkelse"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/konto/brugerinfo"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/konto/nyhedsbrev"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/info/:id"
                            component={(props) =>
                                renderWithSuspense(InfoPage, props)
                            }
                        />

                        <Route
                            path="/kupon"
                            component={(props) =>
                                renderWithSuspense(BetslipPage, props)
                            }
                        />
                        <Route
                            path="/log-ind"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/login"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/opret-konto"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/signup"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/forgot-password"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/mitid-verify/forgotpassword"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/mitid-verify/confirm/:type"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/mitid-verify/success/:type"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/mitid-verify/error"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/opret-bruger"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        {!isTWA && (
                            <Route
                                path="/live-casino/:category"
                                component={(props) =>
                                    renderWithSuspense(LiveCasinoPage, props)
                                }
                            />
                        )}

                        <Route
                            path="/big9/:category/:screen/:entityId/:entityId2"
                            component={(props) =>
                                renderWithSuspense(BigNinePage, props)
                            }
                        />

                        <Route
                            path="/big9/:category/:screen/:entityId"
                            component={(props) =>
                                renderWithSuspense(BigNinePage, props)
                            }
                        />

                        <Route
                            path="/big9/:category/:screen"
                            component={(props) =>
                                renderWithSuspense(BigNinePage, props)
                            }
                        />
                        <Route
                            path="/big9/:category"
                            component={(props) =>
                                renderWithSuspense(BigNinePage, props)
                            }
                        />
                        {SEO_SPORTS.map((sport, index) => (
                            <Route
                                key={index}
                                path={'/' + sport}
                                component={() => renderWithSuspense(HomePage)}
                            />
                        ))}

                        {SEO_SPORTS.map((sport, index) => (
                            <Route
                                key={index}
                                path={'/' + sport + '/:group'}
                                component={(props) =>
                                    renderWithSuspense(HomePage, props)
                                }
                            />
                        ))}

                        {SEO_SPORTS.map((sport, index) => (
                            <Route
                                key={index}
                                path={'/' + sport + '/:group/:tournament'}
                                component={(props) =>
                                    renderWithSuspense(HomePage, props)
                                }
                            />
                        ))}

                        <Route
                            path="*"
                            component={(props) =>
                                renderWithSuspense(NoMatchPage, props)
                            }
                        />
                    </Route>
                </Router>
            </QueryClientProvider>
        </Provider>,
        document.getElementById('root')
    );
} else {
    ReactDOM.render(
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Router history={history}>
                    <Redirect from="/home" to="/" />
                    <Redirect from="/velkommen" to="/" />
                    <Redirect from="/velkomsttilbud" to="/" />
                    <Redirect from="/live-casino" to="/live-casino/alle" />
                    <Redirect from="/indbetaling" to="/konto/indbetaling" />
                    <Redirect from="/udbetaling" to="/konto/udbetaling" />
                    <Redirect from="/bonus" to="/konto/bonus" />
                    <Redirect from="/voucher" to="/konto/voucher" />
                    <Redirect
                        from="/indbetalingsgraense"
                        to="/konto/indbetalingsgraense"
                    />
                    <Redirect
                        from="/selvudelukkelse"
                        to="/konto/selvudelukkelse"
                    />
                    <Redirect from="/brugerinfo" to="/konto/brugerinfo" />
                    <Redirect from="/nyhedsbrev" to="/konto/nyhedsbrev" />
                    <Redirect from="/big9" to="/big9/spil" />

                    <Route
                        path="/promo/:promo"
                        component={(props) =>
                            renderWithSuspense(PromoWelcome, props)
                        }
                    />
                    <Route path="/" component={rootComponent}>
                        <IndexRoute
                            component={(props) =>
                                renderWithSuspense(HomePage, props, null)
                            }
                        />
                        <Route
                            path="/afmeld-nyhedsbrev"
                            component={(props) =>
                                renderWithSuspense(UnsubscribePage, props)
                            }
                        />
                        <Route
                            path="/splash-test-mode"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/live"
                            component={(props) =>
                                renderWithSuspense(LiveEventsPage, props, null)
                            }
                        />
                        <Route
                            path="/pre"
                            component={(props) =>
                                renderWithSuspense(PreEventsPage, props, null)
                            }
                        />
                        <Route
                            path="/velkomsttilbud"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/profile"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/kamp/:id"
                            component={(props) =>
                                renderWithSuspense(GamePage, props, null)
                            }
                        />
                        <Route
                            path="/404"
                            component={() => renderWithSuspense(NoMatchPage)}
                        />
                        <Route
                            path="/kampagner"
                            component={(props) =>
                                renderWithSuspense(CampaignsPage, props)
                            }
                        />
                        <Route
                            path="/kampagner/:seoUrl"
                            component={(props) =>
                                renderWithSuspense(CampaignPage, props)
                            }
                        />
                        <Route
                            path="/historik"
                            component={(props) =>
                                renderWithSuspense(HistoryPage, props)
                            }
                        />
                        <Route
                            path="/historik/afsluttede"
                            component={(props) =>
                                renderWithSuspense(HistoryPage, props)
                            }
                        />
                        <Route
                            path="/historik/aktive"
                            component={(props) =>
                                renderWithSuspense(HistoryPage, props)
                            }
                        />
                        <Route
                            path="/historik/big9"
                            component={(props) =>
                                renderWithSuspense(HistoryPage, props)
                            }
                        />
                        <Route
                            path="/deposit/success"
                            component={(props) =>
                                renderWithSuspense(Success, props)
                            }
                        />
                        <Route
                            path="/deposit/error"
                            component={(props) =>
                                renderWithSuspense(Error, props)
                            }
                        />
                        <Route
                            path="/konto/indbetaling"
                            component={(props) =>
                                renderWithSuspense(PaymentDepositPage, props)
                            }
                        />
                        <Route
                            path="/konto/udbetaling"
                            component={(props) =>
                                renderWithSuspense(PaymentPayoutPage, props)
                            }
                        />
                        <Route
                            path="/konto/bonus"
                            component={(props) =>
                                renderWithSuspense(BonusPage, props)
                            }
                        />
                        <Route
                            path="/konto/voucher"
                            component={(props) =>
                                renderWithSuspense(PaymentVoucherPage, props)
                            }
                        />
                        <Route
                            path="/konto"
                            component={(props) =>
                                renderWithSuspense(ViewUserMenu, props)
                            }
                        />
                        <Route
                            path="/konto/kontohistorik"
                            component={(props) =>
                                renderWithSuspense(TransactionHistory, props)
                            }
                        />
                        <Route
                            path="/konto/indbetalingsgraense"
                            component={(props) =>
                                renderWithSuspense(
                                    IndbetalingsgraensePage,
                                    props
                                )
                            }
                        />
                        <Route
                            path="/konto/selvudelukkelse"
                            component={(props) =>
                                renderWithSuspense(SelfExclusionPage, props)
                            }
                        />
                        <Route
                            path="/konto/brugerinfo"
                            component={(props) =>
                                renderWithSuspense(BrugerinfoPage, props)
                            }
                        />
                        <Route
                            path="/konto/nyhedsbrev"
                            component={(props) =>
                                renderWithSuspense(NewsPage, props)
                            }
                        />
                        <Route
                            path="/info/:id"
                            component={(props) =>
                                renderWithSuspense(InfoPage, props)
                            }
                        />
                        <Route
                            path="/kupon"
                            component={(props) =>
                                renderWithSuspense(BetslipPage, props)
                            }
                        />
                        <Route
                            path="/log-ind"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="testing/log-ind"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/login"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/opret-konto"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/signup"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/forgot-pin"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />

                        <Route
                            path="/forgot-password"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/mitid-verify/forgotpassword"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/mitid-verify/forgotpin"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/mitid-verify/confirm/:type"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/mitid-verify/success/:type"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/mitid-verify/error"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/opret-bruger"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        <Route
                            path="/velkomsttilbud"
                            component={(props) =>
                                renderWithSuspense(HomePage, props)
                            }
                        />
                        {!isTWA && (
                            <Route
                                path="/live-casino/:category"
                                component={(props) =>
                                    renderWithSuspense(LiveCasinoPage, props)
                                }
                            />
                        )}
                        <Route
                            path="/big9/:category/:screen/:entityId/:entityId2"
                            component={(props) =>
                                renderWithSuspense(BigNinePage, props)
                            }
                        />
                        <Route
                            path="/big9/:category/:screen/:entityId"
                            component={(props) =>
                                renderWithSuspense(BigNinePage, props)
                            }
                        />
                        <Route
                            path="/big9/:category/:screen"
                            component={(props) =>
                                renderWithSuspense(BigNinePage, props)
                            }
                        />
                        <Route
                            path="/big9"
                            component={(props) =>
                                renderWithSuspense(BigNinePage, props)
                            }
                        />
                        <Route
                            path="/big9/:category"
                            component={(props) =>
                                renderWithSuspense(BigNinePage, props)
                            }
                        />

                        {SEO_SPORTS.map((sport, index) => (
                            <Route
                                key={index}
                                path={'/' + sport}
                                component={(props) =>
                                    renderWithSuspense(HomePage, props)
                                }
                            />
                        ))}
                        {SEO_SPORTS.map((sport, index) => (
                            <Route
                                key={index}
                                path={'/' + sport + '/:group'}
                                component={(props) =>
                                    renderWithSuspense(HomePage, props)
                                }
                            />
                        ))}
                        {SEO_SPORTS.map((sport, index) => (
                            <Route
                                key={index}
                                path={'/' + sport + '/:group/:tournament'}
                                component={(props) =>
                                    renderWithSuspense(HomePage, props)
                                }
                            />
                        ))}
                        <Route
                            path="*"
                            component={(props) =>
                                renderWithSuspense(NoMatchPage, props)
                            }
                        />
                    </Route>
                </Router>
            </QueryClientProvider>
        </Provider>,
        document.getElementById('root')
    );
}

let el = document.getElementById('bet25-spinner');

el.parentNode.removeChild(el);

serviceWorker.register();

window.login = (username, password) => {
    let channel = 8;
    detectMobile() ? (channel = 8) : (channel = 0);
    AuthService.login(username, password, channel)
        .then((res) => {
            if (res.success) {
                dataLayer.push({
                    event: 'window login',
                    userId: res?.data?.userId,
                });
                window.location.reload();
            } else {
                console.log(res.errorMessage);
            }
        })
        .catch((err) => {
            console.log(err.errorMessage);
        });
};
