import React, { useEffect } from 'react';
import Button from '../../ui/Button';
import {
    NavigationBar,
    CardsImage,
    PreviewButtons,
    PhysicalCardWrapper,
} from './styled';
import {
    hideModal as HideModal,
    showModal as ShowModal,
} from '../../actions/UiActions';
import { connect } from 'react-redux';
import Text from '../../ui/Text';
import {
    detectMobile,
    disableBodyScroll,
    enableBodyScroll,
} from 'utils/UiHelper';
import withPopupWrapper from 'features/SpilID/withPopupWrapper';
import FlexWrapper from 'ui/FlexWrapper';

const PhysicalCardPreview = ({ hideModal, showModal, modalData }) => {
    useEffect(() => {
        disableBodyScroll();
    }, []);

    return (
        <PhysicalCardWrapper>
            <NavigationBar
                title={'Bestil Spil ID kort'}
                clickHandler={() => {
                    hideModal('SPIL_ID_PC_PREVIEW');
                    enableBodyScroll();
                }}
                show={() =>
                    showModal('SPIL_ID_DESCRIPTION_MODAL', {
                        bg: modalData.bg,
                    })
                }
                isAbove
                iconColor={'#fff'}
                titleColor={'#fff'}
            />

            <FlexWrapper
                direction={'column'}
                padding={'0'}
                style={{
                    minHeight: detectMobile() ? 'var(--app-height)' : '700px',
                }}
            >
                <CardsImage />

                <FlexWrapper
                    padding={'33px 22px 24px'}
                    direction={'column'}
                    align={'space-between'}
                    style={{
                        boxSizing: 'border-box',
                        flex: '1 1 auto',
                    }}
                >
                    <FlexWrapper
                        padding={'0'}
                        direction={'column'}
                        alignItems={'flex-start'}
                        align={'flex-start'}
                    >
                        <Text size={'x3'} bold color={'#000'}>
                            Bestil fysisk Spil ID
                        </Text>

                        <Text
                            size={'x15'}
                            padding={'0'}
                            color={'#333'}
                            margin={'16px 0'}
                        >
                            Få et fysisk Spil ID kort, i stedet for eller som
                            supplement til dit digitale kort på telefonen, til
                            vores terminaler.
                        </Text>

                        <Text
                            size={'x15'}
                            padding={'0'}
                            color={'#333'}
                            margin={'16px 0'}
                        >
                            Vi sender dit Spil ID kort - kvit og frit - med
                            posten.
                        </Text>
                    </FlexWrapper>
                    <PreviewButtons>
                        <Button
                            onClick={() => hideModal('SPIL_ID_PC_PREVIEW')}
                            fontSize="16px"
                            grey
                            fontWeight={'normal'}
                        >
                            Annuller
                        </Button>
                        <Button
                            onClick={() =>
                                showModal('SPIL_ID_PC_FORM', modalData)
                            }
                            fontSize="16px"
                            active
                        >
                            Bestil
                        </Button>
                    </PreviewButtons>
                </FlexWrapper>
            </FlexWrapper>
        </PhysicalCardWrapper>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (modalId, data) => dispatch(ShowModal(modalId, data)),
        hideModal: (modalId) => dispatch(HideModal(modalId)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(detectMobile() ? PhysicalCardPreview : withPopupWrapper(PhysicalCardPreview));
